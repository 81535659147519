import { findPermissionsObject } from "./findPermissionsObject";
import { getNestedArrValues } from "./getNestedArrValues";
import { filterStringArr } from "./filterStringArr";

type TypeRemoveChildNestedValues = (
  permissionsArr: any,
  currValue: string,
  originalArr: string[]
) => string[];

export const removeChildNestedValues: TypeRemoveChildNestedValues = (
  permissionsArr,
  currValue,
  originalArr
) => {
  //   return getNestedArrValues([findPermissionsObject(permissionsArr, currValue)]);
  return filterStringArr(
    originalArr,
    getNestedArrValues([findPermissionsObject(permissionsArr, currValue)])
  );
};
