import {
  useSetCompanyRewardPointsMutation,
} from "graphql/_generated/graphql";

export const useSetCompanyRewardPoints = () => {
  const [setCompanyRewardPointsFun, { isLoading }] = useSetCompanyRewardPointsMutation();

  //this function is responsible for adding/updating company reward points
  const handleUpdateCompanyRewardPoints = async (values: any) => {
    try {
      await setCompanyRewardPointsFun(values).unwrap();
    } catch (error: any) {
      throw error;
    }
  };

  return {
    isLoading,
    handleUpdateCompanyRewardPoints,
  };
};
