import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { customNotification } from "components/custom-notification/CustomNotification";
import { CustomCheckBoxGroup } from "components/form/custom-checkbox-group/CustomCheckBoxGroup";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { CustomUploadFile } from "components/form/custom-upload-file/CustomUploadFile";
import { errorFormat } from "helpers";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { profileSchema } from "validations/schema";
import { useUpdateProfile } from "./useUpdateProfile";
import { useTranslation } from "react-i18next";
import { useErrorHandler } from "hooks/useErrorHandler";
interface UpdateProfileProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateProfile: React.FunctionComponent<UpdateProfileProps> = ({
  setVisible,
  visible,
}: UpdateProfileProps) => {
  const { handleError } = useErrorHandler();
  const { t } = useTranslation();
  const handleCancel = () => {
    setVisible(false);
  };
  const [initialValues, setInitialValues] = useState({
    image: "",
    firstName: "",
    lastName: "",
    notificationsEnabled: true,
  });
  const { loading, handleUpdateProfile, handleFetchProfileData } =
    useUpdateProfile();
  useEffect(() => {
    handleFetchProfileData().then((data: any) => {
      setInitialValues(data);
    });
  }, [visible]);

  return (
    <CustomModal
      width={600}
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      title={t("update-profile")}
    >
      <Formik
        enableReinitialize
        validationSchema={profileSchema}
        initialValues={initialValues}
        onSubmit={async (values, { resetForm }) => {
          try {
            await handleUpdateProfile({ updates: values });
            customNotification("success", t("profile-saved-successfully"));
            resetForm();
            setVisible(false);
          } catch (error) {
            handleError(error as string | object);
          }
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <CustomUploadFile
              name="image"
              setFieldValue={setFieldValue}
              imgUrl={values.image}
              accept="image/*"
            />
            <CustomTextField
              name="firstName"
              placeholder={t("first-name")}
              label={t("first-name")}
            />
            <CustomTextField
              name="lastName"
              placeholder={t("last-name")}
              label={t("last-name")}
            />
            <div className="form-control">
              <CustomCheckBoxGroup
                name="notificationsEnabled"
                options={[
                  {
                    label: t("is-notifications-enabled"),
                    value: true,
                  },
                ]}
              >
                {t("is-notifications-enabled")}
              </CustomCheckBoxGroup>
            </div>
            <ModalFooter
              saveText={t("save-profile")}
              onCancel={handleCancel}
              loading={loading}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default UpdateProfile;
