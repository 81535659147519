/**
 * Judging criteria for an assessment type.
 */
enum EnumQuestionTypes {
  SINGLE_CHOICE = "singleChoice",
  MULTIPLE_CHOICE = "multipleChoice",
  BOOLEAN = "boolean",
  RANKING = "ranking",
  RATING = "rating",
  TEXT = "text",
  SLIDER = "slider",
  MATRIX = "matrix",
}

export default EnumQuestionTypes;
