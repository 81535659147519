import { MoreOutlined } from "@ant-design/icons";
import { Dropdown, Switch, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import CustomButton from "components/custom-button/CustomButton";
import CustomMenu from "components/custom-menu/CustomMenu";
import CustomTable from "components/custom-table/CustomTable";
import CustomPopconfirm from "components/form/custom-pop-confirm/CustomPopConfirm";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import AddRole from "features/roles/AddRole";
import styles from "features/roles/css/RolesList.module.scss";
import { useRolesListViewModel } from "features/roles/hooks/useRolesListViewModel";
import { RoleList } from "graphql/_generated/graphql";
import { PERMS } from "helpers";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

interface RolesListProps {}

const RolesList: React.FunctionComponent<RolesListProps> = () => {
  //Initializing States
  const { t } = useTranslation();
  const vm = useRolesListViewModel();

  const columns: ColumnsType<RoleList["data"][0]> = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      render: (_text, _record, index) =>
        (vm.currentPage - 1) * vm.pageSize + index + 1,
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("description"),
      dataIndex: "description",
      key: "description",
    },
    {
      title: t("created-at"),
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: {
        multiple: 1,
      },
      render: (createdAt: string) =>
        moment(createdAt).format("MMMM Do YYYY, h:mm:ss a"),
    },
    {
      title: t("updated-at"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: {
        multiple: 1,
      },
      render: (updatedAt: string) =>
        moment(updatedAt).format("MMMM Do YYYY, h:mm:ss a"),
    },
    {
      width: "20%",
      title: t("status"),
      dataIndex: "isActive",
      key: "isActive",
      align: "center" as "center",
      render: (isActive: boolean) =>
        isActive ? (
          <Tag className="active-tag">{t("active")}</Tag>
        ) : (
          <Tag className="de-active-tag">{t("deactivated")}</Tag>
        ),
    },
    {
      align: "center" as "center",
      title: t("activation"),
      dataIndex: "id",
      key: "id",
      render: (id: string, { isActive }) => (
        <CheckPermissions permission={PERMS.TOGGLE_ROLE_ACTIVATION}>
          <CustomPopconfirm
            onConfirm={() => vm.handleRoleActivation(id)}
            title={
              isActive
                ? t("confirm-deactivate-role")
                : t("confirm-activate-role")
            }
          >
            <Switch
              className="custom-switch"
              defaultChecked
              checked={isActive}
            />
          </CustomPopconfirm>
        </CheckPermissions>
      ),
    },
    {
      align: "center" as "center",
      title: "",
      dataIndex: "id",
      key: "id",
      render: (id: string) => (
        <>
          <Dropdown
            overlay={
              <CheckPermissions permission={PERMS.UPDATE_ROLE}>
                <CustomMenu
                  items={[{ label: t("edit-role"), key: "edit-role" }]}
                  onClick={({ key }) => vm.handleMenu({ key, id })}
                />
              </CheckPermissions>
            }
          >
            <CustomButton type="link" className="link-btn">
              <MoreOutlined />
            </CustomButton>
          </Dropdown>
        </>
      ),
    },
  ];

  return (
    <DashboardLayout openKey={["Configs"]}>
      <div className={styles["roles"]}>
        <Breadcrumbs
          data={[
            {
              label: "Configurations",
            },
            {
              label: "All Roles",
            },
          ]}
        />
        <CustomTable
          filters={{
            handleCallback: vm.handleFilterCallback,
          }}
          className="custom-table"
          columns={columns}
          permission={PERMS.ADD_NEW_ROLE}
          totalItems={vm.columnsData?.metadata?.total}
          onClick={() => vm.setVisible(true)}
          headerTitle={t("all-roles")}
          btnText={t("add-new-role")}
          dataSource={vm.columnsData?.data}
          loading={vm.isLoading}
          showSearch={false}
        />
      </div>
      <AddRole
        visible={vm.visible}
        editId={vm.editId}
        initialValues={vm.initialValues}
        handleCancel={vm.handleCancel}
        handleSubmit={vm.handleSubmit}
        permissionsInitialValues={vm.permissionsInitialValues}
        permissionsDataArr={vm.permissionsDataArr}
        ALL_PERMS={vm.ALL_PERMS}
        isLoading={vm.isRoleLoading}
        showPermissionsError={vm.showPermissionsError}
        onCheck={vm.onCheck}
      />
    </DashboardLayout>
  );
};

export default RolesList;
