import {
  TierUsersList,
  useGetUsersListByTierQuery,
  useLazyGetUsersListByTierQuery,
} from "graphql/_generated/graphql";
import { useEffect, useState } from "react";
interface UserStatsProps {
  tierId: string;
}

export const useUserStatsViewModel = ({ tierId }: UserStatsProps) => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState<TierUsersList>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>("");
  const [companyId, setCompanyId] = useState("");
  const [pagination, setPagination] = useState<any>({
    limit: 10,
    offset: 0,
  });

  const [getUsersListFun, { isFetching }] = useLazyGetUsersListByTierQuery();

  const fetchUsersData = async () => {
    try {
      const { getUsersListByTier } = await getUsersListFun({
        tierId: tierId,
        pagination,
        search: searchText,
        companyId: companyId,
      }).unwrap();
      setData(getUsersListByTier);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUsersData();
  }, [currentPage, pagination, searchText, companyId]);

  const handleSearchInput = (value: string) => {
    setSearchText(value);
    setPagination({
      limit: 10,
      offset: 0,
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const { data: usersData, isLoading } = useGetUsersListByTierQuery({
    tierId: tierId,
    pagination: {
      limit: 6,
    },
  });

  const onPaginationChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPagination({
      offset: (page - 1) * pageSize,
      limit: pageSize,
    });
  };

  return {
    visible,
    usersData,
    isLoading,
    setVisible,
    data,
    handleCancel,
    setCompanyId,
    handleSearchInput,
    isFetching,
    pagination,
    setCurrentPage,
    setPagination,
    currentPage,
    onPaginationChange,
  };
};
