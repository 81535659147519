import {
  MutationAddNewChallengeArgs,
  useAddNewChallengeMutation,
} from "graphql/_generated/graphql";

export const useAddNewChallenge = () => {
  const [addNewChallengeFun, { isLoading }] = useAddNewChallengeMutation();

  //this function is responsible for adding new Challenge
  const handleAddNewChallenge = async (values: MutationAddNewChallengeArgs) => {
    try {
      await addNewChallengeFun({ create: values.create }).unwrap();
    } catch (error: any) {
      throw error;
    }
  };

  return {
    isLoading,
    handleAddNewChallenge,
  };
};
