import { useLazyGetQuestByIdQuery } from "graphql/_generated/graphql";

export const useGetQuest = () => {
  const [getQuestFun, { isFetching }] = useLazyGetQuestByIdQuery();
  const handleGetQuest = async (id: string) => {
    try {
      const res = await getQuestFun({ getQuestByIdId: id }).unwrap();
      return res.getQuestById;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleGetQuest,
    isLoadingGetQuest: isFetching,
  };
};
