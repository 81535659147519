import { customNotification } from "components/custom-notification/CustomNotification";
import { FormikHelpers } from "formik";
import {
  ConfigOptions,
  PositionCreate,
  useLazyGetPositionsConfigQuery,
} from "graphql/_generated/graphql";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useLoggedInUser } from "hooks/useLoggedInUser";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { usePosition } from "../usePosition";

const defaultValues = {
  name: "",
  description: "",
  departmentId: "",
  translations: [],
};

export type ConfigDataProps = {
  departmentId?: string;
  positionId: string;
};

export const useAddPositionViewModel = () => {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const navigate = useNavigate();
  const { isClient, isSuperAdmin, isViwellAdmin } = useLoggedInUser();
  const [editId, setEditId] = useState<string | null>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [clientId, setClientId] = useState<string>("");
  const [inviteUserView, setInviteUserView] = useState<boolean>(false);
  const [departmentId, setDepartmentId] = useState<null | string>(null);

  const [configData, setConfigData] = useState<ConfigDataProps>({
    departmentId: "",
    positionId: "",
  });
  const [positionsData, setPositionsData] = useState<ConfigOptions[]>([
    {
      id: "",
      label: "",
    },
  ]);

  const handleInviteUser = () => {
    setInviteUserView(true);
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
    setEditId(null);
  };

  const handleClientId = (client: string) => {
    setClientId(client);
    setConfigData({
      departmentId: "",
      positionId: "",
    });
  };

  const handleEditId = (id: string) => {
    setEditId(id);
    setVisible(true);
  };

  const [initialValues, setInitialValues] =
    useState<PositionCreate>(defaultValues);

  const {
    isLoading,
    handleAddPosition,
    handleFetchEditPositionData,
    handleUpdatePosition,
  } = usePosition();

  useEffect(() => {
    fetchPositionData(editId);
  }, [editId]);

  const [getPositionsConfigFun, { isFetching: isPositionsLoading }] =
    useLazyGetPositionsConfigQuery();

  const fetchPositionData = (editId?: string | null) => {
    if (editId) {
      handleFetchEditPositionData(editId).then((data) => {
        setInitialValues({
          name: data.name ?? "",
          description: data.description ?? "",
          translations: data.translations,
          departmentId: data.department?.isActive ? data.department.id : "",
        });
      });
    } else {
      setInitialValues(defaultValues);
    }
  };

  useEffect(() => {
    fetchPositions();
  }, [departmentId]);

  const fetchPositions = async (deptId?: string) => {
    const data = await getPositionsConfigFun({
      clientId: clientId,
      departmentId: deptId || departmentId,
    }).unwrap();

    setPositionsData(data.getPositionsConfig.options);
  };

  const handleSubmit = async (
    values: PositionCreate,
    { resetForm }: FormikHelpers<PositionCreate>,
  ) => {
    try {
      if (!editId) {
        const data = { ...values };
        if (isSuperAdmin || isViwellAdmin) {
          data.clientId = clientId;
        }
        const posResponse = await handleAddPosition({ create: data });
        fetchPositions(data.departmentId);
        inviteUserView &&
          setConfigData?.({
            departmentId: data.departmentId,
            positionId: posResponse?.id,
          });
        customNotification("success", t("position-added-successfully"));
      } else {
        await handleUpdatePosition({ id: editId, updateObj: values });
        customNotification("success", t("position-updated-successfully"));
      }
      resetForm();
      closeModal?.();
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const redirectToPositions = () => {
    navigate("/positions");
  };

  return {
    fetchPositionData,
    handleSubmit,
    redirectToPositions,
    isClient,
    initialValues,
    isLoading,
    closeModal,
    editId,
    visible,
    setVisible,
    setEditId,
    handleClientId,
    setInviteUserView,
    handleEditId,
    inviteUserView,
    configData,
    handleInviteUser,
    setDepartmentId,
    isPositionsLoading,
    positionsData,
  };
};
