import { useGetFilteredData } from "./useGetFilteredData";

/**
 * Help shape listing
 *
 * @returns {function, boolean}
 */
export const useFetchDataFromApi = (isSimple?: boolean) => {
  const { applyFiltersOnQueryData, isLoading } = useGetFilteredData(isSimple);

  async function fetchDataFromApi<T extends Function>(
    queryFunctionName: T,
    setData: (x: any) => void,
    filtersData?: any,
    args?: object,
  ) {
    try {
      const havePagination =
        filtersData?.pagination &&
        (filtersData?.pagination?.limit || filtersData?.pagination?.offset);
      const haveFilters = filtersData?.filters;
      const haveFiltersUndefined =
        filtersData === undefined || filtersData === null;
      if (havePagination || haveFilters || haveFiltersUndefined) {
        const data = await applyFiltersOnQueryData(
          queryFunctionName,
          filtersData,
          args,
        );
        setData(data[Object.keys(data)[0]]);
      }
    } catch (error) {
      throw error;
    }
  }

  return { fetchDataFromApi, isLoading };
};
