import { Form } from "antd";
import {
  ActiveAdvanceFilterSvg,
  AdvanceFilterSvg,
  CrossBGSvg,
} from "assets/icons";
import CustomRangePicker from "components/form/custom-range-picker/CustomRangePicker";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { Formik } from "formik";
import {
  UserFilters,
  useLazyGetClientsTreeQuery,
  useLazyGetDepartmentsConfigQuery,
  useLazyGetPositionsConfigQuery,
} from "graphql/_generated/graphql";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "../CompanyDashboard.module.scss";
import { FilterValuesProps } from "../types";
import {
  organizationTimeFilterOptions,
  timeFilterOptions,
  timeFilterTypes,
} from "../utils";
import SectionAdvanceFilters from "./SectionAdvanceFilters";

interface SectionHeaderProps {
  children?: React.ReactNode;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  userFilters?: any;
  setUserFilters?: any;
  sectionTitle: string;
  isAdvanceFilters?: boolean;
  companyRegisteredDate?: string;
  initialValues?: FilterValuesProps;
  selectedValues?: FilterValuesProps;
  setSelectedValues?: React.Dispatch<React.SetStateAction<FilterValuesProps>>;
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SectionHeader: React.FunctionComponent<SectionHeaderProps> = ({
  children,
  visible,
  setVisible,
  userFilters,
  setUserFilters,
  sectionTitle,
  isAdvanceFilters = true,
  companyRegisteredDate,
  initialValues,
  selectedValues,
  setSelectedValues,
  setOpen,
  open,
}) => {
  const { t } = useTranslation();
  const [configData, setConfigData] = useState<{
    companies: any;
    departments: any;
    positions: any;
  }>({
    companies: [],
    departments: [],
    positions: [],
  });

  const [getClientsTreeQueryFun] = useLazyGetClientsTreeQuery();
  const [getDepartmentsConfigQuery, { isLoading: isDeptLoading }] =
    useLazyGetDepartmentsConfigQuery();

  const [getPositionsConfigQuery, { isLoading: isPositionLoading }] =
    useLazyGetPositionsConfigQuery();

  useEffect(() => {
    fetchClients();
    fetchDepartments(userFilters?.clientIds);
    fetchPositions(userFilters?.clientIds, userFilters?.departmentIds);
  }, [userFilters]);

  const fetchClients = async () => {
    const { data: clientData } = await getClientsTreeQueryFun({
      clientId: null,
    });
    setConfigData((prevData) => ({
      ...prevData,
      companies: clientData?.getClientsTree,
    }));
  };

  const fetchDepartments = async (clientId?: string) => {
    const { data: departmentData } = await getDepartmentsConfigQuery({
      clientId: clientId?.length ? clientId : undefined,
    });

    setConfigData((prevData) => ({
      ...prevData,
      departments: departmentData?.getDepartmentsConfig?.options,
    }));
  };

  const fetchPositions = async (clientId?: string, departmentId?: string) => {
    const { data: positionData } = await getPositionsConfigQuery({
      clientId: clientId?.length ? clientId : undefined,
      departmentId: departmentId?.length ? departmentId : undefined,
    });

    setConfigData((prevData) => ({
      ...prevData,
      positions: positionData?.getPositionsConfig?.options,
    }));
  };

  const activeFiltersData: { [key: string]: any } = {
    clientIds: {
      type: "array",
      singleText: t("company"),
      multiText: t("companies"),
    },
    departmentIds: {
      type: "array",
      singleText: t("department-text"),
      multiText: t("departments"),
    },
    positionIds: {
      type: "array",
      singleText: t("position-text"),
      multiText: t("positions"),
    },
    gender: { type: "string", text: t("gender") },
    age: { type: "range", text: t("age") },
  };

  const handleFilterChange = (values: UserFilters) => {
    const data = {
      ...values,
      age:
        values?.age?.min || values?.age?.max
          ? {
              min: values?.age?.min || undefined,
              max: values?.age?.max || undefined,
            }
          : null,
    };
    setUserFilters(data);
  };

  const clearAllFilters = () => {
    const data = {
      clientIds: null,
      departmentIds: null,
      gender: null,
      positionIds: null,
      age: null,
    };
    setUserFilters(data, true);
  };

  const renderActiveFilter = (
    key: string,
    name: string,
    value: string,
    total?: number,
  ) => {
    return (
      <div className={`${styles["active-filter-container"]} margin-right-5`}>
        <div className={`${styles["active-filter-text"]} margin-right-5`}>{`${
          value ? value : `${total} ${name}`
        }`}</div>
        <div
          className="cursor-pointer"
          onClick={() => {
            setUserFilters((prevData: any) => ({
              ...prevData,
              [key]: null,
            }));
            if (["clientIds", "departmentIds"].includes(key)) {
              setUserFilters((prevData: any) => ({
                ...prevData,
                departmentIds: null,
                positionIds: null,
              }));
            }
          }}
        >
          <CrossBGSvg />
        </div>
      </div>
    );
  };

  return (
    <>
      <div>
        <div className={styles["section-item-container"]}>
          <div className={styles["section-title"]}>{sectionTitle}</div>
          <div className={styles["filter-container"]}>
            {children}
            {isAdvanceFilters && Object.keys(userFilters)?.length ? (
              <>
                {userFilters?.["clientIds"]?.length ||
                userFilters?.["departmentIds"]?.length ||
                userFilters?.["gender"]?.length ||
                userFilters?.["positionIds"]?.length ||
                userFilters?.["age"]?.min ||
                userFilters?.["age"]?.max ? (
                  <div
                    className={`${styles["clear-all-filters"]} cursor-pointer`}
                    onClick={() => clearAllFilters()}
                  >
                    {t("clear-all")}
                  </div>
                ) : null}
                {Object.keys(userFilters)?.map((item) =>
                  activeFiltersData?.[item]?.type === "array" &&
                  userFilters?.[item]?.length
                    ? renderActiveFilter(
                        item,
                        userFilters?.[item]?.length === 1
                          ? activeFiltersData?.[item].singleText
                          : activeFiltersData?.[item].multiText,
                        "",
                        userFilters?.[item]?.length,
                      )
                    : activeFiltersData?.[item]?.type === "string" &&
                      userFilters?.[item]
                    ? renderActiveFilter(
                        item,
                        activeFiltersData?.[item].text,
                        userFilters?.[item],
                      )
                    : activeFiltersData?.[item]?.type === "range" &&
                      (userFilters?.[item]?.min || userFilters?.[item]?.max)
                    ? renderActiveFilter(
                        item,
                        activeFiltersData?.[item].text,
                        `${activeFiltersData?.[item].text}: ${
                          userFilters?.[item]?.min || ""
                        }${
                          userFilters?.[item]?.max && userFilters?.[item]?.min
                            ? `-${userFilters?.[item]?.max}`
                            : userFilters?.[item]?.max || ""
                        }`,
                      )
                    : null,
                )}
              </>
            ) : null}
            {initialValues ? (
              <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validateOnChange={true}
                validateOnBlur={true}
                onSubmit={() => {}}
              >
                {({ setFieldValue, values }) => (
                  <Form>
                    <div className={styles["filter-container"]}>
                      {values.pastDays === timeFilterTypes.CUSTOM_DATE_RANGE ? (
                        <CustomRangePicker
                          name="customDateRange"
                          className={`dashboard-date-range-picker margin-right-5`}
                          disabledDate={(current) => {
                            return (
                              moment(companyRegisteredDate, "YYYY-MM-DD") >=
                                current || current >= moment().endOf("day")
                            );
                          }}
                          open={open}
                          allowClear={false}
                          onOpenChange={() => setOpen?.(!open)}
                          onChange={(value: any) => {
                            setFieldValue("customDateRange", value);
                            setSelectedValues?.({
                              ...selectedValues,
                              customDateRange: value,
                            });
                            setOpen?.(false);
                          }}
                        />
                      ) : null}
                      <CustomSelectField
                        name={
                          initialValues?.timeFilter ? "timeFilter" : "pastDays"
                        }
                        className={"dashboard-filter-select margin-right-5"}
                        options={
                          initialValues?.timeFilter
                            ? organizationTimeFilterOptions
                            : timeFilterOptions
                        }
                        onChange={(value: any) => {
                          if (initialValues?.timeFilter) {
                            setFieldValue("timeFilter", value);
                            setSelectedValues?.({
                              timeFilter: value,
                            });
                          } else {
                            setFieldValue("pastDays", value);
                            setFieldValue("customDateRange", "");
                            setSelectedValues?.({
                              pastDays: value,
                              customDateRange: "",
                            });
                            value === timeFilterTypes.CUSTOM_DATE_RANGE &&
                              setOpen?.(true);
                          }
                        }}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            ) : null}

            {isAdvanceFilters ? (
              <div
                className={"cursor-pointer"}
                onClick={() => setVisible(true)}
              >
                {userFilters?.clientIds?.length ||
                userFilters?.departmentIds?.length ||
                userFilters?.positionIds?.length ||
                userFilters?.gender ||
                userFilters?.age?.max ||
                userFilters?.age?.min ? (
                  <ActiveAdvanceFilterSvg />
                ) : (
                  <AdvanceFilterSvg />
                )}
              </div>
            ) : null}
          </div>
        </div>
        {visible ? (
          <SectionAdvanceFilters
            visible={visible}
            setVisible={setVisible}
            initialValues={userFilters}
            handleSubmit={handleFilterChange}
            configData={configData}
            fetchDepartments={fetchDepartments}
            fetchPositions={fetchPositions}
          />
        ) : null}
      </div>
    </>
  );
};
