import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import React, { useEffect } from "react";
import Icon from "@ant-design/icons";
import { Space, Select } from "antd";
import styles from "./CheckinHeader.module.scss";
import { Formik, Form } from "formik";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { questionTypes } from "../Question/questionTypes";
import EnumBoolean from "features/assessment/types/EnumBoolean";
import { useTranslation } from "react-i18next";

interface CheckinHeaderProps {
  values: any;
  disabled: boolean;
  isAddLanguage?: boolean;
  oldRecord?: any;
  assessmentSettings?: any;
}

const CheckinHeader: React.FunctionComponent<CheckinHeaderProps> = ({
  values,
  disabled,
  isAddLanguage,
  oldRecord,
  assessmentSettings,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    console.log("......values", values);
  }, [values]);

  const { Option } = Select;
  return (
    <div className={styles["checkin-header"]}>
      <CustomSelectField
        defaultValue="singleChoice"
        placeholder={t("checkin-question-type-placeholder")}
        name="optionType"
        disabled={
          disabled || values.savedOnServer === EnumBoolean.YES || isAddLanguage
        }
      >
        {questionTypes
          .filter((item) => item.value === "singleChoice")
          .map((item, index) => (
            <Option key={index} value={item.value}>
              <Space align="center">
                <Icon component={item.icon} />
                <div>{item.label}</div>
              </Space>
            </Option>
          ))}
      </CustomSelectField>
      <CustomTextField
        disabled={disabled}
        name="text"
        placeholder={t("checkin-question-placeholder")}
        isAddLanguage={isAddLanguage}
        oldTextValue={oldRecord?.text}
      />
      {assessmentSettings?.isScoresEnable ? (
        <CustomTextField
          disabled={disabled || isAddLanguage}
          name="score"
          placeholder={t("checkin-score-placeholder")}
          type="number"
        />
      ) : null}
    </div>
  );
};

export default CheckinHeader;
