import { List, Space } from "antd";
import * as React from "react";
import styles from "features/rewards/css/UserStats.module.scss";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import Icon from "@ant-design/icons";
import { PointsIcon } from "assets/icons/PointsSvg";
interface UserItemProps {
  id: number;
  name?: string;
  companyName?: string;
  image?: string;
  points?: number;
  fullView?: boolean;
}

const UserItem: React.FunctionComponent<UserItemProps> = ({
  id,
  image,
  name,
  points,
  companyName,
  fullView,
}) => {
  return (
    <List.Item style={{ height: "66px" }}>
      <div className={fullView ? styles["view-grid"] : styles["item-grid"]}>
        <div className={styles["item-title"]}>
          <Space size={14}>
            <div className={styles["item-id"]}>{id}</div>
            <CustomAvatar imgSrc={image} size={32} shape="circle" />
            <Space size={0} direction="vertical">
              <div
                className={
                  id === 1 ? styles["main-title"] : styles["sub-title"]
                }
              >
                {name}
              </div>
              {!fullView && (
                <div className={styles["company-title"]}>{companyName}</div>
              )}
            </Space>
          </Space>
        </div>
        {fullView && (
          <div className={styles["company-title"]}>{companyName}</div>
        )}
        <Space align="center">
          <div className={styles["points"]}>{points}</div>
          <Icon component={PointsIcon} />
        </Space>
      </div>
    </List.Item>
  );
};

export default UserItem;
