import { Col, Row } from "antd";
import CustomDivider from "components/custom-divider/CustomDivider";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { useTranslation } from "react-i18next";
import styles from "../css/AddPartnerReward.module.scss";
import { RewardStocksProps } from "../hooks/useAddPartnerRewardViewModel";

type Props = {
  redeemedRewardStocks: Array<RewardStocksProps>;
};

export default function RedeemedCodes({ redeemedRewardStocks }: Props) {
  const { t } = useTranslation();

  return (
    <div>
      {redeemedRewardStocks && redeemedRewardStocks.length ? (
        <>
          <Row gutter={[24, 0]}>
            <Col sm={12}>
              <div className={styles["reward-code-title"]}>
                {t("reward-code")}
              </div>
            </Col>
            <Col sm={12}>
              <div className={styles["reward-code-title"]}>
                {t("redeemed-by")}
              </div>
            </Col>
          </Row>
          <CustomDivider />
          <div>
            {redeemedRewardStocks.map(
              (stock: RewardStocksProps, index: number) => (
                <div key={index}>
                  <Row gutter={[24, 24]}>
                    <Col sm={12}>
                      <CustomTextField
                        name={`redeemedRewardStocks.[${index}].rewardCode`}
                        placeholder={t("type-code")}
                        disabled={true}
                      />
                    </Col>
                    <Col sm={12}>
                      <div className={styles["redeemed-email"]}>
                        {stock.redeemedUserEmail}
                      </div>
                    </Col>
                  </Row>
                  <CustomDivider />
                </div>
              ),
            )}
          </div>
        </>
      ) : (
        <div className={styles["no-redeemed-code"]}>{t("no-record-found")}</div>
      )}
    </div>
  );
}
