import { Button, Result } from "antd";
import { useNavigationToDashboard } from "hooks/useNavigationToDashboard";
import { useSelector } from "react-redux";

type Props = {};

export default function NotFound({}: Props) {
  const { navigateToSignInUserDashboard } = useNavigationToDashboard();
  const token = useSelector((state: any) => state.auth.token);
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button
          className="primary-btn"
          onClick={() => navigateToSignInUserDashboard(token)}
        >
          Back Home
        </Button>
      }
    />
  );
}
