import { FormikHelpers } from "formik";
import {
  GetAllHabitsQuery,
  useGetAllHabitsQuery,
  useGetConfigPillarsQuery,
  useLazyGetAllHabitsQuery,
} from "graphql/_generated/graphql";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useHabits } from "./useHabits";
import customNotification from "components/custom-notification/CustomNotification";

export const useHabitsViewModel = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleError } = useErrorHandler();
  const { fetchDataFromApi, isLoading: isLoadingHabitList } =
    useFetchDataFromApi(true);

  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [filtersData, setFiltersData] = useState<any>(null);
  const [columnsData, setColumnsData] =
    useState<GetAllHabitsQuery["getAllHabits"]>();

  const [langVisibility, setLangVisibility] = useState(false);
  const [langCode, setLangCode] = useState<string>();
  const [editId, setEditId] = useState<string | null>(null);
  const [isAddLanguage, setIsAddLanguage] = useState(false);
  const [habitRecord, setHabitRecord] = useState<any>();

  const { data: pillars } = useGetConfigPillarsQuery();
  const pillarsData = pillars?.getConfigPillars;

  const [getHabitListFun] = useLazyGetAllHabitsQuery();
  const { isFetching, refetch: refetchHabitList } = useGetAllHabitsQuery();

  const { handleDeleteHabit, handleToggleHabitStatus, isLoading } = useHabits();

  useEffect(() => {
    fetchDataFromApi(getHabitListFun, setColumnsData, filtersData).catch((e) =>
      handleError(e),
    );
  }, [isFetching, filtersData]);

  const filtersCallBack = async (filtersData: any) => {
    try {
      setPageSize(filtersData?.pagination?.offset?.pageSize);
      setCurrentPage(filtersData?.pagination?.offset?.page);
      setFiltersData(filtersData);
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const mappedColumnsData = columnsData?.data?.map((item) => {
    return (
      {
        ...item,
        hasChildren: true,
        children:
          item?.translations && Object.keys(item?.translations).length
            ? Object.keys(item?.translations).map((record: string) => {
                return {
                  ...item,
                  title: item?.translations?.[record]?.title,
                  language: record,
                };
              })
            : null,
      } ?? []
    );
  });

  const onPressAddHabit = () => {
    setLangVisibility(true);
  };

  const onLanguageSubmit = (
    values: { language: string },
    actions?: FormikHelpers<typeof values>,
  ) => {
    setLangCode(values?.language);
    onCloseLangDialog();
    actions?.resetForm();
    navigate("/habits/add-habit", {
      state: {
        langCode: values.language,
        id: editId,
        addLanguage: isAddLanguage,
      },
    });
  };

  const onCloseLangDialog = () => {
    setLangVisibility(false);
    setIsAddLanguage(false);
    setHabitRecord(undefined);
  };

  const onPressAddLang = (id: string, record: any) => {
    setEditId(id);
    setLangVisibility(true);
    setIsAddLanguage(true);
    setHabitRecord(record);
  };

  const onCloseAddHabit = () => {
    setEditId(null);
    setIsAddLanguage(false);
    setHabitRecord(undefined);
    setLangCode(undefined);
  };

  const onPressUpdateHabit = (id: string, langCode: string) => {
    navigate("/habits/add-habit", {
      state: {
        id: id,
        langCode: langCode,
      },
    });
  };

  const onPressUpdateLanguage = (id: string, langCode: string) => {
    navigate("/habits/add-habit", {
      state: {
        id: id,
        langCode: langCode,
        addLanguage: true,
      },
    });
  };

  const onPressHabitDetails = (id: string, langCode: string) => {
    navigate("/habits/habit-details", {
      state: {
        id: id,
        langCode: langCode,
        addLanguage: false,
      },
    });
  };

  const onDeleteHabit = async (id: string) => {
    try {
      const res = await handleDeleteHabit(id);
      customNotification("success", t("habit-deleted-successfully"));
      refetchHabitList();
      return res;
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const onToggleHabitStatus = async (id: string) => {
    try {
      const isHabitActive = await handleToggleHabitStatus(id);
      customNotification(
        "success",
        isHabitActive
          ? t("habit-activated-successfully")
          : t("habit-deactivated-successfully"),
      );
      refetchHabitList();
      return isHabitActive;
    } catch (error) {
      handleError(error as string | object);
    }
  };

  return {
    pageSize,
    currentPage,
    langVisibility,
    langCode,
    editId,
    isAddLanguage,
    habitRecord,
    pillarsData,
    columnsData,
    habitsListData: mappedColumnsData,
    onLanguageSubmit,
    onCloseLangDialog,
    onPressAddHabit,
    onPressAddLang,
    onCloseAddHabit,
    filtersCallBack,
    onPressUpdateHabit,
    onPressUpdateLanguage,
    onPressHabitDetails,
    onDeleteHabit,
    onToggleHabitStatus,
    loading: {
      isLoadingHabitList,
      isLoading,
    },
  };
};
