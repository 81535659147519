import {
  RequestOtpMutationVariables,
  useRequestOtpMutation,
} from "graphql/_generated/graphql";

// hook type
type TypeUseRequestOtp = () => {
  isLoading: boolean;
  handleRequestOtp: (values: RequestOtpMutationVariables) => Promise<any>;
};

export const useRequestOtp: TypeUseRequestOtp = () => {
  const [requestOtpFun, { isLoading }] = useRequestOtpMutation();

  //this function is responsible for login all the users
  const handleRequestOtp = async (values: RequestOtpMutationVariables) => {
    try {
      await requestOtpFun({
        body: { ...values.body },
      }).unwrap();
    } catch (error: any) {
      throw error;
    }
  };

  return { isLoading, handleRequestOtp };
};
