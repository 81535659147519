import {
  EventTranslationInput,
  SetEventTranslationMutationVariables,
  useSetEventTranslationMutation,
} from "graphql/_generated/graphql";

export const useEventTranslation = () => {
  const [addTranslationFun, { isLoading: isLoadingTranslations }] =
    useSetEventTranslationMutation();

  const handleEventTranslation = async (
    values: SetEventTranslationMutationVariables,
  ) => {
    try {
      const res = await addTranslationFun({
        setEventTranslationId: values.setEventTranslationId,
        translationInput: values.translationInput,
      }).unwrap();
      return res.setEventTranslation;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleEventTranslation,
    isLoadingTranslations,
  };
};
