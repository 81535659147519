import { useAddQuest } from "./useAddQuest";
import { useAddQuestTranslations } from "./useAddQuestTranslations";
import { useDeleteQuest } from "./useDeleteQuest";
import { useGetQuest } from "./useGetQuest";
import { useToggleQuest } from "./useToggleQuest";
import { useUpdateQuest } from "./useUpdateQuest";

export const useQuest = () => {
  const { handleAddQuest, isLoading } = useAddQuest();
  const { handleDeleteQuest, isLoadingDeleteQuest } = useDeleteQuest();
  const { handleToggleQuest, isLoadingToggleQuest } = useToggleQuest();
  const { handleGetQuest, isLoadingGetQuest } = useGetQuest();
  const { handleUpdateQuest, isLoadingUpdateQuest } = useUpdateQuest();
  const { handleAddQuestTranslations, isLoadingAddQuestTranslations } =
    useAddQuestTranslations();
  return {
    handleAddQuest,
    handleDeleteQuest,
    handleToggleQuest,
    handleGetQuest,
    handleUpdateQuest,
    handleAddQuestTranslations,
    isLoading:
      isLoading ||
      isLoadingGetQuest ||
      isLoadingUpdateQuest ||
      isLoadingAddQuestTranslations,
    isLoadingDeleteQuest,
    isLoadingToggleQuest,
    isLoadingGetQuest,
  };
};
