import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik, FormikHelpers } from "formik";
import { Space } from "antd";
import { Select } from "formik-antd";

import {
  CreateWellBeingCalender,
  useGetWellBeingCalenderListQuery,
} from "graphql/_generated/graphql";

import { errorFormat } from "helpers";
import { customNotification } from "components/custom-notification/CustomNotification";
import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";

import { useWellBeingCalender } from "../hooks/useWellBeingCalender";
import months from "../constants/months";
import years from "../constants/years";
import { wellBeingCalenderSchema } from "validations/schema/wellBeingCalenderSchema";
import { useErrorHandler } from "hooks/useErrorHandler";

interface AddWellbeingCalendarProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  editId?: string | null;
  setEditId: React.Dispatch<React.SetStateAction<string | null>>;
  data?: CreateWellBeingCalender | null;
}

const { Option } = Select;
const defaultInitialValues = { month: "", year: "", url: "" };

const AddWellbeingCalendar: React.FunctionComponent<
  AddWellbeingCalendarProps
> = ({ visible, setVisible, editId, setEditId, data }) => {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();

  const {
    handleAddNewWellBeingCalender,
    handleUpdateWellBeingCalender,
    isLoading,
  } = useWellBeingCalender();
  const { refetch } = useGetWellBeingCalenderListQuery();

  const handleCancel = () => {
    setVisible(false);
    setEditId(null);
  };
  const [initialValues, setInitialValues] =
    useState<CreateWellBeingCalender>(defaultInitialValues);

  useEffect(() => {
    setInitialValues({
      ...(editId
        ? {
            month: data?.month!,
            year: data?.year!,
            url: data?.url!,
          }
        : { ...defaultInitialValues }),
    });
  }, [editId]);

  return (
    <CustomModal
      title={editId ? t("wellbeing.edit") : t("wellbeing.add")}
      width={600}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
    >
      <Formik
        enableReinitialize={true}
        validationSchema={wellBeingCalenderSchema}
        initialValues={initialValues}
        onSubmit={async (
          values: CreateWellBeingCalender,
          { resetForm }: FormikHelpers<CreateWellBeingCalender>,
        ) => {
          try {
            if (!editId) {
              await handleAddNewWellBeingCalender({ create: values });
            } else {
              await handleUpdateWellBeingCalender({
                update: values,
                id: editId,
              });
            }

            refetch();
            resetForm();
            setVisible(false);
            setEditId(null);
          } catch (error: unknown) {
            handleError(error as string | object);
          }
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <CustomSelectField
              allowClear
              label={t("wellbeing.year")}
              className="form-select"
              name="year"
              placeholder={t("wellbeing.select-year")}
            >
              {years?.map((year: string, index: number) => (
                <Option value={year} key={index}>
                  <Space>{year}</Space>
                </Option>
              ))}
            </CustomSelectField>
            <CustomSelectField
              allowClear
              label={t("wellbeing.month")}
              className="form-select"
              name="month"
              placeholder={t("wellbeing.select-month")}
            >
              {months?.map((month: string, index: number) => (
                <Option value={month} key={index}>
                  <Space>{month}</Space>
                </Option>
              ))}
            </CustomSelectField>
            <CustomTextField
              name="url"
              label={t("wellbeing.url")}
              placeholder={t("wellbeing.url")}
            />
            <ModalFooter
              saveText={t("wellbeing.save")}
              onCancel={handleCancel}
              loading={isLoading}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddWellbeingCalendar;
