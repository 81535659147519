import ContainerHeading from "features/assessment/questions/ContainerHeading";
import CollapseAble from "features/assessment/questions/collapseables/CollapseAble";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styles from "features/rewards/css/TierDetail.module.scss";
import { GetTierDetailsQuery } from "graphql/_generated/graphql";
import CustomImg from "components/custom-img/CustomImg";
import { Space } from "antd";
import { PointsIcon } from "assets/icons/PointsSvg";
import Icon from "@ant-design/icons";

interface TierDetailProps {
  data?: GetTierDetailsQuery["getTierDetails"];
}

const TierDetail: React.FunctionComponent<TierDetailProps> = ({ data }) => {
  const { t } = useTranslation();
  return (
    <CollapseAble
      defaultActiveKey={["tierDetail"]}
      headerKey="tierDetail"
      hideExtra
      expandIconPosition="end"
      header={<ContainerHeading text={t("details")} />}
    >
      <div className={styles["desc-grid"]}>
        <div className={styles["desc-container"]}>
          <div className={styles["image-grid"]}>
            <div className={styles["tierIcon"]}>
              <CustomImg
                className={styles["tier-icon"]}
                imgSrc={data?.tierIcon ?? ""}
              />
            </div>
            <div className={styles["tier-image"]}>
              <CustomImg
                className={styles["tierImg"]}
                imgSrc={data?.tierImage ?? ""}
              />
            </div>
            <div className={styles["card-image"]}>
              <CustomImg imgSrc={data?.tierCardImage ?? ""} />
            </div>
          </div>
        </div>
        <div className={styles["desc-container"]}>
          <div className={styles["description"]}>
            <div className={styles["title"]}>{data?.name}</div>
            <Space size={16} align="baseline">
              <div className={styles["title"]}>{data?.points}</div>
              <Icon component={PointsIcon} />
            </Space>
          </div>
        </div>
      </div>
    </CollapseAble>
  );
};

export default TierDetail;
