import { MoreOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import {
  BuildingList,
  useGetBuildingListQuery,
  useLazyGetBuildingListQuery,
} from "graphql/_generated/graphql";
import React, { useEffect, useState } from "react";

import CustomButton from "components/custom-button/CustomButton";
import CustomDropDown from "components/custom-dropdown/CustomDropDown";
import CustomMenu from "components/custom-menu/CustomMenu";
import CustomTable from "components/custom-table/CustomTable";
import { PERMS } from "helpers";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";

// import AddBadge from "../add-building/AddBadge";
// import { useBuilding } from "../useBuilding";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useTranslation } from "react-i18next";
import AddBuilding from "../add-building/AddBuilding";
import { useAddBuildingViewModel } from "../hooks/useAddBuildingViewModel";

interface BuildingsListProps {}

const BuildingsList: React.FunctionComponent<BuildingsListProps> = (props) => {
  //states
  const { t } = useTranslation();
  const [filtersData, setFiltersData] = useState<any>(null);
  const [columnsData, setColumnsData] = useState<BuildingList>();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const vm = useAddBuildingViewModel();

  //end states

  //hooks
  const { isFetching, refetch } = useGetBuildingListQuery();
  const [getAllBuildingsDataFun] = useLazyGetBuildingListQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const { handleError } = useErrorHandler();

  // const { handleActivationBuilding } = useBuilding();
  //end hooks

  //helpers
  const handleMenu = ({ domEvent, key, keyPath, id }: any) => {
    if (key === "edit-building") {
      vm.handleEditId(id);
    }
  };
  //end helpers

  //effects
  useEffect(() => {
    fetchDataFromApi(getAllBuildingsDataFun, setColumnsData, filtersData).catch(
      (e) => handleError(e),
    );
  }, [isFetching]);
  //end effects

  const columns: ColumnsType<BuildingList["data"][0]> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: {
        multiple: 1,
      },
      render: (_text, _record, index) =>
        (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: t("building-title"),
      dataIndex: "name",
      key: "Title",
      sorter: {
        multiple: 1,
      },
    },
    {
      title: t("address"),
      dataIndex: "address",
      key: "Address",
      render: (_text) => _text?.address,
    },
    {
      title: t("country"),
      dataIndex: "address",
      key: "Country",
      align: "center",
      render: (_text) => _text?.country,
    },
    {
      title: t("city"),
      dataIndex: "address",
      key: "City",
      align: "center",
      render: (_text) => _text?.city,
    },
    {
      title: "",
      dataIndex: "id",
      key: "action",
      align: "center",
      render: (id: string) => (
        <>
          <CustomDropDown
            overlay={
              <CustomMenu
                items={[{ label: t("edit-building"), key: "edit-building" }]}
                onClick={({ domEvent, key, keyPath }) =>
                  handleMenu({ domEvent, key, keyPath, id })
                }
              />
            }
          >
            <CustomButton type="link" className="link-btn">
              <MoreOutlined />
            </CustomButton>
          </CustomDropDown>
        </>
      ),
    },
  ];

  return (
    <>
      <CustomTable
        filters={{
          handleCallback: async (filtersData: any) => {
            await fetchDataFromApi(
              getAllBuildingsDataFun,
              setColumnsData,
              filtersData,
            );
            setPageSize(filtersData?.pagination?.offset?.pageSize);
            setCurrentPage(filtersData?.pagination?.offset?.page);
            setFiltersData(filtersData);
          },
        }}
        rowKey="id"
        loading={isLoading}
        columns={columns}
        totalItems={columnsData?.metadata?.total}
        permission={PERMS.ADD_NEW_DEPARTMENT}
        onClick={() => vm.setVisible(true)}
        headerTitle={t("buildings-title")}
        btnText={t("add-building")}
        dataSource={columnsData?.data}
      />
      <AddBuilding
        visible={Boolean(vm.visible)}
        closeModal={vm.closeModal}
        isLoading={vm.isLoading}
        initialValues={vm.initialValues}
        handleSubmit={vm.handleSubmit}
        isClient={vm.isClient}
        redirectToBuildings={vm.redirectToBuildings}
        countries={vm.countries}
      />
    </>
  );
};

export default BuildingsList;
