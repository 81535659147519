export const DepartmentSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.700859 4.0725L7.80169 7.93583C7.92419 8.0025 8.07586 8.0025 8.19919 7.93583L15.3 4.0725C15.5859 3.91667 15.5609 3.51833 15.2567 3.39583L8.15669 0.53C8.05669 0.49 7.94336 0.49 7.84336 0.53L0.743359 3.39583C0.440026 3.51833 0.415026 3.91667 0.700859 4.0725ZM15.2569 6.88017L13.7319 6.23017L8.19937 9.24017C8.07603 9.30683 7.92437 9.30683 7.80103 9.24017L2.26853 6.23017L0.743535 6.88017C0.439368 7.00933 0.414368 7.431 0.701035 7.59517L7.80103 11.676C7.92437 11.7468 8.07603 11.7468 8.19937 11.676L15.2994 7.59517C15.586 7.431 15.561 7.00933 15.2569 6.88017ZM13.9594 10.0188L15.2569 10.5805C15.561 10.7122 15.586 11.1397 15.2994 11.3063L8.19937 15.4463C8.07603 15.518 7.92437 15.518 7.80103 15.4463L0.701035 11.3063C0.414368 11.1397 0.439368 10.7122 0.743535 10.5805L2.04103 10.0188L7.80103 13.153C7.92437 13.2197 8.07603 13.2197 8.19937 13.153L13.9594 10.0188Z"
        fill="#303030"
      />
    </svg>
  );
};
