import {
  useAddAndUpdateCarousalTranslationMutation,
  MutationAddAndUpdateCarousalTranslationArgs,
} from "graphql/_generated/graphql";

export const useAddAndUpdateCarousalTranslation = () => {
  const [addAndUpdateCarousalTranslationFun, { isLoading }] =
    useAddAndUpdateCarousalTranslationMutation();
  const handleAddUpdateCarousalTranslation = async (
    values: MutationAddAndUpdateCarousalTranslationArgs,
  ) => {
    try {
      const res = await addAndUpdateCarousalTranslationFun({
        create: values.create,
      }).unwrap();
      return res;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleAddUpdateCarousalTranslation,
    isLoadingAddUpdateCarousalTranslation: isLoading,
  };
};
