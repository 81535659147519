export const ChallengeRewardsSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0001 3.33268C11.8384 3.33268 13.3334 4.82768 13.3334 6.66602C13.3334 8.50435 11.8384 9.99935 10.0001 9.99935C8.16177 9.99935 6.66677 8.50435 6.66677 6.66602C6.66677 4.82768 8.16177 3.33268 10.0001 3.33268ZM15.8076 17.2918L13.8834 9.80935C14.5801 8.94935 15.0001 7.85685 15.0001 6.66602C15.0001 3.90852 12.7576 1.66602 10.0001 1.66602C7.2426 1.66602 5.0001 3.90852 5.0001 6.66602C5.0001 7.85685 5.4201 8.94935 6.11677 9.80935L4.1926 17.2918C4.10927 17.6185 4.22927 17.9627 4.49844 18.1652C4.7676 18.3677 5.13177 18.386 5.42177 18.2177L9.86344 15.6102L14.5968 18.2285C14.7226 18.2985 14.8618 18.3327 15.0001 18.3327C15.1809 18.3327 15.3609 18.2743 15.5101 18.1585C15.7734 17.9552 15.8901 17.6143 15.8076 17.2918Z"
        fill="#303030"
      />
    </svg>
  );
};
