export const toUpperCaseWords = (text: string) => {
  return text
    .split(" ")
    .map((word) => {
      if (word.length > 0) {
        const first = word.charAt(0);
        return first.toUpperCase() + word.substring(1);
      }
      return "";
    })
    .join(" ");
};

export const toUpperCaseFirstWord = (text: string) => {
  if (text.length > 1) {
    const first = text.charAt(0);
    return first.toUpperCase() + text.substring(1).toLocaleLowerCase();
  }
  return "";
};
