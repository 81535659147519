import {
  MutationAddNewCategoryMappingArgs,
  useAddNewCategoryMappingMutation,
} from "graphql/_generated/graphql";

// Define the type of handleAddMapper function
export type TypeHandleAddNewCategoryMapping = (
  values: MutationAddNewCategoryMappingArgs,
) => Promise<void>;

// Define the type of useAddCategoryMapping hook
export type TypeUseAddMapper = () => {
  isLoading: boolean;
  handleAddMapper: TypeHandleAddNewCategoryMapping;
};

export const useAddCategoryMapping: TypeUseAddMapper = () => {
  const [addRewardFun, { isLoading }] = useAddNewCategoryMappingMutation();

  // This function is responsible for adding the mapper data
  const handleAddMapper: TypeHandleAddNewCategoryMapping = async (values) => {
    try {
      await addRewardFun(values).unwrap();
    } catch (error: unknown) {
      throw error;
    }
  };

  // This function is responsible for updating the offer data
  return { isLoading, handleAddMapper };
};
