import { useAddPartnerReward } from "./useAddPartnerReward";
import { useDeletePartnerReward } from "./useDeletePartnerReward";
import { useImportBulkRewards } from "./useImportBulkRewards";
import { useGetRewardVisibility } from "./useRewardVisibility";
import { useTogglePartnerReward } from "./useTogglePartnerReward";
import { useUpdatePartnerReward } from "./useUpdatePartnerReward";

export const useReward = () => {
  const { handleAddPartnerReward, isLoading: isLoadingAddPartnerReward } =
    useAddPartnerReward();

  const { rewardVisibility } = useGetRewardVisibility();

  const {
    handleGetPartnerReward,
    handleUpdateReward,
    isLoading: isLoadingGetPartnerReward,
  } = useUpdatePartnerReward();

  const { handleTogglePartnerReward, isLoading: isLoadingToggleReward } =
    useTogglePartnerReward();

  const { handleDeleteReward, isLoading: isLoadingDeleteReward } =
    useDeletePartnerReward();

  const { handleImportBulkRewards, isLoading: isLoadingBulkRewards } =
    useImportBulkRewards();

  return {
    handleAddPartnerReward,
    handleGetPartnerReward,
    handleTogglePartnerReward,
    handleDeleteReward,
    handleUpdateReward,
    handleImportBulkRewards,
    rewardVisibility,
    isLoading:
      isLoadingAddPartnerReward ||
      isLoadingGetPartnerReward ||
      isLoadingToggleReward ||
      isLoadingDeleteReward ||
      isLoadingBulkRewards,
  };
};
