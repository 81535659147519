import { MoreOutlined } from "@ant-design/icons";
import { Modal, Switch, TableColumnsType } from "antd";
import { DefaultRecordType } from "rc-table/lib/interface";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomTable from "components/custom-table/CustomTable";
import { customNotification } from "components/custom-notification/CustomNotification";
import { errorFormat, PERMS } from "helpers";
import styles from "./mapper-list.module.scss";
import CustomPopconfirm from "components/form/custom-pop-confirm/CustomPopConfirm";
import CustomDropDown from "components/custom-dropdown/CustomDropDown";
import CustomMenu from "components/custom-menu/CustomMenu";
import CustomButton from "components/custom-button/CustomButton";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import {
  useGetCategoriesTreeQuery,
  useGetCategoryPillarsMappingListQuery,
  useLazyGetCategoryPillarsMappingListQuery,
  GetCategoryPillarMapping,
} from "graphql/_generated/graphql";
import MapperAdd from "../mapper-add/MapperAdd";
import { CONFIRM_MODAL_CONFIG } from "helpers/constants";
import { useDeleteCategoryMapping } from "../hooks/useDeleteMapper";
import { useActivationMapper } from "../hooks/useActivationMapper";
import { Formik } from "formik";
import { Form } from "formik-antd";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { usePillarIdAndName } from "hooks";
import CustomTreeSelect from "components/form/custom-tree-select/CustomTreeSelect";
import { useErrorHandler } from "hooks/useErrorHandler";

interface MapperListProps {}
interface MenuParams {
  key: string;
  id: string;
  record: DefaultRecordType;
}

const MapperList: React.FunctionComponent<MapperListProps> = (props) => {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [columnsData, setColumnsData] = useState<any>();
  const [filtersData, setFiltersData] = useState<any>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [selectedMapper, setSelectedMapper] = useState<any>(undefined);

  //Hooks
  const { pillarsData } = usePillarIdAndName();
  const { handleActivationDeactivation } = useActivationMapper();
  const { refetch, isFetching } = useGetCategoryPillarsMappingListQuery();
  const { handleDeleteCategoryMapping } = useDeleteCategoryMapping();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const [getAll] = useLazyGetCategoryPillarsMappingListQuery();
  const { data: categoriesData } = useGetCategoriesTreeQuery(
    {
      pillarId: null,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const { handleError } = useErrorHandler();

  const handleMenu = ({ key, id, record }: MenuParams) => {
    if (key === "edit") {
      handleEdit(record);
    } else if (key === "delete") {
      Modal.confirm({
        ...CONFIRM_MODAL_CONFIG,
        title: t("warning"),
        content: (
          <>
            <div>{t("associations.delete-warning")}</div>
          </>
        ),
        onOk: async () => {
          try {
            await handleDeleteCategoryMapping(id);
            refetch();
          } catch (error) {
            handleError(error as string | object);
          }
        },
      });
    }
  };

  const handleEdit = (record: any) => {
    setSelectedMapper(record);
    setVisible(true);
  };

  const handleActivation = async (id: string) => {
    try {
      await handleActivationDeactivation(id);
      refetch();
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const handleClickNew = () => {
    setSelectedMapper(undefined);
    setVisible(true);
  };

  useEffect(() => {
    fetchDataFromApi(getAll, setColumnsData, filtersData).catch((e) =>
      handleError(e),
    );
  }, [isFetching]);

  const columns: TableColumnsType<DefaultRecordType> = [
    {
      title: t("id"),
      key: "id",
      dataIndex: "id",
      sorter: {
        multiple: 1,
      },
      render: (_text: any, _record: any, index: number) =>
        (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: t("associations.categories-subcategories"),
      key: "category",
      dataIndex: "category",
      render: (category: any, _record: any, index: number) => {
        return category.parent
          ? `${category.parent.name}/ ${category.name}`
          : category.name;
      },
      sorter: {
        multiple: 1,
      },
    },
    {
      title: t("associations.pillars"),
      key: "pillars",
      dataIndex: "pillars",
      render: (data: any, _record: any, index: number) =>
        data?.map((pillar: any) => pillar.title).join(", "),
    },
    {
      title: t("associations.activation"),
      dataIndex: "id",
      key: "id",
      align: "center" as "center",
      render: (id: string, { isActive }) => (
        <CheckPermissions permission={PERMS.TOGGLE_AVATAR_ACTIVATION}>
          <CustomPopconfirm
            onConfirm={() => handleActivation(id)}
            title={
              isActive
                ? t("associations.confirm-deactivate")
                : t("associations.confirm-activate")
            }
          >
            <Switch
              className="custom-switch"
              defaultChecked
              checked={isActive}
            />
          </CustomPopconfirm>
        </CheckPermissions>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "Actions",
      align: "center",
      render: (id: string, record: DefaultRecordType) => (
        <>
          <CustomDropDown
            overlay={
              <CustomMenu
                items={[
                  { label: t("associations.edit"), key: "edit" },
                  { label: t("associations.delete"), key: "delete" },
                ]}
                onClick={({ key, keyPath }) => handleMenu({ key, id, record })}
              />
            }
          >
            <CustomButton type="link" className="link-btn">
              <MoreOutlined />
            </CustomButton>
          </CustomDropDown>
        </>
      ),
    },
  ];

  return (
    <div>
      <CustomTable
        filters={{
          handleCallback: async (filtersData: any) => {
            await fetchDataFromApi(getAll, setColumnsData, filtersData);
            setPageSize(filtersData?.pagination?.offset?.pageSize);
            setCurrentPage(filtersData?.pagination?.offset?.page);
            setFiltersData(filtersData);
          },
          status: {
            applyAs: "and",
            key: "isActive",
            comparison: "Equal",
          },
          manualFilters: [
            {
              key: "pillars.id",
              comparison: "Equal",
              applyAs: "and",
              render: (onChangeCallback) => {
                return (
                  <Formik initialValues={{ company: null }} onSubmit={() => {}}>
                    {({ setFieldValue, values }) => (
                      <Form>
                        <CustomSelectField
                          allowClear
                          marginBtm={false}
                          name="pillar.id"
                          className="filter-select"
                          placeholder={t("select-pillar")}
                          options={pillarsData}
                          onChange={onChangeCallback}
                        />
                      </Form>
                    )}
                  </Formik>
                );
              },
            },
            {
              key: "category.id",
              comparison: "Equal",
              applyAs: "and",
              render: (onChangeCallback) => {
                return (
                  <Formik initialValues={{ company: null }} onSubmit={() => {}}>
                    {({ setFieldValue, values }) => (
                      <Form>
                        <CustomTreeSelect
                          noMargin
                          allowClear
                          showArrow
                          treeNodeFilterProp="name"
                          treeLine={true && { showLeafIcon: false }}
                          fieldNames={{
                            label: "name",
                            value: "id",
                          }}
                          className="filter-select"
                          name="category.id"
                          placeholder={t("select-category")}
                          treeData={categoriesData?.getCategoriesTree}
                          onChange={onChangeCallback}
                        />
                      </Form>
                    )}
                  </Formik>
                );
              },
            },
          ],
        }}
        totalItems={columnsData?.metadata?.total}
        loading={isLoading || isFetching}
        columns={columns}
        permission={PERMS.ADD_NEW_CATEGORY}
        onClick={handleClickNew}
        btnText={t("associations.add-new")}
        dataSource={columnsData?.data}
        rowKey="id"
      />
      <MapperAdd
        visible={visible}
        editId={selectedMapper?.id}
        setVisible={setVisible}
        setCategoryMapper={setSelectedMapper}
        categoryMapper={selectedMapper}
      />
    </div>
  );
};

export default MapperList;
