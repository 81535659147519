import { Tabs, TabsProps } from "antd";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import "./CustomTabs.module.scss";
interface CustomTabsProps extends TabsProps {
  active?: string;
}

const CustomTabs: React.FunctionComponent<CustomTabsProps> = (props) => {
  const { items } = props;
  const navigate = useNavigate();
  return (
    <Tabs
      className="custom-tabs"
      onChange={(activekey) => navigate(`#${activekey}-tab`)}
      items={items}
      {...props}
    />
  );
};

export default CustomTabs;
