import IQuestionBooleans from "features/assessment/types/IQuestionBooleans";

const booleansInitialObject: IQuestionBooleans = {
  SN: 1,
  id: "",
  text: "",
  score: undefined,
  emoji: undefined,
  tags: [],
  optionSortOrder: 0,
};

export default booleansInitialObject;
