import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  values: any;
};

const CustomShowDuration = ({ values }: Props) => {
  const { t } = useTranslation();
  const time = values;
  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;
  return (
    <div>
      <h3>
        <i>
          {t("duration")}: {minutes !== 0 && minutes + " " + t("minutes") + ":"}{" "}
          {seconds} {t("seconds")}
        </i>
      </h3>
    </div>
  );
};

export default CustomShowDuration;
