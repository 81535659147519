import {
  useAddNewTierMutation,
  MutationAddNewTierArgs,
  useGetTierListQuery,
} from "graphql/_generated/graphql";

export const useAddTier = () => {
  const [addTierFun, { isLoading }] = useAddNewTierMutation();

  const { refetch } = useGetTierListQuery();

  const handleAddTier = async (values: MutationAddNewTierArgs) => {
    try {
      await addTierFun({ create: values.create }).unwrap();
      refetch();
    } catch (error) {
      throw error;
    }
  };
  return { isLoading, handleAddTier };
};
