import { LeaderboardType } from "graphql/_generated/graphql";
import { useMemo } from "react";

export const useGetLeaderboardTypes = () => {
  const leaderboardTypeMap: Record<string, string> = {
    Company: "Company Leaderboard",
    Individual: "Individual Leaderboard",
    Team: "Team Leaderboard",
  };
  const leaderboardTypes = useMemo(
    () =>
      Object.keys(LeaderboardType).map((key) => ({
        label: leaderboardTypeMap[key],
        value: LeaderboardType[key as keyof typeof LeaderboardType],
      })),
    [LeaderboardType],
  );
  return {
    leaderboardTypes,
  };
};
