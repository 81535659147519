export const ChallengeValidationsData = [
  {
    id: 1,
    name: "Minimum Age",
  },
  {
    id: 2,
    name: "Maximum Age",
  },
  {
    id: 3,
    name: "Gender",
  },
  {
    id: 4,
    name: "Location",
  },
];

export const ChallengeGender = [
  {
    id: "Male",
    name: "Male",
  },
  {
    id: "Female",
    name: "Female",
  },
];
