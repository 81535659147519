import { ErrorMessage } from "formik";
import React, { MouseEventHandler, useCallback } from "react";

import Select, {
  components,
  GroupBase,
  MultiValueGenericProps,
  MultiValueProps,
  OnChangeValue,
  Props,
} from "react-select";
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortEndHandler,
  SortableHandle,
} from "react-sortable-hoc";
import { useErrorTranslation } from "validations/schema/common/useErrorTranslation";
import classes from "./CustomSortableSelect.module.scss";
import { MultiSelectSortProps, Option } from "./CustomSortableSelectTypes";

function arrayMove<T>(array: readonly T[], from: number, to: number) {
  const slicedArray = array.slice();
  slicedArray.splice(
    to < 0 ? array.length + to : to,
    0,
    slicedArray.splice(from, 1)[0],
  );
  return slicedArray;
}

const SortableMultiValue = SortableElement((props: MultiValueProps<Option>) => {
  const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { ...props.innerProps, onMouseDown };
  return <components.MultiValue {...props} innerProps={innerProps} />;
});

// const SortableMultiValueLabel = SortableHandle(
//   (props: MultiValueGenericProps) => <components.MultiValueLabel {...props} />,
// );

const SortableMultiValueLabel = SortableHandle(
  (props: MultiValueGenericProps<Option, true, GroupBase<Option>>) => (
    <components.MultiValueLabel {...props} />
  ),
) as React.ComponentType<
  MultiValueGenericProps<Option, true, GroupBase<Option>>
>;

const SortableSelect = SortableContainer(Select) as React.ComponentClass<
  Props<Option, true> & SortableContainerProps
>;

const defaultStyles = {
  control: (provided: any) => ({
    ...provided,
    minHeight: "3rem",
    background: "#fff",
    border: "1px solid rgba(128, 128, 128, 0.16)",
    borderRadius: "0.75rem",
    fontFamily: "'Source Sans Pro', sans-serif",
    fontWeight: 400,
    fontSize: "0.875rem",
  }),
  multiValue: (provided: any) => ({
    ...provided,
    zIndex: 9999,
  }),
};

export const MultiSelectSort = ({
  marginBtm = true,
  label,
  showEmptyLabel = false,
  subLabel,
  options,
  selected,
  setFieldValue,
  name,
  isDisabled = false,
  styles,
}: MultiSelectSortProps) => {
  const { errorT } = useErrorTranslation();

  const onChange = useCallback(
    (selectedOptions: OnChangeValue<Option, true>) => {
      setFieldValue(name, selectedOptions);
    },
    [name, setFieldValue],
  );

  const onSortEnd: SortEndHandler = useCallback(
    ({ oldIndex, newIndex }) => {
      const newValue = arrayMove(selected, oldIndex, newIndex);
      setFieldValue(name, newValue);
    },
    [selected, name, setFieldValue],
  );

  return (
    <div className={marginBtm === false ? "" : classes["form-control"]}>
      {label && !showEmptyLabel && (
        <label className="label" htmlFor={name}>
          {label}
        </label>
      )}
      {showEmptyLabel ? <div className="empty-label" /> : null}

      {subLabel && (
        <label className={classes["subLabel"]} htmlFor={name}>
          {subLabel}
        </label>
      )}

      <SortableSelect
        useDragHandle
        axis="xy"
        onSortEnd={onSortEnd}
        distance={4}
        getHelperDimensions={({ node }) => node.getBoundingClientRect()}
        isMulti
        options={options}
        value={selected}
        onChange={onChange}
        components={{
          // @ts-ignore We're failing to provide a required index prop to SortableElement
          MultiValue: SortableMultiValue,
          MultiValueLabel: SortableMultiValueLabel,
        }}
        getOptionLabel={(option: Option) => option.label}
        getOptionValue={(option: Option) => option.id}
        closeMenuOnSelect={false}
        styles={{ ...defaultStyles, ...styles }}
        isDisabled={isDisabled}
      />

      <ErrorMessage
        name={name}
        render={(msg) => {
          return <div className="text-danger">{errorT(msg)}</div>;
        }}
      />
    </div>
  );
};
