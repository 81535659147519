import {
  GetAllStreaksQuery,
  useGetAllStreaksQuery,
} from "graphql/_generated/graphql";

export const useGetStreaks = () => {
  const { data, isFetching, refetch } = useGetAllStreaksQuery();

  const streaksData: NonNullable<GetAllStreaksQuery["getAllStreaks"]> =
    data?.getAllStreaks ?? [];

  return {
    streaksData,
    isLoading: isFetching,
    refetch,
  };
};
