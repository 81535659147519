import { customNotification } from "components/custom-notification/CustomNotification";
import { ClientCreate, Regions } from "graphql/_generated/graphql";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCompany } from "../useCompany";
import { useErrorHandler } from "hooks/useErrorHandler";
import { InviteCompanyProps } from "../invite-company/InviteCompany";
import { FormikHelpers } from "formik";
import { useGetCompanyConfig } from "./useGetCompanyConfig";

export const useInviteCompanyViewModel = ({
  setVisible,
}: InviteCompanyProps) => {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const { isLoading, inviteCompany } = useCompany();
  const [child, setChild] = useState<boolean>(false);
  const [parentRegionalKey, setParentRegionalKey] = useState<Regions>();
  const {
    companiesData,
    refetchCompanies,
    isLoadingClientsTree,
    isFetchingListWithoutChildren,
    refetchListWithoutChildren,
    isFetchingClientList,
    refetchClientList,
    rolesData,
    regionList,
  } = useGetCompanyConfig({ regionalKey: parentRegionalKey! });

  //this function will used specific for adding child companies
  const handleChange = (checked: boolean) => {
    setChild(checked);
  };

  const handleCancel = () => {
    setVisible(false);
    setChild(false);
  };

  const handleInviteCompany = async (
    values: ClientCreate,
    { resetForm }: FormikHelpers<ClientCreate>,
  ) => {
    if (!child) {
      values.parent = null;
    }
    try {
      await inviteCompany({ client: values });
      resetForm();
      setVisible(false);
      setChild(false);
      refetchCompanies();
      refetchClientList();
      refetchListWithoutChildren();
    } catch (error) {
      handleError(error as string | object);
    }
  };

  return {
    handleCancel,
    handleChange,
    child,
    handleInviteCompany,
    regionList,
    setParentRegionalKey,
    rolesData,
    isLoadingClientsTree,
    companiesData,
    isLoading,
    isFetchingClientList,
    isFetchingListWithoutChildren,
  };
};
