import { List, Pagination } from "antd";
import CustomModal from "components/custom-modal/CustomModal";
import DataTableFilters from "features/challenge/challenge-detail/DataTableFilters";
import styles from "features/rewards/css/UserStats.module.scss";
import { PaginationInput, TierUsersList } from "graphql/_generated/graphql";
import { useTranslation } from "react-i18next";
import UserItem from "./UserItem";

interface UserStatsDetailsProps {
  visible: boolean;
  data: TierUsersList | undefined;
  pagination: PaginationInput;
  currentPage: number;
  isFetching: boolean;
  onPaginationChange: (page: number, pageSize: number) => void;
  handleSearchInput: (value: string) => void;
  handleCancel: () => void;
  setCompanyId: React.Dispatch<React.SetStateAction<string>>;
}

const UserStatsDetails: React.FunctionComponent<UserStatsDetailsProps> = ({
  visible,
  data,
  pagination,
  currentPage,
  isFetching,
  onPaginationChange,
  handleSearchInput,
  handleCancel,
  setCompanyId,
}) => {
  const { t } = useTranslation();

  return (
    <CustomModal
      title={`${t("users")} (${data?.metadata.total ?? 0})`}
      onCancel={handleCancel}
      visible={visible}
      footer={null}
      width={720}
    >
      <DataTableFilters
        isCompanyFilter={true}
        setSelectedCompany={setCompanyId}
        handleSearchInput={handleSearchInput}
      />
      <div className={styles["desc-container"]}>
        <List
          dataSource={data?.data}
          loading={isFetching}
          renderItem={(item, index) => (
            <UserItem
              id={Number(
                `${
                  index + 1 + `${pagination?.offset ? pagination?.offset : 0}`
                }`,
              )}
              image={item.image ?? ""}
              name={item.userName}
              points={item.points}
              companyName={item.companyName}
              fullView={true}
            />
          )}
        />
      </div>
      <div className={"pagination-margin"}>
        <Pagination
          onChange={onPaginationChange}
          current={currentPage}
          pageSize={pagination?.limit ?? 0}
          showTotal={(total, range) => (
            <div>
              Showing&nbsp;
              <b>
                {range[0]}-{range[1]}
              </b>
              &nbsp;of <b>{total}</b>&nbsp; items
            </div>
          )}
          total={data?.metadata?.total || 0}
          responsive
          showSizeChanger={false}
        />
      </div>
    </CustomModal>
  );
};

export default UserStatsDetails;
