import { useLazyGetEmployeeDetailQuery } from "graphql/_generated/graphql";

export const useGetEmployeeDetails = () => {
  const [getEmployeeDetailsFun, { isFetching }] =
    useLazyGetEmployeeDetailQuery();

  const handleGetEmployeeDetails = async (id: string) => {
    try {
      const res = await getEmployeeDetailsFun({
        getEmployeeDetailId: id,
      }).unwrap();
      return res.getEmployeeDetail;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleGetEmployeeDetails,
    isLoading: isFetching,
  };
};
