import {
  convertTranslationArrToObj,
  convertTranslationObjToArr,
} from "helpers";
import {
  MutationUpdateCollectionArgs,
  useGetSpecialistConfigQuery,
  useLazyGetCollectionQuery,
  useUpdateCollectionMutation,
} from "graphql/_generated/graphql";
export const useUpdateCollection = () => {
  const { refetch } = useGetSpecialistConfigQuery();

  const [updateCollectionFun, { isLoading }] = useUpdateCollectionMutation();

  const [prevEditDataFun, { isLoading: prevDataLoading }] =
    useLazyGetCollectionQuery();

  //This function is responsible for updating the collection data
  const handleUpdateCollection = async (
    values: MutationUpdateCollectionArgs,
  ) => {
    try {
      let translations = await convertTranslationArrToObj(
        values.updateObj.translations,
      );

      //Here we are getting the collection id after updating the collection
      const collectionId: any = await updateCollectionFun({
        updateObj: {
          ...values.updateObj,
          contentIds: values?.updateObj?.contentIds?.map(
            (item: any) => item.id,
          ),
          translations,
        },
        updateCollectionId: values.id,
      }).unwrap();

      refetch();
    } catch (error) {
      throw error;
    }
  };

  //this function is responsible for fetching the specific collection data which collection id we will be provided
  const handleFetchEditCollectionData = async (id: string) => {
    try {
      const res = await prevEditDataFun({ getCollectionId: id }).unwrap();
      const translations = await convertTranslationObjToArr(
        res.getCollection?.translations,
      );
      return { ...res?.getCollection, translations };
    } catch (error) {
      throw error;
    }
  };

  return {
    isLoading: isLoading || prevDataLoading,
    handleUpdateCollection,
    handleFetchEditCollectionData,
  };
};
