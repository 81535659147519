import { useDeleteAssessmentCarousalMutation } from "graphql/_generated/graphql";

export const useDeleteAssessmentCarousal = () => {
  const [deleteAssessmentCarousalFun, { isLoading }] =
    useDeleteAssessmentCarousalMutation();

  const handleDeleteAssessmentCarousalFun = async (values: any) => {
    try {
      const res = await deleteAssessmentCarousalFun({
        carousalId: values.carousalId,
      }).unwrap();

      return res.deleteAssessmentCarousal;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleDeleteAssessmentCarousalFun,
    isLoadingDeleteAssessment: isLoading,
  };
};
