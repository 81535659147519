import { MutationAddNewAvatarArgs } from "graphql/_generated/graphql";
import { useAddAvatar } from "./add-avatar/useAddAvatar";
import { useUpdateAvatar } from "./add-avatar/useUpdateAvatar";
import { useActivationAvatar } from "./avatar-list/useActivationAvatar";

type useAvatar = () => {
  isLoading: boolean;
  handleAddAvatar: (values: MutationAddNewAvatarArgs) => Promise<void>;
  handleActivationDeactivationAvatar: (id: string) => Promise<boolean>;
};

export const useAvatar = () => {
  const { isLoading: addAvatarLoading, handleAddAvatar } = useAddAvatar();
  const { isLoading: activationLoading, handleActivationDeactivationAvatar } =
    useActivationAvatar();
  const {
    isLoading: updateAvatarLoading,
    handleUpdateAvatar,
    handleFetchEditAvatar,
  } = useUpdateAvatar();
  return {
    isLoading: addAvatarLoading || activationLoading || updateAvatarLoading,
    handleAddAvatar,
    handleActivationDeactivationAvatar,
    handleUpdateAvatar,
    handleFetchEditAvatar,
  };
};
