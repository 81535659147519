import { Spin } from "antd";

type Props = {};

const CustomLoader = (props: Props) => {
  return (
    <Spin
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    />
  );
};

export default CustomLoader;
