export const normalizeRewardKeys = (object: any) => {
  if (object.hasOwnProperty("companiesNameList")) {
    object.companiesNameList = object.companiesNameList
      .split(",")
      .map((item: string) => item.trim());
  }

  Object.keys(object).forEach(function (key) {
    var newKey = key.replace(/\s+/g, "").replace(/\*/g, "");
    if (object[key] && typeof object[key] === "object") {
      normalizeRewardKeys(object[key]);
    }
    if (key !== newKey) {
      object[newKey] = object[key];
      delete object[key];
    }
  });

  return object;
};
