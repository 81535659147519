import * as Yup from "yup";
import * as Validations from "validations/schema/common/validations";

export const assessmentTypeSchema = Yup.object().shape({
  name: Validations.requiredFieldWithoutSpaces("name"),
  answerTypes: Validations.requiredArrayOfStringValidation("answer-type"),
  mandatory: Validations.requiredBooleanValidation("option"),
  pillars: Validations.requiredBooleanValidation("option"),
  categories: Validations.requiredBooleanValidation("option"),
  grouping: Validations.requiredBooleanValidation("option"),
  subQuestions: Validations.requiredBooleanValidation("option"),
  weightsOnTags: Validations.requiredBooleanValidation("option"),
  weightsOnAnswers: Validations.requiredBooleanValidation("option"),
  expiryDate: Validations.requiredBooleanValidation("option"),
  contentAssociation: Validations.requiredBooleanValidation("option"),
  contentTypes: Yup.array().when("contentAssociation", {
    is: true,
    then: Validations.requiredArrayOfStringValidation("content-type"),
  }),
  scores: Validations.requiredBooleanValidation("option"),
  notifications: Validations.requiredBooleanValidation("option"),
  randomTrigger: Validations.requiredBooleanValidation("option"),
  answerComments: Validations.requiredBooleanValidation("option"),
  emoji: Validations.requiredBooleanValidation("option"),
  isActive: Validations.requiredBooleanValidation("option"),
  color: Validations.required("color"),
});
