export const HabitSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2595 12C15.9926 12 15.7605 11.8147 15.6968 11.5512C15.1821 9.40478 13.3694 7.85423 11.1839 7.61953V8.27893C11.1839 8.69421 10.9413 9.0642 10.5517 9.24361C10.1279 9.43948 9.63463 9.3689 9.29132 9.06479L6.35779 6.46251C6.13084 6.26016 6 5.9737 6 5.67665C6 5.3796 6.13084 5.09313 6.35779 4.89079L9.29132 2.2885C9.63463 1.9844 10.1279 1.91381 10.5517 2.10969C10.9413 2.28909 11.1839 2.65908 11.1839 3.07436V3.79082C14.4399 4.08081 17 6.79426 17 10.0883C17 10.5647 16.9404 11.0565 16.8223 11.5506C16.7592 11.8147 16.527 12 16.2595 12Z"
        fill="#303030"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.74047 9C4.00737 9 4.23953 9.18529 4.30321 9.44881C4.81789 11.5952 6.63058 13.1458 8.8161 13.3805V12.7211C8.8161 12.3058 9.05868 11.9358 9.44832 11.7564C9.87211 11.5605 10.3654 11.6311 10.7087 11.9352L13.6422 14.5375C13.8692 14.7398 14 15.0263 14 15.3234C14 15.6204 13.8692 15.9069 13.6422 16.1092L10.7087 18.7115C10.3654 19.0156 9.87211 19.0862 9.44832 18.8903C9.05868 18.7109 8.8161 18.3409 8.8161 17.9256V17.2092C5.56011 16.9192 3 14.2057 3 10.9117C3 10.4353 3.05963 9.9435 3.17774 9.4494C3.24084 9.18529 3.473 9 3.74047 9Z"
        fill="#303030"
      />
    </svg>
  );
};
