import { Input, InputProps } from "formik-antd";
import * as React from "react";
import styles from "./CustomTextField.module.scss";
import { ErrorMessage, useField } from "formik";
import { useErrorTranslation } from "validations/schema/common/useErrorTranslation";
import {
  MULTIPLE_ERRORS,
  PWD_VALIDATION_RULES,
} from "validations/schema/common/validationHelpers";
import CheckList from "./CheckList";
import { useTranslation } from "react-i18next";

interface CustomPasswordProps extends InputProps {
  label?: string;
  placeholder?: string;
  className?: string;
  hideError?: boolean;
  marginBtm?: boolean;
  showEmptyLabel?: boolean;
  showRules?: boolean;
}

const CustomPassword: React.FunctionComponent<CustomPasswordProps> = (
  props,
) => {
  const {
    name,
    label,
    className,
    type,
    marginBtm = true,
    showEmptyLabel,
    showRules,
    hideError,
    ...textFieldProps
  } = props;

  const { t } = useTranslation();
  const { errorT } = useErrorTranslation();
  const [field, meta, helpers] = useField(name);

  const errorCheck = meta.error?.startsWith(MULTIPLE_ERRORS)
    ? (meta.error as string)
    : errorT(meta.error as string);

  const PasswordRules = [
    {
      id: PWD_VALIDATION_RULES.LEN,
      title: t("between-characters"),
    },
    {
      id: PWD_VALIDATION_RULES.UC,
      title: t("include-uppercase-character"),
    },
    {
      id: PWD_VALIDATION_RULES.LC,
      title: t("include-lowercase-character"),
    },
    {
      id: PWD_VALIDATION_RULES.NUM,
      title: t("include-number"),
    },
    {
      id: PWD_VALIDATION_RULES.SYM,
      title: t("include-special-character"),
    },
  ];
  const hasMultipleErrors = errorCheck?.startsWith(MULTIPLE_ERRORS);
  const failedRules = hasMultipleErrors
    ? errorCheck?.replace(MULTIPLE_ERRORS, "").split(",")
    : null;

  return (
    <div className={marginBtm ? styles["form-control"] : ""}>
      {label && !showEmptyLabel && (
        <label className={styles["label"]} htmlFor={name}>
          {label}
        </label>
      )}
      {showEmptyLabel ? <div className="empty-label" /> : null}
      <Input.Password
        name={name}
        placeholder={props.placeholder}
        className={className ? className : "from-input"}
        {...textFieldProps}
      />
      {showRules && (
        <CheckList
          items={PasswordRules}
          failedItems={failedRules}
          isValid={field.value.length > 0 && !meta.error}
        />
      )}
      {!hideError && (
        <ErrorMessage
          name={name}
          render={(msg) => <div className="text-danger">{errorT(msg)}</div>}
        />
      )}
    </div>
  );
};

export default CustomPassword;
