import { useState } from "react";
import { ErrorMessage, useField } from "formik";
import { PlusCircleFilled } from "@ant-design/icons";
import Picker from "@emoji-mart/react";
import CustomButton from "../../custom-button/CustomButton";
import CustomModal from "../../custom-modal/CustomModal";
import styles from "./CustomEmojiPicker.module.scss";
import { Space } from "antd";
import { useErrorTranslation } from "validations/schema/common/useErrorTranslation";

interface EmojiPickerProps {
  name: string;
  label?: string;
  subLabel?: string;
  helpers?: any;
  handleChange?: (value: string) => void;
  customValue?: string | null;
  isDisabled?: boolean;
  error?: string;
  hideError?: boolean;
}

const EmojiPicker: React.FunctionComponent<EmojiPickerProps> = (props) => {
  const [field, meta, helpers] = useField(props);

  const { errorT } = useErrorTranslation();

  const [visible, setVisible] = useState(false);

  field["value"] =
    props?.customValue !== undefined ? props?.customValue : field?.value;

  const codes =
    field.value &&
    field?.value?.split("-")?.map((c: string) => parseInt(c, 16));

  const emoji = codes && String.fromCodePoint(...codes);
  const error = errorT(props?.error || "");

  // console.log("error:>", error);
  // console.log("props name:>", props?.name);

  return (
    <div>
      <Space className={styles["emoji-picker-formGroup"]}>
        {props?.label && (
          <label className={styles["label"]}>{props?.label}</label>
        )}

        {props?.subLabel && (
          <label className="subLabel">{props?.subLabel}</label>
        )}

        <CustomButton
          disabled={props?.isDisabled}
          className="emoji-btn"
          onClick={() => setVisible(true)}
        >
          {(field?.value && <span>{emoji}</span>) ?? (
            <PlusCircleFilled style={{ fontSize: "1rem" }} />
          )}
        </CustomButton>
      </Space>

      <CustomModal
        width={425}
        title="Select Emoji"
        footer={false}
        visible={visible}
        onCancel={() => setVisible(false)}
      >
        <Picker
          onEmojiSelect={(emoji: any) => {
            setVisible(false);
            helpers.setValue(emoji.unified);
            if (props?.handleChange) {
              props.handleChange(emoji.unified);
            }
          }}
        />
        <ErrorMessage
          name={props?.name}
          render={(msg) => {
            return <div className="text-danger">{errorT(msg)}</div>;
          }}
        />
      </CustomModal>

      <div className={styles["error-msg"]}>
        {/* {!props.error && ( */}
        {/* {!props.hideError && (
          <ErrorMessage
            name={props?.name}
            render={(msg) => <div className="text-danger">{errorT(msg)}</div>}
          />
        )} */}
        {/* {!field.value ? (
          <div className="text-danger">Emoji is required.</div>
        ) : null} */}
      </div>
    </div>
  );
};

export default EmojiPicker;
