/**
 * Sleep function for delay.
 *
 * @param ms number
 * @returns Promise
 */
export const Sleep = (ms: number = 500) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export default Sleep;
