import {
  MutationAddEapNumberArgs,
  useAddEapNumberMutation,
} from "graphql/_generated/graphql";

interface AddEAPResult {
  isLoading: boolean;
  handleAddNewEAP: (values: MutationAddEapNumberArgs) => Promise<void>;
}

export const useAddEAP = (): AddEAPResult => {
  const [addNewEAPFun, { isLoading }] = useAddEapNumberMutation();

  // This function is responsible for adding the EAP
  const handleAddNewEAP = async (values: MutationAddEapNumberArgs) => {
    try {
      const { isGlobal, number, countryCode } = values.create;
      await addNewEAPFun({
        create: { isGlobal, number, countryCode },
      }).unwrap();
    } catch (error) {
      throw error;
    }
  };

  return {
    isLoading,
    handleAddNewEAP,
  };
};
