import { Tooltip } from "antd";
import { BronzeMedalSvg, GoldMedalSvg, SilverMedalSvg } from "assets/icons";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import CustomDivider from "components/custom-divider/CustomDivider";
import styles from "features/challenge/css/ActivityDetail.module.scss";
import {
  ChallengeType,
  ProgressStatus,
  Trackable,
} from "graphql/_generated/graphql";
import { useLoggedInUser } from "hooks/useLoggedInUser";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { ChallengeGoalTypes } from "../data/ChallengeTypes";

interface ChallengeDescriptionProps {
  titleLeft?: string;
  titleRight?: string;
  data?: any;
  noSteps?: boolean;
  noRank?: boolean;
  isMedalShow?: boolean;
  setVisible?: any;
  isModalVisible?: boolean;
}

const ChallengeDataTable: FunctionComponent<ChallengeDescriptionProps> = ({
  titleLeft,
  titleRight,
  data,
  noSteps,
  noRank,
  isMedalShow,
  setVisible,
  isModalVisible,
}) => {
  const { isClient } = useLoggedInUser();
  const { t } = useTranslation();

  const medals: { [key: string]: any } = {
    1: <GoldMedalSvg />,
    2: <SilverMedalSvg />,
    3: <BronzeMedalSvg />,
  };

  const getChallengeType = (item: any) => {
    let data = "";
    if ([Trackable.Collection, Trackable.Content].includes(item?.type)) {
      if (item?.goalFilter?.value) {
        data = item.goalFilter.value;
      } else {
        data = "Content Items";
      }
    } else {
      const unit = ChallengeGoalTypes[ChallengeType.Physical].goals.find(
        ({ id }: any) => id === item?.type,
      )?.unit;
      data = unit ?? "";
    }
    return data;
  };

  const getRank = (item: any) =>
    isMedalShow && medals?.[item?.rank] ? medals?.[item?.rank] : item?.rank;

  return (
    <>
      {isModalVisible ? null : (
        <div className={styles["data-table-heading"]}>
          <h3 className={styles["title-heading"]}>{titleLeft}</h3>
          <h3
            className={`${styles["title-heading"]} ${styles["see-more"]}`}
            onClick={() => setVisible(true)}
          >
            {titleRight}
          </h3>
        </div>
      )}
      <div
        className={`${styles["data-table-card"]} ${
          isModalVisible ? styles["modal-data"] : ""
        }`}
      >
        {data?.map((item: any, index: number) => (
          <>
            <div className={styles["data-table-row"]}>
              <div className={styles["card-left"]}>
                {item?.rank && !noRank ? (
                  <div className={styles["rank"]}>{getRank(item)}</div>
                ) : null}
                <CustomAvatar width={32} height={32} imgSrc={item?.userImage} />
                <div className={styles["participating-title-container"]}>
                  <Tooltip title={item?.userName}>
                    <h3
                      className={`${styles["participating-title"]} ${
                        index > 0 || !item.status
                          ? styles["title-semibold"]
                          : ""
                      }`}
                    >
                      {item?.userName}
                    </h3>
                  </Tooltip>

                  <p className={styles["participating-desc"]}>
                    {item?.status === ProgressStatus.InProgress
                      ? `${t("day")} ${
                          item.progressDays > item.challengeDuration
                            ? item.challengeDuration
                            : item.progressDays
                        } ${t("out-of")} ${item.challengeDuration}`
                      : item?.status === ProgressStatus.Done
                      ? t("goal-reached")
                      : item?.status === ProgressStatus.Failed
                      ? t("goal-not-reached")
                      : item?.status}
                  </p>
                </div>
              </div>
              {isModalVisible && !isClient ? (
                <p className={styles["participating-desc"]}>
                  {item?.companyName}
                </p>
              ) : null}
              {noSteps ? null : (
                <div className={styles["card-right"]}>
                  <p className={styles["participating-desc"]}>
                    {item?.progress}
                  </p>
                  <p className={styles["participating-desc"]}>
                    {getChallengeType(item)}
                  </p>
                </div>
              )}
            </div>
            <CustomDivider />
          </>
        ))}
      </div>
    </>
  );
};
export default ChallengeDataTable;
