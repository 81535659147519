export const BadgeSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_502_86035)">
        <path
          d="M13.0876 14.6426H6.91197C6.61292 14.6426 6.3368 14.4823 6.18843 14.2227L3.0932 8.80603C2.9468 8.54983 2.9468 8.23532 3.0932 7.97913L6.18843 2.56246C6.3368 2.30282 6.61292 2.14258 6.91197 2.14258H13.0876C13.3867 2.14258 13.6628 2.30282 13.8112 2.56246L16.9064 7.97913C17.0528 8.23532 17.0528 8.54983 16.9064 8.80603L13.8112 14.2227C13.6628 14.4823 13.3867 14.6426 13.0876 14.6426Z"
          fill="#303030"
        />
      </g>
      <g filter="url(#filter1_b_502_86035)">
        <path
          d="M6.55205 15.3573H13.4483C13.7456 15.3573 14.0205 15.1989 14.1695 14.9415L16.1287 11.5573L18.3659 11.2132C18.7217 11.1584 18.9748 11.5502 18.7786 11.8521L16.984 14.6129C16.971 14.633 16.9563 14.6519 16.94 14.6694L14.7667 17.0099C14.6878 17.0948 14.5772 17.1431 14.4613 17.1431H5.539C5.42314 17.1431 5.31251 17.0948 5.23367 17.0099L3.06032 14.6694C3.04405 14.6519 3.02933 14.633 3.0163 14.6129L1.22173 11.8521C1.02554 11.5502 1.27864 11.1584 1.63444 11.2132L3.87159 11.5573L5.83086 14.9415C5.97985 15.1989 6.25469 15.3573 6.55205 15.3573Z"
          fill="#303030"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_502_86035"
          x="-11.4166"
          y="-12.2574"
          width="42.8332"
          height="41.3"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.2" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_502_86035"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_502_86035"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_b_502_86035"
          x="-13.2467"
          y="-3.19199"
          width="46.4934"
          height="34.7346"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.2" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_502_86035"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_502_86035"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
