import * as React from "react";
import viwellLogo from "assets/images/viwell-logo.svg";
import Footer from "./Footer";
interface VerificationErrorProps {}

const VerificationError: React.FunctionComponent<VerificationErrorProps> = (
  props,
) => {
  return (
    <div className="container mx-auto px-4 bg-white py-[30px] md:py-[64px] md:max-w-[700px]">
      <div className="flex flex-col justify-between items-center min-h-[calc(100vh-64px)]  md:min-h-[calc(100vh-128px)]">
        <div className="text-center">
          <a
            href="https://viwell.com/"
            target="_blank"
            className="inline-block"
          >
            <img
              src={viwellLogo}
              alt="VIWELL Logo"
              className="w-24 h-5 md:w-[285px] md:h-[64px] mx-auto"
            />
          </a>
          <div className="my-[32px] md:my-[64px]">
            <h1 className="font-sf-pro-text font-bold text-base md:text-[32px]">
              Data Deletion Request Error
            </h1>
            <p className="font-sf-pro-text font-normal text-xs md:text-2xl mt-[8px] md:mt-[16px]">
              Oops! We couldn't delete your data at the moment. Please contact
              our support team for assistance.
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default VerificationError;
