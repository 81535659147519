// export const ifPermitted = (
//   operationName: string | string[],
//   component: React.ReactNode
// ) => {
//   const isPerm = checkIfPermitted(operationName);
//   if (isPerm) {
//     return component;
//   }
// };

// export const checkIfPermitted = (operationName: string | string[]) => {
//   if (typeof operationName === "object" && operationName.length) {
//     return operationName.some(
//       (r) =>
//         CONSTANTS.PERMISSIONS?.includes(r) || CONSTANTS.USERTYPE == "SuperAdmin"
//     );
//   } else {
//     return (
//       CONSTANTS.PERMISSIONS?.includes(operationName) ||
//       CONSTANTS.USERTYPE === "SuperAdmin"
//     );
//   }
// };

export const checkIfPermitted = (
  operationName: string | string[],
  permissionsArr: string[],
  userType: string
) => {
  if (userType == "SuperAdmin") {
    return true;
  }
  if (typeof operationName === "object" && operationName.length) {
    return operationName.some((r) => permissionsArr?.includes(r));
  } else if (typeof operationName === "string") {
    return permissionsArr?.includes(operationName);
  }
};

export function checkIfPermittedRoutes(
  routesArr: any[],
  permissionsArr: string[],
  userType: string
) {
  const dummyArr: any = [];
  routesArr.map((item: { permission: string }) => {
    if (!item.permission) {
      dummyArr.push(item);
    }
    if (permissionsArr?.includes(item.permission) || userType == "SuperAdmin") {
      dummyArr.push(item);
    }
  });
  return dummyArr;
}
