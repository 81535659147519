// import CustomButton from "app/components/common/custom-button/CustomButton";
// import DashboardLayout from "app/components/dashboard/dashboard-layout/DashboardLayout";
// import React, { useState } from "react";
// import AddRange from "../add-range/AddRange";
// import RangesList from "../ranges-list/RangesList";
// import styles from "./Ranges.module.scss";
// interface RangesProps {}

// const Ranges: React.FunctionComponent<RangesProps> = (props) => {
//   const [visible, setVisible] = useState<boolean>(false);
//   const [editId, setEditId] = useState<string | null>(null);
//   return (
//     <>
//       <DashboardLayout openKey={["Reports"]}>
//         <div className={styles["ranges"]}>
//           <CustomButton
//             className="primary-btn"
//             onClick={() => setVisible(true)}
//           >
//             Add New Range
//           </CustomButton>
//         </div>
//         <RangesList />
//       </DashboardLayout>
//       <AddRange
//         visible={visible}
//         setVisible={setVisible}
//         editId={editId}
//         setEditId={setEditId}
//       />
//     </>
//   );
// };

export default () => {}; // Ranges;
