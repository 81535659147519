import { FormikHelpers } from "formik";
import {
  AddNewMeasurementMutation,
  GetMeasurementQuery,
  MeasurementCreate,
  MeasurementList,
  UpdateMeasurementMutation,
  useGetMeasurementConfigQuery,
  useGetMeasurementListQuery,
  useLazyGetMeasurementListQuery,
} from "graphql/_generated/graphql";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMeasurement } from "./useMeasurement";
import customNotification from "components/custom-notification/CustomNotification";
import { convertTranslationArrToObj } from "helpers";

export type SubmitUnitResponse =
  | Omit<AddNewMeasurementMutation["addNewMeasurement"], "__typename">
  | Omit<UpdateMeasurementMutation["updateMeasurement"], "__typename">;

export const useMeasurementModel = () => {
  type UnitRecord = NonNullable<typeof unitsListData>[0];
  //Initializing States
  const [editId, setEditId] = useState<string | null>(null);
  const [isAddUnit, setIsAddUnit] = useState<boolean>(false);
  const [filtersData, setFiltersData] = useState<any>(null);
  const [columnsData, setColumnsData] = useState<MeasurementList>();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [unitData, setUnitData] =
    useState<GetMeasurementQuery["getMeasurement"]>();
  const [langVisibility, setLangVisibility] = useState(false);
  const [langCode, setLangCode] = useState<string>();
  const [isAddLanguage, setIsAddLanguage] = useState(false);
  const [unitRecord, setUnitRecord] = useState<UnitRecord>();

  const initialValues: MeasurementCreate = {
    unit: unitData?.unit ?? "",
    description: unitData?.description ?? "",
    translations: [],
  };

  //Hooks
  const { t } = useTranslation();
  const { isFetching, refetch } = useGetMeasurementListQuery();
  const { refetch: refetchUnitConfig } = useGetMeasurementConfigQuery();
  const [getAllMeasurementsDataFun] = useLazyGetMeasurementListQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const { handleError } = useErrorHandler();

  const {
    handleAddNewMeasurement,
    handleUpdateMeasurement,
    handleFetchMeasurementData,
    handleDeleteUnit,
    handleToggleUnit,
    isLoading: isLoadingAddUnit,
  } = useMeasurement();

  useEffect(() => {
    fetchDataFromApi(
      getAllMeasurementsDataFun,
      setColumnsData,
      filtersData,
    ).catch((e) => handleError(e));
  }, [isFetching, filtersData]);

  const filtersCallBack = async (filtersData: any) => {
    try {
      setPageSize(filtersData?.pagination?.offset?.pageSize);
      setCurrentPage(filtersData?.pagination?.offset?.page);
      setFiltersData(filtersData);
    } catch (error) {
      handleError(error as string | object);
    }
  };

  useEffect(() => {
    if (editId) {
      handleFetchMeasurementData(editId).then((data) => {
        const translationData = langCode && data?.translations?.[langCode];
        setUnitData({
          ...data,
          unit: isAddLanguage ? translationData?.unit : data?.unit,
          description: isAddLanguage
            ? translationData?.description
            : data?.description,
        });
      });
    }
  }, [editId]);

  const onSubmitUnit = async (
    values: MeasurementCreate,
    formikHelpers?: FormikHelpers<typeof values>,
  ) => {
    let res: SubmitUnitResponse;
    try {
      if (!editId && !isAddLanguage) {
        const { translations, ...restValues } = values;
        const convertedTranslations =
          translations && (await convertTranslationArrToObj(translations));
        res = await handleAddNewMeasurement({
          create: { ...restValues, translations: convertedTranslations },
        });
        customNotification("success", t("metric-added-successfully"));
      } else if (isAddLanguage && editId && langCode) {
        res = await handleUpdateMeasurement({
          id: editId,
          updateObj: {
            translations: {
              [langCode]: {
                unit: values.unit,
                description: values.description,
              },
            },
          },
        });
        customNotification("success", t("unit-translation-successfully"));
      } else {
        res = await handleUpdateMeasurement({ updateObj: values, id: editId });
        customNotification("success", t("unit-updated-successfully"));
      }
      formikHelpers?.resetForm();
      refetch();
      onCloseUnit();
      refetchUnitConfig();
      return res;
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const onDeleteUnit = async (id: string) => {
    try {
      const res = await handleDeleteUnit(id);
      customNotification("success", t("unit-deleted-successfully"));
      refetch();
      return res;
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const onToggleUnit = async (id: string) => {
    try {
      const isUnitActive = await handleToggleUnit(id);
      customNotification(
        "success",
        isUnitActive
          ? t("unit-activated-successfully")
          : t("unit-deactivated-successfully"),
      );
      refetch();
      return isUnitActive;
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const unitsListData = columnsData?.data?.map((item) => {
    return (
      {
        ...item,
        hasChildren: true,
        children:
          item?.translations && Object.keys(item?.translations).length
            ? Object.keys(item?.translations).map((record: string) => {
                return {
                  ...item,
                  unit: item?.translations?.[record]?.unit,
                  description: item?.translations?.[record]?.description,
                  language: record,
                };
              })
            : null,
      } ?? []
    );
  });

  const onPressAddUnit = () => {
    setIsAddUnit(true);
  };

  const onCloseUnit = () => {
    setIsAddUnit(false);
    setEditId(null);
    setUnitData(undefined);
    setIsAddLanguage(false);
    setUnitRecord(undefined);
    setLangCode(undefined);
  };

  const onUpdateUnit = (id: string) => {
    setEditId(id);
    setIsAddUnit(true);
  };

  const onPressAddLang = (id: string, record: UnitRecord) => {
    setEditId(id);
    setLangVisibility(true);
    setIsAddLanguage(true);
    setUnitRecord(record);
  };

  const onPressUpdateLanguage = (id: string, record: UnitRecord) => {
    setEditId(id);
    setLangCode(record.language);
    setIsAddLanguage(true);
    setIsAddUnit(true);
  };

  const onLanguageSubmit = (
    values: { language: string },
    actions?: FormikHelpers<typeof values>,
  ) => {
    setLangCode(values?.language);
    setLangVisibility(false);
    actions?.resetForm();
    setIsAddUnit(true);
  };

  const onCloseLangDialog = () => {
    setLangVisibility(false);
    setIsAddLanguage(false);
    setUnitRecord(undefined);
  };

  return {
    isAddUnit,
    langVisibility,
    unitRecord,
    editId,
    columnsData,
    unitsListData,
    pageSize,
    currentPage,
    loading: { isLoading, isLoadingAddUnit },
    initialValues,
    onSubmitUnit,
    filtersCallBack,
    onPressAddUnit,
    onPressAddLang,
    onCloseUnit,
    onUpdateUnit,
    onLanguageSubmit,
    onCloseLangDialog,
    onPressUpdateLanguage,
    onDeleteUnit,
    onToggleUnit,
  };
};
