import "antd-css-utilities/utility.min.css";
import "assets/css/index.css";
import { CONFIG } from "helpers/constants";
import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./i18n";
import { initSentry } from "./sentry";
import * as serviceWorker from "./serviceWorker";
import { store } from "./store/store";
const ENV = process.env.REACT_APP_ENV || CONFIG.ENV;

if (ENV && ENV !== "dev") {
  initSentry();
}

let persistor = persistStore(store);

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Suspense fallback={"Loading"}>
          <App />
        </Suspense>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  // </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
