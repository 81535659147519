/**
 * Judging criteria for a question's place in an assessment.
 * A 'hierarchy' field, with this Enum values, is placed under a question.
 */
enum EnumQuestionHierarchy {
  PILLAR_QUESTION = "PILLAR_QUESTION",
  SUB_QUESTION = "SUB_QUESTION",
}

export default EnumQuestionHierarchy;
