import Icon, { MoreOutlined } from "@ant-design/icons";
import { Space, Switch, Tag } from "antd";
import type { ColumnsType } from "antd/lib/table";
import CustomButton from "components/custom-button/CustomButton";
import CustomDropDown from "components/custom-dropdown/CustomDropDown";
import customNotification from "components/custom-notification/CustomNotification";
import CustomTable from "components/custom-table/CustomTable";
import CustomPopconfirm from "components/form/custom-pop-confirm/CustomPopConfirm";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import { errorFormat } from "helpers";
import { getTypeIcon } from "helpers/getTypeIcon";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import {
  GetAssessmentTypesQuery,
  AssessmentType,
  useGetAssessmentTypesQuery,
  useLazyGetAssessmentTypesQuery,
  useToggleAssessmentTypeMutation,
} from "graphql/_generated/graphql";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import TypeSettings from "../../features/assessment/type-settings/TypeSettings";
import styles from "features/assessment/css/AssessmentTypes.module.scss";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import { useErrorHandler } from "hooks/useErrorHandler";

interface AssessmentTypesProps {}

const AssessmentTypes: React.FunctionComponent<AssessmentTypesProps> = (
  props,
) => {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [editId, setEditId] = useState<string | null>(null);
  const [assessmentType, setAssessmentType] = useState<any>(null);
  const [visible, setVisible] = useState(false);
  const [filtersData, setFiltersData] = useState<any>(null);
  const [columnsData, setColumnsData] = useState<any>();

  const { isFetching, refetch } = useGetAssessmentTypesQuery();
  const [assessmentTypeToggleFun, { isLoading: isToggleLoading }] =
    useToggleAssessmentTypeMutation();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const [getAssessmentsTypeFun] = useLazyGetAssessmentTypesQuery();
  const { handleError } = useErrorHandler();

  const handleMenu = ({ key, id, record }: any) => {
    if (key === "edit-assessment-type") {
      handleEditAssessment(id, record);
    }
  };

  const handleEditAssessment = (id: string, record: any) => {
    setEditId(id);
    setVisible(true);
    setAssessmentType(record);
  };

  useEffect(() => {
    fetchDataFromApi(getAssessmentsTypeFun, setColumnsData, filtersData).catch(
      (e) => handleError(e),
    );
  }, [isFetching]);

  const columns: ColumnsType<AssessmentType> = [
    {
      title: t("id"),
      key: "id",
      render: (_text, _record, index) =>
        (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: t("name"),
      key: "name",
      dataIndex: "name",
      render: (_text, record, index) => {
        const typeIcon = getTypeIcon(record.name);
        return (
          <Space>
            <Icon component={typeIcon} />
            <span>{record.name}</span>
          </Space>
        );
      },
    },
    // {
    //   title: t("status"),
    //   key: "isActive",
    //   dataIndex: "isActive",
    //   align: "center",
    //   render: (_text, record, _index) => {
    //     return record?.isActive ? (
    //       <Tag className="active-tag">{t("active")}</Tag>
    //     ) : (
    //       <Tag className="de-active-tag">{t("deactivated")}</Tag>
    //     );
    //   },
    // },
    // {
    //   title: t("activation"),
    //   key: "isActive",
    //   dataIndex: "isActive",
    //   align: "center",
    //   render: (_text, record, _index) => {
    //     return (
    //       <CustomPopconfirm
    //         onConfirm={async () => {
    //           try {
    //             const res = await assessmentTypeToggleFun({
    //               assessmentTypeId: record.id,
    //             }).unwrap();
    //             customNotification(
    //               "success",
    //               res.ToggleAssessmentType
    //                 ? t("assessment-type-activated-success")
    //                 : t("assessment-type-deactivated-success"),
    //             );
    //             refetch();
    //           } catch (error) {
    //             customNotification("error", errorFormat(error));
    //           }
    //         }}
    //         title={
    //           record.isActive
    //             ? t("deactivate-assessment-type")
    //             : t("activate-assessment-type")
    //         }
    //       >
    //         <Switch
    //           className="custom-switch"
    //           defaultChecked
    //           checked={record?.isActive}
    //         />
    //       </CustomPopconfirm>
    //     );
    //   },
    // },
    {
      title: t("action"),
      key: "id",
      dataIndex: "id",
      align: "right",
      render: (id: string, record) => (
        <CustomDropDown
          menu={{
            items: [
              { key: "edit-assessment-type", label: t("edit-assessment-type") },
            ],
            onClick: ({ domEvent, key, keyPath }) =>
              handleMenu({ domEvent, key, keyPath, id, record }),
          }}
        >
          <CustomButton type="link" className="link-btn">
            <MoreOutlined />
          </CustomButton>
        </CustomDropDown>
      ),
    },
  ];

  return (
    <DashboardLayout openKey={["Assessment"]}>
      <>
        <Breadcrumbs
          data={[
            {
              label: t("assessments"),
            },
            {
              label: t("assessment-types-title"),
            },
          ]}
        />

        <CustomTable
          filters={{
            handleCallback: async (filtersData: any) => {
              await fetchDataFromApi(
                getAssessmentsTypeFun,
                setColumnsData,
                filtersData,
              );
              setPageSize(filtersData?.pagination?.offset?.pageSize);
              setCurrentPage(filtersData?.pagination?.offset?.page);
              setFiltersData(filtersData);
            },
          }}
          columns={columns}
          headerTitle={t("assessment-types-title")}
          dataSource={columnsData}
          loading={isLoading || isToggleLoading}
        />
      </>
      <TypeSettings
        visible={visible}
        setVisible={setVisible}
        setEditId={setEditId}
        editId={editId}
        assessmentType={assessmentType}
      />
    </DashboardLayout>
  );
};

export default AssessmentTypes;
