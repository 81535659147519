import { Form, Formik } from "formik";
import { HabitCreate } from "graphql/_generated/graphql";
import * as React from "react";
import {
  HabitState,
  useAddHabitViewModel,
} from "../hooks/useAddHabitViewModel";

import styles from "features/habits/css/HabitDetail.module.scss";
import { Space } from "antd";
import CustomButton from "components/custom-button/CustomButton";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import { useTranslation } from "react-i18next";
import { PERMS, renderHtmlContent } from "helpers";
import { EditOutlined } from "@ant-design/icons";
import ContainerHeading from "features/assessment/questions/ContainerHeading";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import CollapseAble from "features/assessment/questions/collapseables/CollapseAble";

interface HabitDetailProps {
  state: HabitState;
}

const HabitDetail: React.FunctionComponent<HabitDetailProps> = ({ state }) => {
  const { t } = useTranslation();
  const vm = useAddHabitViewModel(state);
  return (
    <Formik
      enableReinitialize
      initialValues={vm.initialValues}
      onSubmit={vm.onHabitSubmit}
    >
      <Form>
        <div className={styles["habit-header"]}>
          <div className={styles["habit-title"]}>
            {`${t("habit-details")} - ${t(state?.langCode)}`}
          </div>
          <Space size={12}>
            <CustomButton
              htmlType="reset"
              className="link-text-btn"
              onClick={vm.onPressCancel}
            >
              {t("close")}
            </CustomButton>
            <CheckPermissions permission={PERMS.UPDATE_HABIT}>
              <CustomButton
                className="light-btn"
                loading={vm.isLoading}
                icon={<EditOutlined />}
                onClick={vm.onPressUpdateHabit}
              >
                {t("update-habit")}
              </CustomButton>
            </CheckPermissions>
          </Space>
        </div>
        <div className={styles["content-container"]}>
          <CollapseAble
            defaultActiveKey={["description"]}
            headerKey="description"
            hideExtra
            expandIconPosition="end"
            header={<ContainerHeading text={t("name-desc")} />}
          >
            <div className={styles["habit-heading"]}>
              {vm.habitsData?.title}
            </div>
            <div className={styles["habit-description"]}>
              {renderHtmlContent(vm.habitsData?.description ?? "")}
            </div>
          </CollapseAble>
          <CollapseAble
            defaultActiveKey={["habit-options"]}
            headerKey="habit-options"
            hideExtra
            expandIconPosition="end"
            header={<ContainerHeading text={t("habit-option")} />}
          >
            <div className={styles["flex-row"]} id="area">
              <CustomSelectField
                name="pillarId"
                label={t("pillar")}
                placeholder={t("select-pillar")}
                options={vm.pillarsData?.options}
                fieldNames={{ label: "label", value: "id" }}
                disabled
              />
              <CustomSelectField
                name="unitId"
                label={t("goal-unit")}
                placeholder={t("select-goal-unit")}
                options={vm.unitsData?.options}
                fieldNames={{ label: "label", value: "id" }}
                disabled
              />
            </div>
            <div className={styles["flex-row"]}>
              <CustomTextField
                name="target"
                label={t("recommended-target")}
                type="number"
                placeholder={t("enter-recommended-target")}
                disabled
              />
              <CustomSelectField
                name="repetition"
                label={t("repetition")}
                placeholder={t("select-repetition")}
                disabled
                options={vm.repetitionOptions}
              />
            </div>
          </CollapseAble>
        </div>
      </Form>
    </Formik>
  );
};

export default HabitDetail;
