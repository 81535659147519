import LoggedInRoutes from "./LoggedInRoutes";
import LoggedOutRoutes from "./LoggedOutRoutes";
import OnboardingRoutes from "./OnboardingRoutes";
import PlaygroundRoutes from "./PlaygroundRoutes";

const routes = {
  loggedIn: [
    ...LoggedInRoutes,
    ...PlaygroundRoutes,
  ],
  loggedOut: LoggedOutRoutes,
  onBoarding: OnboardingRoutes,
};

export default routes;
