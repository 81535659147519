// export const MatrixGenerator = (rows: number, columns: number) => {};

/**
 * Generates matrix dimension dropdowns.
 * i.e. numberOfOptionsDropdown, numberOfTitlesDropdown
 *
 * @param total
 * @returns dropdown Array<Object>
 */
export const MatrixDropdownGenerator = (total: number): Array<Object> => {
  let dropdown: Array<Object> = [];

  for (let i = 1; i <= total; i++) {
    dropdown = [
      ...dropdown,
      {
        label: String(i),
        value: Number(i),
      },
    ];
  }

  return dropdown;
};
