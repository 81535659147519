import * as Yup from "yup";
import * as Validations from "./common/validations";

export const avatarSchema = Yup.object().shape({
  imgPath: Validations.image("image"),
  name: Validations.requiredFieldWithoutSpaces("title"),
  translations: Yup.array().of(
    Yup.object().shape({
      lang: Validations.required("language"),
      name: Validations.required("title"),
    })
  ),
});
