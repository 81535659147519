import { useTranslation } from "react-i18next";
import CustomModal from "components/custom-modal/CustomModal";
import React from "react";
import InviteSingleUser from "./invite-users/InviteSingleUser";

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  employeeId: string | undefined;
  employerId: string | undefined;
  selectedEmployee: any;
  setEmployeeId: React.Dispatch<React.SetStateAction<string | null>>;
};

export default function EditEmployee(props: Props) {
  const { t } = useTranslation();
  const handleCancel = () => {
    props.setVisible(false);
    props.setEmployeeId && props.setEmployeeId(null);
  };

  return (
    <CustomModal
      width={600}
      visible={props.visible}
      onCancel={handleCancel}
      footer={false}
      title={t("Edit Employee")}
    >
      <InviteSingleUser {...props} />
    </CustomModal>
  );
}
