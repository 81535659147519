import { MoreOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, Form, Select, Space, Switch, Tag } from "antd";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import CustomButton from "components/custom-button/CustomButton";
import CustomMenu from "components/custom-menu/CustomMenu";
import { customNotification } from "components/custom-notification/CustomNotification";
import CustomTable from "components/custom-table/CustomTable";
import CustomPopconfirm from "components/form/custom-pop-confirm/CustomPopConfirm";
import { cloneObject, errorFormat, PERMS } from "helpers";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import CustomTreeSelect from "components/form/custom-tree-select/CustomTreeSelect";
import { useCompany } from "features/company/useCompany";
import { Formik } from "formik";
import {
  useGetClientsTreeQuery,
  useGetEmployeeListQuery,
  useLazyGetDeletionRequestsQuery,
  useLazyGetEmployeeListQuery,
} from "graphql/_generated/graphql";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EditEmployee from "features/users/EditEmployee";
import { useRevokeRequest } from "features/users/hooks/useRevokeRequest";
import { useLoggedInUser } from "hooks/useLoggedInUser";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import styles from "features/users/css/UsersList.module.scss";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

const { Option } = Select;
type Props = { employerId?: string; companyChildren?: Array<any> };

const DeleteRequests = ({ employerId, companyChildren }: Props) => {
  const { t } = useTranslation();
  const [filtersData, setFiltersData] = useState<any>(null);
  const { isFetching, refetch } = useGetEmployeeListQuery();
  const [columnsData, setColumnsData] = useState<any>();
  const [getDeletionRequestsFun] = useLazyGetDeletionRequestsQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const { handleRevokeRequest } = useRevokeRequest();
  const [companyId, setCompanyId] = useState<string | undefined>(undefined);
  const { data } = useGetClientsTreeQuery();
  const { isSuperAdmin } = useLoggedInUser();
  const { handleError } = useErrorHandler();
  const region = useSelector((state: RootState) => state.auth.region);

  useEffect(() => {
    fetchData();
  }, [isFetching, region]);

  const fetchData = () => {
    fetchDataFromApi(getDeletionRequestsFun, setColumnsData, filtersData).catch(
      (e) => handleError(e),
    );
  };

  const columns = [
    {
      title: t("id"),
      key: "id",
      sorter: {
        multiple: 1,
      },
      render: (_text: any, record: any, index: number) => {
        return (currentPage - 1) * pageSize + index + 1;
      },
    },
    {
      title: t("name"),
      dataIndex: "firstName",
      key: "firstName",
      sorter: {
        multiple: 1,
      },
      render: (_text: any, record: any, index: number) => (
        <Space>
          {/* <CustomAvatar
          imgSrc={record.image ? record.image : record?.avatar?.imgPath}
        /> */}
          <div className="cut-text">
            <Space>
              {record.firstName}
              {record.lastName}
            </Space>
          </div>
        </Space>
      ),
    },
    {
      title: t("email"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("company"),
      dataIndex: "companyName",
      key: "companyName",
      render: (_text: any, record: any, index: number) =>
        record.employer.companyName,
    },
    // {
    //   title: t("department"),
    //   dataIndex: "department",
    //   key: "department",
    //   render: (_text: any, record: any, index: number) =>
    //     record.department?.name,
    // },
    {
      title: t("request-date"),
      dataIndex: "requestedAt",
      key: "requestedAt",
      sorter: {
        multiple: 1,
      },
      render: (createdAt: string, record: any) =>
        moment(record.deleteRequest.requestedAt).format(
          "MMMM Do YYYY, h:mm:ss a",
        ),
    },
    {
      title: t("reason"),
      dataIndex: "reason",
      key: "reason",
      render: (_text: any, record: any, index: number) =>
        record.deleteRequest.reason,
    },
    {
      title: t("remaining-days"),
      dataIndex: "id",
      key: "id",
      render: (createdAt: string, record: any) => {
        const deleteAt = moment(record.deleteRequest.deleteAt);
        const today = moment();
        const diff = deleteAt.diff(today, "days") || 0;
        return `${diff} ${t("days-left")}`;
      },
    },
    {
      width: "5%",
      align: "center" as "center",
      dataIndex: "id",
      key: "id",
      render: (id: string, { isProfileComplete }: any) => (
        <Dropdown
          overlay={
            <CustomPopconfirm
              onConfirm={async () => {
                try {
                  await handleRevokeRequest({ userId: id });
                  customNotification(
                    "success",
                    t("delete-request-cancelled-success"),
                  );
                  refetch();
                } catch (error) {
                  handleError(error as string | object);
                }
              }}
              title={t("confirm-cancel-delete-request")}
            >
              <CustomMenu
                items={[{ label: t("cancel-delete-request"), key: "Cancel" }]}
              />
            </CustomPopconfirm>
          }
        >
          <CustomButton type="link" className="link-btn">
            <MoreOutlined />
          </CustomButton>
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      <DashboardLayout openKey={["Users"]}>
        <div className={styles["users"]}>
          <Breadcrumbs
            data={[
              {
                label: "Users",
              },
              {
                label: t("delete-requests.title"),
              },
            ]}
          />
          <CustomTable
            rowKey="id"
            filters={{
              handleCallback: async (filtersData: any) => {
                setPageSize(filtersData?.pagination?.offset?.pageSize);
                setCurrentPage(filtersData?.pagination?.offset?.page);
                fetchDataFromApi(
                  getDeletionRequestsFun,
                  setColumnsData,
                  filtersData,
                );
                setFiltersData(filtersData);
              },
              date: [
                {
                  applyAs: "and",
                  key: "deleteRequest.requestedAt",
                  comparison: "Range",
                  placeholder: "Request",
                },
              ],
              manualFilters: isSuperAdmin
                ? [
                    {
                      key: "employer.id",
                      value: companyId,
                      comparison: "Equal",
                      applyAs: "and",
                      render: (onChangeCallback, value) => {
                        return (
                          <Formik
                            initialValues={{ company: null }}
                            onSubmit={() => {}}
                          >
                            {({ setFieldValue, values }) => (
                              <Form>
                                <CustomTreeSelect
                                  noMargin
                                  allowClear
                                  showArrow
                                  treeNodeFilterProp="companyName"
                                  treeLine={true && { showLeafIcon: false }}
                                  className="filter-select"
                                  name="company"
                                  treeData={
                                    companyChildren
                                      ? companyChildren
                                      : data?.getClientsTree
                                  }
                                  placeholder="Companies: All"
                                  fieldNames={{
                                    label: "companyName",
                                    value: "id",
                                  }}
                                  onChange={(e) => {
                                    onChangeCallback(e);
                                  }}
                                  onClear={() => setCompanyId(undefined)}
                                  value={value}
                                />
                              </Form>
                            )}
                          </Formik>
                        );
                      },
                    },
                  ]
                : undefined,
            }}
            totalItems={columnsData?.metadata?.total}
            loading={isLoading}
            columns={columns}
            headerTitle={t("delete-requests.title")}
            dataSource={columnsData?.data}
          />
        </div>
      </DashboardLayout>
    </>
  );
};

export default DeleteRequests;
