import CustomModal from "components/custom-modal/CustomModal";
import { Form, Formik, FormikHelpers } from "formik";
import * as React from "react";
import { addLanguageSchema } from "validations/schema/addLanguageSchema";
import { CustomSelectField } from "../custom-select-field/CustomSelectField";
import ModalFooter from "components/custom-modal/ModalFooter";
import { useLanguages } from "hooks";
import { errorFormat, getLanguage } from "helpers";
import { useTranslation } from "react-i18next";

interface CustomLanguageSelectorProps<T> {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (values: T, actions: FormikHelpers<T>) => void;
  data?: any;
  title?: string;
  saveText?: string;
  label?: string;
}

const CustomLanguageSelector: React.FunctionComponent<
  CustomLanguageSelectorProps<{ language: string }>
> = ({ visible, setVisible, onSubmit, data, title, saveText, label }) => {
  const { languages } = useLanguages();
  const addedLanguages = data?.translations
    ? Object.keys(data?.translations)?.map((item: any) => getLanguage(item))
    : [];

  const handleCancel = () => {
    setVisible(false);
  };

  const { t } = useTranslation();

  return (
    <CustomModal
      centered
      width={576}
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      title={title ?? t("add-new-language")}
    >
      <Formik
        enableReinitialize
        initialValues={{ language: "" }}
        validationSchema={addLanguageSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <CustomSelectField
            name="language"
            label={label ? `${label} ${t("language")}` : t("language")}
            placeholder={t("select-language")}
            options={
              languages.filter(
                (item) =>
                  item.value !== data?.language &&
                  !addedLanguages?.includes(item.label),
              ) || []
            }
          />
          <ModalFooter
            saveText={saveText ?? t("add-language")}
            onCancel={handleCancel}
          />
        </Form>
      </Formik>
    </CustomModal>
  );
};

export default CustomLanguageSelector;
