import {
  MutationAddNewPartnerArgs,
  useAddNewPartnerMutation,
  useGetPartnerListQuery,
} from "graphql/_generated/graphql";

export const useAddPartner = () => {
  const [addPartnerFun, { isLoading }] = useAddNewPartnerMutation();

  const { refetch } = useGetPartnerListQuery();

  const handleAddPartner = async (values: MutationAddNewPartnerArgs) => {
    try {
      await addPartnerFun({ create: values.create }).unwrap();
      refetch();
    } catch (error) {
      throw error;
    }
  };

  return {
    handleAddPartner,
    isLoading,
  };
};
