import {
  useGetFaqListQuery,
  useToggleFaqMutation,
} from "graphql/_generated/graphql";

export const useActivationFaq = () => {
  const { refetch } = useGetFaqListQuery();
  const [ActivationDeactivationFun, { isLoading: activationLoading }] =
    useToggleFaqMutation();

  //this function is responsible for Activation/Deactivation faq
  const handleActivationDeactivationFaq = async (id: string) => {
    try {
      const res = await ActivationDeactivationFun({
        toggleFaqId: id,
      }).unwrap();

      refetch();

      return res.toggleFAQ;
    } catch (error: any) {
      throw error;
    }
  };

  return { activationLoading, handleActivationDeactivationFaq };
};
