import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { customNotification } from "components/custom-notification/CustomNotification";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { CustomUploadFile } from "components/form/custom-upload-file/CustomUploadFile";
import { errorFormat } from "helpers";
import TranslationFieldArr from "components/translation-field-array/TranslationFieldArr";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { avatarSchema } from "validations/schema";
import { useAvatar } from "../useAvatar";
import { useState, useEffect } from "react";
import {
  useGetAvatarListQuery,
  AvatarCreate,
  AvatarGender,
} from "graphql/_generated/graphql";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { Select, Space } from "antd";
import { useErrorHandler } from "hooks/useErrorHandler";

const { Option } = Select;

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  editId?: string | null;
  setEditId: React.Dispatch<React.SetStateAction<string | null>>;
};

const AddAvatar = ({ setVisible, visible, editId, setEditId }: Props) => {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const { refetch } = useGetAvatarListQuery();
  const {
    handleFetchEditAvatar,
    handleUpdateAvatar,
    handleAddAvatar,
    isLoading,
  } = useAvatar();

  const [initialValues, setInitialValues] = useState<AvatarCreate>({
    imgPath: "",
    name: "",
    gender: AvatarGender.Male,
    translations: [],
  });

  const handleCancel = () => {
    setVisible(false);
    setEditId(null);
  };

  useEffect(() => {
    if (editId) {
      handleFetchEditAvatar(editId).then((data: any) => {
        setInitialValues({
          imgPath: data?.imgPath,
          name: data?.name,
          gender: data?.gender,
          translations: data?.translations,
        });
      });
    } else {
      setInitialValues({
        imgPath: "",
        name: "",
        gender: AvatarGender.Male,
        translations: [],
      });
    }
  }, [editId]);

  return (
    <CustomModal
      width={600}
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      title={t(editId ? t("edit-avatar") : t("add-avatar"))}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={avatarSchema}
        onSubmit={async (values, { resetForm }) => {
          if (!editId) {
            try {
              await handleAddAvatar({
                create: {
                  ...values,
                },
              });
              customNotification("success", t("avatar-added-successfully"));
              resetForm();
              setVisible(false);
            } catch (error) {
              handleError(error as string | object);
            }
          } else {
            try {
              await handleUpdateAvatar({
                id: editId,
                updateObj: values,
              });
              setEditId(null);
              customNotification("success", t("avatar-updated-successfully"));
              resetForm();
              refetch();
              setVisible(false);
            } catch (error) {
              handleError(error as string | object);
            }
          }
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <CustomUploadFile
              name="imgPath"
              setFieldValue={setFieldValue}
              imgUrl={values.imgPath}
              accept="image/*"
            />
            <CustomTextField name="name" placeholder={t("avatar-title")} />
            <CustomSelectField
              defaultValue={AvatarGender.Male}
              placeholder={t("gender")}
              name="gender"
            >
              {Object.values(AvatarGender).map((item, index) => (
                <Option value={item} key={index}>
                  {item}
                </Option>
              ))}
            </CustomSelectField>
            {/* <TranslationFieldArr values={values} attribute="name" /> */}
            <ModalFooter
              saveText={t("save-avatar")}
              onCancel={handleCancel}
              loading={isLoading}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddAvatar;
