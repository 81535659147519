import { useDeleteCategoryMappingMutation } from "graphql/_generated/graphql";

type TypeHandleDeleteCategoryMapping = (id: string) => Promise<any>;

type TypeUseDeleteCategoryMapping = () => {
  isLoading: boolean;
  handleDeleteCategoryMapping: TypeHandleDeleteCategoryMapping;
};

export const useDeleteCategoryMapping: TypeUseDeleteCategoryMapping = () => {
  const [DeleteFun, { isLoading }] = useDeleteCategoryMappingMutation();

  // This function is responsible for deleting mapping
  const handleDeleteCategoryMapping: TypeHandleDeleteCategoryMapping = async (
    id,
  ) => {
    try {
      const res = await DeleteFun({ deleteCategoryMappingId: id }).unwrap();
      return res;
    } catch (error: unknown) {
      throw error;
    }
  };

  return {
    isLoading,
    handleDeleteCategoryMapping,
  };
};
