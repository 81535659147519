import moment from "moment";

/**
 * Moment Formats
 */
enum MomentFormats {
  UNIX_MILLISECONDS = "x",
  UNIX = "X",
}

/**
 * Unix Timestamp in milliseconds.
 *
 * @param datetime
 * @returns number
 */
export const unixTimestampMs = (datetime?: any): number => {
  datetime = datetime ? moment(datetime) : moment();

  return Number(datetime.format(MomentFormats.UNIX_MILLISECONDS));
};

/**
 *
 * @param yearsAgo
 * @returns
 */
export const getOldDate = (yearsAgo: number) => {
  const oldDate = new Date();

  oldDate.setFullYear(new Date().getFullYear() - yearsAgo);

  return oldDate;
};

/**
 *
 * @param date
 * @returns
 */
export const setDayEndTime = (date: Date): Date => {
  const d = new Date(date);

  d.setHours(23, 59, 59, 999);

  return d;
};
