import * as Validations from "validations/schema/common/validations";
import * as Yup from "yup";
import { createUniqueLanguagesTest } from "./common/validationHelpers";

export const collectionSchema = Yup.object().shape({
  image: Validations.image("image"),
  title: Validations.requiredFieldWithoutSpaces("title"),
  description: Validations.required("description"),
  collectionType: Validations.required("program-type"),
  language: Validations.required("language"),
  tagIds: Validations.requiredArrayOfStringValidation("tag"),
  categoryIds: Validations.requiredArrayOfStringValidation("category"),
  pillarIds: Validations.required("pillar"),
  contentIds: Validations.requiredArrayOfStringValidation("content"),
  status: Validations.required("status"),
  translations: Yup.array()
    .of(
      Yup.object().shape({
        lang: Validations.required("language"),
        title: Validations.requiredFieldWithoutSpaces("title").max(
          300,
          "max-300-chars",
        ),
        description: Validations.requiredFieldWithoutSpaces("description"),
      }),
    )
    .test(
      "unique-languages",
      "error:text-lang-unique",
      createUniqueLanguagesTest({
        parentFieldName: "language",
        errorMsg: "error:text-lang-unique",
      }),
    )
    .nullable(),
});
