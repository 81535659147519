import * as Validations from "validations/schema/common/validations";
import * as Yup from "yup";

export const inviteCompanySchema = Yup.object().shape({
  companyName: Validations.requiredAlphaFieldWithoutSpaces("company"),
  email: Validations.email,
  roleId: Validations.required("role"),
  subscriptions: Validations.requiredFieldPositiveNumber("subscriptions"),
  regionalServiceKey: Validations.required("region"),
});

export const editCompanySchema = Yup.object().shape({
  accessCode: Yup.mixed().when("isAccessCodeEnabled", {
    is: true,
    then: Validations.requiredAlphaNumericValidation("access-code", 12),
  }),
});
