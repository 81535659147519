import IQuestionSteps from "features/assessment/types/IQuestionSteps";

const stepsInitialObject: IQuestionSteps = {
  SN: 1,
  id: "",
  text: "",
  start: undefined,
  end: undefined,
  emoji: undefined,
  score: undefined,
  tags: [],
};

export default stepsInitialObject;
