import React from "react";
import { BronzeMedalSvg, GoldMedalSvg, SilverMedalSvg } from "assets/icons";

const ranks: { [key: number]: React.ReactElement } = {
  1: GoldMedalSvg(),
  2: SilverMedalSvg(),
  3: BronzeMedalSvg(),
};
export const getRanks = (key: number) => {
  if (key in ranks && Number(key) <= 3) {
    return ranks[key];
  } else {
    return key;
  }
};
