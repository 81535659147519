import customNotification from "components/custom-notification/CustomNotification";
import { FormikHelpers } from "formik";
import {
  PartnerRewardCreate,
  RewardPartnerStockTypes,
  useGetClientsTreeQuery,
  useGetConfigProvidersQuery,
  useGetConfigRewardCategoriesQuery,
  useGetConfigRewardTypesQuery,
  useGetConfigTiersQuery,
} from "graphql/_generated/graphql";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useReward } from "./useReward";

export interface AddPartnerRewardProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  editId?: string | null;
  setEditId: React.Dispatch<React.SetStateAction<string | null>>;
  langCode?: string;
  isAddLanguage?: boolean;
  partnerId: string;
}

export interface RewardStocksProps {
  id?: string;
  rewardCode: string;
  rewardStockType: RewardPartnerStockTypes;
  isDeleted?: boolean;
  redeemedUserEmail?: string;
  redeemedAt?: string;
}

export interface RewardCreateProps extends PartnerRewardCreate {
  availableRewardStocks: Array<RewardStocksProps>;
  redeemedRewardStocks: Array<RewardStocksProps>;
}

export const useAddPartnerRewardViewModel = ({
  setVisible,
  editId,
  setEditId,
  langCode,
  isAddLanguage,
  partnerId,
}: AddPartnerRewardProps) => {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const { data: tiersData } = useGetConfigTiersQuery();
  const { data: clientsData } = useGetClientsTreeQuery();
  const { data: rewardCategoriesData } = useGetConfigRewardCategoriesQuery();
  const { data: providerData } = useGetConfigProvidersQuery();
  const { data: rewardTypesData } = useGetConfigRewardTypesQuery();

  const handleCancel = () => {
    setVisible(false);
    setEditId(null);
  };

  const {
    handleAddPartnerReward,
    handleGetPartnerReward,
    handleUpdateReward,
    rewardVisibility,
    isLoading,
  } = useReward();

  const [initialValues, setInitialValues] = useState<RewardCreateProps>({
    partnerId: partnerId,
    name: "",
    language: langCode,
    isDateEnable: false,
    startDate: undefined,
    endDate: undefined,
    isRedemptionLimitsEnable: false,
    isCreateStockEnable: false,
    price: 0,
    redemptionLimits: undefined,
    stockType: undefined,
    stockLimit: undefined,
    rewardTypeId: "",
    availableRewardStocks: [],
    redeemedRewardStocks: [],
    companyVisibility: undefined,
    rewardCategoryId: undefined,
    rewardProviderId: undefined,
    tierId: undefined,
    image: undefined,
    companyIds: undefined,
  });

  const rewardPartnerStockTypes = Object.keys(RewardPartnerStockTypes).map(
    (item) => ({
      label: item,
      value: item,
    }),
  );

  const handleDisabled = (values: RewardCreateProps) => {
    if (
      initialValues.name === values.name &&
      initialValues.description === values.description &&
      initialValues.isDateEnable === values.isDateEnable &&
      initialValues.startDate === values.startDate &&
      initialValues.endDate === values.endDate &&
      initialValues.isRedemptionLimitsEnable ===
        values.isRedemptionLimitsEnable &&
      initialValues.isCreateStockEnable === values.isCreateStockEnable &&
      initialValues.price === values.price &&
      initialValues.redemptionLimits === values.redemptionLimits &&
      initialValues.stockType === values.stockType &&
      initialValues.stockLimit === values.stockLimit &&
      initialValues.rewardTypeId === values.rewardTypeId &&
      initialValues.availableRewardStocks === values.availableRewardStocks &&
      initialValues.companyVisibility === values.companyVisibility &&
      initialValues.rewardCategoryId === values.rewardCategoryId &&
      initialValues.rewardProviderId === values.rewardProviderId &&
      initialValues.tierId === values.tierId &&
      initialValues.image === values.image &&
      initialValues.companyIds === values.companyIds
    )
      return true;
    return false;
  };

  const isAvailableRewardCodesEmpty = (values: RewardCreateProps) =>
    values.isCreateStockEnable &&
    values.stockType === RewardPartnerStockTypes.Individual &&
    values.availableRewardStocks.every((item) => item.isDeleted);

  useEffect(() => {
    if (editId) {
      handleGetPartnerReward(editId).then((data) => {
        const translationData = langCode && data?.translations?.[langCode];
        const companies =
          data.companiesList && data.companiesList.map((obj) => obj.id);
        setInitialValues({
          name: isAddLanguage ? translationData?.name : data?.name,
          description: isAddLanguage
            ? translationData?.description
            : data?.description,
          language: langCode,
          image: data.image,
          companyIds: companies,
          partnerId: data.partner?.id,
          rewardCategoryId: data.rewardCategory?.id,
          rewardTypeId: data.rewardType?.id,
          rewardProviderId: data.rewardProvider?.id,
          tierId: data.tier?.id,
          isDateEnable: data.isDateEnable,
          companyVisibility: data.companyVisibility,
          startDate: data.startDate,
          endDate: data.endDate,
          isRedemptionLimitsEnable: data.isRedemptionLimitsEnable,
          isCreateStockEnable: data.isCreateStockEnable,
          price: data.price,
          redemptionLimits:
            data.redemptionLimits === null ? undefined : data.redemptionLimits,
          stockType: data.stockType === null ? undefined : data.stockType,
          stockLimit: data.stockLimit === null ? undefined : data.stockLimit,
          availableRewardStocks: data.availableRewardStocks || [],
          redeemedRewardStocks: data.redeemedRewardStocks || [],
        });
      });
    }
  }, [editId]);

  const onSubmit = async (
    values: RewardCreateProps,
    actions?: FormikHelpers<typeof values>,
  ) => {
    const { availableRewardStocks, redeemedRewardStocks, ...otherValues } =
      values;
    let rewardStocksData = undefined;
    if (availableRewardStocks?.length)
      rewardStocksData = availableRewardStocks
        .filter((item) => item.rewardCode)
        .map((item) => ({
          ...item,
          rewardStockType: RewardPartnerStockTypes.Individual,
        }));
    if (!editId) {
      try {
        await handleAddPartnerReward({
          create: {
            ...otherValues,
            rewardStocks: rewardStocksData,
            language: langCode,
            partnerId: partnerId,
          },
        });
        customNotification("success", t("partner-reward-added-successfully"));
        actions?.resetForm();

        setVisible(false);
      } catch (error) {
        handleError(error as string | object);
      }
    } else if (isAddLanguage && editId && langCode) {
      try {
        await handleUpdateReward({
          update: {
            translations: {
              [langCode]: {
                name: values.name,
                description: values.description,
              },
            },
          },
          id: editId,
        });
        setEditId(null);
        customNotification("success", t("partner-language-added-successfully"));
        actions?.resetForm();

        setVisible(false);
      } catch (error) {
        handleError(error as string | object);
      }
    } else {
      try {
        await handleUpdateReward({
          id: editId,
          update: { ...otherValues, rewardStocks: rewardStocksData },
        });
        customNotification("success", t("partner-reward-updated-successfully"));
        setEditId(null);
        setVisible(false);
        actions?.resetForm();
      } catch (error) {
        handleError(error as string | object);
      }
    }
  };

  return {
    tiersData,
    clientsData,
    rewardCategoriesData,
    providerData,
    rewardTypesData,
    handleCancel,
    initialValues,
    rewardVisibility,
    isLoading,
    onSubmit,
    rewardPartnerStockTypes,
    handleDisabled,
    isAvailableRewardCodesEmpty,
  };
};
