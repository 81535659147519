import { useUsers } from "features/users/hooks/useUsers";
import { useTranslation } from "react-i18next";
import customNotification from "components/custom-notification/CustomNotification";
import { useErrorHandler } from "hooks/useErrorHandler";
import { mapUsersContents } from "./useInviteMultipleUsers";

export const useBulkUsersPreviewViewModel = () => {
  const { t } = useTranslation();
  const { isLoading, inviteMultipleEmployees } = useUsers();
  const { handleError } = useErrorHandler();

  const handleBulkInvite = async (values: any, refetch: () => void) => {
    try {
      const mappedUsers = mapUsersContents(values.users);
      const res = await inviteMultipleEmployees({
        ...values,
        users: mappedUsers,
      });
      if (res.inviteEmployeeInBulk.errorCount > 0) {
        handleError(JSON.stringify(res.inviteEmployeeInBulk.errorsOn));
      } else {
        customNotification("success", t("success-invite-employees"));
        refetch();
      }
    } catch (error) {
      handleError(error as string | object);
    }
  };

  return { handleBulkInvite, isLoading };
};
