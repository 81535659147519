import { InfoCircleOutlined } from "@ant-design/icons";
import { useField, useFormikContext } from "formik";
import * as React from "react";
import { useErrorTranslation } from "validations/schema/common/useErrorTranslation";

interface FormInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
}

const FormInput: React.FunctionComponent<FormInputProps> = (props) => {
  const { label, name, ...restProps } = props;
  const [field, meta, helpers] = useField(props);
  const { errorT } = useErrorTranslation();
  return (
    <div>
      {label && (
        <label
          htmlFor={name}
          className="font-normal text-sm text-[#121212] font-sf-pro-text before:content-['*'] before:text-red-500 inline-block mb-1"
        >
          {label}
        </label>
      )}
      <input
        id={name}
        {...field}
        {...restProps}
        className={`border border-[#D6D6D6] rounded-lg py-4 px-4 h-14 outline-none w-full ${
          meta.error && meta.touched ? "border-[#E53935]" : ""
        }`}
      />
      {meta.touched && meta.error ? (
        <div className="flex items-center gap-x-1">
          <InfoCircleOutlined
            style={{
              color: "#E53935",
            }}
          />
          <div className="text-[#E53935] font-normal">{errorT(meta.error)}</div>
        </div>
      ) : null}
    </div>
  );
};

export default FormInput;
