import {
  useRemoveSpecialistMutation,
  useToggleSpecialistActivationMutation,
} from "graphql/_generated/graphql";

export const useToggleActivationSpecialist = () => {
  const [ActivationDeactivationFun, { isLoading }] =
    useToggleSpecialistActivationMutation();

  const [removeSpecialistFunc, { isLoading: isSpecialistLoading }] =
    useRemoveSpecialistMutation();

  //this function is responsible for Activation/Deactivation Specialist
  const handleActivationSpecialist = async (id: string) => {
    try {
      const res = await ActivationDeactivationFun({
        toggleSpecialistActivationId: id,
      }).unwrap();
      return res.toggleSpecialistActivation;
    } catch (error) {
      throw error;
    }
  };

  const handleRemoveSpecialist = async (id: string) => {
    try {
      const res = await removeSpecialistFunc({
        removeSpecialistId: id,
      }).unwrap();
      return res.removeSpecialist;
    } catch (error: any) {
      throw error;
    }
  };

  return {
    isLoading,
    isSpecialistLoading,
    handleActivationSpecialist,
    handleRemoveSpecialist,
  };
};
