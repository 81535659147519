import { Space } from "antd";
import { ColumnsType } from "antd/lib/table";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import CustomModal from "components/custom-modal/CustomModal";
import CustomTable from "components/custom-table/CustomTable";
import { Form, Formik, FormikHelpers } from "formik";
import {
  GetTeamEventMembersQuery,
  ManualTeamAssignmentInput,
} from "graphql/_generated/graphql";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styles from "features/events/css/TeamLeaderBoard.module.scss";
import ModalFooter from "components/custom-modal/ModalFooter";
import { TableRowSelection } from "antd/lib/table/interface";
import { Key } from "react";

interface UnAssignedMembersProps {
  visible: boolean;
  onClose: () => void;
  loading: boolean;
  isLoadingMembers: boolean;
  currentPage: number;
  pageSize: number;
  membersData?: GetTeamEventMembersQuery["getTeamEventMembers"];
  membersFiltersCallBack: (filtersData: any) => Promise<void>;
  membersRowSelection: TableRowSelection<
    GetTeamEventMembersQuery["getTeamEventMembers"]["data"][0]
  >;
  onSubmitAddMember: (
    values: ManualTeamAssignmentInput,
    formikHelpers?: FormikHelpers<ManualTeamAssignmentInput> | undefined,
  ) => Promise<boolean | undefined>;
  initialValues: {
    eventId: string;
    teamId: string;
    participantIds: string[];
  };
}

const UnAssignedMembers: React.FunctionComponent<UnAssignedMembersProps> = ({
  visible,
  onClose,
  currentPage,
  pageSize,
  loading,
  membersData,
  membersFiltersCallBack,
  membersRowSelection,
  onSubmitAddMember,
  initialValues,
  isLoadingMembers,
}) => {
  const { t } = useTranslation();
  const selectedMembers = initialValues.participantIds.length ?? 0;
  const columns: ColumnsType<
    GetTeamEventMembersQuery["getTeamEventMembers"]["data"][0]
  > = [
    {
      title: t("id"),
      key: "userId",
      dataIndex: "userId",
      sorter: {
        multiple: 1,
      },
      render: (_text, _record, index) =>
        (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: t("name"),
      dataIndex: "firstName",
      key: "firstName",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => (
        <Space>
          <CustomAvatar imgSrc={record.avatar?.imgPath ?? record.image ?? ""} />
          <div
            className={styles["team-member"]}
          >{`${record.firstName} ${record.lastName}`}</div>
        </Space>
      ),
    },
    {
      title: t("company"),
      dataIndex: "company",
      key: "company.companyName",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => (
        <Space>
          <CustomAvatar imgSrc={record.company?.image ?? ""} />
          <div>{record.company?.companyName}</div>
        </Space>
      ),
    },
    {
      title: t("email"),
      dataIndex: "email",
      key: "email",
      sorter: {
        multiple: 1,
      },
    },
  ];
  return (
    <CustomModal
      centered
      width={720}
      visible={visible}
      onCancel={onClose}
      footer={null}
      title={t("add-team-members", { selectedMembers })}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmitAddMember}
      >
        <Form>
          <div className={styles["mb-1"]}>
            <CustomTable
              rowSelection={membersRowSelection}
              loading={isLoadingMembers}
              dataSource={membersData?.data}
              columns={columns}
              filters={{
                handleCallback: membersFiltersCallBack,
              }}
              headerTitle={`${selectedMembers}/${
                membersData?.data.length ?? 0
              } ${t("emp-selected")}`}
              searchStyle={{
                borderStyle: "solid",
                borderRadius: 10,
                borderColor: "#adadad",
                width: 270,
              }}
              totalItems={membersData?.total}
              rowKey="participantId"
              headerStyle={{ fontWeight: 600, fontSize: "16px" }}
            />
          </div>

          <ModalFooter
            disabled={!!!selectedMembers}
            loading={loading}
            saveText={t("add-members")}
            onCancel={onClose}
          />
        </Form>
      </Formik>
    </CustomModal>
  );
};

export default UnAssignedMembers;
