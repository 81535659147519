import React, { FC, useEffect, useState, useCallback } from "react";
import { TreeSelect, TreeSelectProps } from "formik-antd";
import { ErrorMessage, useFormikContext, FormikContextType } from "formik";
import { TreeNodeProps, Space } from "antd";
import { useErrorTranslation } from "validations/schema/common/useErrorTranslation";
import styles from "./CustomTreeSelect.module.scss";
import { DefaultOptionType } from "antd/lib/select";
interface CustomTreeSelectProps extends TreeSelectProps {
  name: string;
  label?: string;
  className?: string;
  noMargin?: boolean;
  multiple?: boolean;
  treeData?: TreeNode[];
  onChange?: (value: any, labelList: React.ReactNode[], extra: any) => void;
  required?: boolean;
}

interface TreeNode {
  title: string;
  value: string;
  key: string;
  id: string;
  children?: TreeNode[];
}

const CustomTreeSelect: FC<CustomTreeSelectProps> = ({
  name,
  label,
  className,
  noMargin,
  treeData,
  required,
  fieldNames,
  ...props
}) => {
  const { errorT } = useErrorTranslation();
  const { setFieldValue } = useFormikContext() as FormikContextType<any>;

  const handleChange = useCallback(
    (value: any, labelList: React.ReactNode[], extra: any): void => {
      props.onChange?.(value, labelList, extra);
    },
    [props, setFieldValue, name],
  );

  return (
    <div
      className={styles["form-control"]}
      style={noMargin ? { marginBottom: 0 } : undefined}
    >
      {(label || required) && (
        <Space size={2}>
          {required && (
            <span className={`${styles["label"]} ${styles["text-danger"]} `}>
              &#42;
            </span>
          )}
          {label && (
            <label className={`${styles["label"]}`} htmlFor={name}>
              {label}
            </label>
          )}
        </Space>
      )}

      <TreeSelect
        name={name}
        showSearch
        getPopupContainer={(trigger) => trigger.parentNode}
        className={className || "form-select"}
        showCheckedStrategy="SHOW_ALL"
        filterTreeNode={(inputValue, treeNode) => {
          const label = fieldNames?.label
            ? treeNode[fieldNames.label]
            : treeNode?.label;

          return (label || "")
            .toLowerCase()
            .includes(inputValue?.toLowerCase());
        }}
        onChange={handleChange}
        treeData={treeData}
        fieldNames={fieldNames}
        {...props}
      />
      <ErrorMessage
        name={name}
        render={(msg) => <div className="text-danger">{errorT(msg)}</div>}
      />
    </div>
  );
};

export default CustomTreeSelect;
