import { ErrorMessage } from "formik";
import { DatePicker, RangePickerProps } from "formik-antd";
import * as React from "react";
import { useErrorTranslation } from "validations/schema/common/useErrorTranslation";
import styles from "./CustomRangePicker.module.scss";
interface CustomRangePickerProps {
  name: string;
  label?: string;
  className?: string;
  onChange?: (dates: any, dateStrings: string[]) => void;
}

const CustomRangePicker: React.FunctionComponent<
  CustomRangePickerProps & RangePickerProps
> = (props) => {
  const { errorT } = useErrorTranslation();

  const { label, className, name, ...rest } = props;
  const { RangePicker } = DatePicker;

  return (
    <div className={styles["form-control"]}>
      {label && <label className={styles["label"]}>{label}</label>}
      <RangePicker
        {...rest}
        name={name}
        className={className ? className : "custom-date"}
      />
      <ErrorMessage
        name={name}
        render={(msg) => <div style={{ color: "red" }}>{errorT(msg)}</div>}
      />
    </div>
  );
};

export default CustomRangePicker;
