import { useEffect, useState } from "react";
import { TrackedEntityType, Trackable } from "graphql/_generated/graphql";

export const useGetTrackerTypes = (entityTpe?: TrackedEntityType) => {
  const [trackerTypes, setTrackerTypes] = useState<any>([]);

  // this useEffect created the options array of TrackingTypes
  useEffect(() => {
    let dummyArr: any = [];
    let filteredObj: any = Trackable;
    if (entityTpe === TrackedEntityType.Activity) {
      const {
        Collection,
        Content,
        ...rest
      } = Trackable;
      filteredObj = rest;
    } else if (entityTpe === TrackedEntityType.Achievement) {
      const { ...restTrackers } = Trackable;
      filteredObj = restTrackers;
    }
    Object.keys(filteredObj).map((item: any) => {
      dummyArr.push({
        label: [Trackable.Collection, Trackable.Content].includes(item) ? `${item} Category` : item,
        value: item,
      });
    });

    setTrackerTypes(dummyArr);
  }, []);
  return { trackerTypes };
};
