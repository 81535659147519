import CustomButton from "components/custom-button/CustomButton";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import { EapCreate } from "graphql/_generated/graphql";
import { useState } from "react";
import AddEAP from "features/employee-assistance/add-eap/AddEAP";
import EAPsList from "features/employee-assistance/eap-list/EAPList";
import styles from "features/employee-assistance/eaps/EAPs.module.scss";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
type Props = {};

export default function EAPs({}: Props) {
  const { t } = useTranslation();

  return (
    <>
      <DashboardLayout openKey={["Configs"]}>
        <Breadcrumbs
          data={[
            {
              label: "Configurations",
            },
            {
              label: t("eap-title"),
            },
          ]}
        />
        <EAPsList />
      </DashboardLayout>
    </>
  );
}
