export const DashboardSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.16667 0.5H5.5C6.41917 0.5 7.16667 1.2475 7.16667 2.16667V5.5C7.16667 6.41917 6.41917 7.16667 5.5 7.16667H2.16667C1.2475 7.16667 0.5 6.41917 0.5 5.5V2.16667C0.5 1.2475 1.2475 0.5 2.16667 0.5ZM10.5 0.5H13.8333C14.7525 0.5 15.5 1.2475 15.5 2.16667V5.5C15.5 6.41917 14.7525 7.16667 13.8333 7.16667H10.5C9.58083 7.16667 8.83333 6.41917 8.83333 5.5V2.16667C8.83333 1.2475 9.58083 0.5 10.5 0.5ZM5.5 8.83333H2.16667C1.2475 8.83333 0.5 9.58083 0.5 10.5V13.8333C0.5 14.7525 1.2475 15.5 2.16667 15.5H5.5C6.41917 15.5 7.16667 14.7525 7.16667 13.8333V10.5C7.16667 9.58083 6.41917 8.83333 5.5 8.83333ZM10.5 8.83333H13.8333C14.7525 8.83333 15.5 9.58083 15.5 10.5V13.8333C15.5 14.7525 14.7525 15.5 13.8333 15.5H10.5C9.58083 15.5 8.83333 14.7525 8.83333 13.8333V10.5C8.83333 9.58083 9.58083 8.83333 10.5 8.83333Z"
        fill="#303030"
      />
    </svg>
  );
};
