import IQuestionRatings from "features/assessment/types/IQuestionRatings";

const ratingsInitialObject: IQuestionRatings = {
  id: "",
  score: undefined,
  text: 1,
  ratingNumber: 1,
  tags: [],
};

export default ratingsInitialObject;
