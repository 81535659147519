import { useResetPasswordMutation } from "graphql/_generated/graphql";

//function type
type TypeHandleResetPasswordArgs = {
  id: string;
  newPassword: string;
  deviceToken: string;
};

// hook type
type TypeUseResetPassword = () => {
  isLoading: boolean;
  handleResetPassword: (
    values: TypeHandleResetPasswordArgs
  ) => Promise<{ token: string; refreshToken: string }>;
};

export const useResetPassword: TypeUseResetPassword = () => {
  const [ResetPasswordFun, { isLoading }] = useResetPasswordMutation();

  //this function is responsible for verifying otp
  const handleResetPassword = async (values: TypeHandleResetPasswordArgs) => {
    try {
      const res = await ResetPasswordFun({
        body: { ...values },
      }).unwrap();

      return {
        token: res.resetPassword.token,
        refreshToken: res.resetPassword.refreshToken,
      };
    } catch (error: any) {
      throw error;
    }
  };

  return { isLoading, handleResetPassword };
};
