import {
  useDeletePartnerMutation,
  useGetPartnerListQuery,
} from "graphql/_generated/graphql";

export const useDeletePartner = () => {
  const [deletePartnerFun, { isLoading }] = useDeletePartnerMutation();
  const { refetch } = useGetPartnerListQuery();

  const handleDeletePartner = async (id: string) => {
    try {
      const res = await deletePartnerFun({ deletePartnerId: id }).unwrap();
      refetch();
      return res.deletePartner.id;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleDeletePartner,
    isLoading,
  };
};
