import { useState } from "react";

const useSorting = () => {
  const [sorting, setSorting] = useState<any>(undefined);

  const handleSorting = (sort: any) => {
    if (sort) {
      // this block of code is only runs if we have multiple filters
      if (Array.isArray(sort)) {
        let sortObj = {};

        sort.map((s) => {
          sortObj = {
            ...sortObj,
            [s.columnKey]: s.order == "ascend" ? "asc" : "desc",
          };
        });

        setSorting(sortObj);
      } else {
        // this block of code will run if we have single filter
        setSorting({
          [sort.columnKey]:
            sort.order == "ascend"
              ? "asc"
              : sort.order === "descend"
              ? "desc"
              : undefined,
        });
      }
    } else {
      setSorting(undefined);
    }
  };

  return { sorting, handleSorting };
};

export default useSorting;
