import { UserFilters } from "graphql/_generated/graphql";
import { FunctionComponent } from "react";
import { ContentList } from "../content-list";
import { InfoTextProps } from "../organization-metrices/types";
import { useTopChallengesViewModel } from "./hooks/useTopChallengesViewModel";

interface TopChallengesProps extends InfoTextProps {
  item: any;
  userFilters: UserFilters;
}

export const TopChallenges: FunctionComponent<TopChallengesProps> = ({
  item,
  userFilters,
  selectedInfoText,
  setSelectedInfoText,
}) => {
  const { infoTexts, handleRefresh, challengeData, isLoading } =
    useTopChallengesViewModel({ item, userFilters });
  return (
    <ContentList
      item={{
        ...item,
        content: challengeData,
        isLoading,
        handleRefresh,
        infoTexts,
      }}
      extraSpacing={true}
      selectedInfoText={selectedInfoText}
      setSelectedInfoText={setSelectedInfoText}
    />
  );
};
