import { Button, ButtonProps } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import "./CustomButton.module.scss";
import * as React from "react";

interface DeleteBtnProps extends ButtonProps {}

const DeleteBtn: React.FunctionComponent<DeleteBtnProps> = (props) => {
  return (
    <Button className="cross-btn" {...props}>
      {props.children ?? <CloseOutlined />}
    </Button>
  );
};

export default DeleteBtn;
