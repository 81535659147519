import { useToggleRoleActivationMutation } from "graphql/_generated/graphql";

export const useToggleActivationRole = () => {
  const [ActivationDeactivationFun, { isLoading }] =
    useToggleRoleActivationMutation();

  //this function is responsible for Activation/Deactivation roles
  const handleToggleActivationRole = async (id: string) => {
    const res = await ActivationDeactivationFun({
      toggleRoleActivationId: id,
    }).unwrap();

    return res.toggleRoleActivation;
  };

  return {
    isLoading,
    handleToggleActivationRole,
  };
};
