import { Col, Row } from "antd";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import CustomTreeSelect from "components/form/custom-tree-select/CustomTreeSelect";
import {
  GetClientsTreeQuery,
  LeaderboardType,
} from "graphql/_generated/graphql";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import CollapseAble from "features/assessment/questions/collapseables";
import ContainerHeading from "features/assessment/questions/ContainerHeading";
import { CustomDatePicker } from "components/form/custom-date-picker/CustomDatePicker";
import { getRandomColor } from "helpers";
interface EventOptionsProps {
  isAddLanguage: boolean;
  oldLocation?: string;
  companyDisabled?: boolean;
  companiesData: GetClientsTreeQuery["getClientsTree"];
  leaderboardTypesData: { label: string; value: LeaderboardType }[];
  editAble: boolean;
  setFiledValue: (name: string, value: any) => void;
}

const EventOptions: FunctionComponent<EventOptionsProps> = ({
  isAddLanguage,
  oldLocation,
  companyDisabled,
  companiesData,
  leaderboardTypesData,
  editAble,
  setFiledValue,
}) => {
  const { t } = useTranslation();

  return (
    <CollapseAble
      defaultActiveKey={["event-options"]}
      headerKey="event-options"
      hideExtra
      expandIconPosition="end"
      header={<ContainerHeading text={t("event.options")} />}
    >
      {!isAddLanguage && (
        <Row gutter={[24, 0]}>
          <Col sm={24} md={12}>
            <CustomDatePicker
              showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD HH:mm"
              placeholder={t("select-starting-date")}
              label={t("starting-date")}
              name="startDate"
              disabled={editAble}
            />
          </Col>
          <Col sm={24} md={12}>
            <CustomDatePicker
              showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD HH:mm"
              placeholder={t("select-expiry-date")}
              label={t("expiry-date")}
              name="expiryDate"
              disabled={editAble}
            />
          </Col>
        </Row>
      )}

      {!isAddLanguage && (
        <Row gutter={[24, 0]}>
          <Col sm={24} md={24}>
            <CustomTreeSelect
              allowClear
              showArrow
              showSearch
              treeNodeFilterProp="companyName"
              treeLine={true && { showLeafIcon: false }}
              label={t("event.participating-companies")}
              multiple={true}
              className="form-select"
              name="companyIds"
              treeData={companiesData}
              placeholder={t("company.select-company")}
              fieldNames={{ label: "companyName", value: "id" }}
              getPopupContainer={() => document.body}
            />
          </Col>
        </Row>
      )}
      <Row gutter={[24, 0]}>
        {!isAddLanguage && (
          <Col sm={24} md={12}>
            <CustomSelectField
              options={leaderboardTypesData}
              label={t("leaderboard-type")}
              name="leaderboardType"
              placeholder={t("select-type")}
              className="form-select"
              disabled={editAble}
            />
          </Col>
        )}

        <Col sm={24} md={12}>
          <CustomTextField
            name="location"
            label={t("location")}
            placeholder={t("enter-location")}
            oldTextValue={oldLocation}
            isAddLanguage={isAddLanguage}
          />
        </Col>
      </Row>
    </CollapseAble>
  );
};
export default EventOptions;
