import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import config from "config";
import authSlice, { removeToken, setToken } from "features/auth/authSlice";
import dashboardSlice from "features/dashboard/dashboardSlice";
import { GraphQLClient } from "graphql-request";
import { CONFIG } from "helpers/constants";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
} from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import storage from "redux-persist/lib/storage";
import { v4 as uuidv4 } from "uuid";
import { getRefreshedToken, isTokenExpired } from "./authUtils";
import { resetStore } from "./globalActions";
import navigationSlice from "./navigationSlice";
import permissionsSlice, { removePermissions } from "./permissionsSlice";
const graphqlUrl = config.api.graphqlUrl;
export const client = new GraphQLClient(graphqlUrl);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

let refreshPromise: any = null;

export const api = createApi({
  baseQuery: graphqlRequestBaseQuery({
    client,
    customErrors: (clientError) => {
      const error = clientError.response?.errors?.[0]?.message;
      return error;
    },
    prepareHeaders: async (headers, api) => {
      const getState = api.getState;
      let token = (getState() as RootState).auth.token;
      const region = (getState() as RootState).auth.region;
      headers.set(CONFIG.XCorrelationId, uuidv4().toString());
      try {
        // Check if token is expired
        if (token && isTokenExpired(token)) {
          if (!refreshPromise) {
            const refreshToken =
              (getState() as RootState).auth.refreshToken || "";
            refreshPromise = getRefreshedToken(token, refreshToken, "abc");
          }
          const refreshResponse = await refreshPromise;
          store.dispatch(setToken(refreshResponse));
          token = refreshResponse.token;
        }
      } catch (e) {
        store.dispatch(removeToken());
        store.dispatch(removePermissions());
        store.dispatch(resetStore());
        refreshPromise = null; // Clear the refresh promise on error so next refresh can start afresh
      } finally {
        if (token) {
          headers.set("token", ` ${token}`);
          if (region) {
            headers.set(CONFIG.REGIONAL_KEY_HEADER, region);
          }
        }
        refreshPromise = null; // Clear the refresh promise after refreshing so the next refresh can start when needed
        return headers;
      }
    },
  }),
  endpoints: () => ({}),
});

const encryption = encryptTransform({
  secretKey: "my-super-secret-key",
  onError: function (error) {
    // Handle the error
  },
});

const authPersistConfig = {
  key: "viwell-auth-token",
  version: 1,
  storage,
  transforms: [encryption],
};

const dashboardPersistConfig = {
  key: "viwell-dashboard",
  version: 1,
  storage,
};

const navigationPersistConfig = {
  key: "viwell-dashboard",
  version: 1,
  storage,
};

const persistedAuthReducer = persistReducer(authPersistConfig, authSlice);
const persistedDashboardReducer = persistReducer(
  dashboardPersistConfig,
  dashboardSlice,
);

const rootReducer = combineReducers({
  auth: persistedAuthReducer,
  dashboard: persistedDashboardReducer,
  permissions: permissionsSlice,
  navigation: navigationSlice,
  [api.reducerPath]: api.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(api.middleware),
  devTools: process.env.NODE_ENV !== "production",
});
