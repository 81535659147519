import { UserFilters } from "graphql/_generated/graphql";
import { FunctionComponent } from "react";
import { ContentList } from "../content-list";
import { InfoTextProps } from "../organization-metrices/types";
import { useTopProgramsViewModel } from "./hooks/useTopProgramsViewModel";

interface ProgramProps extends InfoTextProps {
  item: any;
  userFilters: UserFilters;
}

export const Program: FunctionComponent<ProgramProps> = ({
  item,
  userFilters,
  selectedInfoText,
  setSelectedInfoText,
}) => {
  const { handleRefresh, isLoading, programData } = useTopProgramsViewModel({
    item,
    userFilters,
  });
  return (
    <ContentList
      item={{
        ...item,
        content: programData,
        isLoading: isLoading,
        handleRefresh,
      }}
      selectedInfoText={selectedInfoText}
      setSelectedInfoText={setSelectedInfoText}
    />
  );
};
