import {
  MutationAddNewMeasurementArgs,
  useAddNewMeasurementMutation,
} from "graphql/_generated/graphql";

export const useAddMeasurement = () => {
  const [addNewMeasurementFun, { isLoading }] = useAddNewMeasurementMutation();

  //this function is responsible for adding the Unit
  const handleAddNewMeasurement = async (
    values: MutationAddNewMeasurementArgs,
  ) => {
    try {
      const res = await addNewMeasurementFun({
        create: values.create,
      }).unwrap();
      return res.addNewMeasurement;
    } catch (error) {
      throw error;
    }
  };

  return {
    isLoading,
    handleAddNewMeasurement,
  };
};
