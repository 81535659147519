import { useAddNewCollection } from "./add-collection/useAddCollection";
import { useUpdateCollection } from "./add-collection/useUpdateCollection";
import { useToggleActivationCollection } from "./collection-list/useToggleActivationCollection";

export const useCollection = () => {
  const {
    handleActivationCollection,
    isLoading: activationDeactivationLoading,
  } = useToggleActivationCollection();

  const { isLoading, handleAddNewCollection } = useAddNewCollection();

  const {
    isLoading: updateCollectionLoading,
    handleUpdateCollection,
    handleFetchEditCollectionData,
  } = useUpdateCollection();

  return {
    isLoading:
      isLoading || activationDeactivationLoading || updateCollectionLoading,
    handleActivationCollection,
    handleAddNewCollection,
    handleUpdateCollection,
    handleFetchEditCollectionData,
  };
};
