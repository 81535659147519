import {
  MutationAddNewEventArgs,
  MutationUpdateEventArgs,
  useAddNewEventMutation,
  useUpdateEventMutation,
} from "graphql/_generated/graphql";

export const useAddUpdateEvent = () => {
  const [addEventFn, { isLoading: isAddingEvent }] = useAddNewEventMutation();
  const [updateEventFn, { isLoading: isUpdatingEvent }] =
    useUpdateEventMutation();

  const addEvent = async (values: MutationAddNewEventArgs) => {
    try {
      const res = await addEventFn({ create: values.create }).unwrap();
      return res.addNewEvent;
    } catch (error) {
      throw error;
    }
  };

  const updateEvent = async (values: MutationUpdateEventArgs) => {
    try {
      const res = await updateEventFn({
        update: values.update,
      }).unwrap();
      return res.updateEvent;
    } catch (error) {
      throw error;
    }
  };

  return {
    addEvent,
    updateEvent,
    isAddingOrUpdatingEvent: isAddingEvent || isUpdatingEvent,
  };
};
