export const BooleanIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect opacity="0.24" width="24" height="24" rx="4" fill="#7573E1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0004 18.6665C8.32453 18.6665 5.3337 15.6757 5.3337 11.9999C5.3337 8.32404 8.32453 5.33321 12.0004 5.33321C15.6762 5.33321 18.667 8.32404 18.667 11.9999C18.667 15.6757 15.6762 18.6665 12.0004 18.6665ZM12.0003 3.66663C7.40533 3.66663 3.66699 7.40496 3.66699 12C3.66699 16.595 7.40533 20.3333 12.0003 20.3333C16.5953 20.3333 20.3337 16.595 20.3337 12C20.3337 7.40496 16.5953 3.66663 12.0003 3.66663Z"
        fill="#303030"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.66663V20.3333C16.595 20.3333 20.3333 16.595 20.3333 12C20.3333 7.40496 16.595 3.66663 12 3.66663Z"
        fill="#303030"
      />
    </svg>
  );
};
