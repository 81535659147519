import { Col, Row } from "antd";
import { FunctionComponent } from "react";
import styles from "../css/EventAdd.module.scss";
import CollapseAble from "features/assessment/questions/collapseables";
import ContainerHeading from "features/assessment/questions/ContainerHeading";
import ParticipatingCompanies from "./ParticipatingCompanies";
import { EventParticipantsScoreInput } from "graphql/_generated/graphql";
import ParticipatingCompaniesEdit from "./ParticipatingCompaniesEditScore";
import { useTranslation } from "react-i18next";
import { GetEventDetails } from "../hooks/useEventViewModel";
import { FormikHelpers } from "formik";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import { PERMS } from "helpers";

interface EventDescriptionProps {
  eventData?: GetEventDetails;
  onSubmitUpdateScore: (
    values: EventParticipantsScoreInput,
    formikHelpers?: FormikHelpers<EventParticipantsScoreInput> | undefined,
  ) => Promise<boolean | undefined>;
  loading?: boolean;
  visible?: boolean;
  userVisibility: boolean;
  eventId: string;
  companyId: string;
  onPressEdit: () => void;
  onPressUser: (clientId: string) => void;
  onCloseUser: () => void;
}

const ParticipationDetails: FunctionComponent<EventDescriptionProps> = ({
  eventData,
  loading,
  visible,
  userVisibility,
  eventId,
  companyId,
  onSubmitUpdateScore,
  onPressEdit,
  onPressUser,
  onCloseUser,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <CollapseAble
        defaultActiveKey={["participants"]}
        headerKey="participants"
        hideExtra={false}
        expandIconPosition="end"
        header={<ContainerHeading text={t("participant-details")} />}
        extraProps={
          <CheckPermissions permission={PERMS.UPDATE_EVENT_SCORES} /> && {
            handleEdit: onPressEdit,
          }
        }
      >
        <div className={styles["content-card"]}>
          <Row gutter={[24, 24]}>
            <Col sm={24}>
              <ParticipatingCompanies
                loading={loading}
                companies={eventData?.participants}
                onPressItem={onPressUser}
                userVisibility={userVisibility}
                eventId={eventId}
                companyId={companyId}
                onCloseUser={onCloseUser}
              />
            </Col>
          </Row>
        </div>
      </CollapseAble>
      {visible && (
        <ParticipatingCompaniesEdit
          loading={loading}
          companies={eventData?.participants}
          visible={visible}
          setVisible={onPressEdit}
          onSubmitUpdateScore={onSubmitUpdateScore}
          userVisibility={userVisibility}
          onCloseUser={onCloseUser}
          onPressUser={onPressUser}
        />
      )}
    </>
  );
};
export default ParticipationDetails;
