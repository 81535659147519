import {
  useGetRewardCategoryListQuery,
  useToggleRewardCategoryActivationMutation,
} from "graphql/_generated/graphql";

export const useToggleRewardCategory = () => {
  const [toggleRewardCategoryFun, { isLoading }] =
    useToggleRewardCategoryActivationMutation();

  const { refetch } = useGetRewardCategoryListQuery();

  const handleToggleRewardCategory = async (id: string) => {
    try {
      const res = await toggleRewardCategoryFun({
        toggleRewardCategoryActivationId: id,
      }).unwrap();
      refetch();
      return res.toggleRewardCategoryActivation;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleToggleRewardCategory,
    isLoading,
  };
};
