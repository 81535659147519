import * as Yup from "yup";
interface Translation {
  [key: string]: string;
}
interface UniqueLanguagesOptions {
  parentFieldName: string;
  fieldName?: string;
  arrayName?: string;
  errorMsg: string;
}

export const VALD_PREFIX_SEPERATOR = "__";

export const VALD_PREFIX = {
  ALL_UPPER_CASE: "_AU" + VALD_PREFIX_SEPERATOR,
  UPPER_CASE_WORDS: "_UW" + VALD_PREFIX_SEPERATOR,
  UPPER_CASE_FIRST_WORD: "_UFW" + VALD_PREFIX_SEPERATOR,
  ALL_LOWER_CASE: "_AL" + VALD_PREFIX_SEPERATOR,
};

export const IMAGE_SIZE = 10 * 1024 * 1024; // 10MB

export const isImageSizeValid = (size: number) => {
  console.log("IMAGE_SIZE", IMAGE_SIZE);
  return size <= IMAGE_SIZE;
};

export const toUpperCase = (errorField: string) => {
  return VALD_PREFIX.ALL_UPPER_CASE + errorField;
};

export const toUpperCaseWords = (errorField: string) => {
  return VALD_PREFIX.UPPER_CASE_WORDS + errorField;
};

export const toUpperCaseFirstWord = (errorField: string) => {
  return VALD_PREFIX.UPPER_CASE_FIRST_WORD + errorField;
};

export const toLowerCase = (errorField: string) => {
  return VALD_PREFIX.ALL_LOWER_CASE + errorField;
};

export const PWD_VALIDATION_RULES = {
  LEN: "LEN",
  UC: "UC",
  LC: "LC",
  NUM: "Num",
  SYM: "SYM",
  REQ: "REQ",
};

export const MULTIPLE_ERRORS = "_multipleErrors_";

export const getCombinedNewPasswordError = (errors?: string[]) => {
  if (!errors?.length) {
    return null;
  }
  return MULTIPLE_ERRORS + errors.join(",");
};

const findDuplicateIndex = (
  fieldName: string,
  translations: Array<Translation>,
  parentLanguage: string | undefined,
): number => {
  const languages = new Set<string>();
  const duplicateIndexes: Record<string, number> = {};

  translations.forEach((translation, index) => {
    if (languages.has(translation[fieldName])) {
      duplicateIndexes[translation[fieldName]] = index;
    }
    languages.add(translation[fieldName]);
  });

  if (parentLanguage && languages.has(parentLanguage)) {
    duplicateIndexes[parentLanguage] = translations.findIndex(
      (t) => t[fieldName] === parentLanguage,
    );
  }

  return Object.values(duplicateIndexes).length > 0
    ? duplicateIndexes[Object.keys(duplicateIndexes)[0]]
    : -1;
};

export const createUniqueLanguagesTest = (options: UniqueLanguagesOptions) => {
  const {
    parentFieldName,
    fieldName = "lang",
    arrayName = "translations",
    errorMsg,
  } = options;

  return function (this: Yup.TestContext, translations: any) {
    const parentLanguage = this.parent[parentFieldName];
    const duplicateIndex = findDuplicateIndex(
      fieldName,
      translations,
      parentLanguage,
    );

    if (duplicateIndex !== -1) {
      throw this.createError({
        message: errorMsg ?? "Languages must be unique",
        path: `[${arrayName}][${duplicateIndex}][${fieldName}]`,
      });
    }

    return true;
  };
};
