import {
  useChangePasswordMutation,
  MutationChangePasswordArgs,
} from "graphql/_generated/graphql";

export const useChangePassword = () => {
  const [ChangePasswordFun, { isLoading }] = useChangePasswordMutation();

  const handleChangePassword = async (values: MutationChangePasswordArgs) => {
    try {
      await ChangePasswordFun({
        body: values.body,
      }).unwrap();
    } catch (error) {
      throw error;
    }
  };

  return {
    handleChangePassword,
    isLoading,
  };
};
