export const singleChoiceIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect opacity="0.24" width="24" height="24" rx="4" fill="#7573E1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 14.25C10.7595 14.25 9.75 13.2405 9.75 12C9.75 10.7595 10.7595 9.75 12 9.75C13.2405 9.75 14.25 10.7595 14.25 12C14.25 13.2405 13.2405 14.25 12 14.25ZM12 8.25C9.93225 8.25 8.25 9.93225 8.25 12C8.25 14.0677 9.93225 15.75 12 15.75C14.0677 15.75 15.75 14.0677 15.75 12C15.75 9.93225 14.0677 8.25 12 8.25ZM12 18C8.69175 18 6 15.3082 6 12C6 8.69175 8.69175 6 12 6C15.3082 6 18 8.69175 18 12C18 15.3082 15.3082 18 12 18ZM12 4.5C7.8645 4.5 4.5 7.8645 4.5 12C4.5 16.1355 7.8645 19.5 12 19.5C16.1355 19.5 19.5 16.1355 19.5 12C19.5 7.8645 16.1355 4.5 12 4.5Z"
        fill="#303030"
      />
    </svg>
  );
};
