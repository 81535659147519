import { useEffect, useState } from "react";
import languageData from "assets/data/languages.json";

type Language = { label: string; value: string };

export const useLanguages = () => {
  const [languages, setLanguages] = useState<Language[]>([]);
  const [languagesWithOutEn, setLanguagesWithOutEn] = useState<Language[]>([]);

  const getAppLanguages = async () => {
    const withOutEnData = languageData.filter((item: { value: string }) => {
      return item.value !== "en";
    });

    setLanguages(languageData);
    setLanguagesWithOutEn(withOutEnData);
  };

  useEffect(() => {
    getAppLanguages();
  }, []);

  return { languages, languagesWithOutEn };
};
