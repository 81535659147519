import { useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const useReCaptcha = (action: "SignUp" | "SignIn") => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [token, setToken] = useState<string | null>();
  const fetchToken = async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    try {
      const token = await executeRecaptcha(action);
      setToken(token);
    } catch (error) {
      console.error("Error executing reCAPTCHA:", error);
      setToken(null);
    }
  };
  useEffect(() => {
    fetchToken();
    const interval = setInterval(() => {
      fetchToken();
      return () => clearInterval(interval);
    }, 60000);
  }, [executeRecaptcha, action]);

  return {
    token,
    fetchToken,
  };
};

export default useReCaptcha;
