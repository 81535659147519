import { useToggleAchievementActivationMutation } from "graphql/_generated/graphql";

export const useToggleAchievementActivation = () => {
  const [ActivationDeactivationFun, { isLoading }] =
    useToggleAchievementActivationMutation();

  //this function is responsible for Activation/Deactivation Achievement
  const handleActivationAchievement = async (id: string) => {
    try {
      const res = await ActivationDeactivationFun({
        toggleAchievementActivationId: id,
      }).unwrap();
      return res.toggleAchievementActivation;
    } catch (error) {
      throw error;
    }
  };
  return {
    isLoading,
    handleActivationAchievement,
  };
};
