// import AuthLayout from "../auth-layout/AuthLayout";
// import { useRequestOtpMutation } from "graphql/_generated/graphql";
// import { useLocation, useNavigate } from "react-router-dom";

// interface OtpMethodNavigationData {
//   email: string;
// }

// const VerificationMethod = () => {
//   const location = useLocation();
//   console.log("::", location);
//   const navigate = useNavigate();
//   const state = location.state as OtpMethodNavigationData;

//   const [requestOtpFun] = useRequestOtpMutation();
//   const handleClick = async (type: OtpMethod) => {
//     console.log(type);
//     try {
//       const res = await requestOtpFun({
//         email: state.email,
//         method: type,
//       }).unwrap();
//       navigate("/otp-verification", {
//         state: { email: state.email },
//       });
//       console.log("OTP RESPONSE", res);
//     } catch (error) {
//       console.log("Error", error);
//     }
//   };

//   return (
//     <AuthLayout>
//       <h1>Select Verification Method</h1>
//       <button onClick={() => handleClick(OtpMethod.Email)}>Email</button>
//       <button onClick={() => handleClick(OtpMethod.Phone)}>Phone</button>
//     </AuthLayout>
//   );
// };

// export default VerificationMethod;

import React from "react";

const VerificationMethod = () => {
  return <div>VerificationMethod</div>;
};

export default VerificationMethod;
