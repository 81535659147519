import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { Form, Formik, FormikHelpers } from "formik";
import { FaqCreate } from "graphql/_generated/graphql";
import { useTranslation } from "react-i18next";
import { faqsSchema } from "validations/schema";
import TranslationFieldArr from "../translation-field-array/TranslationFieldArr";

type Props = {
  visible: boolean;
  editId?: string | null;
  handleCancel: () => void;
  initialValues: FaqCreate;
  isLoading: boolean;
  handleSubmit: (
    values: FaqCreate,
    { resetForm }: FormikHelpers<FaqCreate>,
  ) => void;
};

const AddFAQ = ({
  visible,
  editId,
  handleCancel,
  initialValues,
  handleSubmit,
  isLoading,
}: Props) => {
  const { t } = useTranslation();

  return (
    <CustomModal
      width={600}
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      title={t(editId ? "faq.edit-question" : "faq.add-question")}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={faqsSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <CustomTextField name="question" placeholder={t("question")} />
            <CustomTextField name="answer" placeholder={t("answer")} />
            <TranslationFieldArr values={values} />
            <ModalFooter
              saveText={t("save-question")}
              onCancel={handleCancel}
              loading={isLoading}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddFAQ;
