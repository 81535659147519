export const RewardSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3698 15.5002H8.68233V10.7268H14.1365V14.7427C14.1365 15.161 13.7932 15.5002 13.3698 15.5002ZM1.86425 14.7423V10.7273H7.31842V15.5006H2.63092C2.20758 15.5006 1.86425 15.1606 1.86425 14.7423ZM14.75 3.90917H13.215C13.3642 3.59833 13.455 3.25417 13.455 2.88583C13.455 1.57083 12.3842 0.5 11.0683 0.5C9.50083 0.5 8.55083 1.6 8 2.64083C7.44917 1.6 6.5 0.5 4.9325 0.5C3.61667 0.5 2.545 1.57083 2.545 2.88583C2.545 3.25417 2.63667 3.59833 2.78583 3.90917H1.25083C0.836667 3.90917 0.5 4.3975 0.5 5.00083V8.2725C0.5 8.875 0.836667 9.36417 1.25083 9.36417H1.86417H7.31833V3.90917H8.6825V9.36417H14.1367H14.75C15.1642 9.36417 15.5 8.875 15.5 8.2725V5.00083C15.5 4.3975 15.1642 3.90917 14.75 3.90917ZM11.0683 3.90917H8.9175C9.23917 3.0625 9.90083 1.86417 11.0683 1.86417C11.6325 1.86417 12.0917 2.3225 12.0917 2.88583C12.0917 3.45 11.6325 3.90917 11.0683 3.90917ZM4.9325 3.90917C4.36833 3.90917 3.90917 3.45 3.90917 2.88583C3.90917 2.3225 4.36833 1.86417 4.9325 1.86417C6.1 1.86417 6.76167 3.0625 7.08333 3.90917H4.9325Z"
        fill="#303030"
      />
    </svg>
  );
};
