import {
  useDeleteSubtitleMutation,
  MutationDeleteSubtitleArgs,
} from "graphql/_generated/graphql";

export const useDeleteSubTitle = () => {
  const [deleteSubTitleFun, { isLoading }] = useDeleteSubtitleMutation();

  const handleDeleteSubTitle = async (values: MutationDeleteSubtitleArgs) => {
    try {
      await deleteSubTitleFun({
        subtitleUri: values.subtitleUri,
        videoUri: values.videoUri,
      });
    } catch (error) {
      throw error;
    }
  };

  return {
    handleDeleteSubTitle,
    isLoading,
  };
};
