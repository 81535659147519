import * as Yup from "yup";
import * as Validations from "validations/schema/common/validations";

export const rangeSchema = Yup.object().shape({
  name: Validations.requiredFieldWithoutSpaces("group-name"),
  scoreRanges: Yup.array().of(
    Yup.object().shape({
      name: Validations.required("range-name"),
      start: Validations.requiredFieldPositiveNumber("start-points")
        .min(0, "error:positive-integer")
        .max(100, "max-100-chars"),
      end: Validations.requiredFieldPositiveNumber("end-points")
        .min(0, "error:positive-integer")
        .max(100, "max-100-chars"),
      color: Validations.required("color"),
    })
  ),
});
