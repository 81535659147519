import {
  convertTranslationArrToObj,
  convertTranslationObjToArr,
} from "helpers";
import {
  useLazyGetContentQuery,
  useLazyGetVideoDetailsQuery,
  useUpdateAudioContentMutation,
  useUpdateFileContentMutation,
  useUpdateRecipeContentMutation,
  useUpdateTextContentMutation,
  useUpdateVideoContentMutation,
  ContentType,
} from "graphql/_generated/graphql";

export const useUpdateContent = () => {
  const [prevEditDataFun, { isLoading }] = useLazyGetContentQuery();
  const [getVideoDetailsFun] = useLazyGetVideoDetailsQuery();

  const [updateAudioFun, { isLoading: updateAudioContentLoading }] =
    useUpdateAudioContentMutation();

  const [updateVideoContentFun, { isLoading: updateVideoContentLoading }] =
    useUpdateVideoContentMutation();

  const [updateFileContentFun, { isLoading: updateFileContentLoading }] =
    useUpdateFileContentMutation();

  const [updateTextContentFun, { isLoading: updateTextContentLoading }] =
    useUpdateTextContentMutation();

  const [updateRecipeContentFun, { isLoading: updateRecipeContentLoading }] =
    useUpdateRecipeContentMutation();

  //   this function is responsible for fetching the specific content data which content id we will be provided
  const handleFetchEditContentData = async (id: string) => {
    try {
      const res = await prevEditDataFun({ getContentId: id });
      const translations = await convertTranslationObjToArr(
        res.data?.getContent.translations,
      );

      const tagsArr: string[] = [];
      res.data?.getContent.tags
        .filter((item: any) => item.isActive === true)
        .map((tag: { id: string }) => {
          tagsArr.push(tag.id);
        });

      return { ...res.data?.getContent, translations, tags: tagsArr };
    } catch (error) {}
  };

  //this function is responsible for updating the content data
  const handleUpdateContent = async (editId: string, values: any) => {
    const { contentType } = values.updateObj;

    try {
      let contentTranslations = await convertTranslationArrToObj(
        values.updateObj.translations,
      );

      let params = {
        updateObj: { ...values.updateObj, translations: contentTranslations },
      };
      let res;
      const {
        points,
        status,
        language,
        spokenLanguage,
        thumbnailImage,
        translations,
        title,
        description,
        tagIds,
        categoryIds,
        pillarIds,
        creatorId,
        audioUrl,
        duration,
        coverImage,
        localizedAudios,
        localizedVideos,
        localizedFiles,
        localizedTexts,
        fileUrl,
        videoUrl,
        text,
        type,
        relatedRecipes,
        timeRequired,
        serving,
        shortDescription,
      } = params.updateObj;
      const commonParams = {
        points,
        status,
        language,
        spokenLanguage,
        thumbnailImage,
        translations,
        title,
        description,
        tagIds,
        categoryIds,
        pillarIds,
        creatorId,
        shortDescription,
      };

      if (contentType === ContentType.Audio) {
        res = await updateAudioFun({
          updateAudioContentId: editId,
          updateObj: {
            ...commonParams,
            audioUrl,
            localizedAudios,
            coverImage,
            duration,
          },
        }).unwrap();
      } else if (contentType === ContentType.Video) {
        res = await updateVideoContentFun({
          updateVideoContentId: editId,
          updateObj: {
            ...commonParams,
            localizedVideos,
            videoUrl,
            duration,
          },
        }).unwrap();
      } else if (contentType === ContentType.File) {
        res = await updateFileContentFun({
          updateFileContentId: editId,
          updateObj: {
            ...commonParams,
            localizedFiles,
            fileUrl,
          },
        }).unwrap();
      } else if (contentType === ContentType.Text) {
        res = await updateTextContentFun({
          updateTextContentId: editId,
          updateObj: {
            ...commonParams,
            localizedTexts,
            text,
          },
        }).unwrap();
      } else if (contentType === ContentType.Recipe) {
        const gallery = params.updateObj.gallery.map((item: any) => {
          return item["image"];
        });
        res = await updateRecipeContentFun({
          updateRecipeContentId: editId,
          updateObj: {
            ...commonParams,
            gallery,
            relatedRecipes,
            timeRequired,
            serving,
          },
        }).unwrap();
      }
    } catch (error: any) {
      throw error;
    }
  };

  return {
    isLoading:
      isLoading ||
      updateAudioContentLoading ||
      updateVideoContentLoading ||
      updateFileContentLoading ||
      updateTextContentLoading ||
      updateRecipeContentLoading,
    handleFetchEditContentData,
    handleUpdateContent,
  };
};
