import {
  useGetTierListQuery,
  useToggleTierActivationMutation,
} from "graphql/_generated/graphql";

export const useToggleTierActivation = () => {
  const [toggleTierFun, { isLoading }] = useToggleTierActivationMutation();

  const { refetch } = useGetTierListQuery();

  const handleTierActivation = async (id: string) => {
    try {
      const res = await toggleTierFun({
        toggleTierActivationId: id,
      }).unwrap();
      refetch();

      return res.toggleTierActivation;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleTierActivation,
    isLoading,
  };
};
