// import CustomButton from "app/components/common/custom-button/CustomButton";
// import DashboardLayout from "app/components/dashboard/dashboard-layout/DashboardLayout";
// import { useState } from "react";
// import { useTranslation } from "react-i18next";
// import CheckPermissions from "../common/check-permissions/CheckPermissions";
// import { PERMS } from "app/helpers";
// import AddExpert from "./add-expert/AddExpert";
// import ExpertsList from "./experts-list/ExpertsList";
// import styles from "./Experts.module.scss";
// type Props = {};

// const Experts = (props: Props) => {
//   const { t } = useTranslation();
//   const [visible, setVisible] = useState(false);
//   return (
//     <>
//       <DashboardLayout openKey={["Users"]}>
//         <div className={styles["expert"]}>
//           <CheckPermissions permission={PERMS.INVITE_EXPERT}>
//             <CustomButton
//               className="primary-btn"
//               onClick={() => setVisible(true)}
//             >
//               {t("expert.add-expert")}
//             </CustomButton>
//           </CheckPermissions>
//         </div>

//         <ExpertsList />
//       </DashboardLayout>
//       <AddExpert visible={visible} setVisible={setVisible} />
//     </>
//   );
// };

export default () => {}; // Experts;
