import customNotification from "components/custom-notification/CustomNotification";
import {
  Comparison,
  PartnerRewardList,
  useGetConfigRewardTypesQuery,
  useGetConfigTiersQuery,
  useGetPartnerRewardListQuery,
  useLazyGetPartnerRewardListQuery,
} from "graphql/_generated/graphql";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useReward } from "./useReward";
interface PartnerRewardsProps {
  partnerId: string;
}

export const usePartnerRewardsViewModel = ({
  partnerId,
}: PartnerRewardsProps) => {
  const { t } = useTranslation();
  const [langVisibility, setLangVisibility] = useState(false);
  const [langCode, setLangCode] = useState<string>();
  const [isAddLanguage, setIsAddLanguage] = useState(false);
  const [isAddPartnerReward, setIsAddPartnerReward] = useState(false);
  const [editId, setEditId] = useState<string | null>(null);
  const [isActivated, setIsActivated] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [rewardData, setRewardData] = useState<MappedPartnerRewardList>();
  const [isBulkUpload, setIsBulkUpload] = useState(false);

  const [columnsData, setColumnsData] = useState<PartnerRewardList>();
  const [filtersData, setFiltersData] = useState<any>(null);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const { isFetching } = useGetPartnerRewardListQuery();
  const [getPartnerListFun] = useLazyGetPartnerRewardListQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const { data: tiersData } = useGetConfigTiersQuery();
  const { data: rewardTypesData } = useGetConfigRewardTypesQuery();
  const { handleError } = useErrorHandler();

  const {
    handleTogglePartnerReward,
    handleDeleteReward,
    isLoading: isLoadingReward,
  } = useReward();

  const partnerFilter = {
    key: "partnerId",
    value: partnerId,
    comparison: Comparison.Equal,
  };

  useEffect(() => {
    setFiltersData({
      filters: {
        and: [partnerFilter],
      },
    });
  }, []);

  useEffect(() => {
    if (!filtersData) {
      fetchDataFromApi(getPartnerListFun, setColumnsData, {
        filters: {
          and: [partnerFilter],
        },
      });
    } else {
      fetchDataFromApi(getPartnerListFun, setColumnsData, filtersData);
    }
  }, [isFetching, filtersData]);

  const mappedColumnsData = columnsData?.data.map((item) => {
    return (
      {
        ...item,
        hasChildren: true,
        children:
          item?.translations && Object.keys?.(item?.translations)
            ? Object.keys?.(item?.translations)?.map((record) => {
                return {
                  ...item,
                  name: item?.translations?.[record]?.name,
                  language: record,
                };
              })
            : null,
      } ?? []
    );
  });
  type MappedPartnerRewardList = NonNullable<typeof mappedColumnsData>[0];

  const handleSubmit = (values: any, actions: any) => {
    setLangCode(values?.language);
    setLangVisibility(false);
    setIsAddPartnerReward(true);
    actions.resetForm();
  };

  const handleMenu = ({ key, id, record }: any) => {
    if (key === "edit-reward") {
      setIsAddLanguage(false);
      setEditId(id);
      setIsAddPartnerReward(true);
      setLangCode(undefined);
    }
    if (key === "delete-reward") {
      setRewardData(record);
      setIsDelete(true);
      () => submitDeleteReward();
    }
    if (key === "add-new-language") {
      setRewardData(record);
      setLangVisibility(true);
      setEditId(id);
      setIsAddLanguage(true);
    }
    if (key === "edit-language") {
      setLangCode(record.language);
      setIsAddLanguage(true);
      setEditId(id);
      setIsAddPartnerReward(true);
    }
  };

  const handleToggleReward = (record: MappedPartnerRewardList) => {
    setRewardData(record);
    setIsActivated(true);
  };

  const handleToggleRewardActivation = async () => {
    try {
      const isActivated = await handleTogglePartnerReward(rewardData?.id ?? "");
      customNotification(
        "success",
        isActivated
          ? t("reward-activated-successfully")
          : t("reward-deactivated-successfully"),
      );
      setIsActivated(false);
      setRewardData(undefined);
      return isActivated;
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const submitDeleteReward = async () => {
    try {
      const res = await handleDeleteReward(rewardData?.id);
      customNotification("success", t("deleted-reward-successfully"));
      setIsDelete(false);
      setRewardData(undefined);
      return res;
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const filtersCallback = async (filtersData: any) => {
    const mergedFilters = { ...filtersData };
    mergedFilters.filters = {
      ...mergedFilters.filters,
      and: [partnerFilter, ...(mergedFilters.filters?.and || [])],
    };
    setPageSize(mergedFilters?.pagination?.offset?.pageSize);
    setCurrentPage(mergedFilters?.pagination?.offset?.page);
    setFiltersData(mergedFilters);
    fetchDataFromApi(getPartnerListFun, setColumnsData, mergedFilters);
  };

  return {
    mappedColumnsData,
    pageSize,
    currentPage,
    handleToggleReward,
    handleMenu,
    setIsBulkUpload,
    setLangVisibility,
    setIsAddLanguage,
    setRewardData,
    filtersCallback,
    rewardTypesData,
    tiersData,
    columnsData,
    isLoading,
    langVisibility,
    isAddLanguage,
    handleSubmit,
    rewardData,
    isAddPartnerReward,
    setIsAddPartnerReward,
    editId,
    setEditId,
    langCode,
    isLoadingReward,
    isActivated,
    setIsActivated,
    handleToggleRewardActivation,
    isDelete,
    setIsDelete,
    submitDeleteReward,
    isBulkUpload,
  };
};
