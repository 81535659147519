import {
  CreateQuestMutationVariables,
  useCreateQuestMutation,
} from "graphql/_generated/graphql";

export const useAddQuest = () => {
  const [addQuestFun, { isLoading }] = useCreateQuestMutation();

  const handleAddQuest = async (values: CreateQuestMutationVariables) => {
    try {
      const res = await addQuestFun({ quest: values.quest }).unwrap();
      return res.createQuest;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleAddQuest,
    isLoading,
  };
};
