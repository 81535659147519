import * as Yup from "yup";

export async function yupValidator<T extends Array<any>>(
  schema: Yup.AnySchema,
  data: any[]
): Promise<T | string> {
  try {
    return await schema.validate(data);
  } catch (err: any) {
    const error: any = new Error(''); 
    error.error_list = err.errors; 
    throw error;
  }
}
