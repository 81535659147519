import * as Yup from "yup";
import * as Validations from "validations/schema/common/validations";

export const clientProfileSchema = Yup.object().shape({
  clientAdmin: Yup.object().shape({
    firstName: Validations.requiredFieldWithoutSpaces("first-name"),
    lastName: Validations.requiredFieldWithoutSpaces("last-name"),
  }),

  phoneNumber: Validations.phone("phone-number"),

  companySize: Validations.required("company-size"),
  mainFocus: Validations.requiredArrayOfStringValidation("company-main-focus"),
  avgAgeOfEmployee: Validations.required("error:avg-age-emp"),
  goal: Validations.required("goal"),
  // buildings: Yup.array()
  //   .of(
  //     Yup.object().shape({
  //       name: Validations.requiredFieldWithoutSpaces("name"),
  //       address: Yup.object().shape({
  //         address: Validations.required("address"),
  //         coordinates: Yup.object().shape({
  //           lat: Validations.required("latitude"),
  //           lng: Validations.required("longitude"),
  //         }),
  //         country: Validations.required("country"),
  //       }),
  //     })
  //   )
  //   .required("required"),
});
