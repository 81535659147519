import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import CustomLanguageSelector from "components/form/custom-language-selector/CustomLanguageSelector";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import AddRewardCategory from "features/rewards/category/AddRewardCategory";
import { CategoryList } from "features/rewards/category/CategoryList";
import { useCategoryListViewModel } from "features/rewards/hooks/useCategoryListViewModel";
import { useTranslation } from "react-i18next";
interface CategoriesProps {}

const Categories: React.FunctionComponent<CategoriesProps> = (props) => {
  const { t } = useTranslation();
  const vm = useCategoryListViewModel();

  return (
    <DashboardLayout openKey={["Rewards"]}>
      <Breadcrumbs
        data={[
          {
            label: t("rewards"),
          },
          {
            label: t("reward-categories"),
          },
        ]}
      />
      <CategoryList setVisible={vm.setVisible} />
      {vm.visible && (
        <CustomLanguageSelector
          visible={vm.visible}
          setVisible={vm.setVisible}
          onSubmit={vm.handleSubmit}
          title={`${t("category")} ${t("main-language")}`}
          label={t("category")}
          saveText={t("add-category")}
        />
      )}
      {vm.isAddCategory && (
        <AddRewardCategory
          visible={vm.isAddCategory}
          editId={null}
          langCode={vm.langCode}
          handleCancel={vm.handleCancel}
          handleSubmit={vm.handleAddSubmit}
          isLoading={vm.isLoadingTier}
          initialValues={vm.initialValues}
        />
      )}
    </DashboardLayout>
  );
};

export default Categories;
