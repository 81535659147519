import { Divider } from "antd";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import CustomTextEditor from "components/form/custom-text-editor/CustomTextEditor";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import {
  filterLanguagesWithout,
  getLabelFromValue,
} from "features/content/utils";
import { FieldArray, FieldArrayRenderProps, useFormikContext } from "formik";
import { useLanguages } from "hooks";
import { useTranslation } from "react-i18next";
import styles from "./css/Specialist.module.scss";

const EXCLUDED_LANG = "en";

type TranslationValue = {
  lang: string;
  [key: string]: string;
};

type Props = {
  values?: {
    translations: TranslationValue[];
  };
};

const AddButton = ({ onAdd }: { onAdd: () => void }) => {
  const { t } = useTranslation();

  return (
    <div className={styles["form-control"]}>
      <span className={styles["add-btn"]} onClick={onAdd}>
        + {t("add-new-language")}
      </span>
    </div>
  );
};

const TranslationFields = ({ values }: Props) => {
  const { t } = useTranslation();
  const { languages } = useLanguages();

  const renderTranslationFields = ({
    form,
    push,
    remove,
  }: FieldArrayRenderProps) => {
    const { translations } = form.values;

    if (!translations || translations.length === 0) {
      return (
        <AddButton
          onAdd={() => push({ lang: "", title: "", description: "" })}
        />
      );
    }

    return translations.map((translation: TranslationValue, index: number) => (
      <div key={index}>
        <div className={styles["multi-lang-box"]}>
          <CustomSelectField
            label={t("select-another-language")}
            required={true}
            value={getLabelFromValue(languages, values, index)}
            name={`translations.${index}.lang`}
            placeholder={t("select-language")}
            options={filterLanguagesWithout(
              languages,
              values?.translations,
              EXCLUDED_LANG,
            )}
            defaultValue={getLabelFromValue(languages, values, index)}
            className="form-select"
          />
        </div>
        <CustomTextField
          name={`translations.${index}.firstName`}
          label={t(`specialist.first-name-translation`)}
          placeholder={t("first-name")}
        />
        <CustomTextField
          name={`translations.${index}.lastName`}
          label={t(`specialist.last-name-translation`)}
          placeholder={t("last-name")}
        />
        <CustomTextField
          name={`translations.${index}.specialty`}
          label={t(`specialist.specialty-translation`)}
          placeholder={t("specialty")}
        />
        <CustomTextEditor
          label={t("specialist.description-translation")}
          name={`translations.${index}.description`}
          placeholder={t("enter-description")}
        />

        <div className={styles["flex-div"]}>
          {translations.length + 1 < languages.length ? (
            <AddButton
              onAdd={() => push({ lang: "", title: "", description: "" })}
            />
          ) : (
            ""
          )}
          <span className={styles["remove-btn"]} onClick={() => remove(index)}>
            - {t("remove-language")}
          </span>
        </div>
        <Divider />
      </div>
    ));
  };

  return <FieldArray name="translations" render={renderTranslationFields} />;
};

export default TranslationFields;
