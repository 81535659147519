import { List, Space } from "antd";
import * as React from "react";
import styles from "features/events/css/TeamLeaderBoard.module.scss";
import { useTranslation } from "react-i18next";
import EditTeam from "./EditTeam";
import { getRanks } from "helpers";
import { useState } from "react";
import { useViewMembersViewModel } from "features/events/hooks/useViewMembersViewModel";
interface TeamLeaderBoardItemProps {
  eventId: string;
  itemKey: number;
  id: string;
  teamTitle: string;
  color: string;
  score: number;
  members: number;
  onPressTeamMember: (id: string) => void;
}

const TeamLeaderBoardItem: React.FunctionComponent<
  TeamLeaderBoardItemProps
> = ({
  itemKey,
  id,
  color,
  score,
  teamTitle,
  members,
  eventId,
  onPressTeamMember,
}) => {
  const { t } = useTranslation();
  const vm = useViewMembersViewModel();
  return (
    <>
      <List.Item key={id}>
        <div className={styles["item-grid"]}>
          <div>
            <Space size={14}>
              <div className={styles["item-id"]}>{getRanks(itemKey)}</div>
              <div
                className={styles["team-color"]}
                style={{
                  backgroundColor: color,
                }}
              />
              <div className={styles["sub-title"]}>{teamTitle}</div>
            </Space>
          </div>
          <div
            className={styles["members"]}
            onClick={() => onPressTeamMember(id)}
          >{`${members} ${t("members")}`}</div>
          <div className={styles["users"]}>
            <div className={styles["count"]}>{score}</div>
          </div>
        </div>
      </List.Item>
    </>
  );
};

export default TeamLeaderBoardItem;
