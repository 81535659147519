import { Checkbox, Col, Row } from "antd";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { FunctionComponent, useState } from "react";
import styles from "features/challenge/css/AddActivity.module.scss";
import CollapseAble from "features/assessment/questions/collapseables";
import ContainerHeading from "features/assessment/questions/ContainerHeading";
import { ChallengeGender } from "features/challenge/data/ChallengeValidations";
import CustomDivider from "components/custom-divider/CustomDivider";
import {
  useGetClientsTreeQuery,
  useGetDepartmentsConfigQuery,
} from "graphql/_generated/graphql";
import { usePositionsIdAndName } from "hooks/usePositionIdAndName";
import { useLoggedInUser } from "hooks/useLoggedInUser";

interface ChallengeValidationsProps {
  setFieldValue?: any;
  disabled?: boolean;
  enableValidations: any;
  setEnableValidations: any;
  values: any;
}

const ChallengeValidations: FunctionComponent<ChallengeValidationsProps> = ({
  disabled,
  enableValidations,
  setEnableValidations,
}) => {
  const { data: departmentData } = useGetDepartmentsConfigQuery();
  const { positionData } = usePositionsIdAndName();
  const { isClient } = useLoggedInUser();

  const { data: childCompanies } = useGetClientsTreeQuery(
    { clientId: null },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const handleChange = (e: any, key: string) => {
    setEnableValidations({ ...enableValidations, [key]: e.target.checked });
  };

  return (
    <CollapseAble
      defaultActiveKey={["challenge-validations"]}
      headerKey={"challenge-validations"}
      className={styles["activity-card"]}
      header={
        <div className="d-flex">
          <ContainerHeading text="Challenge Validations" />
        </div>
      }
      hideExtra={true}
      expandIconPosition="end"
    >
      <div className={styles["content-card"]}>
        {isClient ? (
          <Row gutter={[24, 0]}>
            <Col sm={24} className={`${styles["viwell-checkbox"]}`}>
              <Checkbox
                checked={enableValidations.isChildCompany}
                disabled={disabled}
                onChange={(e) => handleChange(e, "isChildCompany")}
              >
                Child Companies
              </Checkbox>
            </Col>
            <Col sm={12}>
              <CustomSelectField
                label="Companies"
                name="companies"
                className="form-select"
                mode="multiple"
                showEmptyLabel={true}
                placeholder="Select Company"
                options={childCompanies?.getClientsTree}
                disabled={!enableValidations.isChildCompany || disabled}
                fieldNames={{ label: "companyName", value: "id" }}
              />
            </Col>
          </Row>
        ) : null}

        <Row gutter={[24, 0]}>
          <Col sm={12} className={`${styles["viwell-checkbox"]}`}>
            <Checkbox
              checked={enableValidations.isMinAge}
              disabled={disabled}
              onChange={(e) => handleChange(e, "isMinAge")}
            >
              Minimum Age
            </Checkbox>
          </Col>
          <Col sm={12} className={`${styles["viwell-checkbox"]}`}>
            <Checkbox
              checked={enableValidations.isMaxAge}
              disabled={disabled}
              onChange={(e) => handleChange(e, "isMaxAge")}
            >
              Maximum Age
            </Checkbox>
          </Col>
          <Col sm={24} md={12}>
            <CustomTextField
              type="number"
              label="Minimum Age"
              showEmptyLabel={true}
              name="validations.age.min"
              placeholder="Enter Min Age"
              disabled={!enableValidations.isMinAge || disabled}
            />
          </Col>
          <Col sm={24} md={12}>
            <CustomTextField
              type="number"
              label="Maximum Age"
              showEmptyLabel={true}
              name="validations.age.max"
              placeholder="Enter Max Age"
              disabled={!enableValidations.isMaxAge || disabled}
            />
          </Col>
        </Row>
        <CustomDivider />

        <Row gutter={[24, 0]}>
          <Col
            sm={24}
            md={isClient ? 12 : 24}
            className={`${styles["viwell-checkbox"]}`}
          >
            <Checkbox
              checked={enableValidations.isGender}
              disabled={disabled}
              onChange={(e) => handleChange(e, "isGender")}
            >
              Gender
            </Checkbox>
          </Col>
          {isClient ? (
            <Col sm={24} md={12} className={`${styles["viwell-checkbox"]}`}>
              <Checkbox
                checked={enableValidations.isDepartment}
                disabled={disabled}
                onChange={(e) => handleChange(e, "isDepartment")}
              >
                Department
              </Checkbox>
            </Col>
          ) : null}
          <Col sm={24} md={12}>
            <CustomSelectField
              label="Gender"
              name="validations.gender"
              showEmptyLabel={true}
              placeholder="Gender"
              className="form-select"
              options={ChallengeGender}
              disabled={!enableValidations.isGender || disabled}
              fieldNames={{ label: "name", value: "id" }}
            />
          </Col>
          {isClient ? (
            <Col sm={24} md={12}>
              <CustomSelectField
                label="Select Department"
                name="validations.departmentIds"
                className="form-select"
                mode="multiple"
                showEmptyLabel={true}
                placeholder="Select Department"
                options={departmentData?.getDepartmentsConfig.options}
                disabled={!enableValidations.isDepartment || disabled}
                fieldNames={{ label: "label", value: "id" }}
              />
            </Col>
          ) : null}
        </Row>
        <CustomDivider />

        {isClient ? (
          <Row gutter={[24, 0]}>
            <Col sm={24} className={`${styles["viwell-checkbox"]}`}>
              <Checkbox
                checked={enableValidations.isPosition}
                disabled={disabled}
                onChange={(e) => handleChange(e, "isPosition")}
              >
                Position
              </Checkbox>
            </Col>
            <Col sm={24} md={12}>
              <CustomSelectField
                label="Select Position"
                name="validations.positionIds"
                mode="multiple"
                className="form-select"
                placeholder="Select Position"
                options={positionData}
                showEmptyLabel={true}
                disabled={!enableValidations.isPosition || disabled}
                fieldNames={{ label: "label", value: "value" }}
              />
            </Col>
          </Row>
        ) : null}
      </div>
    </CollapseAble>
  );
};
export default ChallengeValidations;
