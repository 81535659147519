import { useToggleEapActivationMutation } from "graphql/_generated/graphql";

export const useToggleActivationEAP = () => {
  const [ActivationDeactivationFun, { isLoading }] =
    useToggleEapActivationMutation();

  //this function is responsible for Activation/Deactivation EAP
  const handleActivationEAP = async (id: string) => {
    try {
      const res = await ActivationDeactivationFun({
        toggleEapActivationId: id,
      }).unwrap();
      return res.toggleEAPActivation;
    } catch (error) {
      throw error;
    }
  };

  return {
    isLoading,
    handleActivationEAP,
  };
};
