import { convertTranslationArrToObj } from "helpers";
import {
  AddNewRoleMutationVariables,
  useAddNewRoleMutation,
} from "graphql/_generated/graphql";

export const useAddRole = () => {
  
  const [addNewRoleFun, { isLoading }] = useAddNewRoleMutation();

  //this function is responsible for adding the new role
  const handleAddNewRole = async (values: AddNewRoleMutationVariables) => {
    try {
      let translations = await convertTranslationArrToObj(
        values.create.translations
      );
      let params = { create: { ...values.create, translations } };
      const res = await addNewRoleFun(params).unwrap();
      return res.addNewRole.id;
    } catch (error: any) {
      throw error;
    }
  };

  return {
    isLoading,
    handleAddNewRole,
  };
};
