import CustomDivider from "components/custom-divider/CustomDivider";
import styles from "features/users/css/InviteMultipleUsers.module.scss";
import { EmployeeBulkCreate } from "graphql/_generated/graphql";
import moment from "moment";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface PreviewDataTableProps {
  data?: EmployeeBulkCreate[];
  previewKeys?: {
    [key: string]: boolean;
  };
}

const PreviewDataTable: FunctionComponent<PreviewDataTableProps> = ({
  data,
  previewKeys,
}) => {
  const { t } = useTranslation();

  const calculateWidth = (field: any, nestedField?: any) => {
    let maxWidth = 0;
    data?.forEach((item: any) => {
      const size =
        getTextWidth(
          nestedField ? item[field][nestedField] : item[field],
          "normal 14px sans-serif",
        ) + 12;
      if (size > maxWidth) {
        maxWidth = size;
      }
    });
    return maxWidth;
  };

  function getTextWidth(text: any, font?: any) {
    const canvas = document.createElement("canvas");
    const context: any = canvas.getContext("2d");
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  }

  const headersTitle: { [key: string]: string } = {
    firstName: "first-name",
    lastName: "last-name",
    dob: "date-of-birth",
    gender: "gender",
    email: "email",
    phoneNumber: "mobile",
    position: "position-name",
    building: "building",
    department: "department",
    address: "address",
    city: "company.city",
    country: "company.country",
  };

  const renderFieldHeader = (key: string, nestedKey?: string) =>
    previewKeys?.["all"] || previewKeys?.[key] ? (
      <div
        className={`${styles["row-header"]} ${styles["row-item-spacing"]} ${
          previewKeys?.["all"] ? "" : styles["row-flex"]
        }`}
        style={{
          width: calculateWidth(key, nestedKey),
        }}
      >
        {t(`${headersTitle[nestedKey ? nestedKey : key]}`)}
      </div>
    ) : null;

  const renderNestedFieldHeader = (key: string, nestedKey: string) =>
    previewKeys?.["all"] || previewKeys?.[nestedKey] ? (
      <div
        className={`${styles["row-header"]} ${styles["row-item-spacing"]} ${
          previewKeys?.["all"] ? "" : styles["row-flex"]
        }`}
        style={{
          width: calculateWidth(key, nestedKey),
        }}
      >
        {t(`${headersTitle[nestedKey ? nestedKey : key]}`)}
      </div>
    ) : null;

  const renderFieldContent = (item: any, key: string) =>
    previewKeys?.["all"] || previewKeys?.[key] ? (
      <div
        className={`${styles["row-item-spacing"]} ${
          previewKeys?.["all"] ? "" : styles["row-flex"]
        }`}
        style={{
          width: calculateWidth(key),
        }}
      >
        {key === "dob" && item?.[key]
          ? moment(item?.[key]).format("DD/MM/YYYY")
          : item?.[key]}
      </div>
    ) : null;

  const renderNestedFieldContent = (
    item: any,
    key: string,
    nestedKey: string,
  ) =>
    previewKeys?.["all"] || previewKeys?.[nestedKey] ? (
      <div
        className={`${styles["row-item-spacing"]} ${
          previewKeys?.["all"] ? "" : styles["row-flex"]
        }`}
        style={{
          width: calculateWidth(key, nestedKey),
        }}
      >
        {item?.[key]?.[nestedKey]}
      </div>
    ) : null;

  return (
    <div
      className={`${styles["preview-container"]} ${
        previewKeys?.["all"] ? styles["preview-content-scroll"] : ""
      }`}
    >
      <div
        className={`${styles["data-table-container"]} ${
          previewKeys?.["all"] ? styles["fit-container-width"] : ""
        }`}
      >
        <div className={styles["data-table-row"]}>
          {renderFieldHeader("firstName")}
          {renderFieldHeader("lastName")}
          {renderFieldHeader("dob")}
          {renderFieldHeader("gender")}
          {renderFieldHeader("email")}
          {renderFieldHeader("phoneNumber")}
          {renderFieldHeader("position")}
          {renderFieldHeader("building")}
          {renderFieldHeader("department")}
          {renderNestedFieldHeader("address", "address")}
          {renderNestedFieldHeader("address", "city")}
          {renderNestedFieldHeader("address", "country")}
        </div>
        <CustomDivider />
        {data?.map((item: any, index: number) => (
          <>
            <div className={styles["data-table-row"]}>
              {renderFieldContent(item, "firstName")}
              {renderFieldContent(item, "lastName")}
              {renderFieldContent(item, "dob")}
              {renderFieldContent(item, "gender")}
              {renderFieldContent(item, "email")}
              {renderFieldContent(item, "phoneNumber")}
              {renderFieldContent(item, "position")}
              {renderFieldContent(item, "building")}
              {renderFieldContent(item, "department")}
              {renderNestedFieldContent(item, "address", "address")}
              {renderNestedFieldContent(item, "address", "city")}
              {renderNestedFieldContent(item, "address", "country")}
            </div>
            <CustomDivider />
          </>
        ))}
      </div>
    </div>
  );
};
export default PreviewDataTable;
