import * as Yup from "yup";
import * as Validations from "validations/schema/common/validations";
import EnumQuestionHierarchy from "features/assessment/types/EnumQuestionHierarchy";
import EnumAssessmentTypes from "features/assessment/types/EnumAssessmentTypes";

export const assessmentSchema = Yup.object().shape({
  description: Validations.required("description"),
});

export function assessmentQuestionSchema(settings?: any) {
  const optional = Yup.string().nullable();
  const optionalArray = Yup.array().nullable();

  // const tagsValidation = settings?.isTagsEnable
  // ? Validations.requiredArrayOfStringValidation("tag")
  // : optionalArray;

  const tagsValidation = optionalArray;

  const scoreValidation = Yup.number()
    .required("Score is required.")
    .min(0, "Score should be greater or than equal to 0");

  const optionsArray = Yup.array().of(
    Yup.object().shape({
      text: Validations.required("choice"),
      score: settings?.isScoresEnable ? scoreValidation : optional,
      emoji: optional,
      tags: tagsValidation,
    }),
  );

  const ratingsArray = Yup.array().of(
    Yup.object().shape({
      score: settings?.isScoresEnable ? scoreValidation : optional,
      tags: tagsValidation,
    }),
  );

  const optionsValidation = Yup.array()
    .when("optionType", {
      is: "singleChoice",
      then: optionsArray,
    })
    .when("optionType", {
      is: "multipleChoice",
      then: optionsArray,
    })
    .when("optionType", {
      is: "ranking",
      then: optionsArray,
    });

  const sliderArray = Yup.array().of(
    Yup.object().shape({
      score: settings?.isScoresEnable ? scoreValidation : optional,
      emoji: optional,
      tags: tagsValidation,
      start: Validations.requiredFieldNumber("start"),
      end: Validations.requiredFieldNumber("end"),
    }),
  );

  const matricesArray = Yup.array().of(
    Yup.object().shape({
      score: settings?.isScoresEnable ? scoreValidation : optional,
      emoji: optional,
      text: Validations.required("text"),
      tags: tagsValidation,
    }),
  );

  const stringsArray = (key: string) =>
    Yup.array().of(
      Yup.object().shape({
        value: Validations.required(key),
      }),
    );

  const booleansValidation = Yup.array().when("optionType", {
    is: "boolean",
    then: optionsArray,
  });

  const ratingValidation = Yup.array().when("optionType", {
    is: "rating",
    then: ratingsArray,
  });

  const sliderValidation = Yup.array().when("optionType", {
    is: "slider",
    then: sliderArray,
  });

  const matrixValidation = Yup.array().when("optionType", {
    is: "matrix",
    then: matricesArray,
  });

  const metricOptionsValidation = Yup.array().when("optionType", {
    is: "matrix",
    then: stringsArray("option"),
  });

  const metricTitlesValidation = Yup.array().when("optionType", {
    is: "matrix",
    then: stringsArray("title"),
  });

  return Yup.object().shape({
    categoryId: Yup.string().when("hierarchy", {
      is: EnumQuestionHierarchy.PILLAR_QUESTION,
      then: Yup.string().required("Category is required"),
    }),
    area: Yup.string().when("hierarchy", {
      is: (hierarchy: any) => {
        return (
          settings?.assessmentTypeName ==
            EnumAssessmentTypes.VIWELL_ASSESSMENT &&
          hierarchy == EnumQuestionHierarchy.PILLAR_QUESTION
        );
      },
      then: Yup.string().required("Area is required"),
    }),
    // .when("hierarchy", {
    //   is: EnumQuestionHierarchy.PILLAR_QUESTION,
    //   then: Yup.string().required("Area is required"),
    // }),
    parentOptionId: Yup.array().when("hierarchy", {
      is: EnumQuestionHierarchy.SUB_QUESTION,
      then: tagsValidation,
    }),
    optionType: Validations.required("optionType"),
    text: Validations.required("question"),
    score: settings?.isScoresEnable ? scoreValidation : optional,
    isEmojiEnable: Yup.boolean().required(),
    options: optionsValidation,
    booleans: booleansValidation,
    ratings: ratingValidation,
    steps: sliderValidation,
    metrices: matrixValidation,
    metricOptions: metricOptionsValidation,
    metricTitles: metricTitlesValidation,
    start: Yup.string().when("optionType", {
      is: "slider",
      then: Yup.string().required("Start is required"),
    }),
    end: Yup.string().when("optionType", {
      is: "slider",
      then: Yup.string().required("End is required"),
    }),
    minChar: Yup.string().when("optionType", {
      is: "text",
      then: Yup.string().required("Minimum Characters are required"),
    }),
    maxChar: Yup.string().when("optionType", {
      is: "text",
      then: Yup.string().required("Maximum Characters are required"),
    }),
  });
}

export const sliderOptionsRangeValidation = (values: any): string => {
  const { steps: options, start, end, isEmojiEnable } = values;

  const MainQuestionStart = start;
  const MainQuestionEnd = end;
  let message: string = "";

  if (
    !!isEmojiEnable &&
    options.find((option: any) => !option?.text || !option?.text?.length)
  )
    return "Emoji titles are required.";
  else if (options[0]["start"] !== MainQuestionStart)
    // first option START should match MainQuestionStart
    return "First option Start should match Overall Range Start!";
  // last option END should match MainQuestionEnd
  else if (options[options.length - 1]["end"] !== MainQuestionEnd)
    return "Last option END should match Overall Range End!";
  // each Start should be 1 step greater then previous End
  else {
    for (let i = 0; i < options.length; i++) {
      if (typeof options[i + 1] !== "undefined") {
        if (options[i + 1].start - options[i].end !== 1) {
          return "Each new option Start should be 1 step greater then previous End!";
        }
      }
    }
  }

  return message;
};

export const checkinOnboardingSchema = Yup.object().shape({
  description: Validations.required("description"),
  image: Validations.required("image"),
});

export function checkinAssessmentQuestionSchema(settings?: any) {
  const optional = Yup.string().nullable();
  const optionalArray = Yup.array().nullable();
  const tagsValidation = optionalArray;

  const scoreValidation = Yup.number()
    .required("Score is required.")
    .min(0, "Score should be greater than or equal to 0");

  const optionsArray = Yup.array().of(
    Yup.object().shape({
      text: Validations.required("choice"),
      subText: Validations.required("subText"),
      score: settings?.isScoresEnable ? scoreValidation : optional,
      emoji: optional,
      tags: tagsValidation,
    }),
  );

  const optionsValidation = Yup.array().when("optionType", {
    is: "singleChoice",
    then: optionsArray,
  });

  return Yup.object().shape({
    text: Validations.required("question"),
    score: settings?.isScoresEnable ? scoreValidation : optional,
    options: optionsValidation,
    helperText: optional,
    minChar: Yup.number().when("isCheckInFeedbackEnable", {
      is: (isCheckInFeedbackEnable: any) => isCheckInFeedbackEnable === true,
      then: Yup.number().required("Minimum Characters are required"),
      otherwise: Yup.number().nullable(),
    }),
    maxChar: Yup.number().when("isCheckInFeedbackEnable", {
      is: (isCheckInFeedbackEnable: any) => isCheckInFeedbackEnable === true,
      then: Yup.number().required("Maximum Characters are required"),
      otherwise: Yup.number().nullable(),
    }),
  });
}

export function assessmentSettingsSchema() {
  const notificationSettingsValidation = Yup.array().of(
    Yup.object().shape({
      timeOfDay: Yup.date().when("isPeriodic", {
        is: (isPeriodic: boolean) => {
          return !!isPeriodic ? true : false;
        },
        then: Yup.date().required("Time is required").nullable(),
        otherwise: Yup.date().nullable(),
      }),
      startDate: Yup.date().when("isPeriodic", {
        is: (isPeriodic: boolean) => {
          return !!isPeriodic ? true : false;
        },
        then: Yup.date()
          .min(new Date(), "Start date can't be in past")
          .required("Start date is required")
          .nullable(),
        otherwise: Yup.date().nullable(),
      }),
      endDate: Yup.date().when("isPeriodic", {
        is: (isPeriodic: boolean) => {
          return !!isPeriodic ? true : false;
        },
        then: Yup.date()
          .min(Yup.ref("startDate"), "End date can't be before Start date")
          .required("End date is required")
          .nullable(),
        otherwise: Yup.date().nullable(),
      }),
      message: Yup.string().required("Message is required"),
    }),
  );

  return Yup.object().shape({
    notificationSetting: notificationSettingsValidation,
  });
}
