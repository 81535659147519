import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { CustomTextArea } from "components/form/custom-text-area/CustomTextArea";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { Form, Formik, FormikHelpers } from "formik";
import { MeasurementCreate } from "graphql/_generated/graphql";
import { useTranslation } from "react-i18next";
import { unitsSchema } from "validations/schema/unitsSchema";
import UnitTranslation from "./UnitTranslation";
import { SubmitUnitResponse } from "./hooks/useMeasurementModel";

interface AddUnitProps {
  visible: boolean;
  editId?: string | null;
  initialValues: MeasurementCreate;
  loading: boolean;
  isAddingTranslation?: boolean;
  onCloseUnit: () => void;
  onSubmitUnit: (
    values: MeasurementCreate,
    formikHelpers?: FormikHelpers<MeasurementCreate> | undefined,
  ) => Promise<SubmitUnitResponse | undefined>;
}

const AddUnit: React.FunctionComponent<AddUnitProps> = ({
  visible,
  editId,
  onCloseUnit,
  onSubmitUnit,
  initialValues,
  loading,
  isAddingTranslation,
}) => {
  const { t } = useTranslation();
  return (
    <CustomModal
      width={600}
      visible={visible}
      onCancel={onCloseUnit}
      footer={false}
      title={editId ? t("edit-unit") : t("add-new-unit")}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={unitsSchema}
        onSubmit={onSubmitUnit}
      >
        {({ values }) => (
          <Form>
            <CustomTextField
              name="unit"
              label={t("unit")}
              placeholder={t("enter-unit")}
            />
            <CustomTextArea
              name="description"
              placeholder={t("enter-description")}
              label={t("description")}
              rows={6}
            />
            {isAddingTranslation && <UnitTranslation values={values} />}
            <ModalFooter
              saveText={t("save-unit")}
              onCancel={onCloseUnit}
              loading={loading}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddUnit;
