export const ChallengeRewardsPointSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.76416 11.9867L12.0036 5.0332L17.2431 11.9867L12.0036 18.9402L6.76416 11.9867Z"
        fill="#FFF159"
      />
      <path
        d="M17.2463 11.9867L12.0068 5.0332V18.9402L17.2463 11.9867Z"
        fill="#FFDF40"
      />
      <path
        d="M11.9938 1.19922L13.2622 4.47432L11.9938 7.13927L8.34088 11.9866L6.33635 13.2522L3.59717 11.9866L11.9938 1.19922Z"
        fill="#FFBE40"
      />
      <path
        d="M20.3999 11.9863L12.0032 22.799L10.7349 19.2198L12.0032 16.8336L15.6562 11.9863L17.8459 10.7207L20.3999 11.9863Z"
        fill="#FFBE40"
      />
      <path
        d="M11.9938 16.8336V22.799L3.59717 11.9863H8.34088L11.9938 16.8336Z"
        fill="#FF9F40"
      />
      <path
        d="M20.3898 11.9866H15.6461L11.9932 7.13927V1.19922L20.3898 11.9866Z"
        fill="#FF9F40"
      />
    </svg>
  );
};
