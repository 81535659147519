import {
  useVerifyOtpMutation,
  VerifyOtpMutationVariables,
} from "graphql/_generated/graphql";

// hook type
type TypeUseOtpVerification = () => {
  isLoading: boolean;
  verifyOtp: (values: VerifyOtpMutationVariables) => Promise<any>;
};

export const useOtpVerification: TypeUseOtpVerification = () => {
  const [VerifyOtpFun, { isLoading }] = useVerifyOtpMutation();

  //this function is responsible for verifying otp
  const verifyOtp = async (values: VerifyOtpMutationVariables) => {
    try {
      const res = await VerifyOtpFun({
        body: { ...values.body },
      }).unwrap();
      return res;
    } catch (error: any) {
      throw error;
    }
  };

  return { isLoading, verifyOtp };
};
