import {
  GetTeamLeaderboardQueryVariables,
  useLazyGetTeamLeaderboardQuery,
} from "graphql/_generated/graphql";

export const useGetTeamLeaderboard = () => {
  const [getTeamLeaderboardFun, { isFetching: isLoadingTeamLeaderboard }] =
    useLazyGetTeamLeaderboardQuery();

  const handleGetTeamLeaderboard = async (
    values: GetTeamLeaderboardQueryVariables,
  ) => {
    try {
      const res = await getTeamLeaderboardFun(values).unwrap();
      return res.getTeamLeaderboard;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleGetTeamLeaderboard,
    isLoadingTeamLeaderboard,
  };
};
