const ChallengeDuration: { [key: string]: any } = [
  {
    id: "1",
    name: "Days",
    min: 1,
    max: 60,
  },
  {
    id: "30",
    name: "Months",
    min: 1,
    max: 12,
  },
];

export default ChallengeDuration;
