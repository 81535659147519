import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { customNotification } from "components/custom-notification/CustomNotification";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { errorFormat } from "helpers";
import { Formik, Form } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { Gender } from "graphql/_generated/graphql";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { useErrorHandler } from "hooks/useErrorHandler";

interface SectionAdvanceFiltersProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: any;
  initialValues: any;
  configData: any;
  fetchDepartments: any;
  fetchPositions: any;
}

const SectionAdvanceFilters: React.FunctionComponent<
  SectionAdvanceFiltersProps
> = ({
  setVisible,
  visible,
  handleSubmit,
  initialValues,
  configData,
  fetchDepartments,
  fetchPositions,
}) => {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <CustomModal
      width={600}
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      title={t("filter")}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={async (values, { resetForm }) => {
          try {
            setVisible(false);
            handleSubmit(values);
          } catch (error) {
            handleError(error as string | object);
          }
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <CustomSelectField
              label={t("gender")}
              name="gender"
              allowClear
              placeholder={t("gender")}
              className="form-select"
              options={Object.keys(Gender).map((item) => ({
                value: item,
                label: item,
              }))}
            />
            <CustomTextField
              type="number"
              label={t("minimum-age")}
              name="age.min"
              min={1}
              placeholder={t("minimum-age")}
            />
            <CustomTextField
              type="number"
              name="age.max"
              label={t("maximum-age")}
              placeholder={t("maximum-age")}
              min={1}
            />
            <CustomSelectField
              name="clientIds"
              className="form-select"
              mode="multiple"
              label={t("companies")}
              placeholder={t("companies")}
              options={configData?.companies}
              fieldNames={{ label: "companyName", value: "id" }}
              onChange={(values) => {
                setFieldValue("clientIds", values);
                setFieldValue("departmentIds", null);
                setFieldValue("positionIds", null);
                fetchDepartments(values);
                fetchPositions(values, "");
              }}
            />
            <CustomSelectField
              name="departmentIds"
              className="form-select"
              label={t("departments")}
              placeholder={t("departments")}
              mode="multiple"
              options={configData?.departments}
              fieldNames={{ label: "label", value: "id" }}
              onChange={(value) => {
                setFieldValue("departmentIds", value);
                setFieldValue("positionIds", null);
                fetchPositions(values?.clientIds, value);
              }}
            />
            <CustomSelectField
              name="positionIds"
              mode="multiple"
              className="form-select"
              label={t("positions")}
              placeholder={t("positions")}
              options={configData?.positions}
              // disabled={!enableValidations.isPosition || disabled}
              fieldNames={{ label: "label", value: "id" }}
            />
            <ModalFooter
              // loading={isLoading}
              saveText={t("apply")}
              onCancel={handleCancel}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default SectionAdvanceFilters;
