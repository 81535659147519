export const AssessmentSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8333 13.0293C13.8333 13.4893 13.4592 13.8626 13 13.8626H3C2.54083 13.8626 2.16667 13.4893 2.16667 13.0293V7.19596H13.8333V13.0293ZM4.66667 3.0293C5.12667 3.0293 5.5 3.4018 5.5 3.86263C5.5 4.32346 5.12667 4.69596 4.66667 4.69596C4.20667 4.69596 3.83333 4.32346 3.83333 3.86263C3.83333 3.4018 4.20667 3.0293 4.66667 3.0293ZM8 3.0293C8.46 3.0293 8.83333 3.4018 8.83333 3.86263C8.83333 4.32346 8.46 4.69596 8 4.69596C7.54 4.69596 7.16667 4.32346 7.16667 3.86263C7.16667 3.4018 7.54 3.0293 8 3.0293ZM13 0.529297H3C1.62167 0.529297 0.5 1.65096 0.5 3.0293V5.5293V7.19596V13.0293C0.5 14.4076 1.62167 15.5293 3 15.5293H13C14.3783 15.5293 15.5 14.4076 15.5 13.0293V7.19596V5.5293V3.0293C15.5 1.65096 14.3783 0.529297 13 0.529297Z"
        fill="#303030"
      />
    </svg>
  );
};
