export const SleepIcon = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none">
      <path
        d="M23.7151 3.04688C24.1358 3.04688 24.4768 2.70585 24.4768 2.28516C24.4768 1.86447 24.1358 1.52344 23.7151 1.52344C23.2944 1.52344 22.9534 1.86447 22.9534 2.28516C22.9534 2.70585 23.2944 3.04688 23.7151 3.04688Z"
        fill="white"
      />
      <path
        d="M13.0506 26.0001C18.7381 26.0001 23.5731 22.2823 25.3102 17.1461C25.4187 16.8252 25.3023 16.4712 25.0245 16.2774C24.7467 16.0836 24.3744 16.0964 24.1106 16.309C22.4683 17.6325 20.4876 18.3321 18.3827 18.3321C13.3145 18.3321 9.19121 14.2316 9.19121 9.19145C9.19121 6.07494 10.851 3.09305 13.5229 1.40954C13.8094 1.22896 13.9423 0.880907 13.849 0.555296C13.7575 0.235931 13.47 0.0134571 13.1394 0.00370705C13.1213 0.00233595 13.0892 0 13.0506 0C5.89681 0 -0.000244141 5.83769 -0.000244141 13.0001C-0.000244141 20.1599 5.89386 26.0001 13.0506 26.0001ZM10.6925 1.76481C8.74169 3.78871 7.66776 6.45626 7.66776 9.19145C7.66776 15.0716 12.4744 19.8556 18.3827 19.8556C19.9296 19.8556 21.4571 19.5134 22.8511 18.8694C20.8154 22.2982 17.1206 24.4767 13.0506 24.4767C6.69438 24.4767 1.5232 19.3283 1.5232 13.0001C1.5232 7.476 5.46308 2.85108 10.6925 1.76481Z"
        fill="#303030"
      />
      <path
        d="M14.574 6.09377C15.8127 6.09377 16.8592 7.16349 16.8592 8.42972C16.8592 8.8504 17.2002 9.19144 17.6209 9.19144C18.0416 9.19144 18.3826 8.8504 18.3826 8.42972C18.3826 7.16349 19.4291 6.09377 20.6678 6.09377C21.0885 6.09377 21.4295 5.75272 21.4295 5.33205C21.4295 4.91138 21.0885 4.57033 20.6678 4.57033C19.4077 4.57033 18.3826 3.5452 18.3826 2.28516C18.3826 1.86449 18.0416 1.52344 17.6209 1.52344C17.2002 1.52344 16.8592 1.86449 16.8592 2.28516C16.8592 3.5452 15.8341 4.57033 14.574 4.57033C14.1533 4.57033 13.8123 4.91138 13.8123 5.33205C13.8123 5.75272 14.1533 6.09377 14.574 6.09377ZM17.6209 4.56769C17.8408 4.86049 18.1019 5.12075 18.3955 5.33972C18.1018 5.56361 17.8437 5.8256 17.6209 6.12582C17.3981 5.82565 17.1401 5.56372 16.8463 5.33972C17.1399 5.12075 17.401 4.86049 17.6209 4.56769Z"
        fill="#303030"
      />
      <path
        d="M25.2384 9.19166C25.6591 9.19166 26.0001 8.85062 26.0001 8.42994C26.0001 8.00925 25.6591 7.66821 25.2384 7.66821C24.8177 7.66821 24.4767 8.00925 24.4767 8.42994C24.4767 8.85062 24.8177 9.19166 25.2384 9.19166Z"
        fill="#303030"
      />
    </svg>
  );
};
