import { StringMap } from "components/interfaces/common";

export const offerExcelMapper: StringMap = {
  "Offer Title (EN)": "title",
  // 'Offer Title (AR)': 'title',
  "Offer Type": "isOnline",
  "Offer ID/Reference no": "referenceId",
  // "Offer Detail (AR)": "offerDetail",
  "Offer Detail (EN)": "offerDetail",
  // "Voucher Url (TEST)": "voucherUrl",
  // "Redeem Code": "redeemCode",
  "Redemption Type": "redemptionType",
  "Valid From": "validFrom",
  "Valid To": "validTo",
  // "Brand (AR)": "brand",
  "Brand (EN)": "brand",
  "Campaign Name (Internal use for Merit)": "campaignName",
  // "Category (AR)": "category",
  "Category (EN)": "category",
  // "Sub Category (AR)": "subCategory",
  "Sub Category (EN)": "subCategory",
  "Country Name": "countryName",
  Currency: "currency",
  // "Terms & Conditions (AR)": "termsConditions",
  "Terms & Conditions (EN)": "termsConditions",
  "Image URL": "imageUrl",
  "Image Ratio/size": "imageRatio",
};
