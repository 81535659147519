import AddOption from "components/custom-button/AddOption";
import DeleteBtn from "components/custom-button/DeleteBtn";
import EmojiPicker from "components/form/custom-emoji-picker/EmojiPicker";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { FieldArray } from "formik";
import * as React from "react";
import ICoreFields from "features/assessment/types/ICoreFields";
import { InitialValueTypesOfAssessment } from "features/playground/types";
import styles from "./BooleanOptions.module.scss";
import { useTranslation } from "react-i18next";

interface BooleanOptionsProps extends ICoreFields {
  tags: any;
  values: any;
  namePrefix?: string;
  addBoolean: (key: string) => void;
  deleteBoolean: (key: string, booleanId: string) => void;
  isDisabled?: boolean;
  isSubQuestion?: boolean;
  isAddLanguage?: boolean;
  assessmentSettings?: any;
}

const BooleanOptions: React.FunctionComponent<BooleanOptionsProps> = (
  props,
) => {
  const { values } = props;
  const { t } = useTranslation();
  const namePrefix = `${props?.namePrefix ? props?.namePrefix + "." : ""}`;

  return (
    <>
      <FieldArray
        name="booleans"
        render={() => (
          <>
            {values?.booleans
              ?.sort(
                (a: any, b: any) => a?.optionSortOrder - b?.optionSortOrder,
              )
              ?.map((boolean: any, index: number) => (
                <div
                  className={
                    props?.assessmentSettings?.isTagsEnable &&
                    props?.assessmentSettings?.isScoresEnable
                      ? values?.isEmojiEnable
                        ? styles["boolean-header-emoji"]
                        : styles["boolean-header"]
                      : styles["option-filter"]
                  }
                  key={boolean.id}
                >
                  {values.isEmojiEnable && (
                    <EmojiPicker
                      name={`${namePrefix}booleans[${index}].emoji`}
                      isDisabled={props?.isDisabled}
                    />
                  )}
                  <CustomSelectField
                    name={`${namePrefix}booleans[${index}].text`}
                    placeholder="Yes or No"
                    options={[
                      { value: "YES", label: "Yes" },
                      { value: "NO", label: "No" },
                    ]}
                    disabled={props?.isDisabled || props?.isAddLanguage}
                  />
                  {props?.assessmentSettings?.isScoresEnable ? (
                    <CustomTextField
                      name={`${namePrefix}booleans[${index}].score`}
                      placeholder="Score"
                      type="number"
                      disabled={props?.isDisabled || props?.isAddLanguage}
                    />
                  ) : null}
                  {props?.assessmentSettings?.isTagsEnable ? (
                    <CustomSelectField
                      maxTagCount="responsive"
                      name={`${namePrefix}booleans[${index}].tags`}
                      mode="multiple"
                      options={props?.tags?.data}
                      disabled={
                        props?.tags?.isLoading ||
                        props?.isDisabled ||
                        props?.isAddLanguage
                      }
                      placeholder="Select Tags"
                    />
                  ) : null}
                  <DeleteBtn
                    disabled={props?.isDisabled || props?.isAddLanguage}
                    onClick={() => props?.deleteBoolean("booleans", boolean.id)}
                  />
                </div>
              ))}
            {values.booleans?.length < 2 && !props?.isAddLanguage ? (
              <AddOption
                disabled={props?.isDisabled}
                title="Add Boolean"
                onClick={() => props?.addBoolean("booleans")}
              />
            ) : null}
          </>
        )}
      />
    </>
  );
};

export default BooleanOptions;
