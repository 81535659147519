import * as React from "react";
import { Segmented } from "antd";
import "./CustomSegment.module.scss";
interface CustomSegmentProps {
  options:
    | string[]
    | number[]
    | {
        label: string;
        value: string;
        icon?: React.ReactNode;
        disabled?: boolean;
      }[];
  size?: "large" | "middle" | "small";
  className?: string;
  onChange?: (value: string | number) => void;
}

const CustomSegment: React.FunctionComponent<any> = (props) => {
  return (
    <Segmented
      {...props}
      className={props.className ? props.className : "custom-segment"}
    />
  );
};

export default CustomSegment;
