import { useGetTopPrograms } from "features/company-dashboard/hooks/useGetTopPrograms";
import { ContentDetailProps } from "features/company-dashboard/types";
import {
  getFetchingStatus,
  getFormattedDateRanges,
} from "features/company-dashboard/utils";
import {
  ProgressStatus,
  UserFilters,
  useGetCollectionConfigQuery,
} from "graphql/_generated/graphql";
import { useEffect, useState } from "react";

export interface TopProgramsProps {
  item: any;
  userFilters: UserFilters;
}

export const useTopProgramsViewModel = ({
  item,
  userFilters,
}: TopProgramsProps) => {
  const [programData, setProgramData] = useState<ContentDetailProps>({
    isDataConcealed: false,
    data: [],
  });

  const { handleGetTopPrograms, isLoading } = useGetTopPrograms();
  const { refetch: fetchPrograms, isFetching } = useGetCollectionConfigQuery();

  useEffect(() => {
    const isFetching = getFetchingStatus(item.selectedValues);
    if (isFetching) getProgramsData();
  }, [item.selectedValues, userFilters]);

  const getProgramsData = async () => {
    const { startingDate, endingDate } = getFormattedDateRanges(
      item.selectedValues,
    );
    const topPrograms = await handleGetTopPrograms({
      startDate: startingDate,
      endDate: endingDate,
      status: item.type === ProgressStatus.Left ? "" : item.type,
      userFilters,
    });
    if (topPrograms) {
      const programsList = await fetchPrograms();
      const mappedPrograms = topPrograms.items.length
        ? topPrograms.items.map((item, index) => ({
            id: index + 1,
            name:
              programsList?.data?.getCollectionConfig?.options.find(
                (program) => program.id === item.id,
              )?.label || "",
            value: ((item.count / topPrograms.total) * 100).toFixed(0) || "",
          }))
        : [];
      setProgramData({
        isDataConcealed: topPrograms?.isDataConcealed,
        data: mappedPrograms,
      });
    }
  };

  const handleRefresh = () => {
    getProgramsData();
  };

  return {
    handleRefresh,
    isLoading,
    programData,
  };
};
