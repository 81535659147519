import {
  GetPillarInteractionsHistoryQueryVariables,
  useLazyGetPillarInteractionsHistoryQuery,
} from "graphql/_generated/graphql";

export const usePillarInteractionsHistory = () => {
  const [pillarInteractionsHistoryFun, { isFetching }] =
    useLazyGetPillarInteractionsHistoryQuery();

  const handleGetPillarInteractionsHistory = async (
    values: GetPillarInteractionsHistoryQueryVariables,
  ) => {
    try {
      const res = await pillarInteractionsHistoryFun(values).unwrap();

      return res.getPillarInteractionsHistory;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleGetPillarInteractionsHistory,
    isLoading: isFetching,
  };
};
