import { useAddPosition } from "./add-position/useAddPosition";
import { useUpdatePosition } from "./add-position/useUpdatePosition";
import { useToggleActivationPosition } from "./positions-list/useToggleActivationPosition";

export const usePosition = () => {
  const { handleAddPosition, isLoading } = useAddPosition();

  const {
    isLoading: updatePositionLoading,
    handleFetchEditPositionData,
    handleUpdatePosition,
  } = useUpdatePosition();

  const {
    handleActivationPosition,
    handleRemovePosition,
    isLoading: toggleActivationPositionLoading,
  } = useToggleActivationPosition();

  return {
    isLoading:
      isLoading || updatePositionLoading || toggleActivationPositionLoading,
    handleAddPosition,
    handleFetchEditPositionData,
    handleUpdatePosition,
    handleRemovePosition,
    handleActivationPosition,
  };
};
