import {
  MutationUpdateOfferArgs,
  useUpdateOfferMutation,
} from "graphql/_generated/graphql";

//define the type of handleUpdateOffer function
export type TypeHandleUpdateOffers = (
  values: MutationUpdateOfferArgs
) => Promise<void>;

//define the type of usnUpdateOffer hook
export type TypeUsnUpdateOffer = () => {
  isLoading: boolean;
  handleUpdateOffer: TypeHandleUpdateOffers;
};

export const useUpdateOffer: TypeUsnUpdateOffer = () => {
  const [updateOfferFun, { isLoading }] = useUpdateOfferMutation();

  //this function is responsible for Updating the offer data
  const handleUpdateOffer = async (values: MutationUpdateOfferArgs) => {
    try {
      await updateOfferFun({
        update: values.update,
        updateOfferId: values.id,
      }).unwrap();
    } catch (error: any) {
      throw error;
    }
  };

  //this function is responsible for updating the offer data
  return { isLoading, handleUpdateOffer };
};
