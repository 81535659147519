import {
  MutationRevokeDeleteRequestArgs,
  useRevokeDeleteRequestMutation,
} from "graphql/_generated/graphql";

export const useRevokeRequest = () => {
  const [revokeRequestFun, { isLoading }] = useRevokeDeleteRequestMutation();

  const handleRevokeRequest = async (
    values: MutationRevokeDeleteRequestArgs,
  ) => {
    try {
      await revokeRequestFun({ userId: values.userId }).unwrap();
    } catch (error) {
      throw error;
    }
  };
  return {
    isLoading,
    handleRevokeRequest,
  };
};
