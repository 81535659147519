export const TiersIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7094 3.33398H3.29024C2.85524 3.33398 2.50024 3.68982 2.50024 4.12482V5.04315C2.50024 5.47815 2.85524 5.83398 3.29024 5.83398H16.7094C17.1444 5.83398 17.5002 5.47815 17.5002 5.04315V4.12482C17.5002 3.68982 17.1444 3.33398 16.7094 3.33398Z"
        fill="#303030"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7094 8.75H3.29024C2.85524 8.75 2.50024 9.10583 2.50024 9.54083V10.4592C2.50024 10.8942 2.85524 11.25 3.29024 11.25H16.7094C17.1444 11.25 17.5002 10.8942 17.5002 10.4592V9.54083C17.5002 9.10583 17.1444 8.75 16.7094 8.75Z"
        fill="#303030"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7094 14.166H3.29024C2.85524 14.166 2.50024 14.5218 2.50024 14.9568V15.8752C2.50024 16.3102 2.85524 16.666 3.29024 16.666H16.7094C17.1444 16.666 17.5002 16.3102 17.5002 15.8752V14.9568C17.5002 14.5218 17.1444 14.166 16.7094 14.166Z"
        fill="#303030"
      />
    </svg>
  );
};
