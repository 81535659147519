import { useGetPopularCategories } from "features/company-dashboard/hooks/useGetPopularCategories";
import { FilterValuesProps } from "features/company-dashboard/types";
import {
  getFetchingStatus,
  getFormattedDateRanges,
} from "features/company-dashboard/utils";
import {
  UserFilters,
  useGetConfigCategoriesQuery,
} from "graphql/_generated/graphql";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InfoTextProps } from "../organization-metrices/types";
import { CategoryDetails } from "./CategoryDetails";
import { List } from "./List";
import { ContentDetailProps } from "./types";

interface CategoriesProps extends InfoTextProps {
  userFilters: UserFilters;
  selectedValues: FilterValuesProps;
  pillarDetailsVisible: boolean;
  selectedPillar: any;
  visible: boolean;
}

export const Categories: FunctionComponent<CategoriesProps> = ({
  userFilters,
  selectedValues,
  pillarDetailsVisible,
  selectedPillar,
  visible,
  selectedInfoText,
  setSelectedInfoText,
}) => {
  const { t } = useTranslation();

  const initialValues = {
    isDataConcealed: false,
    data: [],
  };

  const [popularCategoriesContent, setPopularCategoriesContent] =
    useState<ContentDetailProps>(initialValues);

  const { handleGetPopularCategories, isLoading } = useGetPopularCategories();
  const { refetch: fetchCategories } = useGetConfigCategoriesQuery();

  useEffect(() => {
    const isFetching = getFetchingStatus(selectedValues);
    if (isFetching) getCategoriesData();
  }, [selectedValues, userFilters, selectedPillar]);

  const getCategoriesData = async () => {
    if (selectedValues.pastDays) {
      setPopularCategoriesContent(initialValues);
      const { startingDate, endingDate } =
        getFormattedDateRanges(selectedValues);
      const popularCategories = await handleGetPopularCategories({
        startDate: startingDate,
        endDate: endingDate,
        userFilters,
        pillarId: selectedPillar?.key,
      });
      if (popularCategories) {
        const categoriesData = await fetchCategories();
        const mappedCategories = popularCategories.items.length
          ? popularCategories.items.map((item, index) => ({
              id: index + 1,
              name:
                categoriesData?.data?.getConfigCategories?.options?.find(
                  (category) => category.id === item.id,
                )?.label || "",
              value: item.count,
              key: item.id,
              total: popularCategories.total,
            }))
          : [];
        setPopularCategoriesContent({
          isDataConcealed: popularCategories?.isDataConcealed,
          data: mappedCategories,
        });
      }
    }
  };

  const handleRefresh = () => {
    getCategoriesData();
  };

  const infoTexts = [
    `${t("engaging-wellbeing-pillars")} ${t("categories-desc")} ${t(
      "based-on-interaction-classes",
    )}`,
    t("analyses-interactions"),
    t("explore-interaction-overtime"),
    t("enhance-employee-engagement"),
  ];

  return (
    <>
      {pillarDetailsVisible && selectedPillar?.name ? (
        <CategoryDetails
          item={{
            content: popularCategoriesContent,
            selectedValues,
            selectedPillar,
            handleRefresh,
            isLoading,
          }}
          infoTexts={infoTexts}
          visible={visible}
          extraSpacing={true}
          selectedInfoText={selectedInfoText}
          setSelectedInfoText={setSelectedInfoText}
        />
      ) : (
        <List
          item={{
            title: t("most-popular-categories"),
            content: popularCategoriesContent,
            selectedValues,
            type: t("category"),
            handleRefresh,
            isLoading,
          }}
          infoTexts={infoTexts}
          visible={visible}
          extraSpacing={true}
          selectedInfoText={selectedInfoText}
          setSelectedInfoText={setSelectedInfoText}
        />
      )}
    </>
  );
};
