import { Avatar, Col, Divider, Radio, Row, Select, Space } from "antd";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import { customNotification } from "components/custom-notification/CustomNotification";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { CustomSrtUpload } from "components/form/custom-srt-upload/CustomSrtUpload";
import { CustomVideoUpload } from "components/form/custom-video-upload/CustomVideoUpload";
import { errorFormat } from "helpers";
import { useSpecialistConfigList } from "hooks/useSpecialistConfig";
import { FieldArray, FormikValues } from "formik";
import {
  MutationDeleteSubtitleArgs,
  useActivateSubtitleMutation,
} from "graphql/_generated/graphql";
import styles from "features/content/css/AddContent.module.scss";
import { filterLanguagesWithout, getLabelFromValue } from "../utils";
import { useTranslation } from "react-i18next";
import { useErrorHandler } from "hooks/useErrorHandler";

const { Option } = Select;
const EXCLUDED_LANG = undefined;

const VideoContent = (props: {
  editId: string;
  value: string | null;
  values: FormikValues;
  languages: { label: string; value: string }[];
  handleDeleteSubTitle: (values: MutationDeleteSubtitleArgs) => Promise<void>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
}) => {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const [activeSubtitleFun] = useActivateSubtitleMutation();
  const {
    values,
    setFieldValue,
    editId,
    languages,
    handleDeleteSubTitle,
    value,
  } = props;
  const { getSpecialistConfigData } = useSpecialistConfigList();

  return (
    <>
      {/* video will show only if the content type is video */}
      {values.contentType === "Video" && (
        <>
          <Row gutter={[24, 0]}>
            <Col sm={24} md={12}>
              <CustomSelectField
                label={t("content-spoken-language")}
                required={true}
                value={getLabelFromValue(languages, values.spokenLanguage)}
                name="spokenLanguage"
                placeholder={t("select-language")}
                options={languages}
                defaultValue={getLabelFromValue(
                  languages,
                  values.spokenLanguage,
                )}
                className="form-select"
              />
            </Col>
            {/* <Col sm={24} md={12}>
              <label className="label">{t("spoken-language")}</label>
              <div>
                {languages.find((x) => x.value === values.language)?.label}
              </div>
            </Col> */}
            <Col sm={24} md={12}>
              <div className={styles["form-control"]}>
                <CustomSelectField
                  label={t("select-specialist")}
                  name="creatorId"
                  className="form-select"
                  placeholder={t("select-specialist")}
                >
                  {getSpecialistConfigData?.map((item: any, index: any) => (
                    <Option value={item.value} key={index}>
                      <Space>
                        <CustomAvatar imgSrc={item.image} />
                        {item.label}
                      </Space>
                    </Option>
                  ))}
                </CustomSelectField>
              </div>
            </Col>
            <Col sm={24} md={12}>
              <CustomVideoUpload
                label={t("upload-content-file")}
                name="videoUrl"
                accept="video/*"
                setFieldValue={setFieldValue}
                title={values.title}
                description={values.description}
                durationFieldName="duration"
                disable={
                  values.title?.length && values.description?.length
                    ? false
                    : true
                }
              />
            </Col>
          </Row>

          {/* This is block of code is the nested field array for uploading the subtitles against videos, which videos we will upload in the upper block of code if the content type is Video and this block of code is also run when content Type is video this is because we need the subtitles only with videos  */}

          {/* this video will show if edit id is available and content type is video */}
          {editId && (
            <iframe
              title="Vieme"
              frameBorder="0"
              allowFullScreen
              allow="autoplay; encrypted-media"
              src={values.videoUrl}
            ></iframe>
          )}
        </>
      )}
      {/* this subtitle block is only shown when the content type is video because we need subtitles only with videos */}
      {values.contentType === "Video" && (
        <>
          <FieldArray
            name="subtitles"
            render={(arrayHelpers) => (
              <>
                {values.subtitles &&
                  values.subtitles?.length > 0 &&
                  values.subtitles.map((translation: any, index: number) => (
                    <>
                      <div className={styles["divider"]}>
                        <div key={index} className={styles["subtitles"]}>
                          <CustomSelectField
                            label={t("select-language-subtitle")}
                            required={true}
                            name={`subtitles.${index}.language`}
                            placeholder={t("select-language")}
                            value={getLabelFromValue(
                              languages,
                              values.subtitles[index].language,
                            )}
                            defaultValue={getLabelFromValue(
                              languages,
                              values.subtitles[index].language,
                            )}
                            options={filterLanguagesWithout(
                              languages,
                              values.subtitles,
                              EXCLUDED_LANG,
                              values.language,
                            )}
                            className="form-select"
                          />

                          <CustomSrtUpload
                            disable={
                              values.subtitles[index].language.length <= 0
                            }
                            label={t("select-subtitle-file")}
                            name={`subtitles.${index}.url`}
                            setFieldValue={setFieldValue}
                            videoId={values.videoUrl}
                            localizedVideos={values.localizedVideos}
                            language={values.subtitles[index].language}
                            index={index}
                            accept=".srt,.mkv"
                            imgUrl={values.subtitles[index]?.url}
                          />
                        </div>
                        {values.subtitles?.length > 0 && (
                          <span
                            className={styles["remove-btn"]}
                            onClick={async () => {
                              try {
                                await handleDeleteSubTitle({
                                  subtitleUri: values.subtitles[index].url,
                                  videoUri: values.videoUrl?.split("/").pop(),
                                });
                                return arrayHelpers.remove(index);
                              } catch (error) {
                                handleError(error as string | object);
                              }
                            }} // remove a language from the list
                          >
                            - Remove subtitle
                          </span>
                        )}
                      </div>
                    </>
                  ))}
                {values.translations?.length < languages?.length && (
                  <div className={styles["flex-end"]}>
                    <div className={styles["form-control"]}>
                      <span
                        className={styles["add-btn"]}
                        onClick={() =>
                          arrayHelpers.push({
                            language: "",
                            url: "",
                          })
                        }
                      >
                        + {t("add-subtitle")}
                      </span>
                    </div>
                  </div>
                )}
                <Divider dashed />
              </>
            )}
          />
          {values.subtitles.length > 0 && (
            <>
              {/* this code of block only run if content type is video and this is a nested code inside of dynamic content field array here we are showing all the subtitles against every video below the video and when user click on which radio button this language subtitle will active against this particular video*/}
              {values.subtitles[0].language?.length > 0 && (
                <div className={styles["form-control"]}>
                  <div className="label">Active Sub title</div>
                  <Radio.Group
                    defaultValue={value}
                    onChange={async (e) => {
                      try {
                        await activeSubtitleFun({
                          subtitleUri: e.target.value,
                          videoUri: values.videoUrl.split("/").pop(),
                        }).unwrap();

                        if (values.localizedVideos[0]?.url.length > 0) {
                          values.localizedVideos.map(
                            async (video: {
                              language: string;
                              url: string;
                            }) => {
                              try {
                                await activeSubtitleFun({
                                  subtitleUri: e.target.value,
                                  videoUri: video.url?.split("/").pop(),
                                }).unwrap();
                                customNotification(
                                  "success",
                                  t("subtitle-activated"),
                                );
                              } catch (error) {
                                handleError(error as string | object);
                              }
                            },
                          );
                        }
                      } catch (error) {}
                    }}
                  >
                    {values.subtitles.map(
                      (
                        item: {
                          url: string;
                          language: string;
                          checked?: boolean;
                        },
                        index: number,
                      ) => {
                        const languageLabel = languages.filter(
                          (language) => language.value === item.language,
                        )[0]?.label;

                        return (
                          <Radio
                            // defaultChecked={item.checked}
                            // checked={item.che}
                            key={index}
                            value={item.url}
                            disabled={!item.url}
                          >
                            {languageLabel}
                          </Radio>
                        );
                      },
                    )}
                  </Radio.Group>
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default VideoContent;
