import { useEffect } from "react";
import { useLocation } from "react-router-dom";

//Zoho Sales Iq Script Hook:
export const useZohoScript = (url: string, widgetCode: string) => {
  const location = useLocation();
  const excludedPaths = [
    "/delete-account",
    "/verification-success",
    "/verification-error",
  ];

  useEffect(() => {
    if (!excludedPaths.includes(location.pathname)) {
      const script = document.createElement("script");
      script.setAttribute("type", "text/javascript");

      let code = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "${widgetCode}", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="${url}";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.innerHTML = "<div id='zsiqwidget'></div>";`;

      script.appendChild(document.createTextNode(code));
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [url, widgetCode]);
};
