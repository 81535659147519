import { useToggleActivationChallenge } from "./useToggleActivationChallenge";
import { useEditChallenge } from "./useEditChallenge";
import { useUpdateChallenge } from "./useUpdateChallenge";
import { useAddNewChallenge } from "./useAddNewChallenge";
import { useDeleteChallenge } from "./useDeleteChallenge";

export const useChallenge = () => {
  const {
    isLoading: toggleActivationChallengeLoading,
    handleActivationChallenge,
  } = useToggleActivationChallenge();

  const { isLoading: toggleDeleteChallengeLoading, handleDeleteChallenge } =
    useDeleteChallenge();

  const { isLoading: isLoadingEditChallenge, handleFetchChallengeData } =
    useEditChallenge();

  const { handleAddNewChallenge, isLoading } = useAddNewChallenge();

  const { isLoading: isLoadingUpdateChallenge, handleUpdateChallenge } =
    useUpdateChallenge();

  return {
    isLoading:
      isLoading ||
      toggleActivationChallengeLoading ||
      isLoadingUpdateChallenge ||
      toggleDeleteChallengeLoading,
    handleActivationChallenge,
    handleFetchChallengeData,
    handleUpdateChallenge,
    handleAddNewChallenge,
    handleDeleteChallenge,
  };
};
