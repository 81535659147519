import moment from "moment";
import * as Yup from "yup";
import { requiredFieldValidation } from "validations/schema/common/commonValidations";
import { toLowerCase } from "validations/schema/common/validationHelpers";

const dateValidation = Yup.date()
  .typeError("${path} must be valid date")
  .required("${path} must be valid date");
const toDateValidation = Yup.date()
  .typeError("${path} must be valid date")
  .test(
    "phone-number-validation",
    `Active To must be greater than Active From date`,
    (value: any, testContext) =>
      moment(value) > moment(testContext.parent.validFrom)
  )
  .required("${path} must be valid date");

export const offerSchema = Yup.object().shape({
  title: requiredFieldValidation("${path} must have a Title"),
  offerDetail: requiredFieldValidation(" ${path} must have a Detail"),
  validFrom: dateValidation,
  validTo: toDateValidation,
  isOnline: Yup.mixed().oneOf(["OFFLINE", "ONLINE"]),
});

export const offerUploadSchema = offerSchema.shape({
  referenceId: requiredFieldValidation("${path} must have a Reference Id"),
  brand: requiredFieldValidation("${path} must have a brand"),
  termsConditions: requiredFieldValidation("${path} must have termsConditions"),
  subCategory: requiredFieldValidation("${path} must have a subCategory"),
  category: requiredFieldValidation("${path} must have a category"),
  countryName: requiredFieldValidation("${path} must have a countryName"),
  imageRatio: requiredFieldValidation("${path} must have a imageRatio"),
  redemptionType: requiredFieldValidation("${path} must have a redemptionType"),
});
