import {
  useGetAvatarListQuery,
  useToggleAvatarActivationMutation,
} from "graphql/_generated/graphql";

export const useActivationAvatar = () => {
  
  const { refetch } = useGetAvatarListQuery();
  const [ActivationDeactivationFun, { isLoading }] =
    useToggleAvatarActivationMutation();

  //this function is responsible for Activation/Deactivation avatar
  const handleActivationDeactivationAvatar = async (id: string) => {
    try {
      const res = await ActivationDeactivationFun({
        toggleAvatarActivationId: id,
      }).unwrap();

      refetch();

      return res.toggleAvatarActivation;
    } catch (error: any) {
      throw error;
    }
  };

  return { isLoading, handleActivationDeactivationAvatar };
};
