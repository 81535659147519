import {
  GetViwellAssessmentPillarStatsQueryVariables,
  useLazyGetViwellAssessmentPillarStatsQuery,
} from "graphql/_generated/graphql";

export const useGetPilarStats = () => {
  const [pillarStatsFun, { isFetching }] =
    useLazyGetViwellAssessmentPillarStatsQuery();

  const handleGetPillarStats = async (
    values: GetViwellAssessmentPillarStatsQueryVariables,
  ) => {
    try {
      const res = await pillarStatsFun(values).unwrap();

      return res.getViwellAssessmentPillarStats;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleGetPillarStats,
    isLoading: isFetching,
  };
};
