import {
  useUpdateAssessmentTypeMutation,
  MutationUpdateAssessmentTypeArgs,
} from "graphql/_generated/graphql";

export const useUpdateAssessmentType = () => {
  const [updateAssessmentTypeFun, { isLoading }] =
    useUpdateAssessmentTypeMutation();

  const handleUpdateAssessmentType = async (
    values: MutationUpdateAssessmentTypeArgs,
  ) => {
    try {
      await updateAssessmentTypeFun({
        assessmentTypeId: values.assessmentTypeId,
        update: values.update,
      }).unwrap();
    } catch (error) {
      throw error;
    }
  };

  return {
    handleUpdateAssessmentType,
    isLoading,
  };
};
