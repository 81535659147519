import {
  GetIndividualLeaderboardQueryVariables,
  useLazyGetIndividualLeaderboardQuery,
} from "graphql/_generated/graphql";

export const useGetIndividualLeaderboard = () => {
  const [
    getIndividualLeaderboardFun,
    { isFetching: isLoadingIndividualLeaderboard },
  ] = useLazyGetIndividualLeaderboardQuery();

  const handleGetIndividualLeaderboard = async (
    values: GetIndividualLeaderboardQueryVariables,
  ) => {
    try {
      const res = await getIndividualLeaderboardFun(values).unwrap();
      return res.getIndividualLeaderboard;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleGetIndividualLeaderboard,
    isLoadingIndividualLeaderboard,
  };
};
