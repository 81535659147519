import { List, Pagination } from "antd";
import CustomModal from "components/custom-modal/CustomModal";
import React from "react";
import { useTranslation } from "react-i18next";
import UserItem from "./UserItem";
import styles from "features/events/css/EventDetail.module.scss";
import { useParticipantUsersModel } from "../hooks/useParticipantUsersModel";
import DataTableFilters from "features/challenge/challenge-detail/DataTableFilters";

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  eventId: string;
  companyId: string;
};

const ParticipatingUsers: React.FC<Props> = ({
  visible,
  eventId,
  companyId,
  setVisible,
}) => {
  const { t } = useTranslation();

  const vm = useParticipantUsersModel({
    eventId: eventId,
    clientId: companyId,
  });

  const handleCancel = () => {
    setVisible(false);
    vm.onCloseUserModal();
  };

  return (
    <CustomModal
      width={720}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      title={t("event.participating-users")}
    >
      <DataTableFilters
        isCompanyFilter={false}
        handleSearchInput={vm.handleSearchInput}
      />
      <div className={styles["participating-container"]}>
        <List
          loading={vm.isLoading}
          dataSource={vm.userData ?? []}
          renderItem={(item, index) => (
            <UserItem
              itemKey={index + 1}
              firstName={item.firstName ?? ""}
              lastName={item.lastName ?? ""}
              email={item.email ?? ""}
              image={item.avatar?.imgPath ?? item.image ?? ""}
              isKSA={!!item.image}
            />
          )}
        />
      </div>
      <div className={"pagination-margin"}>
        <Pagination
          onChange={vm.onChangePagination}
          current={vm.currentPage}
          pageSize={vm.pagination?.limit}
          total={vm.totalUsers}
          showTotal={(total, range) => (
            <div>
              Showing&nbsp;
              <b>
                {range[0]}-{range[1]}
              </b>
              &nbsp;of <b>{total}</b>&nbsp; items
            </div>
          )}
          responsive
          showSizeChanger={false}
        />
      </div>
    </CustomModal>
  );
};

export default ParticipatingUsers;
