import DeleteAccount from "features/delete-account/DeleteAccount";
import { CONFIG } from "helpers/constants";
import * as React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

interface DeleteAccountPageProps {}

const DeleteAccountPage: React.FunctionComponent<DeleteAccountPageProps> = (
  props,
) => {
  const RECAPTCHA_KEY =
    process.env.REACT_APP_RECAPTCHA_KEY || CONFIG.RECAPTCHA_KEY;

  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
      <DeleteAccount />
    </GoogleReCaptchaProvider>
  );
};

export default DeleteAccountPage;
