import { useToggleCollectionActivationMutation } from "graphql/_generated/graphql";

export const useToggleActivationCollection = () => {
  const [ActivationDeactivationFun, { isLoading }] =
    useToggleCollectionActivationMutation();
  //this function is responsible for Activation/Deactivation collection
  const handleActivationCollection = async (id: string) => {
    try {
      const res = await ActivationDeactivationFun({
        toggleCollectionActivationId: id,
      }).unwrap();
      return res.toggleCollectionActivation;
    } catch (error) {
      throw error;
    }
  };
  return {
    isLoading,
    handleActivationCollection,
  };
};
