import { CONFIG } from "helpers/constants";

const baseUrl = process.env.REACT_APP_BASE_URL || CONFIG.BASE_URL;
const baseImgUrl =
  process.env.REACT_APP_BASE_IMAGE_URL || CONFIG.BASE_IMAGE_URL;

const graphqlUrl = baseUrl
  ? `${baseUrl}`.endsWith("/")
    ? `${baseUrl}graphql`
    : `${baseUrl}/graphql`
  : "https://api-dev.viwell.com/graphql";

const api = {
  baseUrl,
  graphqlUrl,
  baseImgUrl,
};

export default api;
