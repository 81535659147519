import CheckPermissions from "components/check-permissions/CheckPermissions";
import CustomButton from "components/custom-button/CustomButton";
import { PERMS } from "helpers";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import { useState } from "react";
import AddCollection from "features/content/collection/add-collection/AddCollection";
import CollectionList from "features/content/collection/collection-list/CollectionList";
import styles from "features/content/collection/collection/Collection.module.scss";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
interface CollectionProps {}

const Collection: React.FunctionComponent<CollectionProps> = (props) => {
  const { t } = useTranslation();

  return (
    <DashboardLayout openKey={["Content"]}>
      <Breadcrumbs
        data={[
          {
            label: "Content",
          },
          {
            label: t("programs"),
          },
        ]}
      />
      <CollectionList />
    </DashboardLayout>
  );
};

export default Collection;
