import { useState } from "react";

export const useViewMembersViewModel = () => {
  //states
  const [teamMemberVisibility, setTeamMemberVisibility] = useState(false);
  const [teamId, setTeamId] = useState<string | null>();

  //callbacks
  const onPressTeamMember = (teamId: string) => {
    setTeamId(teamId);
    setTeamMemberVisibility(true);
  };
  const onCloseTeamMembers = () => {
    setTeamMemberVisibility(false);
    setTeamId(null);
  };
  return {
    teamMemberVisibility,
    teamId,
    onPressTeamMember,
    onCloseTeamMembers,
  };
};
