import {
  useActivateAssessmentMutation,
  MutationActivateAssessmentArgs,
} from "graphql/_generated/graphql";

export const useToggleAssessment = () => {
  const [assessmentToggleFun, { isLoading }] = useActivateAssessmentMutation();

  const handleToggleAssessment = async (id: string) => {
    try {
      const res = await assessmentToggleFun({
        activateAssessmentId: id,
      }).unwrap();
      return res.activateAssessment;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleToggleAssessment,
    isLoading,
  };
};
