import { Avatar, Col, Row, Select, Space } from "antd";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import CustomTextEditor from "components/form/custom-text-editor/CustomTextEditor";
import { useSpecialistConfigList } from "hooks/useSpecialistConfig";
import { FormikValues } from "formik";
import { MutationDeleteSubtitleArgs } from "graphql/_generated/graphql";
import styles from "features/content/css/AddContent.module.scss";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const TextContent = (props: {
  editId: string;
  value: string | null;
  values: FormikValues;
  languages: { label: string; value: string }[];
  handleDeleteSubTitle: (values: MutationDeleteSubtitleArgs) => Promise<void>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
}) => {
  const { t } = useTranslation();
  const { values } = props;
  const { getSpecialistConfigData } = useSpecialistConfigList();

  return (
    <>
      {/* this block of code is only run if the content type is text */}
      {values.contentType === "Text" && (
        <>
          <Row gutter={[24, 0]}>
            <Col sm={24} md={12}>
              <CustomSelectField
                label={t("select-specialist")}
                name="creatorId"
                className="form-select"
                placeholder={t("select-specialist")}
              >
                {getSpecialistConfigData?.map((item: any, index: any) => (
                  <Option value={item.value} key={index}>
                    <Space>
                      <CustomAvatar imgSrc={item.image} />
                      {item.label}
                    </Space>
                  </Option>
                ))}
              </CustomSelectField>
            </Col>
          </Row>
          <div className={styles["form-control"]}>
            <CustomTextEditor
              name="text"
              label={t("write-content")}
              placeholder={t("enter-content-title")}
            />
          </div>
        </>
      )}
    </>
  );
};

export default TextContent;
