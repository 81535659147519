import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import EventAdd from "features/events/event-add/EventAdd";

const EventAddPage = () => {
  return (
    <DashboardLayout openKey={["Activity"]}>
      <EventAdd />
    </DashboardLayout>
  );
};
export default EventAddPage;
