import { useDeleteCompanyRewardPointsMutation } from "graphql/_generated/graphql";

export const useDeleteException = () => {
  const [DeleteFun, { isLoading }] = useDeleteCompanyRewardPointsMutation();

  //this function is responsible for deleting company reward points
  const handleDeleteException = async (id: string) => {
    try {
      const res = await DeleteFun({
        deleteCompanyRewardPointsId: id,
      }).unwrap();
      return res.deleteCompanyRewardPoints;
    } catch (error) {
      throw error;
    }
  };
  return {
    isLoading,
    handleDeleteException,
  };
};
