import { useCallback, useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { customNotification } from "components/custom-notification/CustomNotification";
import CustomTextEditor from "components/form/custom-text-editor/CustomTextEditor";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import CustomTreeSelect from "components/form/custom-tree-select/CustomTreeSelect";
import { CustomUploadFile } from "components/form/custom-upload-file/CustomUploadFile";
import { errorFormat } from "helpers";
import { useSpecialistConfigList } from "hooks/useSpecialistConfig";
import {
  useGetCategoriesTreeQuery,
  useGetSpecialistListQuery,
} from "graphql/_generated/graphql";

import { useSpecialist } from "./hooks/useSpecialist";
import TranslationFields from "./TranslationFields";
import { specialistSchema } from "validations/schema";
import { useErrorHandler } from "hooks/useErrorHandler";

interface AddSpecialistProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  editId?: string | null;
  setEditId: React.Dispatch<React.SetStateAction<string | null>>;
}

interface SpecialistFormValues {
  image: string;
  firstName: string;
  lastName: string;
  specialty: string;
  description: string;
  categoriesIds: string[];
  translations: any[];
}

const defaultInitialValues: SpecialistFormValues = {
  image: "",
  firstName: "",
  lastName: "",
  specialty: "",
  description: "",
  categoriesIds: [],
  translations: [],
};

const AddSpecialist: React.FunctionComponent<AddSpecialistProps> = ({
  visible,
  setVisible,
  editId,
  setEditId,
}) => {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const { refetch } = useSpecialistConfigList();
  const { refetch: refetchSpecialists } = useGetSpecialistListQuery();
  const { data: categoriesData } = useGetCategoriesTreeQuery();
  const {
    isLoading,
    handleAddNewSpecialist,
    handleFetchEditSpecialistData,
    handleUpdateSpecialist,
  } = useSpecialist();

  const [initialValues, setInitialValues] =
    useState<SpecialistFormValues>(defaultInitialValues);

  const fetchData = useCallback(() => {
    if (editId) {
      handleFetchEditSpecialistData(editId).then((data) => {
        const updatedValues: SpecialistFormValues = {
          ...data,
          image: data.image ?? "",
          description: data.description ?? "",
        };
        setInitialValues(updatedValues);
      });
    } else {
      setInitialValues(defaultInitialValues);
    }
  }, [editId, handleFetchEditSpecialistData]);

  useEffect(() => {
    fetchData();
  }, [editId]);

  const handleCancel = () => {
    setVisible(false);
    setEditId(null);
  };

  const handleSubmit = async (
    values: SpecialistFormValues,
    resetForm: () => void,
  ) => {
    try {
      if (!editId) {
        await handleAddNewSpecialist({ create: values });
        customNotification("success", t("specialist-added-successfully"));
      } else {
        await handleUpdateSpecialist({ id: editId, updateObj: values });
        customNotification("success", t("specialist-updated-successfully"));
        setEditId(null);
      }
      refetch();
      refetchSpecialists();
      setVisible(false);
      resetForm();
    } catch (error) {
      handleError(error as string | object);
    }
  };

  return (
    <CustomModal
      title={editId ? t("edit-specialist") : t("add-specialist")}
      width={600}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={specialistSchema}
        onSubmit={async (values, { resetForm }) => {
          await handleSubmit(values, resetForm);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <CustomUploadFile
              name="image"
              setFieldValue={setFieldValue}
              imgUrl={values.image || ""}
              accept="image/*"
            />
            <CustomTextField
              name="firstName"
              label={t("first-name")}
              placeholder={t("first-name")}
            />
            <CustomTextField
              name="lastName"
              label={t("last-name")}
              placeholder={t("last-name")}
            />
            <CustomTextField
              name="specialty"
              label={t("specialty")}
              placeholder={t("specialty")}
            />
            <CustomTreeSelect
              allowClear
              showArrow
              multiple
              treeNodeFilterProp="name"
              treeLine={true && { showLeafIcon: false }}
              value={values.categoriesIds}
              label={t("select-category")}
              fieldNames={{
                label: "name",
                value: "id",
              }}
              className="form-select"
              name="categoriesIds"
              placeholder={t("select-category")}
              treeData={categoriesData?.getCategoriesTree}
              onChange={(values) => setFieldValue("categoriesIds", values)}
            />
            <CustomTextEditor
              label={t("description")}
              name="description"
              placeholder={t("enter-description")}
            />
            <div className="translation-fields-wrapper">
              <TranslationFields values={values} />
            </div>
            <ModalFooter
              saveText={t("save-specialist")}
              onCancel={handleCancel}
              loading={isLoading}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddSpecialist;
