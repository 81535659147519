import * as Yup from "yup";
import * as Validations from "validations/schema/common/validations";

const baseAdmin = Yup.object().shape({
  email: Validations.email,
  firstName: Validations.required("first-name"),
  lastName: Validations.required("last-name"),
});

export const clientAdminSchema = baseAdmin.shape({
  roleId: Validations.required("role"),
});

export const adminSchema = baseAdmin.shape({
  phoneNumber: Validations.required("phone-number"),
  roleId: Validations.required("role"),
});
