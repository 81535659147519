import { useResendCompanyInvite } from "./companies-list/useResendCompanyInvite";
import { useToggleInviteCompanyActivation } from "./companies-list/useToggleInviteCompanyActivation";

import { useInviteCompany } from "./invite-company/useInviteCompany";

export const useCompany = () => {
  const { inviteCompany, isLoading } = useInviteCompany();
  const { handleToggleActivationCompany, isLoading: ToggleActivationLoading } =
    useToggleInviteCompanyActivation();

  const { handleResendInvite, isLoading: isLoadingResendInvite } =
    useResendCompanyInvite();

  return {
    isLoading: isLoading || ToggleActivationLoading || isLoadingResendInvite,
    inviteCompany,
    handleToggleActivationCompany,
    handleResendInvite,
  };
};
