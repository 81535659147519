import * as React from "react";
import "assets/css/tailwind.scss";
import viwellLogo from "assets/images/viwell-logo.svg";
import facebook from "assets/images/facebook.svg";
import twitter from "assets/images/twitter.svg";
import instagram from "assets/images/instagram.svg";
import linkedin from "assets/images/linkedin.svg";
import { Form, Formik } from "formik";
import FormInput from "./FormInput";
import PhoneFormInput from "./FormPhoneInput";
import { deleteAccountSchema } from "validations/schema/deleteAccountSchema";
import customNotification from "components/custom-notification/CustomNotification";
import { useTranslation } from "react-i18next";
import { useDeleteAccount } from "./useDeleteAccount";
import useReCaptcha from "./useRecaptcha";

interface DeleteAccountProps {}

const DeleteAccount: React.FunctionComponent<DeleteAccountProps> = (props) => {
  const { t } = useTranslation();

  const { handleDeleteAccount, loading } = useDeleteAccount();
  const { token, fetchToken } = useReCaptcha("SignIn");
  return (
    <div className="min-h-screen bg-white">
      <div className="container mx-auto px-4">
        <div className="my-[30px] md:my-[64px]">
          <div className="text-center">
            <a
              href="https://viwell.com/"
              target="_blank"
              className="inline-block"
            >
              <img
                src={viwellLogo}
                alt="VIWELL Logo"
                className="w-24 h-5 md:w-[285px] md:h-[64px] mx-auto"
              />
            </a>
            <div className="mt-[32px] md:mt-[64px]">
              <h1 className="font-sf-pro-text font-bold text-base md:text-[32px]">
                Delete Account Data
              </h1>
              <p className="font-sf-pro-text font-normal text-xs md:text-2xl mt-[8px] md:mt-[16px]">
                Please add your information in order to complete your request
              </p>
            </div>
          </div>
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              contactInfo: "",
            }}
            validationSchema={deleteAccountSchema}
            onSubmit={async (values, { resetForm }) => {
              try {
                const res = await handleDeleteAccount({
                  body: values,
                  token: token ?? "",
                });
                resetForm();
                fetchToken();
                customNotification("success", t("verification-email-sent"));
              } catch (error) {
                customNotification("error", t("account-not-deleted"));
              }
            }}
          >
            <Form>
              <div className="w-full mt-[16px] md:mt-[20px] grid gap-y-5 md:grid-cols-2 md:gap-5 md:max-w-[670px] mx-auto">
                <FormInput name="firstName" label="First Name" />
                <FormInput name="lastName" label="Last Name" />
                <FormInput name="email" label="Email" />
                <PhoneFormInput
                  name="contactInfo"
                  label="Phone"
                  country={"ae"}
                />
              </div>
              <div className="w-full font-bold font-sf-pro-text text-sm md:max-w-[320px] mx-auto mt-[16px] md:mt-[20px] md:text-center">
                <button
                  type="submit"
                  className="w-full rounded-lg bg-[#FFD119] py-[10px] disabled:bg-[#D6D6D6]"
                  disabled={!!!token || loading}
                >
                  <div className="flex justify-center">
                    Request Account Deletion
                  </div>
                </button>
              </div>
            </Form>
          </Formik>
          <div className="text-center my-[32px] md:my-[40px]">
            <div className="flex gap-x-3 justify-center">
              <a
                href="https://viwell.com/data-privacy/terms-and-conditions/"
                target="_blank"
                className="font-normal text-xs md:text-base font-sf-pro-text hover:text-[#121212]"
              >
                Terms & Conditions
              </a>
              <div>-</div>
              <a
                href="https://viwell.com/data-privacy/privacy-policy/"
                target="_blank"
                className="font-normal text-xs  md:text-base  font-sf-pro-text hover:text-[#121212]"
              >
                Privacy Policy
              </a>
            </div>
          </div>
          <div className="flex flex-col items-center gap-y-4">
            <a
              href="https://viwell.com/"
              target="_blank"
              className="inline-block"
            >
              <img
                src={viwellLogo}
                alt="VIWELL Logo"
                className="w-[74px] h-[20px] md:w-[146px] md:h-[32px]"
              />
            </a>
            <div className="flex gap-x-8">
              <a href="https://web.facebook.com/viwell.life">
                <img
                  src={facebook}
                  alt="VIWELL Facebook"
                  className="w-6 h-6 md:w-8 md:h-8 "
                />
              </a>
              <a href="https://twitter.com/viwelllife">
                <img
                  src={twitter}
                  alt="VIWELL Twitter"
                  className="w-6 h-6 md:w-8 md:h-8 "
                />
              </a>
              <a href="https://www.instagram.com/viwell.life/">
                <img
                  src={instagram}
                  alt="VIWELL Instagram"
                  className="w-6 h-6 md:w-8 md:h-8 "
                />
              </a>
              <a href="https://www.linkedin.com/company/viwelllife">
                <img
                  src={linkedin}
                  alt="VIWELL Linkedin"
                  className="w-6 h-6 md:w-8 md:h-8 "
                />
              </a>
            </div>
            <h2 className="font-normal text-center text-xs font-sf-pro-text md:text-base">
              Business Bay, Dubai, UAE
            </h2>
            <h3 className="font-normal text-center text-xs font-sf-pro-text md:text-base mb-[32px] md:mb-[64px]">
              &copy; 2024, VIWELL Lifestyle coaching, All Rights Reserved.
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccount;
