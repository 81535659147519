import {
  useGetCategoryPillarsMappingListQuery,
  useToggleCategoryMappingActivationMutation,
} from "graphql/_generated/graphql";

// A hook for managing category mapping activation
export const useActivationMapper = () => {
  // Query to fetch the category pillars mapping list
  const { refetch } = useGetCategoryPillarsMappingListQuery();

  // Mutation to toggle category mapping activation
  const [activationDeactivationFun, { isLoading: activationLoading }] =
    useToggleCategoryMappingActivationMutation();

  // Function to handle the activation or deactivation of a category mapping
  const handleActivationDeactivation = async (id: string) => {
    try {
      // Attempt to perform the mutation
      const res = await activationDeactivationFun({
        toggleCategoryMappingActivationId: id,
      }).unwrap();

      // Refetch the data
      refetch();

      return res;
    } catch (error: any) {
      // Rethrow the error so it can be handled by the calling code
      throw error;
    }
  };

  // Return the loading status and the function for use by the calling code
  return { activationLoading, handleActivationDeactivation };
};
