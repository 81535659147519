import { Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import * as React from "react";

interface BulkRewardsErrorsProps {
  data: any;
}

const BulkRewardsErrors: React.FunctionComponent<BulkRewardsErrorsProps> = ({
  data,
}) => {
  const columns: ColumnsType<any> = [
    {
      title: "Row Number",
      dataIndex: "row",
      width: 120,
      ellipsis: true,
    },
    {
      title: "Column Name",
      dataIndex: "column",
      width: 150,
      ellipsis: true,
    },
    {
      title: "Error Description",
      dataIndex: "error",
      fixed: "right",
      render: (_text, record, _index) => (
        <Typography.Paragraph
          ellipsis={{
            expandable: true,
            rows: 2,
          }}
        >
          {record?.error}
        </Typography.Paragraph>
      ),
    },
  ];

  return (
    <div style={{ margin: "1.5rem 0px" }}>
      <Table
        className="custom-table"
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    </div>
  );
};

export default BulkRewardsErrors;
