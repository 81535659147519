import { Space } from "antd";
import * as React from "react";
import styles from "./TextOptions.module.scss";
import { CustomTextField } from "../../../../components/form/custom-text-field/CustomTextField";
import { Form, Formik } from "formik";
import { TAlphanumeric } from "types/TAlphaNumeric";
import { useTranslation } from "react-i18next";
interface TextOptionsProps {
  values: any;
  namePrefix?: string;
  isDisabled?: boolean;
  isSubQuestion?: boolean;
  isAddLanguage?: boolean;
}

const TextOptions: React.FunctionComponent<TextOptionsProps> = (props) => {
  const { t } = useTranslation();
  const namePrefix = `${props?.namePrefix ? props?.namePrefix + "." : ""}`;

  return (
    <div className={styles["text-header"]}>
      <Space align="baseline">
        <div>{t("minimum-characters-allowed")}</div>
        <CustomTextField
          name={`${namePrefix}minChar`}
          placeholder={t("minimum-characters")}
          disabled={props?.isDisabled || props?.isAddLanguage}
          type="number"
        />
      </Space>
      <Space align="baseline">
        <div>{t("maximum-characters-allowed")}</div>
        <CustomTextField
          name={`${namePrefix}maxChar`}
          placeholder={t("maximum-characters")}
          disabled={props?.isDisabled || props?.isAddLanguage}
          type="number"
        />
      </Space>
    </div>
  );
};

export default TextOptions;
