import { Modal } from "antd";
import { customNotification } from "components/custom-notification/CustomNotification";
import { FormikHelpers } from "formik";
import { useGetPositionsConfigQuery } from "graphql/_generated/graphql";
import { CONFIRM_MODAL_CONFIG } from "helpers/constants";
import { deepCompareObjects } from "helpers/deepCompareObjects";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useLoggedInUser } from "hooks/useLoggedInUser";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useEditEmployeeDetail } from "../hooks/useEditEmployee";
import { useUsers } from "../hooks/useUsers";
import {
  Gender,
  InviteSingleUserProps,
} from "../invite-users/InviteSingleUser";
import { useGetUserConfig } from "./useGetUserConfig";

interface EmployeeCreateDto {
  email: string;
  departmentId?: string;
  positionId?: string;
  buildingId?: string;
  // city: string;
  firstName?: string;
  lastName?: string;
  gender?: Gender | null;
  phoneNumber?: string | null;
  dob?: string | null;
}

export interface EmployeeData {
  employeeCreateDto: EmployeeCreateDto;
  employerId: string;
}

export type HandleParams = {
  employeeValues: EmployeeData;
  formikHelpers?: FormikHelpers<EmployeeData>;
};

export const useInviteSingleUserViewModel = (
  props: InviteSingleUserProps,
  confirmContent: React.ReactNode,
) => {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const [employerId, setEmployerId] = useState<null | string>(null);
  const [configData, setConfigData] = useState<{
    companies: any;
    departments: any;
    positions: any;
    buildings: any;
  }>({
    companies: [],
    departments: [],
    positions: [],
    buildings: [],
  });
  const { companyId: loggedInEmployerId } = useLoggedInUser();

  const {
    handleUpdateEmployeeDetail,
    handleUpdateEmployeeEmail,
    handleFetchEmployeeDetailData,
    isLoading: isLoadingEmployee,
  } = useEditEmployeeDetail();
  const { getClientsTreeQueryFun, getEmployeeListFun, refetchEmployees } =
    useGetUserConfig();
  const isEditMode = !!props.employeeId;
  const { isLoading, inviteSingleEmployee } = useUsers();
  const initValues: EmployeeData = useMemo(
    () => ({
      employeeCreateDto: {
        email: "",
        departmentId: "",
        positionId: "",
        buildingId: "",
        // city: "",
        phoneNumber: null,
        firstName: "",
        lastName: "",
        dob: null,
        gender: null,
      },
      employerId: "",
    }),
    [],
  );
  const [initialValues, setInitialValues] = useState<EmployeeData>(initValues);

  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    getEmployeeDetail();
  }, [props.employeeId]);

  const { data: positions } = useGetPositionsConfigQuery();

  useEffect(() => {
    const position = positions?.getPositionsConfig?.options?.find(
      (x) => x?.id === initialValues?.employeeCreateDto?.positionId,
    );
    position &&
      setInitialValues((value) => {
        return {
          ...value,
          employeeCreateDto: {
            ...value.employeeCreateDto,
            departmentId: position.extra?.departmentId,
          },
        };
      });
  }, [
    configData.positions,
    props.employeeId,
    initialValues?.employeeCreateDto?.positionId,
  ]);

  useEffect(() => {
    if (props.employerId) {
      setEmployerId(props.employerId);
      setInitialValues((value) => {
        value.employerId = props.employerId!;
        return value;
      });
    }

    if (loggedInEmployerId) {
      setEmployerId(loggedInEmployerId);
    }
  }, [loggedInEmployerId, props.employerId]);

  const fetchClients = async () => {
    const { data: clientData } = await getClientsTreeQueryFun({
      clientId: null,
    });

    setConfigData((prevData) => ({
      ...prevData,
      companies: clientData?.getClientsTree,
    }));
  };

  const getEmployeeDetail = async () => {
    if (isEditMode) {
      try {
        const { buildingId, positionId, clientId } =
          await handleFetchEmployeeDetailData(props.employeeId!);

        const { email, firstName, lastName, dob, gender, phoneNumber } =
          props.selectedEmployee;

        setInitialValues({
          employeeCreateDto: {
            ...initValues.employeeCreateDto,
            email: email || "",
            // city,
            positionId,
            buildingId,
            firstName,
            lastName,
            dob,
            gender,
            phoneNumber,
          },
          employerId: clientId!,
        });

        setEmployerId(clientId);
      } catch (error) {
        const {
          email,
          // city,
          employer: { id: employerId },
        } = props.selectedEmployee;
        setInitialValues({
          employeeCreateDto: {
            ...initValues.employeeCreateDto,
            email: email || "",
            // city,
          },
          employerId,
        });

        setEmployerId(props.selectedEmployee?.employer?.id);
      }
    } else {
      setInitialValues(initValues);
    }
  };

  const handleOk = async ({ employeeValues, formikHelpers }: HandleParams) => {
    try {
      if (props.employeeId) {
        // First API call
        const msg = await handleUpdateEmployeeEmail(
          props.employeeId,
          employeeValues.employeeCreateDto.email,
        );
        customNotification("success", msg);
        await getEmployeeListFun().unwrap();
        // Second API call in case other fields are changed
        const { email, ...restValues } = employeeValues.employeeCreateDto;
        const { email: initialEmail, ...restInitialValues } =
          initialValues.employeeCreateDto;

        if (!deepCompareObjects(restValues, restInitialValues)) {
          await handleUpdateEmployeeDetail({
            id: props.employeeId,
            clientId: employeeValues.employerId,
            update: employeeValues.employeeCreateDto,
          });
          await getEmployeeListFun().unwrap();
        }
      }
      formikHelpers?.resetForm();
      props.setVisible(false);
      props.setEmployeeId && props.setEmployeeId(null);
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const handleConfirmEmailChange = async ({
    employeeValues,
    formikHelpers,
  }: HandleParams) => {
    props.setVisible(false);
    Modal.confirm({
      ...CONFIRM_MODAL_CONFIG,
      closable: true,
      centered: true,
      title: t("warning"),
      content: confirmContent,
      width: 600,
      onOk: () =>
        handleOk({
          employeeValues,
          formikHelpers,
        }),
    });
  };

  const handleUpdateEmployee = async ({ employeeValues }: HandleParams) => {
    await handleUpdateEmployeeDetail({
      id: props.employeeId,
      clientId: employeeValues.employerId,
      update: employeeValues.employeeCreateDto,
    });
    customNotification("success", t("employee-updated-successfully"));
  };

  const handleInviteEmployee = async ({
    employeeValues,
    formikHelpers,
  }: HandleParams) => {
    try {
      await inviteSingleEmployee({
        parentId: employerId,
        employeeCreateDto: employeeValues.employeeCreateDto,
      });
      customNotification("success", t("employee-invited-successfully"));
      formikHelpers?.resetForm();
      refetchEmployees();
      props.setVisible(false);
      props.setEmployeeId && props.setEmployeeId(null);
    } catch (error) {
      throw error;
    }
  };

  const onSubmit = async ({ employeeValues, formikHelpers }: HandleParams) => {
    try {
      if (
        isEditMode &&
        employeeValues.employeeCreateDto.email ===
          initialValues.employeeCreateDto.email
      ) {
        await handleUpdateEmployee({ employeeValues });
        formikHelpers?.resetForm();
        refetchEmployees();
        props.setVisible(false);
        props.setEmployeeId && props.setEmployeeId(null);
      } else if (
        isEditMode &&
        employeeValues.employeeCreateDto.email !=
          initValues.employeeCreateDto.email
      ) {
        await handleConfirmEmailChange({ employeeValues, formikHelpers });
      } else {
        await handleInviteEmployee({ employeeValues, formikHelpers });
      }
    } catch (error) {
      handleError(error as string | object);
    }
  };

  return {
    onSubmit,
    configData,
    employerId,
    initialValues,
    isLoading,
    isLoadingEmployee,
    setEmployerId,
  };
};
