import { Button, ButtonProps } from "antd";
import { PlusCircleFilled } from "@ant-design/icons";
import "./CustomButton.module.scss";
import * as React from "react";

interface AddOptionProps extends ButtonProps {
  title?: string;
}

const AddOption: React.FunctionComponent<AddOptionProps> = (props) => {
  return (
    <Button
      className="add-option-btn"
      type="link"
      icon={<PlusCircleFilled />}
      {...props}
    >
      {props.title ?? "Add New Option"}
    </Button>
  );
};

export default AddOption;
