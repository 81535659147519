import { FulFillmentIcon } from "assets/icons/FulFillmentIcon";
import { MoodIcon } from "assets/icons/MoodIcon";
import { OnboardingIcon } from "assets/icons/OnboardingIcon";
import { SleepIcon } from "assets/icons/SleepIcon";
import { StressIcon } from "assets/icons/StressIcon";
import { ViwellIcon } from "assets/icons/ViwellIcon";

export const assessmentTypeIcons: any = {
  viwell: ViwellIcon,
  onboarding: OnboardingIcon,
  sleep: SleepIcon,
  fulfillment: FulFillmentIcon,
  mood: MoodIcon,
  stress: StressIcon,
};
export const getTypeIcon = (name: string) => {
  const type = name?.split(" ")[0].toLowerCase();
  const icon = assessmentTypeIcons?.[type] || assessmentTypeIcons.viwell;
  return icon;
};
