import { Editor } from "@tinymce/tinymce-react";
import { ErrorMessage, useField } from "formik";
import * as React from "react";
import { Editor as TinyMCEEditor } from "tinymce";
import { CONFIG } from "helpers/constants";
import { useErrorTranslation } from "validations/schema/common/useErrorTranslation";
import "./CustomTextEditor.module.scss";
import { getLangDir } from "helpers/getLangDir";
import { Space, Tooltip } from "antd";
import InfoCircleFilled from "@ant-design/icons/InfoCircleFilled";

interface CustomTextEditorProps {
  label?: string;
  name: string;
  placeholder?: string | any;
  isRestrictAccess?: boolean;
  disabled?: boolean;
  oldTextValue?: React.ReactNode;
  isAddLanguage?: boolean;
}

const apiKey =
  process.env.REACT_APP_TINY_MCE_API_KEY || CONFIG.TINY_MCE_API_KEY;

const CustomTextEditor: React.FunctionComponent<CustomTextEditorProps> = (
  props,
) => {
  const { errorT } = useErrorTranslation();
  const { label, name, oldTextValue, isAddLanguage } = props;
  const [field, meta] = useField(name);
  const type = "text";
  const handleEditorChange = (value: string, editor: TinyMCEEditor) => {
    field.onChange({ target: { type, name, value } });
    const direction = getLangDir(editor.getContent({ format: "text" }));
    if (direction === "rtl") {
      editor.getBody().style.direction = "rtl";
      editor.getBody().style.fontFamily = "Almarai";
    } else {
      editor.getBody().style.direction = "ltr";
      editor.getBody().style.fontFamily =
        "-apple-system, BlinkMacSystemFont, sans-serif";
    }
  };

  const handleBlur = () => {
    field.onBlur({ target: { name } });
  };

  return (
    <div className="text-editor">
      {label && (
        <Space>
          <label className="label" htmlFor={name}>
            {label}
          </label>
          {isAddLanguage && (
            <Tooltip title={oldTextValue}>
              <InfoCircleFilled />
            </Tooltip>
          )}
        </Space>
      )}
      <Editor
        value={field.value}
        onEditorChange={handleEditorChange}
        onBlur={handleBlur}
        disabled={props?.disabled}
        textareaName={props.name}
        apiKey={apiKey}
        init={{
          setup: (editor) => {
            editor.on("init", () => {
              const direction = getLangDir(
                editor.getContent({ format: "text" }),
              );
              if (direction === "rtl") {
                editor.getBody().style.direction = "rtl";
                editor.getBody().style.fontFamily = "Almarai";
              } else {
                editor.getBody().style.direction = "ltr";
                editor.getBody().style.fontFamily =
                  "-apple-system, BlinkMacSystemFont, sans-serif";
              }
            });
          },
          plugins:
            "preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons accordion",
          menubar: props.isRestrictAccess
            ? ""
            : "file edit view insert format tools table help",
          toolbar: props.isRestrictAccess
            ? "bold italic underline strikethrough | blocks | align numlist bullist | undo redo"
            : "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | accordion accordionremove | lineheight outdent indent | link image | table media | forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | save print | pagebreak anchor codesample | ltr rtl",
          toolbar_sticky: false,
          autosave_ask_before_unload: true,
          autosave_interval: "30s",
          autosave_prefix: "{path}{query}-{id}-",
          autosave_restore_when_empty: false,
          autosave_retention: "2m",
          image_advtab: true,
          importcss_append: true,
          height: 350,
          image_caption: true,
          quickbars_selection_toolbar:
            "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
          noneditable_class: "mceNonEditable",
          toolbar_mode: "sliding",
          contextmenu: "link image table",
          // skin: useDarkMode ? "oxide-dark" : "oxide",
          // content_css: useDarkMode ? "default" : "default",
          content_style:
            "body { font-family: -apple-system, BlinkMacSystemFont, sans-serif; font-size:14px; line-height:1.4; }",
          font_family_formats:
            "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; SF Pro Text=-apple-system, BlinkMacSystemFont, sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Oswald=oswald; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
          font_size_formats: "8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt",
          elementpath: false,
          branding: false,
          file_picker_types: "file, image, media",
          browser_spellcheck: true,
          automatic_uploads: true,
          paste_data_images: true,
          placeholder: props.placeholder,
          content_css: [
            "https://fonts.googleapis.com/css2?family=Almarai&display=swap",
          ],
          content_css_cors: true,
        }}
      />
      <ErrorMessage
        name={name}
        render={(msg) => <div className="text-danger">{errorT(msg)}</div>}
      />
    </div>
  );
};

export default CustomTextEditor;
