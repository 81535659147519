import { Spin } from "antd";
import AuthLayout from "features/auth/auth-layout/AuthLayout";
import { setToken } from "features/auth/authSlice";
import { customNotification } from "components/custom-notification/CustomNotification";
import { useRefreshToken } from "hooks/useRefreshToken";
import { useLazyGetPermissionsQuery } from "graphql/_generated/graphql";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { permissions } from "store/permissionsSlice";
import { useCompanyProfile } from "features/company/hooks/useCompanyProfile";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useLayoutEffect } from "react";
import { LoadingOutlined } from "@ant-design/icons";

const CompanyProfile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleError } = useErrorHandler();
  const { handleCompleteClientProfile } = useCompanyProfile();
  const refreshToken = useSelector((state: any) => state.auth.refreshToken);
  const { handleRefreshToken } = useRefreshToken();
  const [getPermissionsFun] = useLazyGetPermissionsQuery();

  const completeClientProfile = async () => {
    try {
      const res = await handleCompleteClientProfile({
        completeProfileDTO: {
          clientAdmin: {
            firstName: "Enterprise",
            lastName: "Administrator",
          },
        },
      });

      //get new tokens
      const newTokens = await handleRefreshToken({
        tokens: { deviceToken: "", refreshToken },
      });
      //replace the new tokens with the new tokens in the store
      dispatch(setToken(newTokens.refreshToken));

      // navigate to company dashboard
      navigate("/company-dashboard");

      if (res.completeClientProfile.msg) {
        // Call permissions API after successful completion of the first API call
        const data = await getPermissionsFun().unwrap();
        // Store the Permissions to store
        dispatch(permissions(data.getPermissions));
      }

      customNotification("success", res.completeClientProfile.msg);
    } catch (error) {
      handleError(error as string | object);
    }
  };

  useLayoutEffect(() => {
    completeClientProfile();
  }, []);

  return (
    <AuthLayout>
      <div
        style={{
          minHeight: "100vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Spin
          indicator={
            <LoadingOutlined style={{ fontSize: 32, color: "black" }} spin />
          }
        />
      </div>
    </AuthLayout>
  );
};

export default CompanyProfile;
