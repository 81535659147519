import Admins from "pages/admin/Admins";
import AdminClient from "features/admin/client/admin/AdminClient";
import CompanyDashboard from "features/company-dashboard/CompanyDashboard";

const LoggedAdmins = ["SuperAdmin", "VIWELLAdmin"];
const ClientAdmin = "ClientAdmin";
type Route = {
  path: string;
  element: JSX.Element;
};

/**
 * @TODO: This is a temporary fix
 * Until roles are not assigned
 *
 * @param userRole
 * @returns routes
 */
const getAdminRoutes = function (userRole: string): Route[] {
  if (LoggedAdmins.includes(userRole)) {
    return [{ path: "/admins", element: <Admins /> }];
  } else if (userRole === ClientAdmin) {
    return [
      { path: "/client-admins", element: <AdminClient /> },
      { path: "/company-dashboard", element: <CompanyDashboard /> },
    ];
  }
  return [];
};

export default getAdminRoutes;
