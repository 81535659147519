import { Avatar, Space, Switch, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import CustomTable from "components/custom-table/CustomTable";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import {
  useGetClientAdminListQuery,
  useLazyGetClientAdminListQuery,
  useGetConfigRolesQuery,
  ClientAdminList,
  Comparison,
} from "graphql/_generated/graphql";
import { customNotification } from "components/custom-notification/CustomNotification";
import CustomPopconfirm from "components/form/custom-pop-confirm/CustomPopConfirm";
import { PERMS, errorFormat } from "helpers";
import { useClientAdmin } from "../useClientAdmin";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import AddClientAdmin from "../add/AddClientAdmin";
import CustomDropDown from "components/custom-dropdown/CustomDropDown";
import CustomButton from "components/custom-button/CustomButton";
import { useResendInvite } from "features/users/hooks/useResendInvite";
import { MoreOutlined } from "@ant-design/icons";
import { useErrorHandler } from "hooks/useErrorHandler";

interface AdminClientListProps {}

const AdminClientList: React.FunctionComponent<AdminClientListProps> = () => {
  const { t } = useTranslation();
  const [filtersData, setFiltersData] = useState<any>(null);
  const [columnsData, setColumnsData] = useState<ClientAdminList>();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const { handleToggleClientActivation } = useClientAdmin();
  const [visible, setVisible] = useState<boolean>(false);
  const [editId, setEditId] = useState<string | null>(null);

  const { isFetching } = useGetClientAdminListQuery();
  const [getClientAdminList] = useLazyGetClientAdminListQuery();
  const { data: rolesData } = useGetConfigRolesQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const { handleResendInvite } = useResendInvite();
  const { handleError } = useErrorHandler();

  const clientFilter = {
    key: "isMainAdmin",
    value: false,
    comparison: Comparison.Equal,
  };

  useEffect(() => {
    setFiltersData({
      filters: {
        and: [clientFilter],
      },
    });
  }, []);

  useEffect(() => {
    if (!filtersData) {
      fetchDataFromApi(getClientAdminList, setColumnsData, {
        filters: {
          and: [clientFilter],
        },
      }).catch((e) => handleError(e));
    } else {
      fetchDataFromApi(getClientAdminList, setColumnsData, filtersData).catch(
        (e) => handleError(e),
      );
    }
  }, [isFetching, filtersData]);

  const inviteAdmin = async (id: string) => {
    try {
      await handleResendInvite({ id });
      customNotification("success", t("resent-invite"));
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const handleMenu = ({
    key,
    id,
    record,
  }: {
    key: string;
    id: string;
    record: ClientAdminList["data"][0];
  }) => {
    if (key === "resend-invite") {
      inviteAdmin(id);
    }
    if (key === "edit-details") {
      setEditId(id);
      setVisible(true);
    }
  };

  const columns: ColumnsType<ClientAdminList["data"][0]> = [
    {
      title: `${t("id")}`,
      key: "id",
      dataIndex: "id",
      sorter: {
        multiple: 1,
      },
      render: (_text, _record, index) =>
        (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: `${t("name")}`,
      key: "firstName",
      dataIndex: "firstName",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _number) => (
        <Space>
          <CustomAvatar imgSrc={record.image ?? ""} />
          <span>
            {record.firstName} {record.lastName}
          </span>
        </Space>
      ),
    },
    {
      title: t("role"),
      key: "roles",
      dataIndex: "roles",
      render: (_text, record, _index) =>
        record.roles.map((item) => <Space>{item?.name}</Space>),
    },
    {
      title: `${t("created-at")}`,
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: {
        multiple: 1,
      },
      render: (createdAt: string) =>
        moment(createdAt).format("MMMM Do YYYY, h:mm:ss a"),
    },
    {
      title: `${t("updated-at")}`,
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: {
        multiple: 1,
      },
      render: (updatedAt: string) =>
        moment(updatedAt).format("MMMM Do YYYY, h:mm:ss a"),
    },
    {
      title: `${t("status")}`,
      dataIndex: "isActive",
      key: "isActive",
      align: "center" as "center",
      render: (_text, record, _index) =>
        !record.isProfileComplete ? (
          <Tag className="pending-tag">{t("pending")}</Tag>
        ) : record.isActive ? (
          <Tag className="active-tag">{t("active")}</Tag>
        ) : (
          <Tag className="de-active-tag">{t("deactivated")}</Tag>
        ),
    },
    {
      title: `${t("activation")}`,
      dataIndex: "id",
      key: "id",
      align: "center" as "center",
      render: (id: string, { isActive }: any) => (
        <CustomPopconfirm
          onConfirm={async () => {
            try {
              const isActivityActive = await handleToggleClientActivation(id);

              customNotification(
                "success",
                isActivityActive
                  ? t("admin-activated-success")
                  : t("admin-deactivated-success"),
              );

              // callRefetch();
            } catch (error) {
              handleError(error as string | object);
            }
          }}
          title={
            isActive
              ? t("confirm-deactivate-admin")
              : t("confirm-activate-admin")
          }
        >
          <Switch className="custom-switch" defaultChecked checked={isActive} />
        </CustomPopconfirm>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "Actions",
      align: "center",
      render: (id: string, record) => (
        <CustomDropDown
          menu={{
            items: [
              {
                key: "edit-details",
                label: t("edit-details"),
              },
              ...(!record.isProfileComplete
                ? [
                    {
                      key: "resend-invite",
                      label: t("resend-invite"),
                    },
                  ]
                : []),
            ],
            onClick: ({ domEvent, key, keyPath }) =>
              handleMenu({ key, id, record }),
          }}
        >
          <CustomButton type="link" className="link-btn">
            <MoreOutlined />
          </CustomButton>
        </CustomDropDown>
      ),
    },
  ];

  return (
    <>
      <CustomTable
        columns={columns}
        filters={{
          handleCallback: async (filtersData: any) => {
            const mergedFilters = { ...filtersData };
            mergedFilters.filters = {
              ...mergedFilters.filters,
              and: [clientFilter, ...(mergedFilters.filters?.and || [])],
            };
            await fetchDataFromApi(
              getClientAdminList,
              setColumnsData,
              mergedFilters,
            );
            setPageSize(mergedFilters?.pagination?.offset?.pageSize);
            setCurrentPage(mergedFilters?.pagination?.offset?.page);
            setFiltersData(mergedFilters);
          },

          date: [
            {
              applyAs: "and",
              key: "createdAt",
              comparison: "Range",
            },
          ],
          status: {
            applyAs: "and",
            key: "isActive",
            comparison: "Equal",
          },
        }}
        totalItems={columnsData?.metadata?.total}
        loading={isLoading}
        onClick={() => setVisible(true)}
        headerTitle={t("admins")}
        btnText={t("add-new-admin")}
        dataSource={columnsData?.data}
        rowKey="id"
      />
      {visible && (
        <AddClientAdmin
          visible={visible}
          setVisible={setVisible}
          editId={editId}
          setEditId={setEditId}
        />
      )}
    </>
  );
};

export default AdminClientList;
