import { KSAFlag } from "assets/icons/KSAFlagSvg";
import { GlobalFlag } from "assets/icons/GlobalFlagSvg";
export const getRegionIcon = (regionalServiceKey: string) => {
  const regionFlagsMap = {
    KSA: KSAFlag,
    GLOBAL: GlobalFlag,
  };

  return regionFlagsMap[regionalServiceKey as keyof typeof regionFlagsMap];
};
