import {
  useGetViwellAdminListQuery,
  useToggleViwellAdminActivationMutation,
} from "graphql/_generated/graphql";

export type TypeToggleViwellAdmin = (id: string) => Promise<boolean>;

type TypeToggleViwellAdminHook = () => {
  isLoading: boolean;
  handleToggleActivation: TypeToggleViwellAdmin;
};

export const useToggleInviteAdminActivation: TypeToggleViwellAdminHook =
  () => {
    const { refetch, isLoading: getAdminsLoading } = useGetViwellAdminListQuery();

    const [toggleFunc, { isLoading }] =
      useToggleViwellAdminActivationMutation();

    //this function is responsible for activate and deactivate admin
    const handleToggleActivation = async (id: string) => {
      try {
        const res = await toggleFunc({
          toggleViwellAdminActivationId: id,
        }).unwrap();
        refetch();
        return res.toggleViwellAdminActivation;
      } catch (error: any) {
        throw error;
      }
    };

    return {
      isLoading: isLoading || getAdminsLoading,
      handleToggleActivation,
    };
  };
