import styles from "features/assessment/css/AssessmentCreate.module.scss";
import {
  useLazyGetAdminAssessmentQuestionsQuery,
  useLazyGetAssessmentCarousalQuery,
} from "graphql/_generated/graphql";

import Icon from "@ant-design/icons";
import { Form, Space } from "antd";
import { ConfigSvg } from "assets/icons";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import CustomButton from "components/custom-button/CustomButton";
import AssessmentQuestionHeading from "features/assessment/pillar/AssessmentPillerHeading";
import ContainerHeading from "features/assessment/questions/ContainerHeading";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";

import AddElement from "components/AddElement/AddElement";
import CheckinQuestion from "features/assessment/questions/CheckinQuestion";
import OnBoarding from "features/onboardings/OnBoardings";
import generateUniqueId from "helpers/generateUniqueId";
import { cloneDeep } from "lodash";
import { useLocation, useNavigate } from "react-router";

import { customNotification } from "components/custom-notification/CustomNotification";
import { CustomTextArea } from "components/form/custom-text-area/CustomTextArea";
import { StringMap } from "components/interfaces";
import CollapseAble from "features/assessment/questions/collapseables";
import AssessmentSettings from "features/assessment/settings/AssessmentSettings";
import EnumAssessmentTypes from "features/assessment/types/EnumAssessmentTypes";
import ICheckIn from "features/assessment/types/ICheckIn";
import ICheckInHash from "features/assessment/types/ICheckInHash";
import ICheckInSettings from "features/assessment/types/ICheckInSettings";
import IInitialObjectProps from "features/assessment/types/IInitialObjectProps";
import { getLanguage } from "helpers";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import AssessmentRoutes from "routes/RouteCollections/AssessmentRoutes";
import { RootState } from "store/store";
import { assessmentSchema } from "validations/schema";
import { useGetAssessmentSettings } from "../../features/assessment/hooks/useGetAssessmentSettings";
import { useUpdateAssessment } from "../../features/assessment/hooks/useUpdateAssessment";

const checkInId = generateUniqueId();

const CheckInInitialObject: ICheckIn = {
  image: "",
  description: "",
  translations: {
    description: "",
  },
};

const initialCheckIns: ICheckInHash = {
  [checkInId]: CheckInInitialObject,
};

const settingInitialValues: ICheckInSettings = {
  name: "",
  enableWearableData: true,
  color: "#7573E1",
  notifications: false,
  integrationType: "",
  emoji: null,
};

const initialObject: IInitialObjectProps = {
  description: "",
};

interface NavigationProps {
  message: string;
  showMessage?: boolean;
}

/**
 * Create CheckIn component.
 *
 * @returns JSX
 */
const CheckInCreate: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const params = location.state;

  const [getQuestionsFunc] = useLazyGetAdminAssessmentQuestionsQuery();
  const [getAssessmentCarousalFunc] = useLazyGetAssessmentCarousalQuery();
  const { handleGetAssessmentSettings, isLoadingAssessmentSettings } =
    useGetAssessmentSettings();
  const { handleUpdateAssessment } = useUpdateAssessment();
  const [checkInQuestions, setCheckInQuestions] = useState<StringMap>({});
  const [checkInCarousals, setCheckInCarousals] = useState<any>();
  const [assessmentId, setAssessmentId] = useState<string>(
    params?.assessmentId || "",
  );
  const formRef = useRef<any>();

  const [visible, setVisible] = useState<boolean>(false);
  const [refetchSettings, setRefetchSettings] = useState<boolean | null>(false);

  const [settings, updateSettings] =
    useState<ICheckInSettings>(settingInitialValues);

  const [checkIns, setCheckIns] = useState<ICheckInHash>(initialCheckIns);

  const [initialValues, setInitialValues] = useState<any>(initialObject);
  const region = useSelector((state: RootState) => state.auth.region);

  const [settingsInitialValues, setSettingsInitialValues] = useState<any>({
    notificationSetting: [],
    deleteNotifications: [],
  });

  useEffect(() => {
    if (!assessmentId) return;
    getCarousals();
    getQuestions();
    getAssessmentSettings();
  }, [assessmentId]);

  const navigateToList = ({ message, showMessage = true }: NavigationProps) => {
    showMessage &&
      customNotification(
        "info",
        message || "Create or Edit an Assessment first.",
      );

    navigate(AssessmentRoutes.AssessmentListRoute.path);
  };

  useEffect(() => {
    getQuestions(false);
  }, [region]);

  useEffect(() => {
    refetchSettings && getAssessmentSettings();
  }, [refetchSettings]);

  const getAssessmentSettings = () => {
    handleGetAssessmentSettings(assessmentId).then((data) => {
      setSettingsInitialValues(data);
      setRefetchSettings(false);
    });
  };

  const getQuestions = async (showMessage?: boolean) => {
    try {
      const response = await getQuestionsFunc({
        assessmentId: assessmentId!,
        language: getLanguage(params?.assessmentLanguage),
      }).unwrap();

      setInitialValues(response.getAdminAssessmentQuestions?.settings!);
      setCheckInQuestions(response.getAdminAssessmentQuestions?.questions);
    } catch (error) {
      navigateToList({ message: "Error Loading Assessment.", showMessage });
    }
  };

  const getCarousals = async () => {
    const { getAssessmentCarousal } = await getAssessmentCarousalFunc({
      assessmentId: assessmentId!,
      language: getLanguage(params?.assessmentLanguage),
    }).unwrap();
    setCheckInCarousals(getAssessmentCarousal);
  };

  const saveSettings = (payload: ICheckInSettings) => {
    toggleSettings();
    updateSettings(payload);
  };

  const handleCarousalsDelete = (indexToDelete: number) => {
    setCheckInCarousals((prevCarousals: any) => {
      return prevCarousals.filter(
        (carousal: any, index: number) => index !== indexToDelete,
      );
    });
  };

  /**
   * Add Checkin.
   */
  const addCheckIn = () => {
    const newCheckIn = {
      ...CheckInInitialObject,
      // id: generateUniqueId(),
      // SN: setCheckInCarousals.length + 1,
    };

    setCheckInCarousals((oldCheckIns: any) => [
      ...(oldCheckIns || []),
      newCheckIn,
    ]);
  };

  /**
   * Delete CheckIn
   *
   * @param id number
   */
  const deleteCheckIn = (id: string) => {
    const checkInsArr = cloneDeep(checkIns);
    setCheckIns(checkInsArr);
  };

  /**
   * Toggle settings modal.
   */
  const toggleSettings = () => {
    setVisible(!visible);
  };

  /**
   * Handle form submission.
   *
   * @param values any
   * @param resetForm any
   */
  const handleSubmission = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  /**
   * CheckIn Question Panel Title mapping
   */
  const checkInPanelTitle: { [key: string]: string } = {
    [EnumAssessmentTypes.FULFILLMENT_CHECK_IN]: "Fulfillment ",
    [EnumAssessmentTypes.STRESS_CHECK_IN]: "Stress ",
    [EnumAssessmentTypes.MOOD_CHECK_IN]: "Mood ",
    [EnumAssessmentTypes.SLEEP_CHECK_IN]: "Sleep ",
  };

  return (
    <DashboardLayout className={styles["assessment-create"]}>
      <div className={styles["assessment-create-header"]}>
        <Breadcrumbs
          data={[
            {
              label: t("assessments"),
            },
            {
              label: t("assessment-list"),
            },
            {
              label: ` ${params?.assessmentTypeName || ""} - ${getLanguage(
                params?.assessmentLanguage,
              )} `,
            },
          ]}
        />

        <Space>
          <CustomButton
            className="assessment-create-header-cancel link-text-btn"
            onClick={() =>
              navigate(`${AssessmentRoutes.AssessmentListRoute.path}#persist`)
            }
          >
            {t("cancel")}
          </CustomButton>
          <CustomButton className="light-btn icon" onClick={toggleSettings}>
            <Icon component={ConfigSvg} />
            {t("settings")}
          </CustomButton>
          <CustomButton className="light-btn" onClick={handleSubmission}>
            {t("save-draft")}
          </CustomButton>
          <CustomButton className="primary-btn" onClick={handleSubmission}>
            {t("save-publish")}
          </CustomButton>
        </Space>
      </div>

      <Formik
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize={true}
        initialValues={initialValues}
        innerRef={formRef}
        validationSchema={assessmentSchema}
        onSubmit={async (values) => {
          try {
            handleUpdateAssessment({
              id: values.id,
              update: {
                description: values.description,
              },
            });
            customNotification("success", t("assessment-updated-success"));
          } catch (error) {
            handleError(error as string | object);
          }
        }}
      >
        {({ values, setFieldValue, errors, validateOnChange }) => {
          return (
            <Form className={styles["assessment-create-form"]}>
              <div className={styles["pillars"]}>
                <CollapseAble
                  defaultActiveKey={["1"]}
                  headerKey={"infoSection"}
                  header={<ContainerHeading text={t("description")} />}
                  hideExtra={true}
                  expandIconPosition="end"
                >
                  <CustomTextArea
                    rows={2}
                    name="description"
                    placeholder={t("description-placeholder")}
                  />
                </CollapseAble>

                <CollapseAble
                  defaultActiveKey={["1"]}
                  expandIconPosition="end"
                  className="pillar"
                  hideExtra={true}
                  headerKey="1"
                  header={
                    <AssessmentQuestionHeading
                      assessmentType={initialValues?.assessmentTypeName}
                      title={
                        (checkInPanelTitle[initialValues?.assessmentTypeName] ||
                          "") + " Onboarding"
                      }
                    />
                  }
                >
                  {checkInCarousals?.map((carousal: any, index: number) => (
                    <OnBoarding
                      onDelete={() => handleCarousalsDelete(index)}
                      key={index}
                      carousal={carousal}
                      onBoardingId={carousal.id}
                      getCarousals={getCarousals}
                    />
                  ))}

                  {/* ADD QUESTION */}
                  <AddElement
                    title={t("add-onboarding-page")}
                    handleAdd={addCheckIn}
                  />
                </CollapseAble>

                <CollapseAble
                  // key={index}
                  className="pillar"
                  hideExtra={true}
                  expandIconPosition="end"
                  defaultActiveKey={["1"]}
                  headerKey="1"
                  header={
                    <AssessmentQuestionHeading
                      assessmentType={initialValues?.assessmentTypeName}
                      title={
                        (checkInPanelTitle[initialValues?.assessmentTypeName] ||
                          "") + " Question"
                      }
                    />
                  }
                >
                  {/* <div>Only Single choice &amp; slider questions here.</div> */}
                  <CheckinQuestion
                    question={
                      checkInQuestions?.[Object.keys(checkInQuestions)[0]]
                    }
                    assessmentSettings={
                      settingsInitialValues?.assessmentSettingId
                    }
                  />
                  {/* {panel.content} */}
                </CollapseAble>
              </div>
            </Form>
          );
        }}
      </Formik>
      {visible && (
        <AssessmentSettings
          setVisible={setVisible}
          assessmentId={assessmentId}
          initialValues={settingsInitialValues}
          visible={visible}
          setRefetchSettings={setRefetchSettings}
        />
      )}
    </DashboardLayout>
  );
};

export default CheckInCreate;
