import { Space } from "antd";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "features/events/css/EventDetail.module.scss";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import CustomButton from "components/custom-button/CustomButton";
import { EditOutlined } from "@ant-design/icons";
import EventDetail from "features/events/event-detail/EventDetail";
import { useTranslation } from "react-i18next";
import { EventState } from "features/events/hooks/useEventViewModel";
import { useState } from "react";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import { PERMS } from "helpers";

interface ActivityDetailProps {}

const EventDetailPage: React.FunctionComponent<ActivityDetailProps> = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const state = (location.state as EventState) ?? {};
  const [eventStatus, setEventStatus] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>();

  const handleStatusChange = (newStatus: boolean, loading: boolean) => {
    setEventStatus(newStatus);
    setLoading(loading);
  };

  return (
    <DashboardLayout openKey={["Activity"]}>
      <Breadcrumbs
        data={[
          {
            label: t("activities"),
          },
          {
            label: t("events"),
          },
          {
            label: `${t("event.details")} - ${t(state?.langCode)}`,
          },
        ]}
      />
      <div className={styles["activity"]}>
        <h6 className="header__row--xtitle">{`${t("event.details")} - ${t(
          state?.langCode,
        )}`}</h6>
        <Space>
          <CustomButton
            className="link-text-btn"
            onClick={() => navigate("/events")}
          >
            Close
          </CustomButton>
          <CheckPermissions permission={PERMS.UPDATE_EVENT}>
            <CustomButton
              loading={loading}
              className="primary-btn"
              icon={<EditOutlined />}
              onClick={() => {
                navigate("/add-event", {
                  state: {
                    id: state?.id,
                    langCode: state?.langCode,
                    addLanguage: state?.addLanguage,
                    editAble: !eventStatus,
                  },
                });
              }}
            >
              <span>{t("event.edit")}</span>
            </CustomButton>
          </CheckPermissions>
        </Space>
      </div>
      <EventDetail onStatusChange={handleStatusChange} />
    </DashboardLayout>
  );
};

export default EventDetailPage;
