import { TypeHandleAddCategory, useAddCategory } from "./useAddCategory";
import {
  TypeGetCategory,
  TypeUpdateCategory,
  useUpdateCategory,
} from "./useUpdateCategory";

import {
  TypeToggleCategory,
  useToggleCategoryActivation,
} from "./useToggleCategoryActivation";

//define the type of useCategory hook
type useCategory = () => {
  isLoading: boolean;
  handleAddCategory: TypeHandleAddCategory;
  handleFetchEditCategoryData: TypeGetCategory;
  handleUpdateCategory: TypeUpdateCategory;
  handleDeActivationCategory: TypeToggleCategory;
};

export const useCategory: useCategory = () => {
  const { handleAddCategory, isLoading } = useAddCategory();
  const { handleDeActivationCategory, isLoading: toggleActivationLoading } =
    useToggleCategoryActivation();

  const {
    handleFetchEditCategoryData,
    handleUpdateCategory,
    isLoading: updateCategoryLoading,
  } = useUpdateCategory();

  return {
    isLoading: isLoading || updateCategoryLoading || toggleActivationLoading,
    handleAddCategory,
    handleFetchEditCategoryData,
    handleUpdateCategory,
    handleDeActivationCategory,
  };
};
