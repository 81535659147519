import { useDeleteHabitMutation } from "graphql/_generated/graphql";

export const useDeleteHabit = () => {
  const [deleteHabitFun, { isLoading: isLoadingDeleteHabit }] =
    useDeleteHabitMutation();

  const handleDeleteHabit = async (id: string) => {
    try {
      const res = await deleteHabitFun({ deleteHabitId: id }).unwrap();
      return res.deleteHabit;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleDeleteHabit,
    isLoadingDeleteHabit,
  };
};
