import { FunctionComponent, useState } from "react";
import AllUsersLeaderboard from "./AllUsersLeaderboard";
import ChallengeDataTable from "./ChallengeDataTable";

interface ChallengeDescriptionProps {
  leaderboards: any;
  challengeId: string;
}

const UsersLeaderboard: FunctionComponent<ChallengeDescriptionProps> = ({
  leaderboards,
  challengeId,
}) => {
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <>
      <ChallengeDataTable
        titleLeft="Users Leaderboard"
        titleRight="See More"
        data={
          leaderboards?.length
            ? leaderboards.map((item: any) => ({
                ...item,
              }))
            : []
        }
        isMedalShow={true}
        setVisible={setVisible}
      />
      {visible ? (
        <AllUsersLeaderboard
          visible={visible}
          setVisible={setVisible}
          challengeId={challengeId}
        />
      ) : null}
    </>
  );
};
export default UsersLeaderboard;
