import { RcFile } from "antd/lib/upload";
import { Base64 } from "js-base64";

export function toBase64(data: any) {
  const stringData = JSON.stringify(data);
  return Base64.encode(stringData);
}

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
