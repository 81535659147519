import ContainerHeading from "features/assessment/questions/ContainerHeading";
import CollapseAble from "features/assessment/questions/collapseables/CollapseAble";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styles from "features/rewards/css/PartnerDetail.module.scss";
import API from "config/api";
import CustomImg from "components/custom-img/CustomImg";
import {
  GetPartnerDetailsQuery,
  PartnerDetails,
} from "graphql/_generated/graphql";
interface PartnerDetailProps {
  data?: GetPartnerDetailsQuery["getPartnerDetails"];
}

const PartnerDetail: React.FunctionComponent<PartnerDetailProps> = ({
  data,
}) => {
  const { t } = useTranslation();
  return (
    <CollapseAble
      defaultActiveKey={["partnerDetail"]}
      headerKey="partnerDetail"
      hideExtra
      expandIconPosition="end"
      header={<ContainerHeading text={t("name-desc")} />}
    >
      <div className={styles["desc-container"]}>
        <CustomImg className={styles["partner-img"]} imgSrc={data?.image} />
        <div className={styles["description"]}>
          <h2 className={styles["title"]}>{data?.name}</h2>
          <h3 className={styles["type"]}>{data?.partnerType.name}</h3>
          <h3 className={styles["type"]}>{data?.regionVisibility}</h3>
          <h3 className={styles["type"]}>{`${t("code")}: ${data?.code}`}</h3>
          <div className={styles["partner-desc"]}>Description</div>
          <p className={styles["detail"]}>{data?.description}</p>
        </div>
      </div>
    </CollapseAble>
  );
};

export default PartnerDetail;
