import {
  useUpdateAssessmentMutation,
  MutationUpdateAssessmentArgs,
} from "graphql/_generated/graphql";

export const useUpdateAssessment = () => {
  const [updateAssessmentFun, { isLoading }] = useUpdateAssessmentMutation();

  const handleUpdateAssessment = async (
    values: MutationUpdateAssessmentArgs,
  ) => {
    try {
      await updateAssessmentFun({
        updateAssessmentId: values.id,
        update: values.update,
      }).unwrap();
    } catch (error) {
      throw error;
    }
  };

  return {
    handleUpdateAssessment,
    isLoading,
  };
};
