import * as React from "react";
import ContainerHeading from "features/assessment/questions/ContainerHeading/ContainerHeading";
import CollapseAble from "features/assessment/questions/collapseables/CollapseAble";
import { useTranslation } from "react-i18next";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import { PERMS } from "helpers";
import styles from "features/events/css/IndividualLeaderBoard.module.scss";
import { List, Pagination } from "antd";
import IndividualLeaderBoardItem from "./IndividualLeaderBoardItem";
import EditIndividualScores from "./EditInvidualScores";
import { useIndividualLeaderboardViewModel } from "features/events/hooks/useIndividualLeaderboardViewModel";
import CustomPagination from "components/custom-pagination/CustomPagination";

interface IndividualLeaderBoardProps {
  eventId: string;
}

const IndividualLeaderBoard: React.FunctionComponent<
  IndividualLeaderBoardProps
> = ({ eventId }) => {
  const { t } = useTranslation();
  const vm = useIndividualLeaderboardViewModel({ eventId });
  return (
    <CollapseAble
      defaultActiveKey={["team-leaderboard "]}
      headerKey="team-leaderboard "
      hideExtra={false}
      expandIconPosition="end"
      header={<ContainerHeading text={t("leaderboard-details")} />}
      extraProps={
        <CheckPermissions permission={PERMS.UPDATE_EVENT_SCORES} /> && {
          handleEdit: vm.onPressEditIndividualScore,
        }
      }
    >
      <div className={styles["leaderboard-container"]}>
        <List
          size="small"
          itemLayout="horizontal"
          loading={vm.isLoading}
          dataSource={vm.leaderboardData?.data}
          renderItem={(item, index) => (
            <IndividualLeaderBoardItem
              itemKey={index + 1}
              id={item.participantId ?? ""}
              userImg={item.avatar?.imgPath ?? item.image ?? ""}
              firstName={item.firstName ?? ""}
              lastName={item.lastName ?? ""}
              companyName={item.company?.companyName ?? ""}
              companyImg={item.company?.image ?? ""}
              score={item.eventScore ?? 0}
            />
          )}
        />
      </div>
      <div className={styles["pagination-margin"]}>
        <CustomPagination
          responsive
          showSizeChanger
          total={vm.leaderboardData?.total}
          onChange={vm.onChangePagination}
          current={vm.currentPage}
        />
      </div>
      {vm.individualVisibility && (
        <EditIndividualScores
          loading={vm.isLoading}
          visible={vm.individualVisibility}
          handleCancel={vm.onPressEditIndividualScore}
          dataSource={vm.leaderboardData}
          onUpdateScoreSubmit={vm.onUpdateScoreSubmit}
          onChangePagination={vm.onChangePagination}
          currentPage={vm.currentPage}
        />
      )}
    </CollapseAble>
  );
};

export default IndividualLeaderBoard;
