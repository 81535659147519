import {
  MutationUpdateSpecialistArgs,
  useLazyGetSpecialistQuery,
  useUpdateSpecialistMutation,
} from "graphql/_generated/graphql";
import {
  convertTranslationArrToObj,
  convertTranslationObjToArr,
} from "helpers";

export const useUpdateSpecialist = () => {
  const [updateSpecialistFun, { isLoading }] = useUpdateSpecialistMutation();
  const [prevEditDataFun, { isLoading: prevDataLoading }] =
    useLazyGetSpecialistQuery();

  //This function is responsible for updating the specialist data
  const handleUpdateSpecialist = async (
    values: MutationUpdateSpecialistArgs,
  ) => {
    try {
      let translations = await convertTranslationArrToObj(
        values.updateObj.translations,
      );

      await updateSpecialistFun({
        updateObj: { ...values.updateObj, translations },
        updateSpecialistId: values.id,
      }).unwrap();
    } catch (error) {
      throw error;
    }
  };

  //this function is responsible for fetching the specific specialist data which specialist id we will be provided
  const handleFetchEditSpecialistData = async (id: string) => {
    try {
      const res = await prevEditDataFun({ getSpecialistId: id }).unwrap();
      const translations = await convertTranslationObjToArr(
        res.getSpecialist?.translations,
      );

      return { ...res?.getSpecialist, translations };
    } catch (error) {
      throw error;
    }
  };

  return {
    isLoading: isLoading || prevDataLoading,
    handleUpdateSpecialist,
    handleFetchEditSpecialistData,
  };
};
