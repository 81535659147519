import {
  ClientDetails,
  // MutationUpdateClientArgs,
  useLazyGetClientDetailQuery,
  useUpdateClientMutation,
} from "graphql/_generated/graphql";

export const useEditCompany = () => {
  // const [updateClientFun, { isLoading }] = useUpdateClientMutation();
  const [prevEditDataFun, { isLoading: prevDataLoading }] =
    useLazyGetClientDetailQuery();

  //This function is responsible for updating the client data
  //   const handleUpdateClient = async (values: MutationUpdateClientArgs) => {
  //     try {
  //       await updateClientFun({
  //         updateObj: values.updateObj,
  //         updateClientId: values.id,
  //       }).unwrap();
  //     } catch (error) {
  //       throw error;
  //     }
  //   };

  //this function is responsible for fetching the specific client data which client id we will be provided
  const handleFetchEditClientData = async (id: string) => {
    try {
      const res = await prevEditDataFun({ getClientDetailId: id }).unwrap();
      return res;
    } catch (error) {
      throw error;
    }
  };

  const [updateClientFun, { isLoading }] = useUpdateClientMutation();

  //this function is responsible for updating the EAP
  const handleUpdateClient = async ({
    values,
    id,
  }: {
    values: ClientDetails;
    id: string | undefined;
  }) => {
    try {
      const {
        avgAgeOfEmployee,
        companyName,
        companySize,
        goal,
        image,
        isEapEnabled,
        subscriptions,
        isActive,
        accessCode,
        isAccessCodeEnabled,
      } = values;

      await updateClientFun({
        updateClientId: id,
        clientUpdate: {
          avgAgeOfEmployee,
          companyName,
          companySize,
          goal,
          image,
          isEapEnabled,
          subscriptions,
          isActive,
          accessCode: accessCode ?? "",
          isAccessCodeEnabled,
        },
      }).unwrap();
    } catch (error) {
      throw error;
    }
  };

  return {
    isLoading,
    handleFetchEditClientData,
    handleUpdateClient,
  };
};
