import { createSlice } from "@reduxjs/toolkit";

interface TypePermissionsSlice {
  permissions: string[];
}

const initialState: TypePermissionsSlice = {
  permissions: [],
};

export const permissionsSlice = createSlice({
  name: "permissionsSlice",
  initialState,
  reducers: {
    permissions: (state, { payload }) => {
      state.permissions = payload;
    },
    removePermissions: (state) => {
      state.permissions = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { permissions, removePermissions } = permissionsSlice.actions;

export default permissionsSlice.reducer;
