import {
  GetChallengeStatsQueryVariables,
  useLazyGetChallengeStatsQuery,
} from "graphql/_generated/graphql";

export const useGetChallengeStats = () => {
  const [challengeStatsFun, { isFetching }] = useLazyGetChallengeStatsQuery();

  const handleGetChallengeStats = async (
    values: GetChallengeStatsQueryVariables,
  ) => {
    try {
      const res = await challengeStatsFun(values).unwrap();

      return res.getChallengeStats;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleGetChallengeStats,
    isLoading: isFetching,
  };
};
