import { useLazyGetPartnerDetailsQuery } from "graphql/_generated/graphql";

export const useGetPartnerDetail = () => {
  const [partnerDetailFun, { isFetching }] = useLazyGetPartnerDetailsQuery();

  const handleGetPartnerDetail = async (id: string) => {
    try {
      const res = await partnerDetailFun({ getPartnerDetailsId: id }).unwrap();

      return res.getPartnerDetails;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleGetPartnerDetail,
    isLoading: isFetching,
  };
};
