import { VALD_PREFIX, VALD_PREFIX_SEPERATOR } from "./validationHelpers";
import { useTranslation } from "react-i18next";
import {
  toUpperCaseFirstWord,
  toUpperCaseWords,
} from "helpers/stringUtils";

export const useErrorTranslation = () => {
  const { t } = useTranslation();

  const getTranslatedFields = (
    //[ {firstname,prefix:_AU}]
    keyPrefixMap: { key: string; prefix?: string }[]
  ) => {
    // {0,firstname}
    const translatedFields: any = {};
    keyPrefixMap.forEach(({ key, prefix }, index) => {
      // firstName
      const translatedField = t(key);
      let caseFixedField = translatedField;

      if (!prefix) {
        caseFixedField = translatedField;
      } else if (VALD_PREFIX.ALL_LOWER_CASE.startsWith(prefix)) {
        caseFixedField = translatedField.toLowerCase();
      } else if (VALD_PREFIX.ALL_UPPER_CASE.startsWith(prefix)) {
        caseFixedField = translatedField.toUpperCase();
      } else if (VALD_PREFIX.UPPER_CASE_WORDS.startsWith(prefix)) {
        caseFixedField = toUpperCaseWords(translatedField);
      } else if (VALD_PREFIX.UPPER_CASE_FIRST_WORD.startsWith(prefix)) {
        caseFixedField = toUpperCaseFirstWord(translatedField);
      }
      // 0,Firstname
      translatedFields[index] = caseFixedField;
    });

    return translatedFields;
  };

  // ['_AU__firstname']
  const getKeyPrefixMap = (keys: string[]) => {
    const map: { key: string; prefix?: string }[] = [];
    keys.forEach((keyWithPrefix) => {
      // [_AU, firstname]
      const keyPieces = keyWithPrefix.split(VALD_PREFIX_SEPERATOR);
      // _AU
      const keyPrefix = keyPieces.shift();
      // firstname
      const key = keyPieces.pop();
      if (key) {
        // {firstname,prefix:_AU}
        map.push({ key, prefix: keyPrefix });
      }
    });
    return map;
  };
  // error:required,_AU__firstname

  const errorT = (errorKey: string) => {
    // [error:required, _AU__firstname]
    const pieces = errorKey ? errorKey.split(",").map((p) => p.trim()) : [];
    // error:required
    const key = pieces.shift() ?? errorKey;
    // {firstname, prefix:_AU}
    const keyPrefixMap = getKeyPrefixMap(pieces);
    // {0, firstname}
    const translatedFields = getTranslatedFields(keyPrefixMap);
    const params = { ...translatedFields };
    // error:required,{0, firstname}
    return t(key, params);
  };
  return { errorT };
};
