import { ErrorMessage } from "formik";
import * as React from "react";
import PhoneInput from "react-phone-input-2";
import { PhoneInputProps } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useErrorTranslation } from "validations/schema/common/useErrorTranslation";
import "./CustomPhoneInput.module.scss";

interface CustomPhoneInputProps extends PhoneInputProps {
  name: string;
  setFieldValue: (name: string, value: string) => void;
  label?: string;
  countryCode?: string;
}

const CustomPhoneInput: React.FunctionComponent<CustomPhoneInputProps> = (
  props,
) => {
  const { label, name, setFieldValue, countryCode } = props;
  const { errorT } = useErrorTranslation();
  const handleOnChange = (value: string) => {
    setFieldValue(name, `+${value}`); // add + prefix
  };

  return (
    <div className="form-control">
      {label && (
        <label className="label" htmlFor={name}>
          {label}
        </label>
      )}
      <PhoneInput
        {...props}
        inputClass="phone-input"
        country={countryCode?.toLocaleLowerCase() ?? "ae"}
        enableAreaCodes={true}
        autoFormat={true}
        prefix="+"
        onChange={handleOnChange}
      />
      <ErrorMessage
        name={name}
        render={(msg) => <div className="text-danger">{errorT(msg)}</div>}
      />
    </div>
  );
};

export default CustomPhoneInput;
