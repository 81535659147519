import {
  MutationUpdateCategoryMappingArgs,
  useUpdateCategoryMappingMutation,
} from "graphql/_generated/graphql";

// Define the type of usnUpdateCategoryMapping hook
export type TypeHandleUpdateCategoryMapping = (
  values: MutationUpdateCategoryMappingArgs,
) => Promise<void>;

export type TypeUseUpdateCategoryMapping = () => {
  isLoading: boolean;
  handleUpdateCategoryMapping: TypeHandleUpdateCategoryMapping;
};

export const useUpdateCategoryMapping: TypeUseUpdateCategoryMapping = () => {
  const [UpdateCategoryMappingFun, { isLoading }] =
    useUpdateCategoryMappingMutation();

  // This function is responsible for updating the mapper data
  const handleUpdateCategoryMapping: TypeHandleUpdateCategoryMapping = async (
    values,
  ) => {
    try {
      await UpdateCategoryMappingFun({
        update: values.update,
        updateCategoryMappingId: values.id,
      }).unwrap();
    } catch (error: unknown) {
      throw error;
    }
  };

  return { isLoading, handleUpdateCategoryMapping };
};
