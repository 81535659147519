import Icon from "@ant-design/icons";
import { Input, Space, Tooltip } from "antd";
import { ErrorMessage, useField } from "formik";
import { useErrorTranslation } from "validations/schema/common/useErrorTranslation";
import styles from "./CustomTextArea.module.scss";
import { getLangDir } from "helpers/getLangDir";

const { TextArea } = Input;
type Props = {
  name: string;
  label?: string;
  placeholder?: string;
  className?: string;
  rows: number;
  disabled?: boolean;
  customProps?: {
    icon?: () => JSX.Element;
  };
  customOnChange?: (e: any) => any;
  oldTextValue?: string;
  isAddLanguage?: boolean;
};
export function CustomTextArea(props: Props) {
  const { errorT } = useErrorTranslation();

  const { name, label, className } = props;
  const [field, meta] = useField(props);

  let textAreaProps = { ...props };
  delete textAreaProps?.customProps;

  const handleChange = (e: any) => {
    field.onChange(e);
    if (props?.customOnChange) {
      props?.customOnChange(e);
    }
  };

  const dir = getLangDir(meta.value);
  const textAreaClassName = className
    ? `${className} ${dir === "rtl" ? "rtl-input" : ""}`
    : dir === "rtl"
    ? "form-text-area rtl-input"
    : "form-text-area";

  return (
    <div className={styles["form-control"]}>
      <Space className="text-area-style--intro">
        {label && (
          <label className="label" htmlFor={name}>
            {label}
          </label>
        )}
        {props.customProps?.icon ? (
          <Tooltip title={props?.oldTextValue}>
            <Icon
              component={props?.customProps?.icon}
              className="viwell-containerHeading--icon"
            />
          </Tooltip>
        ) : null}
      </Space>
      <TextArea
        {...field}
        {...textAreaProps}
        onChange={handleChange}
        rows={6}
        className={textAreaClassName}
        dir={dir}
      />

      <ErrorMessage
        name={name}
        render={(msg) => <div className="text-danger">{errorT(msg)}</div>}
      />
    </div>
  );
}
