import Icon from "@ant-design/icons";
import { Tooltip } from "antd";
import { InfoIcon } from "assets/icons/InfoIcon";
import React from "react";
import "./ContainerHeading.module.scss";

interface HeadingProps {
  text: string;
  className?: string;
  icon?: () => JSX.Element;
  infoText?: string;
}

const ContainerHeading: React.FC<HeadingProps> = (props) => {
  return (
    <h1 className={`viwell-containerHeading ${props?.className ?? ""}`}>
      {props.text}
      {props.icon || props?.infoText ? (
        <Tooltip title={props?.infoText}>
          <Icon
            component={props?.icon ?? InfoIcon}
            className="viwell-containerHeading--icon"
          />
        </Tooltip>
      ) : null}
    </h1>
  );
};
export default ContainerHeading;
