import {
  MutationCompleteClientProfileArgs,
  useCompleteClientProfileMutation,
} from "graphql/_generated/graphql";
import { useNavigate } from "react-router-dom";

export const useCompanyProfile = () => {
  const navigate = useNavigate();
  const [completeProfileFun, { isLoading }] =
    useCompleteClientProfileMutation();

  //this function is responsible for the completion of Client's Profile
  const handleCompleteClientProfile = async (
    values: MutationCompleteClientProfileArgs,
  ) => {
    try {
      let params = { completeProfileDto: { ...values.completeProfileDTO } };

      const res = await completeProfileFun(params).unwrap();
      return res;
    } catch (error: any) {
      throw error;
    }
  };

  return { isLoading, handleCompleteClientProfile };
};
