import styles from "./QuestionHeader.module.scss";
import Icon from "@ant-design/icons";
import * as React from "react";
import { Space, Select } from "antd";
import CustomDivider from "components/custom-divider/CustomDivider";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { questionTypes } from "./questionTypes";
import { CustomSwitch } from "components/form/custom-switch/CustomSwitch";
import ICoreFields from "features/assessment/types/ICoreFields";
import EQuestionHierarchy from "features/assessment/types/EnumQuestionHierarchy";
import EnumBoolean from "features/assessment/types/EnumBoolean";
import { TAlphanumeric } from "types/TAlphaNumeric";
import ICategories from "features/assessment/types/ICategories";
import CustomTreeSelect from "components/form/custom-tree-select/CustomTreeSelect";
import EnumAssessmentTypes from "features/assessment/types/EnumAssessmentTypes";
import { useTranslation } from "react-i18next";

interface QuestionHeaderProps extends ICoreFields {
  tags: any;
  values: any;
  setFieldValue: (key: string, value: any) => void;
  isDisabled?: boolean;
  isAddLanguage?: boolean;
  oldRecord?: any;
  pillarId: TAlphanumeric;
  categories: ICategories;
  assessmentSettings?: any;
  assessmentTypeName: string;
}

const QuestionHeader: React.FunctionComponent<QuestionHeaderProps> = (
  props,
) => {
  const { Option } = Select;
  const { assessmentTypeName } = props;
  const { t } = useTranslation();

  return (
    <>
      <div className={styles["header"]}>
        {/* Serial number temporarily removed */}
        {/* <div className={styles["question-number"]}>{props?.values?.SN}</div> */}

        <div>
          <div className={styles["question-configs"]}>
            {props?.values?.hierarchy ===
              EQuestionHierarchy.PILLAR_QUESTION && (
              <CustomTreeSelect
                allowClear
                showArrow
                treeNodeFilterProp="category"
                treeLine={true && { showLeafIcon: false }}
                value={props?.values?.categoryId}
                disabled={props?.isDisabled || props?.isAddLanguage}
                fieldNames={{
                  label: "name",
                  value: "id",
                }}
                loading={props.categories.loading}
                className="form-select"
                name="categoryId"
                placeholder={t("select-category")}
                treeData={props.categories.data}
                onChange={(values) => props.setFieldValue("categoryId", values)}
              />
            )}

            {assessmentTypeName == EnumAssessmentTypes.VIWELL_ASSESSMENT &&
              props?.values?.hierarchy ===
                EQuestionHierarchy.PILLAR_QUESTION && (
                <CustomSelectField
                  placeholder={t("area")}
                  name="area"
                  options={props?.tags?.data}
                  disabled={
                    props?.tags?.isLoading ||
                    props?.isDisabled ||
                    props?.isAddLanguage
                  }
                />
              )}

            <CustomSelectField
              defaultValue="singleChoice"
              placeholder={t("question-type")}
              name="optionType"
              disabled={
                props?.isDisabled ||
                props?.values?.savedOnServer === EnumBoolean.YES ||
                props?.isAddLanguage
              }
            >
              {questionTypes.map((item, index) => (
                <Option key={index} value={item.value}>
                  <Space align="center">
                    <Icon component={item.icon} />
                    <div>{item.label}</div>
                  </Space>
                </Option>
              ))}
            </CustomSelectField>
            {props?.values.optionType !== "text" &&
            props?.values.optionType !== "rating" ? (
              <Space>
                <CustomSwitch
                  name="isEmojiEnable"
                  className="custom-switch"
                  isDisabled={props?.isDisabled || props?.isAddLanguage}
                  isActive={props?.values?.isEmojiEnable}
                  handleChange={(e: any) =>
                    props.setFieldValue("isEmojiEnable", e)
                  }
                />
                <div className={styles["emojis"]}>{t("emojis")}</div>
              </Space>
            ) : null}
          </div>

          <div className={`${styles["question-title"]}`}>
            <CustomTextField
              disabled={props?.isDisabled}
              name="text"
              placeholder={t("type-question-here")}
              isAddLanguage={props?.isAddLanguage}
              oldTextValue={props?.oldRecord?.text}
            />
            {props?.assessmentSettings?.isScoresEnable ? (
              <CustomTextField
                disabled={props?.isDisabled || props?.isAddLanguage}
                name="score"
                type="number"
                placeholder={t("score")}
              />
            ) : null}
          </div>
        </div>
      </div>
      <CustomDivider />
    </>
  );
};

export default QuestionHeader;
