import { Col, Row, Space } from "antd";
import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { customNotification } from "components/custom-notification/CustomNotification";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { errorFormat } from "helpers";
import { Formik, Form } from "formik";
import { useGetOfferListQuery } from "graphql/_generated/graphql";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetOffer } from "../../features/offers/offer-detail/useGetOffer";
import styles from "./update-offer.module.scss";
import { useUpdateOffer } from "../../features/offers/offer-update/useUpdateOffers";
import { useErrorHandler } from "hooks/useErrorHandler";

interface UpdateOfferProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  editId?: string | null;
  setEditId: React.Dispatch<React.SetStateAction<string | null>>;
}

const UpdateOffer: React.FunctionComponent<UpdateOfferProps> = ({
  setEditId,
  setVisible,
  visible,
  editId,
}) => {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const { handleFetchOffer } = useGetOffer();
  const { handleUpdateOffer, isLoading } = useUpdateOffer();

  const handleCancel = () => {
    setVisible(false);
    setEditId(null);
  };
  const { refetch } = useGetOfferListQuery();
  const [initialValues, setInitialValues] = useState<any>({
    requiredPoints: "",
  });

  useEffect(() => {
    const fetchOffer = async () => {
      if (editId) {
        const { requiredPoints } = await handleFetchOffer(editId);
        setInitialValues({ requiredPoints });
      }
    };

    fetchOffer();
  }, [editId]);

  const submit = async (values: any, resetForm: any) => {
    try {
      await handleUpdateOffer({
        update: values,
        id: editId,
      });
      customNotification("success", t("reward.updated-successfully"));
      resetForm();
      refetch();
      setEditId(null);
      setVisible(false);
    } catch (error) {
      handleError(error as string | object);
    }
  };

  return (
    <CustomModal
      width={600}
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      title={t("update-offer")}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => submit(values, resetForm)}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Row gutter={[24, 0]} align="middle">
              <Col sm={24} md={12}>
                <CustomTextField
                  type="number"
                  name="requiredPoints"
                  placeholder={t("required-points-placeholder")}
                  label={t("required-points-label")}
                />
              </Col>
            </Row>
            <ModalFooter
              loading={isLoading}
              saveText={t("save")}
              onCancel={handleCancel}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default UpdateOffer;
