import customNotification from "components/custom-notification/CustomNotification";
import { FormikHelpers } from "formik";
import {
  Eap,
  EapCreate,
  EapList,
  useGetEapListQuery,
  useLazyGetEapListQuery,
} from "graphql/_generated/graphql";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useEAP } from "../useEAP";

export const useEAPListViewModel = () => {
  //Initializing States
  const { t } = useTranslation();
  const [editId, setEditId] = useState<string | null>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [filtersData, setFiltersData] = useState<any>(null);
  const [columnsData, setColumnsData] = useState<EapList>();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [eap, setEAP] = useState<EapCreate | null>(null);

  //Hooks
  const { isFetching, refetch } = useGetEapListQuery();
  const [getAllEAPsDataFun] = useLazyGetEapListQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const {
    handleAddNewEAP,
    handleUpdateEAP,
    handleActivationEAP,
    isLoading: isEapLoading,
  } = useEAP();

  const { handleError } = useErrorHandler();

  // Default to an empty object if data is null or undefined
  const initialValues: EapCreate = {
    countryCode: undefined,
    number: "",
    isGlobal: false,
    ...(editId ? eap : {}),
  };

  const handleCancel = () => {
    setVisible(false);
    setEditId(null);
  };

  const handleMenu = ({
    key,
    id,
    record,
  }: {
    key: string;
    id: string;
    record: Eap;
  }) => {
    if (key === "edit-eap") {
      setVisible(true);
      setEditId(id);
      setEAP(record);
    }
  };

  const handleAddEap = () => {
    setVisible(true);
  };

  useEffect(() => {
    fetchDataFromApi(getAllEAPsDataFun, setColumnsData, filtersData).catch(
      (e) => handleError(e),
    );
  }, [isFetching]);

  const handleFilterCallback = async (filtersData: any) => {
    await fetchDataFromApi(getAllEAPsDataFun, setColumnsData, filtersData);
    setPageSize(filtersData?.pagination?.offset?.pageSize);
    setCurrentPage(filtersData?.pagination?.offset?.page);
    setFiltersData(filtersData);
  };

  const handleActivationConfirm = async (id: string) => {
    try {
      const isEAPActive = await handleActivationEAP(id);
      refetch();
      customNotification(
        "success",
        isEAPActive
          ? t("eap-activated-successfully")
          : t("eap-deactivated-successfully"),
      );
      refetch();
      return isEAPActive;
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const handleSubmit = async (
    values: EapCreate,
    { resetForm }: FormikHelpers<EapCreate>,
  ): Promise<void> => {
    try {
      if (!editId) {
        await handleAddNewEAP({ create: values });
        customNotification("success", t("eap-added-successfully"));
      } else {
        await handleUpdateEAP({
          updateObj: values,
          id: editId,
        });
        customNotification("success", t("eap-updated-successfully"));
        setEditId(null);
      }
      setVisible(false);
      resetForm();
      refetch();
    } catch (error) {
      handleError(error as string | object);
    }
  };

  return {
    handleFilterCallback,
    eap,
    visible,
    editId,
    isLoading,
    columnsData,
    handleMenu,
    handleActivationConfirm,
    currentPage,
    pageSize,
    handleSubmit,
    handleCancel,
    initialValues,
    isEapLoading,
    handleAddEap,
  };
};
