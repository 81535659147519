import { Popover, Spin } from "antd";
import arrowDownSvg from "assets/images/arrowhead-down.svg";
import arrowUpSvg from "assets/images/arrowhead-up.svg";
import CustomButton from "components/custom-button/CustomButton";
import CustomCard from "components/custom-card/CustomCard";
import { UserFilters } from "graphql/_generated/graphql";
import { useTranslation } from "react-i18next";
import styles from "../../CompanyDashboard.module.scss";
import { formatPastDaysInsight } from "../../utils";
import { InfoTextPopup } from "../InfoTextPopup";
import { NoData } from "../NoData";
import { InfoTextProps } from "../organization-metrices/types";
import { CheckinDetails } from "./CheckinDetails";
import { useCheckinViewModel } from "./hooks/useCheckinViewModel";

interface CheckInProps extends InfoTextProps {
  item: any;
  userFilters: UserFilters;
  visible: boolean;
}

export const Checkin: React.FC<CheckInProps> = ({
  item,
  userFilters,
  visible,
  selectedInfoText,
  setSelectedInfoText,
}) => {
  const {
    handleRefresh,
    isLoading,
    checkinData,
    pastTimeLabel,
    pastTotalSubmissions,
    pastSubmissionsComparison,
    open,
    toggleOpen,
  } = useCheckinViewModel({ visible, item, userFilters });
  const { t } = useTranslation();

  return (
    <>
      <CustomCard bordered={false} className={`${styles["section-card"]}`}>
        <div className={styles["checkin-container"]}>
          <div className={styles["section-item-container"]}>
            <div className={styles["item-header"]}>{item.title}</div>
            <div>
              <InfoTextPopup
                items={item.infoTexts}
                visible={visible || selectedInfoText !== item.type}
                handleSelectedInfoText={(reset?: boolean) =>
                  setSelectedInfoText(reset ? "" : item.type)
                }
              />
            </div>
          </div>
          {checkinData?.totalSubmissions &&
          !checkinData?.isDataConcealed &&
          !isLoading &&
          !item.isFetching ? (
            <>
              <div
                className={styles["checkin-bg"]}
                style={{
                  height: 134,
                  width: 134,
                  position: "absolute",
                  right: -5,
                  bottom: 0,
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${item.bgImage})`,
                }}
              ></div>
              <div className={styles["percentange-container"]}>
                <div className={styles["percentange-text"]}>
                  {isNaN(pastTotalSubmissions)
                    ? "0%"
                    : `${pastTotalSubmissions.toFixed(0)}%`}
                </div>
                <Popover
                  overlayInnerStyle={{
                    borderRadius: 8,
                  }}
                  content={
                    <CheckinDetails
                      {...{
                        title: item.title,
                        message: item.message,
                        open,
                        toggleOpen,
                        checkinData,
                      }}
                    />
                  }
                  trigger="click"
                  open={open}
                  arrowPointAtCenter={true}
                  align={{ offset: [-45, 200] }}
                >
                  <div
                    className={styles["clickable"]}
                    onClick={() => toggleOpen(true)}
                  >
                    <CustomButton className={styles["checkin-btn"]}>
                      {checkinData?.options?.[0]?.title ?? ""}
                    </CustomButton>
                  </div>
                </Popover>
              </div>

              <div className={styles["checkin-insights-container"]}>
                <div className={styles["checkin-insights"]}>
                  {`${checkinData?.totalSubmissions || 0} Check Ins from ${
                    pastTimeLabel?.description
                  }`}
                </div>
                {checkinData?.pastSubmissions > 0 ? (
                  <div className={styles["past-value-container"]}>
                    <div
                      className={`${styles["past-value-comparison"]} ${
                        pastSubmissionsComparison >= 0
                          ? styles["past-value-up"]
                          : styles["past-value-down"]
                      }`}
                    >
                      {`${Math.abs(pastSubmissionsComparison).toFixed(0)}%`}
                    </div>
                    <img
                      className={styles["no-data-img"]}
                      src={
                        pastSubmissionsComparison >= 0
                          ? arrowUpSvg
                          : arrowDownSvg
                      }
                      alt="No Data"
                    />
                  </div>
                ) : null}
              </div>

              <div className={styles["percentange-container"]}>
                <div className={styles["checkin-past-days"]}>
                  {`${pastTimeLabel?.label}: ${formatPastDaysInsight(
                    item.selectedValues,
                  )}`}
                </div>
              </div>
            </>
          ) : (
            <div className={styles["content-nodata-container-height"]}>
              {isLoading || item.isFetching ? (
                <Spin spinning={isLoading || item.isFetching} />
              ) : (
                <NoData
                  handleClick={handleRefresh}
                  btnText={t("refresh")}
                  title={
                    checkinData?.isDataConcealed
                      ? t("data-concealed-for-privacy")
                      : `${t("not-enough-data-for")} ${
                          pastTimeLabel?.description
                        }`
                  }
                />
              )}
            </div>
          )}
        </div>
      </CustomCard>
    </>
  );
};
