import AddOption from "components/custom-button/AddOption";
import DeleteBtn from "components/custom-button/DeleteBtn";
import EmojiPicker from "components/form/custom-emoji-picker/EmojiPicker";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { mappedOptions } from "features/assessment/transformers/AssessmentTransformer";
import { FieldArray } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styles from "./QuestionOptions.module.scss";

interface QuestionOptionsProps {
  values: any;
  tags: any;
  namePrefix?: string;
  addOption: (key: string) => void;
  deleteOption: (key: string, optionId: string) => void;
  isDisabled?: boolean;
  isAddLanguage?: boolean;
  oldRecord?: any;
  isSubQuestion?: boolean;
  assessmentSettings?: any;
}

const QuestionOptions: React.FunctionComponent<QuestionOptionsProps> = (
  props,
) => {
  const { t } = useTranslation();
  const { values } = props;
  const namePrefix = `${props?.namePrefix ? props?.namePrefix + "." : ""}`;

  return (
    <FieldArray
      name="options"
      render={() => (
        <React.Fragment>
          {values?.options
            ?.sort((a: any, b: any) => a?.optionSortOrder - b?.optionSortOrder)
            ?.map((option: any, index: number) => {
              return (
                <div
                  key={index}
                  className={
                    props?.assessmentSettings?.isTagsEnable &&
                    props?.assessmentSettings?.isScoresEnable
                      ? values?.isEmojiEnable
                        ? styles["option-header"]
                        : styles["header"]
                      : values?.isEmojiEnable
                      ? styles["emoji-filter"]
                      : styles["option-filter"]
                  }
                >
                  {values.isEmojiEnable && (
                    <EmojiPicker
                      name={`${namePrefix}options[${index}].emoji`}
                      isDisabled={props?.isDisabled || props?.isAddLanguage}
                      // error={props?.errors?.[index]?.emoji}
                    />
                  )}

                  <CustomTextField
                    name={`${namePrefix}options[${index}].text`}
                    placeholder={t("choice") + " " + (index + 1)}
                    disabled={props?.isDisabled}
                    isAddLanguage={props?.isAddLanguage}
                    oldTextValue={props?.oldRecord?.options[index]?.text}
                  />
                  {props?.assessmentSettings?.isScoresEnable ? (
                    <CustomTextField
                      name={`${namePrefix}options[${index}].score`}
                      placeholder={t("score")}
                      disabled={props?.isDisabled || props?.isAddLanguage}
                      type="number"
                    />
                  ) : null}
                  {props?.assessmentSettings?.isTagsEnable ? (
                    <CustomSelectField
                      maxTagCount="responsive"
                      name={`${namePrefix}options[${index}].tags`}
                      mode="multiple"
                      options={mappedOptions(
                        props?.tags?.data,
                        values?.options[index]?.tags,
                      )}
                      disabled={
                        props?.tags?.isLoading ||
                        props?.isDisabled ||
                        props?.isAddLanguage
                      }
                      placeholder={t("select-tags")}
                    />
                  ) : null}

                  <DeleteBtn
                    disabled={props?.isDisabled || props?.isAddLanguage}
                    onClick={() => props?.deleteOption("options", option.id)}
                  />
                </div>
              );
            })}
          {props?.isAddLanguage ? null : (
            <AddOption
              onClick={() => props?.addOption("options")}
              disabled={props?.isDisabled}
            />
          )}
        </React.Fragment>
      )}
    />
  );
};

export default QuestionOptions;
