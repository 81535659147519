export const LogoutSvg = () => {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 0.75C3 1.1625 2.6625 1.5 2.25 1.5H1.5V10.5H2.25C2.6625 10.5 3 10.8375 3 11.25C3 11.6625 2.6625 12 2.25 12H0.75C0.3375 12 0 11.6625 0 11.25V0.75C0 0.3375 0.3375 0 0.75 0H2.25C2.6625 0 3 0.3375 3 0.75ZM10.5029 2.5686L12.6134 5.5686C12.8009 5.8341 12.7949 6.19035 12.5999 6.44985L10.3499 9.44985C10.2029 9.64635 9.97717 9.74985 9.74918 9.74985C9.59317 9.74985 9.43493 9.7011 9.29992 9.59985C8.96842 9.3516 8.90168 8.88135 9.14993 8.5506L10.5007 6.74985H10.4999H4.49992C4.08592 6.74985 3.74992 6.4146 3.74992 5.99985C3.74992 5.5851 4.08592 5.24985 4.49992 5.24985H10.4999C10.5123 5.24985 10.5238 5.25327 10.5354 5.25671C10.5448 5.25952 10.5543 5.26234 10.5644 5.26335L9.27592 3.4311C9.03743 3.09285 9.11917 2.62485 9.45817 2.38635C9.79642 2.1471 10.2644 2.2296 10.5029 2.5686Z"
        fill="#121212"
      />
    </svg>
  );
};
