import {
  useGetPositionListQuery,
  useRemovePositionMutation,
  useTogglePositionActivationMutation,
} from "graphql/_generated/graphql";

export const useToggleActivationPosition = () => {
  const { refetch } = useGetPositionListQuery();
  const [ActivationDeactivationFun, { isLoading }] =
    useTogglePositionActivationMutation();

  const [removePositionFunc, { isLoading: isDeptLoading }] =
    useRemovePositionMutation();

  //this function is responsible for ActivationDeactivation position
  const handleActivationPosition = async (id: string) => {
    try {
      const res = await ActivationDeactivationFun({
        togglePositionActivationId: id,
      }).unwrap();

      return res.togglePositionActivation;
    } catch (error: any) {
      throw error;
    }
  };

  const handleRemovePosition = async (id: string) => {
    try {
      const res = await removePositionFunc({
        removePositionId: id,
      }).unwrap();

      refetch();
      return res.removePosition;
    } catch (error: any) {
      throw error;
    }
  };

  return {
    isLoading,
    handleActivationPosition,
    handleRemovePosition,
  };
};
