import countriesData from "assets/data/countries.json";
import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import CustomPhoneInput from "components/form/custom-phone-input/CustomPhoneInput";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { Form, Formik, FormikHelpers } from "formik";
import { EapCreate } from "graphql/_generated/graphql";
import React from "react";
import { useTranslation } from "react-i18next";
import { eapSchema } from "validations/schema";

interface AddEAPProps {
  visible: boolean;
  editId?: string | null;
  handleCancel: () => void;
  initialValues: EapCreate;
  handleSubmit: (
    values: EapCreate,
    { resetForm }: FormikHelpers<EapCreate>,
  ) => void;
  isLoading: boolean;
}

const AddEAP: React.FunctionComponent<AddEAPProps> = ({
  visible,
  editId,
  handleCancel,
  initialValues,
  handleSubmit,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <CustomModal
      width={600}
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      title={editId ? t("edit-eap") : t("add-new-eap")}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={eapSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            {!values.countryCode && (
              <CustomSelectField
                label={t("is-global")}
                name="isGlobal"
                placeholder={t("is-global")}
                options={[
                  { value: true, label: t("yes") },
                  { value: false, label: t("no") },
                ]}
              />
            )}
            {!values.isGlobal && (
              <CustomSelectField
                label={t("select-country")}
                className="form-select"
                name="countryCode"
                placeholder={t("select-country")}
                options={countriesData}
                fieldNames={{ label: "name", value: "code" }}
              />
            )}
            <CustomPhoneInput
              value={values.number}
              setFieldValue={setFieldValue}
              name="number"
              label={t("phone-number")}
              placeholder={t("enter-phone-number")}
              countryCode={values.countryCode}
            />
            <ModalFooter
              saveText={t("save-eap")}
              onCancel={handleCancel}
              loading={isLoading}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddEAP;
