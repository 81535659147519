import { MoreOutlined } from "@ant-design/icons";
import { Dropdown, Modal, Space, Switch, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import CustomButton from "components/custom-button/CustomButton";
import CustomMenu from "components/custom-menu/CustomMenu";
import { customNotification } from "components/custom-notification/CustomNotification";
import CustomTable from "components/custom-table/CustomTable";
import CustomPopconfirm from "components/form/custom-pop-confirm/CustomPopConfirm";
import {
  DepartmentList as DepartmentListType,
  useGetDepartmentListQuery,
  useGetDepartmentsConfigQuery,
  useLazyGetDepartmentListQuery,
} from "graphql/_generated/graphql";
import { PERMS, getTranslatedValue } from "helpers";
import { CONFIRM_MODAL_CONFIG } from "helpers/constants";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import { languages } from "i18n/languages";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AddDepartment from "../add-department/AddDepartment";
import { useAddDepartmentViewModel } from "../hooks/useAddDepartmentViewModel";
import { useDepartment } from "../useDepartment";

const DepartmentList = () => {
  //Initializing states
  const [filtersData, setFiltersData] = useState<any>(null);
  const [columnsData, setColumnsData] = useState<DepartmentListType>();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const vm = useAddDepartmentViewModel();

  //Hooks
  const { t } = useTranslation();
  const { isFetching, refetch } = useGetDepartmentListQuery();
  const { refetch: fetchDepartments } = useGetDepartmentsConfigQuery();
  const [getAllDepartmentsDataFun] = useLazyGetDepartmentListQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const { handleDeActivationDepartment, handleRemoveDepartment } =
    useDepartment();
  const { handleError } = useErrorHandler();

  const handleMenu = ({ key, id, record }: any) => {
    if (key === "edit-position") {
      vm.handleEditId(id);
    } else if (key === "delete-position") {
      Modal.confirm({
        ...CONFIRM_MODAL_CONFIG,
        title: t("confirm-delete"),
        content: (
          <>
            <div>{record?.name}</div>
          </>
        ),
        onOk: async () => {
          try {
            await handleRemoveDepartment(id);
            customNotification("success", "Department removed successfully");
            refetch();
            fetchDepartments();
          } catch (error) {
            handleError(error as string | object);
          }
        },
      });
    }
  };

  useEffect(() => {
    fetchDataFromApi(
      getAllDepartmentsDataFun,
      setColumnsData,
      filtersData,
    ).catch((e) => handleError(e));
  }, [isFetching]);

  const columns: ColumnsType<DepartmentListType["data"][0]> = [
    {
      title: t("id"),
      key: "id",
      sorter: {
        multiple: 1,
      },
      render: (_text: any, _record: any, index: number) =>
        (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      sorter: {
        multiple: 1,
      },
      render: (name: string, item: any) => (
        <Space>
          <div className="cut-text">{getTranslatedValue(item, "name")}</div>
        </Space>
      ),
    },
    {
      title: t("company"),
      dataIndex: "company",
      key: "company",
      render: (_text, record, _index) => (
        <span>{record.client.companyName}</span>
      ),
    },
    {
      title: t("languages"),
      dataIndex: "translations",
      key: "translations",
      render: (_text, record, _index) => (
        <Space>
          English
          {record.translations &&
            Object.keys(record.translations).map((key, index) => {
              return languages.filter((item) => item.value === key)[0].label;
            })}
        </Space>
      ),
    },
    {
      title: t("created-at"),
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: {
        multiple: 1,
      },
      render: (createdAt: string) =>
        moment(createdAt).format("MMMM Do YYYY, h:mm:ss a"),
    },
    {
      title: t("updated-at"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: {
        multiple: 1,
      },
      render: (updatedAt: string) =>
        moment(updatedAt).format("MMMM Do YYYY, h:mm:ss a"),
    },
    {
      align: "center" as "center",
      title: t("status"),
      dataIndex: "isActive",
      key: "isActive",
      render: (isActive: boolean) =>
        isActive ? (
          <Tag className="active-tag">{t("active")}</Tag>
        ) : (
          <Tag className="de-active-tag">{t("deactivated")}</Tag>
        ),
    },
    {
      align: "center" as "center",
      title: t("activation"),
      dataIndex: "id",
      key: "id",
      render: (id: string, { isActive }) => (
        <>
          <CheckPermissions permission={PERMS.TOGGLE_DEPARTMENT_ACTIVATION}>
            <CustomPopconfirm
              onConfirm={async () => {
                try {
                  const isDepartmentActive = await handleDeActivationDepartment(
                    id,
                  );
                  customNotification(
                    "success",
                    isDepartmentActive
                      ? t("department-activation-successfully")
                      : t("department-deactivate-successfully"),
                  );
                  refetch();
                  fetchDepartments();
                } catch (error) {
                  handleError(error as string | object);
                }
              }}
              title={
                isActive
                  ? t("confirm-deactivate-department")
                  : t("confirm-activate-department")
              }
            >
              <Switch
                className="custom-switch"
                defaultChecked
                checked={isActive}
              />
            </CustomPopconfirm>
          </CheckPermissions>
        </>
      ),
    },
    {
      align: "center" as "center",
      title: "",
      dataIndex: "id",
      key: "id",
      render: (id: string, record) => (
        <>
          <Dropdown
            overlay={
              <CheckPermissions permission={PERMS.UPDATE_DEPARTMENT}>
                <CustomMenu
                  items={[
                    { label: t("edit-department"), key: "edit-position" },
                    { label: t("delete-department"), key: "delete-position" },
                  ]}
                  onClick={({ domEvent, key, keyPath }) =>
                    handleMenu({ domEvent, key, keyPath, id, record })
                  }
                />
              </CheckPermissions>
            }
          >
            <CustomButton type="link" className="link-btn">
              <MoreOutlined />
            </CustomButton>
          </Dropdown>
        </>
      ),
    },
  ];

  return (
    <>
      <CustomTable
        filters={{
          handleCallback: async (filtersData: any) => {
            await fetchDataFromApi(
              getAllDepartmentsDataFun,
              setColumnsData,
              filtersData,
            );
            setPageSize(filtersData?.pagination?.offset?.pageSize);
            setCurrentPage(filtersData?.pagination?.offset?.page);
            setFiltersData(filtersData);
          },
          date: [
            {
              applyAs: "and",
              key: "createdAt",
              comparison: "Range",
            },
          ],
          status: {
            applyAs: "and",
            key: "isActive",
            comparison: "Equal",
          },
        }}
        totalItems={columnsData?.metadata.total}
        loading={isLoading}
        columns={columns}
        permission={PERMS.ADD_NEW_DEPARTMENT}
        onClick={() => vm.setVisible(true)}
        headerTitle={t("departments-title")}
        btnText={t("add-department")}
        dataSource={columnsData?.data}
        rowKey="id"
      />
      <AddDepartment
        visible={Boolean(vm.visible)}
        closeModal={vm.closeModal}
        isLoading={vm.isLoading}
        initialValues={vm.initialValues}
        handleSubmit={vm.handleSubmit}
        isClient={vm.isClient}
        redirectToDepartments={vm.redirectToDepartments}
      />
    </>
  );
};

export default DepartmentList;
