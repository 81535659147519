export const UserSvg = () => {
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.33366 3.83333C9.33366 5.67167 7.83866 7.16667 6.00033 7.16667C4.16199 7.16667 2.66699 5.67167 2.66699 3.83333C2.66699 1.995 4.16199 0.5 6.00033 0.5C7.83866 0.5 9.33366 1.995 9.33366 3.83333ZM11.8337 14.6667C11.8337 15.1267 11.4612 15.5 11.0003 15.5H1.00033C0.539492 15.5 0.166992 15.1267 0.166992 14.6667C0.166992 11.45 2.78449 8.83333 6.00033 8.83333C9.21616 8.83333 11.8337 11.45 11.8337 14.6667Z"
        fill="#303030"
      />
    </svg>
  );
};
