import {
  useGetPartnerListQuery,
  useTogglePartnerActivationMutation,
} from "graphql/_generated/graphql";

export const useTogglePartnerActivation = () => {
  const [togglePartnerFun, { isLoading }] =
    useTogglePartnerActivationMutation();

  const { refetch } = useGetPartnerListQuery();

  const handleTogglePartnerActivation = async (id: string) => {
    try {
      const res = await togglePartnerFun({
        togglePartnerActivationId: id,
      }).unwrap();
      refetch();
      return res.togglePartnerActivation;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleTogglePartnerActivation,
    isLoading,
  };
};
