let currentLang = localStorage.i18nextLng;

export const getTranslatedValue = (item: any, attr: string) => {
  if (!item?.translations || currentLang === "en") {
    return item[attr];
  } else {
    if (item?.translations[currentLang]) {
      return item?.translations[currentLang][attr];
    } else {
      return item[attr];
    }
  }
};

// export const getTranslatedValue = (
//   translations: any,
//   attributeName: string,
//   attribute: string
// ) => {
//   if (!translations || currentLang === "en") {
//     return attributeName;
//   } else {
//     return translations[currentLang][attribute];
//   }
// };
