import {
  convertTranslationArrToObj,
  convertTranslationObjToArr,
} from "helpers";
import {
  UpdateRoleMutationVariables,
  useUpdateRoleMutation,
  useLazyGetRoleQuery,
  GetRoleQueryVariables,
} from "graphql/_generated/graphql";





export const useUpdateRole = () => {
  
  const [updateRoleFun, { isLoading }] = useUpdateRoleMutation();
  const [prevEditDataFun, { isLoading: prevDataLoading }] =
    useLazyGetRoleQuery();

  //this function is responsible for fetching the specific role data which role id we will be provided
  const handleFetchPreviousRoleData = async (id: string) => {
    const res = await prevEditDataFun({ getRoleId: id });

    const translations = await convertTranslationObjToArr(
      res.data?.getRole.translations
    );

    return { ...res.data?.getRole, translations };
  };

  //this function is responsible for adding the new role
  const handleUpdateRole = async (values: UpdateRoleMutationVariables) => {
    try {
      let translations = await convertTranslationArrToObj(
        values.updateObj.translations
      );

      await updateRoleFun({
        updateObj: { ...values.updateObj, translations },
        updateRoleId: values.updateRoleId,
      }).unwrap();
    } catch (error: any) {
      throw error;
    }
  };

  return {
    isLoading: isLoading || prevDataLoading,
    handleFetchPreviousRoleData,
    handleUpdateRole,
  };
};
