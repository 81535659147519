import * as React from "react";
import { Collapse, CollapseProps, Space } from "antd";
import Icon from "@ant-design/icons";
import "./CustomCollapse.module.scss";
import { ArrowIcon } from "assets/icons/ArrowIcon";

interface CustomCollapseProps extends CollapseProps {
  children: React.ReactNode;
}

const CustomCollapse: React.FunctionComponent<CustomCollapseProps> = (
  props,
) => {
  return (
    <Collapse
      bordered={props.bordered ?? false}
      className={props.className ? props.className : "viwell-collapse"}
      expandIcon={({ isActive }) => {
        return <Icon component={ArrowIcon} rotate={isActive ? 90 : 180} />;
      }}
      {...props}
    >
      {props.children}
    </Collapse>
  );
};

export default CustomCollapse;
