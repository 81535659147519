export const ReportSvg = () => {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.26134 5.66699C8.93301 5.66699 8.66634 5.34949 8.66634 4.95866V2.31533L11.7847 5.66699H9.26134ZM9.49967 14.0003H4.49967C4.03967 14.0003 3.66634 13.627 3.66634 13.167C3.66634 12.707 4.03967 12.3337 4.49967 12.3337H9.49967C9.96051 12.3337 10.333 12.707 10.333 13.167C10.333 13.627 9.96051 14.0003 9.49967 14.0003ZM4.49967 9.00033H6.99967C7.45967 9.00033 7.83301 9.37366 7.83301 9.83366C7.83301 10.2937 7.45967 10.667 6.99967 10.667H4.49967C4.03967 10.667 3.66634 10.2937 3.66634 9.83366C3.66634 9.37366 4.03967 9.00033 4.49967 9.00033ZM13.4497 5.10699L9.74634 0.940326C9.58884 0.766159 9.36467 0.666992 9.12967 0.666992H2.46301C1.28884 0.666992 0.333008 1.60199 0.333008 2.75033V15.2503C0.333008 16.3987 1.28884 17.3337 2.46301 17.3337H11.5363C12.7113 17.3337 13.6663 16.3987 13.6663 15.2503V5.66699C13.6663 5.45949 13.5888 5.26033 13.4497 5.10699Z"
        fill="#303030"
      />
    </svg>
  );
};
