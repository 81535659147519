export const BronzeMedalSvg = () => {
  return (
    <svg
      width="29"
      height="33"
      viewBox="0 0 29 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.7783 32.0018L21.5767 11.3828L15.3843 12.7574L19.0997 30.0381L26.7783 32.0018Z"
        fill="url(#paint0_linear_1556_775)"
      />
      <path
        d="M2.00876 32.0018L7.2104 11.3828L13.4028 12.7574L9.68737 30.0381L2.00876 32.0018Z"
        fill="url(#paint1_linear_1556_775)"
      />
      <path
        d="M14.3936 28.8593L19.0998 30.0375L15.3843 12.7568L14.3936 12.9532V28.8593Z"
        fill="url(#paint2_linear_1556_775)"
      />
      <path
        d="M14.3936 28.8593L9.68731 30.0375L13.4028 12.7568L14.3936 12.9532V28.8593Z"
        fill="url(#paint3_linear_1556_775)"
      />
      <path
        d="M28.7864 12.3858L21.5898 24.7707H7.19661L0 12.3858L7.19661 0.000976562L21.5898 0.000977188L28.7864 12.3858Z"
        fill="#F6E43D"
      />
      <path
        d="M26.778 12.386L20.5856 23.0973H8.20073L2.0083 12.386L8.20073 1.6748L20.5856 1.67481L26.778 12.386Z"
        fill="#CBBF53"
      />
      <path
        d="M25.4843 12.3858L19.9405 21.8428H8.84627L3.30248 12.3858L8.84627 2.92871L19.9405 2.92871L25.4843 12.3858Z"
        fill="#772019"
        stroke="#610D03"
        stroke-width="0.5"
      />
      <path
        d="M20.5859 1.67461L21.5901 0.000976562L28.7867 12.3858H26.7784L20.5859 1.67461Z"
        fill="#E7905A"
      />
      <path
        d="M8.20068 1.67461L7.19651 0.000976562L-0.000102043 12.3858H2.00825L8.20068 1.67461Z"
        fill="#F1AF7A"
      />
      <path
        d="M20.5859 23.0979L21.5901 24.7715L28.7867 12.3866H26.7784L20.5859 23.0979Z"
        fill="#D56E41"
      />
      <path
        d="M8.20068 23.0979L7.19651 24.7715L-0.000102043 12.3866H2.00825L8.20068 23.0979Z"
        fill="#E79058"
      />
      <path
        d="M8.20096 23.0967H20.5858L21.59 24.7703H7.19678L8.20096 23.0967Z"
        fill="#D57C43"
      />
      <path
        d="M8.20047 1.6748H20.5853L21.5895 0.00117517H7.19629L8.20047 1.6748Z"
        fill="#F3B590"
      />
      <path
        d="M20.0835 2.67898L20.5855 1.6748H8.20068L8.70277 2.67898H20.0835Z"
        fill="#C67144"
      />
      <path
        d="M20.0835 22.0925L20.5855 23.0967H8.20068L8.70277 22.0925H20.0835Z"
        fill="#F4AD74"
      />
      <path
        d="M20.0835 2.67898L20.5856 1.6748L26.778 12.386H25.7738L20.0835 2.67898Z"
        fill="#D27E53"
      />
      <path
        d="M8.70312 2.67898L8.20104 1.6748L2.00861 12.386H3.01278L8.70312 2.67898Z"
        fill="#E58F5E"
      />
      <path
        d="M20.0835 22.0925L20.5856 23.0967L26.778 12.3854H25.7738L20.0835 22.0925Z"
        fill="#E58B56"
      />
      <path
        d="M8.70264 22.0925L8.20055 23.0967L2.00812 12.3854H3.0123L8.70264 22.0925Z"
        fill="#FFAC7D"
      />
      <path
        d="M14.6166 5.5796L14.393 5.13239L14.1694 5.5796L12.2188 9.48068L7.99519 10.1305L7.47403 10.2107L7.86596 10.5634L11.1037 13.4774L10.1327 17.6853L10.0076 18.2275L10.496 17.961L14.393 15.8347L18.29 17.961L18.7784 18.2275L18.6533 17.6853L17.6822 13.4774L20.92 10.5634L21.312 10.2107L20.7908 10.1305L16.5671 9.48068L14.6166 5.5796Z"
        fill="#A74D1A"
        stroke="#610D03"
        stroke-width="0.5"
      />
      <path
        d="M14.3931 12.0512V5.69141L16.4014 9.70812L14.3931 12.0512Z"
        fill="#D26D37"
      />
      <path
        d="M14.3931 12.0513L12.3848 9.70818L14.3931 5.69141V12.0513Z"
        fill="#F3A869"
      />
      <path
        d="M20.7529 10.3775L16.4014 9.70801L14.3931 12.0511L20.7529 10.3775Z"
        fill="#FDB173"
      />
      <path
        d="M14.3926 12.0506L17.4051 13.3895L20.7524 10.377L14.3926 12.0506Z"
        fill="#C36A40"
      />
      <path
        d="M14.3926 12.0518L17.4051 13.3907L18.4093 17.7421L14.3926 12.0518Z"
        fill="#ED8A50"
      />
      <path
        d="M14.3926 15.5664V12.0518L18.4093 17.7421L14.3926 15.5664Z"
        fill="#D06C39"
      />
      <path
        d="M10.3765 17.7421L14.3932 12.0518V15.5664L10.3765 17.7421Z"
        fill="#D26F3D"
      />
      <path
        d="M11.3806 13.3907L14.3932 12.0518L10.3765 17.7421L11.3806 13.3907Z"
        fill="#EF8D56"
      />
      <path
        d="M8.0332 10.377L14.393 12.0506L11.3805 13.3895L8.0332 10.377Z"
        fill="#C56635"
      />
      <path
        d="M8.0332 10.3775L12.3846 9.70801L14.393 12.0511L8.0332 10.3775Z"
        fill="#FFAF6F"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1556_775"
          x1="21.0813"
          y1="11.3828"
          x2="21.0813"
          y2="32.0018"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C53D45" />
          <stop offset="1" stop-color="#F34156" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1556_775"
          x1="7.7058"
          y1="11.3828"
          x2="7.7058"
          y2="32.0018"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C53D45" />
          <stop offset="1" stop-color="#F34156" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1556_775"
          x1="16.7467"
          y1="12.7568"
          x2="16.7467"
          y2="30.0375"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB828B" />
          <stop offset="1" stop-color="#F4949B" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1556_775"
          x1="12.0404"
          y1="12.7568"
          x2="12.0404"
          y2="30.0375"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB828B" />
          <stop offset="1" stop-color="#F4949B" />
        </linearGradient>
      </defs>
    </svg>
  );
};
