import Icon from "@ant-design/icons";
import { ErrorMessage, Form, Formik } from "formik";
import React, { useState } from "react";
import { errorFormat, getLanguage } from "helpers";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { selectAssessmentTypeSchema } from "validations/schema";
import CustomCard from "components/custom-card/CustomCard";

import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { customNotification } from "components/custom-notification/CustomNotification";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";

import "features/assessment/select-types/SelectAssessmentTypes.module.scss";
import { Divider, Space } from "antd";
import ContainerHeading from "features/assessment/questions/ContainerHeading";
import { useErrorTranslation } from "validations/schema/common/useErrorTranslation";
import {
  Language,
  useGetAssessmentTypesQuery,
} from "graphql/_generated/graphql";
import { getTypeIcon } from "helpers/getTypeIcon";
import { useLanguages } from "hooks";
import { useCreateAssessment } from "../hooks/useCreateAssessment";
import AssessmentRoutes from "routes/RouteCollections/AssessmentRoutes";
import AssessmentCreateRouteState from "routes/RouteStates/AssessmentCreateRouteState";
import { useErrorHandler } from "hooks/useErrorHandler";

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const SelectAssessmentTypes = ({ visible, setVisible }: Props) => {
  const { t } = useTranslation();
  const { errorT } = useErrorTranslation();
  const navigate = useNavigate();
  const { languages } = useLanguages();
  const { handleError } = useErrorHandler();

  const { data: assessmentTypes } = useGetAssessmentTypesQuery();
  const { handleCreateAssessment, isLoadingCreateAssessment } =
    useCreateAssessment();

  const handleCancel = () => {
    setVisible(false);
  };

  const initialValues = {
    assessmentTypeId: "",
    language: "",
    description: "Assessment Description",
    name: "",
  };

  return (
    <CustomModal
      width={600}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      title={<ContainerHeading text={t("assessment.add-new-assessment")} />}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={selectAssessmentTypeSchema}
        onSubmit={async (values, { resetForm }) => {
          try {
            const res = await handleCreateAssessment({
              create: { ...values, language: getLanguage(values.language) },
            });

            resetForm();

            let assessmentRoute = AssessmentRoutes.AssessmentCreateRoute.path;

            if (values.name?.includes("Check-In")) {
              assessmentRoute =
                AssessmentRoutes.AssessmentCreateCheckInRoute.path;
            }

            navigate(assessmentRoute, {
              state: AssessmentCreateRouteState({
                assessmentId: res,
                assessmentLanguage: values?.language,
                assessmentTypeName: values?.name,
                assessmentTypeId: values?.assessmentTypeId,
              }),
            });
          } catch (error) {
            handleError(error as string | object);
          }
        }}
      >
        {({ values, setFieldValue, errors }) => {
          return (
            <Form className="assessmentType__form">
              {/* ASSESSMENT TYPE SELECTION */}
              {assessmentTypes?.getAssessmentTypes.map((item, index) => {
                return (
                  <AssessmentTypeCard
                    key={index}
                    name={item?.name}
                    id={item.id}
                    isActive={values.assessmentTypeId === item?.id}
                    setFieldValue={setFieldValue}
                  />
                );
              })}

              <ErrorMessage
                name={"assessmentTypeId"}
                render={(msg) => {
                  return <div className="text-danger">{errorT(msg)}</div>;
                }}
              />

              {/* LANGUAGE SELECTION */}
              <Divider />
              <Space className="assessmentType__form__language">
                <ContainerHeading text="Assessment Language" />
                <CustomSelectField
                  name="language"
                  placeholder="Select assessment language"
                  options={languages}
                />
              </Space>

              <ModalFooter
                className="assessmentType__form__footer"
                saveText="Add Assessment"
                loading={isLoadingCreateAssessment}
                onCancel={handleCancel}
              />
            </Form>
          );
        }}
      </Formik>
    </CustomModal>
  );
};

export default SelectAssessmentTypes;

export interface AssessmentTypeCardProps {
  name: string;
  id: string;
  isActive: boolean;
  setFieldValue: (name: string, value: string) => void;
}

const AssessmentTypeCard: React.FC<AssessmentTypeCardProps> = (props) => {
  const typeIcon = getTypeIcon(props.name);
  return (
    <CustomCard
      className={`assessmentType__form__card ${
        props?.isActive ? "active" : ""
      }`}
      onClick={() => {
        props.setFieldValue("assessmentTypeId", props.id);
        props.setFieldValue("name", props.name);
      }}
    >
      <Icon component={typeIcon} className="assessmentType__form__card--icon" />
      <ContainerHeading text={props?.name} />
    </CustomCard>
  );
};
