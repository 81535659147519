import { Switch } from "formik-antd";
import styles from "./CustomSwitch.module.scss";

type Props = {
  name: string;
  label?: string;
  className?: string;
  onChange?: (arg0: boolean) => void;
  disabled?: boolean;
};

export function CustomSwitch(props: Props) {
  const { name, label, className } = props;
  return (
    <div>
      {label && (
        <span>
          <label htmlFor={name}>
            <p className={styles["label"]}>{label}</p>
          </label>
        </span>
      )}

      <Switch {...props} className={className ? className : "from-input"} />
    </div>
  );
}
