import { useDeleteCategoryMutation } from "graphql/_generated/graphql";

type TypeHandleDeleteCategory = (id: string) => Promise<any>;

type TypeUseDeleteCategory = () => {
  isLoading: boolean;
  handleDeleteCategory: TypeHandleDeleteCategory;
};

export const useDeleteCategory: TypeUseDeleteCategory = () => {
  const [deleteFun, { isLoading }] = useDeleteCategoryMutation();

  const handleDeleteCategory: TypeHandleDeleteCategory = async (id) => {
    try {
      const res = await deleteFun({ deleteCategoryId: id }).unwrap();
      return res;
    } catch (error) {
      throw error;
    }
  };

  return {
    isLoading,
    handleDeleteCategory,
  };
};
