import { MoreOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, Menu, Modal, Space, Switch, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import CustomButton from "components/custom-button/CustomButton";
import CustomMenu from "components/custom-menu/CustomMenu";
import { customNotification } from "components/custom-notification/CustomNotification";
import CustomTable from "components/custom-table/CustomTable";
import CustomPopconfirm from "components/form/custom-pop-confirm/CustomPopConfirm";
import { CONFIRM_MODAL_CONFIG } from "helpers/constants";
import { errorFormat, PERMS } from "helpers";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import { useSpecialistConfigList } from "hooks/useSpecialistConfig";
import { store } from "store/store";
import {
  api,
  useGetSpecialistListQuery,
  useLazyGetSpecialistListQuery,
} from "graphql/_generated/graphql";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AddSpecialist from "./AddSpecialist";
import { useSpecialist } from "./hooks/useSpecialist";
import { useErrorHandler } from "hooks/useErrorHandler";
interface SpecialistListProps {}

const SpecialistList: React.FunctionComponent<SpecialistListProps> = () => {
  const { t } = useTranslation();
  const [filtersData, setFiltersData] = useState<any>(null);
  const [columnsData, setColumnsData] = useState<any>();
  const [editId, setEditId] = useState<string | null>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const { isFetching, refetch } = useGetSpecialistListQuery();
  const { refetch: specialistConfigFetch } = useSpecialistConfigList();
  const [getAllSpecialistDataFun] = useLazyGetSpecialistListQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const { handleActivationSpecialist, handleRemoveSpecialist } =
    useSpecialist();
  const { handleError } = useErrorHandler();

  const handleMenu = ({ key, id, record }: any) => {
    if (key === "edit-specialist") {
      handleEdit(id);
    } else if (key === "delete-specialist") {
      Modal.confirm({
        ...CONFIRM_MODAL_CONFIG,
        content: (
          <>
            <Space>
              <CustomAvatar imgSrc={record?.image} />
              {record?.firstName}
              {record?.lastName}
            </Space>
          </>
        ),
        onOk: async () => {
          try {
            await handleRemoveSpecialist(id);
            store.dispatch(api.util.resetApiState());
            customNotification("success", "Specialist removed successfully");
            refetch();
          } catch (error) {
            handleError(error as string | object);
          }
        },
      });
    }
  };

  const handleEdit = (id: string) => {
    setEditId(id);
    setVisible(true);
  };
  useEffect(() => {
    fetchDataFromApi(
      getAllSpecialistDataFun,
      setColumnsData,
      filtersData,
    ).catch((e) => handleError(e));
  }, [isFetching]);

  const columns: ColumnsType<any> = [
    {
      title: `${t("id")}`,
      key: "id",
      dataIndex: "id",
      sorter: {
        multiple: 1,
      },
      render: (_text: any, _record: any, index: number) =>
        (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: `${t("name")}`,
      key: "name",
      dataIndex: "firstName",
      sorter: {
        multiple: 1,
      },
      render: (_text: any, record: any) => (
        <Space>
          <CustomAvatar imgSrc={record?.image} />
          {record?.firstName}
          {record?.lastName}
        </Space>
      ),
    },
    {
      title: `Specialty`,
      key: "specialty",
      dataIndex: "specialty",
      render: (specialty) => <div className="cut-text">{specialty}</div>,
    },
    {
      title: `${t("created-at")}`,
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: {
        multiple: 1,
      },
      render: (createdAt: string) =>
        moment(createdAt).format("MMMM Do YYYY, h:mm:ss a"),
    },
    {
      title: `${t("updated-at")}`,
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: {
        multiple: 1,
      },
      render: (updatedAt: string) =>
        moment(updatedAt).format("MMMM Do YYYY, h:mm:ss a"),
    },
    {
      title: `${t("status")}`,
      dataIndex: "isActive",
      key: "isActive",
      align: "center" as "center",
      render: (isActive: boolean) =>
        isActive ? (
          <Tag className="active-tag">Active</Tag>
        ) : (
          <Tag className="de-active-tag">Deactivated</Tag>
        ),
    },
    {
      title: `${t("activation")}`,
      dataIndex: "id",
      key: "id",
      align: "center" as "center",
      render: (id: string, { isActive }: any) => (
        <CheckPermissions permission={PERMS.TOGGLE_POSITION_ACTIVATION}>
          <CustomPopconfirm
            onConfirm={async () => {
              try {
                const isSpecialistActive = await handleActivationSpecialist(id);

                customNotification(
                  "success",
                  isSpecialistActive
                    ? "Specialist Activate successfully"
                    : "Specialist Deactivate successfully",
                );
                refetch();
                specialistConfigFetch();
              } catch (error) {
                handleError(error as string | object);
              }
            }}
            title={
              isActive
                ? "Are you sure you want to deactivate the Specialist"
                : "Are you sure you want to activate the Specialist"
            }
          >
            <Switch
              className="custom-switch"
              defaultChecked
              checked={isActive}
            />
          </CustomPopconfirm>
        </CheckPermissions>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      align: "center" as "center",
      render: (id: string, record) => (
        <Dropdown
          overlay={
            <CheckPermissions permission={PERMS.UPDATE_CATEGORY}>
              <CustomMenu
                items={[
                  { label: "Edit Specialist", key: "edit-specialist" },
                  { label: "Delete Specialist", key: "delete-specialist" },
                ]}
                onClick={({ domEvent, key, keyPath }) =>
                  handleMenu({ domEvent, key, keyPath, id, record })
                }
              />
            </CheckPermissions>
          }
        >
          <CustomButton type="link" className="link-btn">
            <MoreOutlined />
          </CustomButton>
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      <CustomTable
        filters={{
          handleCallback: async (filtersData: any) => {
            await fetchDataFromApi(
              getAllSpecialistDataFun,
              setColumnsData,
              filtersData,
            );
            setPageSize(filtersData?.pagination?.offset?.pageSize);
            setCurrentPage(filtersData?.pagination?.offset?.page);
            setFiltersData(filtersData);
          },
          date: [
            {
              applyAs: "and",
              key: "createdAt",
              comparison: "Range",
            },
          ],
          status: {
            applyAs: "and",
            key: "isActive",
            comparison: "Equal",
          },
        }}
        rowKey="id"
        loading={isLoading}
        columns={columns}
        totalItems={columnsData?.metadata?.total}
        permission={PERMS.ADD_NEW_SPECIALIST}
        onClick={() => setVisible(true)}
        headerTitle={t("specialists-title")}
        btnText={t("add-new-specialist")}
        dataSource={columnsData?.data}
      />
      <AddSpecialist
        visible={visible}
        setVisible={setVisible}
        editId={editId}
        setEditId={setEditId}
      />
    </>
  );
};

export default SpecialistList;
