import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { customNotification } from "components/custom-notification/CustomNotification";
import { forgotEmail } from "features/auth/authSlice";
import { useForgotPassword } from "features/auth/hooks/useForgotPassword";
import { useErrorHandler } from "hooks/useErrorHandler";
import { ForgotPassword } from "graphql/_generated/graphql";

interface ForgotPasswordSubmitProps {
  isLoading: boolean;
  onSubmit: (values: ForgotPassword) => Promise<void>;
}

const useForgotPasswordSubmit = (): ForgotPasswordSubmitProps => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const { isLoading, handleForgotPassword } = useForgotPassword();

  const onSubmit = async (values: ForgotPassword) => {
    try {
      await handleForgotPassword({ body: values });

      // Dispatch to store
      dispatch(
        forgotEmail({
          email: values.email as string,
        }),
      );

      customNotification("success", t("reset-password-link-success"));
      navigate("/check-email");
    } catch (error) {
      handleError(error as string | object);
    }
  };

  return {
    isLoading,
    onSubmit,
  };
};

export default useForgotPasswordSubmit;
