import {
  convertTranslationArrToObj,
  convertTranslationObjToArr,
} from "helpers";

import {
  MutationUpdateTagArgs,
  useGetConfigTagsQuery,
  useLazyGetTagQuery,
  useUpdateTagMutation,
} from "graphql/_generated/graphql";

export type TypeHandleUpdateFun = (
  values: MutationUpdateTagArgs
) => Promise<void>;

export type TypeHandleFetchPreviousDepartmentData = (id: string) => any;

//define the type of useCategory hook
type TypeUseUpdateTag = () => {
  isLoading: boolean;

  handleUpdateTag: TypeHandleUpdateFun;

  handleFetchEditTagData: TypeHandleFetchPreviousDepartmentData;
};

export const useUpdateTag: TypeUseUpdateTag = () => {
  const [updateTagFun, { isLoading }] = useUpdateTagMutation();
  const [prevEditDataFun, { isLoading: prevDataLoading }] =
    useLazyGetTagQuery();
  const { refetch } = useGetConfigTagsQuery();

  //this function is responsible for updating the tag
  const handleUpdateTag = async (values: MutationUpdateTagArgs) => {
    try {
      let translations = await convertTranslationArrToObj(
        values.updateObj.translations
      );
      let params = { updateObj: { ...values.updateObj, translations } };

      await updateTagFun({
        updateTagId: values.id,
        updateObj: params.updateObj,
      }).unwrap();

      refetch();
    } catch (error: any) {
      throw error;
    }
  };

  //this function is responsible for fetching the specific tag data which tag id we will be provided
  const handleFetchEditTagData = async (id: string) => {
    try {
      const res = await prevEditDataFun({ getTagId: id });
      const translations = await convertTranslationObjToArr(
        res.data?.getTag.translations
      );

      return { ...res.data?.getTag, translations };
    } catch (error) {
      throw error;
    }
  };

  return {
    isLoading: isLoading || prevDataLoading,
    handleFetchEditTagData,
    handleUpdateTag,
  };
};
