import { useToggleEmployeeActivationMutation } from "graphql/_generated/graphql";

// types of function
export type TypeHandleActivationDeactivationUsers = (
  id: string
) => Promise<boolean>;

//define the type of useUsers hook
type TypeUseToggleActivationUsers = () => {
  isLoading: boolean;
  handleUserActivationToggle: TypeHandleActivationDeactivationUsers;
};

export const useToggleActivationUsers: TypeUseToggleActivationUsers = () => {
  const [ActivationDeactivationFun, { isLoading }] =
    useToggleEmployeeActivationMutation();

  //this function is responsible for Activation/Deactivation Users
  const handleUserActivationToggle = async (id: string) => {
    try {
      const res = await ActivationDeactivationFun({
        toggleEmployeeActivationId: id,
      }).unwrap();

      return res.toggleEmployeeActivation;
    } catch (error: any) {
      throw error;
    }
  };

  return {
    isLoading,
    handleUserActivationToggle,
  };
};
