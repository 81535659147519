export const ProgramSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2.5" y="14" width="4" height="4" rx="1" fill="#303030" />
      <rect x="8.5" y="14" width="9" height="4" rx="1" fill="#303030" />
      <rect x="2.5" y="8" width="4" height="4" rx="1" fill="#303030" />
      <rect x="2.5" y="2" width="4" height="4" rx="1" fill="#303030" />
      <rect x="8.5" y="8" width="9" height="4" rx="1" fill="#303030" />
      <rect x="8.5" y="2" width="9" height="4" rx="1" fill="#303030" />
    </svg>
  );
};
