import * as Yup from "yup";
import * as Validations from "validations/schema/common/validations";
import { RegionVisibility } from "graphql/_generated/graphql";
export const AddPartnerSchema = Yup.object().shape({
  image: Validations.image("image"),
  name: Validations.requiredFieldWithoutSpaces("name"),
  description: Validations.requiredFieldWithoutSpaces("description"),
  partnerTypeId: Validations.required("partner-type"),
  code: Validations.requiredFieldPositiveNumber("partnerCode").test(
    "len",
    "partner-code-length",
    (val) => (val ? String(val).length <= 10 : true),
  ),
  regionVisibility: Validations.required("region-visibility"),
  countriesListId: Yup.array().when("regionVisibility", {
    is: RegionVisibility.Local,
    then: Validations.requiredArrayOfStringValidation("country"),
  }),
});
