import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setPagination } from "store/navigationSlice";
import { usePersistedState } from "./usePersistedState";
import { useState } from "react";

export const defaultPagination = {
  offset: { page: 1, pageSize: 10 },
};

const usePagination = (paginationType: any, persistEnabled?: boolean) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const pagination = usePersistedState(
    "pagination",
    location,
    defaultPagination,
  );
  const [localPagination, setLocalPagination] = useState({
    offset: { page: 1, pageSize: 10 },
  });
  const handlePagination = (currentPagination: any) => {
    if (persistEnabled) {
      persistEnabled && navigate("#persist");
      dispatch(
        setPagination({
          location,
          ...currentPagination,
        }),
      );
    } else {
      setLocalPagination({
        offset: {
          page: currentPagination?.current,
          pageSize: currentPagination?.pageSize,
        },
      });
    }
  };

  const handleCursorPaginationValue = (type: string) => {
    setPagination({
      ...pagination,
      cursor: {
        type,
      },
    });
  };

  return {
    pagination: persistEnabled ? pagination : localPagination,
    handlePagination,
    handleCursorPaginationValue,
  };
};

export default usePagination;
