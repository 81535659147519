import { useEffect, useState } from "react";
import { EventState, GetEventDetails } from "./useEventViewModel";
import { useEvent } from "./useEvent";
import { useErrorHandler } from "hooks/useErrorHandler";
import { FormikHelpers } from "formik";
import { EventParticipantsScoreInput } from "graphql/_generated/graphql";
import { useTranslation } from "react-i18next";
import customNotification from "components/custom-notification/CustomNotification";
import moment from "moment";

export const useEventDetailViewModel = (state: EventState) => {
  const { id: editId, langCode, addLanguage } = state ?? {};
  const { t } = useTranslation();
  const [eventData, setEventData] = useState<GetEventDetails>();
  const [participantVisibility, setParticipantVisibility] =
    useState<boolean>(false);
  const [clientId, setClientId] = useState<string | null>();
  const [userVisibility, setUserVisibility] = useState<boolean>(false);

  const { getEvent, handleUpdateParticipantScore, isLoadingEvent } = useEvent();
  const { handleError } = useErrorHandler();
  const fetchData = async () => {
    try {
      if (editId) {
        const data = await getEvent(editId);
        const translationData = langCode && data?.translations?.[langCode];
        setEventData({
          ...data,
          oldTitle: data.title ?? "",
          oldDescription: data.description ?? "",
          oldLocation: data.location,
        });
      }
    } catch (error) {
      handleError(error as string | object);
    }
  };
  useEffect(() => {
    fetchData();
  }, [editId]);

  const onSubmitUpdateParticipantScore = async (
    values: EventParticipantsScoreInput,
    formikHelpers?: FormikHelpers<typeof values>,
  ) => {
    try {
      const res = await handleUpdateParticipantScore({ update: values });
      fetchData();
      formikHelpers?.resetForm();
      customNotification("success", t("score-update-successfully"));
      onPressEditParticipant();
      return res;
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const onPressEditParticipant = () => {
    setParticipantVisibility(!participantVisibility);
  };

  const onPressUser = (clientId: string) => {
    setClientId(clientId);
    setUserVisibility(true);
  };

  const onPressCloseUser = () => {
    setClientId(null);
    setUserVisibility(false);
  };

  const getEventStatus = (record?: GetEventDetails): boolean => {
    if (record) {
      const { startDate, isActive } = record;
      const eventStartDate = moment(startDate);
      const todayDate = moment();
      const isPlanned = eventStartDate > todayDate;
      return isActive && isPlanned;
    }
    return false;
  };

  return {
    eventData,
    isLoadingEvent,
    participantVisibility,
    userVisibility,
    clientId,
    onSubmitUpdateParticipantScore,
    onPressEditParticipant,
    onPressUser,
    onPressCloseUser,
    getEventStatus,
  };
};
