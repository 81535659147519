import {
  useRefreshTokenMutation,
  MutationRefreshTokenArgs,
} from "graphql/_generated/graphql";

export const useRefreshToken = () => {
  const [handleRefreshTokenFun, { isLoading }] = useRefreshTokenMutation();

  const handleRefreshToken = async (values: MutationRefreshTokenArgs) => {
    const res = await handleRefreshTokenFun({
      tokens: {
        deviceToken: "",
        refreshToken: values.tokens.refreshToken,
      },
    }).unwrap();
    return res;
  };

  return { isLoading, handleRefreshToken };
};
