import AuthLayout from "features/auth/auth-layout/AuthLayout";
import styles from "features/auth/css/CheckEmail.module.scss";
import { useRequestOtpMutation } from "graphql/_generated/graphql";
import { useSelector } from "react-redux";
import { customNotification } from "components/custom-notification/CustomNotification";
import { errorFormat } from "helpers";
import { useTranslation } from "react-i18next";
import { useErrorHandler } from "hooks/useErrorHandler";

export default function CheckEmail() {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const forgotEmail = useSelector((state: any) => state.auth.forgotEmail);
  const [requestOtpFun] = useRequestOtpMutation();

  //this function is responsible for request otp
  const handleRequestOtp = async () => {
    try {
      await requestOtpFun({
        body: { email: forgotEmail, forAdmin: true },
      }).unwrap();
      customNotification("success", t("reset-password-link-success-label"));
    } catch (error: any) {
      handleError(error as string | object);
    }
  };

  return (
    <AuthLayout card id="checkEmailScreen">
      <h1 className={styles["title"]}>{t("check-email")} </h1>
      <p className={styles["description"]}>
        {t("email-sent")} {forgotEmail}
      </p>
      <p className={styles["description"]}>{t("click-link")}</p>
      <h2 className={styles["get-mail"]}>{t("no-email-sent")}</h2>
      <h3
        className={styles["resend"]}
        id="resendOTP"
        onClick={handleRequestOtp}
      >
        {t("resend")}
      </h3>
    </AuthLayout>
  );
}
