import {
  useLazyGetEmployeeOrgDetailsQuery,
  useSetEmployeeOrgDetailsMutation,
  useUpdateEmployeeEmailMutation,
  useUpdateEmployeeMutation,
} from "graphql/_generated/graphql";

export const useEditEmployeeDetail = () => {
  const [prevEditDataFun, { isLoading: prevDataLoading }] =
    useLazyGetEmployeeOrgDetailsQuery();
  const [updateEmployeeDetailFun, { isLoading: isUpdating }] =
    useSetEmployeeOrgDetailsMutation();

  const [updateEmployeeFunc, { isLoading: isUpdatingEmployee }] =
    useUpdateEmployeeMutation();

  const [updateEmployeeEmailFun, { isLoading: isLoadingEmployeeEmail }] =
    useUpdateEmployeeEmailMutation();

  //this function is responsible for fetching data against provided EmployeeDetail id
  const handleFetchEmployeeDetailData = async (id: string) => {
    try {
      const res = await prevEditDataFun({
        getEmployeeOrgDetailsEmployeeId2: id,
      }).unwrap();
      return res.getEmployeeOrgDetails;
    } catch (error) {
      throw error;
    }
  };

  //this function is responsible for updating the EmployeeDetail
  const handleUpdateEmployeeDetail = async (values: any) => {
    try {
      const {
        clientId,
        update: {
          buildingId,
          positionId,
          firstName,
          lastName,
          dob,
          gender,
          phoneNumber,
        },
      } = values;

      await updateEmployeeDetailFun({
        employeeId: values.id,
        employeeOrgDetails: {
          positionId,
          buildingId,
          clientId,
        },
      }).unwrap();

      await updateEmployeeFunc({
        updates: {
          dob,
          firstName,
          gender,
          lastName,
          phoneNumber,
        },
        updateEmployeeId: values.id,
      }).unwrap();
    } catch (error: any) {
      throw error;
    }
  };

  const handleUpdateEmployeeEmail = async (id: string, email: string) => {
    try {
      const res = await updateEmployeeEmailFun({
        employeeId: id,
        newEmail: email,
      }).unwrap();
      return res.updateEmployeeEmail.msg;
    } catch (error) {
      throw error;
    }
  };

  return {
    isLoading: prevDataLoading || isUpdating || isLoadingEmployeeEmail,
    handleFetchEmployeeDetailData,
    handleUpdateEmployeeDetail,
    handleUpdateEmployeeEmail,
  };
};
