import React from "react";
import { Layout } from "antd";
import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";
import "./DashboardLayout.module.scss";
type Props = {
  children: React.ReactNode;
  openKey?: string[];
  className?: string;
};

export default function DashboardLayout({
  children,
  openKey,
  className,
}: Props) {
  const { Content } = Layout;
  return (
    <Layout hasSider className={`viwell-dashboard ${className ?? ""}`}>
      <Sidebar openKey={openKey ? openKey : [""]} />
      <Layout>
        <Header />
        <Content className="viwell-content">{children}</Content>
      </Layout>
    </Layout>
  );
}
