import { useDeleteQuestMutation } from "graphql/_generated/graphql";

export const useDeleteQuest = () => {
  const [deleteQuestFun, { isLoading }] = useDeleteQuestMutation();

  const handleDeleteQuest = async (id: string) => {
    try {
      const res = await deleteQuestFun({ deleteQuestId: id }).unwrap();
      return res.deleteQuest;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleDeleteQuest,
    isLoadingDeleteQuest: isLoading,
  };
};
