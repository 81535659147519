interface Permission {
  key: string;
  label: string;
  permissionFor: string[];
  moduleDependent?: boolean;
  children?: Permission[];
}

let obj: any = {};
export function findPermissionsObject(
  permissionsArr: Permission[],
  currValue: string
): Permission | undefined {
  for (let i = 0; i < permissionsArr.length; i++) {
    if (permissionsArr[i].key === currValue) {
      obj = permissionsArr[i];
      break;
      //   return permissionsArr[i];
    }
    let childrenArr = permissionsArr[i].children;
    if (childrenArr) {
      findPermissionsObject(childrenArr, currValue);
    }
  }
  return obj;
}

// let obj: any = [];
// export function findPermissionsObject(
//   permissionsArr: Permission[],
//   currValue: string
// ): Permission | undefined {
//   console.log(permissionsArr);
//   for (let i = 0; i < permissionsArr.length; i++) {
//     if (permissionsArr[i].value === currValue) {
//       console.log(permissionsArr[i], "a");
//       obj = permissionsArr;
//       break;
//     }
//     let childrenArr = permissionsArr[i].children;
//     if (childrenArr) {
//       findPermissionsObject(childrenArr, currValue);
//     }
//   }
//   return obj;
// }
