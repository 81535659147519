const metricOptionInitialObject = {
  SN: 1,
  emoji: undefined,
  text: "",
  score: undefined,
  rowTitle: "",
  columnTitle: "",
  matrixKey: "",
  column: 1,
  row: 1,
  tags: [],
};
export default metricOptionInitialObject;

// Metric
//     O1  O2  O3
//    -----------
// T1 | 1 | 2 | 3
// T2 | 4 | 5 | 6
// T3 | 7 | 8 | 9

// Titles
//  const metricTitles =
//  { 1: {
//   id:1,
//   value:"",
//  }
// }

// Options
// const metricOptions =
//  { 1: {
//   id:1,
//   value:"",
//  }
// }
