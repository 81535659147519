import { MoreOutlined } from "@ant-design/icons";
import { Modal, Select, Space, Switch, Tag } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import CustomButton from "components/custom-button/CustomButton";
import CustomDropDown from "components/custom-dropdown/CustomDropDown";
import CustomTable from "components/custom-table/CustomTable";
import CustomLanguageSelector from "components/form/custom-language-selector/CustomLanguageSelector";
import { PERMS, getLanguage } from "helpers";
import { useTranslation } from "react-i18next";
import AddQuest from "./AddQuest";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import { getLangDir } from "helpers/getLangDir";
import { CONFIRM_MODAL_CONFIG } from "helpers/constants";
import { useDailyQuestViewModal } from "../hooks/useDailyQuestViewModal";
import moment from "moment";
import { useLanguages } from "hooks";

interface DailyQuestsListProps {}

const DailyQuestsList: React.FunctionComponent<DailyQuestsListProps> = () => {
  const { t } = useTranslation();
  const { languages } = useLanguages();
  const vm = useDailyQuestViewModal();

  type MappedQuestList = NonNullable<typeof vm.QuestListData>[0];

  const handleMenu = ({
    key,
    id,
    record,
  }: {
    key: string;
    id: string;
    record: MappedQuestList;
  }) => {
    if (key === "edit-quest") {
      vm.onQuestUpdate(id);
    }
    if (key === "delete-quest") {
      Modal.confirm({
        ...CONFIRM_MODAL_CONFIG,
        closable: true,
        centered: true,
        title: t("warning"),
        content: (
          <>
            <div className="confirm-content">{t("confirm-delete-quest")}</div>
          </>
        ),
        width: 506,
        onOk: () => vm.onDeleteQuest(id),
      });
    }
    if (key === "add-new-language") {
      vm.onPressAddLang(id, record);
    }
    if (key === "edit-language") {
      vm.onPressUpdateLanguage(id, record);
    }
  };

  const handleToggleQuest = (record: MappedQuestList) => {
    Modal.confirm({
      ...CONFIRM_MODAL_CONFIG,
      closable: true,
      centered: true,
      title: t("warning"),
      content: (
        <>
          <div className="confirm-content">
            {record.isActive
              ? t("sure-to-deactivate-quest")
              : t("sure-to-activate-quest")}
          </div>
        </>
      ),
      width: 506,
      onOk: () => vm.onToggleQuest(record.id ?? ""),
    });
  };

  const columns: ColumnsType<MappedQuestList> = [
    {
      title: t("id"),
      key: "id",
      dataIndex: "id",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, index) =>
        record.hasChildren
          ? (vm.currentPage - 1) * vm.pageSize + index + 1
          : null,
    },
    {
      title: t("title"),
      key: "title",
      dataIndex: "title",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => {
        return (
          <div className={record?.hasChildren ? "parent-row" : "child-row"}>
            <Space>
              <CustomAvatar shape="square" imgSrc={record?.image ?? ""} />
              <span className={`cut-text-${getLangDir(record.title ?? "")}`}>
                {record.title}
              </span>
            </Space>
          </div>
        );
      },
    },
    {
      title: `${t("language")}`,
      key: "language",
      dataIndex: "language",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => getLanguage(record.language),
    },
    {
      title: `${t("quest-type")}`,
      key: "questType",
      dataIndex: "questType",
      align: "center",
      render: (_text, record, _index) => record.questType.name,
    },
    {
      title: t("creation-date"),
      key: "createdAt",
      dataIndex: "createdAt",
      sorter: {
        multiple: 1,
      },
      render: (createdAt: string) => moment(createdAt).format("MMMM Do YYYY"),
    },
    {
      title: `${t("updated-at")}`,
      key: "updatedAt",
      dataIndex: "updatedAt",
      sorter: {
        multiple: 1,
      },
      render: (updatedAt: string) => moment(updatedAt).format("MMMM Do YYYY"),
    },
    {
      title: t("status-label"),
      dataIndex: "isActive",
      key: "isActive",
      align: "center" as "center",

      render: (isActive: boolean) =>
        isActive ? (
          <Tag className="active-tag"> &bull; {`${t("active")}`}</Tag>
        ) : (
          <Tag className="de-active-tag">{t("deactivated")}</Tag>
        ),
    },
    {
      align: "center" as "center",
      title: t("activation"),
      dataIndex: "id",
      key: "id",
      render: (_text, record, _index) => (
        <>
          <CheckPermissions permission={PERMS.TOGGLE_QUEST}>
            <Switch
              className="custom-switch"
              defaultChecked
              checked={!!record.isActive}
              onClick={() => handleToggleQuest(record)}
            />
          </CheckPermissions>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "Actions",
      align: "center",
      render: (id: string, record) => {
        const mainActions = [
          {
            key: "edit-quest",
            label: t("edit-quest"),
          },
          {
            key: "add-new-language",
            label: t("add-new-language"),
          },
          {
            key: "delete-quest",
            label: t("delete-quest"),
          },
        ];
        const langActions = [{ key: "edit-language", label: "Edit Language" }];
        return (
          <CheckPermissions
            permission={PERMS.DELETE_QUEST || PERMS.UPDATE_QUEST}
          >
            <CustomDropDown
              menu={{
                items: record?.hasChildren ? mainActions : langActions,
                onClick: ({ key }) => handleMenu({ key, id, record }),
              }}
            >
              <CustomButton type="link" className="link-btn">
                <MoreOutlined />
              </CustomButton>
            </CustomDropDown>
          </CheckPermissions>
        );
      },
    },
  ];
  return (
    <>
      <CustomTable
        showSearch={false}
        columns={columns}
        dataSource={vm.QuestListData}
        loading={vm.loading.isLoadingQuestList}
        rowKey={(record) =>
          record.hasChildren && record.id
            ? record.id
            : record.id + record.questType.id
        }
        expandIconColumnIndex={1}
        filters={{
          handleCallback: vm.filtersCallBack,
          status: {
            applyAs: "and",
            key: "isActive",
            comparison: "Equal",
          },
          date: [
            {
              applyAs: "and",
              key: "createdAt",
              comparison: "Range",
              placeholder: t("creation"),
            },
            {
              applyAs: "and",
              key: "updatedAt",
              comparison: "Range",
              placeholder: t("modified"),
            },
          ],
          manualFilters: [
            {
              key: "language",
              value: "language",
              comparison: "Equal",
              applyAs: "and",
              render: (onChangeCallback) => (
                <Select
                  options={languages}
                  fieldNames={{ label: "label", value: "value" }}
                  className="filter-select"
                  onChange={onChangeCallback}
                  allowClear
                  placeholder={t("language-filter")}
                />
              ),
            },
          ],
        }}
      />
      {vm.langVisibility && (
        <CustomLanguageSelector
          visible={vm.langVisibility}
          setVisible={vm.onCloseLangDialog}
          onSubmit={vm.onLanguageSubmit}
          data={vm.questRecord}
        />
      )}

      {vm.isAddQuest && (
        <AddQuest
          visible={vm.isAddQuest}
          editId={vm.editId}
          langCode={vm.langCode}
          isAddLanguage={vm.isAddLanguage}
          onCloseAddQuest={vm.onCloseAddQuest}
          onAddQuestSubmit={vm.onAddQuestSubmit}
          loading={vm.loading.isLoadingAddQuest}
          questData={vm.questData}
          questTypes={vm.questTypesData}
        />
      )}
    </>
  );
};

export default DailyQuestsList;
