import { RegionVisibility } from "graphql/_generated/graphql";
import { useMemo } from "react";

export const useGetRegionVisibility = () => {
  const regionVisibility = useMemo(() => {
    return Object.keys(RegionVisibility).map((item) => ({
      label: item,
      value: RegionVisibility[item as keyof typeof RegionVisibility],
    }));
  }, []);

  return {
    regionVisibility,
  };
};
