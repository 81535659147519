import {
  PaginationInput,
  TierCompanyList,
  useGetCompaniesListByTierQuery,
  useLazyGetCompaniesListByTierQuery,
} from "graphql/_generated/graphql";
import { useEffect, useState } from "react";

interface CompanyStatsDetailsProps {
  tierId: string;
}

export const useCompanyStatsViewModel = ({
  tierId,
}: CompanyStatsDetailsProps) => {
  const [data, setData] = useState<TierCompanyList>();
  const [visible, setVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>("");
  const [pagination, setPagination] = useState<PaginationInput>({
    limit: 10,
    offset: 0,
  });

  const { data: companiesData, isLoading } = useGetCompaniesListByTierQuery({
    tierId: tierId,
    pagination: {
      limit: 6,
    },
  });

  const [getCompanyListFun, { isFetching }] =
    useLazyGetCompaniesListByTierQuery();

  useEffect(() => {
    fetchCompaniesData();
  }, [currentPage, pagination, searchText]);

  const fetchCompaniesData = async () => {
    try {
      const { getCompaniesListByTier } = await getCompanyListFun({
        tierId: tierId,
        pagination,
        search: searchText,
      }).unwrap();
      setData(getCompaniesListByTier);
    } catch (error) {
      console.log(error);
    }
  };

  const onPaginationChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPagination({
      offset: (page - 1) * pageSize,
      limit: pageSize,
    });
  };

  const handleSearchInput = (value: string) => {
    setSearchText(value);
    setPagination({
      limit: 10,
      offset: 0,
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return {
    data,
    handleCancel,
    handleSearchInput,
    isFetching,
    pagination,
    currentPage,
    setCurrentPage,
    setPagination,
    companiesData,
    visible,
    setVisible,
    isLoading,
    onPaginationChange,
  };
};
