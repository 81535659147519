import {
  useGetPartnerRewardListQuery,
  useTogglePartnerRewardActivationMutation,
} from "graphql/_generated/graphql";

export const useTogglePartnerReward = () => {
  const [togglePartnerRewardFun, { isLoading }] =
    useTogglePartnerRewardActivationMutation();

  const { refetch } = useGetPartnerRewardListQuery();

  const handleTogglePartnerReward = async (id: string) => {
    try {
      const res = await togglePartnerRewardFun({
        togglePartnerRewardActivationId: id,
      }).unwrap();
      refetch();
      return res.togglePartnerRewardActivation;
    } catch (error) {
      throw error;
    }
  };
  return {
    handleTogglePartnerReward,
    isLoading,
  };
};
