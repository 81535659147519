import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {};

const EmployeeProfile = (props: Props) => {
  const { t } = useTranslation();
  return <DashboardLayout>{t("employee-profile")}</DashboardLayout>;
};

export default EmployeeProfile;
