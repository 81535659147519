import { Space } from "antd";
import { focusOnErrorField } from "helpers";

import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import CustomButton from "components/custom-button/CustomButton";
import ChallengeDescription from "features/challenge/add-challenge/ChallengeDescription";
import ChallengeImage from "features/challenge/add-challenge/ChallengeImage";
import ChallengeOptions from "features/challenge/add-challenge/ChallengeOptions";
import ChallengeValidations from "features/challenge/add-challenge/ChallengeValidations";
import RewardDetails from "features/challenge/add-challenge/RewardDetails";
import styles from "features/challenge/css/AddActivity.module.scss";
import { useAddChallengeViewModel } from "features/challenge/hooks/useAddChallengeViewModel";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { addChallengeSchema } from "validations/schema";

const AddChallenge = () => {
  const { t } = useTranslation();
  const vm = useAddChallengeViewModel();

  return (
    <DashboardLayout openKey={["Activity"]}>
      <Formik
        enableReinitialize={true}
        initialValues={vm.initialValues}
        validationSchema={addChallengeSchema({
          isSuperAdmin: vm.isSuperAdmin,
          isViwellAdmin: vm.isViwellAdmin,
          isOptional: vm.isDisabled(),
          isViwellChallenge: vm.isViwellChallenge,
          translations: t,
          language: vm.language,
        })}
        onSubmit={vm.onSubmit}
      >
        {({ values, setFieldValue, errors, isSubmitting, isValid }) => {
          focusOnErrorField({ errors, isSubmitting, isValid });
          return (
            <Form>
              <Breadcrumbs
                data={[
                  {
                    label: t("activities"),
                  },
                  {
                    label: t("challenges"),
                  },
                  ...(vm.isViewOnly
                    ? [
                        {
                          label: `${t("challenge-details")} - ${vm.language}`,
                        },
                        {
                          label: `${t("view-challenge")} - ${vm.language}`,
                        },
                      ]
                    : [
                        {
                          label: `${vm.editId ? t("edit") : t("add-new")} ${t(
                            "challenge",
                          )} - ${vm.language}`,
                        },
                      ]),
                ]}
              />
              <div className={styles["btn-div"]}>
                <h6 className="header__row--xtitle">{`${
                  vm.isViewOnly
                    ? t("view")
                    : vm.editId
                    ? t("edit")
                    : t("add-new")
                } ${t("Challenge")} - ${vm.language}`}</h6>

                <Space>
                  <CustomButton
                    className="assessment-create-header-cancel link-text-btn"
                    onClick={() => vm.navigateTo()}
                  >
                    {vm.isViewOnly ? t("close") : t("common.cancel")}
                  </CustomButton>

                  {vm.isViewOnly ? null : (
                    <CustomButton
                      loading={vm.isLoading}
                      className="primary-btn"
                      htmlType="submit"
                    >
                      <span>{`${t("save")} ${
                        vm.isAddLanguage ? "" : t("challenge")
                      }`}</span>
                    </CustomButton>
                  )}
                </Space>
              </div>
              <ChallengeDescription
                disabled={vm.isViewOnly}
                isAddLanguage={vm.isAddLanguage}
                setFieldValue={setFieldValue}
                oldRecord={vm.oldRecord}
              />
              <ChallengeOptions
                disabled={vm.isDisabled()}
                values={values}
                setFieldValue={setFieldValue}
                isViwellChallenge={vm.isViwellChallenge}
                setIsViwellChallenge={vm.setIsViwellChallenge}
              />
              <ChallengeValidations
                disabled={vm.isDisabled()}
                setFieldValue={setFieldValue}
                values={values}
                enableValidations={vm.enableValidations}
                setEnableValidations={vm.setEnableValidations}
              />
              <RewardDetails
                disabled={vm.isDisabled()}
                isViewOnly={vm.isViewOnly}
                values={values}
                setFieldValue={setFieldValue}
              />
              <ChallengeImage
                disabled={vm.isAddLanguage || vm.isViewOnly}
                setFieldValue={setFieldValue}
                values={values}
              />
            </Form>
          );
        }}
      </Formik>
    </DashboardLayout>
  );
};
export default AddChallenge;
