import {
  useLazyGetTierDetailsQuery,
  useUpdateTierMutation,
  MutationUpdateTierArgs,
  useGetTierListQuery,
} from "graphql/_generated/graphql";

export const useUpdateTier = () => {
  const [getTierDetailFun, { isLoading }] = useLazyGetTierDetailsQuery();

  const { refetch } = useGetTierListQuery();

  const [updateTierFun, { isLoading: isLoadingUpdateTier }] =
    useUpdateTierMutation();

  const handleUpdateTier = async (values: MutationUpdateTierArgs) => {
    try {
      await updateTierFun({
        updateTierId: values.id,
        update: values.update,
      }).unwrap();
      refetch();
    } catch (error) {
      throw error;
    }
  };

  const handleGetTierDetail = async (id: string) => {
    try {
      const res = await getTierDetailFun({ getTierDetailsId: id });
      return res.data?.getTierDetails;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleGetTierDetail,
    handleUpdateTier,
    isLoading: isLoading || isLoadingUpdateTier,
  };
};
