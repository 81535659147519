import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import { useState } from "react";
import styles from "features/rewards/css/PartnerList.module.scss";
import { useTranslation } from "react-i18next";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import { PERMS } from "helpers/constants";
import CustomButton from "components/custom-button/CustomButton";
import { PlusCircleOutlined } from "@ant-design/icons";
import PartnersList from "features/rewards/partners/PartnersList";
import AddPartner from "features/rewards/partners/AddPartner";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import CustomLanguageSelector from "components/form/custom-language-selector/CustomLanguageSelector";
interface PartnersProps {}

const Partners: React.FunctionComponent<PartnersProps> = (props) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [isAddPartner, setIsAddPartner] = useState(false);
  const [langCode, setLangCode] = useState<string>();

  const handleSubmit = (values: any, actions: any) => {
    actions.resetForm();
    setLangCode(values.language);
    setVisible(false);
    setIsAddPartner(true);
  };

  return (
    <DashboardLayout openKey={["Rewards"]}>
      <Breadcrumbs
        data={[
          {
            label: t("rewards"),
          },
          {
            label: t("partners"),
          },
        ]}
      />
      <PartnersList setVisible={setVisible} />
      {visible && (
        <CustomLanguageSelector
          title={`${t("partner")} ${t("main-language")}`}
          visible={visible}
          setVisible={setVisible}
          onSubmit={handleSubmit}
          label={t("partner")}
          saveText={t("add-partner")}
        />
      )}
      {isAddPartner && (
        <AddPartner
          visible={isAddPartner}
          setVisible={setIsAddPartner}
          editId={null}
          setEditId={() => null}
          langCode={langCode}
        />
      )}
    </DashboardLayout>
  );
};

export default Partners;
