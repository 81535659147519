import * as Yup from "yup";
import * as Validations from "./common/validations";

export const specialistSchema = () =>
  Yup.object().shape({
    image: Validations.image("image"),
    firstName: Validations.requiredFieldWithoutSpaces("first-name"),
    lastName: Validations.requiredFieldWithoutSpaces("last-name"),
    specialty: Validations.required("specialty"),
    categoriesIds: Validations.requiredArrayOfStringValidation("categories"),
  });
