import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import customNotification from "components/custom-notification/CustomNotification";
import CustomTextEditor from "components/form/custom-text-editor/CustomTextEditor";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { CustomUploadFile } from "components/form/custom-upload-file/CustomUploadFile";
import { errorFormat } from "helpers";
import { Form, Formik } from "formik";
import { useGetOnBoardingListQuery } from "graphql/_generated/graphql";
import { useEffect, useState } from "react";
import { AddOnBoardingSchema } from "validations/schema/addOnBoardingSchema";
import OnBoardingTranslations from "./OnBoardingTranslations";
import { useOnboarding } from "../../features/onboardings/hooks/useOnboarding";
import { useTranslation } from "react-i18next";
import { useErrorHandler } from "hooks/useErrorHandler";

interface AddOnBoardingProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  editId: string | null;
  setEditId: React.Dispatch<React.SetStateAction<string | null>>;
}

const AddOnBoarding: React.FunctionComponent<AddOnBoardingProps> = (props) => {
  const {
    isLoading,
    handleAddOnBoarding,
    handleUpdateOnBoarding,
    handleFetchOnBoarding,
  } = useOnboarding();
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const { refetch } = useGetOnBoardingListQuery();
  const { setVisible, editId, setEditId } = props;
  const [initialValue, setInitialValue] = useState<any>({
    image: "",
    title: "",
    description: "",
    translations: [],
  });
  const handleCancel = () => {
    setVisible(false);
    setEditId(null);
  };

  useEffect(() => {
    if (editId) {
      handleFetchOnBoarding({ id: editId }).then((data) => {
        setInitialValue(data);
      });
    } else {
      setInitialValue({
        image: "",
        title: "",
        description: "",
        translations: [],
      });
    }
  }, [editId]);

  return (
    <CustomModal
      title={editId ? t("edit-onboardings") : t("add-onboardings")}
      width={600}
      footer={false}
      onCancel={handleCancel}
      {...props}
    >
      <Formik
        enableReinitialize
        validationSchema={AddOnBoardingSchema}
        initialValues={initialValue}
        onSubmit={async (values, { resetForm }) => {
          try {
            if (!editId) {
              await handleAddOnBoarding({
                create: values,
              });
              customNotification("success", t("onboarding-added-successfully"));
            } else {
              await handleUpdateOnBoarding({
                id: editId,
                update: values,
              });
              customNotification(
                "success",
                t("onboarding-updated-successfully"),
              );
            }

            setVisible(false);
            setEditId(null);
            resetForm();
            refetch();
          } catch (error: any) {
            handleError(error as string | object);
          }
        }}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <CustomUploadFile
              name="image"
              setFieldValue={setFieldValue}
              imgUrl={values.image}
              accept="image/*"
            />
            <CustomTextField
              name="title"
              label={t("title")}
              placeholder={t("enter-title")}
            />
            <CustomTextEditor label={t("description")} name="description" />
            <OnBoardingTranslations values={values} />
            <ModalFooter
              loading={isLoading}
              onCancel={handleCancel}
              saveText={t("save-onboardings")}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddOnBoarding;
