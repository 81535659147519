import {
  useUpdateFaqMutation,
  MutationUpdateFaqArgs,
  useLazyGetFaqQuery,
} from "graphql/_generated/graphql";
import {
  convertTranslationArrToObj,
  convertTranslationObjToArr,
} from "helpers";

export const useUpdateFaq = () => {
  const [updateFaqFun, { isLoading }] = useUpdateFaqMutation();
  const [prevEditDataFun, { isLoading: prevDataLoading }] =
    useLazyGetFaqQuery();

  //This function is responsible for updating the faq data
  const handleUpdateFaq = async (values: MutationUpdateFaqArgs) => {
    try {
      const translations = await convertTranslationArrToObj(
        values.update?.translations!,
      );
      let params = { update: { ...values.update, translations } };
      await updateFaqFun({
        updateFaqId: values.id,
        update: params.update,
      }).unwrap();
    } catch (error) {
      throw error;
    }
  };

  //this function is responsible for fetching the specific faq data which faq id we will provide
  const handleFetchEditFaq = async (id: string) => {
    try {
      const res = await prevEditDataFun({ getFaqId: id }).unwrap();
      const translations = await convertTranslationObjToArr(
        res?.getFAQ?.translations!,
      );

      return { ...res?.getFAQ, translations };
    } catch (error) {
      throw error;
    }
  };

  return {
    updateLoading: isLoading || prevDataLoading,
    handleUpdateFaq,
    handleFetchEditFaq,
  };
};
