import { Pagination } from "antd";
import { EmployeeBulkCreate } from "graphql/_generated/graphql";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import PreviewDataTable from "./PreviewDataTable";

type Props = {
  items?: EmployeeBulkCreate[];
  previewKeys?: {
    [key: string]: boolean;
  };
};

export default function PreviewDetails({ items, previewKeys }: Props) {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pagination, setPagination] = useState<any>({
    limit: 10,
    offset: 0,
  });

  return (
    <>
      <PreviewDataTable
        data={items?.slice(pagination?.offset, pagination?.limit * currentPage)}
        previewKeys={previewKeys}
      />
      <div className={"pagination-margin"}>
        <Pagination
          onChange={(page, pageSize) => {
            setCurrentPage(page);
            setPagination({
              offset: (page - 1) * pageSize,
              limit: pageSize,
            });
          }}
          current={currentPage}
          pageSize={pagination?.limit}
          showTotal={(total, range) => (
            <span>
              {t("showing")}&nbsp;
              <b>
                {range[0]}-{range[1]}
              </b>
              &nbsp;{t("of")} <b>{total}</b>&nbsp; {t("items")}
            </span>
          )}
          total={items?.length || 0}
        />
      </div>
    </>
  );
}
