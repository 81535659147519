import { GraphQLClient } from "graphql-request";
import {
  DeleteUserData,
  SendUserDeletionNotificationDocument,
  SendUserDeletionNotificationMutation,
} from "graphql/_generated/graphql";
import config from "config";

const graphqlUrl = config.api.graphqlUrl;
export const deleteAccount = async (
  body: DeleteUserData,
  recaptcha: string,
) => {
  try {
    const client = new GraphQLClient(graphqlUrl);
    const response = await client.request(
      SendUserDeletionNotificationDocument,
      { body },
      { recaptcha },
    );
    return (response as SendUserDeletionNotificationMutation)
      .sendUserDeletionNotification;
  } catch (error) {
    throw error;
  }
};
