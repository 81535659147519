import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { customNotification } from "components/custom-notification/CustomNotification";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { errorFormat } from "helpers";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import {
  useGetClientsTreeQuery,
  SetCompanyRewardPointsMutationVariables,
  useGetRewardPointsListQuery,
} from "graphql/_generated/graphql";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { ChallengeRewardsPointSvg } from "assets/icons";
import { exceptionCompanySchema } from "validations/schema/addChallengeSchema";
import { useChallenge } from "../hooks/useChallenge";
import { useErrorHandler } from "hooks/useErrorHandler";

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  data?: any;
};

const AddException = ({ setVisible, visible, data }: Props) => {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const { refetch } = useGetRewardPointsListQuery();
  const { handleUpdateCompanyRewardPoints, isLoading } = useChallenge();

  const { data: companies } = useGetClientsTreeQuery(
    { clientId: null },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  //Initializing states
  const [initialValues, setInitialValues] =
    useState<SetCompanyRewardPointsMutationVariables>({
      setCompanyRewardPointsId: "",
      points: 0,
    });

  //this function is used to close modal with unsaved changes
  const handleCancel = () => {
    setVisible(false);
  };

  // Re-Initializing states after fetching rewards points data for specific id
  useEffect(() => {
    if (data) {
      setInitialValues({
        setCompanyRewardPointsId: data?.companyId,
        points: data?.points,
      });
    }
  }, [data]);

  return (
    <CustomModal
      width={600}
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      title={t(data ? "reward.edit-exception" : "reward.add-exception")}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={exceptionCompanySchema({
          translations: t,
        })}
        onSubmit={async (values, { resetForm }) => {
          try {
            await handleUpdateCompanyRewardPoints({
              setCompanyRewardPointsId: values.setCompanyRewardPointsId,
              points: values.points,
            });
            refetch();
            customNotification(
              "success",
              "Exception added/updated successfully",
            );
            resetForm();
            setVisible(false);
          } catch (error) {
            handleError(error as string | object);
          }
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <CustomSelectField
              label="Company"
              name="setCompanyRewardPointsId"
              className="form-select"
              placeholder="Select Company"
              options={companies?.getClientsTree}
              disabled={data?.companyId}
              fieldNames={{ label: "companyName", value: "id" }}
            />
            <CustomTextField
              name="points"
              label={t("challenge-default-reward")}
              type="number"
              placeholder={t("challenge-default-reward")}
              icon={<ChallengeRewardsPointSvg />}
            />
            <ModalFooter
              saveText={data ? "Save" : t("reward.add-exception")}
              onCancel={handleCancel}
              loading={isLoading}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddException;
