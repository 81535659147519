import AddOption from "components/custom-button/AddOption";
import DeleteBtn from "components/custom-button/DeleteBtn";
import CustomCard from "components/custom-card/CustomCard";
import CustomCrons from "components/form/custom-crons/CustomCrons";
import { CustomDatePicker } from "components/form/custom-date-picker/CustomDatePicker";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { FieldArray } from "formik";
import { AssessmentSettingOutput, EventType } from "graphql/_generated/graphql";
import moment from "moment";
import * as React from "react";
import styles from "./AssessmentSettings.module.scss";
import { CustomTimePicker } from "components/form/custom-time-picker/CustomTimePicker";
import { useTranslation } from "react-i18next";

export interface settingsType extends AssessmentSettingOutput {
  deleteNotifications: string[];
}
interface AssessmentSettingNotificationsProps {
  values: settingsType;
  setFieldValue: (field: string, value: any) => void;
}

const AssessmentSettingNotifications: React.FunctionComponent<
  AssessmentSettingNotificationsProps
> = (props) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = props;

  React.useEffect(() => {
    values?.notificationSetting?.map((item, index) => {
      const startDate = values.notificationSetting[index].startDate;
      if (values.notificationSetting[index].isPeriodic) {
        const cronInterval =
          values.notificationSetting?.[index].cron?.split("*")?.[1];
        if (cronInterval)
          setFieldValue(
            `notificationSetting.${index}.cron`,
            cronInterval?.slice(1).trim().toString() || "1",
          );
        if (startDate) {
          setFieldValue(
            `notificationSetting.${index}.startDate`,
            values.notificationSetting[index].startDate,
          );
          setFieldValue(
            `notificationSetting.${index}.endDate`,
            values.notificationSetting[index].endDate,
          );
          setFieldValue(
            `notificationSetting.${index}.timeOfDay`,
            moment(values.notificationSetting[index].startDate),
          );
        }
      } else {
        if (startDate) {
          setFieldValue(`notificationSetting.${index}.startDate`, null);
          setFieldValue(`notificationSetting.${index}.endDate`, null);
          setFieldValue(`notificationSetting.${index}.timeOfDay`, null);
        }
      }
    });
  }, []);

  return (
    <FieldArray
      name="notificationSetting"
      render={({ push, remove }) => (
        <>
          {values.notificationSetting &&
            values.notificationSetting.map((item, index) => (
              <div className={styles["notifications"]}>
                <CustomCard className="notification-card">
                  <div className={styles["flex-notification"]}>
                    <span>
                      {t("notification")} {index + 1}
                    </span>
                    <DeleteBtn
                      onClick={() => {
                        if (values.notificationSetting[index].id) {
                          setFieldValue("deleteNotifications", [
                            ...(values.deleteNotifications ?? []),
                            values.notificationSetting[index].id,
                          ]);
                        }
                        remove(index);
                      }}
                    />
                  </div>
                  <CustomSelectField
                    name={`notificationSetting.${index}.isPeriodic`}
                    options={[
                      {
                        label: t("periodic"),
                        value: true,
                      },
                      {
                        label: t("aperiodic"),
                        value: false,
                      },
                    ]}
                    label={t("notification-type")}
                    placeholder={t("select-notification-type")}
                  />
                  {!values.notificationSetting[index].isPeriodic && (
                    <CustomSelectField
                      name={`notificationSetting.${index}.eventType`}
                      options={[
                        {
                          label: t("start"),
                          value: EventType.Start,
                        },
                        {
                          label: t("end"),
                          value: EventType.End,
                        },
                      ]}
                      label={t("set-event")}
                      placeholder={t("select-event-type")}
                    />
                  )}
                  {values.notificationSetting[index].isPeriodic && (
                    <CustomSelectField
                      name={`notificationSetting.${index}.cron`}
                      placeholder={t("select-interval")}
                      label={t("interval")}
                      options={[
                        {
                          label: t("daily"),
                          value: "1",
                        },
                        {
                          label: t("weekly"),
                          value: "7",
                        },
                        {
                          label: t("monthly"),
                          value: "30",
                        },
                        {
                          label: t("quarterly"),
                          value: "90",
                        },
                      ]}
                    />
                  )}
                  {values.notificationSetting[index].isPeriodic && (
                    <>
                      <CustomTimePicker
                        name={`notificationSetting.${index}.timeOfDay`}
                        label={t("time-of-day")}
                        placeholder={t("select-time")}
                      />
                      <CustomDatePicker
                        name={`notificationSetting.${index}.startDate`}
                        label={t("start-date")}
                        placeholder={t("select-start-date")}
                      />
                      <CustomDatePicker
                        name={`notificationSetting.${index}.endDate`}
                        label={t("end-date")}
                        placeholder={t("select-end-date")}
                      />
                    </>
                  )}

                  <CustomTextField
                    name={`notificationSetting.${index}.message`}
                    label={t("message")}
                    placeholder={t("enter-message")}
                  />
                </CustomCard>
              </div>
            ))}
          <div className={styles["add-notification"]}>
            <AddOption
              title={t("add-notification")}
              onClick={() =>
                push({
                  cron: "1",
                  endDate: null,
                  eventType: EventType.Start,
                  isActive: true,
                  isPeriodic: true,
                  message: "",
                  startDate: null,
                  timeOfDay: null,
                })
              }
            />
          </div>
        </>
      )}
    />
  );
};

export default AssessmentSettingNotifications;
