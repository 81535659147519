import { MoreOutlined } from "@ant-design/icons";
import { Avatar, Popconfirm, Space, Switch, TableColumnsType, Tag } from "antd";
import {
  useGetOfferListQuery,
  useLazyGetOfferListQuery,
} from "graphql/_generated/graphql";
import { DefaultRecordType } from "rc-table/lib/interface";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import CustomTable from "components/custom-table/CustomTable";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import { useAddOffer } from "../../features/offers/offer-add/useAddOffers";
import { customNotification } from "components/custom-notification/CustomNotification";
import { errorFormat } from "helpers";
import styles from "features/offers/offers-list/offers-list.module.scss";
import { offerExcelMapper } from "features/offers/offers-list/offers-data-map";
import { useToggleOffer } from "features/offers/offers-list/useToggleOffer";
import { CustomBulkUpload } from "components/form/custom-bulk-upload/CustomBulkUpload";
import { offerUploadSchema } from "validations/schema/offerSchema";
import CustomPopconfirm from "components/form/custom-pop-confirm/CustomPopConfirm";
import UpdateOffer from "./UpdateOffer";
import CustomDropDown from "components/custom-dropdown/CustomDropDown";
import CustomMenu from "components/custom-menu/CustomMenu";
import CustomButton from "components/custom-button/CustomButton";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import { useErrorHandler } from "hooks/useErrorHandler";

interface OffersListProps {}

const OffersList: React.FunctionComponent<OffersListProps> = (props) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const [editId, setEditId] = useState<string | null>(null);
  const [filtersData, setFiltersData] = useState<any>(null);
  const [columnsData, setColumnsData] = useState<any>();
  const { handleAddOffer, isLoading: isLoadingAddOffer } = useAddOffer();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const { isFetching, refetch } = useGetOfferListQuery();
  const [getOffersListFun] = useLazyGetOfferListQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const { handleToggleOffer } = useToggleOffer();
  const { handleError } = useErrorHandler();
  const navigate = useNavigate();

  useEffect(() => {
    fetchDataFromApi(getOffersListFun, setColumnsData, filtersData);
  }, [isFetching]);

  const handleMenu = ({ key, id }: any) => {
    if (key === "edit") {
      setVisible(true);
      setEditId(id);
    }
  };

  const handleUpload = async (data: any) => {
    data = data.map((x: any) => {
      x.isOnline = x?.isOnline?.toLowerCase() === "online" ? true : false;

      return x;
    });

    try {
      await handleAddOffer({
        create: data,
      });

      customNotification("success", `${t("offers.reward-uploaded-msg")}`);
      refetch();
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const columns: TableColumnsType<DefaultRecordType> = [
    {
      title: t("id"),
      key: "id",
      dataIndex: "id",
      sorter: {
        multiple: 1,
      },
      render: (_text: any, _record: any, index: number) =>
        (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: t("name"),
      key: "title",
      dataIndex: "title",
      sorter: {
        multiple: 1,
      },
      render: (_text: any, record: any, index: number) => (
        <Space>
          <CustomAvatar
            disableBaseUrl
            shape="square"
            imgSrc={record?.imageUrl}
          />
          <div className="cut-text">{record.title}</div>
        </Space>
      ),
    },
    {
      title: t("reward-type"),
      key: "isOnline",
      dataIndex: "isOnline",
      render: (_text: any, record: any, index: number) => (
        <span>{record?.isOnline ? t("online") : t("offline")}</span>
      ),
    },
    {
      title: t("offers.no-of-points"),
      key: "requiredPoints",
      dataIndex: "requiredPoints",
      align: "center",
      render: (_text: any, record: any, index: number) => (
        <span>{record?.requiredPoints}</span>
      ),
    },
    {
      title: t("offers.limit"),
      key: "type",
      dataIndex: "type",
    },
    {
      title: t("offers.active-from"),
      dataIndex: "validFrom",
      key: "validFrom",
      sorter: {
        multiple: 1,
      },
      render: (validFrom: string) => moment(validFrom).format("MMMM Do YYYY"),
    },
    {
      title: t("offers.active-to"),
      dataIndex: "validTo",
      key: "validTo",
      sorter: {
        multiple: 1,
      },
      render: (validTo: string) => moment(validTo).format("MMMM Do YYYY"),
    },
    {
      title: t("offers.status"),
      dataIndex: "isActive",
      key: "isActive",
      align: "center",
      render: (isActive: boolean) =>
        isActive ? (
          <Tag className="active-tag">{t("active")}</Tag>
        ) : (
          <Tag className="de-active-tag">{t("deactivated")}</Tag>
        ),
    },
    {
      title: t("offers.activation"),
      dataIndex: "id",
      key: "id",
      align: "center" as "center",
      render: (id: string, { isActive }: any) => (
        <CustomPopconfirm
          onConfirm={async () => {
            try {
              const isActive = await handleToggleOffer(id);
              refetch();
              customNotification(
                "success",
                isActive
                  ? t("offers.reward-activated")
                  : t("offers.reward-deactivated"),
              );
            } catch (error) {
              handleError(error as string | object);
            }
          }}
          title={
            isActive
              ? t("offers.reward-deactivation-msg")
              : t("offers.reward-activation-msg")
          }
        >
          <Switch className="custom-switch" defaultChecked checked={isActive} />
        </CustomPopconfirm>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "Actions",
      align: "center",
      render: (id: string) => (
        <>
          <CustomDropDown
            overlay={
              <CustomMenu
                items={[{ label: t("offers.edit-reward"), key: "edit" }]}
                onClick={({ domEvent, key, keyPath }) =>
                  handleMenu({ domEvent, key, keyPath, id })
                }
              />
            }
          >
            <CustomButton type="link" className="link-btn">
              <MoreOutlined />
            </CustomButton>
          </CustomDropDown>
        </>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <Breadcrumbs
        data={[
          {
            label: t("rewards"),
          },
          {
            label: t("rewards"),
          },
        ]}
      />
      <div className={styles["offer"]}>
        <h1 className={styles["title"]}>{t("rewards")}</h1>
        <CustomBulkUpload
          {...{
            name: "upload",
            handleUpload,
            keyMap: offerExcelMapper,
            validationSchema: offerUploadSchema,
          }}
        >
          {t("offers.import")}
        </CustomBulkUpload>
        {/* <CustomButton
          className={`primary-btn ${styles["ml-3"]}`}
          onClick={() => navigate("/add-offer")}
        >
          {t("offers.add-reward")}
        </CustomButton> */}
      </div>
      <CustomTable
        columns={columns}
        filters={{
          handleCallback: async (filtersData: any) => {
            setPageSize(filtersData?.pagination?.offset?.pageSize);
            setCurrentPage(filtersData?.pagination?.offset?.page);
            fetchDataFromApi(getOffersListFun, setColumnsData, filtersData);
            setFiltersData(filtersData);
          },

          search: true,
          date: [
            {
              applyAs: "and",
              key: "validFrom",
              comparison: "Range",
              placeholder: "Active From",
            },
            {
              applyAs: "and",
              key: "validTo",
              comparison: "Range",
              placeholder: "Active To",
            },
          ],
          status: {
            applyAs: "and",
            key: "isActive",
            comparison: "Equal",
          },
        }}
        totalItems={columnsData?.metadata?.total}
        loading={isLoading || isLoadingAddOffer}
        dataSource={columnsData?.data}
      />
      <UpdateOffer
        {...{
          setEditId,
          setVisible,
          visible,
          editId,
        }}
      />
    </DashboardLayout>
  );
};

export default OffersList;
