export function convertTranslationArrToObj(arr: any[]) {
  let translations: any = {};
  if (arr === null || arr.length === 0) {
    return (translations = null);
  }
  new Promise<void>((resolve) => {
    arr.map((item) => {
      const { lang, ...rest } = item;
      translations[item.lang] = { ...rest };
    });
    resolve();
  });
  return translations;
}

export function convertTranslationObjToArr(obj: any) {
  let translations: any = [];
  if (obj == null) {
    return translations;
  }
  new Promise<void>((resolve) => {
    Object.keys(obj).map((key) => {
      translations.push({ lang: key, ...obj[key] });
    });
    resolve();
  });
  return translations;
}

export function filterSelctSelectedValues(options: any[], value: any[]) {
  return options.filter(
    (option) => option.value != value.map((val: { lang: any }) => val.lang)
  );
}
