import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { customNotification } from "components/custom-notification/CustomNotification";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { errorFormat } from "helpers";
import TranslationFieldArr from "components/translation-field-array/TranslationFieldArr";
import { Form, Formik } from "formik";
import {
  useGetConfigTagsQuery,
  useGetTagListQuery,
} from "graphql/_generated/graphql";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { tagSchema } from "validations/schema";
import { useTag } from "./hooks/useTag";
import { useErrorHandler } from "hooks/useErrorHandler";

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  editId?: string | null;
  setEditId: React.Dispatch<React.SetStateAction<string | null>>;
};

const AddTag = ({ setVisible, visible, editId, setEditId }: Props) => {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const { isLoading, handleAddTag, handleFetchEditTagData, handleUpdateTag } =
    useTag();
  const { refetch } = useGetTagListQuery();
  const { refetch: configTagsFetch } = useGetConfigTagsQuery();
  const [initialValues, setInitialValues] = useState<any>({
    title: "",
    translations: [],
  });

  const handleCancel = () => {
    setVisible(false);
    setEditId(null);
  };

  //setting the previous values to initial values object when click on the edit button
  useEffect(() => {
    if (editId) {
      handleFetchEditTagData(editId).then((data: any) => {
        setInitialValues({
          title: data.title,
          translations: data.translations,
        });
      });
    } else {
      setInitialValues({
        title: "",
        translations: [],
      });
    }
  }, [editId]);

  return (
    <CustomModal
      width={600}
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      title={t(editId ? "edit-tag" : "tag.add-tag")}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={tagSchema}
        onSubmit={async (values, { resetForm }) => {
          if (!editId) {
            try {
              await handleAddTag({
                create: {
                  ...values,
                },
              });
              customNotification("success", t("tag-added-successfully"));
              resetForm();
              setVisible(false);
              refetch();
              configTagsFetch();
            } catch (error) {
              handleError(error as string | object);
            }
          } else {
            try {
              await handleUpdateTag({ id: editId, updateObj: values });
              customNotification("success", t("tag-updated-successfully"));
              refetch();
              configTagsFetch();
              resetForm();
              setVisible(false);
              setEditId(null);
            } catch (error) {
              handleError(error as string | object);
            }
          }
        }}
      >
        {({ values, errors }) => {
          console.log(errors);
          return (
            <Form>
              <CustomTextField
                label={t("tag-name")}
                name="title"
                placeholder={t("tag.enter-tag-title")}
              />

              <TranslationFieldArr values={values} attribute="title" />
              <ModalFooter
                saveText={t("save-tag")}
                onCancel={handleCancel}
                loading={isLoading}
              />
            </Form>
          );
        }}
      </Formik>
    </CustomModal>
  );
};

export default AddTag;
