import React from "react";
import { Breadcrumb } from "antd";

import "./BreadCrumbs.module.scss";
import { BCCProps } from "components/custom-table/types";

const Breadcrumbs: React.FC<BCCProps> = (props) => (
  <Breadcrumb className="viwell-breadcrumbs">
    {props?.data?.map((item, index) => {
      return <Breadcrumb.Item key={index}>{item?.label}</Breadcrumb.Item>;
    })}
  </Breadcrumb>
);

export default Breadcrumbs;
