export const HamburgerIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.29 0H14.7092C15.1442 0 15.5 0.355833 15.5 0.790833V0.875833C15.5 1.31083 15.1442 1.66667 14.7092 1.66667H1.29C0.855 1.66667 0.5 1.31083 0.5 0.875833V0.790833C0.5 0.355833 0.855 0 1.29 0ZM14.7092 4.16656H1.29C0.855 4.16656 0.5 4.5224 0.5 4.9574V5.0424C0.5 5.4774 0.855 5.83323 1.29 5.83323H14.7092C15.1442 5.83323 15.5 5.4774 15.5 5.0424V4.9574C15.5 4.5224 15.1442 4.16656 14.7092 4.16656ZM14.7092 8.33344H1.29C0.855 8.33344 0.5 8.68927 0.5 9.12427V9.20927C0.5 9.64427 0.855 10.0001 1.29 10.0001H14.7092C15.1442 10.0001 15.5 9.64427 15.5 9.20927V9.12427C15.5 8.68927 15.1442 8.33344 14.7092 8.33344Z"
        fill="#303030"
      />
    </svg>
  );
};
