import styles from "../../CompanyDashboard.module.scss";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";
import CustomDivider from "components/custom-divider/CustomDivider";
import { useState } from "react";

export const ChallengesStatsDetails: React.FC<any> = ({
  challengeBarColors,
  challengesData,
}) => {
  const { t } = useTranslation();

  const renderChallengeStats = () =>
    challengesData?.stats?.map((item: any, index: number) => (
      <div className={`${styles["department-container"]}`}>
        <div
          className={styles["department-bar"]}
          style={{
            background: challengeBarColors[index],
          }}
        ></div>
        <div className={styles["department-text-container"]}>
          <Typography className={styles["department-title"]}>
            {item.name}
          </Typography>
          <div className={styles["department-percentage"]}>
            <Typography className={styles["department-desc-text"]}>
              {`${item.value.toLocaleString()}`}
            </Typography>
            <Typography className={styles["department-desc-text"]}>
              {`${(
                (item.value / challengesData?.totalEnrolledChallenges) *
                100
              ).toFixed(0)}%`}
            </Typography>
          </div>
        </div>
      </div>
    ));

  return (
    <div className={styles["total-assessments-container"]}>
      <div className={styles["completed-assessments-container"]}>
        <div className={styles["completed-assessments-box"]}>
          <Typography className={styles["box-main-text"]}>
            {`${t("active-label")} ${t("employees")}`}
          </Typography>
          <Typography className={styles["box-sub-text"]}>
            {`${challengesData.activeEmployees.toLocaleString()} - ${(
              (challengesData.activeEmployees / challengesData.totalEmployees) *
              100
            ).toFixed(0)}%`}
          </Typography>
        </div>
        <div className={styles["completed-assessments-box"]}>
          <Typography className={styles["box-main-text"]}>
            {`${t("in-active")} ${t("employees")}`}
          </Typography>
          <Typography className={styles["box-sub-text"]}>
            {`${challengesData.inActiveEmployees.toLocaleString()} - ${(
              (challengesData.inActiveEmployees /
                challengesData.totalEmployees) *
              100
            ).toFixed(0)}%`}
          </Typography>{" "}
        </div>
      </div>
      <CustomDivider className={styles["divider-color"]} />
      <div
        className={`${styles["departments-container"]} ${styles["challenges-content-height"]}`}
      >
        {renderChallengeStats()}
      </div>
    </div>
  );
};
