import { Space, Form } from "antd";
import CustomButton from "components/custom-button/CustomButton";
import { customNotification } from "components/custom-notification/CustomNotification";
import { errorFormat } from "helpers";
import {
  useGetGlobalRewardPointsQuery,
  useSetGlobalRewardPointsMutation,
} from "graphql/_generated/graphql";
import styles from "features/challenge-rewards/css/ChallengeRewards.module.scss";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";

import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { ChallengeRewardsPointSvg } from "assets/icons";
import { defaultRewardSchema } from "validations/schema/addChallengeSchema";
import { useErrorHandler } from "hooks/useErrorHandler";
interface ActivityListProps {}

const GlobalChallengePoints: React.FunctionComponent<ActivityListProps> = (
  props,
) => {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const [initialValues, setInitialValues] = useState<any>();
  const { isFetching, refetch, data } = useGetGlobalRewardPointsQuery();
  const [setGlobalPointsFun, { isLoading }] =
    useSetGlobalRewardPointsMutation();

  const navigate = useNavigate();
  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (data?.getGlobalRewardPoints) {
      setInitialValues(data?.getGlobalRewardPoints);
    }
  }, [data]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={defaultRewardSchema({
          translations: t,
        })}
        onSubmit={async (values, { resetForm }) => {
          try {
            await setGlobalPointsFun({
              ...values,
            }).unwrap();
            customNotification(
              "success",
              "Default reward updated successfully",
            );
          } catch (error) {
            handleError(error as string | object);
          }
        }}
      >
        {({ setFieldValue, values }) => {
          return (
            <Form>
              <div className={styles["btn-div"]}>
                <h6 className="header__row--xtitle">Challenges</h6>

                <Space>
                  <CustomButton
                    className="assessment-create-header-cancel link-text-btn"
                    onClick={() => navigate("/challenges")}
                  >
                    {t("common.cancel")}
                  </CustomButton>

                  <CustomButton
                    loading={isLoading}
                    className="primary-btn"
                    htmlType="submit"
                    onClick={async () => {
                      console.log("log submit", values);
                      if (values.points)
                        try {
                          await setGlobalPointsFun({
                            ...values,
                          }).unwrap();
                          customNotification(
                            "success",
                            "Default reward updated successfully",
                          );
                          refetch();
                        } catch (error) {
                          handleError(error as string | object);
                        }
                    }}
                  >
                    <span>Save</span>
                  </CustomButton>
                </Space>
              </div>
              <div className={styles["global-points-container"]}>
                <h1 className={styles["default-title"]}>Default Reward</h1>
                <CustomTextField
                  name="points"
                  label={t("challenge-default-reward")}
                  type="number"
                  placeholder={t("challenge-default-reward")}
                  icon={<ChallengeRewardsPointSvg />}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default GlobalChallengePoints;
