import { Col, Row } from "antd";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";

import CustomCard from "components/custom-card/CustomCard";
import CustomTextEditor from "components/form/custom-text-editor/CustomTextEditor";
import { FunctionComponent } from "react";
import styles from "features/challenge/css/AddActivity.module.scss";
import { CustomTextArea } from "components/form/custom-text-area/CustomTextArea";
import CollapseAble from "features/assessment/questions/collapseables";
import ContainerHeading from "features/assessment/questions/ContainerHeading";

interface ChallengeDescriptionProps {
  setFieldValue?: any;
  isAddLanguage?: boolean;
  oldRecord?: any;
  disabled?: boolean;
}

const ChallengeDescription: FunctionComponent<ChallengeDescriptionProps> = ({
  isAddLanguage,
  oldRecord,
  disabled,
}) => {
  return (
    <CollapseAble
      defaultActiveKey={["description"]}
      headerKey={"description"}
      className={styles["activity-card"]}
      header={
        <div className="d-flex">
          <ContainerHeading text="Name & Description" />
        </div>
      }
      hideExtra={true}
      expandIconPosition="end"
    >
      <div className={styles["content-card"]}>
        <Row gutter={[24, 0]}>
          <Col sm={24} md={12}>
            <CustomTextField
              label="Challenge Title"
              name="title"
              placeholder="Enter challenge title"
              isAddLanguage={isAddLanguage}
              oldTextValue={oldRecord?.title}
              disabled={disabled}
            />
          </Col>
        </Row>
        <CustomTextArea
          name="description"
          label="Description"
          placeholder="Enter challenge description here..."
          rows={4}
          disabled={disabled}
        />
        <CustomTextEditor
          name="rules"
          label="Rules"
          disabled={disabled}
          isRestrictAccess={true}
        />
      </div>
    </CollapseAble>
  );
};
export default ChallengeDescription;
