import { useLazyGetAssessmentSettingsQuery } from "graphql/_generated/graphql";

export const useGetAssessmentSettings = () => {
  const [getAssessmentSettingsFun, { isLoading: isLoadingAssessmentSettings }] =
    useLazyGetAssessmentSettingsQuery();

  const handleGetAssessmentSettings = async (id: string) => {
    try {
      const res = await getAssessmentSettingsFun({
        assessmentId: id,
      }).unwrap();
      return res.getAssessmentSettings;
    } catch (error) {
      throw error;
    }
  };
  return {
    handleGetAssessmentSettings,
    isLoadingAssessmentSettings,
  };
};
