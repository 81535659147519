import { Progress, Spin, Upload } from "antd";
import axios from "axios";
import { ErrorMessage } from "formik";
import { useState } from "react";
import styles from "../custom-upload-file/CustomUploadFile.module.scss";
import { useGetUploadDetailsForVideoMutation } from "graphql/_generated/graphql";
import { useErrorTranslation } from "validations/schema/common/useErrorTranslation";
type Props = {
  name: string;
  label?: string;
  setFieldValue: any;
  title: string;
  description: string;
  disable?: boolean;
  videoUrl?: string;
  durationFieldName?: string;
  accept?: string;
};

export function CustomVideoUpload(props: Props) {
  const { errorT } = useErrorTranslation();

  const [error, setError] = useState<string | null>("");
  const [defaultFileList, setDefaultFileList] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [progress, setProgress] = useState(0);
  // const [fileSize, setFileSize] = useState<number>(0);
  const [uploadVideoDetailsFun] = useGetUploadDetailsForVideoMutation();
  const { name, label } = props;

  //this function is responsible uploading the video on vimeo
  let fileSize = 0;
  const uploadProps = {
    multiple: false,
    accept: props.accept,
    listType: "picture-card" as "picture-card",
    onProgress(event: { percent: number }) {},
    onChange({ fileList }: any) {
      setDefaultFileList(fileList);
    },
    onRemove() {
      props.setFieldValue(name, "");
      setError(null);
    },
    async customRequest({ onSuccess, onError, file, onProgress }: any) {
      setLoading(true);
      const extension = file.name.split(".").pop().toLowerCase();

      const config = {
        onUploadProgress: (event: any) => {
          const percent = Math.floor((event.loaded / event.total) * 100);
          setProgress(percent);
          if (percent === 100) {
            setTimeout(() => setProgress(0), 1000);
          }
          onProgress({ percent: (event.loaded / event.total) * 100 });
        },
      };
      try {
        if (props.durationFieldName) {
          const duration: number = await getDuration(file);
          props.setFieldValue(props.durationFieldName, Math.floor(duration));
        }

        const res = await uploadVideoDetailsFun({
          details: { title: props.title, description: props.description },
          sizeInBytes: file.size,
        }).unwrap();

        props.setFieldValue(
          name,
          `https://player.vimeo.com/video/${res.getUploadDetailsForVideo.uri}`
        );

        let configData = {
          method: "patch",
          url: res.getUploadDetailsForVideo.uploadLink,
          headers: {
            "Tus-Resumable": "\t1.0.0",
            "Upload-Offset": "0",
            "Content-Type": "application/offset+octet-stream",
            Accept: "\tapplication/vnd.vimeo.*+json;version=3.4",
          },
          data: file,
          config,
        };

        const finalVideoRes = await axios(configData);

        setLoading(false);
        onSuccess("Ok");
      } catch (error) {
        onError({ error });
        setLoading(false);
      }

      // TODO: use from environment variable
      // const base = "https://dev-api.viwell.com";
      // const formData = new FormData();
      // formData.append("video", file);
      // axios
      //   .post(`${base}/media/uploadVideo`, formData, {
      //     params: {
      //       title: props.title,
      //       description: props.description,
      //     },
      //   })
      //   .then((res) => {
      //     console.log("video uploaded response=>", res.data);
      //     props.setFieldValue("filePath", `https://vimeo.com/${res.data.uri}`);
      //     onSuccess("Ok");
      //   })
      //   .catch((err) => {
      //     console.log("Video upload error =>", err);
      //   });
    },
  };

  return (
    <div className={styles["form-control"]}>
      {label && (
        <label className="label" htmlFor={name}>
          {label}
        </label>
      )}
      <Upload
        {...uploadProps}
        maxCount={1}
        multiple={false}
        name={name}
        disabled={props.disable}
      >
        {/* {defaultFileList.length >= 1 ? null :  */}
        {props.videoUrl ? (
          <div className={styles["img-url"]}>
            {/* <iframe
              src={props.videoUrl}
              className={styles["img-fluid"]}
            ></iframe> */}
          </div>
        ) : (
          <div>Upload Video</div>
        )}
      </Upload>
      <Spin spinning={loading} />
      {progress > 0 ? <Progress percent={progress} /> : null}
      {/* this error message will show when error in the api */}
      {error ? (
        <ErrorMessage
          name={name}
          render={(msg) => <div style={{ color: "red" }}>{error}</div>}
        />
      ) : (
        ""
      )}

      {/* this error message will show if the validation not succeeded from the
      front end */}
      <ErrorMessage
        name={name}
        render={(msg) => <div style={{ color: "red" }}>{errorT(msg)}</div>}
      />
    </div>
  );
}

async function getDuration(file: File): Promise<number> {
  return new Promise((res) => {
    const video = document.createElement("video");
    video.preload = "metadata";

    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      const duration = video.duration;
      res(duration);
      video.remove();
    };

    video.src = URL.createObjectURL(file);
  });
}
