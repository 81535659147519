import { customNotification } from "components/custom-notification/CustomNotification";
import { errorFormat } from "helpers";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import AuthLayout from "features/auth/auth-layout/AuthLayout";
import { setToken } from "features/auth/authSlice";
import { useTranslation } from "react-i18next";
import { useOtpVerification } from "../../features/auth/hooks/useOtpVerification";
// import { messaging, getToken } from "services/firebase/firebase.service";

export default function OtpVerify() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { verifyOtp } = useOtpVerification();
  const token = useSelector((state: any) => state.auth.token);

  let { userId, otp } = useParams();
  const values = {
    id: userId!,
    otp: otp!,
  };

  useEffect(() => {
    handleOtp();
  }, []);

  //this function is responsible for verification otp
  const handleOtp = async () => {
    try {
      // const deviceToken = await getToken(messaging);
      const res = await verifyOtp({ body: { ...values, deviceToken: "" } });

      if (res.verifyOtp.__typename === "Tokens") {
        dispatch(
          setToken({
            token: res.verifyOtp.token,
            refreshToken: res.verifyOtp.refreshToken,
          }),
        );

        navigate(`/company`);
        customNotification("success", t("otp-verified"));
      } else {
        const navigateTo = token ? "logout-to-proceed" : "reset-password";
        navigate(`/${navigateTo}/${userId}`, {
          state: { isResetPassword: true },
        });
      }
    } catch (error: any) {
      customNotification(
        "error",
        errorFormat(error)?.toLocaleLowerCase()?.includes("messaging")
          ? "Link is expired"
          : errorFormat(error),
      );
    }
  };

  return (
    <AuthLayout>
      <></>
    </AuthLayout>
  );
}
