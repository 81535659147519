import { TabsProps } from "antd";
import CustomTabs from "components/custom-tabs/CustomTabs";
import CategoriesList from "features/categories/categories-list/CategoriesList";
import styles from "features/content/css/Configurations.module.scss";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import MapperList from "features/mapper/mapper-list/MapperList";
import PillarList from "features/pillars/pillars-list/PillarsList";
import TagList from "pages/tags/TagList";
import * as React from "react";
import { useTranslation } from "react-i18next";
interface ConfigurationsProps {}

const Configurations: React.FunctionComponent<ConfigurationsProps> = (
  props,
) => {
  const { t } = useTranslation();
  const tabs: TabsProps["items"] = [
    {
      label: t("configurations.pillars"),
      children: <PillarList />,
      key: "pillars",
    },
    {
      label: t("configurations.categories"),
      children: <CategoriesList />,
      key: "categories",
    },
    {
      label: t("configurations.mapper"),
      children: <MapperList />,
      key: "mapper",
    },
    {
      label: t("configurations.tags"),
      children: <TagList />,
      key: "tags",
    },
    // {
    //   label: t("configurations.attributes"),
    //   children: <AttributesList />,
    //   key: "attributes",
    // },
  ];
  return (
    <DashboardLayout openKey={["Content"]}>
      <div className={styles["config"]}>
        <CustomTabs items={tabs} />
      </div>
    </DashboardLayout>
  );
};

export default Configurations;
