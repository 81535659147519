import * as Yup from "yup";
import * as Validations from "validations/schema/common/validations";

export const badgeSchema = Yup.object().shape({
  name: Validations.requiredFieldWithoutSpaces("name"),
  icon: Validations.image("image"),
  tracker: Yup.object().shape({
    goalType: Validations.required("tracker-type"),
    goal: Validations.requiredFieldPositiveNumber("tracker-goal"),
  }),
  message: Validations.requiredFieldWithoutSpaces("message"),
  translations: Yup.array().of(
    Yup.object().shape({
      lang: Validations.required("language"),
      name: Validations.requiredFieldWithoutSpaces("error:selected-lang-name"),
      message: Validations.requiredFieldWithoutSpaces("message"),
    })
  ),
});
