import { useToggleHabitStatusMutation } from "graphql/_generated/graphql";

export const useToggleHabitStatus = () => {
  const [toggleHabitStatusHabitFun, { isLoading: isLoadingToggleHabitStatus }] =
    useToggleHabitStatusMutation();

  const handleToggleHabitStatus = async (id: string) => {
    try {
      const res = await toggleHabitStatusHabitFun({
        toggleHabitStatusId: id,
      }).unwrap();
      return res.toggleHabitStatus;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleToggleHabitStatus,
    isLoadingToggleHabitStatus,
  };
};
