import { Space } from "antd";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import CustomButton from "components/custom-button/CustomButton";
import CustomTextEditor from "components/form/custom-text-editor/CustomTextEditor";
import ContainerHeading from "features/assessment/questions/ContainerHeading/ContainerHeading";
import CollapseAble from "features/assessment/questions/collapseables/CollapseAble";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import DocumentationTranslations from "features/documentation/DocumentationTranslations";
import styles from "features/documentation/css/Documentation.module.scss";
import { useDocumentationViewModel } from "features/documentation/hooks/useDocumentationViewModel";
import { Form, Formik } from "formik";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { documentationSchema } from "validations/schema/documentationSchema";

export enum DocumentationSections {
  privacyPolicy = "privacyPolicy",
  termsAndConditions = "termsAndConditions",
  aboutViwell = "aboutViwell",
}

const Documentation: FunctionComponent = () => {
  const { t } = useTranslation();

  const vm = useDocumentationViewModel();

  return (
    <DashboardLayout openKey={["MobileSettings"]}>
      <Breadcrumbs
        data={[
          {
            label: "Mobile Settings",
          },
          {
            label: "Documentation",
          },
        ]}
      />
      <Formik
        enableReinitialize
        initialValues={vm.initialValues}
        validationSchema={documentationSchema}
        onSubmit={vm.handleSubmit}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <div className={styles["doc-header"]}>
              <div className={styles["header-title"]}>{t("documentation")}</div>
              <Space>
                <CustomButton
                  className="light-btn"
                  htmlType="reset"
                  onClick={vm.resetNavigation}
                >
                  {t("cancel")}
                </CustomButton>
                <CustomButton
                  loading={vm.isLoading}
                  htmlType="submit"
                  className="primary-btn"
                >
                  {t("save-publish")}
                </CustomButton>
              </Space>
            </div>
            <div className={styles["divider"]}>
              <CollapseAble
                defaultActiveKey={["privacy"]}
                headerKey="privacy"
                hideExtra
                expandIconPosition="end"
                header={<ContainerHeading text={t("privacy-policy")} />}
              >
                <div key={"privacy"}>
                  <CustomTextEditor
                    label={t("content-description")}
                    name="privacyPolicy"
                  />
                  <DocumentationTranslations
                    sectionName={DocumentationSections.privacyPolicy}
                    values={values}
                  />
                </div>
              </CollapseAble>
              <CollapseAble
                defaultActiveKey={[""]}
                headerKey="terms"
                hideExtra
                expandIconPosition="end"
                header={<ContainerHeading text={t("terms-conditions")} />}
              >
                <div key={"terms"}>
                  <CustomTextEditor
                    label={t("content-description")}
                    name="termsAndConditions"
                  />
                  <DocumentationTranslations
                    sectionName={DocumentationSections.termsAndConditions}
                    values={values}
                  />
                </div>
              </CollapseAble>
              <CollapseAble
                defaultActiveKey={[""]}
                headerKey="aboutViwell"
                hideExtra
                expandIconPosition="end"
                header={<ContainerHeading text={t("about-viwell")} />}
              >
                <div key={"aboutViwell"}>
                  <CustomTextEditor
                    label={t("content-description")}
                    name="aboutViwell"
                  />
                  <DocumentationTranslations
                    sectionName={DocumentationSections.aboutViwell}
                    values={values}
                  />
                </div>
              </CollapseAble>
            </div>
          </Form>
        )}
      </Formik>
    </DashboardLayout>
  );
};

export default Documentation;
