import {
  useGetDepartmentListQuery,
  useRemoveDepartmentMutation,
  useToggleDepartmentActivationMutation,
} from "graphql/_generated/graphql";

export const useToggleActivationDepartment = () => {
  const { refetch } = useGetDepartmentListQuery();
  const [ActivationDeactivationFun, { isLoading }] =
    useToggleDepartmentActivationMutation();
  const [removeDepartmentFunc, { isLoading: isDeptLoading }] =
    useRemoveDepartmentMutation();

  //this function is responsible for Activation/Deactivation departments
  const handleDeActivationDepartment = async (id: string) => {
    try {
      const res = await ActivationDeactivationFun({
        toggleDepartmentActivationId: id,
      }).unwrap();

      refetch();
      return res.toggleDepartmentActivation;
    } catch (error: any) {
      throw error;
    }
  };

  const handleRemoveDepartment = async (id: string) => {
    try {
      const res = await removeDepartmentFunc({
        removeDepartmentId: id,
      }).unwrap();

      refetch();
      return res.removeDepartment;
    } catch (error: any) {
      throw error;
    }
  };

  return {
    isLoading,
    handleDeActivationDepartment,
    handleRemoveDepartment,
    isDeptLoading,
  };
};
