import { Tabs, TabsProps } from "antd";
import CustomModal from "components/custom-modal/CustomModal";
import CustomTabs from "components/custom-tabs/CustomTabs";
import React from "react";
import { useTranslation } from "react-i18next";
import InviteMultipleUsers from "./InviteMultipleUsers";
import InviteSingleUser from "./InviteSingleUser";

const { TabPane } = Tabs;

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  employerId?: string | undefined;
};

export default function InviteUsers({
  visible,
  setVisible,
  employerId,
}: Props) {
  const { t } = useTranslation();
  const handleCancel = () => {
    setVisible(false);
  };

  const tabs: TabsProps["items"] = [
    {
      label: t("invite-single-user"),
      children: (
        <InviteSingleUser employerId={employerId} setVisible={setVisible} />
      ),
      key: "invite-single-user",
    },
    {
      label: t("invite-bulk-users"),
      children: (
        <InviteMultipleUsers employerId={employerId} setVisible={setVisible} />
      ),
      key: "invite-multiple-user",
    },
  ];

  return (
    <CustomModal
      width={600}
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      title={t("users.invite-users")}
    >
      <CustomTabs items={tabs} />
    </CustomModal>
  );
}
