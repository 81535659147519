import {
  convertTranslationArrToObj,
  convertTranslationObjToArr,
} from "helpers";

import {
  MutationUpdateDepartmentArgs,
  useGetDepartmentListQuery,
  useLazyGetDepartmentQuery,
  useUpdateDepartmentMutation,
} from "graphql/_generated/graphql";

export const useUpdateDepartment = () => {
  const [updateDepartmentFun, { isLoading }] = useUpdateDepartmentMutation();
  const [prevEditDataFun, { isLoading: prevDataLoading }] =
    useLazyGetDepartmentQuery();
  const { refetch } = useGetDepartmentListQuery();

  //This function is responsible for updating the department data
  const handleUpdateDepartment = async (
    values: MutationUpdateDepartmentArgs
  ) => {
    try {
      let translations = await convertTranslationArrToObj(
        values.updateObj.translations
      );
      let params = {
        updateObj: { ...values.updateObj, translations },
      };
      await updateDepartmentFun({
        updateDepartmentId: values.id,
        updateObj: params.updateObj,
      }).unwrap();
      refetch();
    } catch (error: any) {
      throw error;
    }
  };

  //this function is responsible for fetching the specific department data which department id we will be provided
  const handleFetchPreviousDepartmentData = async (id: string) => {
    try {
      const res = await prevEditDataFun({ getDepartmentId: id });

      const translations = await convertTranslationObjToArr(
        res.data?.getDepartment.translations
      );

      return { ...res.data?.getDepartment, translations };
    } catch (error) {
      throw error;
    }
  };

  return {
    isLoading: isLoading || prevDataLoading,
    handleFetchPreviousDepartmentData,
    handleUpdateDepartment,
  };
};
