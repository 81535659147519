import { Col, Row } from "antd";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { FunctionComponent } from "react";
import styles from "features/events/css/EventAdd.module.scss";
import { CustomTextArea } from "components/form/custom-text-area/CustomTextArea";

import { t } from "i18next";
import CustomTextEditor from "components/form/custom-text-editor/CustomTextEditor";
import CollapseAble from "features/assessment/questions/collapseables/CollapseAble";
import ContainerHeading from "features/assessment/questions/ContainerHeading/ContainerHeading";
import { GetEventDetails } from "../hooks/useEventViewModel";

interface EventDescriptionProps {
  oldTitle?: string;
  oldDescription?: React.ReactNode;
  isAddLanguage?: boolean;
}

const EventDescription: FunctionComponent<EventDescriptionProps> = ({
  oldTitle,
  oldDescription,
  isAddLanguage,
}) => {
  return (
    <CollapseAble
      defaultActiveKey={["description"]}
      headerKey="description"
      hideExtra
      expandIconPosition="end"
      header={<ContainerHeading text={t("name-desc")} />}
    >
      <Row gutter={[24, 0]}>
        <Col sm={24} md={12}>
          <CustomTextField
            label={t("event.title")}
            name="title"
            placeholder={t("event.enter-title")}
            oldTextValue={oldTitle}
            isAddLanguage={isAddLanguage}
          />
        </Col>
      </Row>
      <CustomTextEditor
        name="description"
        label={t("description")}
        placeholder={t("event.enter-description")}
        isRestrictAccess
        oldTextValue={oldDescription}
        isAddLanguage={isAddLanguage}
      />
    </CollapseAble>
  );
};
export default EventDescription;
