import {
  MutationInviteEmployeeArgs,
  useInviteEmployeeMutation,
} from "graphql/_generated/graphql";

//type of function
export type TypeInviteSingleEmployee = (values: any) => Promise<void>;

//define the type of useUsers hook
type TypeUseInviteSingleEmployee = () => {
  isLoading: boolean;
  inviteSingleEmployee: TypeInviteSingleEmployee;
};

export const useInviteSingleEmployee: TypeUseInviteSingleEmployee = () => {
  const [inviteSingleEmployeeFun, { isLoading }] = useInviteEmployeeMutation();

  //this function is responsible for inviting single user
  const inviteSingleEmployee = async (values: any) => {
    try {
      const { departmentId, ...rest } = values.employeeCreateDto;
      let params = {
        parentId: values.parentId,
        employeeCreateDto: {
          ...rest,
          department: departmentId,
        },
      };

      await inviteSingleEmployeeFun(params).unwrap();
    } catch (error: any) {
      throw error;
    }
  };

  return {
    isLoading,
    inviteSingleEmployee,
  };
};
