export const MultipleChoiceIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect opacity="0.24" width="24" height="24" rx="4" fill="#7573E1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.25 12.625C17.25 12.211 17.586 11.875 18 11.875C18.414 11.875 18.75 12.211 18.75 12.625V16.7995C18.75 17.875 17.8747 18.7502 16.8 18.7502H7.2C6.12525 18.7502 5.25 17.875 5.25 16.7995V7.20024C5.25 6.12474 6.12525 5.25024 7.2 5.25024H14.3752C14.7892 5.25024 15.1252 5.58549 15.1252 6.00024C15.1252 6.41424 14.7892 6.75024 14.3752 6.75024H7.2C6.95175 6.75024 6.75 6.95199 6.75 7.20024V16.7995C6.75 17.0477 6.95175 17.2502 7.2 17.2502H16.8C17.0482 17.2502 17.25 17.0477 17.25 16.7995V12.625ZM9.98225 11.2099C10.2822 10.9234 10.7562 10.9354 11.0427 11.2346L12.1715 12.4181L16.7112 7.72839C16.9985 7.43139 17.4732 7.42239 17.7717 7.71114C18.0695 7.99914 18.077 8.47389 17.789 8.77164L12.7062 14.0216C12.5652 14.1679 12.371 14.2504 12.1677 14.2504H12.1647C11.9607 14.2496 11.7657 14.1656 11.6247 14.0179L9.9575 12.2704C9.671 11.9704 9.683 11.4956 9.98225 11.2099Z"
        fill="#303030"
      />
    </svg>
  );
};
