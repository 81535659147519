import { Dropdown, DropDownProps } from "antd";
import * as React from "react";

interface CustomDropDownProps extends DropDownProps {}

const CustomDropDown: React.FunctionComponent<CustomDropDownProps> = (
  props
) => {
  return <Dropdown {...props} />;
};

export default CustomDropDown;
