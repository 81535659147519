import { Spin } from "antd";
import CustomCard from "components/custom-card/CustomCard";
import CustomDivider from "components/custom-divider/CustomDivider";
import { PieChart } from "features/company-dashboard/charts/pie/PieChart";
import {
  pillarsDataMapper,
  timeFilterOptions,
} from "features/company-dashboard/utils";
import { useTranslation } from "react-i18next";
import styles from "../../CompanyDashboard.module.scss";
import { InfoTextPopup } from "../InfoTextPopup";
import { NoData } from "../NoData";

export const CategoryDetails: React.FC<any> = ({
  item,
  className,
  extraSpacing,
  infoTexts,
  visible,
  selectedInfoText,
  setSelectedInfoText,
}) => {
  const { t } = useTranslation();

  const otherCategoriesData = item?.content?.data.slice(5)?.reduce(
    (interactionsData: any, item: any) => {
      const otherCategories = {
        ...interactionsData,
        value: interactionsData.value + item.value,
        total: interactionsData.total + item.total,
      };
      return { ...otherCategories };
    },
    { name: t("other"), value: 0, total: 0 },
  );

  const allCategories = [
    ...(item?.content?.data?.slice(0, 5) || []),
    ...(item?.content?.data?.length > 5 ? [otherCategoriesData] : []),
  ];

  const pieChartData = allCategories.map((item) => ({
    name: "Categories",
    value: parseInt(((item.value / item.total) * 100).toFixed(0)),
  }));

  const pillarCategoryBarColors = pillarsDataMapper?.find(
    (pillar) => pillar.name === item?.selectedPillar?.name,
  )?.categoryColors;

  const pastTimeLabel = timeFilterOptions.find(
    (time) => time.value === item.selectedValues?.pastDays,
  );

  const renderCategories = () =>
    allCategories?.map((category: any, index: number) => (
      <div className={styles["category-margin"]}>
        <div className={`${styles["category-details-container"]}`}>
          <div
            className={styles["category-bar"]}
            style={{
              background: pillarCategoryBarColors?.[index],
            }}
          ></div>
          <div className={styles["pillar-left-container"]}>
            <div className={`${styles["content-index"]}`}>{category.name}</div>
          </div>
          <div className={styles["pillar-content-container"]}>
            <div className={`${styles["pillars-content-text"]}`}>
              {`${((category.value / category.total) * 100).toFixed(0)}%`}
            </div>
            <div
              className={styles["pillars-content-text"]}
            >{`${category.value?.toLocaleString("en-US")}`}</div>
          </div>
        </div>
        {index <= allCategories?.length - 2 ? (
          <CustomDivider className={styles["divider-color"]} />
        ) : null}
      </div>
    ));

  return (
    <CustomCard
      bordered={false}
      className={`${styles["section-card"]} ${styles["pillars-content-height"]}`}
    >
      <div
        className={`${styles["content-container"]} ${
          extraSpacing ? styles["content-container-height"] : ""
        }`}
      >
        <div className={styles["section-item-container"]}>
          <div className={styles["item-header"]}>{`${t(
            "most-interacted-categories",
          )} ${item.selectedPillar.name} ${t("pillar-title")}`}</div>
          <div>
            <InfoTextPopup
              items={infoTexts}
              visible={visible || selectedInfoText !== item.type}
              handleSelectedInfoText={(reset?: boolean) =>
                setSelectedInfoText(reset ? "" : item.type)
              }
            />
          </div>
        </div>
        {!item?.isLoading && item?.content?.data?.length ? (
          <div className={styles["viwell-assessment-container"]}>
            <div className={styles["category-pie-chart-container"]}>
              <PieChart
                pieChartConfig={{
                  data: pieChartData,
                  colors: pillarCategoryBarColors,
                  innerRadius: 50,
                  outerRadius: 125,
                }}
              />
            </div>
            <div className={styles["categories-container"]}>
              {renderCategories()}
            </div>
          </div>
        ) : (
          <div
            className={`${styles["margin-top-16"]} ${styles["pillar-list-container"]}`}
          >
            {item?.isLoading ? (
              <Spin spinning={item?.isLoading} />
            ) : (
              <NoData
                handleClick={item?.handleRefresh}
                btnText={t("refresh")}
                title={
                  item?.content?.isDataConcealed
                    ? t("data-concealed-for-privacy")
                    : `${t("not-enough-data-for")} ${
                        pastTimeLabel?.description
                      }`
                }
              />
            )}
          </div>
        )}
      </div>
    </CustomCard>
  );
};
