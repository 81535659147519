import { useGetTopChallenges } from "features/company-dashboard/hooks/useGetTopChallenges";
import { ContentDetailProps } from "features/company-dashboard/types";
import {
  getFetchingStatus,
  getFormattedDateRanges,
} from "features/company-dashboard/utils";
import {
  UserFilters,
  useGetChallengeConfigQuery,
} from "graphql/_generated/graphql";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export interface TopChallengesProps {
  item: any;
  userFilters: UserFilters;
}

export const useTopChallengesViewModel = ({
  item,
  userFilters,
}: TopChallengesProps) => {
  const [challengeData, setChallengeData] = useState<ContentDetailProps>({
    isDataConcealed: false,
    data: [],
  });
  const { t } = useTranslation();

  const { handleGetTopChallenges, isLoading } = useGetTopChallenges();
  const { refetch: fetchChallenges } = useGetChallengeConfigQuery();

  useEffect(() => {
    const isFetching = getFetchingStatus(item.selectedValues);
    if (isFetching) getChallengeData();
  }, [item.selectedValues, userFilters]);

  const getChallengeData = async () => {
    const { startingDate, endingDate } = getFormattedDateRanges(
      item.selectedValues,
    );

    const topChallenges = await handleGetTopChallenges({
      startDate: startingDate,
      endDate: endingDate,
      userFilters,
    });
    if (topChallenges) {
      const challengesConfig = await fetchChallenges();
      const mappedChallenges = topChallenges.items.length
        ? topChallenges.items.map((item, index) => ({
            id: index + 1,
            name:
              challengesConfig?.data?.getChallengeConfig?.options.find(
                (challenge) => challenge.id === item.id,
              )?.label || "",
            value: ((item.count / topChallenges.total) * 100).toFixed(0) || "",
          }))
        : [];
      setChallengeData({
        isDataConcealed: topChallenges?.isDataConcealed,
        data: mappedChallenges,
      });
    }
  };

  const handleRefresh = () => {
    getChallengeData();
  };

  const infoTexts = [
    t("find-popular-challenges"),
    t("presents-top-challenges"),
    t("understand-challenges-resonate"),
    t("promote-engagement-initiatives"),
  ];
  return {
    infoTexts,
    handleRefresh,
    challengeData,
    isLoading,
  };
};
