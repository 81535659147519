import { Popconfirm, PopconfirmProps } from "antd";
import * as React from "react";

interface CustomPopconfirmProps extends PopconfirmProps {
  children?: React.ReactNode;
}

const CustomPopconfirm: React.FunctionComponent<CustomPopconfirmProps> = (
  props
) => {
  return (
    <Popconfirm {...props} placement="left">
      {props.children}
    </Popconfirm>
  );
};

export default CustomPopconfirm;
