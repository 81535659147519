import { Col, Row } from "antd";
import { FunctionComponent, useEffect, useState } from "react";
import styles from "features/challenge/css/AddActivity.module.scss";
import CollapseAble from "features/assessment/questions/collapseables";
import ContainerHeading from "features/assessment/questions/ContainerHeading";
import UsersLeaderboard from "./UsersLeaderboard";
import UsersLeft from "./UsersLeft";
import UsersFailed from "./UsersFailed";
import ParticipationCompanies from "./ParticipationCompanies";
import { useLoggedInUser } from "hooks/useLoggedInUser";
import {
  ProgressStatus,
  useGetLeaderboardQuery,
  useGetParticipatingCompaniesQuery,
} from "graphql/_generated/graphql";

interface ChallengeDescriptionProps {
  challengeId: string;
}

const ParticipationDetails: FunctionComponent<ChallengeDescriptionProps> = ({
  challengeId,
}) => {
  const { isClient } = useLoggedInUser();

  const [companies, setCompanies] = useState<any>([]);
  const [leaderboards, setLeaderboards] = useState<any>([]);
  const [usersleft, setUsersleft] = useState<any>([]);
  const [usersFailed, setUsersFailed] = useState<any>([]);

  const { data: companiesData } = useGetParticipatingCompaniesQuery({
    entityId: challengeId,
    pagination: {
      limit: 5,
    },
  });

  const { data: leaderBoardsData } = useGetLeaderboardQuery({
    entityId: challengeId,
    pagination: {
      limit: 5,
    },
  });

  const { data: usersLeftData } = useGetLeaderboardQuery({
    entityId: challengeId,
    status: ProgressStatus.Left,
    pagination: {
      limit: 5,
    },
  });

  const { data: usersFailedData } = useGetLeaderboardQuery({
    entityId: challengeId,
    status: ProgressStatus.Failed,
    pagination: {
      limit: 5,
    },
  });

  useEffect(() => {
    companiesData?.getParticipatingCompanies?.data?.length &&
      setCompanies(companiesData?.getParticipatingCompanies?.data);
  }, [companiesData]);

  useEffect(() => {
    leaderBoardsData?.getLeaderboard?.data?.length &&
      setLeaderboards(leaderBoardsData?.getLeaderboard?.data);
  }, [leaderBoardsData]);

  useEffect(() => {
    usersLeftData?.getLeaderboard?.data?.length &&
      setUsersleft(usersLeftData?.getLeaderboard?.data);
  }, [usersLeftData]);

  useEffect(() => {
    usersFailedData?.getLeaderboard?.data?.length &&
      setUsersFailed(usersFailedData?.getLeaderboard?.data);
  }, [usersFailedData]);

  return companies?.length ||
    leaderboards?.length ||
    usersleft?.length ||
    usersFailed?.length ? (
    <CollapseAble
      defaultActiveKey={["description"]}
      headerKey={"description"}
      className={styles["activity-card"]}
      header={
        <div className="d-flex">
          <ContainerHeading text="Participation Details" />
        </div>
      }
      hideExtra={true}
      expandIconPosition="end"
    >
      <div className={styles["content-card"]}>
        {companies?.length || leaderboards?.length ? (
          <Row gutter={[24, 24]}>
            {isClient || !companies?.length ? null : (
              <Col sm={leaderboards?.length ? 12 : 24}>
                <ParticipationCompanies
                  companies={companies}
                  challengeId={challengeId}
                />
              </Col>
            )}
            <Col sm={isClient || !companies?.length ? 24 : 12}>
              <UsersLeaderboard
                leaderboards={leaderboards}
                challengeId={challengeId}
              />
            </Col>
          </Row>
        ) : null}

        {usersleft?.length || usersFailed?.length ? (
          <Row gutter={[24, 24]}>
            {usersleft?.length ? (
              <Col sm={usersFailed?.length ? 12 : 24}>
                <UsersLeft usersleft={usersleft} challengeId={challengeId} />
              </Col>
            ) : null}

            {usersFailed?.length ? (
              <Col sm={usersleft?.length ? 12 : 24}>
                <UsersFailed
                  usersFailed={usersFailed}
                  challengeId={challengeId}
                />
              </Col>
            ) : null}
          </Row>
        ) : null}
      </div>
    </CollapseAble>
  ) : null;
};
export default ParticipationDetails;
