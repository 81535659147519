import {
  AssignToTeamsManuallyMutationVariables,
  useAssignToTeamsManuallyMutation,
} from "graphql/_generated/graphql";

export const useAssignToTeamsManually = () => {
  const [assignToTeamsFun, { isLoading: isLoadingAssignToTeams }] =
    useAssignToTeamsManuallyMutation();

  const handleAssignToTeams = async (
    values: AssignToTeamsManuallyMutationVariables,
  ) => {
    try {
      const res = await assignToTeamsFun(values).unwrap();
      return res.assignToTeamsManually;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleAssignToTeams,
    isLoadingAssignToTeams,
  };
};
