import React from "react";
import styles from "../../CompanyDashboard.module.scss";

import { formatGraphValues } from "features/company-dashboard/utils";
import { renderHtmlContent } from "helpers";
import {
  CartesianGrid,
  Line,
  LineChart as ReChartLineGraph,
  ResponsiveContainer,
  Text,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { IXAxisProps, IYAxisProps, LineChartProps } from "../types";

export const LineChart: React.FC<LineChartProps> = ({ lineChartConfig }) => {
  const CustomXAxisTick = ({ payload, x, y }: IXAxisProps) => {
    const {
      xData: { customXTick, width, className },
    } = lineChartConfig;
    return (
      <foreignObject x={x - customXTick} y={y - 5} width={width} height={30}>
        <div className={`${styles["metrices-linechart-label"]} ${className}`}>
          {payload.value}
        </div>
      </foreignObject>
    );
  };

  const CustomYAxisTick = (tickObject: IYAxisProps) => {
    const {
      payload: { value },
    } = tickObject;
    tickObject["className"] = styles["y-axis-value"];
    const {
      yData: { unit },
    } = lineChartConfig;
    return (
      <Text {...tickObject}>{`${formatGraphValues(value, unit, false)}`}</Text>
    );
  };

  const CustomTooltip = ({
    active,
    payload,
    label,
  }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      const {
        xData,
        yData: { unit },
      } = lineChartConfig;
      return (
        <div className={styles["tooltip-container"]}>
          {xData.isEmojiBar ? (
            <div style={{ fontSize: 20 }}>
              {renderHtmlContent(`&#x${label};`)}
            </div>
          ) : (
            <div className={styles["barchart-label"]}>{label}</div>
          )}
          <div className={styles["tooltip-value"]}>{`${formatGraphValues(
            payload[0].value,
            unit,
            true,
          )}`}</div>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer>
      <ReChartLineGraph data={lineChartConfig.data}>
        <Tooltip cursor={false} content={<CustomTooltip />} />
        <CartesianGrid strokeDasharray="3 3 3" />
        <XAxis
          dataKey={lineChartConfig.xData.key}
          tick={(tickObject) => CustomXAxisTick(tickObject)}
          tickLine={false}
          // scale="band"
          interval={lineChartConfig.xData.interval}
          padding={{ left: 20, right: 20 }}
          stroke="#808080"
        />
        <YAxis
          tickLine={false}
          domain={lineChartConfig.yData.domain}
          stroke="#808080"
          yAxisId="left"
          interval={0}
          tick={(tickObject) => CustomYAxisTick(tickObject)}
        />
        <YAxis
          yAxisId="right"
          stroke="#808080"
          orientation="right"
          tickLine={false}
        />
        <Line
          type="linear"
          yAxisId="left"
          dataKey={lineChartConfig.yData.key}
          stroke={lineChartConfig.lineColor}
          fill={lineChartConfig.lineColor}
        />
      </ReChartLineGraph>
    </ResponsiveContainer>
  );
};
