import {
  MutationUpdatePartnerArgs,
  useGetPartnerDetailsQuery,
  useGetPartnerListQuery,
  useUpdatePartnerMutation,
} from "graphql/_generated/graphql";

export const useUpdatePartner = () => {
  const [updatePartnerFun, { isLoading }] = useUpdatePartnerMutation();
  const { refetch } = useGetPartnerListQuery();

  const handleUpdatePartner = async (values: MutationUpdatePartnerArgs) => {
    try {
      await updatePartnerFun({
        update: values.update,
        updatePartnerId: values.id,
      }).unwrap();
      refetch();
    } catch (error) {
      throw error;
    }
  };

  return {
    handleUpdatePartner,
    isLoading,
  };
};
