import * as React from "react";
import styles from "features/rewards/css/TierStats.module.scss";
import CompanyStats from "./CompanyStats";
import UserStats from "./UserStats";
interface TierStatsProps {
  tierId: string;
}

const TierStats: React.FunctionComponent<TierStatsProps> = ({ tierId }) => {
  return (
    <div className={styles["container"]}>
      <div className={styles["grid"]}>
        <CompanyStats tierId={tierId} />
        <UserStats tierId={tierId} />
      </div>
    </div>
  );
};

export default TierStats;
