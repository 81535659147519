import styles from "./SubQuestions.module.scss";
import Icon from "@ant-design/icons";
import * as React from "react";
import { Form, Formik } from "formik";
import { Divider, Space, Select } from "antd";
import TextOptions from "../text-options/TextOptions";
import RatingOptions from "../rating-options/RatingOptions";
import SliderOptions from "../slider-options/SliderOptions";
import BooleanOptions from "../boolean-options/BooleanOptions";
import QuestionOptions from "../question-options/QuestionOptions";
import {
  DeleteOutlined,
  EditFilled,
  SaveFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import DeleteBtn from "components/custom-button/DeleteBtn";
import { CustomSwitch } from "components/form/custom-checkbox/CustomSwitch";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { questionTypes } from "../Question/questionTypes";
import IAssessmentPillarQuestion from "features/assessment/types/IAssessmentPillarQuestion";
import EnumBoolean from "features/assessment/types/EnumBoolean";
import CustomPopconfirm from "components/form/custom-pop-confirm/CustomPopConfirm";
import { customNotification } from "components/custom-notification/CustomNotification";
import { errorFormat } from "helpers";
import MetricOptions from "../metric-options/MetricOptions";
import MatrixTransformer from "features/assessment/transformers/MatrixTransformer";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import {
  assessmentQuestionSchema,
  sliderOptionsRangeValidation,
} from "validations/schema/assessmentSchema";
import EnumQuestionTypes from "features/assessment/types/EnumQuestionTypes";
import { sortableQuestionTypes } from "features/assessment/data/sortableTypes";
import { useTranslation } from "react-i18next";
import { useErrorHandler } from "hooks/useErrorHandler";

interface SubQuestionsProps extends IAssessmentPillarQuestion {
  values: any;
  tags: any;
  pillarId: any;
  assessmentId: any;
  parentOptions?: any;
  addRow: (key: string, arr: any) => void;
  deleteSubQuestion: (key: string, subQuestionId: string) => void;
  setFieldValue: (key: string, value: any) => void;
  isDisabled?: boolean;
  saveQuestion: (values: any, subQuestion?: boolean) => void;
  getTypeBasedOptions: (type: string, question: any) => any;
  refetchQuestion?: (props: any) => any;
  questionId: string;
  isAddLanguage?: boolean;
  oldRecord?: any;
  resetField?: any;
  index?: number;
  parentId?: number;
  assessmentSettings?: any;
  isParentQuestionSavedOnServer?: boolean;
}

const SubQuestions: React.FunctionComponent<SubQuestionsProps> = (props) => {
  const { Option } = Select;
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const [initialState, setInitialState] = React.useState<any>({});
  const [editMode, setEditMode] = React.useState<boolean>(true);
  const [isQuestionSaving, activateSaveMode] = React.useState<boolean>(false);
  const [deleteOptionId, setDeleteOptionId] = React.useState<Array<string>>([]);
  const [sliderRangeError, setSliderRangeError] = React.useState<string>("");

  const toggleEditMode = () => setEditMode(!editMode);

  React.useEffect(() => {
    setInitialState(props.values);
  }, []);
  // console.log("in Sub Question-----------------");

  React.useEffect(() => {
    if (props?.oldRecord) {
      resetTranslationFields();
    }
  }, []);

  const resetTranslationFields = () => {
    if (props?.values) {
      props?.resetField(
        `pillars.${props?.pillarId}.questions[${props?.parentId}].subQuestions[${props?.index}].text`,
        props?.values?.translations?.text,
      );
      props?.resetField(
        `pillars.${props?.pillarId}.questions[${props?.parentId}].subQuestions[${props?.index}].subText`,
        props?.values?.translations?.subText,
      );

      props?.values.options.map((item: any, optIndex: number) => {
        props?.resetField(
          `pillars.${props?.pillarId}.questions[${props?.parentId}].subQuestions[${props?.index}].options[${optIndex}].text`,
          props?.values?.options?.[optIndex]?.translations?.text,
        );
        props?.resetField(
          `pillars.${props?.pillarId}.questions[${props?.parentId}].subQuestions[${props?.index}].options[${optIndex}].subText`,
          props?.values?.options?.[optIndex]?.translations?.subText,
        );
      });
    }
  };
  // console.log(
  //   "isParentQuestionSavedOnServer:>",
  //   props?.isParentQuestionSavedOnServer,
  // );

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={true}
      initialValues={props.values}
      validationSchema={
        props?.isAddLanguage
          ? null
          : assessmentQuestionSchema(props?.assessmentSettings)
      }
      onSubmit={async (values, { setValues }) => {
        const response =
          values.optionType === EnumQuestionTypes.SLIDER
            ? sliderOptionsRangeValidation(values)
            : false;

        if (!!response) {
          setSliderRangeError(response);
        } else {
          setSliderRangeError("");
          let options =
            props?.getTypeBasedOptions(values?.optionType, values) || [];
          if (values?.optionType === "matrix") {
            options = MatrixTransformer({
              values,
            });
          } else {
            options = options?.map((optItem: any, index: number) => {
              return {
                score: optItem?.score || 0,
                text: optItem?.text?.toString() || "",
                subText: optItem?.subText?.toString() || "",
                emoji: values?.isEmojiEnable ? optItem?.emoji || null : null,
                optionSortOrder: sortableQuestionTypes.includes(
                  values?.optionType,
                )
                  ? index
                  : undefined,
                start: optItem?.start || undefined,
                end: optItem?.end || undefined,
                ratingNumber: optItem?.ratingNumber || undefined,
                id:
                  optItem?.savedOnServer === EnumBoolean.YES
                    ? optItem.id
                    : undefined,
                tags: optItem?.tags?.map((tagtItem: any) => {
                  return {
                    tagId: tagtItem,
                  };
                }),
              };
            });
          }

          let payload: any = {
            assessmentId: props?.assessmentId,
            parentOptionIds: values?.parentOptionId,
            optionType: values?.optionType,
            options,
            tagId: "",
            score: values?.score || 0,
            pillarId: props?.pillarId,
            text: values?.text?.toString() || "",
            subText: values?.subText || "",
            isEmojiEnable: values.isEmojiEnable,
            start: values?.start || undefined,
            end: values?.end || undefined,
            minChar: values?.minChar || undefined,
            maxChar: values?.maxChar || undefined,
          };

          if (props?.isAddLanguage) {
            const data = { ...payload, id: props?.values?.id };
            props.saveQuestion(data, true);
          } else {
            if (values.savedOnServer === EnumBoolean.YES) {
              payload = {
                id: values?.id,
                update: {
                  text: values?.text?.toString() || "",
                  subText: values?.subText || "",
                  score: values?.score || 0,
                  options: options,
                  deleteOptionId: deleteOptionId,
                  deleteTagId: [],
                  isEmojiEnable: values.isEmojiEnable,
                  start: values?.start || undefined,
                  end: values?.end || undefined,
                  minChar: values?.minChar || undefined,
                  maxChar: values?.maxChar || undefined,
                },
              };
            }
            activateSaveMode(true);
            const response: any = await props.saveQuestion(payload);
            if (
              !!props?.refetchQuestion &&
              (response ||
                (values.savedOnServer === EnumBoolean.YES && values?.id))
            ) {
              const savedQuestion = await props?.refetchQuestion({
                questionId:
                  values.savedOnServer === EnumBoolean.YES
                    ? values?.id
                    : response?.createQuestion?.id,
                values,
              });
              if (!!savedQuestion) {
                setValues(savedQuestion);
              }
            }

            activateSaveMode(false);
          }

          toggleEditMode();
        }
      }}
    >
      {({
        values,
        setFieldValue,
        setValues,
        errors,
        validateOnChange,
        handleSubmit,
      }) => {
        const addRow = (key: string) => {
          setFieldValue(key, props.addRow(key, values?.[key]));
        };

        const deleteRow = (key: string, id: string) => {
          const deletedOption = values?.[key]?.find(
            (item: any) => item.id === id,
          );
          setFieldValue(
            key,
            values?.[key]?.filter((item: any) => item.id !== deletedOption.id),
          );

          if (deletedOption?.savedOnServer === EnumBoolean.YES) {
            setDeleteOptionId([...deleteOptionId, deletedOption?.id]);
          }
        };

        const handleEdit = (e: any) => {
          if (editMode) {
            e.preventDefault();
            handleSubmit();
          } else {
            toggleEditMode();
          }
        };

        return (
          <Form className={styles["assessment-create-form"]}>
            <Divider />
            <div className={styles["subQuestion-header"]}>
              {/* <div className={styles["sub-title"]}>Sub Q{values?.SN}</div> */}
              <div className={styles["sub-title"]}>{t("sub-question")}</div>

              <div className={styles["question-configs"]}>
                {/*
              # Sub Questions dont have Categories or Area
              # Sub Questions have options dropdown from main question.
            */}

                <CustomSelectField
                  placeholder={t("option")}
                  name={"parentOptionId"}
                  // allowClear
                  mode="multiple"
                  options={props?.parentOptions?.map((opt: any) => ({
                    label: opt.text || opt.SN,
                    value: opt.id,
                  }))}
                  disabled={
                    props?.isDisabled ||
                    !editMode ||
                    values?.savedOnServer === EnumBoolean.YES ||
                    props?.isAddLanguage
                  }
                />

                <CustomSelectField
                  defaultValue="singleChoice"
                  placeholder={t("question-type")}
                  name={`optionType`}
                  disabled={
                    props?.isDisabled ||
                    !editMode ||
                    values?.savedOnServer === EnumBoolean.YES ||
                    props?.isAddLanguage
                  }
                >
                  {questionTypes.map((item, index) => (
                    <Option key={index} value={item.value}>
                      <Space align="center">
                        <Icon component={item.icon} />
                        <div>{item.label}</div>
                      </Space>
                    </Option>
                  ))}
                </CustomSelectField>
                {values.optionType !== "text" &&
                  values.optionType !== "rating" && (
                    <Space>
                      <CustomSwitch
                        name={`isEmojiEnable`}
                        className="custom-switch"
                        disabled={
                          props?.isDisabled || !editMode || props?.isAddLanguage
                        }
                      />
                      <div className={styles["emojis"]}>{t("emojis")}</div>
                    </Space>
                  )}
              </div>

              <div>
                <DeleteBtn
                  disabled={
                    props?.isDisabled ||
                    isQuestionSaving ||
                    !props?.isParentQuestionSavedOnServer
                  }
                  style={{ marginRight: "10px" }}
                  onClick={handleEdit}
                >
                  {!editMode ? (
                    <EditFilled />
                  ) : isQuestionSaving ? (
                    <LoadingOutlined />
                  ) : (
                    <SaveFilled />
                  )}
                </DeleteBtn>

                {props?.isAddLanguage ? null : (
                  <CustomPopconfirm
                    onConfirm={async () => {
                      try {
                        props.deleteSubQuestion("subQuestions", values.id);
                      } catch (error) {
                        handleError(error as string | object);
                      }
                    }}
                    title={t("confirm-delete-question")}
                  >
                    <DeleteBtn
                      disabled={
                        props?.isDisabled || !editMode || isQuestionSaving
                      }
                    >
                      <DeleteOutlined />
                    </DeleteBtn>
                  </CustomPopconfirm>
                )}
              </div>
            </div>

            <div className={`${styles["question-title"]}`}>
              <CustomTextField
                disabled={props?.isDisabled || !editMode || isQuestionSaving}
                name="text"
                placeholder={t("type-question-here")}
                isAddLanguage={props?.isAddLanguage}
                oldTextValue={props?.oldRecord?.text}
              />

              {props?.assessmentSettings?.isScoresEnable ? (
                <CustomTextField
                  disabled={
                    props?.isDisabled ||
                    !editMode ||
                    isQuestionSaving ||
                    props?.isAddLanguage
                  }
                  name="score"
                  type="number"
                  placeholder={t("score")}
                />
              ) : null}
            </div>

            {values?.optionType === "ranking" ||
            values?.optionType === "singleChoice" ||
            values?.optionType === "multipleChoice" ? (
              <QuestionOptions
                tags={props?.tags}
                values={values}
                addOption={(e: any) => addRow(e)}
                deleteOption={(key: string, id: string) => deleteRow(key, id)}
                isDisabled={props?.isDisabled || !editMode}
                isAddLanguage={props?.isAddLanguage}
                oldRecord={props?.oldRecord}
                isSubQuestion={true}
                assessmentSettings={props?.assessmentSettings}
              />
            ) : values?.optionType === "text" ? (
              <TextOptions
                values={values}
                isDisabled={props?.isDisabled || !editMode}
                isSubQuestion={true}
              />
            ) : values?.optionType === "rating" ? (
              <RatingOptions
                tags={props?.tags}
                values={values}
                addRating={(e: any) => addRow(e)}
                deleteRating={(key: string, id: string) => deleteRow(key, id)}
                setFieldValue={setFieldValue}
                isDisabled={props?.isDisabled || !editMode}
                isSubQuestion={true}
                assessmentSettings={props?.assessmentSettings}
              />
            ) : values?.optionType === "slider" ? (
              <SliderOptions
                tags={props?.tags}
                values={values}
                addStep={(e: any) => addRow(e)}
                deleteStep={(key: string, id: string) => deleteRow(key, id)}
                isDisabled={props?.isDisabled || !editMode}
                isSubQuestion={true}
                rangeError={sliderRangeError}
                assessmentSettings={props?.assessmentSettings}
              />
            ) : values?.optionType === "boolean" ? (
              <BooleanOptions
                tags={props?.tags}
                values={values}
                addBoolean={(e: any) => addRow(e)}
                deleteBoolean={(key: string, id: string) => deleteRow(key, id)}
                isDisabled={props?.isDisabled || !editMode}
                isSubQuestion={true}
                assessmentSettings={props?.assessmentSettings}
              />
            ) : values?.optionType === "matrix" ? (
              <MetricOptions
                tags={props?.tags}
                values={values}
                setValues={setValues}
                isDisabled={!editMode}
                setDeleteOptionIds={setDeleteOptionId}
                deletedOptionIds={deleteOptionId}
                isAddLanguage={props?.isAddLanguage}
              />
            ) : null}
          </Form>
        );
      }}
    </Formik>
  );
};

export default SubQuestions;
