import CheckPermissions from "components/check-permissions/CheckPermissions";
import { AppstoreOutlined, BarsOutlined } from "@ant-design/icons";
import CustomButton from "components/custom-button/CustomButton";
import { PERMS } from "helpers";
import CustomSegment from "components/segment/CustomSegment";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ContentList from "features/content/content/content-list/ContentList";
import styles from "features/content/css/ContentDetail.module.scss";
import { useNavigate } from "react-router-dom";
import ContentCardList from "features/content/content/content-list/ContentCardList";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
type Props = {};

const Content = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [view, setView] = useState<string | number | null>("list");
  const handleChange = (value: string | number) => {
    setView(value);
  };
  const options = [
    {
      value: "list",
      label: "List view",
      icon: <BarsOutlined />,
    },
    {
      label: "card View",
      value: "card",
      icon: <AppstoreOutlined />,
    },
  ];
  return (
    <>
      <DashboardLayout openKey={["Content"]}>
        <Breadcrumbs
          data={[
            {
              label: "Content",
            },
            {
              label: t("contents"),
            },
          ]}
        />
        <div className={styles["content"]}>
          <h1 className={styles["title"]}>{t("contents")}</h1>
        </div>
        <div className={styles["content-view"]}>
          <CustomSegment options={options} onChange={handleChange} />
        </div>
        {view === "list" ? <ContentList /> : <ContentCardList />}
      </DashboardLayout>
    </>
  );
};

export default Content;
