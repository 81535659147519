import * as Yup from "yup";
import * as Validations from "validations/schema/common/validations";

export const addHabitSchema = Yup.object().shape({
  title: Validations.requiredFieldWithoutSpaces("title"),
  description: Validations.requiredFieldWithoutSpaces("description"),
  pillarId: Validations.requiredFieldWithoutSpaces("pillar"),
  target:
    Validations.requiredFieldPositiveNumber("target").integer(
      "error:only-integer",
    ),
  unitId: Validations.requiredFieldWithoutSpaces("unit"),
  repetition: Validations.requiredFieldWithoutSpaces("repetition"),
});
