import {
  useCreateAssessmentCarousalMutation,
  MutationCreateAssessmentCarousalArgs,
} from "graphql/_generated/graphql";

export const useCreateAssessmentCarousal = () => {
  const [createAssessmentCarousalFun, { isLoading }] =
    useCreateAssessmentCarousalMutation();

  const handleCreateAssessmentCarousalFun = async (
    values: MutationCreateAssessmentCarousalArgs,
  ) => {
    try {
      const res = await createAssessmentCarousalFun({
        create: values.create,
      }).unwrap();

      return res.createAssessmentCarousal;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleCreateAssessmentCarousalFun,
    isLoadingCreateAssessment: isLoading,
  };
};
