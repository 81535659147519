import { useAddStreak } from "./useAddStreak";
import { useGetStreaks } from "./useGetStreaks";

export const useStreak = () => {
  const { handleAddStreak, isLoading } = useAddStreak();
  const { streaksData, refetch, isLoading: isLoadingStreaks } = useGetStreaks();

  return {
    handleAddStreak,
    streaksData,
    refetchStreaks: refetch,
    isLoading: isLoading || isLoadingStreaks,
  };
};
