import { Typography } from "antd";
import noData from "assets/images/no-data.svg";
import CustomButton from "components/custom-button/CustomButton";
import { useTranslation } from "react-i18next";
import styles from "../CompanyDashboard.module.scss";

const { Text } = Typography;
interface NoDataProps {
  title?: string;
  btnText?: string;
  handleClick?: () => void;
}

export const NoData: React.FC<NoDataProps> = ({
  title,
  btnText,
  handleClick,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles["no-data-container"]}>
      <div className={styles["no-data"]} style={{ display: "flex" }}>
        <img className={styles["no-data-img"]} src={noData} alt="No Data" />
        <Text className={styles["no-data-title"]}>
          {title ?? t("no-enough-data")}
        </Text>
        <CustomButton onClick={handleClick} className={styles["no-data-btn"]}>
          {btnText}
        </CustomButton>
      </div>
    </div>
  );
};
