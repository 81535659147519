import {
  MutationUpdateWellBeingCalenderArgs,
  useUpdateWellBeingCalenderMutation,
} from "graphql/_generated/graphql";

export const useUpdateWellBeingCalender = () => {
  const [updateWellBeingCalenderFun, { isLoading }] =
    useUpdateWellBeingCalenderMutation();

  //this function is responsible for updating the WellBeingCalender
  const handleUpdateWellBeingCalender = async (
    values: MutationUpdateWellBeingCalenderArgs,
  ) => {
    try {
      await updateWellBeingCalenderFun({
        updateWellBeingCalenderId: values.id,
        update: values.update,
      }).unwrap();
    } catch (error) {
      throw error;
    }
  };
  return {
    isLoading,
    handleUpdateWellBeingCalender,
  };
};
