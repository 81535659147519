import styles from "./AssessmentSettings.module.scss";
import { Col, Divider, Row, Space } from "antd";
import CustomDivider from "components/custom-divider/CustomDivider";
import { CustomDatePicker } from "components/form/custom-date-picker/CustomDatePicker";
import { CustomRadioGroup } from "components/form/custom-radio-group/CustomRadioGroup";
import ContainerHeading from "features/assessment/questions/ContainerHeading";
import { InfoIcon } from "assets/icons/InfoIcon";
import { CustomSwitch } from "components/form/custom-checkbox/CustomSwitch";
import { Fragment } from "react";
import AssessmentSettingNotifications from "./AssessmentSettingNotifications";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { WearableDataType } from "graphql/_generated/graphql";
import { useTranslation } from "react-i18next";

type Props = {
  values: any;
  setFieldValue: any;
};

export const AssessmentSettingsCommon = ({ values, setFieldValue }: Props) => {
  const { t } = useTranslation();
  const wearableDataTypes = Object.values(WearableDataType)?.map(
    (key: string) => ({ label: key.toUpperCase(), value: key }),
  );

  return (
    <>
      {values?.assessmentSettingId?.isCategoriesVisible && (
        <CustomRadioGroup
          label={t("enable-categories")}
          subLabel={t("user-will-see-categories")}
          name="assessmentSettingId.isCategoriesEnable"
          className="label"
          options={[
            {
              label: t("yes"),
              value: true,
            },
            {
              label: t("no"),
              value: false,
            },
          ]}
        />
      )}
      {values?.assessmentSettingId?.isContentAssociationVisible && (
        <CustomRadioGroup
          label={t("enable-content-association")}
          subLabel={t("user-will-see-content-association")}
          name="assessmentSettingId.isContentAssociationEnable"
          className="label"
          options={[
            {
              label: t("yes"),
              value: true,
            },
            {
              label: t("no"),
              value: false,
            },
          ]}
        />
      )}
      {values?.assessmentSettingId?.isGroupingVisible && (
        <CustomRadioGroup
          label={t("enable-grouping")}
          subLabel={t("user-will-see-grouping")}
          name="assessmentSettingId.isGroupingEnable"
          className="label"
          options={[
            {
              label: t("yes"),
              value: true,
            },
            {
              label: t("no"),
              value: false,
            },
          ]}
        />
      )}
      {values?.assessmentSettingId?.isMandatoryVisible && (
        <CustomRadioGroup
          label={t("mandatory")}
          subLabel={t("users-have-to-complete-assessment")}
          name="assessmentSettingId.isMandatory"
          className="label"
          options={[
            {
              label: t("yes"),
              value: true,
            },
            {
              label: t("no"),
              value: false,
            },
          ]}
        />
      )}
      {/* {values?.assessmentSettingId?.isNotificationsVisible && (
    <CustomRadioGroup
      label={t("enable-notifications")}
      subLabel={t("users-will-see-notifications")}
      name="assessmentSettingId.isNotificationsEnable"
      className="label"
      options={[
        {
          label: t("yes"),
          value: true,
        },
        {
          label: t("no"),
          value: false,
        },
      ]}
    />
  )} */}
      {values?.assessmentSettingId?.isPillarsVisible && (
        <CustomRadioGroup
          label={t("enable-pillars")}
          subLabel={t("users-will-see-pillars")}
          name="assessmentSettingId.isPillarsEnable"
          className="label"
          options={[
            {
              label: t("yes"),
              value: true,
            },
            {
              label: t("no"),
              value: false,
            },
          ]}
        />
      )}
      {values?.assessmentSettingId?.isScoresVisible && (
        <CustomRadioGroup
          label={t("enable-scores")}
          subLabel={t("users-will-see-scores")}
          name="assessmentSettingId.isScoresEnable"
          className="label"
          options={[
            {
              label: t("yes"),
              value: true,
            },
            {
              label: t("no"),
              value: false,
            },
          ]}
        />
      )}
      {values?.assessmentSettingId?.isSkippableVisible && (
        <CustomRadioGroup
          label={t("skippable")}
          subLabel={t("users-will-see-the-skip-section")}
          name="assessmentSettingId.isSkippable"
          className="label"
          options={[
            {
              label: t("yes"),
              value: true,
            },
            {
              label: t("no"),
              value: false,
            },
          ]}
        />
      )}
      {values?.assessmentSettingId?.isSubQuestionsVisible && (
        <CustomRadioGroup
          label={t("enable-subquestions")}
          subLabel={t("users-will-see-the-subquestions")}
          name="assessmentSettingId.isSubQuestionsEnable"
          className="label"
          options={[
            {
              label: t("yes"),
              value: true,
            },
            {
              label: t("no"),
              value: false,
            },
          ]}
        />
      )}
      {values?.assessmentSettingId?.isTagsVisible && (
        <CustomRadioGroup
          label={t("enable-tags")}
          subLabel={t("users-will-see-tags")}
          name="assessmentSettingId.isTagsEnable"
          className="label"
          options={[
            {
              label: t("yes"),
              value: true,
            },
            {
              label: t("no"),
              value: false,
            },
          ]}
        />
      )}
      {values?.assessmentSettingId?.isExpiryDateVisible && (
        <CustomRadioGroup
          label={t("enable-expiry-date")}
          subLabel={t("user-will-see-content-expiry-date")}
          name="assessmentSettingId.isExpiryDateEnable"
          className="label"
          options={[
            {
              label: t("yes"),
              value: true,
            },
            {
              label: t("no"),
              value: false,
            },
          ]}
        />
      )}
      {values?.assessmentSettingId?.isExpiryDateEnable &&
        values?.assessmentSettingId?.isExpiryDateVisible && (
          <>
            <CustomDivider />
            <ContainerHeading text={t("dates")} icon={InfoIcon} />
            <CustomDatePicker
              placeholder={t("select-expiry-date")}
              subLabel={t("date")}
              name="expiryDate"
            />
          </>
        )}
      {values?.assessmentSettingId?.isWearableVisible && (
        <>
          <Divider />
          <Fragment>
            <ContainerHeading text={t("wearable-data")} icon={InfoIcon} />
            <p className="subLabel">
              {t("turn-on-off-wearable-data-for-check-in-assessment")}
            </p>
            <div className={styles["enable-notification"]}>
              <ContainerHeading text={t("enable-wearable-data")} />

              <CustomSwitch
                name="assessmentSettingId.isWearableEnable"
                className="custom-switch"
              />
            </div>
            <Space className="assessmentType__form__language wearableRow--switch">
              <CustomSelectField
                name="assessmentSettingId.wearableDataType"
                options={wearableDataTypes}
                label={t("integration-type")}
                placeholder={t("select-type")}
                disabled={!values.assessmentSettingId?.isWearableEnable}
              />
            </Space>
          </Fragment>
        </>
      )}

      {values?.assessmentSettingId?.isNotificationsVisible && (
        <>
          <div className={styles["enable-notification"]}>
            <ContainerHeading text={t("notifications")} icon={InfoIcon} />
            <CustomSwitch
              name="assessmentSettingId.isNotificationsEnable"
              className="custom-switch"
            />
          </div>
          <div className={styles["block"]}>
            <AssessmentSettingNotifications
              setFieldValue={setFieldValue}
              values={values}
            />
          </div>
        </>
      )}
    </>
  );
};
