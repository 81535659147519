import Icon from "@ant-design/icons";
import PlusCircleFilled from "@ant-design/icons/PlusCircleFilled";
import { Space } from "antd";
import Search from "antd/lib/input/Search";
import { SearchIcon } from "assets/icons/SearchIcon";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import CustomButton from "components/custom-button/CustomButton";
import CustomTabs from "components/custom-tabs/CustomTabs";
import CustomLanguageSelector from "components/form/custom-language-selector/CustomLanguageSelector";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import styles from "features/rewards/css/AddStreak.module.scss";
import { useDailyQuestViewModal } from "features/rewards/hooks/useDailyQuestViewModal";
import AddQuest from "features/rewards/quests/AddQuest";
import DailyQuestsList from "features/rewards/quests/DailyQuestsList";
import AddStreak from "features/rewards/streaks/AddStreak";
import { FormikProps } from "formik";
import { StreakCreate } from "graphql/_generated/graphql";
import { PERMS } from "helpers";
import useSearch from "hooks/useSearch";
import { debounce } from "lodash";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { batch } from "react-redux";
import { useNavigate } from "react-router-dom";

interface QuestsProps {}

const Quests: React.FunctionComponent<QuestsProps> = (props) => {
  const DEBOUNCE_TIME = 800;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState("daily-quest");
  const [parentLoading, setParentLoading] = useState(false);
  const { handleSearch, search } = useSearch();
  const vm = useDailyQuestViewModal();

  const handleTabChange = (activeKey: string) => {
    navigate("#tabs");
    setActiveKey(activeKey);
  };

  const formRef = useRef<FormikProps<StreakCreate>>(null);
  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleLoadingChange = (loading: boolean) => {
    // Update the parent component state when isLoading changes in AddStreak
    setParentLoading(loading);
  };

  const handleSearchInput = debounce((e) => {
    batch(() => {
      handleSearch(e);
    });
  }, DEBOUNCE_TIME);

  const getTabBarExtraContent = () => {
    if (activeKey === "streaks") {
      return (
        <div className={styles["streak-header"]}>
          <Space size={12}>
            <CustomButton
              onClick={() => formRef.current?.resetForm()}
              className="link-text-btn"
            >
              {t("cancel")}
            </CustomButton>
            <CheckPermissions permission={PERMS.ADD_STREAK}>
              <CustomButton
                onClick={handleSubmit}
                loading={parentLoading}
                className="primary-btn"
                htmlType="submit"
              >
                {t("save")}
              </CustomButton>
            </CheckPermissions>
          </Space>
        </div>
      );
    } else if (activeKey === "daily-quest") {
      return (
        <div className={styles["streak-header"]}>
          <div className={styles["flex-header"]}>
            <Search
              placeholder={"Search"}
              className="search-input"
              bordered={false}
              onChange={handleSearchInput}
              enterButton={<Icon component={SearchIcon} />}
              defaultValue={search?.search?.text}
            />
            <CheckPermissions permission={PERMS.ADD_QUEST}>
              <CustomButton
                onClick={vm.onPressAddQuest}
                icon={<PlusCircleFilled />}
                className="primary-btn"
              >
                {t("add-new-quest")}
              </CustomButton>
            </CheckPermissions>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <DashboardLayout openKey={["Rewards"]}>
        <Breadcrumbs
          data={[
            {
              label: t("rewards"),
            },
            {
              label: t(`${activeKey}`),
            },
          ]}
        />
        <div>
          <CustomTabs
            tabBarExtraContent={getTabBarExtraContent()}
            onChange={handleTabChange}
            items={[
              {
                label: t("sidebar.daily-quest"),
                key: "daily-quest",
                children: <DailyQuestsList />,
              },
              {
                label: t("streaks"),
                key: "streaks",
                children: (
                  <CheckPermissions permission={PERMS.GET_STREAKS}>
                    <AddStreak
                      formRef={formRef}
                      onLoadingChange={handleLoadingChange}
                    />
                  </CheckPermissions>
                ),
              },
            ]}
          />
        </div>
      </DashboardLayout>
      {vm.langVisibility && (
        <CustomLanguageSelector
          visible={vm.langVisibility}
          setVisible={vm.onCloseLangDialog}
          onSubmit={vm.onLanguageSubmit}
          title={t("quest-main-language")}
          label={t("quest")}
          saveText={t("add-quest")}
        />
      )}
      {vm.isAddQuest && (
        <AddQuest
          visible={vm.isAddQuest}
          editId={vm.editId}
          langCode={vm.langCode}
          isAddLanguage={vm.isAddLanguage}
          onCloseAddQuest={vm.onCloseAddQuest}
          onAddQuestSubmit={vm.onAddQuestSubmit}
          loading={vm.loading.isLoadingAddQuest}
          questTypes={vm.questTypesData}
        />
      )}
    </>
  );
};

export default Quests;
