import { MoreOutlined } from "@ant-design/icons";
import { Switch, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import CustomButton from "components/custom-button/CustomButton";
import CustomDropDown from "components/custom-dropdown/CustomDropDown";
import CustomMenu from "components/custom-menu/CustomMenu";
import CustomTable from "components/custom-table/CustomTable";
import CustomPopconfirm from "components/form/custom-pop-confirm/CustomPopConfirm";
import { EapList } from "graphql/_generated/graphql";
import moment from "moment";
import { useTranslation } from "react-i18next";
import AddEAP from "../add-eap/AddEAP";
import { useEAPListViewModel } from "../hooks/useEAPListViewModel";

const EAPsList: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const vm = useEAPListViewModel();

  const columns: ColumnsType<EapList["data"][0]> = [
    {
      title: t("id"),
      key: "id",
      dataIndex: "id",
      sorter: {
        multiple: 1,
      },
      render: (_text, _record, index) =>
        (vm.currentPage - 1) * vm.pageSize + index + 1,
    },
    {
      title: t("country-code"),
      key: "countryCode",
      dataIndex: "countryCode",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, index) => (record.isGlobal ? t("global") : _text),
    },
    {
      title: t("phone-number"),
      key: "number",
      dataIndex: "number",
    },
    {
      title: t("created-at"),
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: {
        multiple: 1,
      },
      render: (createdAt: string) => moment(createdAt).format("MMMM Do YYYY"),
    },
    {
      title: t("updated-at"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: {
        multiple: 1,
      },
      render: (updatedAt: string) => moment(updatedAt).format("MMMM Do YYYY"),
    },
    {
      title: t("status"),
      dataIndex: "isActive",
      key: "isActive",
      align: "center",
      render: (isActive: boolean) =>
        isActive ? (
          <Tag className="active-tag">{t("active")}</Tag>
        ) : (
          <Tag className="de-active-tag">{t("deactivated")}</Tag>
        ),
    },
    {
      title: t("activation"),
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (id: string, { isActive }: any) => (
        <CustomPopconfirm
          onConfirm={() => vm.handleActivationConfirm(id)}
          title={
            isActive ? t("confirm-deactivate-eap") : t("confirm-activate-eap")
          }
        >
          <Switch className="custom-switch" defaultChecked checked={isActive} />
        </CustomPopconfirm>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "action",
      align: "center",
      width: "10%",
      render: (id: string, record) => (
        <>
          <CustomDropDown
            overlay={
              <CustomMenu
                items={[{ label: t("edit-eap"), key: "edit-eap" }]}
                onClick={({ key }) => vm.handleMenu({ key, id, record })}
              />
            }
          >
            <CustomButton type="link" className="link-btn">
              <MoreOutlined />
            </CustomButton>
          </CustomDropDown>
        </>
      ),
    },
  ];

  return (
    <>
      <CustomTable
        filters={{
          handleCallback: vm.handleFilterCallback,
          date: [
            {
              applyAs: "and",
              key: "createdAt",
              comparison: "Range",
            },
          ],
        }}
        rowKey="id"
        loading={vm.isLoading}
        columns={columns}
        totalItems={vm.columnsData?.metadata.total}
        onClick={vm.handleAddEap}
        headerTitle={t("eap-title")}
        btnText={t("eap-add-new")}
        dataSource={vm.columnsData?.data}
      />
      <AddEAP
        visible={vm.visible}
        editId={vm.editId}
        isLoading={vm.isEapLoading}
        handleCancel={vm.handleCancel}
        handleSubmit={vm.handleSubmit}
        initialValues={vm.initialValues}
      />
    </>
  );
};

export default EAPsList;
