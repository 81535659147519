import * as React from "react";
import { Divider, DividerProps } from "antd";
import "./CustomDivider.module.scss";
interface CustomDividerProps extends DividerProps {}

const CustomDivider: React.FunctionComponent<CustomDividerProps> = (props) => {
  return <Divider className={props.className ?? "question-sep"} {...props} />;
};

export default CustomDivider;
