export const GlobalFlag = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="14"
      zoomAndPan="magnify"
      viewBox="0 0 30 30.000001"
      height="15"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <defs>
        <clipPath id="id1">
          <path
            d="M 8 5.785156 L 26.855469 5.785156 L 26.855469 12 L 8 12 Z M 8 5.785156 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="id2">
          <path
            d="M 8 11 L 26.855469 11 L 26.855469 18 L 8 18 Z M 8 11 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="id3">
          <path
            d="M 8 17 L 26.855469 17 L 26.855469 23.207031 L 8 23.207031 Z M 8 17 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="id4">
          <path
            d="M 2.90625 5.785156 L 9 5.785156 L 9 23.207031 L 2.90625 23.207031 Z M 2.90625 5.785156 "
            clipRule="nonzero"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#id1)">
        <path
          fill="rgb(2.349854%, 50.979614%, 25.489807%)"
          d="M 24.191406 5.785156 L 8.894531 5.785156 L 8.894531 11.816406 L 26.851562 11.816406 L 26.851562 8.464844 C 26.851562 6.984375 25.660156 5.785156 24.191406 5.785156 Z M 24.191406 5.785156 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#id2)">
        <path
          fill="rgb(93.328857%, 93.328857%, 93.328857%)"
          d="M 8.894531 11.816406 L 26.851562 11.816406 L 26.851562 17.175781 L 8.894531 17.175781 Z M 8.894531 11.816406 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#id3)">
        <path
          fill="rgb(7.839966%, 7.839966%, 7.839966%)"
          d="M 8.894531 23.207031 L 24.191406 23.207031 C 25.660156 23.207031 26.851562 22.003906 26.851562 20.527344 L 26.851562 17.175781 L 8.894531 17.175781 Z M 8.894531 23.207031 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#id4)">
        <path
          fill="rgb(92.549133%, 12.548828%, 15.689087%)"
          d="M 5.570312 5.785156 C 4.101562 5.785156 2.910156 6.984375 2.910156 8.464844 L 2.910156 20.527344 C 2.910156 22.003906 4.101562 23.207031 5.570312 23.207031 L 8.894531 23.207031 L 8.894531 5.785156 Z M 5.570312 5.785156 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};
