import {
  useBulkImportPartnerRewardsMutation,
  MutationBulkImportPartnerRewardsArgs,
  useGetPartnerRewardListQuery,
} from "graphql/_generated/graphql";

export const useImportBulkRewards = () => {
  const [importBulkRewardFun, { isLoading }] =
    useBulkImportPartnerRewardsMutation();

  const { refetch } = useGetPartnerRewardListQuery();

  const handleImportBulkRewards = async (
    values: MutationBulkImportPartnerRewardsArgs,
  ) => {
    try {
      const res = await importBulkRewardFun({
        createBulk: values.createBulk,
      }).unwrap();
      refetch();
      return res.bulkImportPartnerRewards;
    } catch (error) {
      throw error;
    }
  };
  return {
    handleImportBulkRewards,
    isLoading,
  };
};
