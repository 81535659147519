import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CONFIG } from "helpers/constants";

export interface CounterState {
  token: string | null;
  refreshToken: string | null;
  forgotEmail: string | null;
  region?: string;
}

const initialState: CounterState = {
  token: null,
  refreshToken: null,
  forgotEmail: null,
  region: CONFIG.REGIONAL_KEY,
};

export const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    setToken: (state, { payload }) => {
      if (payload.login) {
        state.token = payload.login.token;
        state.refreshToken = payload.login.refreshToken;
      } else {
        state.token = payload.token;
        state.refreshToken = payload.refreshToken;
      }
    },
    removeToken: (state) => {
      state.token = null;
      state.refreshToken = null;
    },
    forgotEmail: (state, { payload }) => {
      state.forgotEmail = payload.email;
    },
    setRegion: (state, { payload }) => {
      state.region = payload;
    },
    logout: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { setToken, removeToken, forgotEmail, setRegion, logout } =
  authSlice.actions;

export default authSlice.reducer;
