import CustomButton from "components/custom-button/CustomButton";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import AuthLayout from "features/auth/auth-layout/AuthLayout";
import styles from "features/auth/css/ForgotPassword.module.scss";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { forgotPasswordSchema } from "validations/schema";
import useForgotPasswordSubmit from "../../features/auth/hooks/useForgotPasswordViewModel";

const initialValues = {
  email: "",
};

const ForgotPassword = () => {
  const { t } = useTranslation();
  const { isLoading, onSubmit } = useForgotPasswordSubmit();

  return (
    <AuthLayout card id="forgotPasswordScreen">
      <h1 className={styles["forgot-title"]}>{t("recover-password")}</h1>
      <p className={styles["description"]}>{t("forgot-description1")}</p>
      <p className={styles["description"]}>{t("forgot-description2")}</p>
      <Formik
        initialValues={initialValues}
        validationSchema={forgotPasswordSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <CustomTextField
            name="email"
            label={t("email")}
            placeholder={t("please-enter-your-email")}
          />
          <div className={styles["btn-div"]}>
            <CustomButton
              id="forgotPassword"
              block
              className="primary-btn"
              htmlType="submit"
              loading={isLoading}
            >
              {t("forgot-password")}
            </CustomButton>
          </div>
        </Form>
      </Formik>
    </AuthLayout>
  );
};

export default ForgotPassword;
