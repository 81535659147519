import AddOption from "components/custom-button/AddOption";
import DeleteBtn from "components/custom-button/DeleteBtn";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import CustomTextEditor from "components/form/custom-text-editor/CustomTextEditor";
import {
  filterLanguagesWithout,
  getLabelFromValue,
} from "features/content/utils";
import { useLanguages } from "hooks/useLanguages";
import { FieldArray } from "formik";
import * as React from "react";
import styles from "features/documentation/css/DocumentationTranslations.module.scss";
import { DeleteFilled } from "@ant-design/icons";
import CustomDivider from "components/custom-divider/CustomDivider";
import { useTranslation } from "react-i18next";

interface DocumentationTranslationsProps {
  values: any;
  sectionName: string;
}

const DocumentationTranslations: React.FunctionComponent<
  DocumentationTranslationsProps
> = (props) => {
  const { values, sectionName } = props;
  const { languagesWithOutEn } = useLanguages();
  const { t } = useTranslation();
  const trans = values?.translations;

  return (
    <FieldArray
      name={`translations.${sectionName}`}
      render={({ push, remove }) => (
        <>
          {trans &&
            trans[sectionName]?.map((item: any, index: number) => (
              <div key={index}>
                <CustomDivider />
                <div className={styles["langs"]}>
                  <CustomSelectField
                    label={t("language")}
                    required={true}
                    value={getLabelFromValue(
                      languagesWithOutEn,
                      trans[sectionName][index].lang,
                    )}
                    name={`translations.${sectionName}.${index}.lang`}
                    placeholder={t("select-language")}
                    options={filterLanguagesWithout(languagesWithOutEn, [
                      ...(trans[sectionName] || []),
                    ])}
                    defaultValue={getLabelFromValue(
                      languagesWithOutEn,
                      trans[sectionName][index].lang,
                    )}
                  />
                  <div className={styles["delete-btn"]}>
                    <DeleteBtn onClick={() => remove(index)}>
                      <DeleteFilled />
                    </DeleteBtn>
                  </div>
                </div>
                <CustomTextEditor
                  label={t("enter-description", { language: t(sectionName) })}
                  name={`translations.${sectionName}.${index}.value`}
                />
              </div>
            ))}
          <div className={styles["add-translation"]}>
            <AddOption
              title={t("add-translation")}
              onClick={() =>
                push({
                  lang: "",
                  [sectionName]: "",
                })
              }
            />
          </div>
        </>
      )}
    />
  );
};

export default DocumentationTranslations;
