export const TextIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect opacity="0.24" width="24" height="24" rx="4" fill="#7573E1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4998 9.50001H17.1664C16.6144 9.50001 16.1664 9.12668 16.1664 8.66668C16.1664 8.20668 16.6144 7.83334 17.1664 7.83334H18.4998C19.0518 7.83334 19.4998 8.20668 19.4998 8.66668C19.4998 9.12668 19.0518 9.50001 18.4998 9.50001ZM14.4997 9.50005H6.16634C5.70634 9.50005 5.33301 9.12672 5.33301 8.66672C5.33301 8.20672 5.70634 7.83338 6.16634 7.83338H14.4997C14.9597 7.83338 15.333 8.20672 15.333 8.66672C15.333 9.12672 14.9597 9.50005 14.4997 9.50005ZM8.66642 12.8333H6.16642C5.70642 12.8333 5.33309 12.46 5.33309 12C5.33309 11.54 5.70642 11.1667 6.16642 11.1667H8.66642C9.12642 11.1667 9.49976 11.54 9.49976 12C9.49976 12.46 9.12642 12.8333 8.66642 12.8333ZM18.6664 12.8333H11.1664C10.7064 12.8333 10.3331 12.46 10.3331 12C10.3331 11.54 10.7064 11.1667 11.1664 11.1667H18.6664C19.1264 11.1667 19.4998 11.54 19.4998 12C19.4998 12.46 19.1264 12.8333 18.6664 12.8333ZM12.8331 16.1667C13.2931 16.1667 13.6664 15.7933 13.6664 15.3333C13.6664 14.8733 13.2931 14.5 12.8331 14.5H6.16642C5.70642 14.5 5.33309 14.8733 5.33309 15.3333C5.33309 15.7933 5.70642 16.1667 6.16642 16.1667H12.8331ZM15.4998 16.1667H18.4998C19.0518 16.1667 19.4998 15.7933 19.4998 15.3333C19.4998 14.8733 19.0518 14.5 18.4998 14.5H15.4998C14.9478 14.5 14.4998 14.8733 14.4998 15.3333C14.4998 15.7933 14.9478 16.1667 15.4998 16.1667Z"
        fill="#303030"
      />
    </svg>
  );
};
