import { Spin } from "antd";
import CustomCard from "components/custom-card/CustomCard";
import { PieChart } from "features/company-dashboard/charts/pie/PieChart";
import { useGetViwellAssessmentStats } from "features/company-dashboard/hooks/useGetViwellAssessmentStats";
import {
  AssessmentTypes,
  UserFilters,
  useGetDepartmentsConfigQuery,
} from "graphql/_generated/graphql";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "../../CompanyDashboard.module.scss";
import { InfoTextPopup } from "../InfoTextPopup";
import { NoData } from "../NoData";
import { InfoTextProps } from "../organization-metrices/types";
import { AssessmentDepartmentsDetails } from "./AssessmentDepartmentsDetails";

interface ViwellAssessmentProps extends InfoTextProps {
  userFilters: UserFilters;
  visible: boolean;
}

export const ViwellAssessment: FunctionComponent<ViwellAssessmentProps> = ({
  userFilters,
  visible,
  selectedInfoText,
  setSelectedInfoText,
}) => {
  const { t } = useTranslation();

  const [assessmentsStatsData, setAssessmentsStatsData] = useState<any>(true);
  const { refetch: fetchDepartments, isFetching } =
    useGetDepartmentsConfigQuery();

  const { handleGetViwellAssessmentStats, isLoading } =
    useGetViwellAssessmentStats();

  useEffect(() => {
    getViwellAssessmentStats();
  }, [userFilters]);

  const getViwellAssessmentStats = async () => {
    const viwellAssessmentsStats = await handleGetViwellAssessmentStats({
      userFilters,
    });
    if (viwellAssessmentsStats) {
      const deptsData = await fetchDepartments();
      const mappedDepartments = viwellAssessmentsStats?.departments?.length
        ? viwellAssessmentsStats.departments.map((item) => ({
            ...item,
            name: deptsData?.data?.getDepartmentsConfig?.options.find(
              (dept) => dept.id === item.id,
            )?.label,
            percentage: (item?.submissions / item?.totalEmployees) * 100,
          }))
        : [];
      setAssessmentsStatsData({
        ...viwellAssessmentsStats,
        departments: mappedDepartments,
      });
    }
  };

  const departmentBarColors = [
    "#5555A4",
    "#7274B7",
    "#1071B9",
    "#3B9ED7",
    "#3BCED7",
    "#D6D6D6",
  ];

  const sortedDepartmentsData = assessmentsStatsData?.departments?.length
    ? [...assessmentsStatsData?.departments]?.sort(
        (a, b) => b?.percentage - a?.percentage,
      )
    : [];

  const otherDepartmentsData = sortedDepartmentsData?.slice(5)?.reduce(
    (submissionsData: any, item: any) => {
      const otherSubmissions = {
        ...submissionsData,
        submissions: submissionsData.submissions + item.submissions,
        totalEmployees: submissionsData.totalEmployees + item.totalEmployees,
      };
      return { ...otherSubmissions, isClickable: true };
    },
    { name: t("other-departments"), submissions: 0, totalEmployees: 0 },
  );

  const allDepartments = [
    ...(sortedDepartmentsData?.slice(0, 5)?.length
      ? sortedDepartmentsData?.slice(0, 5)
      : []),
    ...(sortedDepartmentsData?.length > 5 ? [otherDepartmentsData] : []),
  ];

  const pieChartData = allDepartments?.length
    ? allDepartments?.map((item) => ({
        name: "Dept",
        value: parseInt(
          ((item?.submissions / item?.totalEmployees) * 100).toFixed(0),
        ),
      }))
    : [];

  const isDataExists = sortedDepartmentsData?.some(
    (item: any) => item.submissions,
  );

  return (
    <CustomCard
      bordered={false}
      className={`${styles["section-card"]} ${styles["card-height-for-data"]}`}
    >
      <div className={styles["assessment-container"]}>
        <div className={styles["section-item-container"]}>
          <div className={styles["item-header"]}>{t("viwell-assessment")}</div>
          <div>
            <InfoTextPopup
              items={[
                t("employee-count-overview"),
                t("department-completion-percentage"),
                t("monitor-employee-engagement"),
                t("departments-comprehensive-list"),
                t("organisation-wellbeing-strategy"),
              ]}
              visible={
                visible || selectedInfoText !== AssessmentTypes.ViwellAssessment
              }
              handleSelectedInfoText={(reset?: boolean) =>
                setSelectedInfoText(
                  reset ? "" : AssessmentTypes.ViwellAssessment,
                )
              }
            />
          </div>
        </div>
        {!isLoading &&
        !isFetching &&
        isDataExists &&
        !assessmentsStatsData?.isDataConcealed ? (
          <div className={styles["viwell-assessment-container"]}>
            <div className={styles["pie-chart-container"]}>
              <PieChart
                pieChartConfig={{
                  data: pieChartData,
                  colors: departmentBarColors,
                  innerRadius: 40,
                  outerRadius: 105,
                }}
              />
            </div>
            {allDepartments?.length ? (
              <AssessmentDepartmentsDetails
                allDepartments={allDepartments}
                departmentBarColors={departmentBarColors}
                assessmentsData={{
                  completedAssessments:
                    assessmentsStatsData?.completedAssessments,
                  totalEmployees: assessmentsStatsData?.totalEmployees,
                }}
                otherDepartmentsData={sortedDepartmentsData?.slice(5)}
              />
            ) : null}
          </div>
        ) : isLoading || isFetching ? (
          <div className={styles["loading-container"]}>
            <Spin spinning={isLoading || isFetching} />
          </div>
        ) : (
          <NoData
            handleClick={getViwellAssessmentStats}
            btnText={t("refresh")}
            title={
              assessmentsStatsData?.isDataConcealed
                ? t("data-concealed-for-privacy")
                : t("no-enough-data")
            }
          />
        )}
      </div>
    </CustomCard>
  );
};
