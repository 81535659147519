import { useAddBuilding } from "./useAddBuilding";
import { useGetCountries } from "./useGetCountries";
import { useUpdateBuilding } from "./useUpdateBuilding";

export const useBuilding = () => {
  const { handleAddBuilding, isLoading } = useAddBuilding();

  const { countries } = useGetCountries();

  const {
    handleFetchPreviousBuildingData,
    handleUpdateBuilding,
    isLoading: updateBuildingLoading,
  } = useUpdateBuilding();

  return {
    isLoading: isLoading || updateBuildingLoading,
    handleAddBuilding,
    handleFetchPreviousBuildingData,
    handleUpdateBuilding,
    countries,
  };
};
