import {
  useDeleteQuestionMutation,
  MutationDeleteQuestionArgs,
} from "graphql/_generated/graphql";

export const useDeleteQuestion = () => {
  const [deleteQuestionFun, { isLoading }] = useDeleteQuestionMutation();

  const handleDeleteQuestion = async (values: MutationDeleteQuestionArgs) => {
    try {
      await deleteQuestionFun({
        questionId: values.questionId,
      }).unwrap();
    } catch (error) {
      throw error;
    }
  };

  return {
    handleDeleteQuestion,
    isLoading,
  };
};
