import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { CONFIG } from "helpers/constants";
const ENV = process.env.REACT_APP_ENV || CONFIG.ENV;

//sentry integration for QA
export function initSentry() {
  Sentry.init({
    dsn: "https://dd99891a5aea439b94eea171f086d2d6@o1301550.ingest.sentry.io/6538584",
    integrations: [new BrowserTracing()],
    environment: ENV,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
