import { useEffect, useState } from "react";
import { ContentStatus } from "graphql/_generated/graphql";

export const useGetContentStatus = () => {
  const [contentStatus, setContentStatus] = useState<any>([]);

  // this useEffect created the options array of ContentStatus
  useEffect(() => {
    let dummyArr: any = [];
    Object.keys(ContentStatus).map((item) => {
      dummyArr.push({
        label: item,
        value: item,
      });
    });

    setContentStatus(dummyArr);
  }, []);
  return { contentStatus };
};
