export const ActiveAdvanceFilterSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clip-path="url(#clip0_900_20095)">
        <mask
          id="mask0_900_20095"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="32"
          height="32"
        >
          <path d="M32 0H0V32H32V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_900_20095)">
          <path
            d="M30 0H2C0.89543 0 0 0.89543 0 2V30C0 31.1046 0.89543 32 2 32H30C31.1046 32 32 31.1046 32 30V2C32 0.89543 31.1046 0 30 0Z"
            fill="#5555A4"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.94825 10H24.0512C24.5732 10 25.0002 10.427 25.0002 10.949V11.051C25.0002 11.573 24.5732 12 24.0512 12H7.94825C7.42625 12 7.00024 11.573 7.00024 11.051V10.949C7.00024 10.427 7.42625 10 7.94825 10ZM21.0508 15H10.9478C10.4258 15 9.99985 15.427 9.99985 15.949V16.051C9.99985 16.573 10.4258 17 10.9478 17H21.0508C21.5728 17 21.9998 16.573 21.9998 16.051V15.949C21.9998 15.427 21.5728 15 21.0508 15ZM19.0512 20H12.9482C12.4262 20 12.0002 20.427 12.0002 20.949V21.051C12.0002 21.573 12.4262 22 12.9482 22H19.0512C19.5732 22 20.0002 21.573 20.0002 21.051V20.949C20.0002 20.427 19.5732 20 19.0512 20Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_900_20095">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
