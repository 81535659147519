import { CalendarOutlined } from "@ant-design/icons";
import { Col, Row, Space, Table, Tag } from "antd";
import CustomCard from "components/custom-card/CustomCard";
import styles from "features/content/collection/collection-detail/CollectionDetail.module.scss";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import {
  ContentStatus,
  useGetCollectionQuery,
  useGetReviewsQuery,
} from "graphql/_generated/graphql";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { ColumnsType } from "antd/lib/table";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import CustomImg from "components/custom-img/CustomImg";
import Reviews from "components/reviews/Reviews";
import moment from "moment";
import { renderHtmlContent } from "helpers";

interface CollectionDetailProps {}

const CollectionDetail: React.FunctionComponent<CollectionDetailProps> = (
  props,
) => {
  const { id: collectionId } = useParams();
  const { data } = useGetCollectionQuery(
    { getCollectionId: collectionId },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const { data: review } = useGetReviewsQuery({ entityId: collectionId });
  const { t } = useTranslation();

  const columns: ColumnsType<any> = [
    {
      title: t("id"),
      key: "id",
      dataIndex: "id",
      render: (_text: any, record: any, index: number) =>
        data?.getCollection.contents
          ? data?.getCollection?.contents?.indexOf(record) + 1
          : index,
    },
    {
      title: t("content-name"),
      key: "title",
      dataIndex: "title",
      render: (_text: any, record: any, index: number) => (
        <Space>
          <CustomAvatar
            imgSrc={record.thumbnailImage}
            shape="square"
            size="large"
          />
          <span>{record.title}</span>
        </Space>
      ),
    },
    {
      title: t("created-at"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: string) =>
        moment(createdAt).format("MMMM Do YYYY, h:mm:ss a"),
    },
    {
      title: t("updated-at"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (updatedAt: string) =>
        moment(updatedAt).format("MMMM Do YYYY, h:mm:ss a"),
    },
    {
      align: "center" as "center",
      title: t("status"),
      dataIndex: "status",
      key: "status",
      render: (_text: any, record: any, index: number) => (
        <Tag
          className={
            record.status === ContentStatus.Published
              ? "active-tag"
              : record.status === ContentStatus.UnPublished
              ? "pending-tag"
              : "de-active-tag"
          }
        >
          {record.status}
        </Tag>
      ),
    },
  ];

  return (
    <DashboardLayout openKey={["Content"]}>
      <>
        <Breadcrumbs
          data={[
            {
              label: t("content-label"),
            },
            {
              label: t("program-label"),
            },
            {
              label: t("program-details-label"),
            },
          ]}
        />
        <div className={styles["collection"]}>
          <h1 className={styles["title"]}>{t("program-label")}</h1>
        </div>
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={24} md={16}>
            <CustomCard className="collection-card" bordered={false}>
              <div className={styles["collection-title"]}>
                {data?.getCollection.title}
              </div>
              <div className={styles["collection-img"]}>
                <CustomImg
                  imgSrc={data?.getCollection.image}
                  alt="viwell collection"
                />
              </div>
              <div className={styles["description"]}>
                <h2 className={styles["desc"]}>{t("description")}</h2>
                <div className="description-html">
                  {renderHtmlContent(data?.getCollection.description)}
                </div>
              </div>
              <div className={styles["description"]}>
                <h2 className={styles["desc"]}>{t("categories")}</h2>
                <p>
                  {data?.getCollection.categories?.map((category) =>
                    category.isActive ? (
                      <Tag key={category.id}>{category.name}</Tag>
                    ) : null,
                  )}
                </p>
              </div>
              <Reviews review={review?.getReviews[0]} />
              <div className={styles["description"]}>
                <h2 className={styles["desc"]}>{t("tags")}</h2>
                {data?.getCollection.tags
                  .filter((item) => item.isActive)
                  .map((item) => (
                    <Tag color="#108ee9">{item.title}</Tag>
                  ))}
              </div>
            </CustomCard>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <CustomCard className="collection-card">
              <div>
                <Space>
                  <Space direction="vertical">
                    <Space>
                      <CalendarOutlined />
                      <div>
                        {t("created-at")}:{" "}
                        {moment(data?.getCollection.createdAt).format(
                          "MMMM Do YYYY, h:mm:ss a",
                        )}
                      </div>
                    </Space>
                    <Space>
                      <CalendarOutlined />
                      <div>
                        {t("last-modified-date")}:{" "}
                        {moment(data?.getCollection.updatedAt).format(
                          "MMMM Do YYYY, h:mm:ss a",
                        )}
                      </div>
                    </Space>
                  </Space>
                </Space>
              </div>
            </CustomCard>
          </Col>
        </Row>
        <div className={styles["collection-table"]}>
          <Table
            columns={columns}
            className="custom-table"
            dataSource={data?.getCollection.contents}
          />
        </div>
      </>
    </DashboardLayout>
  );
};

export default CollectionDetail;
