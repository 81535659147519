import { FunctionComponent, useState } from "react";
import AllUsersFailed from "./AllUsersFailed";
import ChallengeDataTable from "./ChallengeDataTable";

interface ChallengeDescriptionProps {
  usersFailed: any;
  challengeId: string;
}

const UsersFailed: FunctionComponent<ChallengeDescriptionProps> = ({
  usersFailed,
  challengeId,
}) => {
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <>
      <ChallengeDataTable
        titleLeft="Users Failed The Challenge"
        titleRight="See More"
        data={
          usersFailed?.length
            ? usersFailed.map((item: any) => ({
                ...item,
              }))
            : []
        }
        setVisible={setVisible}
      />
      {visible ? (
        <AllUsersFailed
          visible={visible}
          setVisible={setVisible}
          challengeId={challengeId}
        />
      ) : null}
    </>
  );
};
export default UsersFailed;
