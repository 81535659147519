import * as Yup from "yup";
import * as Validations from "validations/schema/common/validations";

export const AddOnBoardingSchema = Yup.object().shape({
  image: Validations.image("image"),
  title: Validations.requiredFieldWithoutSpaces("title"),
  description: Validations.required("description"),
  translations: Yup.array().of(
    Yup.object().shape({
      lang: Validations.required("language"),
      title: Validations.required("title"),
      description: Validations.required("description"),
    }),
  ),
});
