import { FunctionComponent, useState } from "react";
import { List } from "antd";
import { useTranslation } from "react-i18next";
import styles from "features/events/css/EventDetail.module.scss";
import { useEvent } from "../hooks/useEvent";
import ParticipatingUsers from "./ParticipatingUsers";
import ParticipatingCompanyItem from "./ParticipatingCompanyItem";
import { GetEventDetails } from "../hooks/useEventViewModel";

interface EventDescriptionProps {
  companies?: GetEventDetails["participants"];
  loading?: boolean;
  userVisibility: boolean;
  eventId: string;
  companyId: string;
  onCloseUser: () => void;
  onPressItem: (clientId: string) => void;
}

const ParticipatingCompanies: FunctionComponent<EventDescriptionProps> = ({
  companies,
  loading,
  userVisibility,
  eventId,
  companyId,
  onPressItem,
  onCloseUser,
}) => {
  return (
    <div className={styles["participating-container"]}>
      <List
        loading={loading}
        size="small"
        itemLayout="horizontal"
        dataSource={companies ?? []}
        renderItem={(item, index) => (
          <ParticipatingCompanyItem
            itemKey={index + 1}
            id={item.id}
            companyImg={item.info?.image ?? ""}
            score={item.eventScore}
            participants={item.totalParticipatingEmployeeCount ?? 0}
            onItemClick={onPressItem}
            companyName={item.info?.companyName ?? ""}
            clientId={item.clientId}
          />
        )}
      />
      <ParticipatingUsers
        eventId={eventId}
        companyId={companyId}
        visible={userVisibility}
        setVisible={onCloseUser}
      />
    </div>
  );
};
export default ParticipatingCompanies;
