import {
  MutationAddOffersArgs,
  useAddOffersMutation,
} from "graphql/_generated/graphql";

//define the type of handleAddOffer function
export type TypeHandleAddOffers = (
  values: MutationAddOffersArgs
) => Promise<void>;

//define the type of useAddOffer hook
export type TypeUseAddOffer = () => {
  isLoading: boolean;
  handleAddOffer: TypeHandleAddOffers;
};

export const useAddOffer: TypeUseAddOffer = () => {
  const [addRewardFun, { isLoading }] = useAddOffersMutation();

  //this function is responsible for adding the offer data
  const handleAddOffer = async (values: MutationAddOffersArgs) => {
    try {
      await addRewardFun(values).unwrap();
    } catch (error: any) {
      throw error;
    }
  };

  //this function is responsible for updating the offer data
  return { isLoading, handleAddOffer };
};
