import * as Yup from "yup";
import * as Validations from "./common/validations";

export const streakSchema = Yup.object().shape({
  streaks: Yup.array().of(
    Yup.object().shape({
      points:
        Validations.requiredFieldPositiveNumber("point").integer(
          "error:only-integer",
        ),
    }),
  ),
});
