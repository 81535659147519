import * as React from "react";
import SortableList from "react-easy-sort";

interface SortAbleListProps extends React.ComponentProps<typeof SortableList> {
  // Add any specific props for your SortList component here
}

const SortAbleList: React.FunctionComponent<SortAbleListProps> = (props) => {
  return <SortableList {...props}>{props.children}</SortableList>;
};

export default SortAbleList;
