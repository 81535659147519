import emotionalSvg from "assets/images/emotional.svg";
import financialSvg from "assets/images/financial.svg";
import nutritionalSvg from "assets/images/nutritional.svg";
import physicalSvg from "assets/images/physical.svg";
import professionalSvg from "assets/images/professional.svg";
import socialSvg from "assets/images/social.svg";
import months from "features/wellbeing-calendar/constants/months";
import {
  History,
  MetricUnit,
  OrgMetricGraphDetails,
  TimeUnit,
} from "graphql/_generated/graphql";
import moment from "moment";
import { ValueType } from "recharts/types/component/DefaultTooltipContent";
import { FilterValuesProps } from "../types";

export const arrToObjectMap = (initialObj: object = {}, data: Array<any>) => {
  return data.reduce(
    (obj, item) => {
      const type = item["type"];
      return (
        (obj[type] = {
          ...obj[type],
          ...item,
        }),
        obj
      );
    },
    { ...initialObj },
  );
};

export const addDaysToCurrentDate = (daysToAdd: number) => {
  const now = new Date();

  return new Date(daysToAdd ? now.setDate(now.getDate() + daysToAdd) : now)
    .toISOString()
    .split("T")[0];
};

export const timeFilterTypes: { [key: string]: string } = {
  PAST_7_DAYS: "past-7-days",
  PAST_4_WEEKS: "past-4-weeks",
  PAST_6_MONTHS: "past-6-months",
  PAST_12_MONTHS: "past-12-months",
  CUSTOM_DATE_RANGE: "custom-date-range",
};

export const timeFilterOptions = [
  {
    label: "Past 7 Days",
    description: "past 7 days",
    value: timeFilterTypes.PAST_7_DAYS,
  },
  {
    label: "Past 4 Weeks",
    description: "past 4 weeks",
    value: timeFilterTypes.PAST_4_WEEKS,
  },
  {
    label: "Past 6 Months",
    description: "past 6 months",

    value: timeFilterTypes.PAST_6_MONTHS,
  },
  {
    label: "Past 12 Months",
    description: "past 12 months",
    value: timeFilterTypes.PAST_12_MONTHS,
  },
  {
    label: "Custom",
    description: "selected date range",
    value: timeFilterTypes.CUSTOM_DATE_RANGE,
  },
];

export const getFetchingStatus = (selectedValues: FilterValuesProps) =>
  (selectedValues?.pastDays === timeFilterTypes.CUSTOM_DATE_RANGE &&
    selectedValues?.customDateRange?.length) ||
  selectedValues?.pastDays !== timeFilterTypes.CUSTOM_DATE_RANGE;

export const getFormattedDateRanges = (selectedValues: FilterValuesProps) => {
  let startingDate = formatStartDate(selectedValues?.pastDays),
    endingDate = moment().endOf("day");
  if (selectedValues?.customDateRange?.length) {
    startingDate = moment(selectedValues?.customDateRange[0]).startOf("day");
    endingDate = moment(selectedValues?.customDateRange[1]).endOf("day");
  }
  return { startingDate, endingDate };
};

const timeUnits: { [key: string]: TimeUnit } = {
  [timeFilterTypes.PAST_7_DAYS]: TimeUnit.Day,
  [timeFilterTypes.PAST_4_WEEKS]: TimeUnit.Week,
  [timeFilterTypes.PAST_6_MONTHS]: TimeUnit.Month,
  [timeFilterTypes.PAST_12_MONTHS]: TimeUnit.Month,
};

export const getTimeUnit = (selectedValues: FilterValuesProps) => {
  let timeunit = timeUnits[selectedValues.pastDays || ""];
  if (selectedValues?.customDateRange?.length) {
    const { customDateRange } = selectedValues;
    const endDate = moment(customDateRange[1]);
    const dateRangeDiffinDays = endDate.diff(customDateRange[0], "days");
    if (dateRangeDiffinDays >= 0 && dateRangeDiffinDays <= 120) {
      timeunit = TimeUnit.Day;
    } else if (dateRangeDiffinDays > 120 && dateRangeDiffinDays <= 1200) {
      timeunit = TimeUnit.Month;
    } else if (dateRangeDiffinDays > 1200) {
      timeunit = TimeUnit.Year;
    }
  }
  return timeunit;
};

export const organizationTimeFilterOptions = [
  {
    label: "Monthly",
    value: "monthly",
  },
  {
    label: "Quarterly",
    value: "quarterly",
  },
  {
    label: "Yearly",
    value: "yearly",
  },
];

export const getCompanyYears = (registerDate: string) => {
  const currentYear = moment().format("YYYY");
  let endYear = parseInt(currentYear);
  let registerYear = parseInt(registerDate?.split("-")[1]);
  const totalYears: {
    label: string;
    value: number;
  }[] = [];

  while (endYear >= registerYear) {
    const addYear = endYear.toString();
    totalYears.push({ label: addYear, value: parseInt(addYear) });
    endYear--;
  }

  return totalYears;
};

export const getMonths = (year: number) => {
  const currentYear = moment().format("YYYY");
  const currentMonth = moment().month();
  let selectedYear = year?.toString();

  return months
    .filter((item, index) =>
      selectedYear === currentYear ? index <= currentMonth : item,
    )
    .map((item, index) => ({
      value: index + 1,
      label: item,
    }));
};

export const getValueUnit = (unit: MetricUnit) => {
  const units: { [key: string]: string } = {
    [MetricUnit.Percentage]: "%",
    [MetricUnit.Number]: "$",
    [MetricUnit.Days]: "Days",
  };
  return units[unit];
};

export const formatGraphValues = (
  num: ValueType | undefined,
  unit: MetricUnit | undefined,
  isTooltip: boolean,
) => {
  let result = "";
  if (unit) {
    num = num?.toString().replace(/[^0-9.]/g, "");
    if (num && parseInt(num) < 1000) {
      result = Number(num)?.toFixed(2).replace(/\.00$/, "");
    } else {
      let si = [
        { v: 1e3, s: "K" },
        { v: 1e6, s: "M" },
        { v: 1e9, s: "B" },
        { v: 1e12, s: "T" },
        { v: 1e15, s: "P" },
        { v: 1e18, s: "E" },
      ];
      let index;
      for (index = si.length - 1; index > 0; index--) {
        if (num && parseInt(num) >= si[index].v) {
          break;
        }
      }
      result = num
        ? (parseInt(num) / si[index].v)
            .toFixed(2)
            .toString()
            .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[index].s
        : "";
    }
    if (isTooltip) {
      if (unit === MetricUnit.Percentage) {
        return `${result}${getValueUnit(unit)}`;
      } else if (unit === MetricUnit.Days) {
        return `${result} ${getValueUnit(unit)}`;
      } else if (unit === MetricUnit.Number) {
        return `${getValueUnit(unit)}${result}`;
      }
    } else {
      if (MetricUnit.Percentage === unit) {
        return `${result}${getValueUnit(unit)}`;
      } else return result;
    }
  }
  return `${num?.toLocaleString()}`;
};

export const formatMetricData = (
  data: OrgMetricGraphDetails[],
  type: string,
) => {
  if (data?.length) {
    if (type === "monthly") {
      return months.map((item, index) => {
        const metricData = data.find(
          (metricData: OrgMetricGraphDetails) => metricData.month === index + 1,
        );
        return {
          value: metricData ? metricData.value : undefined,
          name: `${item} ${moment().format("YY")}`,
        };
      });
    } else if (type === "quarterly") {
      return ["Q1", "Q2", "Q3", "Q4"].map((item, index) => {
        const metricData = data.find(
          (metricData: OrgMetricGraphDetails) =>
            metricData.quarter === index + 1,
        );
        return {
          value: metricData ? metricData.value : undefined,
          name: item,
        };
      });
    } else if (type === "yearly") {
      return data.map((item: OrgMetricGraphDetails) => {
        return {
          value: item.value,
          name: item.year,
        };
      });
    }
  }
  return [];
};

const pillarsDateFormatter: {
  [key: string]: {
    decrement: number;
    start: number;
    formatter: string;
    insightFormatter: string;
    subtractType: string;
  };
} = {
  [timeFilterTypes.PAST_7_DAYS]: {
    decrement: 1,
    start: 6,
    formatter: "DD.M.YYYY",
    insightFormatter: "DD.M.YYYY",
    subtractType: "day",
  },
  [timeFilterTypes.PAST_4_WEEKS]: {
    decrement: 7,
    start: 28,
    formatter: "DD.M.YYYY",
    insightFormatter: "DD.M.YYYY",
    subtractType: "day",
  },
  [timeFilterTypes.PAST_6_MONTHS]: {
    decrement: 1,
    start: 5,
    formatter: "MMM YY",
    insightFormatter: "MMM .YYYY",
    subtractType: "month",
  },
  [timeFilterTypes.PAST_12_MONTHS]: {
    decrement: 1,
    start: 11,
    formatter: "MMM YY",
    insightFormatter: "MMM .YYYY",
    subtractType: "month",
  },
  [timeFilterTypes.CUSTOM_DATE_RANGE]: {
    decrement: 1,
    start: 11,
    formatter: "DD.M.YYYY",
    insightFormatter: "DD.M.YYYY",
    subtractType: "day",
  },
};

const mondayOfPast4Week = -20;
const lastDayOfCurrentWeek = 7;

export const formatPillarsData = (
  data: History[],
  selectedValues: FilterValuesProps,
) => {
  if (data?.length) {
    let formatedData = [],
      customDateFormatter = "";
    if (selectedValues?.pastDays === timeFilterTypes.CUSTOM_DATE_RANGE) {
      const { startingDate, endingDate } =
        getFormattedDateRanges(selectedValues);
      const dateRangeDiffinDays = endingDate.diff(startingDate, "days");
      let customDateIncrementer = 0,
        customSubtractType = "day";
      if (dateRangeDiffinDays >= 0) {
        if (dateRangeDiffinDays >= 0 && dateRangeDiffinDays <= 120) {
          customSubtractType = "day";
          customDateIncrementer = 1;
          customDateFormatter = "DD.M.YYYY";
        } else if (dateRangeDiffinDays > 120 && dateRangeDiffinDays <= 1200) {
          customSubtractType = "month";
          customDateIncrementer = 30;
          customDateFormatter = "MMM YY";
        } else if (dateRangeDiffinDays > 1200) {
          customSubtractType = "years";
          customDateIncrementer = 360;
          customDateFormatter = "YYYY";
        }
        for (let i = 0; i <= dateRangeDiffinDays / customDateIncrementer; i++) {
          const dateStartType =
            customSubtractType === "day"
              ? "day"
              : customSubtractType === "month"
              ? "month"
              : "years";
          const dateFormatter = moment(selectedValues?.customDateRange?.[0])
            .add(i, dateStartType)
            .format(customDateFormatter);

          formatedData.push({
            label: dateFormatter,
            value: dateFormatter,
          });
        }
      }
    } else {
      const dateStartType =
        pillarsDateFormatter[selectedValues?.pastDays || ""].subtractType ===
        "day"
          ? "day"
          : "month";

      const startValue =
        pillarsDateFormatter[selectedValues?.pastDays || ""].start;

      if (selectedValues?.pastDays === timeFilterTypes.PAST_4_WEEKS) {
        for (let i = mondayOfPast4Week; i <= 1; ) {
          const weekData = moment()
            .weekday(i)
            .format(
              pillarsDateFormatter[selectedValues?.pastDays || ""].formatter,
            );
          formatedData.push({
            label: `${weekData} - ${moment()
              .weekday(
                i +
                  pillarsDateFormatter[selectedValues?.pastDays || ""]
                    .decrement -
                  1,
              )
              .format(
                pillarsDateFormatter[selectedValues?.pastDays || ""].formatter,
              )}${i === 1 ? "*" : ""}`,
            value: weekData,
          });
          i =
            i + pillarsDateFormatter[selectedValues?.pastDays || ""].decrement;
        }
      } else {
        for (let i = startValue; i >= 0; ) {
          const dateFormatter = moment()
            .subtract(i, dateStartType)
            .format(
              pillarsDateFormatter[selectedValues?.pastDays || ""].formatter,
            );
          formatedData.push({
            label: dateFormatter,
            value: dateFormatter,
          });
          i =
            i - pillarsDateFormatter[selectedValues?.pastDays || ""].decrement;
        }
      }
    }
    const dateFormatter =
      customDateFormatter ||
      pillarsDateFormatter[selectedValues?.pastDays || ""].formatter;
    return formatedData.map((item, index) => {
      const interactionData = data.find(
        (interactionData: History) =>
          moment(interactionData.adjustedDate).format(dateFormatter) ===
          item.value,
      );
      return {
        value: interactionData ? interactionData.interactions : undefined,
        name: item.label,
      };
    });
  }
  return [];
};

export const formatPastDaysInsight = (
  selectedValues: FilterValuesProps,
  showLastDayOfWeek?: boolean,
) => {
  if (
    selectedValues?.pastDays === timeFilterTypes.CUSTOM_DATE_RANGE &&
    selectedValues?.customDateRange?.length
  ) {
    return `${moment(selectedValues?.customDateRange?.[0]).format(
      pillarsDateFormatter[selectedValues.pastDays || ""].insightFormatter,
    )} - ${moment(selectedValues?.customDateRange?.[1]).format(
      pillarsDateFormatter[selectedValues.pastDays || ""].insightFormatter,
    )}`;
  } else {
    const dateStartType =
      pillarsDateFormatter[selectedValues?.pastDays || ""].subtractType ===
      "day"
        ? "day"
        : "month";
    const startDateFormatter =
      selectedValues?.pastDays === timeFilterTypes.PAST_4_WEEKS
        ? moment().weekday(mondayOfPast4Week)
        : moment().subtract(
            pillarsDateFormatter[selectedValues?.pastDays || ""].start,
            dateStartType,
          );
    const endDateFormatter =
      selectedValues?.pastDays === timeFilterTypes.PAST_4_WEEKS &&
      showLastDayOfWeek
        ? moment().weekday(lastDayOfCurrentWeek)
        : moment();
    return `${startDateFormatter
      .startOf(dateStartType)
      .format(
        pillarsDateFormatter[selectedValues.pastDays || ""].insightFormatter,
      )} - ${endDateFormatter.format(
      pillarsDateFormatter[selectedValues.pastDays || ""].insightFormatter,
    )}`;
  }
};

export const formatStartDate = (type: string | undefined) => {
  if (type) {
    const dateStartType =
      pillarsDateFormatter[type].subtractType === "day" ? "day" : "month";
    const formatedData =
      type === timeFilterTypes.PAST_4_WEEKS
        ? moment().weekday(mondayOfPast4Week)
        : moment().subtract(pillarsDateFormatter[type].start, dateStartType);
    return formatedData.startOf(dateStartType);
  }
  return "";
};

export const pillarsDataMapper = [
  {
    id: 1,
    name: "Physical",
    lineColor: "#FFAA00",
    icon: physicalSvg,
    categoryColors: [
      "#FFAA00",
      "#FFBC00",
      "#FECE00",
      "#FFDA00",
      "#FFE600",
      "#D6D6D6",
    ],
  },
  {
    id: 2,
    name: "Professional",
    lineColor: "#DA4407",
    icon: professionalSvg,
    categoryColors: [
      "#DA4407",
      "#E84C0C",
      "#FF5917",
      "#FF723D",
      "#FF8B62",
      "#D6D6D6",
    ],
  },
  {
    id: 3,
    name: "Nutritional",
    lineColor: "#007417",
    icon: nutritionalSvg,
    categoryColors: [
      "#007417",
      "#1E8F31",
      "#41AA4A",
      "#5FC663",
      "#7AE27D",
      "#D6D6D6",
    ],
  },
  {
    id: 4,
    name: "Mental",
    lineColor: "#4F267F",
    icon: emotionalSvg,
    categoryColors: [
      "#4F267F",
      "#6A3D99",
      "#9361C0",
      "#D58AE5",
      "#FCAEFA",
      "#D6D6D6",
    ],
  },
  {
    id: 5,
    name: "Social",
    lineColor: "#CE2854",
    icon: socialSvg,
    categoryColors: [
      "#CE2854",
      "#E24264",
      "#F45A7C",
      "#F95F88",
      "#FF6C9A",
      "#D6D6D6",
    ],
  },
  {
    id: 6,
    name: "Financial",
    lineColor: "#006EBE",
    icon: financialSvg,
    categoryColors: [
      "#006EBE",
      "#27A5F3",
      "#70C2F8",
      "#A0D7FB",
      "#D1EBFE",
      "#D6D6D6",
    ],
  },
];
