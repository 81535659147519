export const SliderIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect opacity="0.24" width="24" height="24" rx="4" fill="#7573E1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1659 17.8327C10.7059 17.8327 10.3326 17.4593 10.3326 16.9993C10.3326 16.5393 10.7059 16.166 11.1659 16.166C11.6259 16.166 11.9993 16.5393 11.9993 16.9993C11.9993 17.4593 11.6259 17.8327 11.1659 17.8327ZM19.4993 16.1667H13.5118C13.1668 15.1992 12.251 14.5 11.166 14.5C10.081 14.5 9.16518 15.1992 8.82018 16.1667H4.49935C4.03852 16.1667 3.66602 16.5392 3.66602 17C3.66602 17.4608 4.03852 17.8333 4.49935 17.8333H8.82018C9.16518 18.8008 10.081 19.5 11.166 19.5C12.251 19.5 13.1668 18.8008 13.5118 17.8333H19.4993C19.9602 17.8333 20.3327 17.4608 20.3327 17C20.3327 16.5392 19.9602 16.1667 19.4993 16.1667ZM17.8329 12.8327C17.3729 12.8327 16.9996 12.4593 16.9996 11.9993C16.9996 11.5393 17.3729 11.166 17.8329 11.166C18.2929 11.166 18.6663 11.5393 18.6663 11.9993C18.6663 12.4593 18.2929 12.8327 17.8329 12.8327ZM17.8327 9.5C16.7477 9.5 15.8318 10.1992 15.4868 11.1667H4.49935C4.03852 11.1667 3.66602 11.5392 3.66602 12C3.66602 12.4608 4.03852 12.8333 4.49935 12.8333H15.4868C15.8318 13.8008 16.7477 14.5 17.8327 14.5C19.211 14.5 20.3327 13.3783 20.3327 12C20.3327 10.6217 19.211 9.5 17.8327 9.5ZM7.83293 6.16602C8.29293 6.16602 8.66626 6.53935 8.66626 6.99935C8.66626 7.45935 8.29293 7.83268 7.83293 7.83268C7.37293 7.83268 6.99959 7.45935 6.99959 6.99935C6.99959 6.53935 7.37293 6.16602 7.83293 6.16602ZM4.49943 7.83333H5.48693C5.83193 8.80083 6.74776 9.5 7.83276 9.5C8.91776 9.5 9.8336 8.80083 10.1786 7.83333H19.4994C19.9603 7.83333 20.3328 7.46083 20.3328 7C20.3328 6.53917 19.9603 6.16667 19.4994 6.16667H10.1786C9.8336 5.19917 8.91776 4.5 7.83276 4.5C6.74776 4.5 5.83193 5.19917 5.48693 6.16667H4.49943C4.0386 6.16667 3.6661 6.53917 3.6661 7C3.6661 7.46083 4.0386 7.83333 4.49943 7.83333Z"
        fill="#303030"
      />
    </svg>
  );
};
