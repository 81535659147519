import {
  GetViwellAssessmentStatsQueryVariables,
  useLazyGetViwellAssessmentStatsQuery,
} from "graphql/_generated/graphql";

export const useGetViwellAssessmentStats = () => {
  const [assessmentsStatsFun, { isFetching }] =
    useLazyGetViwellAssessmentStatsQuery();

  const handleGetViwellAssessmentStats = async (
    values: GetViwellAssessmentStatsQueryVariables,
  ) => {
    try {
      const res = await assessmentsStatsFun(values).unwrap();

      return res.getViwellAssessmentStats;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleGetViwellAssessmentStats,
    isLoading: isFetching,
  };
};
