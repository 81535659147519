import { useLogoutMutation, LogoutMutationVariables } from "graphql/_generated/graphql";

export const useLogout = () => {
  const [logoutFun, { isLoading }] = useLogoutMutation();

  //this function is responsible for adding the new role
  const handleLogout = async (values: LogoutMutationVariables) => {
    try {
      await logoutFun();
    } catch (error: any) {
      throw error;
    }
  };

  return {
    isLoading,
    handleLogout,
  };
};
