import {
  Checkbox,
  DatePicker,
  Pagination,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import { removeUndefinedValuesFromObject } from "helpers";
import { useErrorHandler } from "hooks/useErrorHandler";
import useFilters from "hooks/useFilters";
import usePagination from "hooks/usePagination";
import useSearch from "hooks/useSearch";
import useSorting from "hooks/useSorting";
import { debounce } from "lodash";
import moment from "moment";
import { DefaultRecordType } from "rc-table/lib/interface";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { batch, useSelector } from "react-redux";
import "./CustomTable.module.scss";
import CustomTableHeader from "./CustomTableHeader";
import { CustomTableProps, DateRange, FilterValue } from "./types";
import { searchFilters } from "./utils";

const { Option } = Select;
const { RangePicker } = DatePicker;
const DEBOUNCE_TIME = 800;

function CustomTable<RecordType extends DefaultRecordType>(
  props: CustomTableProps<RecordType>,
) {
  const {
    filters,
    showSearch = true,
    paginationType = "OFFSET",
    enablePersist,
    isNestedTable,
    searchStyle,
    headerStyle,
    showCustomHeader = true,
    ...restProps
  } = props;
  const { pagination, handlePagination } = usePagination(
    paginationType,
    enablePersist,
  );
  const { handleError } = useErrorHandler();
  const { search, handleSearch } = useSearch(enablePersist);
  const { t } = useTranslation();

  const {
    advanceFilters,
    handleFilter,
    handleRangeFilter,
    handleMandatoryFilter,
    handleManualFilter,
  } = useFilters(filters, enablePersist);
  const { sorting, handleSorting } = useSorting();
  const { region } = useSelector((state: any) => state.auth);

  useEffect(() => {
    const { filters: propsFilters } = props;
    const filters = { ...search, ...advanceFilters };
    if (propsFilters?.manualFilter?.value) {
      const { applyAs, comparison, key, value } = propsFilters.manualFilter;
      const existingFilterList = filters[applyAs] || [];
      const newFilter = { comparison, key, value };
      filters[applyAs] = [...existingFilterList, newFilter];
    }

    //if any value is undefined in filters object remove it from the filters object
    removeUndefinedValuesFromObject(filters);

    //if object is empty replace the object with undefined
    const finalFilters =
      Object.keys(filters).length === 0 ? undefined : filters;

    propsFilters
      ?.handleCallback({
        filters: finalFilters,
        pagination,
        sorting,
      })
      .catch((e: any) => handleError(e));
  }, [pagination, search, advanceFilters, sorting]);

  const handleSearchInput = debounce((e) => {
    const paginationState = {
      current: 1,
      pageSize: pagination?.offset?.pageSize || 10,
    };

    batch(() => {
      handleSearch(e);
      handlePagination(paginationState);
    });
  }, DEBOUNCE_TIME);

  useEffect(() => {
    if (!isNestedTable) {
      resetPaginationOnRegionChange();
    }
  }, [region]);

  const resetPaginationOnRegionChange = () => {
    const paginationState = {
      current: 1,
      pageSize: pagination?.offset?.pageSize || 10,
    };
    handlePagination(paginationState);
  };

  const statusOptions = [
    {
      label: t("active"),
      value: true,
      className: "active-tag",
    },
    {
      label: t("deactivated"),
      value: "false",
      className: "de-active-tag",
    },
  ];

  const [status, SetStatus] = useState<
    string | { start: string; end: string }
  >();

  return (
    <>
      {showCustomHeader &&
        (showSearch || props.actions || props.headerTitle || props.btnText) && (
          <CustomTableHeader
            handleSearchInput={handleSearchInput}
            permission={props?.permission || ""}
            onClick={props?.onClick}
            title={props?.headerTitle || ""}
            btnText={props?.btnText || ""}
            showSearch={showSearch}
            defaultValue={search?.search?.text}
            actions={props.actions}
            additionalButtons={props.additionalButtons}
            searchStyle={searchStyle}
            headerStyle={headerStyle}
          />
        )}

      <div
        className={
          filters || props.xtitle || props.isCreate ? "header" : "no-header"
        }
      >
        <Space className="wrap-header-content">
          {/* PAGE TITLE */}
          {props?.xtitle && (
            <h5 className="header__row--xtitle">{props?.xtitle}</h5>
          )}
          {props.filters?.manualFilters?.map((filter) => {
            const { render, key } = filter;
            const filterValue = searchFilters(advanceFilters, key)?.value;
            return render(handleManualFilter(filter), filterValue);
          })}
          {/* Status Dropdown filter */}
          {filters?.status && (
            <Select
              allowClear
              placeholder="Status: All"
              className="filter-select"
              onChange={(value) => {
                SetStatus(value);
                handleFilter(value);
              }}
              onClear={() => {}}
              value={searchFilters(advanceFilters, filters?.status?.key)?.value}
              optionLabelProp="label"
            >
              {statusOptions.map((option) => (
                <Option
                  key={option.label}
                  label={option.label}
                  value={option.value}
                >
                  <Checkbox checked={status == option.value}>
                    <Tag className={option.className}>{option.label}</Tag>
                  </Checkbox>
                </Option>
              ))}
            </Select>
          )}
          {/* Mandatory Dropdown filter */}
          {filters?.mandatory && (
            <Select
              placeholder="Mandatory: All"
              className="filter-select"
              allowClear
              onClear={() => handleMandatoryFilter(null)}
              onChange={handleMandatoryFilter}
            >
              <Option value="true">Mandatory</Option>
              <Option value="false">Non Mandatory</Option>
            </Select>
          )}

          {/* Date Range Filter */}
          {filters?.date?.map((item, index) => {
            const values: FilterValue = searchFilters(
              advanceFilters,
              filters?.date?.[index]?.key,
            )?.value;

            const range: DateRange =
              typeof values === "object"
                ? [moment(values?.start), moment(values?.end)]
                : [null, null];

            return (
              <RangePicker
                key={index}
                className="filter-date-picker"
                onChange={(dates, dateStrings) =>
                  handleRangeFilter(dates, dateStrings, item)
                }
                defaultValue={range}
                placeholder={
                  item.placeholder
                    ? [
                        `${item.placeholder} Start Date`,
                        `${item.placeholder} End Date`,
                      ]
                    : undefined
                }
              />
            );
          })}
          {props?.isCreate && <div>{props.isCreate}</div>}
        </Space>
      </div>
      <Table
        rowKey="id"
        onChange={(currentPagination, _, sort: any) => {
          let filters = { ...search, ...advanceFilters };
          if (Object.keys(filters).length === 0) {
            filters = undefined;
          }

          // this code block only run if we will apply the manual filters
          if (props.filters?.manualFilter) {
            const { applyAs, comparison, key, value }: any =
              props?.filters?.manualFilter;

            const params = {
              comparison,
              key,
              value,
            };

            const checkArrExist =
              filters && filters[applyAs] ? filters[applyAs] : [];
            filters = { ...filters, [applyAs]: [...checkArrExist, params] };
          }

          handleSorting(sort);
        }}
        className={props.className ? props.className : "custom-table"}
        pagination={false}
        {...restProps}
      />
      {props.totalItems ? (
        <div className={"pagination-margin"}>
          <Pagination
            onChange={(page, pageSize) =>
              handlePagination({
                current: page,
                pageSize,
              })
            }
            current={pagination?.offset?.page}
            pageSize={pagination?.offset?.pageSize}
            showTotal={(total) => {
              const { page: currentPage, pageSize: pageSizeDisplayed } =
                pagination?.offset;
              const startItem = (currentPage - 1) * pageSizeDisplayed + 1;
              const endItem = Math.min(currentPage * pageSizeDisplayed, total);

              return (
                <span>
                  {t("showing")}{" "}
                  <b>
                    {startItem}-{endItem}
                  </b>{" "}
                  {t("of")} <b>{total}</b> {t("items")}
                </span>
              );
            }}
            showSizeChanger={true}
            total={props.totalItems || 0}
          />
        </div>
      ) : null}
    </>
  );
}

export default CustomTable;
