import {
  MutationUpdatePartnerRewardArgs,
  useGetPartnerRewardListQuery,
  useLazyGetPartnerRewardDetailsQuery,
  useUpdatePartnerRewardMutation,
} from "graphql/_generated/graphql";

export const useUpdatePartnerReward = () => {
  const [getPartnerRewardDetailFun, { isLoading }] =
    useLazyGetPartnerRewardDetailsQuery();

  const [updatePartnerReward, { isLoading: isLoadingUpdateReward }] =
    useUpdatePartnerRewardMutation();

  const { refetch } = useGetPartnerRewardListQuery();

  const handleGetPartnerReward = async (id: string) => {
    try {
      const res = await getPartnerRewardDetailFun({
        getPartnerRewardDetailsId: id,
      }).unwrap();
      return res.getPartnerRewardDetails;
    } catch (error) {
      throw error;
    }
  };

  const handleUpdateReward = async (
    values: MutationUpdatePartnerRewardArgs,
  ) => {
    try {
      await updatePartnerReward({
        update: values.update,
        updatePartnerRewardId: values.id,
      }).unwrap();
      refetch();
    } catch (error) {
      throw error;
    }
  };

  return {
    handleGetPartnerReward,
    handleUpdateReward,
    isLoading: isLoading || isLoadingUpdateReward,
  };
};
