import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import CustomLanguageSelector from "components/form/custom-language-selector/CustomLanguageSelector";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import { useTiersListViewModel } from "features/rewards/hooks/useTiersListViewModel";
import AddTier from "features/rewards/tiers/AddTier";
import TiersList from "features/rewards/tiers/TiersList";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface TiersProps {}

const Tiers: React.FunctionComponent<TiersProps> = (props) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [langCode, setLangCode] = useState<string>();
  const vm = useTiersListViewModel();

  const handleSubmit = (values: any, actions: any) => {
    actions.resetForm();
    setLangCode(values.language);
    setVisible(false);
    vm.setIsAddTier(true);
  };

  return (
    <DashboardLayout openKey={["Rewards"]}>
      <Breadcrumbs
        data={[
          {
            label: t("rewards"),
          },
          {
            label: t("tiers"),
          },
        ]}
      />
      <TiersList setVisible={setVisible} />
      {visible && (
        <CustomLanguageSelector
          visible={visible}
          setVisible={setVisible}
          onSubmit={handleSubmit}
          title={`${t("tier")} ${t("main-language")}`}
          label={t("tier")}
          saveText={t("add-tier")}
        />
      )}
      {vm.isAddTier && (
        <AddTier
          visible={vm.isAddTier}
          langCode={langCode ?? ""}
          isLoading={vm.isTierLoading}
          initialValues={vm.initialValues}
          handleCancel={vm.handleCancel}
          handleSubmit={vm.handleAddSubmit}
        />
      )}
    </DashboardLayout>
  );
};

export default Tiers;
