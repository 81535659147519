import * as React from "react";
import { Button, ButtonProps } from "antd";
import "./CustomButton.module.scss";

interface CustomButtonProps extends ButtonProps {
  className?: string;
  children?: React.ReactNode;
}

const CustomButton: React.FunctionComponent<CustomButtonProps> = ({
  children,
  ...restProps
}) => {
  return <Button {...restProps}>{children}</Button>;
};

export default CustomButton;
