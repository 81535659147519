import {
  GetPopularPillarsQueryVariables,
  useLazyGetPopularPillarsQuery,
} from "graphql/_generated/graphql";

export const useGetPopularPillars = () => {
  const [popularPillarsFun, { isFetching }] = useLazyGetPopularPillarsQuery();

  const handleGetPopularPillars = async (
    values: GetPopularPillarsQueryVariables,
  ) => {
    try {
      const res = await popularPillarsFun(values).unwrap();

      return res.getPopularPillars;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleGetPopularPillars,
    isLoading: isFetching,
  };
};
