import { EditOutlined } from "@ant-design/icons";
import { Space } from "antd";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import CustomButton from "components/custom-button/CustomButton";
import CustomTabs from "components/custom-tabs/CustomTabs";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import { useTiersListViewModel } from "features/rewards/hooks/useTiersListViewModel";
import AddTier from "features/rewards/tiers/AddTier";
import TierDetail from "features/rewards/tiers/TierDetail";
import TierRewards from "features/rewards/tiers/TierRewards";
import TierStats from "features/rewards/tiers/TierStats";
import { useGetTierDetailsQuery } from "graphql/_generated/graphql";
import { getLanguage } from "helpers";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "./TierDetails.module.scss";

interface TierDetailsProps {}

const TierDetails: React.FunctionComponent<TierDetailsProps> = (props) => {
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const { id, langCode } = useParams();

  const langLabel = getLanguage(langCode ?? "");

  const { data: tierData } = useGetTierDetailsQuery({ getTierDetailsId: id });
  const vm = useTiersListViewModel();

  return (
    <>
      <DashboardLayout openKey={["Tiers"]}>
        <Breadcrumbs
          data={[
            {
              label: t("rewards"),
            },
            {
              label: t("tiers"),
            },
            {
              label: t("tier-details-label", { langLabel }),
            },
          ]}
        />
        <div className={styles["tier-details"]}>
          <h1 className={styles["title"]}>
            {" "}
            {t("tier-details-label", { langLabel })}
          </h1>
          <div>
            <Space size={16}>
              <CustomButton
                onClick={() => navigate("/tiers")}
                className="link-text-btn"
              >
                {t("close")}
              </CustomButton>
              <CustomButton
                onClick={() => {
                  vm.setIsAddTier(true);
                  vm.setEditId(id ?? null);
                }}
                icon={<EditOutlined />}
                className="light-btn"
              >
                {t("edit-tier")}
              </CustomButton>
            </Space>
          </div>
        </div>
        <TierDetail data={tierData?.getTierDetails} />
        <div className={styles["rewards-stats"]}>
          <CustomTabs
            items={[
              {
                label: t("rewards"),
                children: <TierRewards tierId={id ?? ""} />,
                key: "rewards",
              },
              {
                label: t("stats"),
                children: <TierStats tierId={id ?? ""} />,
                key: "stats",
              },
            ]}
          />
        </div>
      </DashboardLayout>
      {vm.isAddTier && (
        <AddTier
          visible={vm.isAddTier}
          editId={vm.editId}
          isLoading={vm.isTierLoading}
          initialValues={vm.initialValues}
          handleCancel={vm.handleCancel}
          handleSubmit={vm.handleAddSubmit}
        />
      )}
    </>
  );
};

export default TierDetails;
