import { customNotification } from "components/custom-notification/CustomNotification";
import { FormikHelpers } from "formik";
import {
  BuildingCreate,
  ConfigOptions,
  useLazyGetBuildingsConfigQuery,
} from "graphql/_generated/graphql";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useLoggedInUser } from "hooks/useLoggedInUser";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useBuilding } from "../add-building/useBuilding";

export type ConfigDataProps = {
  buildingId: string;
};

export const useAddBuildingViewModel = () => {
  const { isClient, isSuperAdmin, isViwellAdmin } = useLoggedInUser();

  const { handleError } = useErrorHandler();
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState<BuildingCreate>({
    name: "",
    address: {
      address: "",
      city: "",
      country: "",
    },
    translations: [],
  });
  const [editId, setEditId] = useState<string | null>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [clientId, setClientId] = useState<string>("");
  const [inviteUserView, setInviteUserView] = useState<boolean>(false);
  const [buildingsData, setBuildingsData] = useState<ConfigOptions[]>([
    {
      id: "",
      label: "",
    },
  ]);

  const [getBuildingsConfigFun, { isFetching: isBuildingsLoading }] =
    useLazyGetBuildingsConfigQuery();

  const handleInviteUser = () => {
    setInviteUserView(true);
    setVisible(true);
  };

  const [configData, setConfigData] = useState<ConfigDataProps>({
    buildingId: "",
  });
  const closeModal = () => {
    setVisible(false);
    setEditId(null);
  };

  const handleClientId = (client: string) => {
    setClientId(client);
    setConfigData({
      buildingId: "",
    });
  };

  const handleEditId = (id: string) => {
    setEditId(id);
    setVisible(true);
  };

  useEffect(() => {
    getBuildings();
  }, [clientId]);

  async function getBuildings() {
    const data = await getBuildingsConfigFun({
      clientId,
    }).unwrap();
    setBuildingsData(data.getBuildingsConfig.options);
  }

  useEffect(() => {
    fetchBuildingData(editId);
  }, [editId]);

  const {
    isLoading,
    handleAddBuilding,
    handleFetchPreviousBuildingData,
    handleUpdateBuilding,
    countries,
  } = useBuilding();

  const navigate = useNavigate();

  const redirectToBuildings = () => {
    navigate("/buildings");
  };

  const fetchBuildingData = (editId?: string | null) => {
    if (editId) {
      handleFetchPreviousBuildingData(editId).then((data: any) => {
        setInitialValues(data);
      });
    } else {
      setInitialValues({
        translations: [],
        name: "",
        address: {
          address: "",
          city: "",
          // coordinates: { lat: "", lng: "" },
          country: "",
        },
      });
    }
  };

  const handleSubmit = async (
    values: BuildingCreate,
    { resetForm }: FormikHelpers<BuildingCreate>,
  ) => {
    const data = { ...values };
    if (isSuperAdmin || isViwellAdmin) {
      data.clientId = clientId;
    }
    try {
      if (!editId) {
        const buildingResponse = await handleAddBuilding({ create: data });
        getBuildings();
        inviteUserView &&
          setConfigData?.({
            buildingId: buildingResponse?.id,
          });
        customNotification("success", t("building-added-successfully"));
      } else {
        await handleUpdateBuilding({
          id: editId,
          updateObj: values,
        });
        getBuildings();
        customNotification("success", t("building-updated-successfully"));
      }
      resetForm();
      closeModal?.();
    } catch (error) {
      handleError(error as string | object);
    }
  };

  return {
    fetchBuildingData,
    handleSubmit,
    redirectToBuildings,
    isClient,
    initialValues,
    isLoading,
    closeModal,
    editId,
    visible,
    setVisible,
    setEditId,
    handleClientId,
    setInviteUserView,
    handleEditId,
    inviteUserView,
    configData,
    countries,
    handleInviteUser,
    buildingsData,
    isBuildingsLoading,
  };
};
