import * as Yup from "yup";
import * as Validations from "./common/validations";

const translationSchema = Yup.object().shape({
  lang: Validations.required("Language"),
  value: Validations.required("Description"),
});

export const documentationSchema = Yup.object().shape({
  privacyPolicy: Validations.required("Privacy Policy"),
  termsAndConditions: Validations.required("Terms And Conditions"),
  aboutViwell: Validations.required("About Viwell"),
  translations: Yup.object().shape({
    aboutViwell: Yup.array().of(translationSchema),
    privacyPolicy: Yup.array().of(translationSchema),
    termsAndConditions: Yup.array().of(translationSchema),
  }),
});
