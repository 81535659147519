import { useLazyGetEventsByIdQuery } from "graphql/_generated/graphql";

export const useGetEvents = () => {
  const [getEventFn, { isFetching: isLoading }] = useLazyGetEventsByIdQuery();

  const getEvent = async (id: string) => {
    try {
      const res = await getEventFn({ event: { id } }).unwrap();
      return res.getEventsById;
    } catch (error) {
      throw error;
    }
  };

  return {
    isLoading,
    getEvent,
  };
};
