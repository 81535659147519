import { Checkbox } from "antd";
import { useEffect } from "react";

//define prop types
type Props = {
  permissionsArr: any[] | undefined;
  handleChange: (e: any) => void;
  permissionsInitialValues: string[];
  setPermissionsInitialValues: (x: string[]) => void;
};

const RecursivePermissions = ({
  permissionsArr = [],
  handleChange,
  permissionsInitialValues,
  setPermissionsInitialValues,
}: Props): any => {
  //adding the modules Dependent roles into the final array
  useEffect(() => {
    let dummyArr: string[] = [];
    permissionsArr.map((item: any) => {
      item.moduleDependent && dummyArr.push(item.key);
    });
    setPermissionsInitialValues([...permissionsInitialValues, ...dummyArr]);
  }, []);

  return permissionsArr.map((item: any, index: number) => {
    return (
      <div key={index} style={{ marginLeft: "20px" }}>
        <Checkbox
          value={item.key}
          onChange={handleChange}
          defaultChecked={item.moduleDependent}
          checked={permissionsInitialValues.includes(item.key)}
          disabled={item.moduleDependent}
        />
        {item.label}

        <div style={{ marginLeft: "20px" }}>
          {item?.children?.length &&
            permissionsInitialValues.includes(item.key) && (
              <RecursivePermissions
                permissionsArr={item.children}
                handleChange={handleChange}
                permissionsInitialValues={permissionsInitialValues}
                setPermissionsInitialValues={setPermissionsInitialValues}
              />
            )}
        </div>
      </div>
    );
  });
};

export default RecursivePermissions;
