import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { customNotification } from "components/custom-notification/CustomNotification";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { errorFormat, getLanguage } from "helpers";
import { Form, Formik } from "formik";
import { addLanguageSchema } from "validations/schema";
import { useLanguages } from "hooks";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useErrorHandler } from "hooks/useErrorHandler";

interface AddLanguageProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  data?: any;
}

const AddLanguage: React.FunctionComponent<AddLanguageProps> = ({
  setVisible,
  visible,
  data,
}) => {
  const { languages } = useLanguages();
  const { handleError } = useErrorHandler();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const addedLanguages = data?.translations
    ? Object.keys(data?.translations)?.map((item: any) => getLanguage(item))
    : [];

  const handleCancel = () => {
    setVisible(false);
  };

  const initialValues = {
    language: "",
  };

  return (
    <CustomModal
      width={600}
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      title={data?.language ? t("add-new-language") : t("main-language")}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={addLanguageSchema}
        onSubmit={async (values, { resetForm }) => {
          try {
            setVisible(false);
            resetForm();
            navigate("/add-challenge", {
              state: {
                editId: data?.id,
                isAddLanguage: data ? true : false,
                languageKey: values.language,
                language: getLanguage(values.language.toLowerCase()),
              },
            });
          } catch (error) {
            handleError(error as string | object);
          }
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <CustomSelectField
              name="language"
              placeholder="Select challenge language"
              options={
                languages.filter(
                  (item) =>
                    item.label !== data?.language &&
                    !addedLanguages?.includes(item.label),
                ) || []
              }
            />
            <ModalFooter
              saveText={data?.language ? t("add-language") : t("add-challenge")}
              onCancel={handleCancel}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddLanguage;
