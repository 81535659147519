import { Popover, Space } from "antd";
import { Field, useField } from "formik";
import { useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";
import { useErrorTranslation } from "validations/schema/common/useErrorTranslation";
import { CustomFastField } from "../custom-fast-field/CustomFastField";
import styles from "./CustomColorPicker.module.scss";
import "./CustomColorPicker.module.scss";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

type Props = {
  name: string;
  label?: string;
  defaultColor?: string;
  showColorInput?: boolean;
};

const ViwellColorPicker = (props: Props) => {
  const { name, defaultColor, showColorInput = true } = props;
  const { errorT } = useErrorTranslation();
  const [field, , helpers] = useField(name);
  const [isOpen, toggle] = useState<boolean>(false);

  const handleColorChange = (color: string) => {
    helpers.setValue(color);
  };

  return (
    <div className={styles["form-control"]}>
      <div className="custom-viwell-picker">
        {showColorInput && (
          <CustomFastField
            placeholder="Select Color"
            parentClassName="custom-viwell-picker--fastField"
            {...props}
          />
        )}
        <Popover
          placement="bottomRight"
          open={isOpen}
          showArrow={false}
          content={
            <Content
              color={field.value ?? defaultColor}
              handleChange={handleColorChange}
              toggle={toggle}
              name={name}
            />
          }
          trigger="click"
        >
          <div
            className={`custom-viwell-picker--swatch ${
              !showColorInput ? "custom-viwell-picker--mt-2" : ""
            }`}
            style={{ backgroundColor: field.value ?? defaultColor }}
            onClick={() => toggle(true)}
          />
        </Popover>
      </div>
    </div>
  );
};

export default ViwellColorPicker;

interface contentProps {
  color: string;
  handleChange: (e: string) => void;
  toggle: (values: boolean) => void;
  name: string;
}
export const Content = (props: contentProps) => {
  const { t } = useTranslation();
  const { color } = props;
  return (
    <div className={"popover"}>
      <Space className="popover--header">
        <label className="label">{t("color-picker")}</label>
        <CloseOutlined onClick={() => props.toggle(false)} />
      </Space>
      <HexColorPicker
        color={color}
        onChange={props?.handleChange}
        className={"popover--hexColorPicker"}
      />
      <Space className="popover--form">
        <div
          className={"custom-viwell-picker--swatch"}
          style={{ backgroundColor: color }}
        />
        <CustomFastField name={props.name} />
      </Space>
    </div>
  );
};
