export const InfoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 5C6.632 5 6.33333 4.70133 6.33333 4.33333C6.33333 3.96533 6.632 3.66667 7 3.66667C7.368 3.66667 7.66667 3.96533 7.66667 4.33333C7.66667 4.70133 7.368 5 7 5ZM7.66667 9.66667C7.66667 10.0347 7.368 10.3333 7 10.3333C6.632 10.3333 6.33333 10.0347 6.33333 9.66667V6.33333C6.33333 5.96533 6.632 5.66667 7 5.66667C7.368 5.66667 7.66667 5.96533 7.66667 6.33333V9.66667ZM7.00016 0.333344C3.31816 0.333344 0.333496 3.31801 0.333496 7.00001C0.333496 10.682 3.31816 13.6667 7.00016 13.6667C10.6815 13.6667 13.6668 10.682 13.6668 7.00001C13.6668 3.31801 10.6815 0.333344 7.00016 0.333344Z"
        fill="#4C4C4C"
      />
    </svg>
  );
};
