import styles from "./Question.module.scss";
import "./Question.module.scss";

import Icon from "@ant-design/icons";
import { Formik, Form, ErrorMessage } from "formik";
import { Fragment, useEffect, useMemo, useState } from "react";
import {
  PlusCircleFilled,
  CopyFilled,
  DeleteFilled,
  EditFilled,
  SaveFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import CollapseAble from "features/assessment/questions/collapseables/CollapseAble";
import CustomButton from "components/custom-button/CustomButton";
import TextOptions from "features/assessment/questions/text-options/TextOptions";
import QuestionHeader from "features/assessment/questions/Question/QuestionHeader";
import SubQuestions from "features/assessment/questions/sub-questions/SubQuestions";
import SliderOptions from "features/assessment/questions/slider-options/SliderOptions";
import RatingOptions from "features/assessment/questions/rating-options/RatingOptions";
import BooleanOptions from "features/assessment/questions/boolean-options/BooleanOptions";
import QuestionOptions from "features/assessment/questions/question-options/QuestionOptions";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import ICoreFields from "features/assessment/types/ICoreFields";
import { Select, Space } from "antd";
import { TAlphanumeric } from "types/TAlphaNumeric";
import { questionTypes } from "./questionTypes";
import generateUniqueId from "helpers/generateUniqueId";
import {
  useCreateQuestionMutation,
  useDeleteQuestionMutation,
  useLazyGetQuestionByIdQuery,
  useUpdateQuestionMutation,
} from "graphql/_generated/graphql";
import EnumBoolean from "features/assessment/types/EnumBoolean";
import {
  optionKey,
  QuestionTranformer,
} from "features/assessment/transformers/AssessmentTransformer";
import initialQuestionDifferentials from "features/assessment/data/initialQuestionDifferentials";
import initialQuestionObject from "features/assessment/data/initialQuestionObject";
import { customNotification } from "components/custom-notification/CustomNotification";
import { errorFormat } from "helpers";
import ICategories from "features/assessment/types/ICategories";
import MetricOptions from "../metric-options/MetricOptions";
import { useAddUpdateQuestionTranslation } from "features/assessment/hooks/useAddUpdateQuestionTranslation";
import MatrixTransformer from "features/assessment/transformers/MatrixTransformer";
import generateNewQuestion from "features/assessment/data/generateNewQuestion";
import {
  assessmentQuestionSchema,
  sliderOptionsRangeValidation,
} from "validations/schema/assessmentSchema";
import { useErrorTranslation } from "validations/schema/common/useErrorTranslation";
import { unixTimestampMs } from "helpers/DatetimeUtils";
import EnumQuestionTypes from "features/assessment/types/EnumQuestionTypes";
import CustomPopconfirm from "components/form/custom-pop-confirm/CustomPopConfirm";
import { sortableQuestionTypes } from "features/assessment/data/sortableTypes";
import { subQuestionsBasedOptionTypes } from "features/assessment/data/subQuestionBasedOptionTypes";
import EnumAssessmentTypes from "features/assessment/types/EnumAssessmentTypes";
import { useTranslation } from "react-i18next";
import { useErrorHandler } from "hooks/useErrorHandler";

interface QuestionProps extends ICoreFields {
  values: any;
  id: TAlphanumeric;
  SN: number;
  pillarId: TAlphanumeric;
  assessmentId: any;
  setFieldValue?: any;
  tags: any;
  categories: ICategories;
  assessmentTypeName: string;
  isSubQuestionsEnable?: boolean;
  deleteQuestion?: (pillarId: TAlphanumeric, questionId: TAlphanumeric) => any;
  cloneQuestion?: (pillarId: TAlphanumeric, questionClone: any) => any;
  reFetchAssessments?: (isRefetch?: boolean) => void;
  isAddLanguage?: boolean;
  oldRecord?: any;
  language?: string;
  resetField?: any;
  pqIndex?: number;
  assessmentSettings?: any;
}

let newQuestionDifferentials = {
  ...initialQuestionDifferentials,
};

/**
 * Assessment Question Component.
 *
 * @param props QuestionProps
 * @returns JSX
 */
const Question: React.FunctionComponent<QuestionProps> = (props) => {
  const { Option } = Select;
  const { assessmentTypeName } = props;
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();

  const [createQuestion, { isLoading }] = useCreateQuestionMutation();
  const [deleteAssessmentQuestion] = useDeleteQuestionMutation();
  const [updateQuestion, { isLoading: UpdateQuestionLoading }] =
    useUpdateQuestionMutation();
  const [getQuestionById] = useLazyGetQuestionByIdQuery();
  const {
    handleAddUpdateQuestionTranslation,
    isLoadingAddUpdateQuestionTranslation,
  } = useAddUpdateQuestionTranslation();

  const [sliderRangeError, setSliderRangeError] = useState<string>("");
  const [showHeader, setShowHeader] = useState<boolean>(false);
  const [isQuestionSaving, activateSaveMode] = useState<boolean>(false);
  const [deleteOptionId, setDeleteOptionId] = useState<Array<string>>([]);
  const [editMode, setEditMode] = useState<boolean>(false);
  const toggleEditMode = () => setEditMode(!editMode);

  const tags = useMemo(
    () => ({
      isLoading: props?.tags?.isLoading,
      data: props?.tags?.data?.map((item: any) => {
        return {
          value: item?.id,
          label: item?.title,
          questionId: props?.id,
        };
      }),
    }),
    [props?.tags],
  );

  useEffect(() => {
    if (props?.oldRecord) {
      resetTranslationFields();
    }
  }, []);

  const resetTranslationFields = () => {
    props?.resetField(
      `pillars.${props?.pillarId}.questions[${props?.values.id}].text`,
      props?.values?.translations?.text,
    );
    props?.resetField(
      `pillars.${props?.pillarId}.questions[${props?.values.id}].subText`,
      props?.values?.translations?.subText,
    );
    if (props?.values?.options) {
      props?.values?.options.map((item: any, optIndex: number) => {
        props?.resetField(
          `pillars.${props?.pillarId}.questions[${props?.values.id}].options[${optIndex}].text`,
          props?.values?.options[optIndex]?.translations?.text,
        );
        props?.resetField(
          `pillars.${props?.pillarId}.questions[${props?.values.id}].options[${optIndex}].subText`,
          props?.values?.options[optIndex]?.translations?.subText,
        );
      });
    }
    if (props?.values?.steps) {
      props?.values?.steps.map((item: any, optIndex: number) => {
        props?.resetField(
          `pillars.${props?.pillarId}.questions[${props?.values.id}].steps[${optIndex}].text`,
          props?.values?.steps[optIndex]?.translations?.text,
        );
        props?.resetField(
          `pillars.${props?.pillarId}.questions[${props?.values.id}].steps[${optIndex}].subText`,
          props?.values?.steps[optIndex]?.translations?.subText,
        );
      });
    }
  };

  /**
   * Generate new Option row.
   *
   * @param key string
   * @param arr any
   * @returns array
   */
  const addNewRowFunction = (key: string, arr: any) => {
    newQuestionDifferentials = {
      ...newQuestionDifferentials,
      subQuestions: [],
    };

    return [
      ...arr,
      {
        ...newQuestionDifferentials[key],
        SN: arr?.length + 1,
        id: generateUniqueId().toString(),
      },
    ];
  };

  const refetchAssessment = () => {
    if (props?.reFetchAssessments) {
      props.reFetchAssessments(true);
    }
  };

  /**
   * Delete Question
   */
  const handleDeleteFunction = () => {
    if (props?.deleteQuestion) {
      props?.deleteQuestion(props?.pillarId, props?.id);
    }
  };

  /**
   * Refetch Question
   *
   * @param props
   * @returns question
   */
  const refetchQuestion = async (props: any) => {
    try {
      let response: any = await getQuestionById({
        questionId: props?.questionId || "",
      }).unwrap();

      response = response?.getQuestionById;

      const tranformedQuestion = QuestionTranformer(
        response?.id,
        response,
        props?.values,
      );

      return tranformedQuestion;
    } catch (error: any) {
      handleError(error as string | object);
    }
  };

  const addUpdateTranslation = async (values: any, subQuestion?: boolean) => {
    const { assessmentId, text = "", subText = "", options } = values;
    const language = props?.language;
    try {
      let params: any = {
        create: {
          assessmentId,
          question: {
            language,
            options: options.map(
              ({ text = "", subText = "", id: optionId }: any) => {
                return { text, subText, language, optionId };
              },
            ),
            questionId: subQuestion ? values.id : props?.oldRecord?.id,
            subText,
            text,
          },
        },
      };

      const res = await handleAddUpdateQuestionTranslation(params);

      customNotification(
        "success",
        t("translation-added-updated-successfully"),
      );
    } catch (error: any) {
      handleError(error as string | object);
    }
  };

  /**
   * Save question function.
   *
   * @param values any
   */
  // const SaveQuestionFunction = async (values: MutationCreateQuestionArgs) => {
  const SaveQuestionFunction = async (values: any, subQuestion?: boolean) => {
    const isEdit = !!values?.id;

    try {
      if (props?.isAddLanguage) {
        addUpdateTranslation(values, subQuestion);
      } else {
        let params: any = { create: { ...values } };

        if (isEdit) {
          params = {
            questionId: values.id,
            updates: values.update,
          };
        }

        const GeneralQuestionFunction: any = isEdit
          ? updateQuestion
          : createQuestion;

        const response = await GeneralQuestionFunction(params).unwrap();
        // refetchAssessment();

        customNotification(
          "success",
          t(
            isEdit
              ? "question-updated-successfully"
              : "question-created-successfully",
          ),
        );

        return response;
      }
    } catch (error: any) {
      handleError(error as string | object);
    }
  };

  /**
   * Get options object for the specified question-type.
   *
   * @param type string
   * @param question any
   * @returns options
   */
  const getTypeBasedOptions = (type: string, question: any) => {
    switch (type) {
      case "ranking":
      case "singleChoice":
      case "multipleChoice":
        return question?.options;
      case "rating":
        return question?.ratings;
      case "slider":
        return question?.steps;
      case "boolean":
        return question?.booleans;
      case "matrix":
        return question?.metrices;
      default:
        return [];
    }
  };

  /**
   * Check if subquestion button should be enabled.
   *
   * @returns boolean
   */
  const isSubQuestionDisabled = (values: any): boolean => {
    return (
      !editMode ||
      props?.isAddLanguage ||
      values.savedOnServer !== EnumBoolean.YES ||
      (values.optionType === "ranking" &&
        values.subQuestions?.length === values.options_saved?.length) ||
      values.savedOnServer !== EnumBoolean.YES ||
      (values.optionType === "singleChoice" &&
        values.subQuestions?.length === values.options_saved?.length) ||
      (values.optionType === "multipleChoice" &&
        values.subQuestions?.length === values.options_saved?.length) ||
      (values.optionType === "boolean" &&
        values.subQuestions?.length === values.booleans_saved?.length) ||
      (values.optionType === "slider" &&
        values.subQuestions?.length === values.steps_saved?.length) ||
      (values.optionType === "ranking" && values.options_saved?.length === 0) ||
      (values.optionType === "singleChoice" &&
        values.options_saved?.length === 0) ||
      (values.optionType === "multipleChoice" &&
        values.options_saved?.length === 0) ||
      (values.optionType === "boolean" &&
        values.booleans_saved?.length === 0) ||
      (values.optionType === "slider" && values.steps_saved?.length === 0)
    );
  };

  /**
   * Generate the 'required default single option' for Text type questions.
   * OptionScore should be that of QuestionScore
   */
  const TextOptionGenerator = (questionValues: any): any => {
    return questionValues?.options?.map((optItem: any) => {
      return {
        score: questionValues?.score || 0,
        text: optItem?.text?.toString() || "Text",
        subText: optItem?.subText?.toString() || "Text",
        tags: [],
        id: optItem?.savedOnServer === EnumBoolean.YES ? optItem.id : undefined,
      };
    });
  };

  return (
    <Formik
      validateOnChange={true}
      validateOnBlur={true}
      enableReinitialize={true}
      initialValues={props.values}
      validationSchema={
        props?.isAddLanguage
          ? null
          : assessmentQuestionSchema({
              ...props?.assessmentSettings,
              assessmentTypeName,
            })
      }
      onSubmit={async (values, { setValues }) => {
        // Validate slider

        if (values.optionType === EnumQuestionTypes.SLIDER) {
          const validateSlider = sliderOptionsRangeValidation(values);
          if (!!validateSlider) {
            setSliderRangeError(validateSlider);
            return;
          } else {
            setSliderRangeError("");
          }
        }

        // Set options fields

        let options: any =
          getTypeBasedOptions(values?.optionType, values) || [];

        // Prepare options based on type

        if (values?.optionType === EnumQuestionTypes.MATRIX) {
          options = MatrixTransformer({
            values,
            tags,
          });
        } else if (values?.optionType === EnumQuestionTypes.TEXT) {
          options = TextOptionGenerator(values);
        } else {
          options = options?.map((optItem: any, index: number) => {
            return {
              score: optItem?.score || 0,
              text: optItem?.text?.toString() || "",
              subText: optItem?.subText?.toString() || "",
              emoji: values?.isEmojiEnable ? optItem?.emoji || null : null,
              start: optItem?.start || undefined,
              end: optItem?.end || undefined,
              ratingNumber: optItem?.ratingNumber || undefined,
              optionSortOrder: sortableQuestionTypes.includes(
                values?.optionType,
              )
                ? index
                : undefined,
              id:
                optItem?.savedOnServer === EnumBoolean.YES
                  ? optItem.id
                  : undefined,
              assessmentQuestionId:
                values?.savedOnServer === EnumBoolean.YES
                  ? values?.id
                  : undefined,
              tags: optItem?.tags?.map((tagtItem: any) => {
                return {
                  tagId: tagtItem,
                  tagName: tags?.data?.find((tag: any) => tag.value == tagtItem)
                    ?.label,
                };
              }),
            };
          });
        }

        // Prepare create question fields

        let payload: any = {
          assessmentId: props?.assessmentId,
          options,
          text: values?.text?.toString() || "",
          tagId: values?.area || "",
          score: values?.score || 0,
          pillarId: props.pillarId || "",
          categoryId: values?.categoryId || "",
          optionType: values?.optionType || "",
          subText: values?.subText?.toString() || "",
          isEmojiEnable: values?.isEmojiEnable,
          start: values?.start || undefined,
          end: values?.end || undefined,
          minChar: values?.minChar || undefined,
          maxChar: values?.maxChar || undefined,
        };

        // Redirect to translation function if isAddLanguage

        if (props?.isAddLanguage) {
          addUpdateTranslation(payload);
          return;
        }

        // Prepare update payload

        if (values.savedOnServer === EnumBoolean.YES) {
          payload = {
            id: values?.id,
            update: {
              text: values?.text?.toString() || "",
              subText: values?.subText?.toString() || "",
              score: values?.score || 0,
              tagId: values?.area || "",
              categoryId: values?.categoryId || "",
              options: options,
              isEmojiEnable: values?.isEmojiEnable,
              start: values?.start || undefined,
              end: values?.end || undefined,
              minChar: values?.minChar || undefined,
              maxChar: values?.maxChar || undefined,
              deleteOptionId: deleteOptionId,
              deleteTagId: [],
            },
          };
        }

        // create or update function

        activateSaveMode(true);

        const response = await SaveQuestionFunction(payload);

        // refetch created/updated question

        let savedQuestion = await refetchQuestion({
          questionId:
            values.savedOnServer === EnumBoolean.YES
              ? values?.id
              : response?.createQuestion?.id,
          values,
        });

        // console.log("before savedQuestion:>", savedQuestion);

        const deletedSubQuestionIds: Array<string> = ["782542242", "16171666"];

        // FILTERING SUBQUESTION IDS
        if (deletedSubQuestionIds?.length > 0) {
          savedQuestion.subQuestions = savedQuestion.subQuestions.filter(
            (subQues: any) => !deletedSubQuestionIds.includes(subQues.id),
          );
        }

        // console.log("after savedQuestion:>", savedQuestion);
        // set created/updated question

        if (!!savedQuestion) {
          setValues(savedQuestion);
        }

        activateSaveMode(false);

        toggleEditMode();
      }}
    >
      {({ values, setFieldValue, handleSubmit, setValues, errors }) => {
        // console.log("values:>", values);
        // console.log("errors:>", errors);

        // options
        const addRow = (key: string, parentKey?: string) => {
          setFieldValue(
            parentKey ?? key,
            addNewRowFunction(key, values?.[key]),
          );
        };

        // options
        const addSubQuestion = () => {
          const newSubQuestion = Object.values(
            generateNewQuestion(values?.subQuestions?.length || [], true),
          );
          const subQuestions = [...values.subQuestions, ...newSubQuestion];

          setFieldValue("subQuestions", subQuestions);
        };

        const handleClone = () => {
          if (props?.cloneQuestion) {
            props?.cloneQuestion(props?.pillarId, values);
          }
        };

        const deleteRow = async (key: string, id: string) => {
          let extraValues: any = {};

          const deletedOption = values?.[key]?.find(
            (item: any) => item.id === id,
          );

          let value: any = values?.[key]?.filter(
            (item: any) => item.id !== deletedOption?.id,
          );

          // REMOVING FROM DB
          const isSavedOnServer =
            deletedOption?.savedOnServer === EnumBoolean.YES;

          // WHILE DELETING OPTIONS DELETE DEPENDENT SUBQUESTIONS
          if (isSavedOnServer) {
            // console.log("Yes Saved on server");
            if (key === "subQuestions") {
              await deleteAssessmentQuestion({
                questionId: deletedOption?.id,
              }).unwrap();

              refetchAssessment();
            } else {
              // console.log("Not subquestion,key is:", key);

              setDeleteOptionId([...deleteOptionId, deletedOption?.id]);

              const fileretedSubQuestions = values?.subQuestions?.filter(
                (item: any) => item.parentOptionId !== id,
              );

              const savedOnServerKey = `${key}_saved`;

              extraValues = {
                [savedOnServerKey]: value,
                subQuestions: fileretedSubQuestions,
              };
            }
          }

          // console.log("extraVAlues:", extraValues);

          setValues({
            ...values,
            ...extraValues,
            [key]: value,
          });
        };

        return (
          <Form className={styles["assessment-create-form"]}>
            <div className={styles["question-container"]}>
              <CollapseAble
                collapsible="header"
                defaultActiveKey={["1"]}
                headerKey={"1"}
                expandIconPosition="end"
                extraProps={{
                  handleDelete: handleDeleteFunction,
                  handleEdit: toggleEditMode,
                  handleUpdate: handleSubmit,
                  handleCopy: handleClone,
                  isEditMode: editMode,
                  isAddLanguage: props?.isAddLanguage,
                  isUpdating: isQuestionSaving,
                }}
                hideExtra={false}
                onChange={(e) => setShowHeader(e?.length > 0 ? false : true)}
                header={
                  showHeader ? (
                    <Space className={"question-container--header"}>
                      <h1>{props?.pqIndex}</h1>

                      <CustomSelectField
                        defaultValue="singleChoice"
                        placeholder={t("question-type-placeholder")}
                        name="optionType"
                        disabled={
                          !editMode || values.savedOnServer === EnumBoolean.YES
                        }
                      >
                        {questionTypes?.map((item, index) => (
                          <Option key={index} value={item.value}>
                            <Space align="center">
                              <Icon component={item.icon} />
                              <div>{item.label}</div>
                            </Space>
                          </Option>
                        ))}
                      </CustomSelectField>

                      <CustomTextField
                        name="text"
                        placeholder={t("type-question-placeholder")}
                        disabled={!editMode}
                      />

                      <CustomTextField
                        name="score"
                        type="number"
                        placeholder={t("score-placeholder")}
                        disabled={!editMode}
                      />
                    </Space>
                  ) : (
                    <h1>{props?.pqIndex}</h1>
                  )
                }
              >
                <QuestionHeader
                  values={values}
                  tags={tags}
                  setFieldValue={setFieldValue}
                  isDisabled={!editMode}
                  isAddLanguage={props?.isAddLanguage}
                  oldRecord={props?.oldRecord}
                  pillarId={props.pillarId}
                  categories={props?.categories}
                  assessmentSettings={props?.assessmentSettings}
                  assessmentTypeName={assessmentTypeName}
                />

                {values?.optionType === "ranking" ||
                values?.optionType === "singleChoice" ||
                values?.optionType === "multipleChoice" ? (
                  <QuestionOptions
                    tags={tags}
                    values={values}
                    addOption={addRow}
                    deleteOption={deleteRow}
                    isDisabled={!editMode}
                    isAddLanguage={props?.isAddLanguage}
                    oldRecord={props?.oldRecord}
                    assessmentSettings={props?.assessmentSettings}
                  />
                ) : values?.optionType === "text" ? (
                  <TextOptions
                    values={values}
                    isDisabled={!editMode}
                    isAddLanguage={props?.isAddLanguage}
                  />
                ) : values?.optionType === "rating" ? (
                  <RatingOptions
                    tags={tags}
                    values={values}
                    addRating={addRow}
                    deleteRating={deleteRow}
                    setFieldValue={setFieldValue}
                    isDisabled={!editMode}
                    isAddLanguage={props?.isAddLanguage}
                    assessmentSettings={props?.assessmentSettings}
                  />
                ) : values?.optionType === "slider" ? (
                  <SliderOptions
                    tags={tags}
                    values={values}
                    addStep={addRow}
                    deleteStep={deleteRow}
                    isDisabled={!editMode}
                    isAddLanguage={props?.isAddLanguage}
                    oldRecord={props?.oldRecord}
                    rangeError={sliderRangeError}
                    assessmentSettings={props?.assessmentSettings}
                  />
                ) : values?.optionType === "boolean" ? (
                  <BooleanOptions
                    tags={tags}
                    values={values}
                    addBoolean={addRow}
                    deleteBoolean={deleteRow}
                    isDisabled={!editMode}
                    isAddLanguage={props?.isAddLanguage}
                    assessmentSettings={props?.assessmentSettings}
                  />
                ) : values?.optionType === "matrix" ? (
                  <MetricOptions
                    tags={tags}
                    values={values}
                    setValues={setValues}
                    isDisabled={!editMode}
                    setDeleteOptionIds={setDeleteOptionId}
                    deletedOptionIds={deleteOptionId}
                    isAddLanguage={props?.isAddLanguage}
                  />
                ) : null}

                <div className={`${styles["sub-question"]} sub-question`}>
                  {assessmentTypeName ==
                    EnumAssessmentTypes.VIWELL_ASSESSMENT &&
                  props?.assessmentSettings?.isSubQuestionsEnable &&
                  !!subQuestionsBasedOptionTypes.includes(values.optionType) ? (
                    <CustomButton
                      title={
                        !editMode
                          ? "Edit Question to Add Sub-Question"
                          : values.savedOnServer !== EnumBoolean.YES
                          ? "Save Question to add Sub-Question"
                          : "Add Sub-Question"
                      }
                      onClick={addSubQuestion}
                      icon={<PlusCircleFilled />}
                      className={
                        isSubQuestionDisabled(values)
                          ? "cross-btn"
                          : "primary-btn"
                      }
                      disabled={isSubQuestionDisabled(values)}
                    >
                      {t("add-sub-question")}
                    </CustomButton>
                  ) : null}
                </div>

                {assessmentTypeName == EnumAssessmentTypes.VIWELL_ASSESSMENT &&
                props?.assessmentSettings?.isSubQuestionsEnable &&
                !!subQuestionsBasedOptionTypes.includes(values.optionType) ? (
                  <Fragment>
                    {values?.subQuestions
                      ?.sort(
                        (a: any, b: any) =>
                          unixTimestampMs(a?.createdAt) -
                          unixTimestampMs(b?.createdAt),
                      )
                      ?.map((subQuestion: any, index: number) => (
                        <SubQuestions
                          key={index}
                          index={index}
                          {...subQuestion}
                          tags={tags}
                          categories={[]}
                          values={subQuestion}
                          isDisabled={!editMode}
                          pillarId={props.pillarId}
                          addRow={addNewRowFunction}
                          parentId={props?.values?.id}
                          deleteSubQuestion={deleteRow}
                          setFieldValue={setFieldValue}
                          resetField={props?.resetField}
                          refetchQuestion={refetchQuestion}
                          assessmentId={props?.assessmentId}
                          saveQuestion={SaveQuestionFunction}
                          isAddLanguage={props?.isAddLanguage}
                          getTypeBasedOptions={getTypeBasedOptions}
                          assessmentSettings={props?.assessmentSettings}
                          oldRecord={props?.oldRecord?.subQuestions[index]}
                          parentOptions={
                            values?.[optionKey(values?.optionType)]
                          }
                          isParentQuestionSavedOnServer={
                            values.savedOnServer === EnumBoolean.YES
                          }
                        />
                      ))}
                  </Fragment>
                ) : null}
              </CollapseAble>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Question;
