import * as Yup from "yup";

// Required email validation schema
export const requiredEmailValidation = (
  invalidMsg: string = "error:email-format",
  reqMsg: string = "error:email",
) => {
  return Yup.string().email(invalidMsg).required(reqMsg);
};

//Required password validation
export const requiredPasswordValidation = (
  reqMsg: string = "error:req-password",
) => {
  return Yup.string().required(reqMsg);
};

//Required new password validation
export const requiredNewPasswordValidation = (
  reqMsg: string = "error:req-password",
  minCharMsg: string = "error:password-min-char",
  lowerCaseMsg: string = "error:password-lower-case",
  upperCaseMsg: string = "error:password-upper-case",
  specialCharMsg: string = "error:password-special-char",
  numMsg: string = "error:password-num",
) => {
  return Yup.string()
    .required(reqMsg)
    .min(6, minCharMsg)
    .matches(/[a-z]+/, lowerCaseMsg)
    .matches(/[A-Z]+/, upperCaseMsg)
    .matches(/[@$!%*#?&]+/, specialCharMsg)
    .matches(/\d+/, numMsg);
};

//Confirm password validation
export const confirmPasswordValidation = (
  refField: string,
  reqMsg: string = "error:req-confirm-password",
  matchMsg: string = "error:error-password-match",
) => {
  return Yup.string()
    .oneOf([Yup.ref(refField), null], matchMsg)
    .required(reqMsg);
};

//Required image validation
export const requiredImgValidation = (reqMsg: string = "error:req-img") => {
  return Yup.string().required(reqMsg).nullable();
};

//Required Filed validation
export const requiredFieldValidation = (reqMsg: string = "error:req-field") => {
  return Yup.string().required(reqMsg);
};

//Required number field validation
export const requiredNumberFieldValidation = (
  reqMsg: string = "error:req-field",
) => {
  return Yup.number().required(reqMsg);
};

// Required Array of strings validation
export const requiredArrayOfStringValidation = (
  reqMsg: string = "error:req-array",
) => {
  return Yup.array().min(1).required(reqMsg);
};

//Required Boolean value validation
export const requiredBooleanValidation = (
  reqMsg: string = "error:req-boolean",
) => {
  return Yup.boolean().nullable().required(reqMsg);
};

//Required field with no white/blank spaces
export const requiredFieldWithoutSpaces = (
  reqMsg: string = "error:req-field",
  blankSpaceMsg: string = "error:blank-space",
) => {
  return Yup.string()
    .nullable()
    .required(reqMsg)
    .matches(/^\s*\S[\s\S]*$/, blankSpaceMsg);
};

//Required field of numbers
export const requiredFieldNumber = (
  reqMsg: string = "error:req-field",
  blankSpaceMsg: string = "error:blank-space",
) => {
  return Yup.number().required(reqMsg);
};

//Required filed with characters only and with no white/blank spaces
export const requiredAlphaFieldWithoutSpaces = (
  reqMsg: string = "error:req-field",
  blankSpaceMsg: string = "error:blank-space",
  alphaMsg: string = "error:alpha",
) => {
  return Yup.string()
    .required(reqMsg)
    .nullable()
    .matches(/^\s*\S[\s\S]*$/, blankSpaceMsg)
    .matches(/^[aA-zZ\s]+$/, alphaMsg);
};
