import { unixTimestampMs } from "helpers/DatetimeUtils";
import IQuestionOptions from "features/assessment/types/IQuestionOptions";

const optionsInitialObject: IQuestionOptions = {
  SN: 1,
  id: "",
  score: undefined,
  text: "",
  tags: [],
  emoji: undefined,
  createdAt: unixTimestampMs(),
  optionSortOrder: 0,
};

export default optionsInitialObject;
