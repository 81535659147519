import * as Yup from "yup";
import * as Validations from "./common/validations";

export const positionSchema = Yup.object().shape({
  name: Validations.requiredFieldWithoutSpaces("name"),
  // description: Validations.requiredFieldWithoutSpaces("description"),
  departmentId: Validations.required("department"),
  translations: Yup.array().of(
    Yup.object().shape({
      lang: Validations.required("language"),
      name: Validations.required("name"),
    }),
  ),
});
