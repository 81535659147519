import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { customNotification } from "components/custom-notification/CustomNotification";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { CustomUploadFile } from "components/form/custom-upload-file/CustomUploadFile";
import { errorFormat } from "helpers";
import { Formik, Form } from "formik";
import {
  InviteClientAdmin,
  useGetClientAdminListQuery,
  useGetConfigRolesQuery,
  useLazyGetClientAdminListQuery,
} from "graphql/_generated/graphql";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { api } from "store/store";
import { store } from "store/store";
import { clientAdminSchema } from "validations/schema";
import { useClientAdmin } from "../useClientAdmin";
import { useUpdateAdmin } from "../hooks/useUpdateAdmin";
import { Modal } from "antd";
import { CONFIRM_MODAL_CONFIG } from "helpers/constants";
import styles from "./AddClientAdmin.module.scss";
import { useErrorHandler } from "hooks/useErrorHandler";

interface AddClientAdminProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  editId?: string | null;
  setEditId: React.Dispatch<React.SetStateAction<string | null>>;
}

const AddClientAdmin: React.FunctionComponent<AddClientAdminProps> = ({
  setEditId,
  setVisible,
  visible,
  editId,
}) => {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState<InviteClientAdmin>({
    image: "",
    firstName: "",
    lastName: "",
    email: "",
    roleId: "",
  });

  const { data: rolesData } = useGetConfigRolesQuery();
  const { refetch: fetchClientAdmin } = useGetClientAdminListQuery();
  const [getClientAdminListFun] = useLazyGetClientAdminListQuery();
  const { isLoading, inviteClientAdmin } = useClientAdmin();
  const { handleError } = useErrorHandler();
  const {
    handleGetAdmin,
    handleUpdateClientAdminEmail,
    handleUpdateClientAdminProfile,
    isLoading: isLoadingUpdateAdmin,
  } = useUpdateAdmin();

  const handleCancel = () => {
    setVisible(false);
    setEditId(null);
  };

  useEffect(() => {
    if (editId) {
      handleGetAdmin(editId).then((data) => {
        setInitialValues({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          image: data.image,
          roleId: data?.roles[0]?.id,
        });
      });
    }
  }, [editId]);

  return (
    <CustomModal
      width={600}
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      title={editId ? t("edit-admin") : t("add-admin")}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={clientAdminSchema}
        onSubmit={async (values, { resetForm }) => {
          const { email, ...restUpdate } = values;
          if (values.email !== initialValues.email && editId) {
            setVisible(false);
            Modal.confirm({
              ...CONFIRM_MODAL_CONFIG,
              closable: true,
              centered: true,
              title: t("warning"),
              content: (
                <>
                  <div className={styles["confirm-content"]}>
                    {t("confirm-email-change-for-admin")}
                  </div>
                </>
              ),
              width: 600,
              onOk: async () => {
                try {
                  // First API call
                  const msg = await handleUpdateClientAdminEmail(editId, email);
                  customNotification("success", msg);
                  if (
                    values.firstName !== initialValues.firstName ||
                    values.lastName !== initialValues.lastName ||
                    values.roleId !== initialValues.roleId ||
                    values.image !== initialValues.image
                  ) {
                    // Second API call
                    await handleUpdateClientAdminProfile({
                      clientId: editId,
                      update: restUpdate,
                    });
                    await getClientAdminListFun().unwrap();
                  }

                  resetForm();
                  setVisible(false);
                  setEditId(null);
                } catch (error) {
                  handleError(error as string | object);
                }
              },
            });
          } else if (editId && values.email === initialValues.email) {
            try {
              // Update profile directly
              await handleUpdateClientAdminProfile({
                clientId: editId,
                update: restUpdate,
              });

              // Fetch data after update
              fetchClientAdmin();

              resetForm();
              setVisible(false);
              setEditId(null);
              customNotification("success", t("admin-update-successfully"));
            } catch (error) {
              handleError(error as string | object);
            }
          } else {
            try {
              // Invite new client admin
              await inviteClientAdmin(values);

              // Fetch data after invite
              fetchClientAdmin();
              resetForm();
              setVisible(false);
            } catch (error) {
              handleError(error as string | object);
            }
          }
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <CustomUploadFile
              name="image"
              setFieldValue={setFieldValue}
              imgUrl={values.image}
              accept="image/*"
            />
            <CustomTextField
              name="firstName"
              label={t("first-name")}
              placeholder={t("enter-first-name")}
            />
            <CustomTextField
              name="lastName"
              label={t("last-name")}
              placeholder={t("enter-last-name")}
            />
            <CustomTextField
              type="email"
              name="email"
              label={t("email")}
              placeholder={t("admin-email")}
            />
            <CustomSelectField
              allowClear
              label={t("role")}
              className="form-select"
              name="roleId"
              placeholder={t("select-role")}
              options={rolesData?.getConfigRoles.options}
              fieldNames={{
                label: "label",
                value: "id",
              }}
            />

            <ModalFooter
              loading={isLoading || isLoadingUpdateAdmin}
              saveText={t("save-admin")}
              onCancel={handleCancel}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddClientAdmin;
