export const PositionSvg = () => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33366 2.58333C7.33366 2.35333 7.52033 2.16667 7.75033 2.16667H10.2503C10.4803 2.16667 10.667 2.35333 10.667 2.58333V3.83333H7.33366V2.58333ZM4.83366 15.5H13.167V3.83333H12.3337V2.58333C12.3337 1.435 11.3987 0.5 10.2503 0.5H7.75033C6.60199 0.5 5.66699 1.435 5.66699 2.58333V3.83333H4.83366V15.5ZM14.8337 3.83333V15.5C16.212 15.5 17.3337 14.3783 17.3337 13V6.33333C17.3337 4.955 16.212 3.83333 14.8337 3.83333ZM0.666992 6.33333C0.666992 4.955 1.78866 3.83333 3.16699 3.83333V15.5C1.78866 15.5 0.666992 14.3783 0.666992 13V6.33333Z"
        fill="#303030"
      />
    </svg>
  );
};
