import * as React from "react";
import CheckInItem from "./CheckInItem";
import { PWD_VALIDATION_RULES } from "validations/schema/common/validationHelpers";
import { useTranslation } from "react-i18next";
import styles from "./CheckInItem.module.scss";

export type ChecklisItem = { id: string; title?: string };
interface CheckListProps {
  items?: ChecklisItem[];
  failedItems?: string[] | null;
  isValid?: boolean;
}

const CheckList: React.FunctionComponent<CheckListProps> = ({
  items,
  failedItems,
  isValid,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      {failedItems?.includes(PWD_VALIDATION_RULES.REQ) && (
        <div className={styles["req-password"]}>{t("password-req")}</div>
      )}
      <div className={styles["password-rules"]}>
        <div className={styles["title"]}>{t("your-password-validation")}</div>
        {items?.map((item: ChecklisItem) => (
          <CheckInItem
            key={item.id}
            title={item.title}
            status={failedItems ? !failedItems.includes(item.id) : null}
            isValid={isValid}
          />
        ))}
      </div>
    </div>
  );
};

export default CheckList;
