import { Card, Skeleton, CardProps } from "antd";
import * as React from "react";
import "./CustomCard.module.scss";
interface CustomCardProps extends CardProps {
  cardLoading?:boolean;
}

const CustomCard: React.FunctionComponent<CustomCardProps> = (props) => {
  return (
    <Card {...props}>
      {props.cardLoading ? (
        <Skeleton active avatar paragraph={{ rows: 6 }} />
      ) : (
        props.children
      )}
    </Card>
  );
};

export default CustomCard;
