import { useToggleContentActivationMutation } from "graphql/_generated/graphql";
export const useToggleActivationContent = () => {
  const [ActivationDeactivationFun, { isLoading }] =
    useToggleContentActivationMutation();
  //this function is responsible for Activation/Deactivation Specialist
  const handleActivationContent = async (id: string) => {
    try {
      const res = await ActivationDeactivationFun({
        toggleContentActivationId: id,
      }).unwrap();
      return res.toggleContentActivation;
    } catch (error) {
      throw error;
    }
  };
  return {
    isLoading,
    handleActivationContent,
  };
};
