import { Col, Row } from "antd";
import ViwellLogo from "assets/images/viwell-mob-white.svg";
import * as React from "react";
import styles from "./AuthLayout.module.scss";
interface SignInLayoutProps {
  title: string;
  subTitle?: string;
  children: React.ReactNode;
  id?: string;
}

const SignInLayout: React.FunctionComponent<SignInLayoutProps> = ({
  title,
  subTitle,
  children,
  id,
}) => {
  return (
    <div className={styles["sign-in"]} id={id}>
      <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
        <Col sm={12}>
          <div className={styles["content"]}>
            <div className={styles["viwell-logo"]}>
              <img src={ViwellLogo} alt="Viwell" />
            </div>
            <h1 className={styles["title"]}>{title}</h1>
            {subTitle && <h2 className={styles["sub-title"]}>{subTitle}</h2>}
            {children}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SignInLayout;
