import {
  useGetClientListQuery,
  useToggleClientActivationMutation,
} from "graphql/_generated/graphql";

export type TypeToggleInviteCompany = (id: string) => Promise<boolean>;

//define the type of useCompany hook
type TypeToggleInviteCompanyHook = () => {
  isLoading: boolean;
  handleToggleActivationCompany: TypeToggleInviteCompany;
};

export const useToggleInviteCompanyActivation: TypeToggleInviteCompanyHook =
  () => {
    const { refetch, isLoading: getClientsLoading } = useGetClientListQuery();

    const [userActivationToggleFun, { isLoading }] =
      useToggleClientActivationMutation();

    //this function is responsible for activate and deactivate client
    const handleToggleActivationCompany = async (id: string) => {
      try {
        const res = await userActivationToggleFun({
          toggleClientActivationId: id,
        }).unwrap();
        refetch();
        return res.toggleClientActivation;
      } catch (error: any) {
        throw error;
      }
    };

    return {
      isLoading: isLoading || getClientsLoading,
      handleToggleActivationCompany,
    };
  };
