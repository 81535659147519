const isArabicOrUrdu = (text: string): boolean => {
  // Arabic and Urdu Unicode ranges
  const arabicUrduRegex =
    /[\u0600-\u06FF\u0750-\u077F\uFB50-\uFDFF\uFE70-\uFEFF]/;
  return arabicUrduRegex.test(text);
};

export const getLangDir = (text?: string): "ltr" | "rtl" => {
  if (text === undefined || text === null) {
    return "ltr";
  }
  const totalCharacters = text?.length;
  const arabicOrUrduCharacters = Array.from(text).reduce(
    (count, char) => (isArabicOrUrdu(char) ? count + 1 : count),
    0,
  );
  const containsArabicOrUrdu = arabicOrUrduCharacters > totalCharacters / 2;

  if (containsArabicOrUrdu) {
    return "rtl";
  } else {
    return "ltr";
  }
};
