import {
  MutationCreateWellBeingCalenderArgs,
  useCreateWellBeingCalenderMutation,
} from "graphql/_generated/graphql";

export const useAddWellBeingCalender: any = () => {
  const [addNewWellBeingCalenderFun, { isLoading }] =
    useCreateWellBeingCalenderMutation();

  //this function is responsible for adding the WellBeingCalender
  const handleAddNewWellBeingCalender = async (
    values: MutationCreateWellBeingCalenderArgs,
  ) => {
    try {
      await addNewWellBeingCalenderFun({ create: values.create }).unwrap();
    } catch (error) {
      throw error;
    }
  };

  return {
    isLoading,
    handleAddNewWellBeingCalender,
  };
};
