import { List, Pagination } from "antd";
import CustomModal from "components/custom-modal/CustomModal";
import DataTableFilters from "features/challenge/challenge-detail/DataTableFilters";
import styles from "features/rewards/css/CompanyStats.module.scss";
import { PaginationInput, TierCompanyList } from "graphql/_generated/graphql";
import { useTranslation } from "react-i18next";
import CompanyItem from "./CompanyItem";

interface CompanyStatsDetailsProps {
  visible: boolean;
  data: TierCompanyList | undefined;
  pagination: PaginationInput;
  currentPage: number;
  isFetching: boolean;
  onPaginationChange: (page: number, pageSize: number) => void;
  handleSearchInput: (value: string) => void;
  handleCancel: () => void;
}

const CompanyStatsDetails: React.FunctionComponent<
  CompanyStatsDetailsProps
> = ({
  visible,
  data,
  pagination,
  currentPage,
  isFetching,
  onPaginationChange,
  handleSearchInput,
  handleCancel,
}) => {
  const { t } = useTranslation();

  return (
    <CustomModal
      title={`${t("companies")} (${data?.metadata.total ?? 0})`}
      onCancel={handleCancel}
      visible={visible}
      footer={null}
      width={720}
    >
      <DataTableFilters
        isCompanyFilter={false}
        handleSearchInput={handleSearchInput}
      />
      <div className={styles["desc-container"]}>
        <List
          dataSource={data?.data}
          loading={isFetching}
          renderItem={(item, index) => (
            <CompanyItem
              id={Number(
                `${
                  index + 1 + `${pagination?.offset ? pagination?.offset : 0}`
                }`,
              )}
              title={item.companyName}
              image={item.image ?? ""}
              users={item.usersCount}
            />
          )}
        />
      </div>
      <div className={"pagination-margin"}>
        <Pagination
          onChange={onPaginationChange}
          current={currentPage}
          pageSize={pagination?.limit ?? 0}
          showTotal={(total, range) => (
            <span>
              Showing&nbsp;
              <b>
                {range[0]}-{range[1]}
              </b>
              &nbsp;of <b>{total}</b>&nbsp; items
            </span>
          )}
          total={data?.metadata?.total || 0}
        />
      </div>
    </CustomModal>
  );
};

export default CompanyStatsDetails;
