import { useDeleteChallengeMutation } from "graphql/_generated/graphql";

export const useDeleteChallenge = () => {
  const [DeleteFun, { isLoading }] = useDeleteChallengeMutation();

  //this function is responsible for deleting Challenge
  const handleDeleteChallenge = async (id: string) => {
    try {
      const res = await DeleteFun({
        deleteChallengeId: id,
      }).unwrap();
      return res.deleteChallenge;
    } catch (error) {
      throw error;
    }
  };
  return {
    isLoading,
    handleDeleteChallenge,
  };
};
