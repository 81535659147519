import * as Yup from "yup";
import * as Validations from "./common/validations";

export const tagSchema = Yup.object().shape({
  title: Validations.requiredFieldWithoutSpaces("name"),
  translations: Yup.array().of(
    Yup.object().shape({
      lang: Validations.required("language"),
      title: Validations.required("name"),
    })
  ),
});
