import { customNotification } from "components/custom-notification/CustomNotification";
import {
  InviteClientAdmin,
  useGetClientAdminListQuery,
  useInviteClientAdminMutation,
} from "graphql/_generated/graphql";
import { useTranslation } from "react-i18next";

export const useInviteAdmin = () => {
  const [inviteAdmin, { isLoading }] = useInviteClientAdminMutation();
  const { refetch, isLoading: getAdminsLoading } = useGetClientAdminListQuery();
  const { t } = useTranslation();

  const inviteClientAdmin = async (values: InviteClientAdmin) => {
    try {
      await inviteAdmin({ inviteClientAdminCreate: values }).unwrap();
      customNotification("success", t("admin-invited-success"));
      refetch();
    } catch (error: any) {
      throw error;
    }
  };

  return { isLoading: isLoading || getAdminsLoading, inviteClientAdmin };
};
