import {
  GetEmployeeParticipatingDetailQuery,
  useLazyGetEmployeeParticipatingDetailQuery,
} from "graphql/_generated/graphql";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useEffect, useState } from "react";

export type ParticipantUserType = {
  eventId: string;
  clientId: string;
};

export const useParticipantUsersModel = (props: ParticipantUserType) => {
  const { eventId, clientId } = props;

  const { handleError } = useErrorHandler();
  const [getEmployeeFun, { isFetching: isLoading }] =
    useLazyGetEmployeeParticipatingDetailQuery();

  const [userData, setUserData] =
    useState<
      GetEmployeeParticipatingDetailQuery["getEmployeeParticipatingDetail"]
    >();
  const [searchText, setSearchText] = useState<string>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pagination, setPagination] = useState({
    limit: 10,
    offset: 0,
  });

  const onChangePagination = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPagination({
      offset: (page - 1) * pageSize,
      limit: pageSize,
    });
  };
  const handleSearchInput = (value: string) => {
    setSearchText(value);
    setPagination({
      limit: 10,
      offset: 0,
    });
  };

  useEffect(() => {
    const getEmployeesData = async () => {
      try {
        if (eventId && clientId) {
          const { getEmployeeParticipatingDetail } = await getEmployeeFun({
            data: {
              eventId: eventId,
              companyId: clientId,
            },
            filters: {
              search: {
                text: String(searchText),
              },
            },
            pagination: pagination,
          }).unwrap();
          setUserData(getEmployeeParticipatingDetail);
        }
      } catch (error) {
        handleError(error as string | object);
      }
    };
    getEmployeesData();
  }, [searchText, currentPage, pagination, eventId, clientId]);

  const onCloseUserModal = () => {
    setUserData(undefined);
  };

  return {
    userData: userData?.data,
    totalUsers: userData?.total,
    currentPage,
    pagination,
    searchText,
    isLoading,
    onChangePagination,
    handleSearchInput,
    onCloseUserModal,
  };
};
