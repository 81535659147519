import { Fragment, useEffect, useState } from "react";
import CollapseAble from "features/assessment/questions/collapseables/CollapseAble";
import ICoreFields from "features/assessment/types/ICoreFields";
import { useLazyGetCategoriesTreeQuery } from "graphql/_generated/graphql";
import initialQuestionDifferentials from "features/assessment/data/initialQuestionDifferentials";
import AssessmentPillerHeading from "./AssessmentPillerHeading";
import Question from "features/assessment/questions/Question";
import EnumAssessmentTypes from "features/assessment/types/EnumAssessmentTypes";
import AddElement from "components/AddElement/AddElement";
import ICategories from "features/assessment/types/ICategories";
import { unixTimestampMs } from "helpers/DatetimeUtils";

interface QuestionProps extends ICoreFields {
  assessmentPillar: any;
  assessmentTypeName: string;
  assessmentId: string | null;
  setFieldValue?: any;
  tags: any;
  isSubQuestionsEnable?: boolean;
  deleteQuestion?: (pillarId: string, questionId: string) => any;
  addNewQuestion?: (pillarId: string, questionClone?: any) => any;
  reFetchAssessments?: (isRefetch?: boolean) => void;
  assessmentSettings?: any;
}

let newQuestionDifferentials = {
  ...initialQuestionDifferentials,
};

/**
 * Assessment Pillar Component.
 *
 * @param props PillarProps
 * @returns JSX
 */
const Pillar: React.FunctionComponent<QuestionProps> = (props) => {
  const { assessmentPillar, assessmentTypeName, assessmentId, tags } = props;

  const totalQuestions = Object.values(assessmentPillar.questions ?? {}).length;

  const [getPillarCategories] = useLazyGetCategoriesTreeQuery();

  const [categories, setCategories] = useState<ICategories>({
    data: [],
    loading: true,
  });

  // GET PILLAR BASE CATEGORIES
  const getCategories = async () => {
    try {
      const response = await getPillarCategories({
        pillarId: props?.assessmentPillar?.id,
      }).unwrap();

      setCategories({
        loading: false,
        data: response?.getCategoriesTree,
        // ?.map((item: any) => {
        //   return {
        //     value: item?.id,
        //     label: item?.name,
        //   };
        // }),
      });
    } catch (error) {
      console.log("categories error:>", error);
      setCategories({
        ...categories,
        loading: false,
      });
    }
  };

  useEffect(() => {
    if (props?.assessmentPillar?.id) getCategories();
  }, [props?.assessmentPillar?.id]);

  // if (assessmentPillar.id === "635bd72d2772716e3306a831") {
  //   console.log("assessmentPillar>:", assessmentPillar.questions);
  // }
  return (
    <CollapseAble
      className="pillar"
      headerKey={assessmentPillar?.id}
      defaultActiveKey={0}
      expandIconPosition="end"
      hideExtra={true}
      header={
        <AssessmentPillerHeading
          title={assessmentPillar?.title}
          icon={assessmentPillar?.icon}
          questionsCount={totalQuestions}
          assessmentType={assessmentTypeName}
        />
      }
    >
      {/* QUESTIONS SECTION */}

      {Object.values(assessmentPillar?.questions ?? {})
        // Sort by createdAt
        // ?.sort((a: any, b: any) => a?.SN - b?.SN)
        ?.sort(
          (a: any, b: any) =>
            unixTimestampMs(a?.createdAt) - unixTimestampMs(b?.createdAt),
        )
        ?.map((pillarQuestion: any, pqIndex: number) => (
          <Question
            {...pillarQuestion}
            assessmentId={assessmentId}
            assessmentTypeName={assessmentTypeName}
            categories={categories}
            values={pillarQuestion}
            pillarId={assessmentPillar?.id}
            tags={tags}
            key={pqIndex}
            pqIndex={pqIndex + 1}
            deleteQuestion={props.deleteQuestion}
            cloneQuestion={props.addNewQuestion}
            reFetchAssessments={props.reFetchAssessments}
            assessmentSettings={props?.assessmentSettings}
          />
        ))}

      {/* ADD QUESTION */}
      <AddElement
        id={assessmentPillar?.id}
        handleAdd={() => {
          if (!!props.addNewQuestion)
            props.addNewQuestion(assessmentPillar?.id, false);
        }}
      />

      {/* {pillar.content} */}
    </CollapseAble>
  );
};

export default Pillar;
