import Icon from "@ant-design/icons";
import { CalendarIcon } from "assets/icons/CalendarIcon";
import { ErrorMessage } from "formik";
import { TimePicker, TimePickerProps } from "formik-antd";
import { useErrorTranslation } from "validations/schema/common/useErrorTranslation";
import styles from "./CustomTimePicker.module.scss";

type Props = TimePickerProps & {
  label?: string;
  subLabel?: string;
  defaultValue?: any;
};

export function CustomTimePicker(props: Props) {
  const { errorT } = useErrorTranslation();
  const { name, label, placeholder, className, subLabel, ...rest } = props;

  return (
    <div className={styles["form-control"]}>
      {label && (
        <label className={styles["label"]} htmlFor={name}>
          {label}
        </label>
      )}
      {subLabel && (
        <label className={styles["subLabel"]} htmlFor={name}>
          {subLabel}
        </label>
      )}

      <TimePicker
        name={name}
        id={name}
        placeholder={placeholder || ""}
        className={className ? className : "custom-date"}
        suffixIcon={<Icon component={CalendarIcon} />}
        {...rest}
      />
      <ErrorMessage
        name={name}
        render={(msg) => <div style={{ color: "red" }}>{errorT(msg)}</div>}
      />
    </div>
  );
}
