import { MoreOutlined } from "@ant-design/icons";
import {
  Avatar,
  Dropdown,
  Form,
  Modal,
  Select,
  Space,
  Switch,
  Tag,
} from "antd";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import CustomButton from "components/custom-button/CustomButton";
import CustomMenu from "components/custom-menu/CustomMenu";
import { customNotification } from "components/custom-notification/CustomNotification";
import CustomTable from "components/custom-table/CustomTable";
import CustomPopconfirm from "components/form/custom-pop-confirm/CustomPopConfirm";
import CustomTreeSelect from "components/form/custom-tree-select/CustomTreeSelect";
import { Formik } from "formik";
import {
  Employee,
  useGetClientsTreeQuery,
  useGetEmployeeListQuery,
  useLazyGetEmployeeListQuery,
} from "graphql/_generated/graphql";
import { PERMS, cloneObject } from "helpers";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import { useLoggedInUser } from "hooks/useLoggedInUser";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import EditEmployee from "./EditEmployee";
import { useUsers } from "./hooks/useUsers";
import UserDetails from "./invite-users/EmployeeDetails";
import { useErrorHandler } from "hooks/useErrorHandler";
import { CONFIRM_MODAL_CONFIG } from "helpers/constants";
import { ColumnsType } from "antd/lib/table";

const { Option } = Select;
type Props = {
  employerId?: string;
  companyChildren?: Array<any>;
  setInviteVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  isProfileComplete?: boolean;
};

const UsersList = ({
  employerId,
  setInviteVisible,
  companyChildren,
  isProfileComplete,
}: Props) => {
  const { t } = useTranslation();
  const [localFilters, setLocalFilters] = useState<any>(null);
  const { isFetching, refetch } = useGetEmployeeListQuery();
  const { handleUserActivationToggle } = useUsers();
  const [columnsData, setColumnsData] = useState<any>();
  const [getAllUsersDataFun] = useLazyGetEmployeeListQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [employeeId, setEmployeeId] = useState<string | null>(null);
  const [selectedEmployee, setSelectedEmployee] = useState<any>();
  const [selectedEmployees, setSelectedEmployees] = useState<React.Key[]>([]);
  const { data } = useGetClientsTreeQuery();
  const [companyId, setCompanyId] = useState<string | undefined>(undefined);
  const { isSuperAdmin } = useLoggedInUser();
  const region = useSelector((state: RootState) => state.auth.region);
  const isDisplayedOnCompanyPage = !!employerId;
  const { handleResendInvite, handleResendBulkInvite } = useUsers();
  const { handleError } = useErrorHandler();

  const [showUserDetail, setShowUserDetail] = useState(false);

  const employerFilter = {
    value: employerId,
    key: "employer.id",
    comparison: "Equal",
  };

  useEffect(() => {
    if (isDisplayedOnCompanyPage) {
      setLocalFilters({
        filters: {
          and: [employerFilter],
        },
      });
    }
  }, [employerId]);

  useEffect(() => {
    fetchData();
  }, [isFetching, localFilters, region]);

  const fetchData = () => {
    if (isDisplayedOnCompanyPage && !localFilters?.filters) return;

    fetchDataFromApi(getAllUsersDataFun, setColumnsData, localFilters).catch(
      (e) => handleError(e),
    );
  };

  const resendInviteCompany = async (id: string) => {
    try {
      await handleResendInvite({ id });
      customNotification("success", "Resent Invite successfully");
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const handleMenu = ({ key, id, record }: any) => {
    if (key === "Edit-employee") {
      setVisible(true);
      setEmployeeId(id);
      setSelectedEmployee(record);
    } else if (key === "resend-invite") {
      resendInviteCompany(id);
    } else if (key === "view-details") {
      setShowUserDetail(true);
      setEmployeeId(id);
    }
  };

  const columns: ColumnsType<Employee> = [
    {
      title: `${t("id")}`,
      key: "id",
      sorter: {
        multiple: 1,
      },
      render: (_text, _record, index) => {
        return (currentPage - 1) * pageSize + index + 1;
      },
    },
    {
      title: t("name"),
      dataIndex: "firstName",
      key: "firstName",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, index) => (
        <Space>
          <CustomAvatar
            imgSrc={record.image ? record.image : record?.avatar?.imgPath}
            isUserProfile={!!record.image}
          />
          <div className="cut-text">
            {record.firstName} {record.lastName}
          </div>
        </Space>
      ),
    },
    {
      title: t("email"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("company"),
      dataIndex: "companyName",
      key: "companyName",
      render: (_text, record, index) => record.employer.companyName,
    },
    // {
    //   title: t("department"),
    //   dataIndex: "department",
    //   key: "department",
    //   render: (_text: any, record: any, index: number) =>
    //     record.department?.name,
    // },
    {
      title: t("registration-date"),
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: {
        multiple: 1,
      },
      render: (createdAt: string) =>
        moment(createdAt).format("MMMM Do YYYY, h:mm:ss a"),
    },
    {
      title: t("profile-state"),
      dataIndex: "isProfileComplete",
      key: "isProfileComplete",
      align: "center" as "center",
      render: (isProfileComplete: boolean) =>
        isProfileComplete ? (
          <Tag className="active-tag">{t("completed")}</Tag>
        ) : (
          <Tag className="de-active-tag">{t("pending")}</Tag>
        ),
    },
    {
      title: t("status"),
      dataIndex: "isActive",
      key: "isActive",
      align: "center" as "center",
      render: (isActive: boolean) =>
        isActive ? (
          <Tag className="active-tag">{t("active")}</Tag>
        ) : (
          <Tag className="de-active-tag">{t("deactivated")}</Tag>
        ),
    },
    {
      align: "right" as "right",
      title: t("actions"),
      dataIndex: "id",
      key: "id",
      render: (_text, record, index) => (
        <CheckPermissions permission={PERMS.TOGGLE_USER_ACTIVATION}>
          <CustomPopconfirm
            onConfirm={async () => {
              try {
                const isUserActive = await handleUserActivationToggle(
                  record.id,
                );
                refetch();
                customNotification(
                  "success",
                  isUserActive
                    ? t("employee-activation-successful")
                    : t("employee-deactivation-successful"),
                );
              } catch (error) {
                handleError(error as string | object);
              }
            }}
            title={`Are you sure you want to ${
              record.isActive ? t("deactivate") : t("activate")
            } the Employee`}
          >
            <Switch
              className="custom-switch"
              defaultChecked
              checked={record.isActive}
            />
          </CustomPopconfirm>
        </CheckPermissions>
      ),
    },
    {
      width: "5%",
      align: "center" as "center",
      dataIndex: "id",
      key: "id",
      render: (id: string, record: any) => (
        <Dropdown
          overlay={
            <CheckPermissions permission={PERMS.INVITE_EMPLOYEE}>
              <CustomMenu
                items={[
                  { label: t("view-detail"), key: "view-details" },
                  { label: t("edit-employee"), key: "Edit-employee" },
                  ...(!record?.isProfileComplete
                    ? [{ label: t("resend-invite"), key: "resend-invite" }]
                    : []),
                ]}
                onClick={({ domEvent, key, keyPath }) =>
                  handleMenu({ domEvent, key, keyPath, id, record })
                }
              />
            </CheckPermissions>
          }
        >
          <CustomButton type="link" className="link-btn">
            <MoreOutlined />
          </CustomButton>
        </Dropdown>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: Employee[]) => {
      setSelectedEmployees(selectedRowKeys);
    },
    selectedRowKeys: selectedEmployees,
    getCheckboxProps: (record: Employee) => ({
      disabled: record.isProfileComplete === true,
      name: `${record.firstName} ${record.lastName}`,
    }),
  };

  const bulkRemind = () => {
    Modal.confirm({
      ...CONFIRM_MODAL_CONFIG,
      title: t("resend-bulk-reminders"),
      content: (
        <>
          <div>
            {t("sure-resend-reminders")} {selectedEmployees.length}{" "}
            {t("employees")}?
          </div>
        </>
      ),
      onOk: async () => {
        try {
          await handleResendBulkInvite({
            userIds: selectedEmployees as string[],
          });
          customNotification(
            "success",
            `${t("bulk-reminders")} ${t("to")} ${selectedEmployees.length} ${t(
              "employees",
            )} ${t("was-sent")}`,
          );
          setSelectedEmployees([]);
        } catch (error) {
          handleError(error as string | object);
        }
      },
    });
  };

  return (
    <>
      <CustomTable
        rowSelection={rowSelection}
        rowKey="id"
        filters={{
          handleCallback: async (filtersData: any) => {
            let mergedFilters = cloneObject(filtersData);
            const {
              filters: { or = [], and = [], search } = {
                or: [],
                and: [],
                search: undefined,
              },
            } = mergedFilters;

            if (isDisplayedOnCompanyPage) {
              mergedFilters.filters = {
                and: [
                  ...(and.find((x: any) => x.key === "employer.id")
                    ? and
                    : [...and, ...[employerFilter]]),
                ],
                or,
                search,
              };
            }

            setPageSize(mergedFilters?.pagination?.offset?.pageSize);
            setCurrentPage(mergedFilters?.pagination?.offset?.page);
            setLocalFilters(mergedFilters);
          },
          date: [
            {
              applyAs: "and",
              key: "createdAt",
              comparison: "Range",
            },
          ],
          status: {
            applyAs: "and",
            key: "isActive",
            comparison: "Equal",
          },
          manualFilter: {
            key: "employer.id",
            value: companyId,
            comparison: "Equal",
            applyAs: "and",
          },
          manualFilters:
            isSuperAdmin && !isDisplayedOnCompanyPage
              ? [
                  {
                    key: "employer.id",
                    value: companyId,
                    comparison: "Equal",
                    applyAs: "and",
                    render: (onChangeCallback, value) => {
                      return (
                        <Formik
                          initialValues={{ company: null }}
                          onSubmit={() => {}}
                        >
                          {({ setFieldValue, values }) => (
                            <Form>
                              <CustomTreeSelect
                                noMargin
                                allowClear
                                showArrow
                                treeNodeFilterProp="companyName"
                                treeLine={true && { showLeafIcon: false }}
                                className="filter-select"
                                name="company"
                                treeData={
                                  companyChildren
                                    ? companyChildren
                                    : data?.getClientsTree
                                }
                                placeholder="Companies: All"
                                fieldNames={{
                                  label: "companyName",
                                  value: "id",
                                }}
                                onChange={(e) => {
                                  onChangeCallback(e);
                                }}
                                onClear={() => setCompanyId(undefined)}
                                value={value}
                              />
                            </Form>
                          )}
                        </Formik>
                      );
                    },
                  },
                  {
                    key: "isProfileComplete",
                    comparison: "Equal",
                    applyAs: "and",
                    render: (onChangeCallback, value) => {
                      return (
                        <Select
                          placeholder={`${t("profile-state")}: ${t("all")}`}
                          className="filter-select"
                          allowClear
                          onChange={onChangeCallback}
                          value={value}
                        >
                          <Option value="true">
                            <Tag className="active-tag">{t("completed")}</Tag>
                          </Option>
                          <Option value="false">
                            <Tag className="de-active-tag">{t("pending")}</Tag>
                          </Option>
                        </Select>
                      );
                    },
                  },
                ]
              : undefined,
        }}
        totalItems={columnsData?.metadata?.total}
        loading={isLoading}
        columns={columns}
        permission={PERMS.INVITE_EMPLOYEE}
        onClick={() => setInviteVisible?.(true)}
        headerTitle={t("employees.title")}
        btnText={
          (isProfileComplete && companyChildren) || !companyChildren
            ? t("employees.invite-employees")
            : ""
        }
        dataSource={columnsData?.data}
        additionalButtons={[
          <CustomButton
            disabled={selectedEmployees.length <= 0}
            className="primary-btn"
            onClick={bulkRemind}
          >
            {t("bulk-reminders")}
          </CustomButton>,
        ]}
      />
      <EditEmployee
        visible={visible}
        selectedEmployee={selectedEmployee}
        setVisible={setVisible}
        employeeId={employeeId!}
        employerId={employerId}
        setEmployeeId={setEmployeeId}
      />
      {showUserDetail && (
        <UserDetails
          visible={showUserDetail}
          setVisible={setShowUserDetail}
          employeeId={employeeId}
          setEmployeeId={setEmployeeId}
        />
      )}
    </>
  );
};

export default UsersList;
