import { useDeleteWellBeingCalenderMutation } from "graphql/_generated/graphql";

type TypeHandleDeleteWellBeingCalender = (id: string) => Promise<any>;

type TypeUseDeleteWellBeingCalender = () => {
  isLoading: boolean;
  handleDeleteWellBeingCalender: TypeHandleDeleteWellBeingCalender;
};

export const useDeleteWellBeingCalender: TypeUseDeleteWellBeingCalender =
  () => {
    const [DeleteFun, { isLoading }] = useDeleteWellBeingCalenderMutation();

    // This function is responsible for deleting WellBeingCalender
    const handleDeleteWellBeingCalender: TypeHandleDeleteWellBeingCalender =
      async (id) => {
        try {
          const res = await DeleteFun({
            deleteWellBeingCalenderId: id,
          }).unwrap();
          return res;
        } catch (error: unknown) {
          throw error;
        }
      };

    return {
      isLoading,
      handleDeleteWellBeingCalender,
    };
  };
