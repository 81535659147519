import { MoreOutlined } from "@ant-design/icons";
import { Select, Space, Switch, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import CustomButton from "components/custom-button/CustomButton";
import CustomConfirmModal from "components/custom-confirm-modal/CustomConfirmModal";
import CustomDropDown from "components/custom-dropdown/CustomDropDown";
import CustomTable from "components/custom-table/CustomTable";
import CustomLanguageSelector from "components/form/custom-language-selector/CustomLanguageSelector";
import { PERMS, getLanguage } from "helpers";
import { getLangDir } from "helpers/getLangDir";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useCategoryListViewModel } from "../hooks/useCategoryListViewModel";
import AddRewardCategory from "./AddRewardCategory";

interface CategoryListProps {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CategoryList: React.FunctionComponent<CategoryListProps> = (
  props,
) => {
  const { t } = useTranslation();

  const vm = useCategoryListViewModel();

  type MappedCategoryList = NonNullable<typeof vm.mappedColumnsData>[0];

  const columns: ColumnsType<MappedCategoryList> = [
    {
      title: `${t("id")}`,
      key: "id",
      dataIndex: "id",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, index) =>
        record.hasChildren
          ? (vm.currentPage - 1) * vm.pageSize + index + 1
          : null,
    },
    {
      title: `${t("category-title")}`,
      key: "name",
      dataIndex: "name",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => {
        return (
          <div className={record?.hasChildren ? "parent-row" : "child-row"}>
            <Space>
              <CustomAvatar shape="square" imgSrc={record?.image ?? ""} />
              <span className={`cut-text-${getLangDir(record.name)}`}>
                {record.name}
              </span>
            </Space>
          </div>
        );
      },
    },
    {
      title: `${t("language")}`,
      key: "language",
      dataIndex: "language",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => getLanguage(record.language),
    },
    {
      title: `${t("creation-date")}`,
      key: "createdAt",
      dataIndex: "createdAt",
      sorter: {
        multiple: 1,
      },
      render: (createdAt: string) => moment(createdAt).format("MMMM Do YYYY"),
    },
    {
      title: `${t("updated-at")}`,
      key: "updatedAt",
      dataIndex: "updatedAt",
      sorter: {
        multiple: 1,
      },
      render: (updatedAt: string) => moment(updatedAt).format("MMMM Do YYYY"),
    },
    {
      title: t("status-label"),
      dataIndex: "isActive",
      key: "isActive",
      align: "center" as "center",

      render: (isActive: boolean) =>
        isActive ? (
          <Tag className="active-tag"> &bull; {`${t("active")}`}</Tag>
        ) : (
          <Tag className="de-active-tag">{t("deactivated")}</Tag>
        ),
    },
    {
      align: "center" as "center",
      title: t("activation"),
      dataIndex: "id",
      key: "id",
      render: (_text, record, _index) => (
        <>
          <CheckPermissions
            permission={PERMS.TOGGLE_REWARD_CATEGORY_ACTIVATION}
          >
            <Switch
              className="custom-switch"
              defaultChecked
              checked={record.isActive}
              onClick={() => vm.handleToggleCategory(record)}
            />
          </CheckPermissions>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "Actions",
      align: "center",
      render: (id: string, record) => {
        const mainActions = [
          {
            key: "edit-reward-category",
            label: t("edit-category"),
          },
          {
            key: "add-new-language",
            label: t("add-new-language"),
          },
          {
            key: "delete-reward-category",
            label: t("delete-category"),
          },
        ];
        const langActions = [{ key: "edit-language", label: "Edit Language" }];
        return (
          <CustomDropDown
            menu={{
              items: record?.hasChildren ? mainActions : langActions,
              onClick: ({ key }) => vm.handleMenu({ key, id, record }),
            }}
          >
            <CustomButton type="link" className="link-btn">
              <MoreOutlined />
            </CustomButton>
          </CustomDropDown>
        );
      },
    },
  ];

  return (
    <>
      <CustomTable
        permission={PERMS.ADD_REWARD_CATEGORY}
        onClick={() => props?.setVisible(true)}
        headerTitle={t("reward-categories")}
        btnText={t("add-reward-category")}
        expandIconColumnIndex={1}
        dataSource={vm.mappedColumnsData}
        columns={columns}
        filters={{
          handleCallback: vm.handleFilterCallback,
          search: true,
          status: {
            applyAs: "and",
            key: "isActive",
            comparison: "Equal",
          },
          date: [
            {
              applyAs: "and",
              key: "createdAt",
              comparison: "Range",
              placeholder: t("creation"),
            },
            {
              applyAs: "and",
              key: "updatedAt",
              comparison: "Range",
              placeholder: t("modified"),
            },
          ],
          manualFilters: [
            {
              key: "language",
              value: "language",
              comparison: "Equal",
              applyAs: "and",
              render: (onChangeCallback) => (
                <Select
                  options={vm.languages}
                  fieldNames={{ label: "label", value: "value" }}
                  className="filter-select"
                  onChange={onChangeCallback}
                  allowClear
                  placeholder={t("language-filter")}
                />
              ),
            },
          ],
        }}
        rowKey={(record) =>
          record.hasChildren ? record.id : record.id + record.name
        }
        totalItems={vm.columnsData?.metadata?.total}
        loading={vm.isLoading}
        className="custom-table"
      />
      {vm.langVisibility && (
        <CustomLanguageSelector
          visible={vm.langVisibility}
          setVisible={vm.setLangVisibility}
          onSubmit={vm.handleSubmit}
          data={vm.categoriesData}
          label={t("category")}
        />
      )}
      {vm.isAddCategory && (
        <AddRewardCategory
          visible={vm.isAddCategory}
          editId={vm.editId}
          langCode={vm.langCode}
          isAddLanguage={vm.isAddLanguage}
          handleCancel={vm.handleCancel}
          handleSubmit={vm.handleAddSubmit}
          isLoading={vm.isLoadingTier}
          initialValues={vm.initialValues}
        />
      )}
      {vm.isDelete && (
        <CustomConfirmModal
          visible={vm.isDelete}
          setVisible={vm.setIsDelete}
          desc={t("sure-to-delete-reward-category", {
            rewardCount: vm.rewardCount,
          })}
          onSubmit={vm.submitDelete}
          loading={vm.isLoadingTier}
        />
      )}
      {vm.isActivated && (
        <CustomConfirmModal
          visible={vm.isActivated}
          setVisible={vm.setIsActivated}
          desc={
            vm.categoriesData?.isActive
              ? t("confirm-deactivate-reward-category")
              : t("confirm-activate-reward-category")
          }
          loading={vm.isLoadingTier}
          onSubmit={vm.handleToggleCategoryActivation}
        />
      )}
    </>
  );
};
