import { UserType } from "graphql/_generated/graphql";
import { useSelector } from "react-redux";
import { parseJwt } from "../helpers";

export const useLoggedInUser = () => {
  const token = useSelector((state: any) => state.auth.token);
  const { id: userId, userType, companyId } = parseJwt(token);

  const isClient = userType === UserType.ClientAdmin;
  const isSuperAdmin = userType === UserType.SuperAdmin;
  const isViwellAdmin = userType === UserType.ViwellAdmin;

  return {
    isClient,
    isSuperAdmin,
    isViwellAdmin,
    userId,
    userType,
    companyId,
  };
};
