import { Col, Row } from "antd";
import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { customNotification } from "components/custom-notification/CustomNotification";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import CustomTextEditor from "components/form/custom-text-editor/CustomTextEditor";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import CustomTreeSelect from "components/form/custom-tree-select/CustomTreeSelect";
import { CustomUploadFile } from "components/form/custom-upload-file/CustomUploadFile";
import { errorFormat } from "helpers";
import { useLanguages } from "hooks";
import { useGetAchievementsIntervalTypes } from "hooks/useGetAchievementsItervalTypes";
import { useGetTrackerTypes } from "hooks/useGetTrackerTypes";
import {
  filterLanguagesWithout,
  getLabelFromValue,
} from "features/content/utils";
import { FieldArray, Form, Formik } from "formik";
import {
  GoalFilterType,
  Trackable,
  TrackedEntityType,
  useGetAchievementListQuery,
  useGetCategoriesTreeQuery,
  useGetMeasurementConfigQuery,
} from "graphql/_generated/graphql";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { badgeSchema } from "validations/schema";
import { useAchievement } from "../useAchievement";
import styles from "./AddBadge.module.scss";
import { useErrorHandler } from "hooks/useErrorHandler";
type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  editId?: string | null;
  setEditId: React.Dispatch<React.SetStateAction<string | null>>;
};

const EXCLUDED_LANG = "en";

export default function AddBadge({
  setVisible,
  visible,
  editId,
  setEditId,
}: Props) {
  const { t } = useTranslation();
  const { languages } = useLanguages();
  const { handleError } = useErrorHandler();

  const handleCancel = () => {
    setVisible(false);
    setEditId(null);
  };
  const {
    isLoading,
    handleAddNewAchievement,
    handleUpdateAchievement,
    handleFetchAchievementData,
  } = useAchievement();
  const { trackerTypes } = useGetTrackerTypes(TrackedEntityType.Achievement);
  const { data: categoriesData } = useGetCategoriesTreeQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const { data: unitsData } = useGetMeasurementConfigQuery();
  const { refetch } = useGetAchievementListQuery();

  const { intervalType } = useGetAchievementsIntervalTypes();

  const [initialValues, setInitialValues] = useState<any>({
    icon: "",
    name: "",
    message: "",
    tracker: {
      goalFilter: { type: undefined, value: undefined },
      goalType: "",
      goal: "",
      cron: null,
    },
    translations: [],
  });
  useEffect(() => {
    if (editId) {
      handleFetchAchievementData(editId).then((data) => {
        setInitialValues(data);
      });
    } else {
      setInitialValues({
        icon: "",
        name: "",
        message: "",
        tracker: {
          goalFilter: { type: undefined, value: undefined },
          goalType: "",
          goal: "",
          cron: null,
        },
        translations: [],
      });
    }
  }, [editId]);

  return (
    <CustomModal
      width={800}
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      title={t(!editId ? t("add-badge") : t("edit-badge"))}
    >
      <Formik
        enableReinitialize={true}
        validationSchema={badgeSchema}
        initialValues={initialValues}
        onSubmit={async (values, { resetForm }) => {
          console.log(values, "trackedEntityId");
          if (!editId) {
            try {
              await handleAddNewAchievement({ create: values });
              customNotification("success", t("badge-added-successfully"));
              setVisible(false);
              resetForm();
              refetch();
            } catch (error: any) {
              handleError(error as string | object);
            }
          } else {
            try {
              await handleUpdateAchievement({ id: editId, updateObj: values });
              customNotification("success", t("badge-updated-successfully"));
              resetForm();
              setVisible(false);
              setEditId(null);
              refetch();
            } catch (error) {
              handleError(error as string | object);
            }
          }
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <CustomUploadFile
              name="icon"
              setFieldValue={setFieldValue}
              imgUrl={values.icon}
              accept="image/*"
            />
            <CustomTextField
              name="name"
              label={t("badge-name")}
              placeholder={t("enter-badge-title")}
            />
            <CustomTextEditor
              name="message"
              label={t("badge-description")}
              placeholder={t("enter-badge-description")}
            />
            <FieldArray
              name="translations"
              render={(arrayHelpers) => (
                <div className={styles["form-control"]}>
                  {values.translations && values.translations.length > 0 ? (
                    values.translations.map(
                      (translation: any, index: number) => (
                        <div key={index}>
                          <div className={styles["multi-lang-box"]}>
                            <CustomSelectField
                              label={t("select-another-language")}
                              required={true}
                              value={getLabelFromValue(
                                languages,
                                values,
                                index,
                              )}
                              name={`translations.${index}.lang`}
                              placeholder={t("select-language")}
                              options={filterLanguagesWithout(
                                languages,
                                values.translations,
                                EXCLUDED_LANG,
                              )}
                              defaultValue={getLabelFromValue(
                                languages,
                                values,
                                index,
                              )}
                              className="form-select"
                            />
                            <CustomTextField
                              label={t("write-title-in-selected-language")}
                              name={`translations.${index}.name`}
                              placeholder={t(
                                "enter-title-in-selected-language",
                              )}
                            />
                          </div>
                          <CustomTextEditor
                            label={t("write-description-in-selected-language")}
                            name={`translations.${index}.message`}
                            placeholder={t(
                              "enter-description-in-selected-language",
                            )}
                          />

                          <div className={styles["flex-div"]}>
                            {values.translations.length + 1 <
                            languages.length ? (
                              <span
                                className={styles["add-btn"]}
                                onClick={() =>
                                  arrayHelpers.push({
                                    lang: "",
                                    name: "",
                                    message: "",
                                  })
                                } // insert an empty string at a position
                              >
                                + {t("add-new-language")}
                              </span>
                            ) : (
                              ""
                            )}
                            <span
                              className={styles["remove-btn"]}
                              onClick={() => arrayHelpers.remove(index)} // remove a language from the list
                            >
                              - {t("remove-language")}
                            </span>
                          </div>
                        </div>
                      ),
                    )
                  ) : (
                    <div className={styles["form-control"]}>
                      <span
                        className={styles["add-btn"]}
                        onClick={() =>
                          arrayHelpers.push({
                            lang: "",
                            name: "",
                            message: "",
                          })
                        }
                      >
                        + {t("add-new-language")}
                      </span>
                    </div>
                  )}
                </div>
              )}
            />
            <Row gutter={[24, 0]}>
              <Col xs={24} sm={12}>
                <CustomSelectField
                  name="tracker.cron"
                  label={t("interval-type")}
                  placeholder={t("select-interval-type")}
                  options={intervalType}
                />
              </Col>
              <Col xs={24} sm={12}>
                <CustomTextField
                  name="tracker.goal"
                  type="number"
                  label={t("goal")}
                  placeholder={t("enter-goal")}
                />
              </Col>
              <Col xs={24}>
                <CustomSelectField
                  disabled={editId ? true : false}
                  name="tracker.goalType"
                  label={t("tracker-type")}
                  placeholder={t("select-tracker-type")}
                  options={trackerTypes}
                  onChange={(value) => {
                    if (value === Trackable.Content) {
                      setFieldValue(
                        "tracker.goalFilter.type",
                        GoalFilterType.ContentCategory,
                      );
                      setFieldValue("tracker.goalFilter.value", undefined);
                    } else if (value === Trackable.Collection) {
                      setFieldValue(
                        "tracker.goalFilter.type",
                        GoalFilterType.CollectionCategory,
                      );
                      setFieldValue("tracker.goalFilter.value", undefined);
                    } else {
                      setFieldValue("tracker.goalFilter", null);
                    }
                  }}
                />
              </Col>

              {[Trackable.Collection, Trackable.Content].includes(
                values.tracker.goalType,
              ) && (
                <Col xs={24}>
                  <CustomTreeSelect
                    allowClear
                    showArrow
                    treeLine={true && { showLeafIcon: false }}
                    name={`tracker.goalFilter.value`}
                    label={t("tracked-category")}
                    placeholder={t("select-tracked-category")}
                    fieldNames={{ label: "name", value: "id" }}
                    treeData={categoriesData?.getCategoriesTree}
                  />
                </Col>
              )}
              {/* {values.tracker.type === TrackerType.Custom && (
                <Col sm={24}>
                  <CustomSelectField
                    disabled={editId ? true : false}
                    label={t("tracker-unit")}
                    name={`tracker.trackedEntityId`}
                    placeholder={t("select-tracker-unit")}
                    fieldNames={{ label: "label", value: "id" }}
                    options={unitsData?.getMeasurementConfig.options}
                    onChange={(values) =>
                      setFieldValue("tracker.trackedEntityId", values)
                    }
                  />
                </Col>
              )} */}
            </Row>
            <ModalFooter
              saveText={t("save-badge")}
              onCancel={handleCancel}
              loading={isLoading}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
}
