export function whereToNavigate(
  token: string,
  isProfileCompleted: boolean,
  userType: string,
) {
  if (!token) {
    return "/";
  }

  if (!isProfileCompleted) {
    switch (userType) {
      case "SuperAdmin":
        return "/super-admin-profile";
      case "ClientAdmin":
        return "/company-profile";
      case "Expert":
        return "/expert-profile";
      case "Partner":
        return "/partner-profile";
      default:
        return "/not-found";
    }
  } else {
    switch (userType) {
      case "SuperAdmin":
        return "/company";
      case "ClientAdmin":
        return "/company-dashboard";
      case "Expert":
        return "/expert-dashboard";
      case "Partner":
        return "/partner-dashboard";
      case "VIWELLAdmin":
        return "/company";
      default:
        return "/not-found";
    }
  }
}
