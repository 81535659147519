import { FormikHelpers } from "formik";
import {
  CreateHabitMutation,
  GetHabitByIdQuery,
  HabitCreate,
  HabitIntervals,
  SetHabitTranslationMutation,
  UpdateHabitMutation,
  useGetConfigPillarsQuery,
  useGetMeasurementConfigQuery,
} from "graphql/_generated/graphql";
import { useNavigate } from "react-router-dom";
import { useHabits } from "./useHabits";
import { useTranslation } from "react-i18next";
import { useErrorHandler } from "hooks/useErrorHandler";
import customNotification from "components/custom-notification/CustomNotification";
import { useGetHabitIntervals } from "./useGetHabitIntervals";
import { useEffect, useState } from "react";

export type HabitState = {
  langCode: string;
  id: string;
  addLanguage: boolean;
};

export type SubmitHabitResponse =
  | CreateHabitMutation["createHabit"]
  | SetHabitTranslationMutation["setHabitTranslation"]
  | UpdateHabitMutation["updateHabit"];

type HabitDetailsQuery = GetHabitByIdQuery["getHabitById"];

export type GetHabitDetails = HabitDetailsQuery & {
  oldTitle?: string;
  oldDescription?: string;
};

export const useAddHabitViewModel = (state: HabitState) => {
  const navigate = useNavigate();
  const { id, langCode, addLanguage } = state ?? {};

  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const {
    handleAddHabit,
    handleSetHabitTranslation,
    handleGetHabitDetail,
    handleUpdateHabit,
    isLoading,
  } = useHabits();
  const { data: pillars } = useGetConfigPillarsQuery();
  const { data: units } = useGetMeasurementConfigQuery();
  const pillarsData = pillars?.getConfigPillars;
  const unitsData = units?.getMeasurementConfig;
  const { habitIntervals } = useGetHabitIntervals();

  const [habitsData, setHabitsData] = useState<GetHabitDetails>();

  const initialValues = {
    language: habitsData?.language ?? langCode,
    title: habitsData?.title ?? "",
    description: habitsData?.description ?? "",
    pillarId: habitsData?.pillar?.id ?? "",
    target: habitsData?.target ?? (null as unknown as number),
    unitId: habitsData?.unit?.id ?? "",
    repetition: habitsData?.repetition ?? HabitIntervals.Daily,
  };

  const onHabitSubmit = async (
    values: HabitCreate,
    formikHelpers?: FormikHelpers<typeof values>,
  ) => {
    let res;
    try {
      if (!id && !addLanguage) {
        res = await handleAddHabit({ habit: values });
        customNotification("success", t("habit-added-successfully"));
      } else if (addLanguage && id && langCode) {
        res = await handleSetHabitTranslation({
          setHabitTranslationId: id,
          translationInput: {
            translations: {
              [langCode]: {
                title: values.title,
                description: values.description,
              },
            },
          },
        });
        customNotification("success", t("habit-translation-added"));
      } else {
        res = await handleUpdateHabit({
          updateHabitId: id,
          updateObj: values,
        });
        customNotification("success", t("habit-updated-successfully"));
      }
      formikHelpers?.resetForm();
      navigate("/habits");
      return res;
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const onPressCancel = () => {
    navigate("/habits");
  };

  const onPressUpdateHabit = () => {
    navigate("/habits/add-habit", {
      state: {
        id: id,
        langCode: langCode,
        addLanguage: false,
      },
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id) {
          const data = await handleGetHabitDetail(id);
          const translationData = langCode && data?.translations?.[langCode];
          setHabitsData({
            ...data,
            title: addLanguage ? translationData?.title : data.title,
            description: addLanguage
              ? translationData?.description
              : data.description,
            oldTitle: data.title ?? "",
            oldDescription: data.description ?? "",
          });
        }
      } catch (error) {
        handleError(error as string | object);
      }
    };
    fetchData();
  }, [id]);

  return {
    pillarsData,
    unitsData,
    repetitionOptions: habitIntervals,
    initialValues,
    habitsData,
    onHabitSubmit,
    onPressCancel,
    onPressUpdateHabit,
    isLoading,
  };
};
