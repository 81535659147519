import { CalendarOutlined } from "@ant-design/icons";
import { Avatar, Col, Row, Space, Tag } from "antd";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import CustomButton from "components/custom-button/CustomButton";
import CustomCard from "components/custom-card/CustomCard";
import CustomImg from "components/custom-img/CustomImg";
import Reviews from "components/reviews/Reviews";
import API from "config/api";
import styles from "features/content/css/ContentDetail.module.scss";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import {
  useGetContentQuery,
  useGetReviewsQuery,
} from "graphql/_generated/graphql";
import { renderHtmlContent } from "helpers";
import { languages } from "i18n/languages";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
const baseMediaUrl = API.baseImgUrl;
interface ContentDetailProps {}

const ContentDetail: React.FC<ContentDetailProps> = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const id: any = location.state;
  const { data } = useGetContentQuery(
    { getContentId: id },
    { refetchOnMountOrArgChange: true },
  );
  const { data: review } = useGetReviewsQuery({ entityId: id });

  return (
    <DashboardLayout>
      <>
        <Breadcrumbs
          data={[
            {
              label: "Content",
            },
            {
              label: "Contents",
            },
            {
              label: t("content-detail"),
            },
          ]}
        />
        <div className={styles["content"]}>
          <h1 className={styles["title"]}>{t("content-detail")}</h1>
          <CustomButton
            className="primary-btn"
            onClick={() =>
              navigate("/add-content", {
                state: id,
              })
            }
          >
            {t("edit-content")}
          </CustomButton>
        </div>
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={24} md={16}>
            <CustomCard className="collection-card" bordered={false}>
              <div className={styles["content-title"]}>
                {data?.getContent.title}
              </div>
              <div className={styles["content-img"]}>
                <CustomImg
                  imgSrc={data?.getContent.thumbnailImage}
                  alt={t("viwell-content")}
                />
              </div>
              <div className={styles["description"]}>
                <h2 className={styles["desc"]}>{t("description")}</h2>
                <div className="description-html">
                  {renderHtmlContent(data?.getContent.description)}
                </div>
              </div>
              {/* <div className={styles["description"]}>
              <h2 className={styles["desc"]}>{t("content-id")}</h2>
              <p>{data?.getContent.id}</p>
            </div> */}
              <div className={styles["description"]}>
                <h2 className={styles["desc"]}>{t("content-type")}</h2>
                <p>{data?.getContent.contentType}</p>
              </div>
              <div className={styles["description"]}>
                <h2 className={styles["desc"]}>{t("status")}</h2>
                <p>{data?.getContent.status}</p>
              </div>
              <div className={styles["description"]}>
                <h2 className={styles["desc"]}>{t("specialist")}</h2>
                <Space size={16}>
                  <CustomAvatar
                    shape="square"
                    imgSrc={data?.getContent.creator.image ?? ""}
                  />
                  <Space size={0} direction="vertical">
                    <div>
                      {data?.getContent.creator.firstName}{" "}
                      {data?.getContent.creator.lastName}
                    </div>
                    <div>{data?.getContent.creator.specialty}</div>
                  </Space>
                </Space>
              </div>
              <div className={styles["description"]}>
                <h2 className={styles["desc"]}>{t("categories")}</h2>
                <p>
                  {[...(data?.getContent?.categories ?? [])].map((category) =>
                    category.isActive ? (
                      <Tag key={category.id}>{category.name}</Tag>
                    ) : null,
                  )}
                </p>
              </div>
              <div className={styles["description"]}>
                <h2 className={styles["desc"]}>{t("pillars")}</h2>
                <p>
                  {data?.getContent.pillars.map((pillar) => (
                    <Tag key={pillar.id}>{pillar.title}</Tag>
                  ))}
                </p>
              </div>
              <div className={styles["description"]}>
                <h2 className={styles["desc"]}>{t("tags")}</h2>
                {data?.getContent.tags
                  .filter((item) => item.isActive)
                  .map((item) => (
                    <Tag color="#108ee9">{item.title}</Tag>
                  ))}
              </div>
              <div className={styles["description"]}>
                <h2 className={styles["desc"]}>{t("content-views")}</h2>
                <p>{data?.getContent.views}</p>
              </div>
              <div className={styles["description"]}>
                <h2 className={styles["desc"]}>{t("content-language")}</h2>
                <p>
                  {
                    languages?.filter(
                      (item) => item.value === data?.getContent?.language,
                    )[0]?.label
                  }
                </p>
              </div>
              <Reviews review={review?.getReviews[0]} />
            </CustomCard>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <div className={styles["detail-card"]}>
              <CustomCard className="collection-card">
                <div>
                  <Space>
                    <Space direction="vertical">
                      <Space>
                        <CalendarOutlined />
                        <div>
                          {t("created-at")}:{" "}
                          {moment(data?.getContent.createdAt).format(
                            "MMMM Do YYYY, h:mm:ss a",
                          )}
                        </div>
                      </Space>
                      <Space>
                        <CalendarOutlined />
                        <div>
                          {t("last-modified-date")}:{" "}
                          {moment(data?.getContent.updatedAt).format(
                            "MMMM Do YYYY, h:mm:ss a",
                          )}
                        </div>
                      </Space>
                    </Space>
                  </Space>
                </div>
              </CustomCard>
              <CustomCard className="collection-card">
                {data?.getContent && "videoUrl" in data.getContent ? (
                  <div>
                    <iframe
                      style={{
                        width: "100%",
                        height: "300px",
                      }}
                      title="Vieme"
                      frameBorder="0"
                      allowFullScreen
                      allow="autoplay; encrypted-media"
                      src={data.getContent.videoUrl}
                    ></iframe>
                    {data?.getContent?.localizedVideos?.map((item, index) => (
                      <div key={index} className={styles["detail-card"]}>
                        <iframe
                          style={{
                            width: "100%",
                            height: "300px",
                          }}
                          title="Vieme"
                          frameBorder="0"
                          allowFullScreen
                          allow="autoplay; encrypted-media"
                          src={item.url}
                        ></iframe>
                      </div>
                    ))}
                  </div>
                ) : data?.getContent && "audioUrl" in data.getContent ? (
                  <>
                    <audio
                      style={{ width: "100%" }}
                      src={`${baseMediaUrl}${data?.getContent?.audioUrl}`}
                      controls
                      controlsList="nodownload"
                    />
                    {data?.getContent.localizedAudios.map((item, index) => (
                      <div key={index} className={styles["detail-card"]}>
                        <div>
                          <audio
                            style={{ width: "100%" }}
                            src={item.url}
                            controls
                            controlsList="nodownload"
                          />
                        </div>
                      </div>
                    ))}
                  </>
                ) : data?.getContent && "text" in data.getContent ? (
                  <div>
                    <div>{renderHtmlContent(data.getContent.text)}</div>
                    {data.getContent.localizedTexts.map((item) => (
                      <div className={styles["mt-2"]}>
                        {renderHtmlContent(item.text)}
                      </div>
                    ))}
                  </div>
                ) : data?.getContent && "gallery" in data.getContent ? (
                  <div>
                    <CustomImg
                      className={styles["recipe-img"]}
                      imgSrc={data.getContent.thumbnailImage}
                      alt=""
                    />
                    <div className={styles["desc"]}>
                      {t("time-required")}: {data.getContent.timeRequired}
                    </div>
                    <div className={styles["desc"]}>
                      {t("serving")}: {data.getContent.serving}
                    </div>
                    <Space>
                      <div className={styles["desc"]}>{t("gallery")}</div>
                      <Avatar.Group>
                        {data.getContent.gallery.map((item) => (
                          <CustomAvatar imgSrc={item} />
                        ))}
                      </Avatar.Group>
                    </Space>
                  </div>
                ) : (
                  ""
                )}
              </CustomCard>
            </div>
          </Col>
        </Row>
      </>
    </DashboardLayout>
  );
};

export default ContentDetail;
