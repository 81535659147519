import { Col, Row } from "antd";
import {
  FilterValuesProps,
  WidgetExtraProps,
} from "features/company-dashboard/types";
import { timeFilterTypes } from "features/company-dashboard/utils";
import { UserFilters } from "graphql/_generated/graphql";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "../../CompanyDashboard.module.scss";
import { SectionHeader } from "../SectionHeader";
import { InfoTextProps } from "../organization-metrices/types";
import { Categories } from "./Categories";
import { Pillars } from "./Pillars";

export const PillarsAndCategories: FunctionComponent<
  InfoTextProps & WidgetExtraProps
> = ({ selectedInfoText, setSelectedInfoText, companyRegisteredDate }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(true);
  const [pillarDetailsVisible, setPillarDetailsVisible] =
    useState<boolean>(false);
  const [selectedPillar, setSelectedPillar] = useState<any>();
  const [userFilters, setUserFilters] = useState<UserFilters>({
    clientIds: null,
    departmentIds: null,
    gender: null,
    positionIds: null,
    age: null,
  });
  const [selectedValues, setSelectedValues] = useState<FilterValuesProps>({
    pastDays: timeFilterTypes.PAST_7_DAYS,
    customDateRange: "",
  });

  return (
    <>
      <div className={styles["checkins-chart"]}>
        <SectionHeader
          visible={visible}
          setVisible={setVisible}
          userFilters={userFilters}
          setUserFilters={setUserFilters}
          sectionTitle={t("pillars-and-categories")}
          initialValues={selectedValues}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
          companyRegisteredDate={companyRegisteredDate}
          open={open}
          setOpen={setOpen}
        />
        <Row gutter={[16, 16]} className={styles["row-flex"]}>
          <Col xs={24} xl={12}>
            <Pillars
              userFilters={userFilters}
              pillarDetailsVisible={pillarDetailsVisible}
              setPillarDetailsVisible={setPillarDetailsVisible}
              selectedPillar={selectedPillar}
              setSelectedPillar={setSelectedPillar}
              selectedValues={selectedValues}
              visible={visible}
              selectedInfoText={selectedInfoText}
              setSelectedInfoText={setSelectedInfoText}
            />
          </Col>
          <Col xs={24} xl={12}>
            <Categories
              userFilters={userFilters}
              pillarDetailsVisible={pillarDetailsVisible}
              selectedPillar={selectedPillar}
              selectedValues={selectedValues}
              visible={visible}
              selectedInfoText={selectedInfoText}
              setSelectedInfoText={setSelectedInfoText}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
