import CustomButton from "components/custom-button/CustomButton";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { SignInSchema } from "validations/schema";
import SignInLayout from "features/auth/auth-layout/SignInLayout";
import styles from "features/auth/css/SignIn.module.scss";
import { useSignInViewModel } from "../../features/auth/hooks/useSignInViewModel";
// import { getToken, messaging } from "services/firebase/firebase.service";

const SignIn = () => {
  const { t } = useTranslation();
  const { onSubmit, requestOtpLoading, isLoading } = useSignInViewModel();

  const initialValues = {
    email: "",
    password: "",
    deviceToken: "",
  };

  return (
    <SignInLayout title="Sign In to Viwell" id="signInScreen">
      <Formik
        initialValues={initialValues}
        validationSchema={SignInSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <CustomTextField
            name="email"
            label={t("email")}
            placeholder={t("email")}
          />
          <CustomTextField
            name="password"
            type="password"
            label={t("password")}
            placeholder={t("password")}
          />
          <div className={styles["forgot-password"]}>
            <Link
              id="forgotPassword"
              className={styles["forgot-link"]}
              to="/forgot-password"
            >
              {t("forgot-password")}
            </Link>
          </div>
          <div className={styles["btn-div"]}>
            <CustomButton
              id="signIn"
              block
              className="primary-btn"
              htmlType="submit"
              loading={isLoading || requestOtpLoading}
            >
              {t("Sign in")}
            </CustomButton>
          </div>
        </Form>
      </Formik>
    </SignInLayout>
  );
};

export default SignIn;
