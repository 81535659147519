import { useAddMeasurement } from "./useAddMeasurement";
import { useDeleteMeasurement } from "./useDeleteMeasurement";
import { useToggleMeasurement } from "./useToggleMeasurement";
import { useUpdateMeasurement } from "./useUpdateMeasurement";

export const useMeasurement = () => {
  const { handleAddNewMeasurement, isLoading } = useAddMeasurement();

  const {
    handleUpdateMeasurement,
    handleFetchMeasurementData,
    isLoading: isLoadingUpdateMeasurement,
  } = useUpdateMeasurement();

  const { handleDeleteUnit, isLoadingDeleteUnit } = useDeleteMeasurement();

  const { handleToggleUnit, isLoadingToggleUnit } = useToggleMeasurement();

  return {
    isLoading:
      isLoading ||
      isLoadingUpdateMeasurement ||
      isLoadingDeleteUnit ||
      isLoadingToggleUnit,
    handleAddNewMeasurement,
    handleUpdateMeasurement,
    handleFetchMeasurementData,
    handleDeleteUnit,
    handleToggleUnit,
  };
};
