import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Location } from "react-router-dom";
import { resetStateForPath } from "store/navigationSlice";
import { useDeepMemo } from "./useDeepMemo";

// Custom hook to get and memoize navigation state
export const usePersistedState = (
  stateKey: string,
  location: Location,
  defaultValue: any,
) => {
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state.navigation[stateKey]);
  const statePath =
    location.hash && location.hash.includes("tab")
      ? location.hash
      : location.pathname;
  const stateForCurrentPath = state[statePath] || defaultValue;

  useEffect(() => {
    return () => {
      const resetNavigationState = location.hash === "#persist" ? false : true;
      resetNavigationState && dispatch(resetStateForPath(statePath));
    };
  }, []);

  return useDeepMemo(() => {
    return stateForCurrentPath;
  }, [stateForCurrentPath]);
};
