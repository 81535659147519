import {
  UpdateParticipantsScoreMutationVariables,
  useUpdateParticipantsScoreMutation,
} from "graphql/_generated/graphql";

export const useUpdateParticipantScore = () => {
  const [
    updateParticipantScoreFun,
    { isLoading: isLoadingUpdateParticipantScore },
  ] = useUpdateParticipantsScoreMutation();

  const handleUpdateParticipantScore = async (
    values: UpdateParticipantsScoreMutationVariables,
  ) => {
    try {
      const res = await updateParticipantScoreFun({
        update: values.update,
      }).unwrap();
      return res.updateParticipantsScore;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleUpdateParticipantScore,
    isLoadingUpdateParticipantScore,
  };
};
