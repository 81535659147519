import { List, Space } from "antd";
import * as React from "react";
import styles from "features/events/css/CompanyItem.module.scss";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import { useTranslation } from "react-i18next";
import { BronzeMedalSvg, GoldMedalSvg, SilverMedalSvg } from "assets/icons";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { Input } from "formik-antd";
interface ParticipatingEditItemProps {
  itemKey: number;
  id: string;
  companyImg: string;
  companyName?: string;
  participants?: number;
  onItemClick: (clientId: string) => void;
  error?: boolean;
  clientId: string;
}

const ParticipatingEditItem: React.FunctionComponent<
  ParticipatingEditItemProps
> = ({
  itemKey,
  id,
  participants,
  companyImg,
  companyName,
  error,
  clientId,
  onItemClick,
}) => {
  const { t } = useTranslation();
  const ranks: { [key: string]: React.ReactNode } = {
    1: <GoldMedalSvg />,
    2: <SilverMedalSvg />,
    3: <BronzeMedalSvg />,
  };
  const getRankComponent = (key: number): React.ReactNode => {
    if (key in ranks && Number(key) <= 3) {
      return ranks[key];
    } else {
      return key;
    }
  };
  return (
    <List.Item key={id}>
      <div className={styles["item-grid"]}>
        <div>
          <Space size={14}>
            <div className={styles["item-id"]}>{getRankComponent(itemKey)}</div>
            <CustomAvatar imgSrc={companyImg} size={32} shape="circle" />
            <div className={styles["sub-title"]}>{companyName}</div>
          </Space>
        </div>
        <div
          onClick={() => onItemClick(clientId)}
          className={styles["participants"]}
        >{`${participants} ${t("participants")}`}</div>
        <div className={styles["users"]}>
          <Input
            className="from-input"
            style={{
              width: "96px",
              height: "40px",
              borderColor: error ? "red" : "#80808029",
            }}
            name={`data[${itemKey - 1}].score`}
            type="number"
          />
        </div>
      </div>
    </List.Item>
  );
};

export default ParticipatingEditItem;
