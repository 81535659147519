import { RcFile } from "antd/lib/upload";
import axios from "axios";
import { useLazyGetSignedUrlQuery } from "graphql/_generated/graphql";
import { useState } from "react";
import { getSignedUrl } from "./raw-requests/rawSignedUrl";
import { useAppSelector } from "./useStoreHooks";

type TypeUseImageUpload = () => {
  loading: boolean;
  handleImageUpload: (file: any, config: any) => Promise<string>;
};

export const useImageUpload: TypeUseImageUpload = () => {
  const token = useAppSelector((state) => state.auth.token);
  const [loading, setLoading] = useState<boolean>(false);

  const handleImageUpload = async (file: RcFile, config: any) => {
    setLoading(true);
    let extension = file?.name?.split(".").pop()?.toLowerCase();
    if (!extension) {
      throw "There is some problem in image file extension";
    }
    try {
      const signedUrl = await getSignedUrl(token, extension);
      if (signedUrl) {
        const response = await axios.put(signedUrl, file, config);
        setLoading(false);
        return response.request.responseURL.split("?")[0];
      }
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  return { loading, handleImageUpload };
};
