import { useGetChallengeStats } from "features/company-dashboard/hooks/useGetChallengeStats";
import {
  getFetchingStatus,
  getFormattedDateRanges,
  timeFilterOptions,
} from "features/company-dashboard/utils";
import { ChallengeStats, UserFilters } from "graphql/_generated/graphql";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export interface TotalChallengesProps {
  selectedValues: any;
  userFilters: UserFilters;
}

export const useTotalChallengesViewModel = ({
  selectedValues,
  userFilters,
}: TotalChallengesProps) => {
  const { t } = useTranslation();

  const [challengeStatsData, setChallengeStatsData] = useState<ChallengeStats>({
    enrolledEmployees: 0,
    failed: 0,
    finished: 0,
    inProgress: 0,
    left: 0,
    pastEnrolledEmployees: 0,
    totalEmployees: 0,
    totalEnrolledChallenges: 0,
    isDataConcealed: false,
  });

  const { handleGetChallengeStats, isLoading } = useGetChallengeStats();

  useEffect(() => {
    const isFetching = getFetchingStatus(selectedValues);
    if (isFetching) getChallengeStats();
  }, [selectedValues, userFilters]);

  const getChallengeStats = async () => {
    if (selectedValues.pastDays) {
      const { startingDate, endingDate } =
        getFormattedDateRanges(selectedValues);
      const challengeStats = await handleGetChallengeStats({
        startDate: startingDate,
        endDate: endingDate,
        userFilters,
      });
      if (challengeStats) {
        setChallengeStatsData(challengeStats);
      }
    }
  };

  const challengeBarColors = ["#5555A4", "#1071B9", "#7274B7", "#3B9ED7"];

  const mappedChallengesData = challengeStatsData?.enrolledEmployees
    ? [
        {
          name: t("successful"),
          value: challengeStatsData?.finished,
        },
        {
          name: t("failed"),
          value: challengeStatsData?.failed,
        },
        {
          name: t("in-progress"),
          value: challengeStatsData?.inProgress,
        },
        {
          name: t("abandoned"),
          value: challengeStatsData?.left,
        },
      ]
    : [];

  const isDataExists = mappedChallengesData?.some((item: any) => item.value);

  const pastTimeLabel = timeFilterOptions.find(
    (time) => time.value === selectedValues?.pastDays,
  );

  const pastComparison = challengeStatsData?.pastEnrolledEmployees
    ? parseInt(
        (
          ((challengeStatsData?.enrolledEmployees -
            challengeStatsData?.pastEnrolledEmployees) /
            challengeStatsData?.pastEnrolledEmployees) *
          100
        ).toFixed(0),
      )
    : 0;

  const infoTexts = [
    t("employee-participation-challenge"),
    t("actively-engaged-employees"),
    t("overall-challenge-success"),
    t("explore-trend-insights"),
  ];

  return {
    infoTexts,
    isLoading,
    challengeStatsData,
    mappedChallengesData,
    challengeBarColors,
    pastComparison,
    isDataExists,
    pastTimeLabel,
    getChallengeStats,
  };
};
