import { TypeHandleAddFun, useAddTag } from "./useAddTag";

import {
  TypeHandleFetchPreviousDepartmentData,
  TypeHandleUpdateFun,
  useUpdateTag,
} from "./useUpdateTag";

import {
  handleToggleActivateTagFun,
  useToggleTagActivation,
} from "./useToggleTagActivation";

//define the type of useTag hook
type useTag = () => {
  isLoading: boolean;
  handleAddTag: TypeHandleAddFun;
  handleFetchEditTagData: TypeHandleFetchPreviousDepartmentData;
  handleUpdateTag: TypeHandleUpdateFun;
  handleToggleActivateTag: handleToggleActivateTagFun;
};

export const useTag: useTag = () => {
  const { handleAddTag, isLoading } = useAddTag();

  const {
    handleFetchEditTagData,
    handleUpdateTag,
    isLoading: updateLoading,
  } = useUpdateTag();

  const { handleToggleActivateTag, isLoading: activationDeactivationLoading } =
    useToggleTagActivation();

  return {
    isLoading: isLoading || updateLoading || activationDeactivationLoading,
    handleAddTag,
    handleFetchEditTagData,
    handleUpdateTag,
    handleToggleActivateTag,
  };
};
