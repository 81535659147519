export const ActivitySvg = () => {
  return (
    <svg
      width="14"
      height="20"
      viewBox="0 0 14 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25971 19.1665C6.16471 19.1665 6.06888 19.1498 5.97471 19.1157C5.61471 18.9848 5.38971 18.6248 5.43138 18.244L6.07138 12.3332H1.16721C0.85971 12.3332 0.57721 12.164 0.43221 11.8923C0.28721 11.6207 0.303877 11.2923 0.47471 11.0365L7.04804 1.20316C7.26138 0.883993 7.66471 0.751493 8.02554 0.883993C8.38638 1.01483 8.61054 1.37483 8.56888 1.75566L7.92888 7.66649H12.8339C13.1414 7.66649 13.4239 7.83566 13.5689 8.10733C13.713 8.37899 13.6972 8.70733 13.5264 8.96316L6.95221 18.7965C6.79471 19.0332 6.53138 19.1665 6.25971 19.1665Z"
        fill="#303030"
      />
    </svg>
  );
};
