import EnumBoolean from "features/assessment/types/EnumBoolean";
import IMatrixTitles from "features/assessment/types/IMetricTitles";
import IMatrixOptions from "features/assessment/types/IMetricOptions";

/**
 * Transform metrices queried data to save metric-type options.
 *
 * @param values {} query data
 * @return [] transformed Array
 */
const MatrixTransformer = (props: any) => {
  const { values, tags } = props;

  let options: any = values.metrices?.map((optItem: any) => {
    return {
      rowTitle: values.metricTitles.find((item: any) => item.id === optItem.row)
        .value,
      columnTitle: values.metricOptions.find(
        (item: any) => item.id === optItem.column,
      ).value,
      column: optItem.column,
      row: optItem.row,
      score: optItem?.score || 0,
      text: optItem?.text?.toString() || "",
      subText: optItem?.subText?.toString() || "",
      emoji: values?.isEmojiEnable ? optItem?.emoji || null : null,
      id: optItem?.savedOnServer === EnumBoolean.YES ? optItem.id : undefined,
      assessmentQuestionId:
        values?.savedOnServer === EnumBoolean.YES ? values?.id : undefined,
      tags: optItem?.tags?.map((tagtItem: any) => {
        return {
          tagId: tagtItem,
          tagName: tags?.data?.find((tag: any) => tag.value == tagtItem)?.label,
        };
      }),
    };
  });

  return options;
};

export default MatrixTransformer;

export const GetTransformedMatrix = (question: any) => {
  let matrixOptions: Array<IMatrixOptions> = [];
  let matrixTitles: Array<IMatrixTitles> = [];
  let metrices: any = [];

  let numberOfOptions = normalizedArray(
    question.options.map((item: any) => item.column),
  ).length;

  let numberOfTitles = normalizedArray(
    question.options.map((item: any) => item.row),
  ).length;

  // MAPPING OPTIONS
  for (let optIndex = 1; optIndex <= numberOfOptions; optIndex++) {
    const optionValue = question.options.find(
      (item: any) => item.column === optIndex,
    ).columnTitle;

    // UPDATING METRIC OPTIONS
    matrixOptions = [
      ...matrixOptions,
      {
        id: optIndex,
        value: optionValue,
      },
    ];
  }

  // METRIC TITLES
  for (let titleIndex = 1; titleIndex <= numberOfTitles; titleIndex++) {
    const titleValue = question.options.find(
      (optItem: any) => optItem.row === titleIndex,
    ).rowTitle;

    // UPDATING METRIC TITLES
    matrixTitles = [
      ...matrixTitles,
      {
        id: titleIndex,
        value: titleValue,
      },
    ];
  }
  // METRICES HASH MAP
  question.options.map((option: any) => {
    const matrixKey = `ROW${option.row}COL${option.column}`;
    metrices = [
      ...metrices,
      {
        ...option,
        matrixKey: matrixKey,
      },
    ];

    return option;
  });

  return {
    rows: matrixTitles,
    columns: matrixOptions,
    numberOfOptions,
    numberOfTitles,
    metrices,
  };
};

let normalizedArray = (array: Array<number>) => {
  return array.filter(function (elem: number, pos: number) {
    return array.indexOf(elem) == pos;
  });
};
