export function normalizeKeys(object: any) {
  Object.keys(object).forEach(function (key) {
    // Split the string by space and remove all asterisks from the first part
    const newKey = key.split(" ")[0].replace(/\*/g, "");

    if (object[key] && typeof object[key] === "object") {
      normalizeKeys(object[key]);
    }

    if (key !== newKey) {
      object[newKey] = object[key];
      delete object[key];
    }
  });

  return object;
}
