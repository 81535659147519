import { List } from "antd";
import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { Form, Formik, FormikHelpers } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styles from "features/events/css/IndividualLeaderBoard.module.scss";
import EditIndividualScoreItem from "./EditIndividualScoreItem";
import {
  EventParticipantsScoreInput,
  GetIndividualLeaderboardQuery,
} from "graphql/_generated/graphql";
import { participantsScoreSchema } from "validations/schema";
import CustomPagination from "components/custom-pagination/CustomPagination";

interface EditIndividualScoresProps {
  visible: boolean;
  handleCancel: () => void;
  dataSource?: GetIndividualLeaderboardQuery["getIndividualLeaderboard"];
  onUpdateScoreSubmit: (
    values: EventParticipantsScoreInput,
    formikHelpers?: FormikHelpers<EventParticipantsScoreInput> | undefined,
  ) => Promise<boolean | undefined>;
  loading: boolean;
  onChangePagination: (page: number, pageSize: number) => void;
  currentPage: number;
}

const EditIndividualScores: React.FunctionComponent<
  EditIndividualScoresProps
> = ({
  visible,
  handleCancel,
  dataSource,
  onUpdateScoreSubmit,
  onChangePagination,
  loading,
  currentPage,
}) => {
  const { t } = useTranslation();
  return (
    <CustomModal
      centered
      width={720}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      title={t("team-management")}
    >
      <Formik
        validationSchema={participantsScoreSchema}
        enableReinitialize
        initialValues={{
          data: dataSource?.data?.map((item) => ({
            participantId: item.participantId ?? "",
            score: item.eventScore ?? 0,
          })) ?? [
            {
              participantId: "",
              score: 0,
            },
          ],
        }}
        onSubmit={onUpdateScoreSubmit}
      >
        {({ values, errors }) => (
          <Form>
            <div
              className={`${styles["leaderboard-container"]} ${styles["mb-1"]} `}
            >
              <List
                loading={loading}
                size="small"
                dataSource={dataSource?.data}
                renderItem={(item, index) => (
                  <EditIndividualScoreItem
                    itemKey={index + 1}
                    id={item.participantId ?? ""}
                    userImg={item.avatar?.imgPath ?? item.image ?? ""}
                    firstName={item.firstName ?? ""}
                    lastName={item.lastName ?? ""}
                    companyName={item.company?.companyName ?? ""}
                    companyImg={item.company?.image ?? ""}
                    score={item.eventScore ?? 0}
                    error={!!(errors && errors.data && errors.data[index])}
                  />
                )}
              />
            </div>
            <div className={styles["mb-1"]}>
              <CustomPagination
                responsive
                showSizeChanger
                total={dataSource?.total}
                onChange={onChangePagination}
                current={currentPage}
              />
            </div>
            <ModalFooter saveText={t("save")} onCancel={handleCancel} />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default EditIndividualScores;
