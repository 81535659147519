import {
  useAddNewRewardCategoryMutation,
  MutationAddNewRewardCategoryArgs,
  useGetRewardCategoryListQuery,
} from "graphql/_generated/graphql";

export const useAddRewardCategory = () => {
  const [addRewardCategoryFun, { isLoading }] =
    useAddNewRewardCategoryMutation();

  const { refetch } = useGetRewardCategoryListQuery();

  const handleAddRewardCategory = async (
    values: MutationAddNewRewardCategoryArgs,
  ) => {
    try {
      await addRewardCategoryFun({ create: values.create }).unwrap();
      refetch();
    } catch (error) {
      throw error;
    }
  };

  return {
    handleAddRewardCategory,
    isLoading,
  };
};
