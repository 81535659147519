import { useAddHabit } from "./useAddHabit";
import { useDeleteHabit } from "./useDeleteHabit";
import { useGetHabitDetails } from "./useGetHabitDetails";
import { useSetHabitTranslation } from "./useSetHabitTranslation";
import { useToggleHabitStatus } from "./useToggleHabitStatus";
import { useUpdateHabit } from "./useUpdateHabit";

export const useHabits = () => {
  const { handleAddHabit, isLoadingAddHabit } = useAddHabit();

  const { handleSetHabitTranslation, isLoadingSetHabitTranslation } =
    useSetHabitTranslation();

  const { handleGetHabitDetail, isLoadingHabitDetails } = useGetHabitDetails();

  const { handleUpdateHabit, isLoadingUpdateHabit } = useUpdateHabit();

  const { handleDeleteHabit, isLoadingDeleteHabit } = useDeleteHabit();

  const { handleToggleHabitStatus, isLoadingToggleHabitStatus } =
    useToggleHabitStatus();

  return {
    handleAddHabit,
    handleSetHabitTranslation,
    handleGetHabitDetail,
    handleUpdateHabit,
    handleDeleteHabit,
    handleToggleHabitStatus,
    isLoading:
      isLoadingAddHabit ||
      isLoadingSetHabitTranslation ||
      isLoadingHabitDetails ||
      isLoadingUpdateHabit ||
      isLoadingDeleteHabit ||
      isLoadingToggleHabitStatus,
  };
};
