import {
  convertTranslationArrToObj,
  convertTranslationObjToArr,
} from "helpers";
import {
  MutationUpdatePositionArgs,
  useLazyGetPositionQuery,
  useUpdatePositionMutation,
} from "graphql/_generated/graphql";

export const useUpdatePosition = () => {
  const [updatePositionFun, { isLoading }] = useUpdatePositionMutation();
  const [prevEditDataFun, { isLoading: prevDataLoading }] =
    useLazyGetPositionQuery();

  //This function is responsible for updating the position data
  const handleUpdatePosition = async (values: MutationUpdatePositionArgs) => {
    try {
      let translations = await convertTranslationArrToObj(
        values.updateObj.translations
      );
      let params = {
        updateObj: { ...values.updateObj, translations },
      };
      await updatePositionFun({
        updatePositionId: values.id,
        updateObj: params.updateObj,
      }).unwrap();
    } catch (error: any) {
      throw error;
    }
  };

  //this function is responsible for fetching the specific position data which position id we will be provided
  const handleFetchEditPositionData = async (id: string) => {
    try {
      const res = await prevEditDataFun({ getPositionId: id });

      const translations = await convertTranslationObjToArr(
        res.data?.getPosition.translations
      );

      return { ...res.data?.getPosition, translations };
    } catch (error) {
      throw error;
    }
  };

  return {
    isLoading: isLoading || prevDataLoading,
    handleFetchEditPositionData,
    handleUpdatePosition,
  };
};
