import { Space, Switch } from "antd";
import CustomModal from "components/custom-modal/CustomModal";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import CustomTreeSelect from "components/form/custom-tree-select/CustomTreeSelect";
import { CustomUploadFile } from "components/form/custom-upload-file/CustomUploadFile";
import { Form, Formik } from "formik";
import { ClientCreate } from "graphql/_generated/graphql";
import React from "react";
import { useTranslation } from "react-i18next";
import { inviteCompanySchema } from "validations/schema";
import ModalFooter from "../../../components/custom-modal/ModalFooter";
import styles from "./InviteCompany.module.scss";
import { CustomCheckBoxGroup } from "components/form/custom-checkbox-group/CustomCheckBoxGroup";
import { useInviteCompanyViewModel } from "../hooks/useInviteCompanyViewModel";

//define props
export type InviteCompanyProps = {
  visible?: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  id?: string;
};

export default function InviteClient({
  setVisible,
  visible,
  id,
}: InviteCompanyProps) {
  const { t } = useTranslation();
  const {
    handleCancel,
    handleChange,
    child,
    handleInviteCompany,
    regionList,
    setParentRegionalKey,
    rolesData,
    isLoadingClientsTree,
    companiesData,
    isLoading,
    isFetchingClientList,
    isFetchingListWithoutChildren,
  } = useInviteCompanyViewModel({
    setVisible,
  });

  //define initial values of the form
  const initialValues: ClientCreate = {
    roleId: "",
    image: "",
    email: "",
    companyName: "",
    parent: null,
    subscriptions: Number(undefined),
    isEapEnabled: false,
    regionalServiceKey: "",
  };

  return (
    <CustomModal
      width={600}
      visible={visible!}
      onCancel={handleCancel}
      footer={false}
      title={t("invite-company")}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={inviteCompanySchema}
        onSubmit={handleInviteCompany}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <CustomUploadFile
              imgUrl={values.image}
              name="image"
              setFieldValue={setFieldValue}
              accept="image/*"
              uploadText={t("upload-image")}
            />

            <CustomTextField
              label={t("company-name")}
              name="companyName"
              placeholder={t("company-name")}
            />

            <div className={styles["form-control"]}>
              <CustomTextField
                label={t("company-email")}
                type="email"
                name="email"
                placeholder={t("company-user-email")}
              />
            </div>
            <CustomTextField
              type="number"
              name="subscriptions"
              label={t("subscriptions")}
              placeholder={t("enter-subscriptions")}
            />
            <CustomSelectField
              name="regionalServiceKey"
              label={t("region")}
              placeholder={t("select-region")}
              options={regionList?.getRegionList}
              fieldNames={{
                label: "country",
                value: "regionalServiceKey",
              }}
              onChange={(value) => {
                setParentRegionalKey(value);
                setFieldValue("parent", null);
              }}
            />
            <CustomSelectField
              label={t("role")}
              className="form-select"
              name="roleId"
              placeholder={t("select-role")}
              options={rolesData?.getConfigRoles.options}
              fieldNames={{
                label: "label",
                value: "id",
              }}
            />
            <div className={styles["form-control"]}>
              <CustomCheckBoxGroup
                name="isEapEnabled"
                options={[{ label: t("active"), value: true }]}
              >
                {t("enable-eap")}
              </CustomCheckBoxGroup>
            </div>
            <div className={styles["form-control"]}>
              <Space>
                <Switch
                  id="child"
                  className="custom-switch"
                  onChange={(values) => handleChange(values)}
                />
                <label className={styles["child"]} htmlFor="child">
                  {t("is-child-company")}
                </label>
              </Space>
            </div>
            <div className={styles["form-control"]}>
              {child && (
                <CustomTreeSelect
                  allowClear
                  showArrow
                  treeNodeFilterProp="companyName"
                  treeLine={true && { showLeafIcon: false }}
                  label={t("parent-company")}
                  className="form-select"
                  name="parent"
                  treeData={companiesData?.getClientsTree}
                  placeholder={t("select-parent-company")}
                  fieldNames={{ label: "companyName", value: "id" }}
                  loading={isLoadingClientsTree}
                />
              )}
            </div>
            <ModalFooter
              saveText={t("invite-company")}
              onCancel={handleCancel}
              loading={
                isLoading ||
                isFetchingClientList ||
                isFetchingListWithoutChildren
              }
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
}
