import passwordChanged from "assets/images/password-changed.png";
import CustomButton from "components/custom-button/CustomButton";
import AuthLayout from "features/auth/auth-layout/AuthLayout";
import { setToken } from "features/auth/authSlice";
import styles from "features/auth/css/PasswordChanged.module.scss";
import { parseJwt } from "helpers";
import { useNavigateToUserProfile } from "hooks/useNavigateToUserProfile";
import { useNavigationToDashboard } from "hooks/useNavigationToDashboard";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

export default function PasswordChanged() {
  const { t } = useTranslation();
  const { navigateToSignInUserProfile } = useNavigateToUserProfile();
  const { navigateToSignInUserDashboard } = useNavigationToDashboard();
  const token = useSelector((state: any) => state.auth.token);
  const isProfileComplete = parseJwt(token).isProfileComplete;
  const location = useLocation();
  const navigate = useNavigate();
  const change: any = location.state;
  const dispatch = useDispatch();
  console.log(change);
  const handlePasswordChanged = () => {
    if (change.changed === true) {
      dispatch(
        setToken({
          token: null,
          refreshToken: null,
        }),
      );
      navigate("/");
    }
  };
  return (
    <AuthLayout card id="passwordChangedScreen">
      <div className={styles["text-center"]}>
        <img src={passwordChanged} alt={t("password-set")} />
        <h1 className={styles["title"]}>
          {t("password-set")}
        </h1>
        <p className={styles["description"]}>
          {t("password-set-description")}
        </p>
        <div className={styles["btn-div"]}>
          <CustomButton
            id="continueProfile"
            block
            className="primary-btn"
            onClick={() =>
              isProfileComplete
                ? navigateToSignInUserDashboard(token)
                : navigateToSignInUserProfile(token)
            }
          >
            {t("continue-label")}
          </CustomButton>
        </div>
      </div>
    </AuthLayout>
  );
}
