import React from "react";
import { Col, Row } from "antd";
import viwellLogo from "assets/images/viwell-mob-white.svg";
import styles from "./AuthLayout.module.scss";

type AuthLayoutProps = {
  children?: React.ReactNode;
  pageTitle?: string;
  subTitle?: string;
  card?: boolean;
  id?: string;
};

export default function AuthLayout({
  children,
  pageTitle,
  subTitle,
  card,
  id,
}: AuthLayoutProps) {
  return (
    <div className={styles["auth-layout"]} id={id}>
      <div className={styles["viwell-logo"]}>
        <img src={viwellLogo} alt="Viwell" />
      </div>
      <Row align="middle" justify="center" style={{ minHeight: "100vh" }}>
        <Col sm={24} md={20} lg={16} xl={16} xxl={14}>
          {card ? (
            <div className={styles["auth-card"]}>{children}</div>
          ) : (
            children
          )}
        </Col>
      </Row>
    </div>
  );
}
