import { useLocation } from "react-router-dom";
import { toBase64 } from "helpers";
import { useState } from "react";
import { defaultPagination } from "./usePagination";
import { defaultSearch } from "./useSearch";
import { usePersistedState } from "./usePersistedState";

/**
 * Help shape listing
 *
 * @returns {function, boolean}
 */
export const useGetFilteredData = (isSimple?: boolean) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const location = useLocation();

  const persistSearch = usePersistedState("search", location, defaultSearch);
  const persistFilters = usePersistedState("filters", location, {});

  const applyFiltersOnQueryData = async <T extends Function>(
    queryFun: T,
    filtersData?: any,
    args?: object,
  ) => {
    setIsLoading(true);

    // Merge the filters data from props and redux store
    const mergedFiltersData = {
      ...filtersData,
      ...{
        filters: {
          ...persistFilters,
          ...persistSearch,
          ...filtersData?.filters,
        },
      },
    };

    try {
      const data = await queryFun({
        ...args,
        sort: mergedFiltersData?.sorting,
        filters: mergedFiltersData?.filters,
        pagination: isSimple
          ? {
              offset: mergedFiltersData?.pagination?.offset
                ? (mergedFiltersData?.pagination?.offset?.page - 1) *
                  mergedFiltersData?.pagination?.offset?.pageSize
                : 0,
              limit: mergedFiltersData?.pagination?.offset?.pageSize || 10,
            }
          : {
              cursor: mergedFiltersData?.pagination?.offset
                ? toBase64({
                    offset:
                      (mergedFiltersData?.pagination?.offset?.page - 1) *
                      mergedFiltersData?.pagination?.offset?.pageSize,
                  })
                : toBase64({
                    offset: 0,
                  }),
              limit: mergedFiltersData?.pagination?.offset?.pageSize || 10,
            },
      }).unwrap();
      setIsLoading(false);

      return data;
    } catch (errorMsg) {
      setIsLoading(false);
      throw errorMsg;
    }
  };

  return { applyFiltersOnQueryData, isLoading };
};
