export const RankingIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect opacity="0.24" width="24" height="24" rx="4" fill="#7573E1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.167 6.39978C19.167 6.94978 18.792 7.39978 18.3337 7.39978H5.83366C5.37533 7.39978 5.00033 6.94978 5.00033 6.39978C5.00033 5.84978 5.37533 5.39978 5.83366 5.39978H18.3337C18.792 5.39978 19.167 5.84978 19.167 6.39978ZM15.8336 11.9999C15.8336 12.5499 15.4586 12.9999 15.0002 12.9999H5.83358C5.37524 12.9999 5.00024 12.5499 5.00024 11.9999C5.00024 11.4499 5.37524 10.9999 5.83358 10.9999H15.0002C15.4586 10.9999 15.8336 11.4499 15.8336 11.9999ZM11.667 18.6C12.1253 18.6 12.5003 18.15 12.5003 17.6C12.5003 17.05 12.1253 16.6 11.667 16.6H5.83366C5.37533 16.6 5.00033 17.05 5.00033 17.6C5.00033 18.15 5.37533 18.6 5.83366 18.6H11.667Z"
        fill="#303030"
      />
    </svg>
  );
};
