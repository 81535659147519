import styles from "../../CompanyDashboard.module.scss";
import { CloseOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import CustomDivider from "components/custom-divider/CustomDivider";
import { useTranslation } from "react-i18next";

export const DepartmentsDetails: React.FC<any> = ({ toggleOpen, data }) => {
  const { t } = useTranslation();

  const renderDepartments = () =>
    data.map((item: any, index: number) => (
      <>
        <div className={styles["depts-main-container"]}>
          <div className={styles["department-container"]}>
            <Typography className={styles["department-title"]}>
              {item.name}
            </Typography>
            <Typography className={styles["department-desc-text"]}>
              {`${item.submissions.toLocaleString()}/${item.totalEmployees.toLocaleString()}`}
            </Typography>
          </div>
          <Typography className={styles["department-desc-text"]}>
            {`${((item.submissions / item.totalEmployees) * 100).toFixed(0)}%`}
          </Typography>
        </div>
        {index === data?.length - 1 ? null : (
          <CustomDivider className={styles["divider-color"]} />
        )}
      </>
    ));

  return (
    <>
      <div className={styles["dept-details"]}>
        <div className={styles["header"]}>
          <div className={styles["item-header"]}>{t("other-departments")}</div>
          <CloseOutlined onClick={() => toggleOpen(false)} />
        </div>
        <div className={styles["dept-scroll-container"]}>
          <div className={styles["dept-details-container"]}>
            {renderDepartments()}
          </div>
        </div>
      </div>
    </>
  );
};
