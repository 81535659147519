type Translation = { lang: string; value: string };
type TranslationsForRequest = Record<string, Record<string, string>>;
type TranslationsForForm = Record<string, Translation[]>;

export function parseTranslationsFromResponse(
  translations: TranslationsForRequest,
): TranslationsForForm {
  const result: TranslationsForForm = {};

  Object.entries(translations).forEach(([lang, translationObj]) => {
    Object.entries(translationObj).forEach(([key, value]) => {
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push({ lang, value });
    });
  });

  return result;
}

export function formatTranslationsForRequest(values: TranslationsForForm) {
  const result: TranslationsForRequest = {};

  Object.entries(values).forEach(([key, translations]) => {
    translations.forEach(({ lang, value }) => {
      if (!result[lang]) {
        result[lang] = {};
      }
      result[lang][key] = value;
    });
  });

  return result;
}
