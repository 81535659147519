/**
 * Route state interface.
 *
 * @param state
 * @returns modified state
 */
export interface IAssessmentCreateRouteState {
  assessmentId: string;
  assessmentLanguage: string;
  assessmentTypeName: string;
  assessmentTypeId?: string;
}

/**
 * Route state modifier function.
 *
 * @param state
 * @returns modified state
 */
const AssessmentCreateRouteState = (state: IAssessmentCreateRouteState) => {
  return {
    ...state,
  };
};

export default AssessmentCreateRouteState;
