import {
  useGetEmployeeListQuery,
  useLazyGetClientsTreeQuery,
  useLazyGetPositionsConfigQuery,
  useLazyGetBuildingsConfigQuery,
  useLazyGetDepartmentsConfigQuery,
  useLazyGetEmployeeListQuery,
} from "graphql/_generated/graphql";

export const useGetUserConfig = () => {
  const [getClientsTreeQueryFun] = useLazyGetClientsTreeQuery();
  const [getDepartmentsConfigQuery] = useLazyGetDepartmentsConfigQuery();
  const [getPositionsConfigQuery] = useLazyGetPositionsConfigQuery();
  const [getBuildingsConfigQuery] = useLazyGetBuildingsConfigQuery();
  const [getEmployeeListFun] = useLazyGetEmployeeListQuery();
  const { refetch } = useGetEmployeeListQuery();

  return {
    getClientsTreeQueryFun,
    getDepartmentsConfigQuery,
    getPositionsConfigQuery,
    getBuildingsConfigQuery,
    getEmployeeListFun,
    refetchEmployees: refetch,
  };
};
