import { useAppDispatch } from "hooks/useStoreHooks";
import { permissions } from "store/permissionsSlice";
import { setToken } from "features/auth/authSlice";
import { useNavigateToUserProfile } from "hooks/useNavigateToUserProfile";
import { useNavigationToDashboard } from "hooks/useNavigationToDashboard";
import { useRequestOtp } from "features/auth/hooks/useRequestOtp";
import { useSignIn } from "features/auth/hooks/useSignIn";
import { client } from "store/store";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useLazyGetPermissionsQuery } from "graphql/_generated/graphql";
import { parseJwt } from "helpers";
import customNotification from "components/custom-notification/CustomNotification";
import { useTranslation } from "react-i18next";

export const useSignInViewModel: any = () => {
  const dispatch = useAppDispatch();
  const { handleError } = useErrorHandler();
  const { handleSignIn, isLoading } = useSignIn();
  const { handleRequestOtp, isLoading: requestOtpLoading } = useRequestOtp();
  const [getPermissionsFun] = useLazyGetPermissionsQuery();
  const { navigateToSignInUserDashboard } = useNavigationToDashboard();
  const { navigateToSignInUserProfile } = useNavigateToUserProfile();
  const { t } = useTranslation();

  const onSubmit = async (values: {
    email: string;
    password: string;
    deviceToken: string;
  }) => {
    try {
      // const deviceToken = await getToken(messaging);
      const res = await handleSignIn({ ...values, deviceToken: "" });

      if (res.login.__typename === "Msg") {
        handleRequestOtp({ body: { email: values.email } });
        customNotification("success", res.login.msg);
      } else {
        const { isProfileComplete, iat } = parseJwt(res.login.token);

        const currentTime = Math.floor(new Date().getTime() / 1000);
        const clockSyncBuffer = 5;
        if (iat > currentTime + clockSyncBuffer) {
          customNotification("warning", t("clock-not-synchronized"), 0);
        }

        //this set headers is due to getPermissions Api
        client.setHeader("token", res.login.token);
        let data;
        if (isProfileComplete) {
          //call permissions api
          data = await getPermissionsFun().unwrap();
        }

        //store headers to store
        dispatch(setToken(res));

        //set Permissions to store and constant
        dispatch(permissions(data?.getPermissions));

        if (isProfileComplete) {
          //navigate according to userRole
          navigateToSignInUserDashboard(res.login.token);
        } else {
          //navigate according to userRole
          navigateToSignInUserProfile(res.login.token);
        }
      }
    } catch (error: any) {
      //remove the token which we have assigned manually from the headers if any error occurs in the permissions api
      client.setHeader("token", "");
      handleError(error as string | object);
    }
  };

  return {
    onSubmit,
    isLoading,
  };
};
