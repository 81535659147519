import { customNotification } from "components/custom-notification/CustomNotification";
import { useContent } from "features/content/content/useContent";
import { getContentType } from "features/content/utils";
import { FormikHelpers } from "formik";
import { Content, GetCategoriesTreeQuery } from "graphql/_generated/graphql";
import { cloneObject, contentTypes } from "helpers";
import { useLanguages, usePillarIdAndName } from "hooks";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetContentConfig } from "./useGetContentConfig";

export type ExtendedContent = Content & {
  categoryIds: string[];
  coverImage: string;
};

export const useAddContentViewModel = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { handleError } = useErrorHandler();
  const { languages } = useLanguages();
  const { pillarsData } = usePillarIdAndName();
  const [value, setValue] = useState(null);
  const [pillarId, setPillarId] = useState<string | undefined>();
  const [categoriesData, setCategoriesData] = useState<
    GetCategoriesTreeQuery | undefined
  >();
  const editId: string = location.state;
  const {
    contentData,
    contentRefetch,
    getCategoriesFun,
    tagsData,
    contentConfigRefetch,
    getSpecialistConfigData,
    getVideoDetailsFun,
    refetch,
  } = useGetContentConfig();

  const localizedContent = (values: typeof initialValues) => {
    const { contentType } = values;
    const dynamicContentType = getContentType(contentType);

    return {
      setSpecialist: () => {
        values[dynamicContentType].forEach((element: any) => {
          element.specialist = getSpecialistConfigData?.find(
            (o) => o.value === element.specialist,
          )?.extra;
        });

        return values;
      },
      resetSpecialist: () => {
        const valuesClone = cloneObject(values);
        valuesClone[dynamicContentType]?.forEach((element: any) => {
          element.specialist = element.specialist?.id;
        });

        return valuesClone;
      },
    };
  };

  const {
    handleAddContent,
    handleFetchEditContentData,
    handleUpdateContent,
    handleDeleteSubTitle,
    isLoading,
  } = useContent();

  let myArray: [];
  const [initialValues, setInitialValues] = useState<any>({
    shortDescription: "",
    title: "",
    description: "",
    translations: [],
    creatorId: "",
    language: "",
    spokenLanguage: null,
    thumbnailImage: "",
    tagIds: [],
    pillarIds: "",
    categoryIds: [],
    videoUrl: "",
    audioUrl: "",
    fileUrl: "",
    text: "",
    duration: "",
    points: {
      start: null,
      end: null,
    },
    // status: "",
    localizedAudios: [],
    localizedVideos: [],
    localizedFiles: [],
    localizedTexts: [],
    type: "",
    originalVideoSubtitle: [{ language: "", url: "" }],
    subtitles: [],
    contentType: "",
    gallery: [
      {
        image: "",
      },
    ],
    relatedRecipes: [],
    timeRequired: undefined,
    serving: undefined,
  });

  const [contentTypeItems, setContentTypeItems] = useState<
    {
      key: number;
      label: string;
      value: string;
    }[]
  >([]);

  //this function is getting the data from api and show in the form
  const showPrevData = async () => {
    if (editId) {
      try {
        let data: typeof initialValues = await handleFetchEditContentData(
          editId,
        );
        let subtitles: any = [];
        const RelatedRecipes: string[] = [];
        data = localizedContent(data).resetSpecialist();
        //this block of code is fetching the subtitles info from the backend side and show in the ui
        if (data?.videoUrl) {
          const videoUri = data?.videoUrl.split("/").pop().split(".")[0];
          const res = await getVideoDetailsFun({ videoUri });
          const subtitlesArr: any = [];
          res.data?.getVideoDetails.subtitles.map((subtitle: any) => {
            subtitlesArr.push({
              language: subtitle.language,
              url: subtitle.uri,
              checked: subtitle.isActive,
            });
          });
          subtitles = subtitlesArr;
        }

        const activeSubtitleValue = subtitles.filter(
          (item: { checked: boolean }) => item.checked === true,
        )[0]?.url;
        setValue(activeSubtitleValue);

        if (data.relatedRecipes) {
          data?.relatedRecipes
            .filter((item: any) => item.isActive === true)
            .map((item: any) => {
              return RelatedRecipes.push(item.id);
            });
        }
        if (data.gallery) {
          myArray = data.gallery.map((str: string, index: number) => ({
            image: str,
          }));
        }

        setInitialValues({
          subtitles,
          language: data.language,
          spokenLanguage: data.spokenLanguage,
          thumbnailImage: data.thumbnailImage,
          translations: data.translations,
          title: data.title,
          description: data.description,
          shortDescription: data?.shortDescription,
          tagIds: data?.tags,
          categoryIds: data.categories
            .filter((category: any) => category?.isActive)
            .map(({ id }: { id: string }) => id),
          pillarIds: data?.pillar?.id,
          creatorId: data.creator.id,
          points: data?.points,
          // status: data.status,
          coverImage: data?.coverImage,
          duration: data?.duration ? data?.duration : "",
          videoUrl: data?.videoUrl ? data?.videoUrl : "",
          audioUrl: data?.audioUrl ? data?.audioUrl : "",
          fileUrl: data?.fileUrl ? data?.fileUrl : "",
          text: data?.text ? data?.text : "",
          serving: data?.serving ? data?.serving : undefined,
          gallery: data?.gallery ? myArray : "",
          relatedRecipes: data?.relatedRecipes ? RelatedRecipes : "",
          timeRequired: data?.timeRequired ? data?.timeRequired : "",

          localizedVideos: data?.localizedVideos
            ? data?.localizedVideos
            : [{ language: "", url: "" }],
          localizedAudios: data?.localizedAudios
            ? data?.localizedAudios
            : [{ language: "", url: "" }],
          localizedTexts: data?.localizedTexts
            ? data?.localizedTexts
            : [{ language: "", url: "" }],
          localizedFiles: data?.localizedFiles
            ? data?.localizedFiles
            : [{ language: "", text: "" }],
          contentType: data.contentType ?? null,
        });
      } catch (e) {
        handleError(e as string | object);
      }
    }
  };

  // this useEffect created the options array for content type select
  useEffect(() => {
    setContentTypeItems(contentTypes());

    //this function will fetch the prev data and show in the form if edit id exists
    showPrevData();
  }, []);

  useEffect(() => {
    (pillarId ?? initialValues?.pillarIds) && fetchPillarCategories();
  }, [pillarId, initialValues?.pillarIds]);

  const handlePillarChange = (value: string) => {
    setPillarId(value);
  };

  const fetchPillarCategories = async () => {
    const res = await getCategoriesFun({
      pillarId: pillarId ?? initialValues?.pillarIds,
    });
    setCategoriesData(res?.data);
  };

  const onSubmit = async (
    values: ExtendedContent,
    { resetForm }: FormikHelpers<ExtendedContent>,
  ) => {
    try {
      const contentData = {
        ...values,
        ...localizedContent(values).setSpecialist(),
      };

      if (!editId) {
        await handleAddContent({
          create: contentData,
        });
        customNotification("success", t("content-added"));
        navigate("/content");
      } else {
        await handleUpdateContent(editId, {
          updateObj: contentData,
        });
        customNotification("success", t("content-updated"));
        navigate("/content#persist");
      }

      resetForm();
      refetch();
      contentConfigRefetch();
      contentRefetch();
    } catch (error) {
      handleError(error as string | object);
    }
  };

  return {
    editId,
    initialValues,
    onSubmit,
    contentTypeItems,
    languages,
    pillarsData,
    categoriesData,
    tagsData,
    handleDeleteSubTitle,
    value,
    isLoading,
    handlePillarChange,
  };
};
