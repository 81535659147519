import { Pagination, PaginationProps } from "antd";
import * as React from "react";
import { useTranslation } from "react-i18next";

interface CustomPaginationProps extends PaginationProps {}

const CustomPagination: React.FunctionComponent<CustomPaginationProps> = (
  props,
) => {
  const { t } = useTranslation();
  return (
    <Pagination
      {...props}
      showTotal={(total, range) => (
        <div>
          {t("showing")}&nbsp;
          <b>
            {range[0]}-{range[1]}
          </b>
          &nbsp;{t("of")}
          <b> {total}</b>&nbsp;{t("items")}
        </div>
      )}
      responsive
    />
  );
};

export default CustomPagination;
