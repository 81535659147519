import { ContentType } from "graphql/_generated/graphql";

export const contentTypes = () => {
  return Object.keys(ContentType)
  .filter(
    (item) =>
      ![
        ContentType.EducationalAudio.toString(),
        ContentType.SoundscapeAudio,
        ContentType.MusicAudio,
        ContentType.SoundTherapyAudio,
      ].includes(item),
  )
  .map((item, index) => {
    return {
      key: index,
      label: item?.replace(/[A-Z]/g, (match) => " " + match),
      value: item,
    };
  });
};
