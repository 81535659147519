import {
  MutationAddNewPartnerRewardArgs,
  useAddNewPartnerRewardMutation,
  useGetPartnerRewardListQuery,
} from "graphql/_generated/graphql";

export const useAddPartnerReward = () => {
  const [addPartnerRewardFun, { isLoading }] = useAddNewPartnerRewardMutation();
  const { refetch } = useGetPartnerRewardListQuery();

  const handleAddPartnerReward = async (
    values: MutationAddNewPartnerRewardArgs,
  ) => {
    try {
      await addPartnerRewardFun({
        create: values.create,
      }).unwrap();
      refetch();
    } catch (error) {
      throw error;
    }
  };

  return {
    handleAddPartnerReward,
    isLoading,
  };
};
