import React, { useState, useEffect } from "react";
import { MoreOutlined } from "@ant-design/icons";
import { Avatar, Space, Switch, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import {
  AchievementList,
  useGetAchievementListQuery,
  useLazyGetAchievementListQuery,
} from "graphql/_generated/graphql";
import moment from "moment";

import CustomButton from "components/custom-button/CustomButton";
import CustomDropDown from "components/custom-dropdown/CustomDropDown";
import CustomMenu from "components/custom-menu/CustomMenu";
import { customNotification } from "components/custom-notification/CustomNotification";
import CustomTable from "components/custom-table/CustomTable";
import CustomPopconfirm from "components/form/custom-pop-confirm/CustomPopConfirm";
import { errorFormat, PERMS } from "helpers";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import { useTranslation } from "react-i18next";

import AddBadge from "../add-badge/AddBadge";
import { useAchievement } from "../useAchievement";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import { useErrorHandler } from "hooks/useErrorHandler";

interface BadgesListProps {}

const BadgesList: React.FunctionComponent<BadgesListProps> = (props) => {
  //states
  const [editId, setEditId] = useState<string | null>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [filtersData, setFiltersData] = useState<any>(null);
  const [columnsData, setColumnsData] = useState<AchievementList>();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation();

  //end states

  //hooks
  const { isFetching, refetch } = useGetAchievementListQuery();
  const [getAllAchievementsDataFun] = useLazyGetAchievementListQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const { handleActivationAchievement } = useAchievement();
  const { handleError } = useErrorHandler();

  //end hooks

  //helpers
  const handleMenu = ({ domEvent, key, keyPath, id }: any) => {
    if (key === "edit-badge") {
      setVisible(true);
      setEditId(id);
    }
  };
  //end helpers

  //effects
  useEffect(() => {
    fetchDataFromApi(
      getAllAchievementsDataFun,
      setColumnsData,
      filtersData,
    ).catch((e) => handleError(e));
  }, [isFetching]);
  //end effects

  const columns: ColumnsType<AchievementList["data"][0]> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: {
        multiple: 1,
      },
      render: (_text, _record, index) =>
        (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, index) => (
        <Space>
          <CustomAvatar
            shape="square"
            imgSrc={record.icon}
            style={{
              filter: record?.isActive ? "grayscale(0)" : "grayscale(1)",
            }}
          />
          <span>{record.name}</span>
        </Space>
      ),
    },
    {
      title: t("badge-type"),
      dataIndex: "tracker.goalType",
      key: "type",
      render: (_text, record, index) => record.tracker.goalType,
    },
    {
      title: t("badge-goal"),
      dataIndex: "goal",
      key: "goal",
      align: "center",
      render: (_text, record, index) => record.tracker.goal,
    },
    {
      title: t("created-at"),
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: {
        multiple: 1,
      },
      render: (createdAt: string) =>
        moment(createdAt).format("MMMM Do YYYY, h:mm:ss a"),
    },
    {
      title: t("updated-at"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: {
        multiple: 1,
      },
      render: (updatedAt: string) =>
        moment(updatedAt).format("MMMM Do YYYY, h:mm:ss a"),
    },
    {
      title: t("status"),
      dataIndex: "isActive",
      key: "status",
      align: "center",
      render: (isActive: boolean) =>
        isActive ? (
          <Tag className="active-tag">{t("active")}</Tag>
        ) : (
          <Tag className="de-active-tag">{t("deactivated")}</Tag>
        ),
    },
    {
      title: t("activation"),
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (id: string, { isActive }) => (
        <CheckPermissions permission={PERMS.TOGGLE_ACHIEVEMENT_ACTIVATION}>
          <CustomPopconfirm
            onConfirm={async () => {
              try {
                const isSpecialistActive = await handleActivationAchievement(
                  id,
                );
                customNotification(
                  "success",
                  isSpecialistActive
                    ? t("badge-activated-successfully")
                    : t("badge-deactivated-successfully"),
                );
                refetch();
              } catch (error) {
                handleError(error as string | object);
              }
            }}
            title={
              isActive
                ? t("confirm-deactivate-badge")
                : t("confirm-activate-badge")
            }
          >
            <Switch
              className="custom-switch"
              defaultChecked
              checked={isActive}
            />
          </CustomPopconfirm>
        </CheckPermissions>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "action",
      align: "center",
      render: (id: string) => (
        <>
          <CheckPermissions permission={PERMS.UPDATE_ACHIEVEMENT}>
            <CustomDropDown
              overlay={
                <CustomMenu
                  items={[{ label: t("edit-badge"), key: "edit-badge" }]}
                  onClick={({ domEvent, key, keyPath }) =>
                    handleMenu({ domEvent, key, keyPath, id })
                  }
                />
              }
            >
              <CustomButton type="link" className="link-btn">
                <MoreOutlined />
              </CustomButton>
            </CustomDropDown>
          </CheckPermissions>
        </>
      ),
    },
  ];

  return (
    <>
      <CustomTable
        filters={{
          handleCallback: async (filtersData: any) => {
            await fetchDataFromApi(
              getAllAchievementsDataFun,
              setColumnsData,
              filtersData,
            );
            setPageSize(filtersData?.pagination?.offset?.pageSize);
            setCurrentPage(filtersData?.pagination?.offset?.page);
            setFiltersData(filtersData);
          },
          date: [
            {
              applyAs: "and",
              key: "createdAt",
              comparison: "Range",
            },
          ],
          status: {
            applyAs: "and",
            key: "isActive",
            comparison: "Equal",
          },
        }}
        rowKey="id"
        loading={isLoading}
        columns={columns}
        totalItems={columnsData?.metadata?.total}
        permission={PERMS.ADD_NEW_ACHIEVEMENT}
        onClick={() => setVisible(true)}
        headerTitle={t("badges-title")}
        btnText={t("add-badge")}
        dataSource={columnsData?.data}
      />
      <AddBadge
        visible={visible}
        setVisible={setVisible}
        editId={editId}
        setEditId={setEditId}
      />
    </>
  );
};

export default BadgesList;
