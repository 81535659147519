import { useToggleChallengeActivationMutation } from "graphql/_generated/graphql";

export const useToggleActivationChallenge = () => {
  const [ActivationDeactivationFun, { isLoading }] =
    useToggleChallengeActivationMutation();

  //this function is responsible for Activation/Deactivation Challenge
  const handleActivationChallenge = async (id: string) => {
    try {
      const res = await ActivationDeactivationFun({
        toggleChallengeActivationId: id,
      }).unwrap();
      return res.toggleChallengeActivation;
    } catch (error) {
      throw error;
    }
  };
  return {
    isLoading,
    handleActivationChallenge,
  };
};
