export const MenuCalendarSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon">
        <path
          id="Icon_2"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.8333 15.0288C15.8333 15.4888 15.4592 15.8621 15 15.8621H5C4.54083 15.8621 4.16667 15.4888 4.16667 15.0288V9.19548H15.8333V15.0288ZM6.66667 5.02881C7.12667 5.02881 7.5 5.40131 7.5 5.86214C7.5 6.32298 7.12667 6.69548 6.66667 6.69548C6.20667 6.69548 5.83333 6.32298 5.83333 5.86214C5.83333 5.40131 6.20667 5.02881 6.66667 5.02881ZM10 5.02881C10.46 5.02881 10.8333 5.40131 10.8333 5.86214C10.8333 6.32298 10.46 6.69548 10 6.69548C9.54 6.69548 9.16667 6.32298 9.16667 5.86214C9.16667 5.40131 9.54 5.02881 10 5.02881ZM15 2.52881H5C3.62167 2.52881 2.5 3.65048 2.5 5.02881V7.52881V9.19548V15.0288C2.5 16.4071 3.62167 17.5288 5 17.5288H15C16.3783 17.5288 17.5 16.4071 17.5 15.0288V9.19548V7.52881V5.02881C17.5 3.65048 16.3783 2.52881 15 2.52881Z"
          fill="#303030"
        />
      </g>
    </svg>
  );
};
