import { useGetPopularPillars } from "features/company-dashboard/hooks/useGetPopularPillars";
import { FilterValuesProps } from "features/company-dashboard/types";
import {
  getFetchingStatus,
  getFormattedDateRanges,
  pillarsDataMapper,
} from "features/company-dashboard/utils";
import {
  UserFilters,
  useGetConfigPillarsQuery,
} from "graphql/_generated/graphql";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InfoTextProps } from "../organization-metrices/types";
import { List } from "./List";
import { PillarDetails } from "./PillarDetails";
import { ContentDetailProps } from "./types";

interface PillarsProps extends InfoTextProps {
  userFilters: UserFilters;
  selectedValues: FilterValuesProps;
  pillarDetailsVisible: boolean;
  selectedPillar: any;
  setSelectedPillar: any;
  setPillarDetailsVisible: any;
  visible: boolean;
}

export const Pillars: FunctionComponent<PillarsProps> = ({
  userFilters,
  selectedValues,
  pillarDetailsVisible,
  selectedPillar,
  setPillarDetailsVisible,
  setSelectedPillar,
  visible,
  selectedInfoText,
  setSelectedInfoText,
}) => {
  const { t } = useTranslation();

  const [popularPillarsContent, setPopularPillarsContent] =
    useState<ContentDetailProps>({
      isDataConcealed: false,
      data: [],
    });

  const { handleGetPopularPillars, isLoading } = useGetPopularPillars();
  const { refetch: fetchPillars } = useGetConfigPillarsQuery();

  useEffect(() => {
    const isFetching = getFetchingStatus(selectedValues);
    if (isFetching) getPillarsData();
  }, [selectedValues, userFilters]);

  const getPillarsData = async () => {
    if (selectedValues.pastDays) {
      const { startingDate, endingDate } =
        getFormattedDateRanges(selectedValues);

      const popularPillars = await handleGetPopularPillars({
        startDate: startingDate,
        endDate: endingDate,
        userFilters,
      });

      if (popularPillars) {
        const pillarsData = await fetchPillars();

        const mappedPillars = popularPillars.items.length
          ? popularPillars.items.map((item, index) => {
              const pillarName =
                pillarsData?.data?.getConfigPillars?.options?.find(
                  (pillar) => pillar.id === item.id,
                )?.label || "";

              const pillarIcon = pillarsDataMapper?.find(
                (pillar) => pillar.name === pillarName,
              )?.icon;
              return {
                id: index + 1,
                name: pillarName,
                value: item.count,
                key: item.id,
                total: popularPillars.total,
                icon: pillarIcon,
              };
            })
          : [];
        setPopularPillarsContent({
          isDataConcealed: popularPillars?.isDataConcealed,
          data: mappedPillars,
        });
      }
    }
  };

  const handleRefresh = () => {
    getPillarsData();
  };

  const infoTexts = [
    `${t("engaging-wellbeing-pillars")} ${t("pillars-desc")} ${t(
      "based-on-interaction-classes",
    )}`,
    t("analyses-interactions"),
    t("explore-interaction-overtime"),
    t("enhance-employee-engagement"),
  ];

  return (
    <>
      {pillarDetailsVisible ? (
        <PillarDetails
          item={{
            selectedValues,
            selectedPillar,
          }}
          infoTexts={infoTexts}
          visible={visible}
          userFilters={userFilters}
          handleItemClick={() => {
            setSelectedPillar("");
            setPillarDetailsVisible(false);
          }}
          selectedInfoText={selectedInfoText}
          setSelectedInfoText={setSelectedInfoText}
        />
      ) : (
        <List
          item={{
            title: t("most-popular-pillars"),
            content: popularPillarsContent,
            selectedValues,
            type: t("pillar-title"),
            handleRefresh,
            isLoading,
          }}
          infoTexts={infoTexts}
          visible={visible}
          handleItemClick={(item: any) => {
            setSelectedPillar(item);
            setPillarDetailsVisible(true);
          }}
          selectedInfoText={selectedInfoText}
          setSelectedInfoText={setSelectedInfoText}
        />
      )}
    </>
  );
};
