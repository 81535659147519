import { useDeleteMeasurementMutation } from "graphql/_generated/graphql";

export const useDeleteMeasurement = () => {
  const [deleteUnitFun, { isLoading: isLoadingDeleteUnit }] =
    useDeleteMeasurementMutation();

  const handleDeleteUnit = async (id: string) => {
    try {
      const res = await deleteUnitFun({ deleteMeasurementId: id }).unwrap();
      return res.deleteMeasurement;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleDeleteUnit,
    isLoadingDeleteUnit,
  };
};
