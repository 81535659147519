import { MoreOutlined } from "@ant-design/icons";
import DownOutlined from "@ant-design/icons/DownOutlined";
import { Select, Switch, Tag, Tree, Typography } from "antd";
import type { DataNode } from "antd/es/tree";
import { ColumnsType } from "antd/lib/table";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import CustomButton from "components/custom-button/CustomButton";
import CustomDropDown from "components/custom-dropdown/CustomDropDown";
import { customNotification } from "components/custom-notification/CustomNotification";
import CustomTable from "components/custom-table/CustomTable";
import CustomPopconfirm from "components/form/custom-pop-confirm/CustomPopConfirm";
import styles from "features/assessment/css/AssessmentList.module.scss";
import { useToggleTranslation } from "features/assessment/hooks/useToggleTranslation";
import AddLanguage from "features/assessment/language/AddLanguage";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import {
  AssessmentOutput,
  AssessmentsOutput,
  Language,
  useGetAssessmentTypesQuery,
  useGetAssessmentsListQuery,
  useLazyGetAssessmentsListQuery,
} from "graphql/_generated/graphql";
import { getLanguage } from "helpers";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AssessmentRoutes from "routes/RouteCollections/AssessmentRoutes";
import AssessmentCreateRouteState from "routes/RouteStates/AssessmentCreateRouteState";
import { RootState } from "store/store";
import { useToggleAssessment } from "../../features/assessment/hooks/useToggleAssessment";
import SelectAssessmentTypes from "../../features/assessment/select-types/SelectAssessmentTypes";

interface AssessmentsListProps {}
interface NewType extends AssessmentsOutput {
  hasChildren: boolean;
  languageText: Language;
  isLangActive: boolean;
}

const AssessmentsList: React.FunctionComponent<AssessmentsListProps> = (
  props,
) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState<boolean>(false);
  const [languageVisible, setLanguageVisible] = useState<boolean>(false);
  const [assessmentData, setAssessmentData] = useState<any>(null);
  const [filtersData, setFiltersData] = useState<any>(null);
  const [columnsData, setColumnsData] = useState<AssessmentOutput>();
  const region = useSelector((state: RootState) => state.auth.region);

  const { fetchDataFromApi, isLoading } = useFetchDataFromApi(true);
  const { handleToggleAssessment } = useToggleAssessment();
  const { handleToggleTranslation } = useToggleTranslation();
  const { data, refetch, isFetching } = useGetAssessmentsListQuery();
  const [getAssessmentsListFun] = useLazyGetAssessmentsListQuery();
  const { data: assessmentTypes } = useGetAssessmentTypesQuery();
  const { handleError } = useErrorHandler();

  const addAssessmentBtn = () => (
    <CustomButton className="primary-btn" onClick={() => setVisible(true)}>
      Add New Assessment
    </CustomButton>
  );

  const handleMenu = ({ key, record }: any) => {
    let assessmentRoute = AssessmentRoutes.AssessmentCreateRoute.path;

    if (record?.assessmentTypeId?.name?.includes("Check-In")) {
      assessmentRoute = AssessmentRoutes.AssessmentCreateCheckInRoute.path;
    }

    if (key === "edit-assessment") {
      navigate(assessmentRoute, {
        state: AssessmentCreateRouteState({
          assessmentId: record?.id,
          assessmentLanguage: record?.language,
          assessmentTypeName: record?.name,
          assessmentTypeId: record?.assessmentTypeId?.id,
        }),
      });
    } else if (key === "add-language") {
      setAssessmentData(record);
      setLanguageVisible(true);
    } else if (key === "edit-language") {
      let assessmentRoute = "/assessment/add-language";
      if (record?.assessmentTypeId?.name?.includes("Check-In")) {
        assessmentRoute = "/assessment/checkin/add-language";
      }
      navigate(assessmentRoute, {
        state: {
          assessmentId: record.id,
          name: record.name,
          language: record.languageText,
        },
      });
    }
  };

  useEffect(() => {
    fetchDataFromApi(getAssessmentsListFun, setColumnsData, filtersData).catch(
      (e) => handleError(e),
    );
  }, [isFetching, region]);

  const mappedColumnsData =
    columnsData?.data.map((item) => {
      return {
        ...item,
        languageText: getLanguage(item.language),
        hasChildren: true,
        isLangActive: false,
        children: item.assessmentLanguages?.length
          ? item.assessmentLanguages?.map((record) => {
              return {
                ...item,
                isActive: false,
                isLangActive: record.isActive,
                languageText: record.language,
              };
            })
          : null,
      };
    }) ?? [];

  type MappedAssessmentList = NonNullable<typeof mappedColumnsData>[0];

  const columns: ColumnsType<MappedAssessmentList> = [
    {
      title: t("id"),
      key: "id",
      sorter: {
        multiple: 1,
      },
      render: (_text, _record, index) => (
        <Typography className={styles["list-id-content"]}>
          {_record.hasChildren
            ? (currentPage - 1) * pageSize + index + 1
            : null}
        </Typography>
      ),
    },
    {
      title: t("assessment-name"),
      key: "name",
      dataIndex: "name",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => {
        const treeData: DataNode[] = [
          {
            title: record?.name,
            key: "tree-0",
          },
        ];
        return record?.hasChildren ? (
          _text
        ) : (
          <Tree
            showLine
            switcherIcon={<DownOutlined />}
            defaultExpandedKeys={["tree-0"]}
            treeData={treeData}
          />
        );
      },
    },
    {
      title: t("type"),
      key: "assessmentTypeId",
      dataIndex: "assessmentTypeId",
      render: (_text, record, _index) => record?.assessmentTypeId?.name,
    },
    {
      title: t("attempts"),
      key: "attemptCount",
      dataIndex: "attemptCount",
      render: (_text, record, _index) =>
        record?.hasChildren ? record?.attemptCount : "",
    },
    {
      title: t("language"),
      key: "languageText",
      dataIndex: "languageText",
    },
    {
      title: t("created-at"),
      key: "createdAt",
      dataIndex: "createdAt",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) =>
        moment(record.createdAt).format("MMMM Do YYYY"),
    },
    {
      title: t("updated-at"),
      key: "updatedAt",
      dataIndex: "updatedAt",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) =>
        moment(record.updatedAt).format("MMMM Do YYYY"),
    },
    {
      title: t("status"),
      key: "isActive",
      dataIndex: "isActive",
      align: "center",
      render: (_text, record, _index) => {
        return record?.isActive || record?.isLangActive ? (
          <Tag className="active-tag">{t("active")}</Tag>
        ) : (
          <Tag className="de-active-tag">{t("deactivated")}</Tag>
        );
      },
    },
    {
      title: t("activation"),
      key: "isActive",
      dataIndex: "isActive",
      align: "center",
      render: (_text, record, _index) => {
        let isAssessment = record?.hasChildren;
        return (
          <CustomPopconfirm
            onConfirm={async () => {
              try {
                let isActiveTag = null;
                if (isAssessment) {
                  isActiveTag = await handleToggleAssessment(record.id);
                } else {
                  const data = {
                    assessmentId: record.id,
                    language: record.languageText,
                    isActive: !record?.isLangActive,
                  };
                  isActiveTag = await handleToggleTranslation(data);
                }

                customNotification(
                  "success",
                  isActiveTag
                    ? t("assessment-activated-success")
                    : t("assessment-deactivated-success"),
                );
                refetch();
              } catch (error) {
                handleError(error as string | object);
              }
            }}
            title={
              record.isActive || record?.isLangActive
                ? t("deactivate-assessment")
                : t("activate-assessment")
            }
          >
            <Switch
              className="custom-switch"
              defaultChecked
              checked={record?.isActive || record?.isLangActive}
            />
          </CustomPopconfirm>
        );
      },
    },
    {
      title: t("action"),
      dataIndex: "id",
      align: "center",
      render: (_text, record, _index) => {
        const mainActions = [
          { key: "edit-assessment", label: t("edit-assessment") },
          { key: "add-language", label: t("add-language") },
        ];
        const langActions = [
          { key: "edit-language", label: t("edit-language") },
        ];
        return (
          <CustomDropDown
            menu={{
              items: record?.hasChildren ? mainActions : langActions,
              onClick: ({ domEvent, key, keyPath }) =>
                handleMenu({ domEvent, key, keyPath, record }),
            }}
          >
            <CustomButton type="link" className="link-btn">
              <MoreOutlined />
            </CustomButton>
          </CustomDropDown>
        );
      },
    },
  ];

  return (
    <DashboardLayout openKey={["Assessment"]}>
      <>
        <Breadcrumbs
          data={[
            {
              label: t("assessments"),
            },
            {
              label: t("assessment-list"),
            },
          ]}
        />
        <CustomTable
          filters={{
            handleCallback: async (filtersData: any) => {
              await fetchDataFromApi(
                getAssessmentsListFun,
                setColumnsData,
                filtersData,
              );
              setPageSize(filtersData?.pagination?.offset?.pageSize);
              setCurrentPage(filtersData?.pagination?.offset?.page);
              setFiltersData(filtersData);
            },
            status: {
              applyAs: "and",
              key: "isActive",
              comparison: "Equal",
            },
            date: [
              {
                applyAs: "and",
                key: "createdAt",
                comparison: "Range",
                placeholder: "Created",
              },
              {
                applyAs: "and",
                key: "updatedAt",
                comparison: "Range",
                placeholder: "Modified",
              },
            ],
            manualFilters: [
              {
                key: "assessmentTypeId",
                value: "d",
                comparison: "Equal",
                applyAs: "and",
                render: (onChangeCallback, value) => (
                  <Select
                    options={assessmentTypes?.getAssessmentTypes}
                    fieldNames={{ label: "name", value: "id" }}
                    allowClear
                    onChange={onChangeCallback}
                    placeholder="Assessment Type: All"
                    className="filter-select"
                    value={value}
                  />
                ),
              },
            ],
          }}
          columns={columns}
          onClick={() => setVisible(true)}
          headerTitle={t("assessment-list")}
          btnText={t("add-assessment")}
          dataSource={mappedColumnsData}
          loading={isLoading}
          totalItems={columnsData?.total}
          rowKey={(record) =>
            record.hasChildren ? record.id : `${record.id}_${record.name}`
          }
        />
      </>
      <SelectAssessmentTypes visible={visible} setVisible={setVisible} />
      <AddLanguage
        visible={languageVisible}
        setVisible={setLanguageVisible}
        data={assessmentData}
      />
    </DashboardLayout>
  );
};

export default AssessmentsList;
