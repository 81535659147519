import { Button, Col, Row, Space, Tag } from "antd";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import CustomCard from "components/custom-card/CustomCard";
import { CustomCheckBoxGroup } from "components/form/custom-checkbox-group/CustomCheckBoxGroup";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { CustomTextArea } from "components/form/custom-text-area/CustomTextArea";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { CustomUploadFile } from "components/form/custom-upload-file/CustomUploadFile";
import ContainerHeading from "features/assessment/questions/ContainerHeading";
import styles from "features/company/css/EditCompany.module.scss";
import { useEditCompanyViewModel } from "features/company/hooks/useEditCompanyViewModel";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import UsersList from "features/users/UsersList";
import InviteUsers from "features/users/invite-users/InviteUsers";
import { Form, Formik } from "formik";
import { PERMS } from "helpers";
import { useLoggedInUser } from "hooks/useLoggedInUser";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { editCompanySchema } from "validations/schema/inviteCompanySchema";

const EditCompany = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isClient } = useLoggedInUser();
  const {
    initialValues,
    onSubmit,
    id,
    isLoading,
    setInviteVisible,
    visible,
  } = useEditCompanyViewModel();

  return (
    <DashboardLayout openKey={["Users"]}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={editCompanySchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, errors }) => {
          return (
            <Form>
              <div className={styles["add-content-header"]}>
                <h1 className={styles["title"]}>
                  {id ? t("edit-company") : t("create-new-company")}
                </h1>
                <Space>
                  <CheckPermissions permission={PERMS.INVITE_EMPLOYEE}>
                    <div className={styles["btn-div"]}>
                      <Button
                        className="light-btn"
                        htmlType="reset"
                        onClick={() => navigate("/company")}
                      >
                        {t("cancel")}
                      </Button>
                      <Button
                        loading={isLoading}
                        htmlType="submit"
                        className="primary-btn"
                      >
                        {t("save")}
                      </Button>
                    </div>
                  </CheckPermissions>
                </Space>
              </div>
              <div className={styles["content-card"]}>
                <CustomCard bordered={false} className="content-card">
                  <div style={{ display: "flex", gap: "30px" }}>
                    <div>
                      <CustomUploadFile
                        imgUrl={values.image}
                        name="image"
                        setFieldValue={setFieldValue}
                        accept="image/*"
                        uploadText={t("upload-image")}
                      />
                    </div>
                  </div>
                  <Row gutter={[24, 0]}>
                    <Col sm={24} md={12}>
                      <CustomTextField
                        disabled={isClient}
                        label={t("company-name")}
                        name="companyName"
                        placeholder={t("company-name")}
                      />
                    </Col>
                    <Col sm={24} md={12}>
                      <CustomTextField
                        disabled
                        label={t("company-user-email")}
                        name="adminEmail"
                        placeholder={t("company-user-email")}
                      />
                    </Col>
                    <Col sm={24} md={12}>
                      <CustomTextField
                        disabled
                        type="number"
                        label={t("total-invited-employees")}
                        name="subscribers"
                        placeholder={t("total-invited-employees")}
                      />
                    </Col>
                    <Col sm={24} md={12}>
                      <CustomTextField
                        type="number"
                        disabled={isClient}
                        label={t("number-of-subscriptions")}
                        name="subscriptions"
                        placeholder={t("enter-number-of-subscriptions")}
                      />
                    </Col>
                    <Col sm={24} md={12}>
                      <CustomTextField
                        disabled
                        label={t("total-employees")}
                        name="employees"
                        placeholder={t("total-employees")}
                      />
                    </Col>
                    <Col sm={24} md={12}>
                      <CustomTextField
                        disabled
                        type="number"
                        label={t("total-registered-active-employees")}
                        name="totalActiveEmployees"
                      />
                    </Col>
                    <Col sm={24} md={12}>
                      <CustomTextField
                        disabled
                        label={t("registered-date")}
                        name="createdAt"
                        placeholder={t("enter-registered-date")}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[24, 0]}>
                    <Col sm={24} md={12}>
                      <div className="display-flex">
                        <CustomCheckBoxGroup
                          name="isAccessCodeEnabled"
                          disabled={!values.isProfileComplete || isClient}
                          options={[]}
                        >
                          {t("activate-access-code")}
                        </CustomCheckBoxGroup>
                        <ContainerHeading
                          text={""}
                          infoText={t("access-code-info-text")}
                          className={"input-field-heading"}
                        />
                      </div>

                      <CustomTextField
                        disabled={
                          !values.isAccessCodeEnabled ||
                          !values.isProfileComplete ||
                          isClient
                        }
                        label={t("access-code")}
                        name="accessCode"
                        placeholder={t("type-unique-code")}
                      />
                    </Col>
                  </Row>
                  {/* <Row gutter={[24, 0]}>
                    <Col sm={24} md={12}>
                      <CustomTextField
                        label="Employees Count"
                        name="numberOfEmployee"
                        placeholder="Enter Employees Count"
                      />
                    </Col>
                  </Row> */}
                  {/* <div className={styles["form-control"]}>
                    <Space>
                      <Switch
                        id="child"
                        className="custom-switch"
                        onChange={(values) => handleChange(values)}
                      />
                      <label className={styles["child"]} htmlFor="child">
                        Is a child Company?
                      </label>
                    </Space>
                  </div> 
                  <div className={styles["form-control"]}>
                    {child && (
                      <CustomTreeSelect
                        allowClear
                        showArrow
                        treeNodeFilterProp="companyName"
                        treeLine={true && { showLeafIcon: false }}
                        label="Parent Company"
                        className="form-select"
                        name="parent"
                        treeData={companiesData?.getClientsTree}
                        placeholder="Select Parent Company"
                        fieldNames={{ label: "companyName", value: "id" }}
                      />
                    )}
                  </div>*/}
                  {values.parent && (
                    <>
                      <div className={styles["form-control"]}>
                        <label className="label">Parent</label>
                        <Tag
                          className={styles["tag"]}
                          onClick={() => {
                            navigate("/edit-company", {
                              state: values.parent?.id,
                            });
                          }}
                        >
                          {values.parent.companyName}
                        </Tag>
                      </div>
                    </>
                  )}
                  {values.children.length > 0 && (
                    <>
                      <div className={styles["form-control"]}>
                        <label className="label">{t("children")}</label>
                        {values.children.map((item: any) => (
                          <Tag
                            className={styles["tag"]}
                            onClick={() => {
                              navigate("/edit-company", {
                                state: item.id,
                              });
                            }}
                          >
                            {item.companyName}
                          </Tag>
                        ))}
                      </div>
                    </>
                  )}
                  {!isClient && (
                    <div className={styles["form-control"]}>
                      <CustomCheckBoxGroup
                        name="isActive"
                        label="Status"
                        options={[{ label: t("active"), value: true }]}
                      >
                        {t("active")}
                      </CustomCheckBoxGroup>
                    </div>
                  )}
                  {!isClient && (
                    <div className={styles["form-control"]}>
                      <CustomCheckBoxGroup
                        name="isEapEnabled"
                        label="EAP"
                        options={[{ label: t("active"), value: true }]}
                      >
                        EAP
                      </CustomCheckBoxGroup>
                    </div>
                  )}
                </CustomCard>
              </div>
              {id && (
                <UsersList
                  companyChildren={initialValues?.children}
                  employerId={id}
                  isProfileComplete={values.isProfileComplete}
                  setInviteVisible={setInviteVisible}
                />
              )}
              <InviteUsers
                visible={visible}
                setVisible={setInviteVisible}
                employerId={id}
              />
            </Form>
          );
        }}
      </Formik>
    </DashboardLayout>
  );
};
export default EditCompany;
