import { List, Space } from "antd";
import * as React from "react";
import styles from "features/rewards/css/CompanyStats.module.scss";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import { useTranslation } from "react-i18next";
import { GoldMedalSvg } from "assets/icons/GoldMedalSvg";
import { SilverMedalSvg } from "assets/icons/SilverMedalSvg";
import { BronzeMedalSvg } from "assets/icons/BronzeMedalSvg";
interface CompanyItemProps {
  id: number;
  title?: string;
  image?: string;
  users?: number;
}

const CompanyItem: React.FunctionComponent<CompanyItemProps> = ({
  id,
  title,
  image,
  users,
}) => {
  const { t } = useTranslation();
  return (
    <List.Item style={{ height: "66px" }}>
      <div className={styles["item-grid"]}>
        <div className={styles["item-title"]}>
          <Space size={14}>
            <div className={styles["item-id"]}>{id}</div>
            <CustomAvatar imgSrc={image} size={32} shape="circle" />
            <div className={styles["sub-title"]}>{title}</div>
          </Space>
        </div>
        <div className={styles["users"]}>
          <div className={styles["count"]}>{users}</div>
          <div className={styles["count"]}>{t("users")}</div>
        </div>
      </div>
    </List.Item>
  );
};

export default CompanyItem;
