import { FieldArray, Form, Formik } from "formik";
import * as React from "react";
import { Divider, Rate } from "antd";
import { StarOutlined } from "@ant-design/icons";
import styles from "./RatingOptions.module.scss";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import DeleteBtn from "components/custom-button/DeleteBtn";
import AddOption from "components/custom-button/AddOption";
import { InitialValueTypesOfAssessment } from "features/playground/types";
import ICoreFields from "features/assessment/types/ICoreFields";
import { useTranslation } from "react-i18next";

interface RatingOptionsProps extends ICoreFields {
  values: any;
  tags: any;
  namePrefix?: string;
  addRating: (key: string) => void;
  deleteRating: (key: string, optionId: string) => void;
  setFieldValue: (key: string, value: any) => void;
  isDisabled?: boolean;
  isSubQuestion?: boolean;
  isAddLanguage?: boolean;
  assessmentSettings?: any;
}

const RatingOptions: React.FunctionComponent<RatingOptionsProps> = (props) => {
  const { t } = useTranslation();
  const { values } = props;
  const namePrefix = `${props?.namePrefix ? props?.namePrefix + "." : ""}`;

  const updateRate = (e: any, id: string) => {
    let ratings = values.ratings;
    let indexToUpdate = ratings.findIndex((item: any) => item.id === id);
    ratings[indexToUpdate].text = e;
    props.setFieldValue(`${namePrefix}ratings`, ratings);
  };

  return (
    <FieldArray
      name="ratings"
      render={() => (
        <>
          {values?.ratings
            ?.sort((a: any, b: any) => a?.ratingNumber - b?.ratingNumber)
            ?.map((rating: any, index: number) => (
              <div key={rating.id} className={styles["rating-header"]}>
                <div className={styles["rating-num"]}>
                  {rating.ratingNumber}
                </div>
                <Rate
                  count={1}
                  value={rating.text}
                  character={<StarOutlined />}
                  onChange={(e) => {}}
                  disabled={props?.isDisabled || props?.isAddLanguage}
                />

                {props?.assessmentSettings?.isScoresEnable ? (
                  <CustomTextField
                    name={`${namePrefix}ratings[${index}].score`}
                    placeholder={t("score")}
                    disabled={props?.isDisabled || props?.isAddLanguage}
                    type="number"
                  />
                ) : null}

                {props?.assessmentSettings?.isTagsEnable ? (
                  <CustomSelectField
                    maxTagCount="responsive"
                    name={`${namePrefix}ratings[${index}].tags`}
                    mode="multiple"
                    options={props?.tags?.data}
                    disabled={
                      props?.tags?.isLoading ||
                      props?.isDisabled ||
                      props?.isAddLanguage
                    }
                    placeholder={t("select-tags")}
                  />
                ) : null}
                {/* <DeleteBtn
              disabled={props?.isDisabled}
              onClick={() => props?.deleteRating("ratings", rating.id)}
            /> */}
              </div>
            ))}
        </>
      )}
    />
  );
};

export default RatingOptions;
