import { useNavigate } from "react-router-dom";
import { parseJwt } from "helpers";

export const useNavigationToDashboard = () => {
  const navigate = useNavigate();

  const navigateToSignInUserDashboard = (token: string) => {
    if (!token) {
      navigate("/");
      return;
    }

    const userRole = parseJwt(token).userType;
    switch (userRole) {
      case "SuperAdmin":
      case "VIWELLAdmin":
        navigate("/company");
        break;
      case "ClientAdmin":
        navigate("/company-dashboard");
        break;
      case "Expert":
        navigate("/expert-dashboard");
        break;
      default:
        navigate("/");
        break;
    }
  };

  return { navigateToSignInUserDashboard };
};
