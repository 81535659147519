import { Form, Select } from "antd";
import CustomTable from "components/custom-table/CustomTable";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import CustomTreeSelect from "components/form/custom-tree-select/CustomTreeSelect";
import { Formik } from "formik";
import {
  useGetClientsTreeQuery,
  useGetEmployeeListQuery,
  useLazyGetDeletedUserListQuery,
} from "graphql/_generated/graphql";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLoggedInUser } from "hooks/useLoggedInUser";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import styles from "features/users/css/UsersList.module.scss";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

const { Option } = Select;
type Props = { employerId?: string; companyChildren?: Array<any> };

const DeletedAccounts = ({ employerId, companyChildren }: Props) => {
  const { t } = useTranslation();
  const [filtersData, setFiltersData] = useState<any>(null);
  const { isFetching, refetch } = useGetEmployeeListQuery();
  const [columnsData, setColumnsData] = useState<any>();
  const [getDeletedUserListFun] = useLazyGetDeletedUserListQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const { data } = useGetClientsTreeQuery();
  const [companyId, setCompanyId] = useState<string | undefined>(undefined);
  const { isSuperAdmin } = useLoggedInUser();
  const { handleError } = useErrorHandler();
  const region = useSelector((state: RootState) => state.auth.region);
  // const { handleResendInvite } = useUsers();

  useEffect(() => {
    fetchData();
  }, [isFetching, region]);

  const fetchData = () => {
    fetchDataFromApi(getDeletedUserListFun, setColumnsData, filtersData).catch(
      (e) => handleError(e),
    );
  };

  const columns = [
    {
      title: t("id"),
      key: "id",
      sorter: {
        multiple: 1,
      },
      render: (_text: any, record: any, index: number) => {
        return (currentPage - 1) * pageSize + index + 1;
      },
    },
    // {
    //   title: t("name"),
    //   dataIndex: "firstName",
    //   key: "firstName",
    //   sorter: {
    //     multiple: 1,
    //   },
    //   render: (_text: any, record: any, index: number) => (
    //     <Space>
    //       <CustomAvatar
    //         imgSrc={record.image ? record.image : record?.avatar?.imgPath}
    //       />
    //       <div className="cut-text">
    //         <Space>
    //           {record.firstName}
    //           {record.lastName}
    //         </Space>
    //       </div>
    //     </Space>
    //   ),
    // },
    {
      title: t("email"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("company"),
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: t("request-date"),
      dataIndex: "requestDate",
      key: "requestDate",
      sorter: {
        multiple: 1,
      },
      render: (requestDate: string) =>
        moment(requestDate).format("MMMM Do YYYY, h:mm:ss a"),
    },
    {
      title: t("deletion-date"),
      dataIndex: "deletionDate",
      key: "deletionDate",
      sorter: {
        multiple: 1,
      },
      render: (deletionDate: string) =>
        moment(deletionDate).format("MMMM Do YYYY, h:mm:ss a"),
    },
    {
      title: t("reason"),
      dataIndex: "deletionReason",
      key: "deletionReason",
    },
  ];

  return (
    <>
      <DashboardLayout openKey={["Users"]}>
        <div className={styles["users"]}>
          <Breadcrumbs
            data={[
              {
                label: "Users",
              },
              {
                label: t("deleted-accounts"),
              },
            ]}
          />
          <CustomTable
            rowKey="id"
            filters={{
              handleCallback: async (filtersData: any) => {
                await fetchDataFromApi(
                  getDeletedUserListFun,
                  setColumnsData,
                  filtersData,
                );
                setPageSize(filtersData?.pagination?.offset?.pageSize);
                setCurrentPage(filtersData?.pagination?.offset?.page);
                setFiltersData(filtersData);
              },
              date: [
                {
                  applyAs: "and",
                  key: "deletionDate",
                  comparison: "Range",
                  placeholder: "Deletion",
                },
                {
                  applyAs: "and",
                  key: "requestDate",
                  comparison: "Range",
                  placeholder: "Request",
                },
              ],
              manualFilters: isSuperAdmin
                ? [
                    {
                      key: "companyId",
                      value: companyId,
                      comparison: "Equal",
                      applyAs: "and",
                      render: (onChangeCallback, value) => {
                        return (
                          <Formik
                            initialValues={{ company: null }}
                            onSubmit={() => {}}
                          >
                            {({ setFieldValue, values }) => (
                              <Form>
                                <CustomTreeSelect
                                  noMargin
                                  allowClear
                                  showArrow
                                  treeNodeFilterProp="companyName"
                                  treeLine={true && { showLeafIcon: false }}
                                  className="filter-select"
                                  name="company"
                                  treeData={
                                    companyChildren
                                      ? companyChildren
                                      : data?.getClientsTree
                                  }
                                  placeholder="Companies: All"
                                  fieldNames={{
                                    label: "companyName",
                                    value: "id",
                                  }}
                                  onChange={(e) => {
                                    onChangeCallback(e);
                                  }}
                                  onClear={() => setCompanyId(undefined)}
                                  value={value}
                                />
                              </Form>
                            )}
                          </Formik>
                        );
                      },
                    },
                  ]
                : undefined,
            }}
            totalItems={columnsData?.metadata?.total}
            loading={isLoading}
            columns={columns}
            headerTitle={t("deleted-accounts")}
            dataSource={columnsData?.data}
          />
        </div>
      </DashboardLayout>
    </>
  );
};

export default DeletedAccounts;
