import { TabsProps } from "antd";
import alertSvg from "assets/images/alert-circle.svg";
import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import CustomTabs from "components/custom-tabs/CustomTabs";
import styles from "features/users/css/InviteMultipleUsers.module.scss";
import { useBulkUsersPreviewViewModel } from "features/users/hooks/useBulkUsersPreviewViewModel";
import { EmployeeBulkCreate } from "graphql/_generated/graphql";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DetectedErrorsModal from "./DetectedErrorsModal";
import PreviewDetails from "./PreviewDetails";

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  users: {
    title: string;
    employees: EmployeeBulkCreate[];
    positions: EmployeeBulkCreate[];
    departments: EmployeeBulkCreate[];
    buildings: EmployeeBulkCreate[];
    cities: EmployeeBulkCreate[];
    countries: EmployeeBulkCreate[];
    addresses: EmployeeBulkCreate[];
  };
  errors: any;
  values: any;
  refetch: () => void;
};

export default function BulkUsersPreview({
  visible,
  setVisible,
  users,
  errors,
  values,
  refetch,
}: Props) {
  const { t } = useTranslation();
  const [errorsVisible, setErrorsVisible] = useState<boolean>(false);

  const handleCancel = () => {
    setVisible(false);
  };

  const { handleBulkInvite, isLoading } = useBulkUsersPreviewViewModel();

  const tabs: TabsProps["items"] = [
    {
      label: t("employees"),
      children: (
        <PreviewDetails
          items={users.employees}
          previewKeys={{
            all: true,
          }}
        />
      ),
      key: "employees",
    },
    {
      label: t("positions"),
      children: (
        <PreviewDetails
          items={users.positions}
          previewKeys={{
            position: true,
            department: true,
          }}
        />
      ),
      key: "positions",
    },
    {
      label: t("departments"),
      children: (
        <PreviewDetails
          items={users.departments}
          previewKeys={{
            department: true,
          }}
        />
      ),
      key: "departments",
    },
    {
      label: t("buildings"),
      children: (
        <PreviewDetails
          items={users.buildings}
          previewKeys={{
            building: true,
            address: true,
            city: true,
            country: true,
          }}
        />
      ),
      key: "buildings",
    },
    {
      label: t("cities"),
      children: (
        <PreviewDetails
          items={users.cities}
          previewKeys={{
            city: true,
          }}
        />
      ),
      key: "cities",
    },
    {
      label: t("countries"),
      children: (
        <PreviewDetails
          items={users.countries}
          previewKeys={{
            country: true,
          }}
        />
      ),
      key: "countries",
    },
    {
      label: t("addresses"),
      children: (
        <PreviewDetails
          items={users.addresses}
          previewKeys={{
            address: true,
          }}
        />
      ),
      key: "addresses",
    },
  ];

  const getErrorsCount = () =>
    errors?.reduce((acc: number, curr: any) => {
      if (curr && Object.keys(curr)) {
        return acc + Object.keys(curr)?.length;
      }
      return acc;
    }, 0);

  const haveErrors = getErrorsCount();

  return (
    <CustomModal
      width={1000}
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      title={users?.title}
    >
      <CustomTabs items={tabs} />
      <div
        className={`${styles["errors-container"]} cursor-pointer`}
        onClick={() => setErrorsVisible(true)}
      >
        {haveErrors ? (
          <>
            <img src={alertSvg} />
            <div className={styles["error-text"]}>{`${haveErrors} ${t(
              "errors-found",
            )}`}</div>
          </>
        ) : null}
      </div>

      {errors && errorsVisible ? (
        <DetectedErrorsModal
          visible={errorsVisible}
          setVisible={() => setErrorsVisible(!errorsVisible)}
          errors={errors}
        />
      ) : null}
      <ModalFooter
        saveText={t("confirm-and-save")}
        loading={isLoading}
        onSubmit={() => !haveErrors && handleBulkInvite(values, refetch)}
        onCancel={() => handleCancel()}
      />
    </CustomModal>
  );
}
