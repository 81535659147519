import { useAddEAP } from "./hooks/useAddEAP";
import { useToggleActivationEAP } from "./hooks/useToggleEAP";
import { useUpdateEAP } from "./hooks/useUpdateEAP";

export const useEAP = () => {
  const { handleAddNewEAP, isLoading } = useAddEAP();

  const { handleUpdateEAP, isLoading: isLoadingUpdateEAP } = useUpdateEAP();
  const { handleActivationEAP, isLoading: isToggling } =
    useToggleActivationEAP();

  return {
    isLoading: isLoading || isLoadingUpdateEAP || isToggling,
    handleAddNewEAP,
    handleUpdateEAP,
    handleActivationEAP,
  };
};
