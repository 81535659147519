import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { CustomUploadFile } from "components/form/custom-upload-file/CustomUploadFile";
import TranslationFieldArr from "components/translation-field-array/TranslationFieldArr";
import { Form, Formik, FormikHelpers } from "formik";
import { CategoryCreate } from "graphql/_generated/graphql";
import { useTranslation } from "react-i18next";
import { categorySchema } from "validations/schema/categorySchema";

type Props = {
  visible: boolean;
  isLoading: boolean;
  editId?: string | null;
  handleCancel: () => void;
  initialValues: CategoryCreate;
  pillarsData:
    | {
        label: string;
        value: string;
        icon: any;
      }[]
    | undefined;
  onSubmit: (
    values: CategoryCreate,
    { resetForm }: FormikHelpers<CategoryCreate>,
  ) => void;
};

const AddCategory = ({
  visible,
  editId,
  handleCancel,
  initialValues,
  isLoading,
  pillarsData,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();

  return (
    <CustomModal
      width={600}
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      title={t(editId ? "edit-category" : "add-category")}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={categorySchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, errors }) => (
          <Form>
            <CustomUploadFile
              name="icon"
              setFieldValue={setFieldValue}
              imgUrl={values.icon}
              accept="image/*"
            />
            <CustomSelectField
              label={t("select-pillar")}
              className="form-select"
              name="pillarId"
              placeholder={t("select-pillar")}
              options={pillarsData}
            />
            <CustomTextField
              label={t("category-name")}
              name="name"
              placeholder={t("category-name-placeholder")}
            />

            <TranslationFieldArr values={values} attribute="name" />
            <ModalFooter
              saveText={t("save-category")}
              onCancel={handleCancel}
              loading={isLoading}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddCategory;
