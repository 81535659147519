import Icon, { DeleteFilled } from "@ant-design/icons";
import { PointsIcon } from "assets/icons/PointsSvg";
import AddOption from "components/custom-button/AddOption";
import DeleteBtn from "components/custom-button/DeleteBtn";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import ContainerHeading from "features/assessment/questions/ContainerHeading/ContainerHeading";
import CollapseAble from "features/assessment/questions/collapseables/CollapseAble";
import styles from "features/rewards/css/AddStreak.module.scss";
import { FieldArray, Form, Formik, FormikProps } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { streakSchema } from "validations/schema";
import { useStreaksViewModal } from "../hooks/useStreaksViewModal";
import { RefObject, useEffect } from "react";
import { StreakCreate } from "graphql/_generated/graphql";
import CustomButton from "components/custom-button/CustomButton";

interface AddStreakProps {
  formRef: RefObject<FormikProps<StreakCreate>> | null;
  onLoadingChange: (loading: boolean) => void;
}

const AddStreak: React.FunctionComponent<AddStreakProps> = ({
  formRef,
  onLoadingChange,
}) => {
  const { t } = useTranslation();

  const { onSetStreakSubmit, streaksData, isLoading } = useStreaksViewModal();

  const initialValues = {
    streaks: [
      {
        points: null as unknown as number,
      },
    ],
  };

  useEffect(() => {
    onLoadingChange(isLoading);
  }, [isLoading]);

  return (
    <Formik
      enableReinitialize
      validationSchema={streakSchema}
      initialValues={
        streaksData && streaksData.length
          ? { streaks: streaksData }
          : initialValues
      }
      onSubmit={onSetStreakSubmit}
      innerRef={formRef}
    >
      {({ values }) => {
        return (
          <Form>
            <CollapseAble
              defaultActiveKey={["streak"]}
              headerKey="streak"
              hideExtra
              expandIconPosition="end"
              header={<ContainerHeading text={t("streak")} />}
            >
              <div className={styles["streak-content"]}>
                <FieldArray
                  name="streaks"
                  render={({ push, remove }) => (
                    <>
                      {values.streaks && values.streaks.length
                        ? values.streaks.map((item, index) => (
                            <div className={styles["streak-item"]} key={index}>
                              <CustomTextField
                                name={`streaks.${index}.points`}
                                label={t("day-reward", { index: index + 1 })}
                                placeholder={t("enter-points")}
                                suffix={<Icon component={PointsIcon} />}
                                type="number"
                              />
                              {index > 0 && (
                                <div className={styles["delete-btn"]}>
                                  <DeleteBtn onClick={() => remove(index)}>
                                    <DeleteFilled />
                                  </DeleteBtn>
                                </div>
                              )}
                            </div>
                          ))
                        : null}
                      <AddOption
                        title={t("add-streak-day")}
                        onClick={() => push({ points: "" })}
                        disabled={values.streaks.length >= 7}
                      />
                    </>
                  )}
                />
              </div>
            </CollapseAble>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddStreak;
