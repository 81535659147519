import { Space } from "antd";
import { PlusCircleFilled } from "@ant-design/icons";
import CustomButton from "components/custom-button/CustomButton";

import "./AddElement.module.scss";

interface AddElementProps {
  id?: string;
  title?: string;
  handleAdd: any;
}

/* ADD QUESTION */
const AddElement: React.FC<AddElementProps> = (props) => {
  return (
    <Space className={"addElement"}>
      <CustomButton
        onClick={() => props.handleAdd(props?.id)}
        icon={<PlusCircleFilled />}
        className="primary-btn"
      >
        {props.title ?? "Add Question"}
      </CustomButton>
    </Space>
  );
};
export default AddElement;
