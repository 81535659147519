import React, { ReactElement, useEffect, useState } from "react";
import { List, Space, Pagination, Select, DatePicker } from "antd";
import Search from "antd/lib/input/Search";
import useFilters from "hooks/useFilters";
import usePagination from "hooks/usePagination";
import useSearch from "hooks/useSearch";
import useSorting from "hooks/useSorting";
import styles from "./CustomCardList.module.scss";
import { removeUndefinedValuesFromObject } from "helpers";
import type {
  AdvanceSearchType,
  DateRange,
  FilterValue,
  Search as SearchType,
} from "../custom-table/types";
import { searchFilters } from "components/custom-table/utils";
import moment from "moment";
import CustomTableHeader from "components/custom-table/CustomTableHeader";

const { Option } = Select;
const { RangePicker } = DatePicker;

export const DividerDot: React.FC = () => (
  <span className={styles["divider-dot"]}></span>
);

interface CardListProps {
  page?: boolean;
  data: Array<any>;
  totalItems?: number;
  loading?: boolean;
  renderCard: (item: any) => ReactElement;
  filters?: {
    handleCallback: any;
    manualFilter?: AdvanceSearchType & { value: any };
    date?: Array<AdvanceSearchType>;
    status?: AdvanceSearchType;
    manualFilters?: Array<
      AdvanceSearchType & {
        value?: any;
        render: (cb: any, value?: any) => React.ReactElement;
      }
    >;
  };
  showSearch?: SearchType;
  permission?: string;
  onClick?: any;
  headerTitle?: string;
  btnText?: string;
}

const CustomCardList: React.FC<CardListProps> = (props) => {
  const { pagination, handlePagination } = usePagination("");
  const { search, handleSearch } = useSearch();
  const {
    advanceFilters,
    handleFilter,
    handleRangeFilter,
    handleManualFilter,
  } = useFilters(props.filters);
  const { sorting, handleSorting } = useSorting();

  useEffect(() => {
    let filters = { ...search, ...advanceFilters };
    removeUndefinedValuesFromObject(filters);

    //if object is empty replace the object with undefined
    if (Object.keys(filters).length === 0) {
      filters = undefined;
    }

    props.filters?.handleCallback({
      filters,
      pagination,
      sorting,
    });
  }, [pagination, search, advanceFilters, sorting]);

  return (
    <>
      <div className="header">
        <CustomTableHeader
          handleSearchInput={handleSearch}
          permission={props?.permission || ""}
          title={props?.headerTitle || ""}
          onClick={props?.onClick}
          btnText={props?.btnText || ""}
          showSearch={props?.showSearch || true}
          defaultValue={search?.search?.text}
        />
        <Space>
          {props.filters?.manualFilters?.map((filter) => {
            const { render, key } = filter;
            const filterValue = searchFilters(advanceFilters, key)?.value;

            return render(handleManualFilter(filter), filterValue);
          })}
          {/* Status Dropdown filter */}
          {props.filters?.status && (
            <Select
              allowClear
              placeholder="Status: All"
              className="filter-select"
              onChange={handleFilter}
              onClear={() => {}}
              value={
                searchFilters(advanceFilters, props.filters?.status?.key)?.value
              }
            >
              <Option value={true}>Active</Option>
              <Option value={"false"}>Deactivated</Option>
            </Select>
          )}

          {/* Date Range Filter */}
          {props.filters?.date?.map((item, index) => {
            const values: FilterValue = searchFilters(
              advanceFilters,
              props.filters?.date?.[index]?.key,
            )?.value;

            const range: DateRange =
              typeof values === "object"
                ? [moment(values?.start), moment(values?.end)]
                : [null, null];

            return (
              <RangePicker
                className="filter-date-picker"
                onChange={(dates, dateStrings) =>
                  handleRangeFilter(dates, dateStrings, item)
                }
                defaultValue={range}
                placeholder={
                  item.placeholder
                    ? [
                        `${item.placeholder} Start Date`,
                        `${item.placeholder} End Date`,
                      ]
                    : undefined
                }
              />
            );
          })}
        </Space>
      </div>
      <List
        loading={props.loading}
        className="card-list  mb"
        grid={{
          gutter: 24,
          xs: 1,
          sm: 2,
          md: 3,
          lg: 3,
          xl: 3,
          xxl: 4,
        }}
        dataSource={props.data}
        renderItem={(item: any) => (
          <List.Item>{props.renderCard(item)}</List.Item>
        )}
      />
      <Pagination
        className="custom-table"
        onChange={(page, pageSize) =>
          handlePagination({
            current: page,
            pageSize,
          })
        }
        current={pagination?.offset?.page}
        pageSize={pagination?.offset?.pageSize}
        pageSizeOptions={[10, 20, 30, 50, 100]}
        showSizeChanger
        defaultCurrent={1}
        total={props.totalItems}
      />
    </>
  );
};

export default CustomCardList;
