import React from "react";
import styles from "../../CompanyDashboard.module.scss";

import { renderHtmlContent } from "helpers";
import {
  Bar,
  Cell,
  BarChart as ReChartBarGraph,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { BarChartProps } from "../types";

export const BarChart: React.FC<BarChartProps> = ({ barChartConfig }) => {
  const CustomXAxisTick = (props: any) => {
    return (
      <foreignObject
        x={props.x - barChartConfig.xData.customXTick}
        y={props.y - 5}
        width={barChartConfig.xData.width}
        height={30}
      >
        {barChartConfig.xData.isEmojiBar ? (
          <div style={{ fontSize: 20 }}>
            {renderHtmlContent(`&#x${props.payload.value};`)}
          </div>
        ) : (
          <div className={styles["barchart-label"]}>{props.payload.value}</div>
        )}
      </foreignObject>
    );
  };

  const CustomYAxisTick = (tickObject: any) => {
    const {
      payload: { value },
    } = tickObject;
    tickObject["className"] = styles["y-axis-value"];
    return <Text {...tickObject}>{`${value}%`}</Text>;
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className={styles["tooltip-container"]}>
          {barChartConfig.xData.isEmojiBar ? (
            <div style={{ fontSize: 20 }}>
              {renderHtmlContent(`&#x${label};`)}
            </div>
          ) : (
            <div className={styles["barchart-label"]}>{label}</div>
          )}
          <p className={styles["tooltip-value"]}>{`${payload[0].value} %`}</p>
        </div>
      );
    }

    return null;
  };

  const CustomBarBG = (props: any) => {
    return (
      <foreignObject
        x={props.x + barChartConfig.customBarBG.x}
        y={props.y}
        width={5}
        height={barChartConfig.customBarBG.height}
      >
        <div className={styles["barchart-border-left"]} />
      </foreignObject>
    );
  };

  return (
    <ResponsiveContainer>
      <ReChartBarGraph data={barChartConfig.data}>
        <Tooltip cursor={false} content={<CustomTooltip />} />
        <XAxis
          dataKey={barChartConfig.xData.key}
          tick={<CustomXAxisTick />}
          tickLine={false}
          // scale="band"
          interval={0}
          stroke="#808080"
        />
        <YAxis
          tickLine={false}
          unit={"%"}
          domain={[0, 100]}
          stroke="#808080"
          tick={(tickObject) => CustomYAxisTick(tickObject)}
        />
        <YAxis
          yAxisId="id-1"
          stroke="#808080"
          orientation="right"
          tickLine={false}
        />
        <Bar
          dataKey={barChartConfig.yData.key}
          barSize={barChartConfig.barSize}
          fill="#5555A4"
          radius={[2, 2, 0, 0]}
          background={CustomBarBG}
        >
          {barChartConfig.isBarHasDifferentColors && barChartConfig.data?.length
            ? barChartConfig.data.map((entry: any, index: number) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))
            : null}
        </Bar>
      </ReChartBarGraph>
    </ResponsiveContainer>
  );
};
