import CustomButton from "components/custom-button/CustomButton";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import React, { useState } from "react";
import AddAdmin from "features/admin/viwell/add/AddAdmin";
import AdminList from "features/admin/viwell/list/AdminList";
import styles from "features/admin/viwell/admin/Admins.module.scss";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
interface AdminsProps {}

const Admins: React.FunctionComponent<AdminsProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <DashboardLayout openKey={["Users"]}>
        <Breadcrumbs
          data={[
            {
              label: "Users",
            },
            {
              label: t("admins"),
            },
          ]}
        />
        <AdminList />
      </DashboardLayout>
    </>
  );
};

export default Admins;
