import {  useToggleCompanyRewardPointsMutation } from "graphql/_generated/graphql";

export const useToggleActivationRewards = () => {
  const [ActivationDeactivationFun, { isLoading }] =
    useToggleCompanyRewardPointsMutation();

  //this function is responsible for Activation/Deactivation company reward
  const handleActivationException = async (values: any) => {
    try {
      const res = await ActivationDeactivationFun(values).unwrap();
      return res.toggleCompanyRewardPoints;
    } catch (error) {
      throw error;
    }
  };
  return {
    isLoading,
    handleActivationException,
  };
};
