import {
  RemoveTeamMembersMutationVariables,
  useRemoveTeamMembersMutation,
} from "graphql/_generated/graphql";

export const useRemoveTeamMember = () => {
  const [removeTeamMemberFun, { isLoading: isLoadingRemoveTeamMember }] =
    useRemoveTeamMembersMutation();

  const handleRemoveTeamMember = async (
    values: RemoveTeamMembersMutationVariables,
  ) => {
    try {
      const res = await removeTeamMemberFun(values).unwrap();
    } catch (error) {
      throw error;
    }
  };
  return {
    handleRemoveTeamMember,
    isLoadingRemoveTeamMember,
  };
};
