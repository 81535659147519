import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { customNotification } from "components/custom-notification/CustomNotification";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { errorFormat } from "helpers";
import { Formik, Form } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useChangePassword } from "features/auth/hooks/useChangePassword";
import { changePasswordSchema } from "validations/schema/changePasswordSchema";
import CustomPassword from "components/form/custom-text-field/CustomPassword";
import { useErrorHandler } from "hooks/useErrorHandler";

interface ChangePasswordProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChangePassword: React.FunctionComponent<ChangePasswordProps> = ({
  setVisible,
  visible,
}) => {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const { handleChangePassword, isLoading } = useChangePassword();

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <CustomModal
      width={600}
      footer={false}
      title={t("change-password")}
      visible={visible}
      onCancel={handleCancel}
    >
      <Formik
        initialValues={{
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        }}
        validationSchema={changePasswordSchema}
        onSubmit={async (values, { resetForm }) => {
          const { confirmPassword, ...rest } = values;
          try {
            await handleChangePassword({
              body: rest,
            });
            resetForm();
            setVisible(false);
            customNotification("success", t("password-changed-success"));
          } catch (error) {
            handleError(error as string | object);
          }
        }}
      >
        <Form>
          <CustomTextField
            type="password"
            name="currentPassword"
            label={t("current-password")}
            placeholder={t("current-password")}
          />
          <CustomPassword
            type="password"
            name="newPassword"
            label={t("new-password")}
            placeholder={t("new-password")}
            hideError
            showRules
          />
          <CustomTextField
            type="password"
            name="confirmPassword"
            label={t("confirm-password")}
            placeholder={t("confirm-password")}
          />
          <ModalFooter
            saveText={t("change-password")}
            onCancel={handleCancel}
            loading={isLoading}
          />
        </Form>
      </Formik>
    </CustomModal>
  );
};

export default ChangePassword;
