import { Spin } from "antd";
import { ArrowLeftIcon } from "assets/icons";
import arrowDownSvg from "assets/images/arrowhead-down.svg";
import arrowUpSvg from "assets/images/arrowhead-up.svg";
import CustomCard from "components/custom-card/CustomCard";
import { LineChart } from "features/company-dashboard/charts/line/LineChart";
import { usePillarInteractionsHistory } from "features/company-dashboard/hooks/usePillarInteractionsHistory";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "../../CompanyDashboard.module.scss";
import {
  formatPastDaysInsight,
  formatPillarsData,
  getFetchingStatus,
  getFormattedDateRanges,
  getTimeUnit,
  pillarsDataMapper,
  timeFilterOptions,
  timeFilterTypes,
} from "../../utils";
import { InfoTextPopup } from "../InfoTextPopup";
import { NoData } from "../NoData";
import { IMetricValuesProps } from "../organization-metrices/types";
import { PillarDetailsProps } from "./types";

export const PillarDetails: React.FC<any> = ({
  item,
  userFilters,
  className,
  extraSpacing,
  handleItemClick,
  infoTexts,
  visible,
  selectedInfoText,
  setSelectedInfoText,
}) => {
  const [pillarInteractionsData, setPillarInteractionsData] =
    useState<PillarDetailsProps>({
      data: [],
      previousInteractions: 0,
      totalInteractions: 0,
      isDataConcealed: false,
    });
  const { t } = useTranslation();

  const { handleGetPillarInteractionsHistory, isLoading } =
    usePillarInteractionsHistory();

  useEffect(() => {
    const isFetching = getFetchingStatus(item.selectedValues);
    if (isFetching) getPillarInteractionData();
  }, [item.selectedValues, userFilters]);

  const getPillarInteractionData = () => {
    const { startingDate, endingDate } = getFormattedDateRanges(
      item.selectedValues,
    );
    const timeunit = getTimeUnit(item.selectedValues);

    handleGetPillarInteractionsHistory({
      startDate: startingDate,
      endDate: endingDate,
      timeUnit: timeunit,
      pillarId: item?.selectedPillar?.key,
      userFilters,
    }).then((data) => {
      const interactionsData: IMetricValuesProps[] = formatPillarsData(
        data?.history,
        item.selectedValues,
      );
      setPillarInteractionsData({
        previousInteractions: data.previousInteractions,
        totalInteractions: data.totalInteractions,
        isDataConcealed: data.isDataConcealed,
        data: interactionsData,
      });
    });
  };

  const handleRefresh = () => {
    getPillarInteractionData();
  };

  const pastTimeLabel = timeFilterOptions.find(
    (time) => time.value === item.selectedValues.pastDays,
  );

  const pillarLineColor = pillarsDataMapper?.find(
    (pillar) => pillar.name === item?.selectedPillar?.name,
  )?.lineColor;

  const pastComparison = pillarInteractionsData?.previousInteractions
    ? parseInt(
        (
          ((pillarInteractionsData?.totalInteractions -
            pillarInteractionsData?.previousInteractions) /
            pillarInteractionsData?.previousInteractions) *
          100
        ).toFixed(0),
      )
    : 0;

  return (
    <>
      <CustomCard
        bordered={false}
        className={`${styles["section-card"]} ${styles["pillars-content-height"]}`}
      >
        <div
          className={`${styles["content-container"]} ${
            extraSpacing ? styles["content-container-height"] : ""
          }`}
        >
          <div className={styles["section-item-container"]}>
            <div className={styles["item-header"]}>
              <div
                className={`${styles["margin-right-8"]} cursor-pointer`}
                onClick={() => handleItemClick?.()}
              >
                <ArrowLeftIcon />
              </div>
              {`${item?.selectedPillar?.name} ${t("pillar-interactions")}`}
            </div>
            <div>
              <InfoTextPopup
                items={infoTexts}
                visible={visible || selectedInfoText !== item.type}
                handleSelectedInfoText={(reset?: boolean) =>
                  setSelectedInfoText(reset ? "" : item.type)
                }
              />
            </div>
          </div>

          {pillarInteractionsData?.data?.length &&
          !pillarInteractionsData?.isDataConcealed &&
          !isLoading ? (
            <>
              <div className={styles["pillars-linechart-container"]}>
                <LineChart
                  lineChartConfig={{
                    barSize: 32,
                    data: pillarInteractionsData?.data,
                    lineColor: pillarLineColor || "",
                    xData: {
                      key: "name",
                      isEmojiBar: false,
                      interval:
                        item.selectedValues?.pastDays ===
                        timeFilterTypes.CUSTOM_DATE_RANGE
                          ? undefined
                          : 0,
                      width: 80,
                      customXTick: 40,
                      className: `${styles["metrices-linechart-label"]}`,
                    },
                    yData: {
                      key: "value",
                      domain: item.domain,
                    },
                  }}
                />
              </div>
              <div
                className={`${styles["percentange-container"]} ${styles["pillars-insights-margin-top"]}`}
              >
                <div className={styles["checkin-past-days"]}>
                  {`${pastTimeLabel?.label}: ${formatPastDaysInsight(
                    item.selectedValues,
                    true,
                  )}`}
                </div>
              </div>
              <div className={styles["percentange-container"]}>
                <img
                  className={styles["no-data-img"]}
                  src={true ? arrowUpSvg : arrowDownSvg}
                  alt="No Data"
                />
                <div className={`${styles["past-value-comparison"]}`}>
                  {`${t("on-average")} ${
                    pillarInteractionsData?.totalInteractions
                  } ${t("interactions")}${
                    pillarInteractionsData?.previousInteractions ? "," : "."
                  }`}
                </div>
                {pillarInteractionsData?.previousInteractions ? (
                  <>
                    <div
                      className={`${styles["past-value-comparison"]} ${
                        styles["pillar-insights-margin"]
                      } ${
                        pastComparison >= 0
                          ? styles["past-value-up"]
                          : styles["past-value-down"]
                      }`}
                    >
                      {`${Math.abs(pastComparison)}%`}
                    </div>
                    <div className={styles["past-value-comparison"]}>
                      {`${pastComparison >= 0 ? t("more") : t("less")} ${t(
                        "than-previous",
                      )} ${pastTimeLabel?.description}`}
                    </div>
                  </>
                ) : null}
              </div>
            </>
          ) : (
            <div
              className={`${styles["margin-top-16"]} ${styles["pillar-list-container"]}`}
            >
              {isLoading ? (
                <Spin spinning={isLoading} />
              ) : (
                <NoData
                  handleClick={handleRefresh}
                  btnText={t("refresh")}
                  title={
                    pillarInteractionsData?.isDataConcealed
                      ? t("data-concealed-for-privacy")
                      : `${t("not-enough-data-for")} ${
                          pastTimeLabel?.description
                        }`
                  }
                />
              )}
            </div>
          )}
        </div>
      </CustomCard>
    </>
  );
};
