import { MoreOutlined } from "@ant-design/icons";
import { Modal, Select, Space, Switch, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import CustomButton from "components/custom-button/CustomButton";
import CustomDropDown from "components/custom-dropdown/CustomDropDown";
import CustomTable from "components/custom-table/CustomTable";
import CustomLanguageSelector from "components/form/custom-language-selector/CustomLanguageSelector";
import { PERMS, getLanguage } from "helpers";
import { CONFIRM_MODAL_CONFIG } from "helpers/constants";
import { getLangDir } from "helpers/getLangDir";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHabitsViewModel } from "../hooks/useHabitsViewModel";

interface HabitListProps {}

const HabitList: React.FunctionComponent<HabitListProps> = (props) => {
  type MappedHabitList = NonNullable<typeof vm.habitsListData>[0];
  const { t } = useTranslation();
  const vm = useHabitsViewModel();
  const handleMenu = ({
    key,
    id,
    record,
  }: {
    key: string;
    id: string;
    record: MappedHabitList;
  }) => {
    if (key === "habit-details") {
      vm.onPressHabitDetails(id, record.language ?? "");
    }
    if (key === "edit-habit") {
      vm.onPressUpdateHabit(id, record.language ?? "");
    }
    if (key === "add-new-language") {
      vm.onPressAddLang(id, record);
    }
    if (key === "edit-language") {
      vm.onPressUpdateLanguage(id, record.language ?? "");
    }
    if (key === "delete-habit") {
      Modal.confirm({
        ...CONFIRM_MODAL_CONFIG,
        closable: true,
        centered: true,
        title: t("delete-habit"),
        content: (
          <>
            <div className="confirm-content">{t("confirm-delete-habit")}</div>
          </>
        ),
        width: 506,
        onOk: () => vm.onDeleteHabit(id),
      });
    }
  };

  const handleToggleQuest = (record: MappedHabitList) => {
    Modal.confirm({
      ...CONFIRM_MODAL_CONFIG,
      closable: true,
      centered: true,
      title: t("warning"),
      content: (
        <>
          <div className="confirm-content">
            {record.isActive
              ? t("sure-to-deactivate-habit")
              : t("sure-to-activate-habit")}
          </div>
        </>
      ),
      width: 506,
      onOk: () => vm.onToggleHabitStatus(record.id ?? ""),
    });
  };

  const columns: ColumnsType<MappedHabitList> = [
    {
      title: `${t("id")}`,
      key: "id",
      dataIndex: "id",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, index) =>
        record.hasChildren
          ? (vm.currentPage - 1) * vm.pageSize + index + 1
          : null,
    },
    {
      title: `${t("habit-name")}`,
      key: "name",
      dataIndex: "name",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => {
        return (
          <div className={record?.hasChildren ? "parent-row" : "child-row"}>
            <Space>
              <span className={`cut-text-${getLangDir(record.title ?? "")}`}>
                {record.title}
              </span>
            </Space>
          </div>
        );
      },
    },
    {
      title: `${t("language")}`,
      key: "language",
      dataIndex: "language",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => getLanguage(record.language),
    },
    {
      title: `${t("habit-goal")}`,
      key: "target",
      dataIndex: "target",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => record.target,
    },
    {
      title: `${t("goal-unit")}`,
      key: "unit",
      dataIndex: "unit",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => record?.unit?.unit,
    },
    {
      title: `${t("pillar-title")}`,
      key: "pillar",
      dataIndex: "pillar",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => record?.pillar?.title,
    },
    {
      title: t("status-label"),
      dataIndex: "isActive",
      key: "isActive",
      align: "center" as "center",

      render: (isActive: boolean) =>
        isActive ? (
          <Tag className="active-tag"> &bull; {`${t("active")}`}</Tag>
        ) : (
          <Tag className="de-active-tag">{t("deactivated")}</Tag>
        ),
    },
    {
      align: "center" as "center",
      title: t("activation"),
      dataIndex: "id",
      key: "id",
      render: (_text, record, _index) => (
        <>
          <CheckPermissions permission={PERMS.HABIT_ACTIVATION}>
            <Switch
              className="custom-switch"
              defaultChecked
              checked={!!record.isActive}
              onClick={() => handleToggleQuest(record)}
            />
          </CheckPermissions>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "Actions",
      align: "center",
      render: (id: string, record) => {
        const mainActions = [
          {
            key: "habit-details",
            label: t("habit-details"),
          },
          {
            key: "edit-habit",
            label: t("edit-habit"),
          },
          {
            key: "add-new-language",
            label: t("add-new-language"),
          },
          {
            key: "delete-habit",
            label: t("delete-habit"),
          },
        ];
        const langActions = [{ key: "edit-language", label: "Edit Language" }];
        return (
          <CheckPermissions
            permission={PERMS.DELETE_HABIT || PERMS.UPDATE_HABIT}
          >
            <CustomDropDown
              menu={{
                items: record?.hasChildren ? mainActions : langActions,
                onClick: ({ key }) => handleMenu({ key, id, record }),
              }}
            >
              <CustomButton type="link" className="link-btn">
                <MoreOutlined />
              </CustomButton>
            </CustomDropDown>
          </CheckPermissions>
        );
      },
    },
  ];
  return (
    <>
      <CustomTable
        headerTitle={t("habits")}
        btnText={t("add-new-habit")}
        onClick={vm.onPressAddHabit}
        expandIconColumnIndex={1}
        columns={columns}
        dataSource={vm.habitsListData}
        totalItems={vm.columnsData?.total}
        loading={vm.loading.isLoadingHabitList}
        rowKey={(record) =>
          record.hasChildren
            ? record.id ?? ""
            : `${record?.id}_${record?.title}`
        }
        filters={{
          handleCallback: vm.filtersCallBack,
          status: {
            applyAs: "and",
            key: "isActive",
            comparison: "Equal",
          },
          manualFilters: [
            {
              key: "pillarId",
              value: "pillarId",
              comparison: "Equal",
              applyAs: "and",
              render: (onChangeCallback) => (
                <Select
                  options={vm.pillarsData?.options}
                  fieldNames={{ label: "label", value: "id" }}
                  className="filter-select"
                  onChange={onChangeCallback}
                  allowClear
                  placeholder={t("pillar-title")}
                />
              ),
            },
          ],
        }}
      />
      {vm.langVisibility && (
        <CustomLanguageSelector
          visible={vm.langVisibility}
          setVisible={vm.onCloseLangDialog}
          onSubmit={vm.onLanguageSubmit}
          data={vm.habitRecord}
          title={
            vm.isAddLanguage ? undefined : `${t("habit")} ${t("main-language")}`
          }
          label={vm.isAddLanguage ? undefined : t("habit")}
          saveText={vm.isAddLanguage ? undefined : t("add-habit")}
        />
      )}
    </>
  );
};

export default HabitList;
