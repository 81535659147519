export const CrossSvg = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.93999 5.00004L8.80666 2.14004C8.93219 2.01451 9.00272 1.84424 9.00272 1.66671C9.00272 1.48917 8.93219 1.31891 8.80666 1.19338C8.68112 1.06784 8.51086 0.997314 8.33332 0.997314C8.15579 0.997314 7.98553 1.06784 7.85999 1.19338L4.99999 4.06004L2.13999 1.19338C2.01445 1.06784 1.84419 0.997314 1.66666 0.997314C1.48912 0.997314 1.31886 1.06784 1.19332 1.19338C1.06779 1.31891 0.997262 1.48917 0.997262 1.66671C0.997262 1.84424 1.06779 2.01451 1.19332 2.14004L4.05999 5.00004L1.19332 7.86004C1.13084 7.92202 1.08124 7.99575 1.0474 8.07699C1.01355 8.15823 0.996124 8.24537 0.996124 8.33337C0.996124 8.42138 1.01355 8.50852 1.0474 8.58976C1.08124 8.671 1.13084 8.74473 1.19332 8.80671C1.2553 8.86919 1.32903 8.91879 1.41027 8.95264C1.49151 8.98648 1.57865 9.00391 1.66666 9.00391C1.75466 9.00391 1.8418 8.98648 1.92304 8.95264C2.00428 8.91879 2.07801 8.86919 2.13999 8.80671L4.99999 5.94004L7.85999 8.80671C7.92197 8.86919 7.9957 8.91879 8.07694 8.95264C8.15818 8.98648 8.24531 9.00391 8.33332 9.00391C8.42133 9.00391 8.50847 8.98648 8.58971 8.95264C8.67095 8.91879 8.74468 8.86919 8.80666 8.80671C8.86914 8.74473 8.91874 8.671 8.95258 8.58976C8.98643 8.50852 9.00385 8.42138 9.00385 8.33337C9.00385 8.24537 8.98643 8.15823 8.95258 8.07699C8.91874 7.99575 8.86914 7.92202 8.80666 7.86004L5.93999 5.00004Z"
        fill="currentColor"
      />
    </svg>
  );
};
