import { Avatar, Col, Row, Select, Space } from "antd";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { CustomUploadFile } from "components/form/custom-upload-file/CustomUploadFile";
import { useSpecialistConfigList } from "hooks/useSpecialistConfig";
import { FormikValues } from "formik";
import {
  ContentType,
  MutationDeleteSubtitleArgs,
} from "graphql/_generated/graphql";
import styles from "features/content/css/AddContent.module.scss";
import { checkTypeAudio, getLabelFromValue } from "../utils";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const FileContent = (props: {
  editId: string;
  value: string | null;
  values: FormikValues;
  languages: { label: string; value: string }[];
  handleDeleteSubTitle: (values: MutationDeleteSubtitleArgs) => Promise<void>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
}) => {
  const { t } = useTranslation();
  const { values, setFieldValue, languages } = props;
  const { getSpecialistConfigData } = useSpecialistConfigList();
  const isTypeAudio = checkTypeAudio(values);

  return (
    <>
      {/* This block of code runs if the content type is audio or file */}
      {(isTypeAudio || values.contentType === "File") && (
        <>
          <Row gutter={[24, 0]}>
            <Col sm={24} md={12}>
              <CustomSelectField
                label={t("content-spoken-language")}
                required={true}
                value={getLabelFromValue(languages, values.spokenLanguage)}
                name="spokenLanguage"
                placeholder={t("select-language")}
                options={languages}
                defaultValue={getLabelFromValue(
                  languages,
                  values.spokenLanguage,
                )}
                className="form-select"
              />
            </Col>
            <Col sm={24} md={12}>
              <div className={styles["form-control"]}>
                <CustomSelectField
                  label={t("select-specialist")}
                  name="creatorId"
                  className="form-select"
                  placeholder={t("select-specialist")}
                >
                  {getSpecialistConfigData?.map((item: any, index: any) => (
                    <Option value={item.value} key={index}>
                      <Space>
                        <CustomAvatar imgSrc={item.image} />
                        {item.label}
                      </Space>
                    </Option>
                  ))}
                </CustomSelectField>
              </div>
            </Col>
          </Row>
          <CustomUploadFile
            durationFieldName={isTypeAudio ? "duration" : undefined}
            accept={
              isTypeAudio
                ? ".mp3"
                : "application/pdf, .xlsx, .xls, .txt, .doc, .docx"
            }
            label={t("upload-content-file")}
            name={
              isTypeAudio
                ? "audioUrl"
                : values.contentType === "File"
                ? "fileUrl"
                : ""
            }
            setFieldValue={setFieldValue}
            imgUrl={
              isTypeAudio
                ? values["audioUrl"]
                : values.contentType === "File"
                ? values["fileUrl"]
                : undefined
            }
          />
        </>
      )}
    </>
  );
};

export default FileContent;
