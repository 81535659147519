import CustomButton from "components/custom-button/CustomButton";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import AddAdmin from "../add/AddClientAdmin";
import AdminList from "../list/AdminClientList";
import styles from "./AdminClient.module.scss";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
interface AdminsClientProps {}

const AdminClient: React.FunctionComponent<AdminsClientProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <DashboardLayout openKey={["Users"]}>
        <Breadcrumbs
          data={[
            {
              label: "Users",
            },
            {
              label: t("admins-title"),
            },
          ]}
        />
        <AdminList />
      </DashboardLayout>
    </>
  );
};

export default AdminClient;
