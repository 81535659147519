import { useToggleActivationRewards } from "./useToggleActivationRewards";
import { useSetCompanyRewardPoints } from "./useSetCompanyRewardPoints";
import { useDeleteException } from "./useDeleteException";

export const useChallenge = () => {
  const {
    isLoading: toggleActivationRewardLoading,
    handleActivationException,
  } = useToggleActivationRewards();

  const { isLoading: isDeleteExceptionLoading, handleDeleteException } =
  useDeleteException();

  const { handleUpdateCompanyRewardPoints, isLoading } = useSetCompanyRewardPoints();

  return {
    isLoading:
      isLoading ||
      toggleActivationRewardLoading ||
      isDeleteExceptionLoading,
      handleActivationException,
    handleUpdateCompanyRewardPoints,
    handleDeleteException,
  };
};
