import { Divider, Space } from "antd";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import AddOption from "components/custom-button/AddOption";
import CustomButton from "components/custom-button/CustomButton";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import CustomTextEditor from "components/form/custom-text-editor/CustomTextEditor";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import ContainerHeading from "features/assessment/questions/ContainerHeading";
import CollapseAble from "features/assessment/questions/collapseables/CollapseAble";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import styles from "features/habits/css/AddHabit.module.scss";
import AddUnit from "features/units/AddUnit";
import { useMeasurementModel } from "features/units/hooks/useMeasurementModel";
import { Form, Formik } from "formik";
import { PERMS, renderHtmlContent } from "helpers";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { addHabitSchema } from "validations/schema";
import {
  HabitState,
  useAddHabitViewModel,
} from "../hooks/useAddHabitViewModel";

interface AddHabitProps {}

const AddHabit: React.FunctionComponent<AddHabitProps> = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const state = location.state as HabitState;
  const vm = useAddHabitViewModel(state);
  const unitVm = useMeasurementModel();

  return (
    <DashboardLayout openKey={["Activity"]}>
      <Breadcrumbs
        data={[
          {
            label: t("activities"),
          },
          {
            label: t("habits"),
          },
          {
            label: state?.id
              ? `${t("update-habit")} - ${t(state?.langCode)}`
              : `${t("add-new-habit")} - ${t(state?.langCode)}`,
          },
        ]}
      />
      <Formik
        enableReinitialize
        initialValues={vm.initialValues}
        validationSchema={addHabitSchema}
        onSubmit={vm.onHabitSubmit}
      >
        <Form>
          <div className={styles["habit-header"]}>
            <div className={styles["habit-title"]}>
              {state.id
                ? `${t("update-habit")} - ${t(state?.langCode)}`
                : `${t("add-new-habit")} - ${t(state?.langCode)}`}
            </div>
            <Space size={12}>
              <CustomButton
                htmlType="reset"
                className="link-text-btn"
                onClick={vm.onPressCancel}
              >
                {t("cancel")}
              </CustomButton>
              <CheckPermissions permission={PERMS.ADD_NEW_HABIT}>
                <CustomButton
                  className="primary-btn"
                  htmlType="submit"
                  loading={vm.isLoading}
                >
                  {t("save-habit")}
                </CustomButton>
              </CheckPermissions>
            </Space>
          </div>
          <div className={styles["content-container"]}>
            <CollapseAble
              defaultActiveKey={["description"]}
              headerKey="description"
              hideExtra
              expandIconPosition="end"
              header={<ContainerHeading text={t("name-desc")} />}
            >
              <div className={styles["flex-row"]}>
                <CustomTextField
                  name="title"
                  label={t("habit-name")}
                  placeholder={t("enter-habit-name")}
                  isAddLanguage={state?.addLanguage}
                  oldTextValue={vm.habitsData?.oldTitle}
                />
              </div>
              <CustomTextEditor
                name="description"
                label={t("description")}
                placeholder={t("enter-description")}
                isRestrictAccess
                isAddLanguage={state?.addLanguage}
                oldTextValue={renderHtmlContent(vm.habitsData?.oldDescription)}
              />
            </CollapseAble>
            <CollapseAble
              defaultActiveKey={["habit-options"]}
              headerKey="habit-options"
              hideExtra
              expandIconPosition="end"
              header={<ContainerHeading text={t("habit-option")} />}
            >
              <div className={styles["flex-row"]} id="area">
                <CustomSelectField
                  name="pillarId"
                  label={t("pillar-title")}
                  placeholder={t("select-pillar")}
                  options={vm.pillarsData?.options}
                  fieldNames={{ label: "label", value: "id" }}
                  getPopupContainer={() => document.body}
                  disabled={state?.addLanguage}
                />
                <CustomSelectField
                  name="unitId"
                  label={t("goal-unit")}
                  placeholder={t("select-goal-unit")}
                  options={vm.unitsData?.options}
                  fieldNames={{ label: "label", value: "id" }}
                  getPopupContainer={() => document.body}
                  disabled={state?.addLanguage}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: "0px" }} />
                      <div className={styles["add-unit"]}>
                        <AddOption
                          title={t("add-unit")}
                          onClick={unitVm.onPressAddUnit}
                        />
                      </div>
                    </>
                  )}
                />
              </div>
              <div className={styles["flex-row"]}>
                <CustomTextField
                  name="target"
                  label={t("recommended-target")}
                  type="number"
                  placeholder={t("enter-recommended-target")}
                  disabled={state?.addLanguage}
                />
                <CustomSelectField
                  name="repetition"
                  label={t("repetition")}
                  placeholder={t("select-repetition")}
                  disabled={state?.addLanguage}
                  options={vm.repetitionOptions}
                />
              </div>
            </CollapseAble>
          </div>
        </Form>
      </Formik>
      <AddUnit
        isAddingTranslation
        visible={unitVm.isAddUnit}
        onCloseUnit={unitVm.onCloseUnit}
        initialValues={unitVm.initialValues}
        onSubmitUnit={unitVm.onSubmitUnit}
        loading={unitVm.loading.isLoadingAddUnit}
      />
    </DashboardLayout>
  );
};

export default AddHabit;
