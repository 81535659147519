import SignIn from "pages/auth/SignIn";
import VerifyOtp from "pages/auth/VerifyOtp";
import CheckEmail from "pages/auth/CheckEmail";
import OtpVerify from "pages/auth/OtpVerify";
import ResetPassword from "pages/auth/ResetPassword";
import ForgotPassword from "pages/auth/ForgotPassword";
import VerificationMethod from "pages/auth/VerificationMethod";
import DeleteAccountPage from "pages/delete-account/DeleteAccountPage";
import VerificationSuccess from "features/delete-account/VerificationSuccess";
import VerificationError from "features/delete-account/VerificationError";

const LoggedOutRoutes = [
  {
    path: "/check-email",
    element: <CheckEmail />,
  },
  {
    path: "/reset-password/:userId",
    element: <ResetPassword />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/verify-otp",
    element: <VerifyOtp />,
  },
  {
    path: "/signup/:userId/:otp",
    element: <OtpVerify />,
  },
  {
    path: "/verifyOtp/:userId/:otp",
    element: <OtpVerify />,
  },
  {
    path: "/verification-method",
    element: <VerificationMethod />,
  },
  {
    path:'/delete-account',
    element: <DeleteAccountPage />,
  },
  {
    path:'/verification-success',
    element: <VerificationSuccess />,
  },
  {
    path:'/verification-error',
    element: <VerificationError />,
  },
  {
    path: "/",
    element: <SignIn />,
  },
  
];

export default LoggedOutRoutes;
