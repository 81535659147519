import Sleep from "./Sleep";
import randomString from "./randomString";

interface IRetryHelperOptions {
  retries?: number;
  retryIntervalMs?: number;
  backoffIteration?: number;
  trialID?: string | undefined;
  exponentialBackoff?: boolean;
  logs?: boolean;
}

/**
 * Recurring Retry Function with Exponential Backoff.
 * Runs 'RetryFunction' & retries automatically if it fails.
 *
 * Tries max '1 + retries' times,
 * with 'retryIntervalMs' milliseconds between retries.
 * From https://mtsknn.fi/blog/js-retry-on-fail/
 *
 * @param RetryFunction
 * @param options
 * @returns Promise
 */
export const RetryHelper = async <T>(
  RetryFunction: () => Promise<T> | T,
  {
    retries = 3,
    retryIntervalMs = 500,
    logs = false,
    trialID = randomString(5),
    exponentialBackoff = false,
    backoffIteration = 0,
  }: IRetryHelperOptions,
): Promise<T> => {
  try {
    logs && console.log(trialID, " Trying...  ");

    return await RetryFunction();
  } catch (error) {
    /**
     * Retries Exhausted
     */
    if (retries <= 0) {
      logs && console.log(trialID, " Retry  ", retries, " Exhausted");

      throw error;
    }

    logs && console.log(trialID, " Retry  ", retries);

    backoffIteration = exponentialBackoff ? backoffIteration + 1 : 1;

    await Sleep(retryIntervalMs * backoffIteration);

    return RetryHelper(RetryFunction, {
      retries: retries - 1,
      exponentialBackoff,
      backoffIteration,
      retryIntervalMs,
      trialID,
      logs,
    });
  }
};

/**
 * Retry Test function.
 *
 * @returns Promise
 */
export const TestFunction = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const number = Math.floor(Math.random() * 10);

      // will work ~ 30% times
      if (number >= 7) {
        return reject("RETRY TEST FUNCTION - FAILED");
      } else {
        return resolve("RETRY TEST FUNCTION - PASSED");
      }
    }, 500);
  });
};

// RetryHelper(TestFunction);

export default RetryHelper;
