import {
  useLazyGetRewardCategoryDetailsQuery,
  useUpdateRewardCategoryMutation,
  MutationUpdateRewardCategoryArgs,
  useGetRewardCategoryListQuery,
} from "graphql/_generated/graphql";

export const useUpdateRewardCategory = () => {
  const [getCategoryDetailFun, { isLoading }] =
    useLazyGetRewardCategoryDetailsQuery();

  const [updateRewardCategoryFun, { isLoading: isLoadingUpdateCategory }] =
    useUpdateRewardCategoryMutation();

  const { refetch } = useGetRewardCategoryListQuery();

  const handleGetRewardCategory = async (id: string) => {
    try {
      const res = await getCategoryDetailFun({
        getRewardCategoryDetailsId: id,
      }).unwrap();
      return res.getRewardCategoryDetails;
    } catch (error) {
      throw error;
    }
  };

  const handleUpdateRewardCategory = async (
    values: MutationUpdateRewardCategoryArgs,
  ) => {
    try {
      await updateRewardCategoryFun({
        update: values.update,
        updateRewardCategoryId: values.id,
      }).unwrap();
      refetch();
    } catch (error) {
      throw error;
    }
  };

  return {
    handleGetRewardCategory,
    handleUpdateRewardCategory,
    isLoading: isLoading || isLoadingUpdateCategory,
  };
};
