import {
  useGetCollectionListQuery,
  useGetConfigTagsQuery,
  useLazyGetCategoriesTreeQuery,
  useLazyGetContentConfigQuery,
} from "graphql/_generated/graphql";

export const useGetCollectionConfig = () => {
  const { refetch } = useGetCollectionListQuery();
  const [getContentFunc] = useLazyGetContentConfigQuery();
  const { data: tagsData } = useGetConfigTagsQuery();
  const [getCategoriesFun] = useLazyGetCategoriesTreeQuery();

  return {
    getContentFunc,
    getCategoriesFun,
    tagsData,
    refetch,
  };
};
