import { InfoCircleFilled } from "@ant-design/icons";
import { Tooltip } from "antd";
import { CustomUploadFile } from "components/form/custom-upload-file/CustomUploadFile";
import uploadImgPlaceholder from "assets/images/imgPlaceHolder.svg";
import { FunctionComponent } from "react";
import styles from "features/challenge/css/AddActivity.module.scss";
import CollapseAble from "features/assessment/questions/collapseables";
import ContainerHeading from "features/assessment/questions/ContainerHeading";

interface ChallengeImageProps {
  setFieldValue?: any;
  disabled?: boolean;
  values?: any;
}

const ChallengeImage: FunctionComponent<ChallengeImageProps> = ({
  setFieldValue,
  disabled,
  values,
}) => {
  return (
    <div className="challenge-detail-container">
      <CollapseAble
        defaultActiveKey={["challenge-details"]}
        headerKey={"challenge-details"}
        className={styles["activity-card"]}
        header={
          <div className="d-flex">
            <ContainerHeading text={"Challenge Details"} />
          </div>
        }
        hideExtra={true}
        expandIconPosition="end"
      >
        <div className={styles["challenge-img"]}>
          <CustomUploadFile
            label="Thumbnail Image"
            infoText="Use PNG or JPEG images with the size of 1280x720 pxl (16.9 ratio),
          and consider the safe area size inside and center of this image as
          900x720 pxl"
            name="image"
            accept="image/png, .jpg, .jpeg"
            disabled={disabled}
            customUploadText={
              <span>
                Drop your image here, or&nbsp; <br />
                <b>select click to browse</b>
              </span>
            }
            imgUrl={values.image}
            customUploadImgSrc={uploadImgPlaceholder}
            setFieldValue={setFieldValue}
            showFullPreview={true}
          />
        </div>
      </CollapseAble>
    </div>
  );
};
export default ChallengeImage;
