import CollapseAble from "features/assessment/questions/collapseables/CollapseAble";
import uploadImgPlaceholder from "assets/images/imgPlaceHolder.svg";

import { CustomTextArea } from "components/form/custom-text-area/CustomTextArea";
import { CustomUploadFile } from "components/form/custom-upload-file/CustomUploadFile";

import { InfoIcon } from "assets/icons/InfoIcon";

import { CustomFastField } from "components/form/custom-fast-field/CustomFastField";
import { CustomSwitch } from "components/form/custom-switch/CustomSwitch";

import "./feedback.module.scss";
import { Space } from "antd";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { useTranslation } from "react-i18next";

export interface FeedbackProps {
  isCheckInFeedbackEnable: boolean;
  feedback: string;
  maxChar: number;
  minChar: number;
  disabled?: boolean;
  handleChange?: any;
  isAddLanguage?: boolean;
}

const Feedback: React.FunctionComponent<FeedbackProps> = (props) => {
  const { t } = useTranslation();
  const minWords = props.minChar ?? 100;
  const maxWords = props.maxChar ?? 1000;

  return (
    <div className={"Feedback-container"}>
      <CollapseAble
        defaultActiveKey={["1"]}
        headerKey={"1"}
        expandIconPosition="end"
        hideExtra={true}
        header={
          <div className="custom-table">
            <CustomSwitch
              label={t("enable-feedback")}
              name="isCheckInFeedbackEnable"
              switchPosition="start"
              className="custom-switch"
              isActive={props?.isCheckInFeedbackEnable}
              isDisabled={props?.disabled || props?.isAddLanguage}
              handleChange={props?.handleChange}
            />
          </div>
        }
      >
        {props?.isCheckInFeedbackEnable ? (
          <>
            <CustomTextField
              label={t("description")}
              name="helperText"
              placeholder={t("add-feedback")}
              disabled={props?.disabled || props?.isAddLanguage}
            />
            <Space className="Feedback-container__limitContainer ">
              <CustomTextField
                label={t("min-characters-allowed")}
                name="minChar"
                placeholder=""
                type="number"
                disabled={props?.disabled || props?.isAddLanguage}
              />
              <CustomTextField
                label={t("max-characters-allowed")}
                name="maxChar"
                type="number"
                placeholder=""
                disabled={props?.disabled || props?.isAddLanguage}
              />
            </Space>
          </>
        ) : null}
      </CollapseAble>

      {/* <CustomButton htmlType="submit">Submit</CustomButton> */}
    </div>
  );
};

export default Feedback;
