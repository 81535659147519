import customNotification from "components/custom-notification/CustomNotification";
import { FormikHelpers } from "formik";
import {
  TierCreate,
  TierList,
  useGetTierListQuery,
  useLazyGetTierListQuery,
} from "graphql/_generated/graphql";
import { useLanguages } from "hooks";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useTier } from "./useTier";

export const useTiersListViewModel = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();

  const [langVisibility, setLangVisibility] = useState(false);
  const [langCode, setLangCode] = useState<string>();
  const [isAddLanguage, setIsAddLanguage] = useState(false);
  const [isAddTier, setIsAddTier] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [editId, setEditId] = useState<string | null>(null);
  const [tierData, setTiersData] = useState<MappedTierList>();
  const [isActivated, setIsActivated] = useState(false);

  const { isFetching } = useGetTierListQuery();
  const [columnsData, setColumnsData] = useState<TierList>();
  const [filtersData, setFiltersData] = useState<any>(null);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [getAllTagsDataFun] = useLazyGetTierListQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const { handleError } = useErrorHandler();
  const { languages } = useLanguages();

  const {
    handleTierActivation,
    handleDeleteTier,
    isLoading: isLoadingTier,
  } = useTier();

  useEffect(() => {
    fetchDataFromApi(getAllTagsDataFun, setColumnsData, filtersData).catch(
      (e) => handleError(e),
    );
  }, [isFetching]);

  const mappedColumnsData = columnsData?.data.map((item) => {
    return (
      {
        ...item,
        hasChildren: true,
        children:
          item?.translations && Object.keys?.(item?.translations)
            ? Object.keys?.(item?.translations)?.map((record) => {
                return {
                  ...item,
                  name: item?.translations?.[record]?.name,
                  language: record,
                };
              })
            : null,
      } ?? []
    );
  });

  type MappedTierList = NonNullable<typeof mappedColumnsData>[0];

  const handleMenu = ({ key, id, record }: any) => {
    if (key === "tier-details") {
      navigation(`/tier-details/${id}/${record?.language}`);
    }
    if (key === "add-new-language") {
      setTiersData(record);
      setLangVisibility(true);
      setEditId(id);
      setIsAddLanguage(true);
    }
    if (key === "edit-tier") {
      setIsAddLanguage(false);
      setEditId(id);
      setIsAddTier(true);
      setLangCode(undefined);
    }
    if (key === "delete-tier") {
      setIsDelete(true);
      setEditId(id);
      () => submitDelete();
    }
    if (key === "edit-language") {
      setLangCode(record.language);
      setIsAddLanguage(true);
      setEditId(id);
      setIsAddTier(true);
    }
  };

  const handleSubmit = (
    values: { language: string },
    actions: FormikHelpers<{ language: string }>,
  ) => {
    setLangCode(values?.language);
    setLangVisibility(false);
    setIsAddTier(true);
    actions.resetForm();
  };

  const submitDelete = async () => {
    try {
      const res = await handleDeleteTier(editId ?? "");
      customNotification("success", t("deleted-tier-successfully"));
      setIsDelete(false);
      setEditId(null);
      return res;
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const handleToggleTier = (record: MappedTierList) => {
    setIsActivated(true);
    setTiersData(record);
  };

  const handleToggleTierActivation = async () => {
    try {
      const isDepartmentActive = await handleTierActivation(tierData?.id ?? "");
      customNotification(
        "success",
        isDepartmentActive
          ? t("tier-activation-successfully")
          : t("tier-deactivate-successfully"),
      );
      setIsActivated(false);
      setTiersData(undefined);
      return isDepartmentActive;
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const handleFilterCallback = async (filtersData: any) => {
    await fetchDataFromApi(getAllTagsDataFun, setColumnsData, filtersData);
    setPageSize(filtersData?.pagination?.offset?.pageSize);
    setCurrentPage(filtersData?.pagination?.offset?.page);
    setFiltersData(filtersData);
  };

  const [initialValues, setInitialValues] = useState<TierCreate>({
    name: "",
    points: 0,
    tierCardImage: "",
    tierImage: "",
    tierIcon: "",
    language: "en",
  });

  const handleCancel = () => {
    setIsAddTier(false);
    setEditId?.(null);
  };

  const {
    handleAddTier,
    isLoading: isTierLoading,
    handleGetTierDetail,
    handleUpdateTier,
  } = useTier();

  useEffect(() => {
    if (editId) {
      handleGetTierDetail(editId).then((data) => {
        const translationData = langCode && data?.translations?.[langCode];
        setInitialValues({
          name: isAddLanguage ? translationData?.name : data?.name,
          points: data?.points || 0,
          tierCardImage: data?.tierCardImage,
          tierImage: data?.tierImage,
          tierIcon: data?.tierIcon,
          language: data?.language,
        });
      });
    }
  }, [editId]);

  const handleAddSubmit = async (
    values: TierCreate,
    { resetForm }: FormikHelpers<TierCreate>,
  ) => {
    if (!editId && !isAddLanguage) {
      try {
        await handleAddTier({
          create: {
            ...values,
            language: langCode,
            points: Number(values.points),
          },
        });
        customNotification("success", t("tier-added-successfully"));
        resetForm();
        setIsAddTier(false);
      } catch (error) {
        handleError(`${error}`);
      }
    } else if (isAddLanguage && editId && langCode) {
      try {
        await handleUpdateTier({
          update: {
            translations: {
              [langCode]: {
                name: values.name,
              },
            },
          },
          id: editId,
        });
        customNotification("success", t("tier-language-added-successfully"));
        resetForm();
        setIsAddTier(false);
        await handleGetTierDetail(editId);
        setEditId?.(null);
      } catch (error) {
        handleError(error as string | object);
      }
    } else {
      try {
        await handleUpdateTier({ update: values, id: editId });
        customNotification("success", t("tier-updated-successfully"));
        setIsAddTier(false);
        resetForm();
        await handleGetTierDetail(editId ?? "");
        setEditId?.(null);
      } catch (error) {
        handleError(error as string | object);
      }
    }
  };

  return {
    mappedColumnsData,
    currentPage,
    pageSize,
    handleToggleTier,
    handleMenu,
    languages,
    columnsData,
    isLoading,
    langVisibility,
    setLangVisibility,
    handleSubmit,
    tierData,
    isAddTier,
    setIsAddTier,
    editId,
    setEditId,
    langCode,
    isAddLanguage,
    isDelete,
    setIsDelete,
    submitDelete,
    isLoadingTier,
    isActivated,
    setIsActivated,
    handleToggleTierActivation,
    handleFilterCallback,
    isTierLoading,
    initialValues,
    handleCancel,
    handleAddSubmit,
  };
};
