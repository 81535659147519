export const FulFillmentIcon = () => {
  return (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none">
      <path
        d="M22.974 0.7697C22.974 0.34462 22.6286 0 22.2035 0C18.1308 0 14.6284 2.48772 13.1356 6.02336C11.6427 2.48772 8.14012 0 4.06682 0C3.64174 0 3.29712 0.34462 3.29712 0.7697C3.29712 5.93562 7.2993 10.1843 12.3659 10.5782V15.6095C12.3659 16.0346 12.7105 16.3792 13.1356 16.3792C13.5607 16.3792 13.9053 16.0346 13.9053 15.6095V10.5782C18.9719 10.1843 22.974 5.93562 22.974 0.7697ZM4.87557 1.5784C8.80761 1.96012 11.9451 5.09762 12.3268 9.02965C8.3951 8.64763 5.25765 5.51018 4.87557 1.5784ZM13.9443 9.02971C14.326 5.09793 17.4633 1.96053 21.3956 1.5785C21.0135 5.51023 17.8761 8.64763 13.9443 9.02971Z"
        fill="#303030"
      />
      <path
        d="M8.32163 15.4775C7.35746 14.5134 5.82042 14.4985 4.83715 15.4034V12.311C4.83715 10.0695 3.01337 8.24438 0.770572 8.24438H0.7697C0.34462 8.24438 0 8.589 0 9.01408V18.9073C0 19.1114 0.0811264 19.3072 0.225471 19.4515L4.9466 24.1728V25.5028C4.9466 25.9279 5.29122 26.2725 5.7163 26.2725H11.4874C11.9124 26.2725 12.2571 25.9279 12.2571 25.5028V21.0979C12.2571 20.0113 11.834 18.9899 11.0658 18.2217L8.32163 15.4775ZM10.7177 24.7331H6.486V23.8539C6.486 23.6498 6.40488 23.4541 6.26053 23.3097L1.5394 18.5885V9.90499C2.99372 10.3718 3.29775 11.7194 3.29775 12.311V17.2585C3.29775 17.4627 3.37888 17.6584 3.52322 17.8027L6.82098 21.1005C7.12157 21.401 7.60894 21.4011 7.90949 21.1005C8.21008 20.7999 8.21008 20.3126 7.90949 20.012L5.84838 17.9509C5.46579 17.5682 5.46548 16.949 5.84838 16.5661C6.23123 16.1833 6.85017 16.1831 7.23318 16.5661L9.97731 19.3103C10.4548 19.7877 10.7177 20.4225 10.7177 21.0979V24.7331H10.7177Z"
        fill="#303030"
      />
      <path
        d="M25.5017 8.24438H25.5009C23.2581 8.24438 21.4343 10.0696 21.4343 12.311V15.4034C20.451 14.4985 18.914 14.5134 17.9498 15.4775L15.2056 18.2217C14.4375 18.9899 14.0144 20.0113 14.0144 21.0979V25.5027C14.0144 25.9278 14.359 26.2724 14.7841 26.2724H20.5551C20.9802 26.2724 21.3248 25.9278 21.3248 25.5027V24.1727L26.0459 19.4515C26.1903 19.3072 26.2714 19.1114 26.2714 18.9073V9.01408C26.2714 8.58895 25.9268 8.24438 25.5017 8.24438ZM24.732 18.5884L20.0109 23.3096C19.8665 23.454 19.7854 23.6497 19.7854 23.8538V24.733H15.5538V21.0978C15.5538 20.4225 15.8167 19.7876 16.2942 19.3102L19.0383 16.566C19.4213 16.1831 20.0403 16.1832 20.4231 16.566C20.806 16.949 20.8057 17.5682 20.4231 17.9508L18.362 20.0119C18.0614 20.3125 18.0614 20.7998 18.362 21.1004C18.6625 21.401 19.1499 21.401 19.4505 21.1004L22.7482 17.8027C22.8926 17.6583 22.9737 17.4626 22.9737 17.2584V12.311C22.9737 11.7193 23.2777 10.3717 24.7321 9.90493V18.5884H24.732Z"
        fill="#303030"
      />
    </svg>
  );
};
