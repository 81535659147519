import { HabitIntervals } from "graphql/_generated/graphql";
import { useMemo } from "react";

export const useGetHabitIntervals = () => {
  const habitIntervals = useMemo(() => {
    return Object.keys(HabitIntervals).map((item) => ({
      label: item,
      value: HabitIntervals[item as keyof typeof HabitIntervals],
    }));
  }, []);

  return {
    habitIntervals,
  };
};
