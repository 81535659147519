import { getLabelFromValue } from "features/content/utils";
import { DefaultRecordType } from "rc-table/lib/interface";

type Language = {
  value: string;
  label: string;
};

export function getFilteredContent<T>(contentList: T[], formValues: any): T[] {
  if (!formValues.language) return contentList;

  return contentList.filter((item: any) => {
    return item.translations && item.translations[formValues.language];
  });
}

export const createRenderFunction = (
  languageProp: keyof DefaultRecordType,
  translationsProp: keyof DefaultRecordType,
) => {
  return (record: DefaultRecordType, languages: Language[]): string[] => {
    const renderedItems: string[] = [];

    const languageValue = record[languageProp];
    const translationsValue = record[translationsProp];

    if (languageValue) {
      const languageLabel = getLabelFromValue(languages, languageValue);
      if (languageLabel) {
        renderedItems.push(languageLabel);
      }
    }

    if (translationsValue) {
      if (Array.isArray(translationsValue)) {
        translationsValue.forEach((lang) => {
          const languageLabel = getLabelFromValue(languages, lang);
          if (languageLabel) {
            renderedItems.push(languageLabel);
          }
        });
      } else if (typeof translationsValue === "object") {
        Object.keys(translationsValue).forEach((key) => {
          const matchingLanguage = languages.find(
            (item: Language) => item.value === key,
          );
          if (matchingLanguage) {
            renderedItems.push(matchingLanguage.label);
          }
        });
      }
    }

    return renderedItems;
  };
};
