import customNotification from "components/custom-notification/CustomNotification";
import { useChallenge } from "features/challenge/hooks/useChallenge";
import { FormikHelpers } from "formik";
import {
  ChallengeType,
  ChallengeValidations,
  GetChallengeQuery,
  GoalFilterType,
  Trackable,
} from "graphql/_generated/graphql";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useLoggedInUser } from "hooks/useLoggedInUser";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { getChallengeStatuses } from "../utils";

export interface ChallengeProps {
  validations?: ChallengeValidations | undefined;
  companies: string[] | undefined;
  categoryId: string | null;
  completePoints: number | null | undefined;
  description: string | null;
  duration: number | null;
  expiryDate: Date | undefined;
  startDate: Date | undefined;
  goal: number | null;
  goalType: Trackable | string | null;
  image: string | null;
  pillarId: string | null;
  rankPoints: number[] | null | undefined;
  rewardDetails: string | null;
  rules: string | null;
  tagIds: string[] | null;
  title: string | null;
  type: ChallengeType | null;
  duration_type: string;
  translations: undefined;
}

export const useAddChallengeViewModel = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const [oldRecord, setOldRecord] = useState<
    GetChallengeQuery["getChallenge"] | null
  >(null);

  const [isViwellChallenge, setIsViwellChallenge] = useState(true);
  const { isSuperAdmin, isViwellAdmin, isClient } = useLoggedInUser();

  const [enableValidations, setEnableValidations] = useState({
    isMinAge: false,
    isMaxAge: false,
    isGender: false,
    isDepartment: false,
    isPosition: false,
    isChildCompany: false,
  });

  const {
    isLoading,
    handleAddNewChallenge,
    handleFetchChallengeData,
    handleUpdateChallenge,
  } = useChallenge();

  const { editId, language, isAddLanguage, languageKey, isCloned, isViewOnly } =
    location.state;
  const [initialValues, setInitialValues] = useState<ChallengeProps>({
    validations: {
      age: {
        max: undefined,
        min: undefined,
      },
      gender: undefined,
      departmentIds: undefined,
      positionIds: undefined,
    },
    companies: undefined,
    categoryId: null,
    completePoints: undefined,
    description: null,
    duration: null,
    expiryDate: undefined,
    startDate: undefined,
    goal: null,
    goalType: null,
    image: null,
    pillarId: null,
    rankPoints: [],
    rewardDetails: null,
    rules: null,
    tagIds: null,
    title: null,
    type: null,
    duration_type: "1",
    translations: undefined,
  });
  const { isPlanned } = getChallengeStatuses({
    startDate: initialValues.startDate,
    expiryDate: initialValues.expiryDate,
    isActive: undefined,
  });

  //this function is getting the data from api and show in the form
  const showPrevData = async () => {
    if (editId) {
      handleFetchChallengeData(editId).then(
        async (data: GetChallengeQuery["getChallenge"]) => {
          try {
            const translationData = data?.translations?.[languageKey];
            setOldRecord(data);
            setIsViwellChallenge(data?.isViwellChallenge);
            setEnableValidations({
              isMinAge: data?.validations?.age?.min ? true : false,
              isMaxAge: data?.validations?.age?.max ? true : false,
              isGender: data?.validations?.gender ? true : false,
              isDepartment: data?.validations?.departmentIds?.length
                ? true
                : false,
              isPosition: data?.validations?.positionIds?.length ? true : false,
              isChildCompany:
                isClient && data?.companies?.slice(1).length ? true : false,
            });
            let goalType = null;
            if (
              [Trackable.Collection, Trackable.Content].includes(data?.goalType)
            ) {
              if (data?.goalFilter?.value) {
                goalType = `${data?.goalType}-${data?.goalFilter?.value}`;
              } else {
                goalType = `${data?.goalType}-All`;
              }
            } else {
              goalType = data?.goalType;
            }
            setInitialValues({
              validations: data?.validations ?? undefined,
              companies: isClient ? data?.companies.slice(1) : data?.companies,
              categoryId: data?.category?.id,
              completePoints: data?.completePoints,
              description: isAddLanguage
                ? translationData?.description
                : data?.description,
              duration: data?.duration,
              expiryDate: isCloned ? undefined : data?.expiryDate,
              startDate: isCloned ? undefined : data?.startDate,
              goal: data?.goal,
              goalType,
              image: data?.image,
              pillarId: data?.pillar?.id,
              rankPoints: data?.rankPoints,
              rewardDetails: isAddLanguage
                ? translationData?.rewardDetails
                : data?.rewardDetails,
              rules: isAddLanguage ? translationData?.rules : data?.rules,
              tagIds: data?.tags?.map((item) => item.id),
              title: isAddLanguage ? translationData?.title : data?.title,
              type: data?.type,
              duration_type: "1",
              translations: data?.translations,
            });
          } catch (error) {
            console.log(error);
          }
        },
      );
    }
  };

  useEffect(() => {
    //this function will fetch the prev data and show in the form if edit id exists
    showPrevData();
  }, [editId]);

  const onSubmit = async (
    values: ChallengeProps,
    { resetForm }: FormikHelpers<ChallengeProps>,
  ) => {
    let challengeValidations: ChallengeValidations = {
      age: undefined,
      gender: undefined,
      departmentIds: undefined,
      positionIds: undefined,
    };
    let companies: string[] | undefined = isClient ? undefined : [];
    let goalType = values?.goalType as Trackable,
      goalFilter = undefined;
    if (enableValidations?.isMinAge || enableValidations?.isMaxAge) {
      challengeValidations.age = {
        min: enableValidations?.isMinAge
          ? values?.validations?.age?.min
          : undefined,
        max: enableValidations?.isMaxAge
          ? values?.validations?.age?.max
          : undefined,
      };
    }
    if (enableValidations?.isGender) {
      challengeValidations.gender = values?.validations?.gender;
    }
    if (enableValidations?.isDepartment) {
      challengeValidations.departmentIds = values?.validations?.departmentIds;
    }
    if (enableValidations?.isPosition) {
      challengeValidations.positionIds = values?.validations?.positionIds;
    }
    if (values?.goalType?.includes(Trackable.Collection)) {
      const goalValue = values?.goalType.split("-")[1];
      if (goalValue !== "All") {
        goalFilter = {
          type: GoalFilterType.CollectionType,
          value: goalValue,
        };
      }
      goalType = Trackable.Collection;
    } else if (values?.goalType?.includes(Trackable.Content)) {
      const goalValue = values?.goalType.split("-")[1];
      if (goalValue !== "All") {
        goalFilter = {
          type: GoalFilterType.ContentType,
          value: goalValue,
        };
      }
      goalType = Trackable.Content;
    } else {
      goalFilter = null;
    }
    if (
      (!isViwellChallenge && !isClient) ||
      (isClient && enableValidations?.isChildCompany)
    ) {
      companies = values.companies ?? [];
    }
    const requestData = {
      duration: values?.duration ?? 0 * parseInt(values.duration_type) ?? 0,
      duration_type: undefined,
      companies,
      validations: challengeValidations,
      goalType,
      goalFilter,
    };
    if (!editId || isCloned) {
      try {
        await handleAddNewChallenge({
          create: {
            ...values,
            ...requestData,
            language,
            description: values.description ?? "",
            goal: values.goal ?? 0,
            goalType: goalType ?? Trackable.Steps,
            image: values.image ?? "",
            rewardDetails: values.rewardDetails ?? "",
            rules: values.rules ?? "",
            title: values.title ?? "",
            tagIds: values.tagIds ?? [],
            type: values.type ?? ChallengeType.Physical,
          },
        });
        resetForm();
        customNotification("success", t("challenge-added-successfully"));
        navigate("/challenges");
      } catch (error) {
        handleError(error as string | object);
      }
    } else {
      try {
        if (isAddLanguage) {
          await handleUpdateChallenge(editId, {
            translations: {
              [languageKey]: {
                title: values.title,
                description: values.description,
                rules: values.rules,
                rewardDetails: values.rewardDetails,
              },
            },
          });
          customNotification(
            "success",
            t("translation-added-updated-successfully"),
          );
        } else {
          if (isPlanned) {
            await handleUpdateChallenge(editId, {
              ...values,
              ...requestData,
            });
          } else {
            await handleUpdateChallenge(editId, {
              title: values.title,
              description: values.description,
              rules: values.rules,
              rewardDetails: values.rewardDetails,
              image: values.image,
            });
          }

          customNotification("success", t("challenge-updated-successfully"));
        }
        resetForm();
        navigate("/challenges");
      } catch (error) {
        handleError(error as string | object);
      }
    }
  };

  const navigateTo = () => {
    navigate("/challenges#persist");
  };

  const isDisabled = () => {
    if (isCloned) return false;
    return editId && (isAddLanguage || isViewOnly || !isPlanned);
  };
  return {
    oldRecord,
    isViwellChallenge,
    isSuperAdmin,
    isViwellAdmin,
    enableValidations,
    initialValues,
    isAddLanguage,
    editId,
    language,
    onSubmit,
    isViewOnly,
    isLoading,
    setIsViwellChallenge,
    setEnableValidations,
    navigateTo,
    isDisabled,
  };
};
