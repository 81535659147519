// Third-party libraries
import { Col, Divider, Row } from "antd";
import { FieldArray, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";

// Local components
import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import CustomTextEditor from "components/form/custom-text-editor/CustomTextEditor";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { CustomUploadFile } from "components/form/custom-upload-file/CustomUploadFile";

// Hooks

// GraphQL
import { Category } from "graphql/_generated/graphql";

// Others
import { MultiSelectSort } from "components/form/custom-sortable-select/CustomSortableSelect";
import { useAddCollectionViewModel } from "features/content/hooks/useAddCollectionViewModel";
import { collectionSchema } from "validations/schema";
import { getLabelFromValue } from "../../utils";
import styles from "./AddCollection.module.scss";

export interface AddCollectionProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  editId?: string | null;
  setEditId: React.Dispatch<React.SetStateAction<string | null>>;
}

export interface CategoryWithChildren extends Category {
  children: Category[] | null;
}

const AddCollection: React.FunctionComponent<AddCollectionProps> = ({
  visible,
  setVisible,
  editId,
  setEditId,
}) => {
  const { t } = useTranslation();
  const {
    handleCancel,
    initialValues,
    handleAddNewCollection,
    refetch,
    onSubmit,
    languages,
    collectionTypeItems,
    setContentFilters,
    pillarsData,
    categoriesData,
    contentStatus,
    tagsData,
    contentData,
    isContentLoading,
    isLoading,
    handlePillarChange,
  } = useAddCollectionViewModel({
    visible,
    setVisible,
    editId,
    setEditId,
  });

  return (
    <CustomModal
      title={editId ? t("edit-program") : t("add-program")}
      width={900}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={collectionSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <CustomUploadFile
              name="image"
              setFieldValue={setFieldValue}
              imgUrl={values.image}
              accept="image/*"
            />
            <Row gutter={[24, 0]}>
              <Col xs={24} sm={12}>
                <CustomSelectField
                  label={t("text-language")}
                  required={true}
                  value={getLabelFromValue(languages, values)}
                  name="language"
                  placeholder={t("select-language")}
                  options={languages}
                  defaultValue={getLabelFromValue(languages, values)}
                  className="form-select"
                />
              </Col>
              <Col xs={24} sm={12}>
                <CustomTextField
                  name="title"
                  placeholder={t("title")}
                  label={t("title")}
                />
              </Col>
            </Row>
            <CustomTextEditor
              name="description"
              label={t("description")}
              placeholder={t("enter-description")}
            />

            {/*this field array is dynamically add the title and description */}
            <FieldArray
              name="translations"
              render={(arrayHelpers) => {
                return (
                  <div>
                    {values.translations && values.translations.length > 0 ? (
                      values.translations.map(
                        (translation: any, index: number) => {
                          return (
                            <div key={index}>
                              <div className="form-control">
                                <Row gutter={[24, 0]}>
                                  <Col xs={24} sm={12}>
                                    <CustomSelectField
                                      marginBtm={false}
                                      label={t("other-text-language")}
                                      required={true}
                                      value={getLabelFromValue(
                                        languages,
                                        values,
                                        index,
                                      )}
                                      name={`translations.${index}.lang`}
                                      placeholder={t("select-language")}
                                      options={languages}
                                      defaultValue={getLabelFromValue(
                                        languages,
                                        values,
                                        index,
                                      )}
                                      className="form-select"
                                    />
                                  </Col>
                                  <Col xs={24} sm={12}>
                                    <CustomTextField
                                      label={t(
                                        "write-title-in-selected-language",
                                      )}
                                      name={`translations.${index}.title`}
                                      placeholder={t(
                                        "enter-title-in-selected-language",
                                      )}
                                    />
                                  </Col>
                                </Row>
                              </div>
                              <CustomTextEditor
                                label={t(
                                  "write-description-in-selected-language",
                                )}
                                name={`translations.${index}.description`}
                                placeholder={t(
                                  "enter-description-in-selected-language",
                                )}
                              />

                              <div className={styles["flex-div"]}>
                                {values.translations.length + 1 <
                                languages.length ? (
                                  <span
                                    className={styles["add-btn"]}
                                    onClick={() =>
                                      arrayHelpers.push({
                                        lang: "",
                                        title: "",
                                        description: "",
                                      })
                                    } // insert an empty string at a position
                                  >
                                    + {t("add-new-language")}
                                  </span>
                                ) : (
                                  ""
                                )}
                                <span
                                  className={styles["remove-btn"]}
                                  onClick={() => arrayHelpers.remove(index)} // remove a language from the list
                                >
                                  - {t("remove-language")}
                                </span>
                              </div>
                              <Divider />
                            </div>
                          );
                        },
                      )
                    ) : (
                      <div className={styles["form-control"]}>
                        <span
                          className={styles["add-btn"]}
                          onClick={() =>
                            arrayHelpers.push({
                              lang: "",
                              title: "",
                              description: "",
                            })
                          }
                        >
                          + {t("add-new-language")}
                        </span>
                      </div>
                    )}
                  </div>
                );
              }}
            />
            <Row gutter={[24, 0]}>
              <Col xs={24} sm={12}>
                <CustomSelectField
                  label={t("program-type")}
                  placeholder={t("select-program-type")}
                  name="collectionType"
                  options={collectionTypeItems}
                  onChange={(val) => {
                    setFieldValue("contentIds", null);
                    setContentFilters((prev) => ({
                      ...prev,
                      ...{ type: val },
                    }));
                  }}
                />
              </Col>
              <Col xs={24} sm={12}>
                <CustomSelectField
                  label={t("spoken-language")}
                  name="spokenLanguage"
                  placeholder={t("select-language")}
                  options={languages}
                  onChange={(val) => {
                    setFieldValue("contentIds", null);
                    setContentFilters((prev) => ({
                      ...prev,
                      ...{ lang: val },
                    }));
                  }}
                />
              </Col>
              <Col xs={24} sm={12}>
                <CustomSelectField
                  onChange={(value) => {
                    handlePillarChange(value);
                    setFieldValue("categoryIds", "");
                    setFieldValue("pillarIds", value);
                  }}
                  label={t("pillar-title")}
                  className="form-select"
                  name="pillarIds"
                  placeholder={t("select-pillar")}
                  options={pillarsData}
                />
              </Col>
              <Col xs={24} sm={12}>
                <CustomSelectField
                  label={t("categories")}
                  name="categoryIds"
                  mode="multiple"
                  placeholder={t("select-category")}
                  className="form-select"
                  options={categoriesData?.getCategoriesTree}
                  fieldNames={{ label: "name", value: "id" }}
                />
              </Col>

              <Col xs={24}>
                <CustomSelectField
                  label={t("status")}
                  name="status"
                  placeholder={t("select-status")}
                  className="form-select"
                  options={contentStatus}
                />
              </Col>
              <Col xs={24}>
                <CustomSelectField
                  mode="multiple"
                  name="tagIds"
                  label={t("tags")}
                  placeholder={t("select-tags")}
                  options={tagsData?.getConfigTags?.options}
                  fieldNames={{ label: "label", value: "id" }}
                />
              </Col>
              <Col xs={24}>
                <MultiSelectSort
                  setFieldValue={setFieldValue}
                  options={contentData!}
                  selected={values.contentIds}
                  name="contentIds"
                  label={t("content")}
                  placeholder={t("select-content")}
                  isDisabled={isContentLoading}
                />
              </Col>
            </Row>
            <ModalFooter
              saveText={t("save-program")}
              onCancel={handleCancel}
              loading={isLoading || isContentLoading}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddCollection;
