import { useTranslation } from "react-i18next";
import { useTeamLeaderboard } from "./useTeamLeaderboard";
import { useEffect, useState } from "react";
import {
  AssignmentType,
  EventParticipantsScoreInput,
  GetTeamEventMembersQuery,
  GetTeamLeaderboardQuery,
  ManualTeamAssignmentInput,
  RemoveParticipantsInput,
  useGetMemberCountQuery,
  useGetTeamEventMembersQuery,
  useLazyGetTeamEventMembersQuery,
} from "graphql/_generated/graphql";
import { useErrorHandler } from "hooks/useErrorHandler";
import { FormikHelpers } from "formik";
import customNotification from "components/custom-notification/CustomNotification";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import { TableRowSelection } from "antd/lib/table/interface";

export type TeamLeaderState = {
  eventId: string;
  onUpdatedLeaderboard?: (() => void) | null;
};

export const useTeamLeaderboardViewModel = (state: TeamLeaderState) => {
  const { eventId, onUpdatedLeaderboard } = state ?? {};

  //States
  const [teamId, setTeamId] = useState<string | null>();
  const [teamScoreVisibility, setTeamScoreVisibility] = useState(false);
  const [editTeamVisibility, setEditTeamVisibility] = useState(false);
  const [teamLeaderboardData, setTeamLeaderboardData] =
    useState<GetTeamLeaderboardQuery["getTeamLeaderboard"]>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [tableCurrentPage, setTableCurrentPage] = useState<number>(1);
  const [pagination, setPagination] = useState({
    limit: 10,
    offset: 0,
  });
  const [filtersData, setFiltersData] = useState<any>(null);
  const [columnsData, setColumnsData] =
    useState<GetTeamEventMembersQuery["getTeamEventMembers"]>();
  const [pageSize, setPageSize] = useState(10);

  //TeamMembers States
  const [teamEventMembersData, setTeamEventMembersData] =
    useState<GetTeamEventMembersQuery["getTeamEventMembers"]>();
  const [teamEventMemberFiltersData, setTeamEventMemberFiltersData] =
    useState<any>(null);
  const [teamEventMemberCurrentPage, setTeamEventMemberCurrentPage] =
    useState<number>(1);
  const [teamEventMemberPageSize, setTeamEventMemberPageSize] = useState(10);
  const [teamEventMemberSelectedKeys, setTeamEventMemberSelectedKey] = useState<
    string[]
  >([]);

  //AddMemberStates
  const [addMemberVisibility, setAddMemberVisibility] = useState(false);
  const [MembersData, setMembersData] =
    useState<GetTeamEventMembersQuery["getTeamEventMembers"]>();
  const [membersFiltersData, setMembersFiltersData] = useState<any>(null);
  const [membersPageSize, setMembersPageSize] = useState(10);
  const [membersCurrentPage, setMembersCurrentPage] = useState<number>(1);
  const [membersSelectedKeys, setMembersSelectedKeys] = useState<string[]>([]);

  //Change Team States
  const [isChangeTeamVisible, setIsChangeTeamVisible] = useState(false);

  //Hooks
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const {
    handleGetTeamLeaderboard,
    handleUpdateTeamScore,
    handleRemoveTeamMember,
    handleAssignToTeams,
    handleTeamAutomatically,
    isLoading: isLoadingTeam,
  } = useTeamLeaderboard();

  const { data, refetch: refetchMemberCount } = useGetMemberCountQuery({
    event: { id: eventId },
  });
  const memberCount = data?.getEventsById.unAssignedMemberCount ?? 0;

  const { fetchDataFromApi, isLoading: isLoadingTeamEventMembers } =
    useFetchDataFromApi(true);
  const [getTeamEventMembersFun] = useLazyGetTeamEventMembersQuery();
  const [getTeamMembersFun] = useLazyGetTeamEventMembersQuery();
  const { isFetching } = useGetTeamEventMembersQuery({
    data: {
      eventId,
    },
  });
  const fetchTeamEventParticipantsData = async () => {
    try {
      if (eventId) {
        await fetchDataFromApi(
          getTeamEventMembersFun,
          setColumnsData,
          filtersData,
          {
            data: {
              eventId: eventId,
            },
          },
        );
      }
    } catch (error) {
      handleError(error as string | object);
    }
  };

  useEffect(() => {
    fetchTeamEventParticipantsData();
  }, [eventId, filtersData, isFetching]);

  const filtersCallBack = async (filtersData: any) => {
    try {
      setPageSize(filtersData?.pagination?.offset?.pageSize);
      setTableCurrentPage(filtersData?.pagination?.offset?.page);
      setFiltersData(filtersData);
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const onChangePagination = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPagination({
      offset: (page - 1) * pageSize,
      limit: pageSize,
    });
  };

  const getTeamLeaderboardData = async () => {
    try {
      if (eventId) {
        const data = await handleGetTeamLeaderboard({
          getTeamLeaderboardId: eventId,
          pagination: pagination,
        });
        setTeamLeaderboardData(data);
      }
    } catch (error) {
      handleError(error as string | object);
    }
  };

  useEffect(() => {
    getTeamLeaderboardData();
  }, [eventId, currentPage, pagination]);

  const onSubmitUpdateTeamScore = async (
    values: EventParticipantsScoreInput,
    formikHelpers?: FormikHelpers<typeof values>,
  ) => {
    try {
      const res = await handleUpdateTeamScore({ update: values });
      formikHelpers?.resetForm();
      getTeamLeaderboardData();
      customNotification("success", t("score-update-successfully"));
      onPressEditTeamScore();
      return res;
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const fetchTeamEventMembersData = async () => {
    try {
      if (teamId && eventId) {
        await fetchDataFromApi(
          getTeamMembersFun,
          setTeamEventMembersData,
          teamEventMemberFiltersData,
          {
            data: {
              eventId: eventId,
              teamId: teamId,
              assignmentType: AssignmentType.Team,
            },
          },
        );
      }
    } catch (error) {
      handleError(error as string | object);
    }
  };

  useEffect(() => {
    fetchTeamEventMembersData();
  }, [eventId, teamId, teamEventMemberFiltersData]);

  const teamEventMembersFiltersCallBack = async (filtersData: any) => {
    try {
      setTeamEventMemberPageSize(filtersData?.pagination?.offset?.pageSize);
      setTeamEventMemberCurrentPage(filtersData?.pagination?.offset?.page);
      setTeamEventMemberFiltersData(filtersData);
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const teamEventMemberRowSelection: TableRowSelection<
    GetTeamEventMembersQuery["getTeamEventMembers"]["data"][0]
  > = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setTeamEventMemberSelectedKey(selectedRowKeys as string[]);
    },
    selectedRowKeys: teamEventMemberSelectedKeys,
  };

  const onSubmitRemoveTeamMember = async (values: RemoveParticipantsInput) => {
    try {
      const res = await handleRemoveTeamMember({
        data: values,
      });
      onUpdatedLeaderboard?.();
      fetchTeamEventMembersData();
      getTeamLeaderboardData();
      refetchMemberCount();
      customNotification("success", t("member-removed-successfully"));
      return res;
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const onPressEditTeamScore = () => {
    setTeamScoreVisibility(!teamScoreVisibility);
  };

  const onPressTeamMember = (teamId: string) => {
    setTeamId(teamId);
    setEditTeamVisibility(true);
  };

  const onCloseEditTeam = () => {
    setTeamId(null);
    setEditTeamVisibility(false);
    setTeamEventMemberSelectedKey([]);
  };

  const onPressAddMember = () => {
    setAddMemberVisibility(true);
  };
  const onCloseAddMember = () => {
    setAddMemberVisibility(false);
    setMembersSelectedKeys([]);
  };

  const onSubmitAddMember = async (
    values: ManualTeamAssignmentInput,
    formikHelpers?: FormikHelpers<typeof values>,
  ) => {
    try {
      const res = await handleAssignToTeams({ data: values });
      formikHelpers?.resetForm();
      onUpdatedLeaderboard?.();
      fetchTeamEventMembersData();
      getTeamLeaderboardData();
      onCloseAddMember();
      onCloseChangeTeam();
      refetchMemberCount();
      setTeamEventMemberSelectedKey([]);
      customNotification("success", t("member-added-successfully"));
      return res;
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const fetchUnAssignedTeamMembersData = async () => {
    try {
      if (eventId && addMemberVisibility) {
        await fetchDataFromApi(
          getTeamMembersFun,
          setMembersData,
          membersFiltersData,
          {
            data: {
              eventId: eventId,
              assignmentType: AssignmentType.UnAssigned,
            },
          },
        );
      }
    } catch (error) {
      handleError(error as string | object);
    }
  };

  useEffect(() => {
    fetchUnAssignedTeamMembersData();
  }, [eventId, membersFiltersData]);

  const membersFiltersCallBack = async (filtersData: any) => {
    try {
      setMembersPageSize(filtersData?.pagination?.offset?.pageSize);
      setMembersCurrentPage(filtersData?.pagination?.offset?.page);
      setMembersFiltersData(filtersData);
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const membersRowSelection: TableRowSelection<
    GetTeamEventMembersQuery["getTeamEventMembers"]["data"][0]
  > = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setMembersSelectedKeys(selectedRowKeys as string[]);
    },
    selectedRowKeys: membersSelectedKeys,
  };

  const onSubmitAssignAutomatically = async () => {
    try {
      const res = await handleTeamAutomatically({ eventId });
      onUpdatedLeaderboard?.();
      fetchTeamEventMembersData();
      getTeamLeaderboardData();
      refetchMemberCount();
      customNotification("success", t("assigned-automatically-successfully"));
      return res;
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const memberInitialValues = {
    eventId,
    teamId: teamId ?? "",
    participantIds: membersSelectedKeys,
  };

  const onPressChangeTeam = (participantId?: string) => {
    setIsChangeTeamVisible(true);
    setTeamEventMemberSelectedKey((prevSelectedKey) => {
      if (participantId) {
        return [...prevSelectedKey, participantId];
      } else {
        return prevSelectedKey;
      }
    });
  };

  const onCloseChangeTeam = () => {
    setIsChangeTeamVisible(false);
    setTeamEventMemberSelectedKey([]);
  };

  return {
    teamLeaderboardData,
    currentPage,
    teamScoreVisibility,
    columnsData,
    tableCurrentPage,
    pageSize,
    isLoading: {
      isLoadingTeamEventMembers,
      isLoadingTeam,
    },
    teamId,
    editTeamVisibility,
    teamEventMembersData,
    teamEventMemberPageSize,
    teamEventMemberCurrentPage,
    teamEventMemberSelectedKeys,
    teamEventMemberRowSelection,
    addMemberVisibility,
    memberInitialValues,
    membersRowSelection,
    membersPageSize,
    membersCurrentPage,
    MembersData,
    memberCount,
    isChangeTeamVisible,
    membersFiltersCallBack,
    onChangePagination,
    onPressEditTeamScore,
    onSubmitUpdateTeamScore,
    filtersCallBack,
    getTeamLeaderboardData,
    onPressTeamMember,
    onCloseEditTeam,
    teamEventMembersFiltersCallBack,
    onSubmitRemoveTeamMember,
    onPressAddMember,
    onCloseAddMember,
    onSubmitAddMember,
    fetchTeamEventParticipantsData,
    onPressChangeTeam,
    onCloseChangeTeam,
    onSubmitAssignAutomatically,
  };
};
