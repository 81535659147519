import { useEffect, useState } from "react";
import { AchievementIntervalType } from "helpers/achievementsInterval";

export const useGetAchievementsIntervalTypes = () => {
  const [intervalType, setIntervalType] = useState<any>([]);
  // this useEffect created the options array for interval type
  useEffect(() => {
    let dummyArr: any = [];
    Object.keys(AchievementIntervalType).map((item) => {
      dummyArr.push({
        label: item,
        value: AchievementIntervalType[item],
      });
    });

    setIntervalType(dummyArr);
  }, []);
  return { intervalType };
};
