import { useGetConfigCountriesQuery } from "graphql/_generated/graphql";
import { useMemo } from "react";

export const useGetCountries = () => {
  const { data } = useGetConfigCountriesQuery();

  const countries = useMemo(() => {
    return data?.getConfigCountries.options.map((country) => ({
      id: country.id,
      label: country.label,
      code: country?.extra?.code,
    }));
  }, [data?.getConfigCountries.options]);

  return {
    countries,
  };
};
