export const EditorSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6791 7.11914L8.88243 5.32247L10.1811 4.02314L11.9771 5.81914L10.6791 7.11914ZM6.0531 11.7498L4.06843 11.9305L4.24443 9.9598L7.9891 6.21514L9.78643 8.01247L6.0531 11.7498ZM12.9358 4.8918L12.9351 4.89114L11.1098 3.0658C10.6158 2.57314 9.7671 2.5498 9.2991 3.0198L3.30177 9.01714C3.08443 9.2338 2.94977 9.5218 2.92177 9.82647L2.6691 12.6065C2.65177 12.8031 2.72177 12.9978 2.86177 13.1378C2.98777 13.2638 3.15777 13.3331 3.3331 13.3331C3.35377 13.3331 3.37377 13.3325 3.39377 13.3305L6.17377 13.0778C6.4791 13.0498 6.76643 12.9158 6.9831 12.6991L12.9811 6.70114C13.4664 6.21447 13.4458 5.40247 12.9358 4.8918Z"
        fill="#121212"
      />
    </svg>
  );
};
