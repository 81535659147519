import { useAddSpecialist } from "./useAddSpecialist";
import { useUpdateSpecialist } from "./useUpdateSpecialist";
import { useToggleActivationSpecialist } from "./useToggleActivationSpecialist";
export const useSpecialist = () => {
  const { handleAddNewSpecialist, isLoading } = useAddSpecialist();

  const {
    handleUpdateSpecialist,
    handleFetchEditSpecialistData,
    isLoading: updatePositionLoading,
  } = useUpdateSpecialist();

  const {
    isLoading: toggleActivationSpecialistLoading,
    handleActivationSpecialist,
    handleRemoveSpecialist,
    isSpecialistLoading,
  } = useToggleActivationSpecialist();
  return {
    isLoading:
      isLoading ||
      updatePositionLoading ||
      toggleActivationSpecialistLoading ||
      isSpecialistLoading,
    handleAddNewSpecialist,
    handleUpdateSpecialist,
    handleFetchEditSpecialistData,
    handleActivationSpecialist,
    handleRemoveSpecialist,
  };
};
