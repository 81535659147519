import { Spin } from "antd";
import CustomCard from "components/custom-card/CustomCard";
import { useTranslation } from "react-i18next";
import styles from "../../CompanyDashboard.module.scss";
import { formatPastDaysInsight, timeFilterOptions } from "../../utils";
import { InfoTextPopup } from "../InfoTextPopup";
import { NoData } from "../NoData";

export const ContentList: React.FC<any> = ({
  item,
  className,
  extraSpacing,
  selectedInfoText,
  setSelectedInfoText,
}) => {
  const { t } = useTranslation();

  const pastTimeLabel = timeFilterOptions.find(
    (time) => time.value === item.selectedValues?.pastDays,
  );

  return (
    <>
      <CustomCard
        bordered={false}
        className={`${styles["section-card"]} ${className}`}
      >
        <div
          className={`${styles["content-container"]} ${
            extraSpacing ? styles["content-container-height"] : ""
          }`}
        >
          <div className={styles["section-item-container"]}>
            <div className={styles["item-header"]}>{item.title}</div>
            <div>
              <InfoTextPopup
                items={item.infoTexts}
                visible={item?.visible || selectedInfoText !== item.type}
                handleSelectedInfoText={(reset?: boolean) =>
                  setSelectedInfoText(reset ? "" : item.type)
                }
              />
            </div>
          </div>
          {item?.content?.data?.length &&
          !item?.content?.isDataConcealed &&
          !item?.isLoading ? (
            <>
              <div
                className={`${styles["margin-top-16"]} ${styles["list-content-height"]}`}
              >
                {item.content.data.map((item: any, index: number) => (
                  <div
                    className={`${styles["content-item-container"]} ${
                      index % 2 == 0 ? styles["bg-color"] : ""
                    }`}
                  >
                    <div
                      className={`${styles["content-text-container"]} ${
                        extraSpacing ? styles["content-container-spacing"] : ""
                      }`}
                    >
                      <div
                        className={`${styles["content-index"]} ${styles["content-index-width"]}`}
                      >
                        {item.id}
                      </div>
                      <div
                        className={`${styles["content-item-text"]} ${styles["content-spacing"]}`}
                      >
                        {item.name}
                      </div>
                    </div>
                    <div
                      className={styles["content-item-text"]}
                    >{`${item.value}%`}</div>
                  </div>
                ))}
              </div>
              <div className={styles["percentange-container"]}>
                <div className={styles["checkin-past-days"]}>
                  {`${pastTimeLabel?.label}: ${formatPastDaysInsight(
                    item.selectedValues,
                  )}`}
                </div>
              </div>
            </>
          ) : (
            <div className={styles["content-nodata-container-height"]}>
              {item.isLoading ? (
                <Spin spinning={item.isLoading} />
              ) : (
                <NoData
                  handleClick={item.handleRefresh}
                  btnText={t("refresh")}
                  title={
                    item?.content?.isDataConcealed
                      ? t("data-concealed-for-privacy")
                      : `${t("not-enough-data-for")} ${
                          pastTimeLabel?.description
                        }`
                  }
                />
              )}
            </div>
          )}
        </div>
      </CustomCard>
    </>
  );
};
