import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import TranslationFieldArr from "components/translation-field-array/TranslationFieldArr";
import { Form, Formik, FormikHelpers } from "formik";
import { BuildingCreate } from "graphql/_generated/graphql";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { buildingSchema } from "validations/schema";
import styles from "../Buildings.module.scss";
import { ConfigDataProps } from "../hooks/useAddBuildingViewModel";

interface CountriesProps {
  id: string;
  label: string;
  code: string;
}
type Props = {
  visible: boolean;
  closeModal: () => void;
  inviteUserView?: boolean;
  isLoading: boolean;
  isClient: boolean;
  initialValues: BuildingCreate;
  handleSubmit: (
    values: BuildingCreate,
    { resetForm }: FormikHelpers<BuildingCreate>,
  ) => void;
  redirectToBuildings: () => void;
  setFieldValue?: (field: string, value: string) => void;
  configData?: ConfigDataProps;
  countries: CountriesProps[] | undefined;
  isBuildingsLoading?: boolean;
};

const AddBuilding = ({
  visible,
  closeModal,
  inviteUserView,
  initialValues,
  handleSubmit,
  isLoading,
  isClient,
  redirectToBuildings,
  setFieldValue,
  configData,
  countries,
  isBuildingsLoading,
}: Props) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (configData?.buildingId && !isBuildingsLoading) {
      setFieldValue?.("employeeCreateDto.buildingId", configData.buildingId);
    }
  }, [configData, isBuildingsLoading]);

  return (
    <CustomModal
      width={600}
      visible={visible}
      onCancel={closeModal}
      footer={null}
      title={t("add-building")}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={buildingSchema}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form>
            <CustomTextField
              label={t("building-name")}
              name="name"
              placeholder={t("building-name-placeholder")}
            />
            <CustomTextField label={t("address")} name={`address.address`} />
            <CustomTextField label={t("company.city")} name={`address.city`} />
            <CustomSelectField
              label={t("select-country")}
              className="form-select"
              name="address.country"
              placeholder={t("select-country-placeholder")}
              options={countries}
              fieldNames={{ label: "label", value: "code" }}
            />
            {inviteUserView ? null : (
              <TranslationFieldArr values={values} attribute="name" />
            )}
            {inviteUserView && isClient ? (
              <div className={styles["building-configuration-details"]}>
                {t("to-add-more-details-visit")}
                <span
                  className={`cursor-pointer ${styles["building-configuration-hyperlink"]}`}
                  onClick={() => redirectToBuildings()}
                >
                  {t("configurations")}
                </span>
                {t("menu")}
              </div>
            ) : null}
            <ModalFooter
              saveText={t("save")}
              loading={isLoading}
              onCancel={closeModal}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddBuilding;
