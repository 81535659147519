import { Col, Row } from "antd";
import moodSvg from "assets/images/feel.svg";
import fulfillmentSvg from "assets/images/fulfillment.svg";
import sleepSvg from "assets/images/sleep.svg";
import stressSvg from "assets/images/stress.svg";
import {
  FilterValuesProps,
  WidgetExtraProps,
} from "features/company-dashboard/types";
import { timeFilterTypes } from "features/company-dashboard/utils";
import {
  AssessmentTypes,
  CheckInStats,
  useGetCheckinsOptionsQuery,
} from "graphql/_generated/graphql";
import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "../../CompanyDashboard.module.scss";
import { SectionHeader } from "../SectionHeader";
import { InfoTextProps } from "../organization-metrices/types";
import { Checkin } from "./Checkin";

export const Checkins: FunctionComponent<InfoTextProps & WidgetExtraProps> = ({
  selectedInfoText,
  setSelectedInfoText,
  companyRegisteredDate,
}) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(true);
  const [userFilters, setUserFilters] = useState<any>({
    clientIds: null,
    departmentIds: null,
    gender: null,
    positionIds: null,
    age: null,
  });
  const [selectedValues, setSelectedValues] = useState<FilterValuesProps>({
    pastDays: timeFilterTypes.PAST_7_DAYS,
    customDateRange: "",
  });

  const { data: optionsData, isFetching } = useGetCheckinsOptionsQuery();
  const checkinOptions = optionsData?.getCheckinsOptions;

  const renderInfoTexts = (checkinName: string) => [
    `${t("view-highest-percentage")} ${checkinName} ${t("check-ins-desc")}`,
    t("compare-trends-differences"),
    t("arrow-trends"),
    t("customise-view-demographics"),
  ];

  const CHECKINS: {
    [key: string]: {
      color: string;
      title: string;
      bgImage: React.ReactNode;
      type: AssessmentTypes;
      message: string;
      infoTexts: string[];
      options: CheckInStats[];
    };
  } = {
    mood: {
      color: "#7573E1",
      title: t("mood"),
      bgImage: moodSvg,
      type: AssessmentTypes.MoodCheckIn,
      message: t("employees-bad-mood"),
      infoTexts: renderInfoTexts(t("mood")),
      options: checkinOptions?.[AssessmentTypes.MoodCheckIn],
    },
    sleep: {
      color: "#089C9C",
      title: t("sleep"),
      bgImage: sleepSvg,
      type: AssessmentTypes.SleepCheckIn,
      message: t("employees-not-sleep-well"),
      infoTexts: renderInfoTexts(t("sleep")),
      options: checkinOptions?.[AssessmentTypes.SleepCheckIn],
    },
    fulfillment: {
      color: "#16A0F0",
      title: t("fulfillment"),
      bgImage: fulfillmentSvg,
      type: AssessmentTypes.FulfillmentCheckIn,
      message: t("employees-feel-unfulfilled"),
      infoTexts: renderInfoTexts(t("fulfillment")),
      options: checkinOptions?.[AssessmentTypes.FulfillmentCheckIn],
    },

    stress: {
      color: "#F45A7C",
      title: t("stress"),
      bgImage: stressSvg,
      type: AssessmentTypes.StressCheckIn,
      message: t("employees-feel-stressed"),
      infoTexts: renderInfoTexts(t("stress")),
      options: checkinOptions?.[AssessmentTypes.StressCheckIn],
    },
  };

  return (
    <>
      <div className={styles["checkins-chart"]}>
        <SectionHeader
          visible={visible}
          setVisible={setVisible}
          userFilters={userFilters}
          setUserFilters={setUserFilters}
          sectionTitle={t("section.check-ins")}
          companyRegisteredDate={companyRegisteredDate}
          initialValues={selectedValues}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
          open={open}
          setOpen={setOpen}
        />

        <div className={styles["checkins-list"]}>
          <Row gutter={[16, 16]} className={styles["row-flex"]}>
            {Object.keys(CHECKINS).map((item) => {
              return (
                <Col xs={24} md={12} xl={6}>
                  <Checkin
                    item={{
                      ...CHECKINS[item],
                      selectedValues: selectedValues,
                      isFetching,
                    }}
                    userFilters={userFilters}
                    visible={visible}
                    setSelectedInfoText={setSelectedInfoText}
                    selectedInfoText={selectedInfoText}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </>
  );
};
