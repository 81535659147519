import { useState } from "react";

export default function useStockEditor() {
  const [editorVisible, setEditorVisible] = useState<boolean>(false);

  const toggleEditorState = () => {
    setEditorVisible(!editorVisible);
  };

  return {
    toggleEditorState,
    editorVisible,
  };
}
