import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import * as React from "react";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import EventsList from "features/events/event-list/EventList";
import { useTranslation } from "react-i18next";

interface EventsProps {}

const Events: React.FunctionComponent<EventsProps> = () => {
  const { t } = useTranslation();
  return (
    <DashboardLayout openKey={["Activity"]}>
      <Breadcrumbs
        data={[
          {
            label: t("activities"),
          },
          {
            label: t("events"),
          },
        ]}
      />
      <EventsList />
    </DashboardLayout>
  );
};

export default Events;
