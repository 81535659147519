import * as Yup from "yup";
import * as Validations from "./common/validations";

export const pillarSchema = Yup.object().shape({
  title: Validations.requiredFieldWithoutSpaces("pillar"),
  icon: Validations.image("image"),
  color: Validations.required("color"),
  translations: Yup.array().of(
    Yup.object().shape({
      lang: Validations.required("language"),
      title: Validations.required("title"),
    })
  ),
});
