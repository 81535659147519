import React from "react";
import { Radio } from "formik-antd";
import { ErrorMessage } from "formik";
import { useErrorTranslation } from "validations/schema/common/useErrorTranslation";
import styles from "./CustomRadioGroup.module.scss";
import "./CustomRadioGroup.module.scss";

type Props = {
  name: string;
  label?: string;
  options: { label: string; value: string | boolean }[];
  children?: React.ReactNode;
  subLabel?: string;
  className?: string;
};

export function CustomRadioGroup(props: Props) {
  const { errorT } = useErrorTranslation();
  const { name, label, options, children, subLabel, className } = props;
  let radioProps = { ...props };
  delete radioProps?.subLabel;
  return (
    <>
      {label && (
        <label className={styles[className ? className : ""]} htmlFor={name}>
          {label}
        </label>
      )}

      {subLabel && (
        <label className={styles["subLabel"]} htmlFor={name}>
          {subLabel}
        </label>
      )}
      {options.length < 2 ? (
        <Radio {...radioProps}>{children}</Radio>
      ) : (
        <Radio.Group {...radioProps} />
      )}
      <ErrorMessage
        name={name}
        render={(msg) => <div className="text-danger">{errorT(msg)}</div>}
      />
    </>
  );
}
