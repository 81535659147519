import { useSelector } from "react-redux";
import { checkIfPermitted, parseJwt } from "helpers";

type Props = {
  permission: string;
};

const CheckPermissions: React.FC<React.PropsWithChildren<Props>> = ({
  permission,
  children,
}) => {
  const permissionsArr = useSelector(
    (state: any) => state.permissions.permissions,
  );

  const token = useSelector((state: any) => state.auth.token);
  const userType = parseJwt(token).userType;

  return (
    <div>
      {checkIfPermitted(permission, permissionsArr, userType) && children}
    </div>
  );
};

export default CheckPermissions;
