import EnumBoolean from "features/assessment/types/EnumBoolean";
import EQuestionHierarchy from "features/assessment/types/EnumQuestionHierarchy";
import EnumQuestionTypes from "features/assessment/types/EnumQuestionTypes";
import IAssessmentPillarHash from "features/assessment/types/IAssessmentPillarHash";
import { TagOptionsProps } from "../types/IQuestionOptions";
import { GetTransformedMatrix } from "./MatrixTransformer";

let pillars: Array<any>;
let pillarsHashMap: IAssessmentPillarHash;
let settings: any;
let questions: any;
let nestedQuestions: any;

let matrices: [];

/**
 * Transform assessment queried data to required Formik structure.
 *
 * @param data {} query data
 * @return {} transformed object
 */
export const AssessmentTransformer = (data: any) => {
  const { getAdminAssessmentQuestions: assessmentData } = data;

  pillars = assessmentData?.pillars ?? [];
  questions = assessmentData?.questions ?? {};
  settings = assessmentData?.settings ?? {};

  /**
   * Prepare nested questions.
   */
  const getDateTime = (date: string): number => new Date(date).getTime();

  Object.entries(questions)
    ?.sort(
      ([keyA, a]: [string, any], [keyB, b]: [string, any]) =>
        getDateTime(a?.createdAt) - getDateTime(b?.createdAt),
    )
    ?.map(([key, question]: [string, any], index: number) => {
      let subQuestions: any = {};
      if (question.optionType === "matrix") {
        const { rows, columns, metrices, numberOfOptions, numberOfTitles } =
          GetTransformedMatrix(question);
        question = {
          ...question,
          numberOfOptions,
          numberOfTitles,
          metricOptions: columns,
          metricTitles: rows,
          options: metrices,
        };
        matrices = metrices;
      }

      let options = question.options.map((option: any) => {
        // CHECKING IF SUBQUESTION ALREADY INSERTED IN SUBQUESTION
        let subAssessmentQuestion = option?.subAssessmentQuestionId
          ? questions[option.subAssessmentQuestionId]
          : null;

        // console.log("subQuestions count:>", subQuestions?.length);
        if (!!subQuestions?.[subAssessmentQuestion?.id]) {
          subQuestions[subAssessmentQuestion.id].parentOptionId = [
            ...subQuestions[subAssessmentQuestion.id].parentOptionId,
            option.id,
          ];
          // console.log("subQuestion found");
          // console.log("subQuestion", subAssessmentQuestion);
          // console.log("option", option);
        } else {
          // console.log("subQuestion not found");

          if (subAssessmentQuestion) {
            subAssessmentQuestion = {
              ...subAssessmentQuestion,
              categoryId: undefined,
              hierarchy: EQuestionHierarchy.SUB_QUESTION,
              savedOnServer: EnumBoolean.YES,
              parentOptionId: [option?.id],
              SN: subQuestions?.length + 1,
            };

            subQuestions = {
              ...subQuestions,
              [subAssessmentQuestion?.id]: subAssessmentQuestion,
            };
          }
        }
        let tags = option?.tags || [];
        tags = tags?.map((tagItem: any) => tagItem?.tagId);

        return {
          ...option,
          savedOnServer: EnumBoolean.YES,
          tags,
          emoji: option?.emoji || null,
        };
      });

      const subQuestionsResult: any = Object.values(subQuestions)?.map(
        (subQuestion: any) => {
          let subQuestionMatrices: any = [];
          if (subQuestion.optionType === "matrix") {
            const { rows, columns, metrices, numberOfOptions, numberOfTitles } =
              GetTransformedMatrix(subQuestion);
            subQuestion = {
              ...subQuestion,
              numberOfOptions,
              numberOfTitles,
              metricOptions: columns,
              metricTitles: rows,
              options: metrices,
            };
          }

          let subQuestionOptions = subQuestion?.options?.map((soItem: any) => {
            let tags = soItem?.tags || [];
            tags = tags?.map((tagItem: any) => tagItem?.tagId);
            return {
              ...soItem,
              savedOnServer: EnumBoolean.YES,
              tags,
              emoji: soItem?.emoji || null,
            };
          });

          const savedOptionType = `${optionKey(question?.optionType, true)}`;
          const optionType = `${optionKey(subQuestion?.optionType)}`;

          return {
            ...subQuestion,
            [savedOptionType]: subQuestionOptions,
            [optionType]: subQuestionOptions,
          };
        },
      );

      const savedOptionType = `${optionKey(question?.optionType, true)}`;
      const optionType = `${optionKey(question?.optionType)}`;

      nestedQuestions = {
        ...nestedQuestions,
        [key]: {
          ...question,
          subQuestions: subQuestionsResult,
          [savedOptionType]: options,
          [optionType]: options,
          category: question.categoryId,
          area: question.tagId,
          hierarchy: EQuestionHierarchy.PILLAR_QUESTION,
          savedOnServer: EnumBoolean.YES,
          SN: index + 1,
        },
      };
    });

  /**
   * Adding nested questions into pillars.
   */

  pillars.map((pillar: any, index: number) => {
    const pillarQuestions = findPillarQuestions(pillar.questionIds);
    let mainQuestions = {};

    Object.entries(pillarQuestions).map(([key, item]: [string, any]) => {
      if (!item.parentOptionId) {
        mainQuestions = {
          ...mainQuestions,
          [key]: {
            ...item,
            parentOptionId: undefined,
          },
        };
      }
      return item;
    });

    const id = pillar?.id ?? "";

    pillarsHashMap = {
      ...pillarsHashMap,
      [id]: {
        id: pillar?.id,
        title: `${pillar?.title}`,
        icon: pillar?.icon,
        questions: mainQuestions,
        SN: index + 1,
      },
    };

    return pillar;
  });

  /**
   * Preparing result.
   */

  return {
    settings,
    pillars: pillarsHashMap,
    nestedQuestions,
  };
};

/**
 * Judges option type for display.
 *
 * @param type string Question type.
 * @param withPostFix boolean helps judging if option is saved on server.
 * @returns string Judging identifier for option type.
 */
export const optionKey = (type: string, withPostFix?: boolean) => {
  switch (type) {
    case EnumQuestionTypes.RANKING:
    case EnumQuestionTypes.SINGLE_CHOICE:
    case EnumQuestionTypes.MULTIPLE_CHOICE:
      return withPostFix ? "options_saved" : "options";
    case EnumQuestionTypes.RATING:
      return withPostFix ? "ratings_saved" : "ratings";
    case EnumQuestionTypes.SLIDER:
      return withPostFix ? "steps_saved" : "steps";
    case EnumQuestionTypes.BOOLEAN:
      return withPostFix ? "booleans_saved" : "booleans";
    case EnumQuestionTypes.MATRIX:
      return withPostFix ? "metrices_saved" : "metrices";
    default:
      return withPostFix ? "options_saved" : "options";
  }
};

/**
 * Filter a Pillar's questions.
 *
 * @param questionIds array
 * @param questions object
 * @returns object
 */
const findPillarQuestions = (questionIds: Array<string>) => {
  let pillarQuestions: any = {};

  for (const id of questionIds) {
    pillarQuestions = {
      ...pillarQuestions,
      [id]: nestedQuestions[id as keyof typeof nestedQuestions],
    };
  }

  return pillarQuestions;
};

/**
 * Find object by key from an Array/Object of objects.
 * General Idea.
 *
 * @param haystack any
 * @param needle any
 * @param key string|number
 */
const findByKey = (haystack: any, needle: any, key: string | number = "id") => {
  return (
    Object.entries(haystack).find((hay: any) => hay[key] == needle) ?? null
  );

  // if (typeof haystack == "array") {
  //   return haystack.find((hay: any) => hay[key] == needle) ?? null;
  // } else if (typeof haystack == "object") {
  //   for (let index in haystack) if (index == needle) return haystack[index];
  // }
};

/**
 * Transform a single Question.
 *
 * @param key
 * @param question
 * @param values
 * @returns
 */
export const QuestionTranformer = (key: string, question: any, values: any) => {
  let options: any;

  if (question.optionType === "matrix") {
    const {
      rows,
      columns,
      metrices,
      numberOfOptions: noOfOpts,
      numberOfTitles: noOfTls,
    } = GetTransformedMatrix(question);
    question = {
      ...question,
      metricOptions: columns,
      metricTitles: rows,
      numberOfOptions: noOfOpts,
      numberOfTitles: noOfTls,
      options: metrices,
    };
  }
  // console.log("question:>", question);

  options = question.options.map((option: any) => {
    let tags = option?.tags || [];
    tags = tags?.map((tagItem: any) => tagItem?.tagId);

    return {
      ...option,
      savedOnServer: EnumBoolean.YES,
      tags,
      emoji: option?.emoji || null,
    };
  });

  const savedOptionType = `${optionKey(question?.optionType, true)}`;
  const optionType = `${optionKey(question?.optionType)}`;

  return {
    ...question,
    subQuestions: values.subQuestions,
    [savedOptionType]: options,
    [optionType]: options,
    parentOptionId: question?.parentOptionIds || undefined,
    categoryId: question?.parentOptionId ? undefined : question.categoryId,
    area: question.tagId,
    hierarchy: question?.parentOptionId
      ? EQuestionHierarchy.SUB_QUESTION
      : EQuestionHierarchy.PILLAR_QUESTION,
    savedOnServer: EnumBoolean.YES,
    SN: values.SN,
  };
};

export const mappedOptions = (
  items: TagOptionsProps[],
  selectedKeys: string[],
) => {
  const selectedItems: TagOptionsProps[] = [];
  const unselectedItems: TagOptionsProps[] = [];

  items?.map((item: TagOptionsProps) => {
    if (selectedKeys.includes(item.value)) {
      selectedItems.push(item);
    } else unselectedItems.push(item);
  });
  const sortedselectedItems = selectedItems?.sort(
    (a: TagOptionsProps, b: TagOptionsProps) => {
      return a?.label?.localeCompare(b?.label);
    },
  );
  const sortedUnselectedItems = unselectedItems?.sort(
    (a: TagOptionsProps, b: TagOptionsProps) => {
      return a?.label?.localeCompare(b?.label);
    },
  );
  return [...sortedselectedItems, ...sortedUnselectedItems];
};

export default AssessmentTransformer;
