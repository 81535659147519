import { Space, Typography } from "antd";
import Icon from "@ant-design/icons";

import CollapseAble from "features/assessment/questions/collapseables/CollapseAble";
import ContainerHeading from "features/assessment/questions/ContainerHeading";
import { HamburgerIcon } from "assets/icons/HamburgerIcon";
import styles from "features/assessment/css/AssessmentPillerHeading.module.scss";
import EnumAssessmentTypes from "features/assessment/types/EnumAssessmentTypes";
import { useTranslation } from "react-i18next";
import { CONFIG } from "helpers/constants";

const { Text } = Typography;
const baseImgUrl =
  process.env.REACT_APP_BASE_IMAGE_URL || CONFIG.BASE_IMAGE_URL;

/**
 * Show Questions count in collapsable panel heading for
 * specific assessment types.
 */
const showQuestionsCountFor = [
  // EnumAssessmentTypes.ONBOARDING_ASSESSMENT,
  // EnumAssessmentTypes.VIWELL_ASSESSMENT,
  "OnBoarding Assessment",
  "Viwell Assessment",
];

interface AssessmentPillerHeadingProps {
  title: string;
  subtitle?: string;
  assessmentType: string;
  questionsCount?: string | number;
  isSortable?: boolean;
  icon?: any;
}

const AssessmentPillerHeading: React.FC<AssessmentPillerHeadingProps> = (
  props,
) => {
  const imgSrc = typeof props.icon === "string" ? props?.icon : "";
  const { t } = useTranslation();

  return (
    <Space className={styles["assessment-create-question-heading"]}>
      {props?.isSortable && (
        <Icon
          component={HamburgerIcon}
          className={styles["assessment-create-question-heading-controller"]}
        />
      )}

      {!!imgSrc && (
        <img
          src={`${baseImgUrl}${imgSrc}`}
          className={styles["assessment-create-question-heading-icon"]}
          alt=""
        />
      )}

      <ContainerHeading text={props?.title} />

      {showQuestionsCountFor.includes(props.assessmentType) && (
        <Text type="secondary">
          {props?.questionsCount || 0} {t("questions")}
        </Text>
      )}
    </Space>
  );
};

export default AssessmentPillerHeading;
