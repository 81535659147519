export const MetricIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect opacity="0.24" width="24" height="24" rx="4" fill="#7573E1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8333 16.2857C17.8333 16.68 17.4592 17 17 17H11.9999V12H17.8333V7.7042C17.8333 7.70754 17.8333 7.71089 17.8333 7.71425V16.2857ZM11.9999 6.99996H7C6.54083 6.99996 6.16667 7.31996 6.16667 7.71425V12H11.9999V6.99996ZM17 5.33334H7C5.62167 5.33334 4.5 6.33037 4.5 7.55556V16.4444C4.5 17.6696 5.62167 18.6667 7 18.6667H17C18.3783 18.6667 19.5 17.6696 19.5 16.4444V7.55556C19.5 6.33037 18.3783 5.33334 17 5.33334Z"
        fill="#303030"
      />
    </svg>
  );
};
