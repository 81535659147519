import { FunctionComponent } from "react";
import styles from "features/events/css/EventAdd.module.scss";
import { t } from "i18next";
import CollapseAble from "features/assessment/questions/collapseables/CollapseAble";
import ContainerHeading from "features/assessment/questions/ContainerHeading/ContainerHeading";
import CustomImageUpload from "components/form/custom-upload-file/CustomImageUpload";

interface EventThumbnailProps {}

const EventThumbnail: FunctionComponent<EventThumbnailProps> = (props) => {
  return (
    <div className="event-detail-container">
      <CollapseAble
        defaultActiveKey={["event-thumbnail"]}
        headerKey="event-thumbnail"
        hideExtra
        expandIconPosition="end"
        header={<ContainerHeading text={t("event.details")} />}
      >
        <div className={styles["event-img"]}>
          <CustomImageUpload
            name="image"
            label={t("thumbnail-img")}
            infoText={t("thumbnail-help-text")}
          />
        </div>
      </CollapseAble>
    </div>
  );
};
export default EventThumbnail;
