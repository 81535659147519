import { Spin } from "antd";
import CustomCard from "components/custom-card/CustomCard";
import CustomDivider from "components/custom-divider/CustomDivider";
import { useTranslation } from "react-i18next";
import styles from "../../CompanyDashboard.module.scss";
import { formatPastDaysInsight, timeFilterOptions } from "../../utils";
import { InfoTextPopup } from "../InfoTextPopup";
import { NoData } from "../NoData";

export const List: React.FC<any> = ({
  item,
  className,
  extraSpacing,
  handleItemClick,
  infoTexts,
  visible,
  selectedInfoText,
  setSelectedInfoText,
}) => {
  const { t } = useTranslation();

  const pastTimeLabel = timeFilterOptions.find(
    (time) => time.value === item.selectedValues?.pastDays,
  );

  return (
    <>
      <CustomCard
        bordered={false}
        className={`${styles["section-card"]} ${className}`}
      >
        <div
          className={`${styles["content-container"]} ${styles["content-container-height"]}`}
        >
          <div className={styles["section-item-container"]}>
            <div className={styles["item-header"]}>{item.title}</div>
            <div>
              <InfoTextPopup
                items={infoTexts}
                visible={visible || selectedInfoText !== item.type}
                handleSelectedInfoText={(reset?: boolean) =>
                  setSelectedInfoText(reset ? "" : item.type)
                }
              />
            </div>
          </div>
          {item?.content?.data?.length &&
          !item?.content?.isDataConcealed &&
          !item?.isLoading ? (
            <>
              <div
                className={`${styles["margin-top-16"]} ${styles["pillar-list-container"]}`}
              >
                <div className={`${styles["pillar-content-item-container"]}`}>
                  <div className={styles["pillar-left-container"]}>
                    <div className={`${styles["content-item-text"]}`}>
                      {item.type}
                    </div>
                  </div>
                  <div className={styles["pillar-content-container"]}>
                    <div className={`${styles["pillars-header-text"]}`}>
                      {t("percentage")}
                    </div>
                    <div className={styles["pillars-header-text"]}>
                      {t("interactions")}
                    </div>
                  </div>
                </div>
                <div className={styles["pillar-content-height"]}>
                  {item?.content?.data?.map((item: any, index: number) => (
                    <div>
                      <CustomDivider className={styles["divider-color"]} />
                      <div
                        className={`${
                          styles["pillar-content-item-container"]
                        } ${handleItemClick ? "cursor-pointer" : ""} ${
                          extraSpacing ? styles["content-height"] : ""
                        }`}
                        onClick={() => handleItemClick?.(item)}
                      >
                        <div className={styles["pillar-left-container"]}>
                          {item.icon ? (
                            <img
                              className={styles["margin-right-8"]}
                              src={item.icon}
                              alt="No Data"
                            />
                          ) : null}

                          <div className={`${styles["content-index"]}`}>
                            {item.name}
                          </div>
                        </div>
                        <div className={styles["pillar-content-container"]}>
                          <div className={`${styles["pillars-content-text"]}`}>
                            {`${((item.value / item.total) * 100).toFixed(0)}%`}
                          </div>
                          <div
                            className={styles["pillars-content-text"]}
                          >{`${item.value.toLocaleString("en-US")}`}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles["percentange-container"]}>
                <div className={styles["checkin-past-days"]}>
                  {`${pastTimeLabel?.label}: ${formatPastDaysInsight(
                    item.selectedValues,
                  )}`}
                </div>
              </div>
            </>
          ) : (
            <div className={`${styles["content-nodata-container-height"]}`}>
              {item?.isLoading ? (
                <Spin spinning={item?.isLoading} />
              ) : (
                <NoData
                  handleClick={item?.handleRefresh}
                  btnText={t("refresh")}
                  title={
                    item?.content?.isDataConcealed
                      ? t("data-concealed-for-privacy")
                      : `${t("not-enough-data-for")} ${
                          pastTimeLabel?.description
                        }`
                  }
                />
              )}
            </div>
          )}
        </div>
      </CustomCard>
    </>
  );
};
