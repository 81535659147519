import CollapseAble from "features/assessment/questions/collapseables";
import ContainerHeading from "features/assessment/questions/ContainerHeading";
import { FieldArray } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styles from "features/events/css/EventAdd.module.scss";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import DeleteBtn from "components/custom-button/DeleteBtn";
import AddOption from "components/custom-button/AddOption";
import { DeleteFilled } from "@ant-design/icons";
import ViwellColorPicker from "components/form/custom-color-picker/ViwellColorPicker";
import { getRandomColor } from "helpers";
import { CreateEventInput } from "graphql/_generated/graphql";

interface TeamManagementProps {
  values: CreateEventInput;
}

const TeamManagement: React.FunctionComponent<TeamManagementProps> = ({
  values,
}) => {
  const { t } = useTranslation();
  return (
    <CollapseAble
      defaultActiveKey={["team-management"]}
      headerKey="team-management"
      hideExtra
      expandIconPosition="end"
      header={<ContainerHeading text={t("team-management")} />}
    >
      <div className={styles["team-content"]}>
        <FieldArray
          name="teams"
          render={({ push, remove }) => (
            <>
              {values &&
              values.teams &&
              Array.isArray(values.teams) &&
              values.teams.length
                ? values.teams.map((item, index) => (
                    <div className={styles["team-item"]} key={index}>
                      <CustomTextField
                        name={`teams.${index}.title`}
                        label={t("team-name", { index: index + 1 })}
                        placeholder={t("enter-title")}
                      />
                      <ViwellColorPicker
                        name={`teams.${index}.color`}
                        showColorInput={false}
                      />
                      {index > 0 && (
                        <div className={styles["delete-btn"]}>
                          <DeleteBtn onClick={() => remove(index)}>
                            <DeleteFilled />
                          </DeleteBtn>
                        </div>
                      )}
                    </div>
                  ))
                : null}
              <AddOption
                title={t("add-team")}
                onClick={() => push({ title: "", color: getRandomColor() })}
              />
            </>
          )}
        />
      </div>
    </CollapseAble>
  );
};

export default TeamManagement;
