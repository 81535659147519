import { Col, Row } from "antd";
import { ChallengeRewardsPointSvg, EditorSvg } from "assets/icons";
import { InfoIcon } from "assets/icons/InfoIcon";
import uploadImgPlaceholder from "assets/images/imgPlaceHolder.svg";
import CustomButton from "components/custom-button/CustomButton";
import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { CustomCheckBoxGroup } from "components/form/custom-checkbox-group/CustomCheckBoxGroup";
import { CustomDatePicker } from "components/form/custom-date-picker/CustomDatePicker";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import CustomTextEditor from "components/form/custom-text-editor/CustomTextEditor";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import CustomTreeSelect from "components/form/custom-tree-select/CustomTreeSelect";
import { CustomUploadFile } from "components/form/custom-upload-file/CustomUploadFile";
import ContainerHeading from "features/assessment/questions/ContainerHeading";
import { ErrorMessage, Form, Formik } from "formik";
import {
  RewardPartnerStockTypes,
  RewardVisibility,
} from "graphql/_generated/graphql";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { addPartnerRewardSchema } from "validations/schema/addPartnerRewardSchema";
import styles from "../css/AddPartnerReward.module.scss";
import { useAddPartnerRewardViewModel } from "../hooks/useAddPartnerRewardViewModel";
import useStockEditor from "../hooks/useStockEditor";
import StockEditor from "./StockEditor";

interface AddPartnerRewardProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  editId?: string | null;
  setEditId: React.Dispatch<React.SetStateAction<string | null>>;
  langCode?: string;
  isAddLanguage?: boolean;
  partnerId: string;
}

const AddPartnerReward: React.FunctionComponent<AddPartnerRewardProps> = ({
  visible,
  editId,
  isAddLanguage,
  ...props
}) => {
  const { t } = useTranslation();

  const vm = useAddPartnerRewardViewModel({
    visible,
    editId,
    isAddLanguage,
    ...props,
  });
  const vmstockEditor = useStockEditor();

  return (
    <CustomModal
      visible={visible}
      onCancel={vm.handleCancel}
      width={654}
      footer={false}
      title={
        editId && !isAddLanguage
          ? t("edit-reward")
          : `${t("add-reward")} ${isAddLanguage ? t("language") : ""}`
      }
    >
      <Formik
        enableReinitialize
        initialValues={vm.initialValues}
        validationSchema={addPartnerRewardSchema}
        onSubmit={vm.onSubmit}
      >
        {({ values, setFieldValue, errors }) => (
          <Form>
            <div className="challenge-detail-container reward-image-container">
              {!isAddLanguage && (
                <CustomUploadFile
                  infoText={t("reward-image")}
                  label={t("reward-image")}
                  setFieldValue={setFieldValue}
                  name="image"
                  accept="image/png, .jpg, .jpeg"
                  customUploadText={
                    <span>
                      {t("drop-image")} {t("or")} <br />
                      <b>{t("select-to-browse")}</b>
                    </span>
                  }
                  customUploadImgSrc={uploadImgPlaceholder}
                  showFullPreview={true}
                  imgUrl={values.image}
                />
              )}
            </div>
            <CustomTextField
              name="name"
              label={t("name")}
              placeholder={t("enter-name")}
            />
            <CustomTextEditor
              name="description"
              label={t("description")}
              placeholder={t("enter-desc")}
              isRestrictAccess={true}
            />
            {!isAddLanguage && (
              <>
                <CustomSelectField
                  name="rewardTypeId"
                  label={t("reward-type")}
                  placeholder={t("select-reward-type")}
                  options={vm.rewardTypesData?.getConfigRewardTypes.options}
                  fieldNames={{ label: "label", value: "id" }}
                />
                <CustomSelectField
                  name="tierId"
                  label={t("tier")}
                  placeholder={t("select-tier")}
                  options={vm.tiersData?.getConfigTiers.options}
                  fieldNames={{ label: "label", value: "id" }}
                />
                <CustomSelectField
                  name="rewardProviderId"
                  label={t("provider")}
                  placeholder={t("select-provider")}
                  options={vm.providerData?.getConfigProviders.options}
                  fieldNames={{ label: "label", value: "id" }}
                />
                <CustomSelectField
                  name="rewardCategoryId"
                  label={t("reward-category")}
                  placeholder={t("select-reward-category")}
                  options={
                    vm.rewardCategoriesData?.getConfigRewardCategories.options
                  }
                  fieldNames={{ label: "label", value: "id" }}
                />
                <CustomSelectField
                  name="companyVisibility"
                  placeholder={t("select-visibility")}
                  label={t("company-visibility")}
                  options={vm.rewardVisibility}
                />
                <ContainerHeading
                  text={t("price-viwell-points")}
                  infoText={t("price-per-points-info-text")}
                  className={"input-field-heading"}
                  icon={InfoIcon}
                />
                <CustomTextField
                  name="price"
                  placeholder={t("price")}
                  type="number"
                  min={0}
                  max={99999}
                  icon={<ChallengeRewardsPointSvg />}
                />
                {values.companyVisibility === RewardVisibility.Company && (
                  <CustomTreeSelect
                    treeData={vm.clientsData?.getClientsTree}
                    allowClear
                    showArrow
                    multiple={true}
                    showSearch={true}
                    treeNodeFilterProp="companiesList"
                    treeLine={true && { showLeafIcon: false }}
                    label={t("companies")}
                    className="form-select"
                    name="companyIds"
                    placeholder={t("select-companies")}
                    fieldNames={{ label: "companyName", value: "id" }}
                  />
                )}

                <div className="form-control">
                  <CustomCheckBoxGroup
                    name="isDateEnable"
                    onChange={(e) => {
                      setFieldValue("isDateEnable", e.target.checked);
                      setFieldValue("startDate", undefined);
                      setFieldValue("endDate", undefined);
                    }}
                    options={[]}
                  >
                    {t("start-end-date")}
                  </CustomCheckBoxGroup>
                </div>
                {values.isDateEnable && (
                  <Row gutter={[12, 0]}>
                    <Col span={12}>
                      <CustomDatePicker
                        name="startDate"
                        label={t("start-date")}
                        placeholder={t("select-start-date")}
                        disabledDate={(current) => {
                          return (
                            moment().add(-1, "days") >= current ||
                            current >=
                              moment(values.endDate, "YYYY-MM-DD").add(
                                "days",
                                1,
                              )
                          );
                        }}
                      />
                    </Col>
                    <Col span={12}>
                      <CustomDatePicker
                        name="endDate"
                        label={t("end-date")}
                        placeholder={t("select-end-date")}
                        disabledDate={(current) => {
                          return (
                            moment().add(-1, "days") >= current ||
                            current < moment(values.startDate, "YYYY-MM-DD")
                          );
                        }}
                      />
                    </Col>
                  </Row>
                )}
                <div className="form-control">
                  <CustomCheckBoxGroup
                    name="isRedemptionLimitsEnable"
                    options={[]}
                    onChange={(e) => {
                      setFieldValue(
                        "isRedemptionLimitsEnable",
                        e.target.checked,
                      );
                      setFieldValue("redemptionLimits", undefined);
                    }}
                  >
                    {t("activate-redemption-limits")}
                  </CustomCheckBoxGroup>
                </div>
                {values.isRedemptionLimitsEnable && (
                  <>
                    <ContainerHeading
                      text={t("redemption-limits")}
                      infoText={t("redemption-limit-info-text")}
                      className={"input-field-heading"}
                      icon={InfoIcon}
                    />
                    <CustomTextField
                      name="redemptionLimits"
                      placeholder={t("redemption-limits")}
                      type="number"
                      min={1}
                      max={9999}
                    />
                  </>
                )}
                <div className="form-control">
                  <CustomCheckBoxGroup
                    name="isCreateStockEnable"
                    options={[]}
                    disabled={!!editId}
                    onChange={(e) => {
                      setFieldValue("isCreateStockEnable", e.target.checked);
                      setFieldValue("stockType", undefined);
                      setFieldValue("stockLimit", undefined);
                      setFieldValue("availableRewardStocks", []);
                    }}
                  >
                    {t("create-stock")}
                  </CustomCheckBoxGroup>
                </div>
                {values.isCreateStockEnable && (
                  <>
                    <CustomSelectField
                      name="stockType"
                      placeholder={t("stock-type")}
                      label={t("stock-type")}
                      disabled={!!editId}
                      options={vm.rewardPartnerStockTypes}
                      onChange={(value) => {
                        setFieldValue("stockType", value);
                        setFieldValue("stockLimit", undefined);
                        setFieldValue("availableRewardStocks", []);
                      }}
                    />
                    {errors.availableRewardStocks ? (
                      <ErrorMessage
                        name={"availableRewardStocks"}
                        render={(msg) => (
                          <div className="text-danger">
                            {t("individual-stock-required")}
                          </div>
                        )}
                      />
                    ) : vm.isAvailableRewardCodesEmpty(values) ? (
                      <div className="text-danger">
                        {t("atleast-one-stock-required")}
                      </div>
                    ) : null}
                    {values.stockType ===
                      RewardPartnerStockTypes.Cumulative && (
                      <>
                        <ContainerHeading
                          text={t("stock-limit")}
                          infoText={t("stock-limit-info-text")}
                          className={"input-field-heading"}
                          icon={InfoIcon}
                        />
                        <CustomTextField
                          name="stockLimit"
                          placeholder={t("stock-limit")}
                          type="number"
                          min={1}
                          max={9999}
                        />
                      </>
                    )}
                    {values.stockType ===
                      RewardPartnerStockTypes.Individual && (
                      <>
                        <div className={styles["stock-editor-container"]}>
                          <CustomButton
                            className={`light-btn ${styles["editor-btn"]}`}
                            icon={<EditorSvg />}
                            onClick={() => vmstockEditor.toggleEditorState()}
                          >
                            {t("stock-editor")}
                          </CustomButton>
                        </div>
                        <StockEditor
                          visible={vmstockEditor.editorVisible}
                          setVisible={vmstockEditor.toggleEditorState}
                          values={values}
                        />
                      </>
                    )}
                  </>
                )}
              </>
            )}
            <ModalFooter
              saveText={t("offers.save-reward")}
              onCancel={vm.handleCancel}
              loading={vm.isLoading}
              disabled={
                vm.handleDisabled(values) ||
                vm.isAvailableRewardCodesEmpty(values)
              }
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddPartnerReward;
