import {
  SetHabitTranslationMutationVariables,
  useSetHabitTranslationMutation,
} from "graphql/_generated/graphql";

export const useSetHabitTranslation = () => {
  const [habitTranslationFun, { isLoading: isLoadingSetHabitTranslation }] =
    useSetHabitTranslationMutation();

  const handleSetHabitTranslation = async (
    values: SetHabitTranslationMutationVariables,
  ) => {
    try {
      const res = await habitTranslationFun({
        setHabitTranslationId: values.setHabitTranslationId,
        translationInput: values.translationInput,
      }).unwrap();
      return res.setHabitTranslation;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleSetHabitTranslation,
    isLoadingSetHabitTranslation,
  };
};
