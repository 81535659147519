import * as React from "react";
import ContainerHeading from "features/assessment/questions/ContainerHeading/ContainerHeading";
import CollapseAble from "features/assessment/questions/collapseables/CollapseAble";
import { useTranslation } from "react-i18next";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import { PERMS } from "helpers";
import styles from "features/events/css/TeamLeaderBoard.module.scss";
import { List, Modal } from "antd";
import TeamLeaderBoardItem from "./TeamLeaderBoardItem";
import CustomButton from "components/custom-button/CustomButton";
import EditTeamScores from "./EditTeamScores";
import { CONFIRM_MODAL_CONFIG } from "helpers/constants";
import { useTeamLeaderboardViewModel } from "features/events/hooks/useTeamLeaderboardViewModel";
import CustomPagination from "components/custom-pagination/CustomPagination";
import EditTeam from "./EditTeam";
import UnAssignedMembers from "./UnAssignedMembers";
import ChangeTeam from "./ChangeTeam";
interface TeamLeaderBoardProps {
  eventId: string;
  onLeaderboardUpdated?: (() => void) | null;
}

const TeamLeaderBoard: React.FunctionComponent<TeamLeaderBoardProps> = ({
  eventId,
  onLeaderboardUpdated,
}) => {
  const { t } = useTranslation();
  const vm = useTeamLeaderboardViewModel({
    eventId,
    onUpdatedLeaderboard: onLeaderboardUpdated,
  });
  const memberCount = vm.memberCount;
  const handleAutoAssign = () => {
    Modal.confirm({
      ...CONFIRM_MODAL_CONFIG,
      closable: true,
      centered: true,
      title: t("auto-assign-team"),
      okText: t("confirm"),
      content: (
        <>
          <div className="confirm-content">
            {t("auto-assign-desc", { memberCount })}
          </div>
        </>
      ),
      width: 506,
      onOk: vm.onSubmitAssignAutomatically,
    });
  };
  return (
    <CollapseAble
      defaultActiveKey={["team-leaderboard "]}
      headerKey="team-leaderboard "
      hideExtra={false}
      expandIconPosition="end"
      header={<ContainerHeading text={t("leaderboard-details")} />}
      extraProps={
        <CheckPermissions permission={PERMS.UPDATE_EVENT_SCORES} /> && {
          handleEdit: vm.onPressEditTeamScore,
        }
      }
    >
      <div className={styles["auto-btn"]}>
        <CustomButton
          disabled={!!!memberCount}
          className="primary-btn"
          onClick={handleAutoAssign}
        >
          {t("auto-assign-team")}
        </CustomButton>
      </div>
      <div className={styles["leaderboard-container"]}>
        <List
          loading={vm.isLoading.isLoadingTeam.isLoadingTeamLeaderboard}
          size="small"
          itemLayout="horizontal"
          dataSource={vm.teamLeaderboardData?.data}
          renderItem={(item, index) => (
            <TeamLeaderBoardItem
              eventId={eventId}
              itemKey={Number(item.rank)}
              id={item.id}
              color={item.color}
              score={item.eventScore}
              teamTitle={item.title}
              members={item.memberCount ?? 0}
              onPressTeamMember={vm.onPressTeamMember}
            />
          )}
        />
      </div>
      <div className={styles["pagination-margin"]}>
        <CustomPagination
          responsive
          showSizeChanger
          current={vm.currentPage}
          total={vm.teamLeaderboardData?.total}
          onChange={vm.onChangePagination}
        />
      </div>
      {vm.teamScoreVisibility && (
        <EditTeamScores
          editScoreVisibility={vm.teamScoreVisibility}
          onCloseEditTeamScore={vm.onPressEditTeamScore}
          TeamsData={vm.teamLeaderboardData}
          loading={vm.isLoading.isLoadingTeam.isLoadingUpdateTeamScore}
          currentPage={vm.currentPage}
          onChangePagination={vm.onChangePagination}
          onSubmitUpdateTeamScore={vm.onSubmitUpdateTeamScore}
        />
      )}
      {vm.editTeamVisibility && (
        <EditTeam
          isLoadingRemoveTeam={
            vm.isLoading.isLoadingTeam.isLoadingRemoveTeamMember
          }
          eventId={eventId}
          teamEventMembersData={vm.teamEventMembersData}
          teamMemberVisibility={vm.editTeamVisibility}
          onCloseTeamMember={vm.onCloseEditTeam}
          teamEventMembersFiltersCallBack={vm.teamEventMembersFiltersCallBack}
          loading={vm.isLoading.isLoadingTeamEventMembers}
          teamEventMemberCurrentPage={vm.teamEventMemberCurrentPage}
          teamEventMemberPageSize={vm.teamEventMemberPageSize}
          teamEventMemberRowSelection={vm.teamEventMemberRowSelection}
          teamEventMemberSelectedKeys={vm.teamEventMemberSelectedKeys}
          onSubmitRemoveTeamMember={vm.onSubmitRemoveTeamMember}
          onPressAddMember={vm.onPressAddMember}
          onPressChangeTeam={vm.onPressChangeTeam}
        />
      )}
      {vm.addMemberVisibility && (
        <UnAssignedMembers
          isLoadingMembers={vm.isLoading.isLoadingTeamEventMembers}
          visible={vm.addMemberVisibility}
          onClose={vm.onCloseAddMember}
          loading={vm.isLoading.isLoadingTeam.isLoadingAssignToTeams}
          currentPage={vm.membersCurrentPage}
          pageSize={vm.membersPageSize}
          membersData={vm.MembersData}
          membersFiltersCallBack={vm.membersFiltersCallBack}
          membersRowSelection={vm.membersRowSelection}
          initialValues={vm.memberInitialValues}
          onSubmitAddMember={vm.onSubmitAddMember}
        />
      )}
      {vm.isChangeTeamVisible && (
        <ChangeTeam
          visible={vm.isChangeTeamVisible}
          onCancel={vm.onCloseChangeTeam}
          teamsData={vm.teamLeaderboardData}
          isLoading={vm.isLoading.isLoadingTeam.isLoadingTeamLeaderboard}
          current={vm.currentPage}
          onChange={vm.onChangePagination}
          onSubmitChangeTeam={vm.onSubmitAddMember}
          eventId={eventId}
          teamId={vm.teamId ?? ""}
          participantIds={vm.teamEventMemberSelectedKeys}
          isLoadingChangeTeam={
            vm.isLoading.isLoadingTeam.isLoadingAssignToTeams
          }
        />
      )}
    </CollapseAble>
  );
};

export default TeamLeaderBoard;
