import { useAddDepartment } from "./add-department/useAddDepartment";
import { useUpdateDepartment } from "./add-department/useUpdateDepartment";
import { useToggleActivationDepartment } from "./department-list/useToggleActivationDepartment";

export const useDepartment = () => {
  const { handleAddDepartment, isLoading } = useAddDepartment();

  const {
    handleFetchPreviousDepartmentData,
    handleUpdateDepartment,
    isLoading: updateDepartmentLoading,
  } = useUpdateDepartment();

  const {
    handleDeActivationDepartment,
    handleRemoveDepartment,
    isDeptLoading,
    isLoading: toggleActivationDepartmentLoading,
  } = useToggleActivationDepartment();

  return {
    isLoading:
      isLoading ||
      updateDepartmentLoading ||
      toggleActivationDepartmentLoading ||
      isDeptLoading,
    handleAddDepartment,
    handleFetchPreviousDepartmentData,
    handleUpdateDepartment,
    handleRemoveDepartment,
    handleDeActivationDepartment,
  };
};
