// navigationSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultPagination } from "hooks/usePagination";

interface NavigationState {
  pagination: {
    [key: string]: {
      offset: {
        page: number;
        pageSize: number;
      };
    };
  };
  filters: any;
  search: {
    [key: string]: {} | undefined;
  };
}

const initialState: NavigationState = {
  pagination: {},
  filters: {},
  search: {},
};

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<any>) => {
      const { filters, location } = action.payload;
      const statePath =
        location.hash && location.hash.includes("tab")
          ? location.hash
          : location.pathname;
      const routeState = state.filters[statePath] || {};

      state.filters[statePath] = {
        ...routeState,
        ...filters,
      };
    },
    setSearch: (state, action: PayloadAction<any>) => {
      const { search, location } = action.payload;
      const routeState = state.search[location.pathname] || {};

      state.search[location.pathname] = {
        ...routeState,
        ...{
          search,
        },
      };
    },
    setPagination: (
      state,
      action: PayloadAction<{
        current: number;
        pageSize: number;
        location: any;
      }>,
    ) => {
      const { current, pageSize, location } = action.payload;
      const routeState = state.pagination[location.pathname];

      state.pagination[location.pathname] = {
        ...routeState,
        offset: { page: current, pageSize },
      };
    },
    resetStateForPath: (state, action) => {
      const { payload: pathname } = action;
      state.filters[pathname] = initialState.filters;
      state.search[pathname] = initialState.filters;
      state.pagination[pathname] = defaultPagination;
    },
  },
});

export const { setPagination, setFilters, setSearch, resetStateForPath } =
  navigationSlice.actions;

export default navigationSlice.reducer;
