import * as XLSX from "xlsx";

export async function excelToJson<T extends Array<any>>(file: File): Promise<T> {
    const data = await file.arrayBuffer();
      const workbook = XLSX.read(data);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData: T = XLSX.utils.sheet_to_json(worksheet, {
        raw: false,
      }) as T;

      return jsonData;
}