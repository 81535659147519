import {
  GetPopularCategoriesQueryVariables,
  useLazyGetPopularCategoriesQuery,
} from "graphql/_generated/graphql";

export const useGetPopularCategories = () => {
  const [popularCategoriesFun, { isFetching }] =
    useLazyGetPopularCategoriesQuery();

  const handleGetPopularCategories = async (
    values: GetPopularCategoriesQueryVariables,
  ) => {
    try {
      const res = await popularCategoriesFun(values).unwrap();

      return res.getPopularCategories;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleGetPopularCategories,
    isLoading: isFetching,
  };
};
