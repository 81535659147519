import {
  useToggleOfferActivationMutation as useToggle,
} from "graphql/_generated/graphql";

export type UseToggleOffer = () => {
  isLoading: boolean;
  handleToggleOffer: (id: any) => Promise<any>;
};

export const useToggleOffer: UseToggleOffer = () => {
  const [toggleFunc, { isLoading }] = useToggle();

  // handler function
  const handleToggleOffer = async (id: any) => {
    try {
      const res = await toggleFunc({ toggleOfferActivationId: id }).unwrap();
      return res;
    } catch (error: any) {
      throw error;
    }
  };

  return { isLoading, handleToggleOffer };
};
