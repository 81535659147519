import { Upload } from "antd";
import fileIcon from "assets/images/fileupload.png";
import axios from "axios";
import { ErrorMessage } from "formik";
import { useGetUploadDetailsForSubtitleMutation } from "graphql/_generated/graphql";
import { useState } from "react";
import { useErrorTranslation } from "validations/schema/common/useErrorTranslation";
import styles from "../custom-upload-file/CustomUploadFile.module.scss";

type Props = {
  name: string;
  label?: string;
  setFieldValue: any;
  title?: string;
  description?: string;
  disable?: boolean;
  videoId?: string;
  language: string;
  index: number;
  imgUrl?: string;
  localizedVideos?: { language: string; url: string }[];
  accept?: string;
};

export function CustomSrtUpload(props: Props) {
  const { errorT } = useErrorTranslation();
  const [error, setError] = useState<string | null>("");
  const [defaultFileList, setDefaultFileList] = useState([]);
  const [progress, setProgress] = useState(0);
  const { name, label } = props;
  const [getUploadInfoFun] = useGetUploadDetailsForSubtitleMutation();

  //this function is responsible uploading the srt file on vimeo
  const uploadProps = {
    multiple: false,
    accept: props.accept,
    listType: "picture-card" as "picture-card",
    onStart(file: any) {
      var reader: any = new FileReader();
      reader.onload = function () {
        var aud = new Audio(reader.result);
        aud.onloadedmetadata = function () {
          props.setFieldValue("duration", aud.duration);
        };
      };
      reader.readAsDataURL(file);
    },
    onProgress(event: { percent: number }) {},
    onChange({ fileList }: any) {
      setDefaultFileList(fileList);
    },
    onRemove() {
      props.setFieldValue(name, "");
      setError(null);
    },
    async customRequest({ onSuccess, onError, file, onProgress }: any) {
      const extension = file.name.split(".").pop().toLowerCase();

      const config = {
        onUploadProgress: (event: any) => {
          const percent = Math.floor((event.loaded / event.total) * 100);
          setProgress(percent);
          if (percent === 100) {
            setTimeout(() => setProgress(0), 1000);
          }
          onProgress({ percent: (event.loaded / event.total) * 100 });
        },
      };
      try {
        const res = await getUploadInfoFun({
          videoUri: props.videoId ? props.videoId.split("/").pop() : "",
          details: {
            language: props.language,
            name: "subtitle file for main video",
          },
        }).unwrap();

        // this code block only runs when we have localized videos array then in this code block we will call the apis on all the available videos
        if (props.localizedVideos && props.localizedVideos[0]?.url.length > 0) {
          props.localizedVideos.map(async (video) => {
            await getUploadInfoFun({
              videoUri: video?.url.split("/").pop(),
              details: {
                language: video.language,
                name: "subtitle file for localized video",
              },
            }).unwrap();
          });
        }

        const filePath = await axios.put(
          res.getUploadDetailsForSubtitle.uploadLink,
          file
        );

        props.setFieldValue(name, `${res.getUploadDetailsForSubtitle.uri}`);
        onSuccess("Ok");
      } catch (error) {}
    },
  };

  return (
    <div className={styles["form-control"]}>
      {label && (
        <label className="label" htmlFor={name}>
          {label}
        </label>
      )}
      <Upload
        {...uploadProps}
        maxCount={1}
        multiple={false}
        name={name}
        disabled={props.disable}
      >
        {defaultFileList.length >= 1 ? null : props.imgUrl ? (
          <div className="img-url">
            <img src={fileIcon} className="img-fluid" alt="srt icon" />
            {/* {props.imgUrl}   */}
          </div>
        ) : (
          <div>Upload File</div>
        )}
      </Upload>
      {/* this error message will show when error in the api */}
      {error ? (
        <ErrorMessage
          name={name}
          render={(msg) => <div style={{ color: "red" }}>{error}</div>}
        />
      ) : (
        ""
      )}

      {/* this error message will show if the validation not succeeded from the
      front end */}
      <ErrorMessage
        name={name}
        render={(msg) => <div style={{ color: "red" }}>{errorT(msg)}</div>}
      />
    </div>
  );
}
