import { Space, Switch, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import { customNotification } from "components/custom-notification/CustomNotification";
import CustomTable from "components/custom-table/CustomTable";
import CustomPopconfirm from "components/form/custom-pop-confirm/CustomPopConfirm";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import {
  useGetViwellAdminListQuery,
  useLazyGetViwellAdminListQuery,
  ViwellAdminList,
} from "graphql/_generated/graphql";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAdmin } from "../useAdmin";
import AddAdmin from "../add/AddAdmin";
import CustomDropDown from "components/custom-dropdown/CustomDropDown";
import CustomButton from "components/custom-button/CustomButton";
import { MoreOutlined } from "@ant-design/icons";
import { useResendInvite } from "features/users/hooks/useResendInvite";
import { useErrorHandler } from "hooks/useErrorHandler";
import { CONFIG, PERMS } from "helpers/constants";
import CheckPermissions from "components/check-permissions/CheckPermissions";

interface AdminListProps {}

const AdminList: React.FunctionComponent<AdminListProps> = () => {
  const { t } = useTranslation();
  const [filtersData, setFiltersData] = useState<any>(null);
  const [columnsData, setColumnsData] = useState<ViwellAdminList>();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const { handleToggleActivation } = useAdmin();
  const [visible, setVisible] = useState<boolean>(false);
  const [editId, setEditId] = useState<string | null>(null);

  const { isFetching, refetch } = useGetViwellAdminListQuery();
  const [getViwellAdminList] = useLazyGetViwellAdminListQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const { handleResendInvite } = useResendInvite();
  const { handleError } = useErrorHandler();

  useEffect(() => {
    fetchDataFromApi(getViwellAdminList, setColumnsData, filtersData).catch(
      (e) => handleError(e),
    );
  }, [isFetching]);

  const inviteAdmin = async (id: string) => {
    try {
      await handleResendInvite({ id });
      customNotification("success", t("resent-invite"));
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const handleMenu = ({
    key,
    id,
    record,
  }: {
    key: string;
    id: string;
    record: ViwellAdminList["data"][0];
  }) => {
    if (key === "resend-invite") {
      inviteAdmin(id);
    } else if (key === "edit-details") {
      setEditId(id);
      setVisible(true);
    }
  };

  const columns: ColumnsType<ViwellAdminList["data"][0]> = [
    {
      title: `${t("id")}`,
      key: "id",
      dataIndex: "id",
      sorter: {
        multiple: 1,
      },
      render: (_text, _record, index) =>
        (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: `${t("name")}`,
      key: "firstName",
      dataIndex: "firstName",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => (
        <Space>
          <CustomAvatar imgSrc={record.image ?? ""} />
          <span>
            {record.firstName} {record.lastName}
          </span>
        </Space>
      ),
    },
    {
      title: "Role",
      key: "roles",
      dataIndex: "roles",
      render: (_text, record, _index) =>
        record?.roles?.map((item) => <Space>{item?.name}</Space>),
    },
    {
      title: `${t("created-at")}`,
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: {
        multiple: 1,
      },
      render: (createdAt: string) =>
        moment(createdAt).format("MMMM Do YYYY, h:mm:ss a"),
    },
    {
      title: `${t("updated-at")}`,
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: {
        multiple: 1,
      },
      render: (updatedAt: string) =>
        moment(updatedAt).format("MMMM Do YYYY, h:mm:ss a"),
    },
    {
      title: `${t("status")}`,
      dataIndex: "isActive",
      key: "isActive",
      align: "center" as "center",
      render: (_text, record, _index) =>
        !record.isProfileComplete ? (
          <Tag className="pending-tag">{t("pending")}</Tag>
        ) : record.isActive ? (
          <Tag className="active-tag">{t("active")}</Tag>
        ) : (
          <Tag className="de-active-tag">{t("deactivated")}</Tag>
        ),
    },
    {
      title: `${t("activation")}`,
      dataIndex: "id",
      key: "id",
      align: "center" as "center",
      render: (id: string, { isActive }: any) => (
        <CheckPermissions permission={PERMS.TOGGLE_ADMIN_ACTIVATION}>
          <CustomPopconfirm
            onConfirm={async () => {
              try {
                const isActivityActive = await handleToggleActivation(id);

                customNotification(
                  "success",
                  isActivityActive
                    ? t("admin-activated-successfully")
                    : t("admin-deactivated-successfully"),
                );

                refetch();
              } catch (error) {
                handleError(error as string | object);
              }
            }}
            title={
              isActive
                ? t("are-you-sure-deactivate-admin")
                : t("are-you-sure-activate-admin")
            }
          >
            <Switch
              className="custom-switch"
              defaultChecked
              checked={isActive}
            />
          </CustomPopconfirm>
        </CheckPermissions>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "Actions",
      align: "center",
      render: (id: string, record) => (
        <CheckPermissions
          permission={PERMS.RESEND_INVITE || PERMS.UPDATE_VIWELL_ADMIN_PROFILE}
        >
          <CustomDropDown
            menu={{
              items: [
                {
                  key: "edit-details",
                  label: t("edit-details"),
                },
                ...(!record.isProfileComplete
                  ? [
                      {
                        key: "resend-invite",
                        label: t("resend-invite"),
                      },
                    ]
                  : []),
              ],
              onClick: ({ domEvent, key, keyPath }) =>
                handleMenu({ key, id, record }),
            }}
          >
            <CustomButton type="link" className="link-btn">
              <MoreOutlined />
            </CustomButton>
          </CustomDropDown>
        </CheckPermissions>
      ),
    },
  ];

  return (
    <>
      <CustomTable
        columns={columns}
        filters={{
          handleCallback: async (filtersData: any) => {
            await fetchDataFromApi(
              getViwellAdminList,
              setColumnsData,
              filtersData,
            );
            setPageSize(filtersData?.pagination?.offset?.pageSize);
            setCurrentPage(filtersData?.pagination?.offset?.page);
            setFiltersData(filtersData);
          },

          date: [
            {
              applyAs: "and",
              key: "createdAt",
              comparison: "Range",
            },
          ],
          status: {
            applyAs: "and",
            key: "isActive",
            comparison: "Equal",
          },
        }}
        totalItems={columnsData?.metadata.total}
        loading={isLoading}
        onClick={() => setVisible(true)}
        headerTitle={t("admins")}
        btnText={t("add-new-admin")}
        dataSource={columnsData?.data}
        rowKey="id"
      />
      {visible && (
        <AddAdmin
          visible={visible}
          setVisible={setVisible}
          editId={editId}
          setEditId={setEditId}
        />
      )}
    </>
  );
};

export default AdminList;
