import CustomImg from "components/custom-img/CustomImg";
import API from "config/api";
import ContainerHeading from "features/assessment/questions/ContainerHeading";
import CollapseAble from "features/assessment/questions/collapseables";
import detailStyles from "features/challenge/css/ActivityDetail.module.scss";
import styles from "features/challenge/css/AddActivity.module.scss";
import { renderHtmlContent } from "helpers";
import { FunctionComponent } from "react";

interface ChallengeDescriptionProps {
  challengeData?: any;
}

const Details: FunctionComponent<ChallengeDescriptionProps> = ({
  challengeData,
}) => {
  return (
    <CollapseAble
      defaultActiveKey={["description"]}
      headerKey={"description"}
      className={styles["activity-card"]}
      header={
        <div className="d-flex">
          <ContainerHeading text="Name & Description" />
        </div>
      }
      hideExtra={true}
      expandIconPosition="end"
    >
      <div
        className={`${detailStyles["desc-container"]} ${styles["content-card"]}`}
      >
        {challengeData?.image ? (
          <div className={detailStyles["bg-container"]}>
            <div
              className={detailStyles["bg-image"]}
              style={{
                backgroundImage: `url(${API.baseImgUrl}${challengeData?.image})`,
              }}
            ></div>
            <div className={`${detailStyles["bg-text"]}`}>
              <CustomImg
                imgSrc={challengeData?.image}
                height={270}
                width={480}
                alt="thumbnail image"
              />
            </div>
          </div>
        ) : null}

        <h1 className={detailStyles["activity-title"]}>
          {challengeData?.title}
        </h1>

        <p className={styles["name-desc"]}>{challengeData?.description}</p>
        <h1 className={detailStyles["title"]}>{"Challenge Rules"}</h1>
        <div className={detailStyles["rules-desc"]}>
          {renderHtmlContent(challengeData?.rules)}
        </div>
      </div>
    </CollapseAble>
  );
};
export default Details;
