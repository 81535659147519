import { useDeleteEventMutation } from "graphql/_generated/graphql";

export const useDeleteEvent = () => {
  const [deleteEventFn, { isLoading }] = useDeleteEventMutation();

  const deleteEvent = async (id: string) => {
    try {
      const res = await deleteEventFn({ event: { id } }).unwrap();
      return res.deleteEvent;
    } catch (error) {
      throw error;
    }
  };

  return {
    deleteEvent,
    isDeletingEvent: isLoading,
  };
};
