import { useToggleMeasurementActivationMutation } from "graphql/_generated/graphql";

export const useToggleMeasurement = () => {
  const [toggleUnitFun, { isLoading: isLoadingToggleUnit }] =
    useToggleMeasurementActivationMutation();

  const handleToggleUnit = async (id: string) => {
    try {
      const res = await toggleUnitFun({
        toggleMeasurementActivationId: id,
      }).unwrap();
      return res.toggleMeasurementActivation;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleToggleUnit,
    isLoadingToggleUnit,
  };
};
