export const ClipboardSvg = () => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66667 5.66699H11.3333C11.7933 5.66699 12.1667 5.29449 12.1667 4.83366V1.50033C12.1667 1.03949 11.7933 0.666992 11.3333 0.666992H4.66667C4.20667 0.666992 3.83333 1.03949 3.83333 1.50033V4.83366C3.83333 5.29449 4.20667 5.66699 4.66667 5.66699ZM13 4.83366V2.33366C14.3783 2.33366 15.5 3.45533 15.5 4.83366V14.8337C15.5 16.212 14.3783 17.3337 13 17.3337H3C1.62167 17.3337 0.5 16.212 0.5 14.8337V4.83366C0.5 3.45533 1.62167 2.33366 3 2.33366V4.83366C3 5.75283 3.7475 6.50033 4.66667 6.50033H11.3333C12.2525 6.50033 13 5.75283 13 4.83366Z"
        fill="#303030"
      />
    </svg>
  );
};
