import ExpertProfile from "pages/experts/ExpertProfile";
import CompanyProfile from "pages/company/CompanyProfile";
import PasswordChanged from "pages/auth/PasswordChanged";
import EmployeeProfile from "pages/users/EmployeeProfile";

// import ExpertDashboard from "app/components/expert-dashboard/ExpertDashboard";

const OnboardingRoutes = [
  {
    path: "/password-changed",
    element: <PasswordChanged />,
  },
  {
    path: "/company-profile",
    element: <CompanyProfile />,
  },
  {
    path: "/employee-profile",
    element: <EmployeeProfile />,
  },
  {
    path: "/expert-profile",
    element: <ExpertProfile />,
  },
];

export default OnboardingRoutes;
