import { DatePicker, Form, Input, Select } from "antd";
import { SearchIcon } from "assets/icons/SearchIcon";
import CustomTreeSelect from "components/form/custom-tree-select/CustomTreeSelect";
import styles from "features/challenge/css/ActivityDetail.module.scss";
import { Formik } from "formik";
import {
  ProgressStatus,
  useGetClientsTreeQuery,
} from "graphql/_generated/graphql";
import { useLoggedInUser } from "hooks/useLoggedInUser";
import { debounce } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
const { Option } = Select;
const { RangePicker } = DatePicker;

const DEBOUNCE_TIME = 500;

type Props = {
  handleSearchInput: any;
  setSelectedCompany?: any;
  isCompanyFilter?: boolean;
  handleStatus?: any;
  isStatusFilter?: boolean;
  handleRangeFilter?: any;
  isDateFilter?: boolean;
};

const DataTableFilters: React.FC<Props> = ({
  handleSearchInput,
  setSelectedCompany,
  isCompanyFilter = true,
  handleStatus,
  isStatusFilter = false,
  handleRangeFilter,
  isDateFilter = false,
}: any) => {
  const { isClient } = useLoggedInUser();

  const { data: companiesData } = useGetClientsTreeQuery(
    { clientId: null },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const statuses = Object.keys(ProgressStatus);
  const { t } = useTranslation();

  const handleSearch = debounce((e) => {
    handleSearchInput(e.target.value);
  }, DEBOUNCE_TIME);

  const getStatusName = (item: string) => {
    switch (item) {
      case ProgressStatus.Done:
        return t("goal-reached");
      case ProgressStatus.Failed:
        return t("goal-not-reached");
      case ProgressStatus.InProgress:
        return t("in-progress-desc");
      default:
        return item;
    }
  };

  return (
    <div className={styles["data-header"]}>
      <Input
        className={styles["search-input"]}
        style={{
          borderStyle: "solid",
          borderRadius: 10,
          borderColor: "#ADADAD",
          width: 270,
        }}
        onChange={handleSearch}
        placeholder={"Search by Name"}
        addonAfter={<SearchIcon />}
      />
      <div className={styles["filters-container"]}>
        {isCompanyFilter && !isClient ? (
          <Formik initialValues={{ companies: null }} onSubmit={() => {}}>
            {({ setFieldValue, values }) => (
              <Form>
                <CustomTreeSelect
                  allowClear
                  showArrow
                  noMargin
                  treeNodeFilterProp="companyName"
                  treeLine={true && { showLeafIcon: false }}
                  className={`filter-select-dark ${styles["filter-padding"]}`}
                  name="companies"
                  treeData={companiesData?.getClientsTree}
                  placeholder={"All Companies"}
                  fieldNames={{ label: "companyName", value: "id" }}
                  onChange={(values) => {
                    setFieldValue("companies", values);
                    setSelectedCompany?.(values);
                  }}
                />
              </Form>
            )}
          </Formik>
        ) : null}
        {isDateFilter ? (
          <RangePicker
            className="filter-date-picker-dark"
            onChange={(dates, dateStrings) =>
              handleRangeFilter(dates, dateStrings)
            }
          />
        ) : null}

        {isStatusFilter ? (
          <Select
            allowClear
            placeholder="All Status"
            className="filter-select-dark"
            onChange={handleStatus}
            onClear={() => {}}
          >
            {statuses?.map((item: string) => (
              <Option value={item}>{getStatusName(item)}</Option>
            ))}
          </Select>
        ) : null}
      </div>
    </div>
  );
};

export default DataTableFilters;
