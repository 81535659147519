import { convertTranslationArrToObj } from "helpers";
import {
  MutationAddNewTagArgs,
  useAddNewTagMutation,
  useGetTagListQuery,
  useGetConfigTagsQuery,
} from "graphql/_generated/graphql";

export type TypeHandleAddFun = (values: MutationAddNewTagArgs) => Promise<void>;

//define the type of useCategory hook
type TypeUseAddTag = () => {
  isLoading: boolean;
  handleAddTag: TypeHandleAddFun;
};

export const useAddTag: TypeUseAddTag = () => {
  const [addTagFun, { isLoading }] = useAddNewTagMutation();
  const { refetch } = useGetConfigTagsQuery();

  //this function is responsible for adding new tag
  const handleAddTag = async (values: MutationAddNewTagArgs) => {
    try {
      let translations = await convertTranslationArrToObj(
        values.create.translations
      );
      let params = { create: { ...values.create, translations } };

      await addTagFun(params).unwrap();
      refetch();
    } catch (error: any) {
      throw error;
    }
  };

  return { isLoading, handleAddTag };
};
