import * as Yup from "yup";
import * as Validations from "./common/validations";

export const categorySchema = Yup.object().shape({
  pillarId: Validations.required("pillar"),
  name: Validations.requiredFieldWithoutSpaces("name"),
  icon: Validations.image("image"),
  translations: Yup.array().of(
    Yup.object().shape({
      lang: Validations.required("language"),
      name: Validations.requiredFieldWithoutSpaces("error:selected-lang-name"),
    }),
  ),
});
