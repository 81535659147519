import { Space } from "antd";
import * as React from "react";
import Icon from "@ant-design/icons";
import { CrossSvg } from "assets/icons";
import { TickSvg } from "assets/icons/TickSvg";
import styles from "./CheckInItem.module.scss";
interface CheckInItemProps {
  status?: boolean | null;
  title?: string;
  isValid?: boolean;
}

const CheckInItem: React.FunctionComponent<CheckInItemProps> = ({
  title,
  status,
  isValid,
}) => {
  const iconColor = () => {
    if (isValid || status) {
      return "#49BF26";
    }
    if (status == null && !isValid) {
      return "#4C4C4C";
    }
    return "#EF4D56";
  };

  const iconName = () => {
    if (isValid || status) {
      return TickSvg;
    }
    if (status == null && !isValid) {
      return undefined;
    }
    return CrossSvg;
  };

  const textColor = () => {
    if (status == null || status) {
      return "#4C4C4C";
    }
    return "#EF4D56";
  };

  return (
    <div>
      <Space align="center" size={10}>
        {iconName() ? (
          <div className={styles["icon"]}>
            <Icon component={iconName()} style={{ color: iconColor() }} />
          </div>
        ) : null}
        <span
          className={styles["dot"]}
          style={{ backgroundColor: textColor() }}
        ></span>
        <div className={styles["title"]} style={{ color: textColor() }}>
          {title}
        </div>
      </Space>
    </div>
  );
};

export default CheckInItem;
