import {
  convertTranslationArrToObj,
  convertTranslationObjToArr,
} from "helpers";
import {
  Comparison,
  MutationUpdateCategoryArgs,
  useCategoriesTreeQuery,
  useGetCategoryListQuery,
  useLazyGetCategoryQuery,
  useUpdateCategoryMutation,
} from "graphql/_generated/graphql";

export type TypeGetCategory = (id: string) => Promise<any>;
export type TypeUpdateCategory = (
  values: MutationUpdateCategoryArgs
) => Promise<void>;

//define the type of useCategory hook
type TypeUseUpdateCategory = () => {
  isLoading: boolean;

  handleFetchEditCategoryData: TypeGetCategory;

  handleUpdateCategory: TypeUpdateCategory;
};

export const useUpdateCategory: TypeUseUpdateCategory = () => {
  const [updateCategoryFun, { isLoading }] = useUpdateCategoryMutation();
  const [prevEditDataFun, { isLoading: prevDataLoading }] =
    useLazyGetCategoryQuery();
  const { refetch: refetchConfigCategories } = useCategoriesTreeQuery();

  const { refetch } = useGetCategoryListQuery({
    filters: {
      and: [{ key: "parentId", value: null, comparison: Comparison.Equal }],
    },
  });

  //this function is responsible for updating the category data
  const handleUpdateCategory = async (values: MutationUpdateCategoryArgs) => {
    try {
      let translations = await convertTranslationArrToObj(
        values.updateObj.translations
      );
      let params = { updateObj: { ...values.updateObj, translations } };

      await updateCategoryFun({
        updateCategoryId: values.id,
        updateObj: params.updateObj,
      }).unwrap();

      refetch();
      refetchConfigCategories();
    } catch (error: any) {
      throw error;
    }
  };

  //this function is responsible for fetching the specific category data which category id we will be provided
  const handleFetchEditCategoryData = async (id: string) => {
    try {
      const res = await prevEditDataFun({ getCategoryId: id });
      const translations = await convertTranslationObjToArr(
        res.data?.getCategory.translations
      );

      return { ...res.data?.getCategory, translations };
    } catch (error) {
      throw error;
    }
  };

  return {
    isLoading: isLoading || prevDataLoading,
    handleFetchEditCategoryData,
    handleUpdateCategory,
  };
};
