import {
  MutationReSendBulkInviteArgs,
  MutationReSendInviteArgs,
  useReSendBulkInviteMutation,
  useReSendInviteMutation,
} from "graphql/_generated/graphql";

export const useResendInvite = () => {
  const [resendInviteFun, { isLoading }] = useReSendInviteMutation();
  const [resendBulkInviteFun, { isLoading: isInviting }] =
    useReSendBulkInviteMutation();

  const handleResendInvite = async (values: MutationReSendInviteArgs) => {
    try {
      await resendInviteFun({ reSendInviteId: values.id }).unwrap();
    } catch (error) {
      throw error;
    }
  };

  const handleResendBulkInvite = async (
    values: MutationReSendBulkInviteArgs,
  ) => {
    try {
      await resendBulkInviteFun({ reSendInviteIds: values.userIds }).unwrap();
    } catch (error) {
      throw error;
    }
  };

  return {
    isLoading,
    isInviting,
    handleResendInvite,
    handleResendBulkInvite,
  };
};
