import { DeleteUserData } from "graphql/_generated/graphql";
import { deleteAccount } from "hooks/raw-requests/hooks/rawDeleteAccount";
import { useState } from "react";
export const useDeleteAccount = () => {
  const [loading, setLoading] = useState(false);

  const handleDeleteAccount = async (data: {
    body: DeleteUserData;
    token: string;
  }) => {
    const { body, token } = data;
    try {
      setLoading(true);
      const res = await deleteAccount(body, token);
      setLoading(false);
      return res;
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  return {
    handleDeleteAccount,
    loading,
  };
};
