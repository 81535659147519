import { Avatar, AvatarProps } from "antd";
import { Regions } from "graphql/_generated/graphql";
import { CONFIG } from "helpers/constants";
import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

interface CustomAvatarProps extends Omit<AvatarProps, "src"> {
  imgSrc?: string;
  height?: number;
  width?: number;
  fit?: "cover" | "contain" | "fill" | "inside" | "outside";
  disableBaseUrl?: boolean;
  isUserProfile?: boolean;
}

const BASE_IMAGE_URL =
  process.env.REACT_APP_BASE_IMAGE_URL || CONFIG.BASE_IMAGE_URL;

const KSA_BASE_IMAGE_URL =
  process.env.REACT_APP_KSA_BASE_IMAGE_URL || CONFIG.KSA_BASE_IMAGE_URL;

const getImageUrl = (
  baseImgUrl: string,
  imgSrc: string | undefined,
  height: number | undefined,
  width: number | undefined,
  fit: "cover" | "contain" | "fill" | "inside" | "outside" | undefined,
) => {
  imgSrc = imgSrc || ""; // use an empty string if imgSrc is undefined
  if (height && width) {
    return `${baseImgUrl}${imgSrc}?h=${height}&w=${width}${
      fit ? `&fit=${fit}` : ""
    }`;
  }
  return `${baseImgUrl}${imgSrc}`;
};

const CustomAvatar: React.FunctionComponent<CustomAvatarProps> = (props) => {
  const {
    imgSrc,
    height,
    width,
    fit,
    disableBaseUrl,
    isUserProfile,
    ...otherProps
  } = props;
  let baseImgUrl = (disableBaseUrl ? "" : BASE_IMAGE_URL) || "";
  const region = useSelector((state: RootState) => state.auth.region);

  if (region === Regions.Ksa && isUserProfile) {
    baseImgUrl = KSA_BASE_IMAGE_URL;
  }
  const avatarProps: AvatarProps = {
    ...otherProps,
    style: { backgroundColor: "whitesmoke", ...otherProps.style },
  };

  if (imgSrc && imgSrc.toLowerCase() !== "null") {
    avatarProps.src = getImageUrl(baseImgUrl, imgSrc, height, width, fit);
  }

  return <Avatar {...avatarProps} />;
};

export default React.memo(CustomAvatar);
