import CheckPermissions from "components/check-permissions/CheckPermissions";
import CustomButton from "components/custom-button/CustomButton";
import { PERMS } from "helpers";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import * as React from "react";
import ChallengesList from "features/challenge/challenge-list/ChallengesList";
import styles from "features/challenge/css/Activity.module.scss";
import AddLanguage from "features/challenge/language/AddLanguage";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import { PlusCircleFilled } from "@ant-design/icons";

interface ActivityProps {}

const Challenges: React.FunctionComponent<ActivityProps> = () => {
  return (
    <DashboardLayout openKey={["Activity"]}>
      <Breadcrumbs
        data={[
          {
            label: "Activities",
          },
          {
            label: "Challenges",
          },
        ]}
      />
      <ChallengesList />
    </DashboardLayout>
  );
};

export default Challenges;
