export const ChervanLeftSvg = () => {
  return (
    <svg
      width="5"
      height="8"
      viewBox="0 0 5 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.90823 7.33334C3.73356 7.33334 3.55956 7.26534 3.4289 7.13L0.853563 4.46334C0.601563 4.20134 0.604897 3.786 0.86223 3.52867L3.5289 0.862004C3.7889 0.601337 4.2109 0.601337 4.47156 0.862004C4.73156 1.12267 4.73156 1.544 4.47156 1.80467L2.26823 4.008L4.38756 6.20334C4.64356 6.46867 4.63623 6.89067 4.37156 7.146C4.24156 7.27134 4.0749 7.33334 3.90823 7.33334Z"
        fill="#262626"
      />
    </svg>
  );
};
