import { useAddWellBeingCalender } from "./useAddWellBeingCalender";
import { useUpdateWellBeingCalender } from "./useUpdateWellBeingCalender";

export const useWellBeingCalender = () => {
  const { handleAddNewWellBeingCalender, isLoading } =
    useAddWellBeingCalender();
  const {
    handleUpdateWellBeingCalender,
    isLoading: isLoadingUpdateWellBeingCalender,
  } = useUpdateWellBeingCalender();

  return {
    isLoading: isLoading || isLoadingUpdateWellBeingCalender,
    handleAddNewWellBeingCalender,
    handleUpdateWellBeingCalender,
  };
};
