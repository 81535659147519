import { UploadOutlined } from "@ant-design/icons";
import { Input, Select, Space } from "antd";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import { parseJwt, PERMS } from "helpers";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CustomButton from "components/custom-button/CustomButton";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import EditEmployee from "features/users/EditEmployee";
import InviteUsers from "features/users/invite-users/InviteUsers";
import UsersList from "features/users/UsersList";
import styles from "features/users/css/UsersList.module.scss";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
const { Search } = Input;
const { Option } = Select;

const Users = () => {
  const { t } = useTranslation();
  const [visible, setInviteVisible] = useState(false);
  const [visibleEditEmployee, setVisibleEditEmployee] = useState(false);
  const token = useSelector((state: any) => state.auth.token);
  const id = parseJwt(token).companyId;
  const userType = parseJwt(token).userType;
  return (
    <>
      <DashboardLayout openKey={["Users"]}>
        <div className={styles["users"]}>
          <Breadcrumbs
            data={[
              {
                label: "Users",
              },
              {
                label: t("employees.title"),
              },
            ]}
          />
          <UsersList setInviteVisible={setInviteVisible} />
        </div>
      </DashboardLayout>
      <InviteUsers
        visible={visible}
        setVisible={setInviteVisible}
        
        employerId={userType === "ClientAdmin" ? id : undefined}
      />
    </>
  );
};

export default Users;
