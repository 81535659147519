import IAssessmentSettings from "features/assessment/types/IAssessmentSettings";

const settingInitialValues: IAssessmentSettings = {
  name: "",
  mandatory: false,
  skippable: false,
  enableSubQuestions: false,
  expiryDate: false,
  date: "",
  notifications: false,
  assessmentTypeName: "",
};

export default settingInitialValues;
