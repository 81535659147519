export const EmployeesSvg = () => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.50033 7.16667C8.33866 7.16667 9.83366 5.67167 9.83366 3.83333C9.83366 1.995 8.33866 0.5 6.50033 0.5C4.66199 0.5 3.16699 1.995 3.16699 3.83333C3.16699 5.67167 4.66199 7.16667 6.50033 7.16667ZM13.167 8.83333C14.5453 8.83333 15.667 7.71167 15.667 6.33333C15.667 4.955 14.5453 3.83333 13.167 3.83333C11.7887 3.83333 10.667 4.955 10.667 6.33333C10.667 7.71167 11.7887 8.83333 13.167 8.83333ZM17.3337 13.8333C17.3337 14.2933 16.9612 14.6667 16.5003 14.6667H12.3337C12.3337 15.1267 11.9612 15.5 11.5003 15.5H1.50033C1.03949 15.5 0.666992 15.1267 0.666992 14.6667C0.666992 11.45 3.28449 8.83333 6.50033 8.83333C8.10616 8.83333 9.56116 9.48583 10.617 10.5383C11.3412 9.97833 12.2328 9.66667 13.167 9.66667C15.4645 9.66667 17.3337 11.5358 17.3337 13.8333Z"
        fill="#303030"
      />
    </svg>
  );
};
