import { useLazyGetAssessmentDefaultSettingsQuery } from "graphql/_generated/graphql";

export const useFetchDefaultSettings = () => {
  const [fetchPrevDataFun, { isLoading }] =
    useLazyGetAssessmentDefaultSettingsQuery();

  const handleFetchDefaultSettings = async (id: string) => {
    try {
      const res = await fetchPrevDataFun({
        getAssessmentDefaultSettingsId: id,
      }).unwrap();
      return res.getAssessmentDefaultSettings;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleFetchDefaultSettings,
    isLoading,
  };
};
