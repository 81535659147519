export const DownArrowSvg = () => {
  return (
    <svg
      width="10"
      height="7"
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.00001 6.50025C4.74401 6.50025 4.48801 6.40225 4.29301 6.20725L0.293006 2.20725C-0.0979941 1.81625 -0.0979941 1.18425 0.293006 0.79325C0.684006 0.40225 1.31601 0.40225 1.70701 0.79325L5.01201 4.09825L8.30501 0.91825C8.70401 0.53525 9.33501 0.54625 9.71901 0.94325C10.103 1.34025 10.092 1.97425 9.69501 2.35725L5.69501 6.21925C5.50001 6.40725 5.25001 6.50025 5.00001 6.50025Z"
        fill="#121212"
      />
    </svg>
  );
};
