import React from "react";
import {
  PieChart as ReChartPieGraph,
  Pie,
  Cell,
  ResponsiveContainer,
} from "recharts";
import { PieChartProps } from "../types";

export const PieChart: React.FC<PieChartProps> = ({ pieChartConfig }) => {
  return (
    <ResponsiveContainer>
      <ReChartPieGraph>
        <Pie
          data={pieChartConfig.data}
          innerRadius={pieChartConfig.innerRadius}
          outerRadius={pieChartConfig.outerRadius}
          paddingAngle={0}
          startAngle={90}
          endAngle={-270}
          dataKey="value"
        >
          {pieChartConfig.data.map((entry: any, index: number) => (
            <Cell
              key={`cell-${index}`}
              stroke=""
              fill={pieChartConfig.colors ? pieChartConfig.colors[index] : ""}
            />
          ))}
        </Pie>
      </ReChartPieGraph>
    </ResponsiveContainer>
  );
};
