import * as React from "react";
import { Comment, Rate, Typography } from "antd";
import styles from "./Reviews.module.scss";


const Reviews: React.FC<{ review: any }> = ({ review }) => {
  return (
    <>
      <div className={styles["description"]}>
        <h2 className={styles["desc"]}>Overall Rating</h2>
        <Rate disabled defaultValue={review?.averageRating} />
      </div>
      <div className={styles["description"]}>
        <h2 className={styles["desc"]}>Reviews</h2>
        {review?.reviews.length
          ? review?.reviews.map((review: any) => (
              <Comment
                content={<Typography.Text italic>{review}</Typography.Text>}
              />
            ))
          : "No reviews yet"}
      </div>
    </>
  );
};

export default Reviews;
