import * as React from "react";
import facebook from "assets/images/facebook.svg";
import twitter from "assets/images/twitter.svg";
import instagram from "assets/images/instagram.svg";
import linkedin from "assets/images/linkedin.svg";
import viwellLogo from "assets/images/viwell-logo.svg";

interface FooterProps {}

const Footer: React.FunctionComponent<FooterProps> = (props) => {
  return (
    <div>
      <div className="text-center my-[32px] md:my-[40px]">
        <div className="flex gap-x-3 justify-center">
          <a
            href="https://viwell.com/data-privacy/terms-and-conditions/"
            target="_blank"
            className="font-normal text-xs md:text-base font-sf-pro-text hover:text-[#121212]"
          >
            Terms & Conditions
          </a>
          <div>-</div>
          <a
            href="https://viwell.com/data-privacy/privacy-policy/"
            target="_blank"
            className="font-normal text-xs  md:text-base  font-sf-pro-text hover:text-[#121212]"
          >
            Privacy Policy
          </a>
        </div>
      </div>
      <div className="flex flex-col items-center gap-y-4">
        <a href="https://viwell.com/" target="_blank" className="inline-block">
          <img
            src={viwellLogo}
            alt="VIWELL Logo"
            className="w-[74px] h-[20px] md:w-[146px] md:h-[32px]"
          />
        </a>
        <div className="flex gap-x-8">
          <a href="https://web.facebook.com/viwell.life">
            <img
              src={facebook}
              alt="VIWELL Facebook"
              className="w-6 h-6 md:w-8 md:h-8 "
            />
          </a>
          <a href="https://twitter.com/viwelllife">
            <img
              src={twitter}
              alt="VIWELL Twitter"
              className="w-6 h-6 md:w-8 md:h-8 "
            />
          </a>
          <a href="https://www.instagram.com/viwell.life/">
            <img
              src={instagram}
              alt="VIWELL Instagram"
              className="w-6 h-6 md:w-8 md:h-8 "
            />
          </a>
          <a href="https://www.linkedin.com/company/viwelllife">
            <img
              src={linkedin}
              alt="VIWELL Linkedin"
              className="w-6 h-6 md:w-8 md:h-8 "
            />
          </a>
        </div>
        <h2 className="font-normal text-center text-xs font-sf-pro-text md:text-base">
          Business Bay, Dubai, UAE
        </h2>
        <h3 className="font-normal text-center text-xs font-sf-pro-text md:text-base">
          &copy; 2024, VIWELL Lifestyle coaching, All Rights Reserved.
        </h3>
      </div>
    </div>
  );
};

export default Footer;
