import {
  AssignToTeamsAutomaticallyMutationVariables,
  useAssignToTeamsAutomaticallyMutation,
} from "graphql/_generated/graphql";

export const useAssignToTeamsAutomatically = () => {
  const [assignTeamAutomatically, { isLoading: isLoadingTeamAutomatically }] =
    useAssignToTeamsAutomaticallyMutation();

  const handleTeamAutomatically = async (
    values: AssignToTeamsAutomaticallyMutationVariables,
  ) => {
    try {
      const res = await assignTeamAutomatically(values).unwrap();
      return res.assignToTeamsAutomatically;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleTeamAutomatically,
    isLoadingTeamAutomatically,
  };
};
