export const AssessmentListSvg = () => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6152 0.425875C14.9327 0.344208 15.2585 0.415042 15.5143 0.613375C15.7702 0.812542 15.916 1.11254 15.916 1.43504V11.1734C15.916 11.6492 15.5952 12.0642 15.1343 12.1825L8.83267 13.8V1.91171L14.6152 0.425875ZM1.38134 0.425819L7.16634 1.91165V13.8008L0.864675 12.1825C0.404675 12.065 0.0830078 11.65 0.0830078 11.1733V1.43582C0.0830078 1.11249 0.229675 0.813319 0.484675 0.613319C0.739675 0.415819 1.06717 0.346652 1.38134 0.425819Z"
        fill="#303030"
      />
    </svg>
  );
};
