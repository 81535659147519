import { customNotification } from "components/custom-notification/CustomNotification";
import { convertTranslationArrToObj, errorFormat } from "helpers";
import {
  useAddNewFileContentMutation,
  useAddNewRecipeContentMutation,
  useAddNewTextContentMutation,
  useAddNewVideoContentMutation,
  ContentType,
  useAddNewAudioContentMutation,
} from "graphql/_generated/graphql";

export const useAddContent = () => {
  const [addNewAudioFun, { isLoading: addAudioLoading }] =
    useAddNewAudioContentMutation();

  const [addNewVideoContentFun, { isLoading: addVideoContentLoading }] =
    useAddNewVideoContentMutation();

  const [addNewTextContentFun, { isLoading: addTextContentLoading }] =
    useAddNewTextContentMutation();

  const [addNewFileContentFun, { isLoading: addFileContentLoading }] =
    useAddNewFileContentMutation();

  const [addNewRecipeContentFun, { isLoading: addRecipeContentLoading }] =
    useAddNewRecipeContentMutation();

  //this function is responsible for adding the new content
  const handleAddContent = async (values: any) => {
    const { contentType } = values.create;

    // Object.keys(AudioContentCreate);
    try {
      let contentTranslations = await convertTranslationArrToObj(
        values.create.translations,
      );
      let params = {
        create: { ...values.create, translations: contentTranslations },
      };
      let res;
      const {
        points,
        status,
        language,
        spokenLanguage,
        thumbnailImage,
        translations,
        title,
        description,
        tagIds,
        categoryIds,
        pillarIds,
        creatorId,
        audioUrl,
        duration,
        coverImage,
        localizedAudios,
        localizedVideos,
        localizedFiles,
        localizedTexts,
        fileUrl,
        videoUrl,
        text,
        type,
        relatedRecipes,
        timeRequired,
        serving,
        shortDescription,
      } = params.create;

      const commonParams = {
        points,
        status,
        language,
        spokenLanguage,
        thumbnailImage,
        translations,
        title,
        description,
        tagIds,
        categoryIds,
        pillarIds,
        creatorId,
        shortDescription,
      };

      if (contentType === ContentType.Audio) {
        res = await addNewAudioFun({
          create: {
            ...commonParams,
            audioUrl,
            localizedAudios,
            coverImage,
            duration,
          },
        }).unwrap();
      } else if (contentType === "Video") {
        res = await addNewVideoContentFun({
          create: {
            ...commonParams,
            localizedVideos,
            videoUrl,
            duration,
          },
        }).unwrap();
      } else if (contentType === "File") {
        res = await addNewFileContentFun({
          create: {
            ...commonParams,
            localizedFiles,
            fileUrl,
          },
        }).unwrap();
      } else if (contentType === "Text") {
        res = await addNewTextContentFun({
          create: {
            ...commonParams,
            localizedTexts,
            text,
          },
        }).unwrap();
      } else if (contentType === "Recipe") {
        const gallery = params?.create?.gallery.map((item: any) => {
          return item["image"];
        });
        res = await addNewRecipeContentFun({
          create: {
            ...commonParams,
            gallery,
            relatedRecipes,
            timeRequired,
            serving,
          },
        }).unwrap();
      }
    } catch (error: any) {
      throw error;
    }
  };

  return {
    isLoading:
      addAudioLoading ||
      addVideoContentLoading ||
      addTextContentLoading ||
      addFileContentLoading ||
      addRecipeContentLoading,
    handleAddContent,
  };
};
