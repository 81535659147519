export const AvatarSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9775 17.5H15C16.3783 17.5 17.5 16.3783 17.5 15V5C17.5 3.62167 16.3783 2.5 15 2.5H5C3.62167 2.5 2.5 3.62167 2.5 5V15C2.5 16.3783 3.62167 17.5 5 17.5H5.02249C5.26309 14.9654 7.40412 12.9762 10 12.9762C12.5959 12.9762 14.7369 14.9654 14.9775 17.5ZM10 11.5476C11.5757 11.5476 12.8571 10.2662 12.8571 8.69048C12.8571 7.11476 11.5757 5.83333 10 5.83333C8.42429 5.83333 7.14286 7.11476 7.14286 8.69048C7.14286 10.2662 8.42429 11.5476 10 11.5476Z"
        fill="#303030"
      />
    </svg>
  );
};
