import { DeleteFilled, QuestionCircleFilled } from "@ant-design/icons";
import { Button, Col, Divider, Row, Tooltip } from "antd";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import CustomButton from "components/custom-button/CustomButton";
import CustomCard from "components/custom-card/CustomCard";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { CustomTextArea } from "components/form/custom-text-area/CustomTextArea";
import CustomTextEditor from "components/form/custom-text-editor/CustomTextEditor";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { CustomUploadFile } from "components/form/custom-upload-file/CustomUploadFile";
import styles from "features/content/css/AddContent.module.scss";
import { useAddContentViewModel } from "features/content/hooks/useAddContentViewModel";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import { FieldArray, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { contentSchema } from "validations/schema";
import ContentUpload from "../../features/content/content-upload/ContentUpload";
import {
  checkTypeAudio,
  getLabelFromValue,
} from "../../features/content/utils";

const AddContent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    editId,
    initialValues,
    onSubmit,
    contentTypeItems,
    languages,
    pillarsData,
    categoriesData,
    tagsData,
    handleDeleteSubTitle,
    value,
    isLoading,
    handlePillarChange,
  } = useAddContentViewModel();

  return (
    <DashboardLayout openKey={["Content"]}>
      <Breadcrumbs
        data={[
          {
            label: "Content",
          },
          {
            label: editId ? "Edit Content" : "Create New Content",
          },
        ]}
      />
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={contentSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => {
          const isTypeAudio = checkTypeAudio(values);
          return (
            <Form>
              <div className={styles["add-content-header"]}>
                <h1 className={styles["title"]}>
                  {editId ? "Edit Content" : "Create New Content"}
                </h1>
              </div>
              <div className={styles["content-card"]}>
                <CustomCard bordered={false} className="content-card">
                  <Row gutter={[24, 0]}>
                    <Col sm={24} md={12}>
                      <CustomSelectField
                        label={t("text-language")}
                        required={true}
                        value={getLabelFromValue(languages, values)}
                        name="language"
                        placeholder={t("select-language")}
                        options={languages}
                        defaultValue={getLabelFromValue(languages, values)}
                        className="form-select"
                      />
                    </Col>
                    <Col sm={24} md={12}>
                      <CustomTextField
                        label={t("content-title")}
                        name="title"
                        placeholder={t("enter-content-title")}
                      />
                    </Col>
                  </Row>

                  <CustomTextEditor
                    name="description"
                    label={t("description")}
                  />
                  <CustomTextArea
                    name="shortDescription"
                    label={t("short-description")}
                    rows={4}
                  />
                  {/*this field array is dynamically add the title and description */}
                  <FieldArray
                    name="translations"
                    render={(arrayHelpers) => (
                      <div>
                        {values.translations &&
                        values.translations.length > 0 ? (
                          values.translations.map(
                            (translation: any, index: number) => (
                              <div key={index}>
                                <Divider />
                                <div className={styles["multi-lang-box"]}>
                                  <div className="-control">
                                    <CustomSelectField
                                      marginBtm={false}
                                      label={t("other-text-language")}
                                      required={true}
                                      value={getLabelFromValue(
                                        languages,
                                        values,
                                        index,
                                      )}
                                      name={`translations.${index}.lang`}
                                      placeholder={t("select-language")}
                                      options={languages}
                                      defaultValue={getLabelFromValue(
                                        languages,
                                        values,
                                        index,
                                      )}
                                      className="form-select"
                                    />
                                  </div>
                                  <CustomTextField
                                    label={t("content-title")}
                                    name={`translations.${index}.title`}
                                    placeholder={t(
                                      "enter-title-in-selected-language",
                                    )}
                                  />
                                </div>
                                <CustomTextEditor
                                  label={t(
                                    "write-description-in-selected-language",
                                  )}
                                  name={`translations.${index}.description`}
                                  placeholder={t(
                                    "enter-description-in-selected-language",
                                  )}
                                />
                                <CustomTextArea
                                  name={`translations.${index}.shortDescription`}
                                  label={t("short-description")}
                                  rows={4}
                                />
                                <div className={styles["flex-div"]}>
                                  {values.translations.length + 1 <
                                  languages.length ? (
                                    <span
                                      className={styles["add-btn"]}
                                      onClick={() =>
                                        arrayHelpers.push({
                                          lang: "",
                                          title: "",
                                          description: "",
                                          shortDescription: "",
                                        })
                                      }
                                    >
                                      + {t("add-new-language")}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  <CustomButton
                                    type="link"
                                    className="remove-btn"
                                    onClick={() => arrayHelpers.remove(index)} // remove a language from the list
                                  >
                                    <DeleteFilled />
                                  </CustomButton>
                                </div>
                              </div>
                            ),
                          )
                        ) : (
                          <div className={styles["form-control"]}>
                            <span
                              className={styles["add-btn"]}
                              onClick={() =>
                                arrayHelpers.push({
                                  lang: "",
                                  title: "",
                                  description: "",
                                  shortDescription: "",
                                })
                              }
                            >
                              + {t("add-new-language")}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  />
                </CustomCard>
              </div>

              <div className={styles["content-card"]}>
                <CustomCard bordered={false} className="content-card">
                  <div className={styles["name-desc"]}>
                    {t("content-detail")}
                  </div>
                  <Row gutter={[24, 0]}>
                    <Col sm={24} md={12}>
                      <CustomSelectField
                        disabled={editId ? true : false}
                        label="Content Type"
                        className="form-select"
                        name="contentType"
                        placeholder={t("select-content-type")}
                        options={contentTypeItems}
                      />
                    </Col>
                    {/* <Col sm={24} md={12}>
                      <CustomTextField
                        type="number"
                        label="Points"
                        name="points.end"
                        placeholder="Enter end points"
                        onChange={(e) =>
                          setFieldValue(
                            "points.end",
                            e.target.value.length === 0
                              ? null
                              : Number(e.target.value),
                          )
                        }
                      />
                    </Col> */}
                    <Col sm={24} md={12}>
                      <CustomSelectField
                        onChange={(value) => {
                          handlePillarChange(value);
                          setFieldValue("categoryIds", "");
                          setFieldValue("pillarIds", value);
                        }}
                        label={t("pillar-title")}
                        className="form-select"
                        name="pillarIds"
                        placeholder={t("select-pillar")}
                        options={pillarsData}
                      />
                    </Col>
                    <Col sm={24} md={12}>
                      <CustomSelectField
                        label={t("categories")}
                        name="categoryIds"
                        mode="multiple"
                        placeholder={t("select-category")}
                        className="form-select"
                        options={categoriesData?.getCategoriesTree}
                        fieldNames={{ label: "name", value: "id" }}
                      />
                    </Col>
                    <Col sm={24} md={12}>
                      <CustomSelectField
                        label="Tags"
                        name="tagIds"
                        mode="multiple"
                        placeholder={t("select-tags")}
                        className="form-select"
                        options={tagsData?.getConfigTags.options}
                        fieldNames={{ label: "label", value: "id" }}
                      />
                    </Col>
                    {/* <Col sm={24} md={12}>
                      <CustomSelectField
                        label={t("select-status")}
                        name="status"
                        placeholder={t("select-status")}
                        className="form-select"
                        options={contentStatus}
                      />
                    </Col> */}
                    {/* <Col sm={24} md={12}>
                      <CustomTextField
                        type="number"
                        label="Start points"
                        name="points.start"
                        placeholder="Enter start points"
                        onChange={(e) =>
                          setFieldValue(
                            "points.start",
                            e.target.value.length === 0
                              ? null
                              : Number(e.target.value),
                          )
                        }
                      />
                    </Col> */}
                  </Row>
                </CustomCard>
              </div>
              <div className={styles["content-card"]}>
                <CustomCard bordered={false} className="content-card">
                  <div className={styles["name-desc"]}>
                    {t("thumbnail-detail")}
                  </div>
                  <Row gutter={[24, 0]}>
                    <Col sm={24} md={12}>
                      <CustomUploadFile
                        label={t("upload-content-thumbnail-image")}
                        name="thumbnailImage"
                        setFieldValue={setFieldValue}
                        imgUrl={values.thumbnailImage}
                        accept="image/*"
                      />
                    </Col>
                    {isTypeAudio && (
                      <Col sm={24} md={12}>
                        <CustomUploadFile
                          label={<BackgroundImageLabel />}
                          name="coverImage"
                          setFieldValue={setFieldValue}
                          imgUrl={values.coverImage}
                          accept="image/*"
                        />
                      </Col>
                    )}
                  </Row>
                </CustomCard>
              </div>
              <div className={styles["content-card"]}>
                {values.contentType.length > 0 && (
                  <CustomCard bordered={false} className="content-card">
                    <div className={styles["name-desc"]}>
                      {`${values.contentType} Files`}
                    </div>
                    <ContentUpload
                      {...{
                        editId,
                        setFieldValue,
                        values,
                        value,
                        languages,
                        handleDeleteSubTitle,
                      }}
                    />
                  </CustomCard>
                )}
              </div>
              <div className={styles["btn-div"]}>
                <Button
                  className="light-btn"
                  htmlType="reset"
                  onClick={() => navigate("/content#persist")}
                >
                  {t("cancel")}
                </Button>
                <Button
                  loading={isLoading}
                  htmlType="submit"
                  className="primary-btn"
                >
                  {t("save-content")}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </DashboardLayout>
  );
};
export default AddContent;

const BackgroundImageLabel = () => {
  return (
    <div className={styles["label-with-help"]}>
      <label className="label" htmlFor={"Background Image"}>
        Background Image
      </label>
      <Tooltip
        color="#494949"
        trigger="click"
        title={
          <div className={styles["help-text"]}>
            <h4>Instructions to upload Audio Background</h4>
            <p>Image Orientation : Portrait</p>
            <p>Aspect Ratio: 13:6</p>
            <p>Max Image Size: 1290 x 2796</p>
            <p>Min Image Size: 600 x 1300</p>
          </div>
        }
      >
        <QuestionCircleFilled />
      </Tooltip>
    </div>
  );
};
