export const ArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="7"
      viewBox="0 0 10 7"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.99976 6.50001C4.74376 6.50001 4.48776 6.40201 4.29276 6.20701L0.292762 2.20701C-0.0982383 1.81601 -0.0982383 1.18401 0.292762 0.793006C0.683762 0.402006 1.31576 0.402006 1.70676 0.793006L5.01176 4.09801L8.30476 0.918006C8.70376 0.535006 9.33476 0.546006 9.71876 0.943006C10.1028 1.34001 10.0918 1.97401 9.69476 2.35701L5.69476 6.21901C5.49976 6.40701 5.24976 6.50001 4.99976 6.50001Z"
        fill="#303030"
      />
    </svg>
  );
};
