import * as Yup from "yup";
import * as Validations from "./common/validations";

const conditionsArray = Yup.array()
  .min(1, "Please add atleast one condition")
  .of(
    Yup.array().of(
      Yup.object().shape({
        conditionType: Validations.required("condition type"),
        valueType: Validations.required("value type"),
      }),
    ),
  );

const attributecConditionsArray = Yup.array().of(
  Yup.object().shape({
    conditionType: Validations.required("condition type"),
    valueType: Validations.required("value type"),
    value: Yup.mixed().when("valueType", {
      is: "Viwell Assessment",
      then: Yup.array()
        .of(Yup.string())
        .required("Please select answer")
        .min(1, "Please select atleast one answer"),
    }),
  }),
);

export const attributesSchema = Yup.object().shape({
  name: Validations.required("attribute name"),
  conditions: conditionsArray,
});

export const attributeConditionsSchema = Yup.object().shape({
  conditions: attributecConditionsArray,
});
