import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { CustomUploadFile } from "components/form/custom-upload-file/CustomUploadFile";
import { Form, Formik, FormikHelpers } from "formik";
import { RewardCategoryCreate } from "graphql/_generated/graphql";
import { useTranslation } from "react-i18next";
import { addRewardCategory } from "validations/schema/addRewardCategorySchema";

interface AddRewardCategoryProps {
  visible: boolean;
  editId?: string | null;
  langCode?: string;
  isAddLanguage?: boolean;
  initialValues: RewardCategoryCreate;
  handleCancel: () => void;
  isLoading: boolean;
  handleSubmit: (
    values: RewardCategoryCreate,
    { resetForm }: FormikHelpers<RewardCategoryCreate>,
  ) => void;
}

const AddRewardCategory: React.FunctionComponent<AddRewardCategoryProps> = ({
  visible,
  editId,
  isAddLanguage,
  handleCancel,
  isLoading,
  handleSubmit,
  initialValues,
}) => {
  const { t } = useTranslation();

  return (
    <CustomModal
      title={
        editId && !isAddLanguage
          ? t("edit-category")
          : `${t("add-category")} ${isAddLanguage ? t("language") : ""}`
      }
      footer={false}
      visible={visible}
      onCancel={handleCancel}
      width={600}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={addRewardCategory}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            {!isAddLanguage && (
              <CustomUploadFile
                name="image"
                setFieldValue={setFieldValue}
                accept="image/*"
                imgUrl={values.image}
              />
            )}
            <CustomTextField
              label={t("category-title")}
              name="name"
              placeholder={t("enter-category-name")}
            />
            <ModalFooter
              saveText={t("save-category")}
              onCancel={handleCancel}
              loading={isLoading}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddRewardCategory;
