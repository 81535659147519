import { convertTranslationArrToObj } from "helpers";
import {
  MutationAddNewPillarArgs,
  useAddPillarMutation,
  useGetConfigPillarsQuery,
} from "graphql/_generated/graphql";

export type TypeAddPillarFun = (
  values: MutationAddNewPillarArgs
) => Promise<void>;

//define the type of usePillar hook
type TypeUsePillar = () => {
  isLoading: boolean;
  handleAddPillar: TypeAddPillarFun;
};

export const useAddPillar: TypeUsePillar = () => {
  const [addPillarFun, { isLoading }] = useAddPillarMutation();
  const { refetch } = useGetConfigPillarsQuery();

  //this function is responsible for adding the Pillar data
  const handleAddPillar = async (values: MutationAddNewPillarArgs) => {
    try {
      let translations = await convertTranslationArrToObj(
        values.create.translations
      );

      let params = { create: { ...values.create, translations } };

      await addPillarFun(params).unwrap();
      refetch();
    } catch (error: any) {
      throw error;
    }
  };

  return { isLoading, handleAddPillar };
};
