import { FormikValues } from "formik";
import {
  ContentType,
  MutationDeleteSubtitleArgs,
} from "graphql/_generated/graphql";
import CustomShowDuration from "../CustomShowDuration";
import { checkTypeAudio } from "../utils";

const AudioContent = (props: {
  editId: string;
  value: string | null;
  values: FormikValues;
  languages: { label: string; value: string }[];
  handleDeleteSubTitle: (values: MutationDeleteSubtitleArgs) => Promise<void>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
}) => {
  const { values } = props;
  const isTypeAudio = checkTypeAudio(values);

  return (
    <>
      {/* duration is only shown if the content type is audio or video */}
      {(isTypeAudio || values.contentType === "Video") &&
        (values.audioUrl || values.videoUrl) && (
          <CustomShowDuration values={values.duration} />
        )}
    </>
  );
};

export default AudioContent;
