import { Tabs } from "antd";
import CustomModal from "components/custom-modal/CustomModal";
import React from "react";
import { useTranslation } from "react-i18next";
import { HandleErrors } from "../HandleErrors";

const { TabPane } = Tabs;

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  errors: any;
};

export default function DetectedErrorsModal({
  visible,
  setVisible,
  errors,
}: Props) {
  const { t } = useTranslation();
  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <CustomModal
      width={1000}
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      title={t("detected-rrrors")}
    >
      <HandleErrors errors={errors} />
    </CustomModal>
  );
}
