import { useToggleCategoryActivationMutation } from "graphql/_generated/graphql";

export type TypeToggleCategory = (id: string) => Promise<boolean | undefined>;

//define the type of useCategory hook
type TypeUseToggleCategoryActivation = () => {
  isLoading: boolean;
  handleDeActivationCategory: TypeToggleCategory;
};

export const useToggleCategoryActivation: TypeUseToggleCategoryActivation =
  () => {
    const [ActivationDeactivationFun, { isLoading }] =
      useToggleCategoryActivationMutation();
    //this function is responsible for Activation/Deactivation categories
    const handleDeActivationCategory = async (id: string) => {
      try {
        const res = await ActivationDeactivationFun({
          toggleCategoryActivationId: id,
        }).unwrap();

        return res.toggleCategoryActivation;
      } catch (error: any) {
        throw error;
      }
    };

    return {
      isLoading,
      handleDeActivationCategory,
    };
  };
