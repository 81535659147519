import {
  useGetClientsTreeQuery,
  useGetEmployeeListQuery,
} from "graphql/_generated/graphql";
import { normalizeKeys } from "helpers";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
import { showBulkUploadPreviewDetails } from "../hooks/useInviteMultipleUsers";
import { InviteMultipleProps } from "../invite-users/InviteMultipleUsers";

export const useInviteMultipleUsersViewModel = ({
  setVisible,
  employerId,
}: InviteMultipleProps) => {
  const { t } = useTranslation();
  const { refetch } = useGetEmployeeListQuery();
  const [bulkUsersContent, setBulkUsersContent] = useState<any>();
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const { data: companiesData } = useGetClientsTreeQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  //initial values object
  const initialObj: any = {
    parentId: "",
    users: [
      {
        email: "",
        position: "",
        building: "",
        department: "",
      },
    ],
  };

  const [initialValues, setInitialValues] = useState(initialObj);
  const { handleError } = useErrorHandler();

  useEffect(() => {
    if (employerId) {
      setInitialValues({ ...initialValues, parentId: employerId });
    }
  }, [employerId]);

  const handleSubmit = async (values: any, { resetForm }: any) => {
    // onSubmit logic here
  };

  const handleRefetchEmployees = () => {
    refetch();
    setShowPreview(false);
    setVisible(false);
  };

  const handleFileChange = async (e: any, setFieldValue: any) => {
    try {
      const file = e.file.originFileObj;
      const data = await file.arrayBuffer();
      const workbook = await XLSX.read(data);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = await XLSX.utils.sheet_to_json(worksheet);
      const bulkUploadList = normalizeKeys(jsonData);
      const mappedBulkUsersContent =
        showBulkUploadPreviewDetails(bulkUploadList);
      setFieldValue("users", bulkUploadList);

      setBulkUsersContent({
        title: file.name.replace(/.xlsx|.xls/gi, ""),
        ...mappedBulkUsersContent,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handlePreviewAndSave = (values: any) => {
    if (values?.parentId) {
      bulkUsersContent?.employees?.length && setShowPreview(true);
    } else {
      handleError(t("company-required"));
    }
  };

  return {
    initialValues,
    handleSubmit,
    handleRefetchEmployees,
    handleFileChange,
    handlePreviewAndSave,
    showPreview,
    setShowPreview,
    companiesData,
    bulkUsersContent,
  };
};
