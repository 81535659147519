import { List, Space } from "antd";
import * as React from "react";
import styles from "features/events/css/UserItem.module.scss";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
interface UserItemProps {
  itemKey: number;
  image?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  isKSA?: boolean;
}

const UserItem: React.FunctionComponent<UserItemProps> = ({
  itemKey,
  image,
  firstName,
  lastName,
  email,
  isKSA,
}) => {
  return (
    <List.Item key={itemKey}>
      <div className={styles["item-grid"]}>
        <div className={styles["item-id"]}>{itemKey}</div>
        <Space>
          <CustomAvatar
            isUserProfile={isKSA}
            imgSrc={image}
            size={32}
            shape="circle"
          />
          <div
            className={styles["sub-title"]}
          >{`${firstName} ${lastName}`}</div>
        </Space>
        <div className={styles["user"]}>{email}</div>
      </div>
    </List.Item>
  );
};

export default UserItem;
