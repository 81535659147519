import {
  MutationUpdateEapNumberArgs,
  useUpdateEapNumberMutation,
} from "graphql/_generated/graphql";

interface UpdateEAPResult {
  isLoading: boolean;
  handleUpdateEAP: (values: MutationUpdateEapNumberArgs) => Promise<void>;
}

export const useUpdateEAP = (): UpdateEAPResult => {
  const [updateEAPFun, { isLoading }] = useUpdateEapNumberMutation();

  // This function is responsible for updating the EAP
  const handleUpdateEAP = async (values: MutationUpdateEapNumberArgs) => {
    try {
      const { isGlobal, number, countryCode } = values.updateObj;
      await updateEAPFun({
        updateEapNumberId: values.id,
        updateObj: { isGlobal, number, countryCode },
      }).unwrap();
    } catch (error) {
      throw error;
    }
  };

  return {
    isLoading,
    handleUpdateEAP,
  };
};
