import { List, Space } from "antd";
import * as React from "react";
import styles from "features/events/css/TeamLeaderBoard.module.scss";
import { useTranslation } from "react-i18next";
import { BronzeMedalSvg, GoldMedalSvg, SilverMedalSvg } from "assets/icons";
import { Input } from "formik-antd";
import { getRanks } from "helpers";
interface EditTeamItemScoreProps {
  itemKey: number;
  id: string;
  teamTitle: string;
  color: string;
  members: number;
  error: boolean;
}

const EditTeamItemScore: React.FunctionComponent<EditTeamItemScoreProps> = ({
  itemKey,
  id,
  color,
  teamTitle,
  members,
  error,
}) => {
  const { t } = useTranslation();
  return (
    <List.Item key={id}>
      <div className={styles["item-grid"]}>
        <div>
          <Space size={14}>
            <div className={styles["item-id"]}>{getRanks(itemKey)}</div>
            <div
              className={styles["team-color"]}
              style={{
                backgroundColor: color,
              }}
            />
            <div className={styles["sub-title"]}>{teamTitle}</div>
          </Space>
        </div>
        <div className={styles["members"]}>{`${members} ${t("members")}`}</div>
        <div className={styles["users"]}>
          <Input
            className="from-input"
            style={{
              width: "96px",
              height: "40px",
              borderColor: error ? "red" : "#80808029",
            }}
            name={`data[${itemKey - 1}].score`}
            type="number"
          />
        </div>
      </div>
    </List.Item>
  );
};

export default EditTeamItemScore;
