import { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import CollapseAble from "features/assessment/questions/collapseables/CollapseAble";
import {
  OptionTypes,
  useCreateQuestionMutation,
  useUpdateQuestionMutation,
} from "graphql/_generated/graphql";
import styles from "./Question/Question.module.scss";
import { useLocation } from "react-router-dom";
import { useDeleteQuestion } from "features/assessment/hooks/useDeleteQuestion";
import EnumBoolean from "features/assessment/types/EnumBoolean";
import { useAddUpdateQuestionTranslation } from "features/assessment/hooks/useAddUpdateQuestionTranslation";
import { customNotification } from "components/custom-notification/CustomNotification";
import { errorFormat } from "helpers";
import CheckinQuestionDetail from "./CheckinQuestionDetail";
import Feedback from "features/assessment/questions/feedback/feedback";
import CustomDivider from "components/custom-divider/CustomDivider";
import { checkinAssessmentQuestionSchema } from "validations/schema/assessmentSchema";
import { sortableQuestionTypes } from "features/assessment/data/sortableTypes";
import { useTranslation } from "react-i18next";

interface CheckinQuestionProps {
  question: any;
  isAddLanguage?: boolean;
  language?: string;
  assessmentSettings?: any;
}

const CheckinQuestion: React.FunctionComponent<CheckinQuestionProps> = (
  props,
) => {
  const location = useLocation();
  const { t } = useTranslation();
  const params = location.state;

  const [initialState, setInitialState] = useState<any>({
    assessmentId: params?.assessmentId,
    optionType: OptionTypes.SingleChoice,
    savedOnServer: EnumBoolean.NO,
    helperText: undefined,
    text: undefined,
    score: "",
    minChar: "",
    maxChar: "",
    deleteOptionId: [],
    options: [
      {
        score: "",
        text: undefined,
        subText: undefined,
        emoji: undefined,
        tags: [],
      },
    ],
  });

  useEffect(() => {
    if (props.question) {
      const questionTransform = {
        ...props.question,
        savedOnServer: EnumBoolean.YES,
        options: props.question.options.map((opItem: any) => {
          return {
            ...opItem,
            emoji: opItem.emoji || undefined,
            tags: opItem.tags.map((tagItem: any) => tagItem.tagId),
          };
        }),
      };

      setInitialState({ ...questionTransform });
    }
  }, [props.question]);

  const [createQuestion, { isLoading }] = useCreateQuestionMutation();
  const [updateQuestion, { isLoading: UpdateQuestionLoading }] =
    useUpdateQuestionMutation();
  const {
    handleAddUpdateQuestionTranslation,
    isLoadingAddUpdateQuestionTranslation,
  } = useAddUpdateQuestionTranslation();
  const { handleDeleteQuestion, isLoading: isDeleting } = useDeleteQuestion();

  const addUpdateTranslation = async (values: any) => {
    const {
      assessmentId,
      text = "",
      subText = "",
      options,
      assessmentQuestionId,
    } = values;
    const language = props?.language;

    try {
      let params: any = {
        create: {
          assessmentId,
          question: {
            language,
            options: options.map(
              ({ text = "", subText = "", id: optionId }: any) => {
                return { text, subText, language, optionId };
              },
            ),
            questionId: assessmentQuestionId,
            subText,
            text,
          },
        },
      };

      handleAddUpdateQuestionTranslation(params);

      customNotification(t("success"), t("translation-add-update-success"));
    } catch (error: any) {
      customNotification(t("error"), errorFormat(error));
    }
  };

  const saveQuestion = async (values: any) => {
    try {
      const {
        assessmentQuestionId,
        assessmentId,
        deleteOptionId,
        ...restValues
      } = values;
      let params: any = { create: { assessmentId, ...restValues } };

      if (!!assessmentQuestionId) {
        params = {
          questionId: assessmentQuestionId,
          updates: {
            deleteTagId: [],
            deleteOptionId: deleteOptionId ?? [],
            ...restValues,
          },
        };
      }

      const GQC: any = !!assessmentQuestionId ? updateQuestion : createQuestion;
      const response = await GQC(params).unwrap();
      if (response)
        customNotification(t("success"), t("question-add-update-success"));
    } catch (error: any) {
      customNotification(t("error"), errorFormat(error));
      throw error;
    }
  };

  const [editMode, setEditMode] = useState<boolean>(false);
  const toggleEditMode = () => setEditMode(!editMode);

  return (
    <Formik
      initialValues={initialState}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={true}
      validationSchema={
        props?.isAddLanguage
          ? null
          : checkinAssessmentQuestionSchema(props?.assessmentSettings)
      }
      onSubmit={(values) => {
        const payload = {
          assessmentQuestionId: values.id,
          assessmentId: params?.assessmentId,
          subText: "",
          score: values?.score || 0,
          optionType: values?.optionType,
          deleteOptionId: values?.deleteOptionId,
          options: values?.options?.map((optItem: any, index: number) => {
            return {
              score: optItem?.score || 0,
              text: optItem?.text,
              subText: optItem?.subText,
              emoji: optItem?.emoji,
              id: optItem?.id,
              optionSortOrder: sortableQuestionTypes.includes(
                values?.optionType,
              )
                ? index
                : undefined,
              // tags: optItem?.tags?.map((tagtItem: any) => tagtItem.tagId),
              tags: optItem?.tags?.length
                ? optItem?.tags?.map((tagtItem: any) => {
                    // return typeof tagtItem === "object" ? tagtItem.tagId : tagtItem
                    return {
                      tagId:
                        typeof tagtItem === "object"
                          ? tagtItem.tagId
                          : tagtItem,
                    };
                  })
                : [],
            };
          }),
          // emoji: values?.emoji,
          tagId: "",
          text: values?.text,
          isCheckInFeedbackEnable: values?.isCheckInFeedbackEnable,
          maxChar: values?.maxChar || 0,
          minChar: values?.minChar || 0,
          helperText: values?.helperText,
        };
        toggleEditMode();
        if (props?.isAddLanguage) addUpdateTranslation(payload);
        else saveQuestion(payload);
      }}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <Form className={styles["assessment-create-form"]}>
          <div className={styles["question-container"]}>
            <CollapseAble
              headerKey={"1"}
              defaultActiveKey={["1"]}
              hideExtra={false}
              expandIconPosition="end"
              collapsible="header"
              extraProps={{
                // handleDelete: handleDelete,
                handleEdit: toggleEditMode,
                handleUpdate: handleSubmit,
                isEditMode: editMode,
                isAddLanguage: props?.isAddLanguage,
                isCheckIn: true,
              }}
              header={<div style={{ visibility: "hidden" }}>checkIn</div>}
            >
              {(values?.id && props?.isAddLanguage) || !props?.isAddLanguage ? (
                <>
                  <CheckinQuestionDetail
                    disabled={!editMode}
                    values={values}
                    isAddLanguage={props?.isAddLanguage}
                    oldRecord={initialState}
                    resetField={setFieldValue}
                    assessmentSettings={props?.assessmentSettings}
                  />
                  <CustomDivider />
                  <Feedback
                    isCheckInFeedbackEnable={values.isCheckInFeedbackEnable}
                    feedback={values.feedback}
                    disabled={!editMode}
                    maxChar={values.maxChar}
                    minChar={values.minChar}
                    isAddLanguage={props?.isAddLanguage}
                    handleChange={(e: any) =>
                      setFieldValue("isCheckInFeedbackEnable", e)
                    }
                  />
                </>
              ) : null}
            </CollapseAble>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CheckinQuestion;
