import { useAddRole } from "./useAddRole";
import { useUpdateRole } from "./useUpdateRole";
import { useToggleActivationRole } from "./useToggleActivationRole";

export const useRoles = () => {
  const { handleAddNewRole, isLoading } = useAddRole();
  const {
    handleUpdateRole,
    handleFetchPreviousRoleData,
    isLoading: updateLoading,
  } = useUpdateRole();
  const { handleToggleActivationRole, isLoading: ToggleActivationRoleLOading } =
    useToggleActivationRole();

  return {
    isLoading: isLoading || updateLoading || ToggleActivationRoleLOading,
    handleAddNewRole,
    handleFetchPreviousRoleData,
    handleUpdateRole,
    handleToggleActivationRole,
  };
};
