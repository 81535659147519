import {
  TypeToggleViwellAdmin,
  useToggleInviteAdminActivation,
} from "./useToggleViwellAdminActivation";

import {
  TypeInviteAdmin,
  useInviteAdmin,
} from "./useInviteAdmin";

type useAdmin = () => {
  isLoading: boolean;
  inviteAdmin: TypeInviteAdmin;
  handleToggleActivation: TypeToggleViwellAdmin;
};

export const useAdmin: useAdmin = () => {
  const { inviteAdmin, isLoading } = useInviteAdmin();
  const { handleToggleActivation, isLoading: ToggleActivationLoading } =
    useToggleInviteAdminActivation();

  return {
    isLoading: isLoading || ToggleActivationLoading,
    inviteAdmin,
    handleToggleActivation,
  };
};
