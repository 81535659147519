import { Pagination } from "antd";
import CustomLoader from "components/custom-loader/CustomLoader";
import CustomModal from "components/custom-modal/CustomModal";
import {
  ProgressStatus,
  useLazyGetLeaderboardQuery,
} from "graphql/_generated/graphql";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import ChallengeDataTable from "./ChallengeDataTable";
import DataTableFilters from "./DataTableFilters";

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  challengeId?: string;
};

const DEBOUNCE_TIME = 500;

const AllUsersLeaderboard: React.FC<Props> = ({
  visible,
  setVisible,
  challengeId,
}: any) => {
  const handleCancel = () => {
    setVisible(false);
  };

  const [data, setData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>("");
  const [status, setStatus] = useState<ProgressStatus>();
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const [pagination, setPagination] = useState<any>({
    limit: 10,
    offset: 0,
  });

  const [getLeaderboardFun, { isLoading }] = useLazyGetLeaderboardQuery();

  useEffect(() => {
    fetchLeaderboardData();
  }, [currentPage, pagination, searchText, selectedCompany, status]);

  const fetchLeaderboardData = async () => {
    const { getLeaderboard } = await getLeaderboardFun({
      entityId: challengeId,
      pagination,
      search: searchText,
      companyId: selectedCompany,
      status,
    }).unwrap();
    setData(getLeaderboard);
  };

  const handleSearchInput = (value: string) => {
    setSearchText(value);
    setPagination({
      limit: 10,
      offset: 0,
    });
  };

  return (
    <CustomModal
      width={720}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      title={`Participating Employees (${data?.metadata?.total || ""})`}
    >
      <DataTableFilters
        handleSearchInput={handleSearchInput}
        setSelectedCompany={setSelectedCompany}
        handleStatus={setStatus}
        isStatusFilter={true}
      />

      {isLoading ? (
        <CustomLoader />
      ) : (
        <>
          <ChallengeDataTable
            data={
              data?.data?.length
                ? data.data.map((item: any) => ({
                    ...item,
                  }))
                : []
            }
            isMedalShow={true}
            isModalVisible={true}
          />
          <div className={"pagination-margin"}>
            <Pagination
              onChange={(page, pageSize) => {
                setCurrentPage(page);
                setPagination({
                  offset: (page - 1) * pageSize,
                  limit: pageSize,
                });
              }}
              current={currentPage}
              pageSize={pagination?.limit}
              showTotal={(total, range) => (
                <span>
                  Showing&nbsp;
                  <b>
                    {range[0]}-{range[1]}
                  </b>
                  &nbsp;of <b>{total}</b>&nbsp; items
                </span>
              )}
              total={data?.metadata?.total || 0}
            />
          </div>
        </>
      )}
    </CustomModal>
  );
};

export default AllUsersLeaderboard;
