import { List } from "antd";
import styles from "features/rewards/css/UserStats.module.scss";
import { useTranslation } from "react-i18next";
import { useUserStatsViewModel } from "../hooks/useUserStatsViewModel";
import UserItem from "./UserItem";
import UserStatsDetails from "./UserStatsDetails";
interface UserStatsProps {
  tierId: string;
}

const UserStats: React.FunctionComponent<UserStatsProps> = ({ tierId }) => {
  const { t } = useTranslation();

  const vm = useUserStatsViewModel({ tierId });

  return (
    <div>
      <div className={styles["header"]}>
        <div className={styles["title"]}>{`${t("users")} (${
          vm.usersData?.getUsersListByTier.metadata.total ?? 0
        })`}</div>
        <div onClick={() => vm.setVisible(true)} className={styles["see-btn"]}>
          {t("see-more")}
        </div>
      </div>
      <div className={styles["desc-container"]}>
        <List
          dataSource={vm.usersData?.getUsersListByTier.data}
          loading={vm.isLoading}
          renderItem={(item, index) => (
            <UserItem
              id={index + 1}
              image={item.image ?? ""}
              name={item.userName}
              points={item.points}
              companyName={item.companyName}
            />
          )}
        />
      </div>
      {vm.visible && (
        <UserStatsDetails
          visible={vm.visible}
          data={vm.data}
          pagination={vm.pagination}
          currentPage={vm.currentPage}
          isFetching={vm.isFetching}
          onPaginationChange={vm.onPaginationChange}
          handleSearchInput={vm.handleSearchInput}
          handleCancel={vm.handleCancel}
          setCompanyId={vm.setCompanyId}
        />
      )}
    </div>
  );
};

export default UserStats;
