import { DeleteOutlined, PlusCircleFilled } from "@ant-design/icons";
import { Col, Row } from "antd";
import { UndoSvg } from "assets/icons";
import CustomButton from "components/custom-button/CustomButton";
import CustomDivider from "components/custom-divider/CustomDivider";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { FieldArray } from "formik";
import { useTranslation } from "react-i18next";
import styles from "../css/AddPartnerReward.module.scss";
import { RewardStocksProps } from "../hooks/useAddPartnerRewardViewModel";

type Props = {
  availableRewardStocks: Array<RewardStocksProps>;
};

export default function AvailableCodes({ availableRewardStocks }: Props) {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles["reward-code-title"]}>{t("reward-code")}</div>
      <CustomDivider />
      <FieldArray
        name="availableRewardStocks"
        render={(arrayHelpers) => (
          <div>
            {availableRewardStocks && availableRewardStocks.length
              ? availableRewardStocks.map(
                  (reward: RewardStocksProps, index: number) => (
                    <div key={index}>
                      <Row gutter={[24, 24]}>
                        <Col sm={12}>
                          <CustomTextField
                            name={`availableRewardStocks.[${index}].rewardCode`}
                            placeholder={t("type-code")}
                          />
                        </Col>
                        <Col sm={12}>
                          <div className={styles["reward-delete-btn"]}>
                            <CustomButton
                              className="light-btn"
                              icon={
                                reward.isDeleted ? (
                                  <UndoSvg />
                                ) : (
                                  <DeleteOutlined />
                                )
                              }
                              onClick={() => {
                                arrayHelpers.replace(index, {
                                  ...reward,
                                  isDeleted: !reward.isDeleted,
                                });
                              }}
                            ></CustomButton>
                          </div>
                        </Col>
                      </Row>
                      <CustomDivider />
                    </div>
                  ),
                )
              : null}
            <CustomButton
              className="light-btn"
              icon={<PlusCircleFilled />}
              onClick={() => arrayHelpers.push({})}
            >
              {t("add-code")}
            </CustomButton>
          </div>
        )}
      />
    </div>
  );
}
