import { Filter } from "./types";

/**
 * @description  finds and returns a match in filters object for the provided key
 * @param filters filters object
 * @param searchKey key to be searched
 * @return Filter filter
 */
export function searchFilters(
  filters: Record<string, Filter[]>,
  searchKey?: string,
): Filter | undefined {
  if (!searchKey) return;
  const allFilters: Filter[] = Object.values(filters).flat(); // flatten all filter arrays into a single array
  return allFilters.find((f) => f.key === searchKey); // find and return the filter with the matching key
}
