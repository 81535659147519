import styles from "./MetricOptions.module.scss";

import MetricRow from "./MetricRow";
import generateUniqueId from "helpers/generateUniqueId";
import CustomButton from "components/custom-button/CustomButton";
import metricOptionInitialObject from "features/assessment/data/metricOptionInitialObject";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import IMetric from "features/assessment/types/IMetric";
import IMetricTitles from "features/assessment/types/IMetricTitles";
import IMetricOptions from "features/assessment/types/IMetricOptions";
import IQuestionMetrices from "features/assessment/types/IQuestionMetrices";
import { Col, Row } from "antd";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import customNotification from "components/custom-notification/CustomNotification";
import { errorFormat } from "helpers";
import CustomPopconfirm from "components/form/custom-pop-confirm/CustomPopConfirm";
import EnumBoolean from "features/assessment/types/EnumBoolean";
import { MatrixDropdownGenerator } from "features/assessment/data/MatrixGenerator";
import { useTranslation } from "react-i18next";

interface MatricOptionProps extends IMetric {
  setDeleteOptionIds?: (ids: Array<string>) => void;
  deletedOptionIds?: any;
  isAddLanguage?: boolean;
}

const MetricOptions: React.FunctionComponent<MatricOptionProps> = (props) => {
  const { t } = useTranslation();
  const mapMetricRow = () => {
    // const metrices: Array<IQuestionMetrices> = [];

    let metricTitles: Array<IMetricTitles> = [];
    let metricOptions: Array<IMetricOptions> = [];

    let metrices: any = [];

    // MAPPING OPTIONS

    for (
      let optIndex = 1;
      optIndex <= props?.values?.numberOfOptions;
      optIndex++
    ) {
      // UPDATING METRIC OPTIONS
      metricOptions = [
        ...metricOptions,
        {
          id: optIndex,
          value: "",
        },
      ];
    }

    for (
      let titleIndex = 1;
      titleIndex <= props?.values?.numberOfTitles;
      titleIndex++
    ) {
      // UPDATING METRIC TITLES
      metricTitles = [
        ...metricTitles,
        {
          id: titleIndex,
          value: "",
        },
      ];

      // CREATING METRIC HASH MAP
      metricOptions.map((optItem: any) => {
        const matrixKey = `ROW${titleIndex}COL${optItem.id}`;
        metrices = [
          ...metrices,
          {
            ...metricOptionInitialObject,
            matrixKey: matrixKey,
            row: titleIndex,
            column: optItem.id,
          },
        ];
      });
    }

    //END OPTIONS
    if (props?.values?.metrices?.length) {
      if (props?.setDeleteOptionIds) {
        props.setDeleteOptionIds([
          ...props.deletedOptionIds,
          ...props.values.metrices
            .filter((item: any) => item?.savedOnServer === EnumBoolean.YES)
            .map((item: any) => item.id),
        ]);
      }
    }

    if (props?.setValues) {
      props?.setValues({
        ...props?.values,
        metrices,
        metricOptions,
        metricTitles,
      });
    }

    return null;
  };

  const numberOfOptionsDropdown = [...MatrixDropdownGenerator(5)];
  const numberOfTitlesDropdown = [...MatrixDropdownGenerator(5)];

  return (
    <>
      <div className={styles["metric-header"]}>
        <CustomSelectField
          allowClear
          maxTagCount="responsive"
          name="numberOfOptions"
          disabled={props?.isDisabled || props?.isAddLanguage}
          placeholder={t("number-of-options")}
          options={numberOfOptionsDropdown}
        />

        <CustomSelectField
          allowClear
          maxTagCount="responsive"
          name="numberOfTitles"
          disabled={props?.isDisabled || props?.isAddLanguage}
          placeholder={t("number-of-titles")}
          options={numberOfTitlesDropdown}
        />
        <div>
          <CustomPopconfirm
            okText={t("yes")}
            onConfirm={async () => {
              mapMetricRow();
            }}
            title={t("confirm-matrix-update")}
          >
            <CustomButton
              onClick={(e) => {
                e.stopPropagation();
              }}
              disabled={
                !props.values.numberOfOptions ||
                !props.values.numberOfTitles ||
                props.isDisabled ||
                props?.isAddLanguage
              }
              className="primary-btn"
            >
              {t("update")}
            </CustomButton>
          </CustomPopconfirm>
        </div>
      </div>

      <div className={styles["metric-row"]}>
        <div />
        <div className={styles["sub-flex"]}>
          {props?.values.metricOptions?.map((optItem: any, index: number) => (
            <div className={styles["metric-option"]} key={optItem.id}>
              <CustomTextField
                marginBtm={false}
                disabled={props?.isDisabled || props?.isAddLanguage}
                name={`metricOptions[${index}].value`}
                placeholder={`${t("option")} ${optItem.id}`}
              />
            </div>
          ))}
        </div>
      </div>

      <div>
        {props?.values.metricTitles?.map((item: any, index: number) => {
          return (
            <MetricRow
              values={props?.values}
              key={index}
              titleIndex={index}
              tags={props.tags}
              isDisabled={props?.isDisabled || props?.isAddLanguage}
              item={item}
            />
          );
        })}
      </div>
    </>
  );
};

export default MetricOptions;
