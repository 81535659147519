import { List, Radio, Space } from "antd";
import CustomModal from "components/custom-modal/CustomModal";
import {
  GetTeamLeaderboardQuery,
  ManualTeamAssignmentInput,
} from "graphql/_generated/graphql";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styles from "features/events/css/TeamLeaderBoard.module.scss";
import { Form, Formik, FormikHelpers } from "formik";
import ModalFooter from "components/custom-modal/ModalFooter";
import CustomPagination from "components/custom-pagination/CustomPagination";

interface ChangeTeamProps {
  visible: boolean;
  onCancel: () => void;
  isLoading: boolean;
  isLoadingChangeTeam: boolean;
  teamsData?: GetTeamLeaderboardQuery["getTeamLeaderboard"];
  current: number;
  onChange: (page: number, pageSize: number) => void;
  onSubmitChangeTeam: (
    values: ManualTeamAssignmentInput,
    formikHelpers?: FormikHelpers<ManualTeamAssignmentInput>,
  ) => Promise<boolean | undefined>;
  eventId: string;
  teamId: string;
  participantIds: string[];
}

const ChangeTeam: React.FunctionComponent<ChangeTeamProps> = ({
  visible,
  onCancel,
  isLoading,
  teamsData,
  current,
  eventId,
  teamId,
  participantIds,
  isLoadingChangeTeam,
  onChange,
  onSubmitChangeTeam,
}) => {
  const { t } = useTranslation();
  return (
    <CustomModal
      centered
      width={720}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      title={t("select-designated-team")}
    >
      <Formik
        enableReinitialize
        initialValues={{
          eventId: eventId,
          participantIds: participantIds,
          teamId: teamId ?? "",
        }}
        onSubmit={onSubmitChangeTeam}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div
              className={`${styles["leaderboard-container"]} ${styles["mb-1"]} `}
            >
              <List
                loading={isLoading}
                size="small"
                itemLayout="horizontal"
                dataSource={teamsData?.data}
                renderItem={(item) => (
                  <List.Item
                    style={{ cursor: "pointer" }}
                    key={item.id}
                    onClick={() => setFieldValue("teamId", item.id)}
                  >
                    <Space size={16}>
                      <Radio checked={item.id === values.teamId} />
                      <div
                        style={{
                          backgroundColor: item.color,
                        }}
                        className={styles["team-color"]}
                      />
                      <div className={styles["team-title"]}>{item.title}</div>
                    </Space>
                  </List.Item>
                )}
              />
            </div>
            <div className={styles["mb-1"]}>
              <CustomPagination
                responsive
                showSizeChanger
                total={teamsData?.total}
                onChange={onChange}
                current={current}
              />
            </div>
            <ModalFooter
              loading={isLoadingChangeTeam}
              saveText={t("move")}
              onCancel={onCancel}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default ChangeTeam;
