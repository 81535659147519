import {
  Regions,
  useGetClientListQuery,
  useGetClientListWithoutChildrenQuery,
  useGetClientsTreeQuery,
  useGetConfigRolesQuery,
  useGetRegionListQuery,
} from "graphql/_generated/graphql";

export const useGetCompanyConfig = ({
  regionalKey,
}: {
  regionalKey: Regions;
}) => {
  const {
    data: companiesData,
    refetch: refetchCompanies,
    isFetching: isLoadingClientsTree,
  } = useGetClientsTreeQuery(
    { region: regionalKey },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const {
    isFetching: isFetchingListWithoutChildren,
    refetch: refetchListWithoutChildren,
  } = useGetClientListWithoutChildrenQuery();
  const { isFetching: isFetchingClientList, refetch: refetchClientList } =
    useGetClientListQuery();
  const { data: rolesData } = useGetConfigRolesQuery();
  const { data: regionList } = useGetRegionListQuery();

  return {
    companiesData,
    refetchCompanies,
    isLoadingClientsTree,
    isFetchingListWithoutChildren,
    refetchListWithoutChildren,
    isFetchingClientList,
    refetchClientList,
    rolesData,
    regionList,
  };
};
