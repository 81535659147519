import {
  MutationReInviteClientArgs,
  useReInviteClientMutation,
} from "graphql/_generated/graphql";

export const useResendCompanyInvite = () => {
  const [resendInviteFun, { isLoading }] = useReInviteClientMutation();
  const handleResendInvite = async (values: MutationReInviteClientArgs) => {
    try {
      await resendInviteFun({ clientId: values.clientId }).unwrap();
    } catch (error) {
      throw error;
    }
  };
  return {
    isLoading,
    handleResendInvite,
  };
};
