import CheckPermissions from "components/check-permissions/CheckPermissions";
import CustomButton from "components/custom-button/CustomButton";
import { PERMS } from "helpers";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AddBuilding from "features/buildings/add-building/AddBuilding";
import BuildingList from "features/buildings/buildings-list/BuildingsList";
import styles from "features/buildings/Buildings.module.scss";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
type Props = {};

const Buildings = (props: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <DashboardLayout openKey={["Configs"]}>
        <Breadcrumbs
          data={[
            {
              label: "Configurations",
            },
            {
              label: t("buildings-title"),
            },
          ]}
        />
        <BuildingList />
      </DashboardLayout>
    </>
  );
};

export default Buildings;
