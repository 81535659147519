import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { customNotification } from "components/custom-notification/CustomNotification";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { errorFormat, getLanguage } from "helpers";
import { Form, Formik } from "formik";
import { Language } from "graphql/_generated/graphql";
import { addLanguageSchema } from "validations/schema";
import { useLanguages } from "hooks";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useErrorHandler } from "hooks/useErrorHandler";

interface AddLanguageProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  data: any;
}

const AddLanguage: React.FunctionComponent<AddLanguageProps> = ({
  setVisible,
  visible,
  data,
}) => {
  const { languages } = useLanguages();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const addedLanguages = data?.assessmentLanguages.map(
    (item: any) => item.language,
  );

  const handleCancel = () => {
    setVisible(false);
  };

  const initialValues = {
    language: "",
  };

  return (
    <CustomModal
      width={600}
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      title={t("add-new-language")}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={addLanguageSchema}
        onSubmit={async (values, { resetForm }) => {
          try {
            setVisible(false);
            resetForm();
            let assessmentRoute = "/assessment/add-language";
            if (data?.assessmentTypeId?.name?.includes("Check-In")) {
              assessmentRoute = "/assessment/checkin/add-language";
            }
            navigate(assessmentRoute, {
              state: {
                assessmentId: data.id,
                name: data.name,
                language: getLanguage(values.language.toLowerCase()),
              },
            });
          } catch (error) {
            handleError(error as string | object);
          }
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <CustomSelectField
              name="language"
              placeholder={t("select-assessment-language")}
              options={
                languages.filter(
                  (item) =>
                    item.label !== data?.languageText &&
                    !addedLanguages?.includes(item.label),
                ) || []
              }
            />
            <ModalFooter saveText={t("add-language")} onCancel={handleCancel} />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddLanguage;
