import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StringMap } from "../../components/interfaces";

export interface CounterState {
  direction: string | null;
  theme: string;
  visibleWidgets: (string | Widgets)[];
}

export enum Widgets {
  OVERALL_PILLAR_ASSESSMENT = "Overall Pillar Assessment",
  USERS = "Users",
  HABITS = "Habits",
  CHALLENGES = "Challenges",
  DAILY_REFLECTIONS = "Daily Reflections",
  METRICS = "Stats",
}

const initialState: CounterState = {
  direction: null,
  theme: "light",
  visibleWidgets: Object.values(Widgets),
};

export const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState,
  reducers: {
    direction: (state, { payload }) => {
      state.direction = payload;
    },
    theme: (state, { payload }) => {
      state.theme = payload;
    },
    visibleWidgets: (state, { payload }) => {
      state.visibleWidgets = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { direction, theme, visibleWidgets } = dashboardSlice.actions;

export default dashboardSlice.reducer;
