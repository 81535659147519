import {
  useUpdateClientAdminEmailMutation,
  MutationUpdateClientAdminProfileArgs,
  useUpdateClientAdminProfileMutation,
  useLazyGetUserProfileQuery,
  useUpdateViwellAdminEmailMutation,
  useUpdateViwellAdminProfileMutation,
  MutationUpdateViwellAdminProfileArgs,
} from "graphql/_generated/graphql";

export const useUpdateAdmin = () => {
  const [getProfileFun, { isLoading }] = useLazyGetUserProfileQuery();

  const [updateEmailFun, { isLoading: isLoadingEmail }] =
    useUpdateClientAdminEmailMutation();

  const [updateAdminFun, { isLoading: isLoadingAdminUpdate }] =
    useUpdateClientAdminProfileMutation();

  const [updateViwellAdminEmailFun, { isLoading: isLoadingViwellAdminEmail }] =
    useUpdateViwellAdminEmailMutation();

  const [
    updateViwellAdminProfileFun,
    { isLoading: isLoadingViwellAdminProfile },
  ] = useUpdateViwellAdminProfileMutation();

  const handleGetAdmin = async (id: string) => {
    try {
      const res = await getProfileFun({
        userId: id,
      }).unwrap();
      return res.getUserProfile;
    } catch (error) {
      throw error;
    }
  };

  const handleUpdateClientAdminEmail = async (id: string, email: string) => {
    try {
      const res = await updateEmailFun({
        clientId: id,
        newEmail: email,
      }).unwrap();
      return res.updateClientAdminEmail.msg;
    } catch (error) {
      throw error;
    }
  };

  const handleUpdateClientAdminProfile = async (
    values: MutationUpdateClientAdminProfileArgs,
  ) => {
    try {
      const res = await updateAdminFun({
        clientId: values.clientId,
        update: values.update,
      }).unwrap();
    } catch (error) {
      throw error;
    }
  };

  const handleUpdateViwellAdminEmail = async (id: string, email: string) => {
    try {
      const res = await updateViwellAdminEmailFun({
        adminId: id,
        newEmail: email,
      }).unwrap();
      return res.updateViwellAdminEmail.msg;
    } catch (error) {
      throw error;
    }
  };

  const handleUpdateViwellAdminProfile = async (
    values: MutationUpdateViwellAdminProfileArgs,
  ) => {
    try {
      await updateViwellAdminProfileFun({
        update: values.update,
        adminId: values.adminId,
      }).unwrap();
    } catch (error) {
      throw error;
    }
  };

  return {
    handleGetAdmin,
    handleUpdateClientAdminEmail,
    handleUpdateClientAdminProfile,
    handleUpdateViwellAdminEmail,
    handleUpdateViwellAdminProfile,
    isLoading:
      isLoading ||
      isLoadingEmail ||
      isLoadingAdminUpdate ||
      isLoadingViwellAdminEmail ||
      isLoadingViwellAdminProfile,
  };
};
