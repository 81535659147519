import "features/onboardings/css/OnBoarding.module.scss";

import CollapseAble from "features/assessment/questions/collapseables/CollapseAble";
import uploadImgPlaceholder from "assets/images/imgPlaceHolder.svg";
import { CustomTextArea } from "components/form/custom-text-area/CustomTextArea";
import { CustomUploadFile } from "components/form/custom-upload-file/CustomUploadFile";
import { InfoIcon } from "assets/icons/InfoIcon";
import { Form, Formik } from "formik";
import { useCreateAssessmentCarousal } from "features/assessment/hooks/useCreateAssessmentCarousal";
import { customNotification } from "components/custom-notification/CustomNotification";
import { useUpdateAssessmentCarousal } from "features/assessment/hooks/useUpdateAssessmentCarousal";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useDeleteAssessmentCarousal } from "features/assessment/hooks/useDeleteAssessmentCarousal";
import { checkinOnboardingSchema } from "validations/schema/assessmentSchema";
import { useAddAndUpdateCarousalTranslation } from "features/assessment/hooks/useAddAndUpdateCarousalTranslation";
import { useTranslation } from "react-i18next";
import { useErrorHandler } from "hooks/useErrorHandler";

interface OnBoardingProps {
  getCarousals?: () => any;
  carousal: any;
  onBoardingId?: string | undefined;
  isAddLanguage?: boolean;
  onDelete?: () => void;
}

const OnBoarding: React.FunctionComponent<OnBoardingProps> = (props) => {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const { handleDeleteAssessmentCarousalFun, isLoadingDeleteAssessment } =
    useDeleteAssessmentCarousal();
  const location = useLocation();
  const params = location.state;

  const handleDelete = async () => {
    props.onDelete && props.onDelete();
    if (props?.onBoardingId) {
      try {
        await handleDeleteAssessmentCarousalFun({
          carousalId: props?.onBoardingId,
        });
        props.getCarousals && props.getCarousals();
        customNotification("success", t("onboarding-deleted-successfully"));
      } catch (error) {
        handleError(error as string | object);
      }
    }
  };

  const { handleCreateAssessmentCarousalFun, isLoadingCreateAssessment } =
    useCreateAssessmentCarousal();

  const { handleUpdateAssessmentCarousalFun, isLoadingUpdateAssessment } =
    useUpdateAssessmentCarousal();

  const { handleAddUpdateCarousalTranslation } =
    useAddAndUpdateCarousalTranslation();

  const [editMode, setEditMode] = useState<boolean>(false);
  const toggleEditMode = () => setEditMode(!editMode);
  return (
    <div className="OnBoarding-container">
      <Formik
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize={true}
        initialValues={props.carousal}
        validationSchema={props?.isAddLanguage ? null : checkinOnboardingSchema}
        onSubmit={async (values, { resetForm }) => {
          try {
            if (props?.isAddLanguage) {
              const data = {
                create: {
                  assessmentId: params?.assessmentId,
                  carousal: {
                    description: values.description || "",
                    carousalId: props.onBoardingId!,
                    language: params?.language,
                  },
                },
              };

              await handleAddUpdateCarousalTranslation(data);
              customNotification(
                "success",
                t("translation-added-updated-successfully"),
              );
            } else {
              if (!props?.onBoardingId) {
                await handleCreateAssessmentCarousalFun({
                  create: [
                    {
                      description: values.description,
                      image: values.image,
                      assessmentId: params?.assessmentId,
                    },
                  ],
                });

                props.getCarousals && props.getCarousals();
                customNotification(
                  "success",
                  t("onboarding-added-successfully"),
                );
              } else {
                await handleUpdateAssessmentCarousalFun({
                  updateCarousal: {
                    description: values.description,
                    image: values.image,
                  },
                  carousalId: props.onBoardingId!,
                });
                customNotification(
                  "success",
                  t("onboarding-updated-successfully"),
                );
              }
            }
          } catch (error) {
            handleError(error as string | object);
          }

          toggleEditMode();
        }}
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <Form>
            <CollapseAble
              collapsible="icon"
              defaultActiveKey={["1"]}
              headerKey={"1"}
              expandIconPosition="end"
              hideExtra={false}
              extraProps={{
                handleDelete: handleDelete,
                handleEdit: toggleEditMode,
                handleUpdate: handleSubmit,
                isEditMode: editMode,
                isAddLanguage: props?.isAddLanguage,
                isCheckIn: true,
              }}
            >
              <CustomUploadFile
                label={t("onboarding-image")}
                name="image"
                disabled={!editMode || props?.isAddLanguage}
                customUploadText={
                  <span>
                    {t("drop-image-here")} <b>{t("click-to-browse")}</b>
                  </span>
                }
                imgUrl={props.carousal?.image}
                customUploadImgSrc={uploadImgPlaceholder}
                setFieldValue={setFieldValue}
              />
              <CustomTextArea
                disabled={!editMode}
                customProps={{ icon: InfoIcon }}
                rows={2}
                label={t("description")}
                name="description"
                placeholder={t("onboarding-description")}
                isAddLanguage={props?.isAddLanguage}
                oldTextValue={props?.carousal?.mainText}
              />
            </CollapseAble>
          </Form>
        )}
      </Formik>

      {/* <CustomButton htmlType="submit">Submit</CustomButton> */}
    </div>
  );
};

export default OnBoarding;
