import { customNotification } from "components/custom-notification/CustomNotification";
import {
  MutationInviteClientArgs,
  useGetClientListQuery,
  useGetClientsTreeQuery,
  useInviteClientMutation,
} from "graphql/_generated/graphql";
import { useTranslation } from "react-i18next";
import { useResendCompanyInvite } from "../companies-list/useResendCompanyInvite";

export type TypeInviteCompany = (
  values: MutationInviteClientArgs,
) => Promise<void>;

//define the type of useCompany hook
type TypeUseInviteCompany = () => {
  isLoading: boolean;
  inviteCompany: TypeInviteCompany;
};

export const useInviteCompany: TypeUseInviteCompany = () => {
  const { t } = useTranslation();
  const [inviteCompanyFun, { isLoading }] = useInviteClientMutation();
  const { refetch, isLoading: getClientsLoading } = useGetClientListQuery();
  const { refetch: companiesTreeRefetch } = useGetClientsTreeQuery();

  //this function is responsible for adding the Pillar data
  const inviteCompany = async (values: MutationInviteClientArgs) => {
    try {
      await inviteCompanyFun({ client: values.client }).unwrap();
      customNotification("success", t("success-invite-company"));
      refetch();
      companiesTreeRefetch();
    } catch (error: any) {
      throw error;
    }
  };

  return {
    isLoading: isLoading || getClientsLoading,
    inviteCompany,
  };
};
