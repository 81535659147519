import {
  GetCheckinStatsQueryVariables,
  useLazyGetCheckinStatsQuery,
} from "graphql/_generated/graphql";

export const useGetCheckinStats = () => {
  const [checkinStatFun, { isFetching }] = useLazyGetCheckinStatsQuery();

  const handleGetCheckinStats = async (
    values: GetCheckinStatsQueryVariables,
  ) => {
    try {
      const res = await checkinStatFun(values).unwrap();

      return res.getCheckinStats;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleGetCheckinStats,
    isLoading: isFetching,
  };
};
