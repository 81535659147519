import * as Yup from "yup";
import * as Validations from "./common/validations";

export const buildingSchema = Yup.object().shape({
  name: Validations.requiredFieldWithoutSpaces("name"),
  address: Yup.object().shape({
    address: Validations.required("address"),
    // coordinates: Yup.object().shape({
    //   lat: Validations.required("latitude"),
    //   lng: Validations.required("longitude"),
    // }),
    country: Validations.required("country"),
  }),
});
