import AuthLayout from "../../features/auth/auth-layout/AuthLayout";

const VerifyOtp = () => {
  return (
    <AuthLayout>
      <h2>Verification link has been sent to your email</h2>
      <h1>a************@gmail.com</h1>
    </AuthLayout>
  );
};

export default VerifyOtp;
