import {
  convertTranslationArrToObj,
  convertTranslationObjToArr,
} from "helpers";
import {
  MutationCreateOnBoardingArgs,
  MutationUpdateOnBoardingArgs,
  useCreateOnBoardingMutation,
  useUpdateOnBoardingMutation,
  useLazyGetOnboardingQuery,
  useGetOnBoardingListQuery,
  QueryGetOnboardingArgs,
  useToggleOnboardingMutation,
} from "graphql/_generated/graphql";

export const useOnboarding = () => {
  const [addOnBoardingFun, { isLoading }] = useCreateOnBoardingMutation();
  const [toggleOnBoardingFun, { isLoading: isToggling }] =
    useToggleOnboardingMutation();
  const [updateOnBoardingFun, { isLoading: isUpdating }] =
    useUpdateOnBoardingMutation();
  const [getOnBoarding, { isLoading: isFetching }] =
    useLazyGetOnboardingQuery();
  const { refetch } = useGetOnBoardingListQuery();

  //this function is responsible for adding the onBoarding data
  const handleAddOnBoarding = async (values: MutationCreateOnBoardingArgs) => {
    try {
      let translations = await convertTranslationArrToObj(
        values.create.translations,
      );

      let params = { create: { ...values.create, translations } };
      await addOnBoardingFun(params).unwrap();
      refetch();
    } catch (error: any) {
      throw error;
    }
  };

  const handleUpdateOnBoarding = async (
    values: MutationUpdateOnBoardingArgs,
  ) => {
    try {
      let translations = await convertTranslationArrToObj(
        values.update.translations,
      );

      const params = {
        update: { ...values.update, translations },
        updateOnBoardingId: values.id,
      };
      await updateOnBoardingFun(params).unwrap();
      refetch();
    } catch (error: any) {
      throw error;
    }
  };

  const handleFetchOnBoarding = async (params: QueryGetOnboardingArgs) => {
    try {
      const res = await getOnBoarding({
        getOnboardingId: params.id,
      }).unwrap();
      const translations = await convertTranslationObjToArr(
        res.getOnboarding?.translations,
      );

      return { ...res?.getOnboarding, translations };
    } catch (error) {
      throw error;
    }
  };

  const handleToggleOnBoarding = async (id: string) => {
    try {
      return await toggleOnBoardingFun({
        toggleOnboardingId: id,
      }).unwrap();
      refetch();
    } catch (error: any) {
      throw error;
    }
  };

  return {
    isLoading: isLoading || isUpdating || isFetching || isToggling,
    handleAddOnBoarding,
    handleUpdateOnBoarding,
    handleFetchOnBoarding,
    handleToggleOnBoarding,
  };
};
