import Playground from "pages/playground/Playground"

const PlaygroundRoutes = [
  {
    path: "/playground",
    element: <Playground />,
  },
];

export default PlaygroundRoutes;
