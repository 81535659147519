import { useAddPartner } from "./useAddPartner";
import { useAddRewardCategory } from "./useAddRewardCategory";
import { useAddTier } from "./useAddTier";
import { useDeletePartner } from "./useDeletePartner";
import { useDeleteRewardCategory } from "./useDeleteRewardCategory";
import { useDeleteTier } from "./useDeleteTier";
import { useGetPartnerDetail } from "./useGetPartnerDetail";
import { useGetRegionVisibility } from "./useRegionVisibility";
import { useTogglePartnerActivation } from "./useTogglePartnerActivation";
import { useToggleRewardCategory } from "./useToggleRewardCategory";
import { useToggleTierActivation } from "./useToggleTierActivation";
import { useUpdatePartner } from "./useUpdatePartner";
import { useUpdateRewardCategory } from "./useUpdateRewardCategory";
import { useUpdateTier } from "./useUpdateTier";

export const useTier = () => {
  const { isLoading, handleAddTier } = useAddTier();

  const { handleTierActivation, isLoading: isLoadingTierActivation } =
    useToggleTierActivation();

  const {
    handleGetTierDetail,
    handleUpdateTier,
    isLoading: isLoadingTierDetail,
  } = useUpdateTier();

  const { isLoading: isLoadingDeleteTier, handleDeleteTier } = useDeleteTier();

  const { handleAddRewardCategory, isLoading: isLoadingAddRewardCategory } =
    useAddRewardCategory();

  const { handleToggleRewardCategory, isLoading: isLoadingToggleReward } =
    useToggleRewardCategory();

  const {
    handleGetRewardCategory,
    handleUpdateRewardCategory,
    isLoading: isLoadingRewardCategory,
  } = useUpdateRewardCategory();

  const { handleDeleteRewardCategory, isLoading: isLoadingDeleteCategory } =
    useDeleteRewardCategory();

  const { handleAddPartner, isLoading: isLoadingAddPartner } = useAddPartner();

  const { handleUpdatePartner, isLoading: isLoadingUpdatePartner } =
    useUpdatePartner();

  const { handleDeletePartner, isLoading: isLoadingDeletePartner } =
    useDeletePartner();

  const {
    handleTogglePartnerActivation,
    isLoading: isLoadingPartnerActivation,
  } = useTogglePartnerActivation();

  const { handleGetPartnerDetail, isLoading: isLoadingPartnerDetail } =
    useGetPartnerDetail();

  const { regionVisibility } = useGetRegionVisibility();

  return {
    handleAddTier,
    handleTierActivation,
    handleGetTierDetail,
    handleUpdateTier,
    handleDeleteTier,
    handleAddRewardCategory,
    handleToggleRewardCategory,
    handleGetRewardCategory,
    handleUpdateRewardCategory,
    handleDeleteRewardCategory,
    handleAddPartner,
    handleUpdatePartner,
    handleDeletePartner,
    handleTogglePartnerActivation,
    handleGetPartnerDetail,
    regionVisibility,
    isLoading:
      isLoading ||
      isLoadingTierActivation ||
      isLoadingTierDetail ||
      isLoadingDeleteTier ||
      isLoadingAddRewardCategory ||
      isLoadingToggleReward ||
      isLoadingRewardCategory ||
      isLoadingDeleteCategory ||
      isLoadingAddPartner ||
      isLoadingUpdatePartner ||
      isLoadingDeletePartner ||
      isLoadingPartnerActivation ||
      isLoadingPartnerDetail,
  };
};
