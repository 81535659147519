import { unixTimestampMs } from "helpers/DatetimeUtils";
import generateUniqueId from "helpers/generateUniqueId";
import EnumQuestionHierarchy from "features/assessment/types/EnumQuestionHierarchy";
import booleansInitialObject from "./booleansInitialObject";
import optionsInitialObject from "./optionsInitialObject";
import questionInitialObject from "./questionInitialObject";
import ratingsInitialObject from "./ratingsInitialObject";
import stepsInitialObject from "./stepsInitialObject";

/**
 * Generate a bare new question.
 *
 * @param totalQuestions int
 * @returns question with local id
 */
const generateNewQuestion = (
  totalQuestions: number,
  isSubQuestion?: boolean,
) => {
  const questionId = generateUniqueId();

  // GENERATING 5 RATINGs OPTIONs
  let ratings = [];
  let newRatingObject = { ...ratingsInitialObject };
  for (let i = 1; i <= 5; i++) {
    ratings.push({
      ...newRatingObject,
      id: generateUniqueId(),
      ratingNumber: i,
    });
  }

  return {
    [questionId]: {
      ...questionInitialObject,

      hierarchy: !!isSubQuestion
        ? EnumQuestionHierarchy.SUB_QUESTION
        : EnumQuestionHierarchy.PILLAR_QUESTION,
      parentOptionId: undefined,
      SN: totalQuestions + 1,
      id: questionId.toString(),
      ratings,
      options: [{ ...optionsInitialObject, id: generateUniqueId() }],
      steps: [{ ...stepsInitialObject, id: generateUniqueId() }],
      booleans: [{ ...booleansInitialObject, id: generateUniqueId() }],
      createdAt: unixTimestampMs(),
    },
  };
};

export default generateNewQuestion;
