import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import TranslationFieldArr from "components/translation-field-array/TranslationFieldArr";
import { ConfigDataProps } from "features/positions/hooks/useAddPositionViewModel";
import { Form, Formik, FormikHelpers } from "formik";
import { DepartmentCreate } from "graphql/_generated/graphql";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { departmentSchema } from "validations/schema";
import styles from "../departments/Departments.module.scss";

type Props = {
  visible: boolean;
  closeModal: () => void;
  inviteUserView?: boolean;
  isLoading: boolean;
  isClient: boolean;
  initialValues: DepartmentCreate;
  handleSubmit: (
    values: DepartmentCreate,
    { resetForm }: FormikHelpers<DepartmentCreate>,
  ) => void;
  redirectToDepartments: () => void;
  setFieldValue?: (field: string, value: string) => void;
  configData?: ConfigDataProps;
  isDeptsLoading?: boolean;
  setDepartmentId?: React.Dispatch<React.SetStateAction<string | null>>;
};

const AddDepartment = ({
  visible,
  closeModal,
  inviteUserView,
  initialValues,
  handleSubmit,
  isLoading,
  isClient,
  redirectToDepartments,
  setFieldValue,
  configData,
  isDeptsLoading,
  setDepartmentId,
}: Props) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (configData?.departmentId && !isDeptsLoading) {
      setDepartmentId?.(configData.departmentId);
      setFieldValue?.(
        "employeeCreateDto.departmentId",
        configData.departmentId,
      );
      setFieldValue?.("employeeCreateDto.positionId", "");
    }
  }, [configData, isDeptsLoading]);

  return (
    <CustomModal
      width={600}
      visible={visible}
      onCancel={closeModal}
      footer={null}
      title={t("department.add-department")}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={departmentSchema}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form>
            <CustomTextField
              label={t("department-name")}
              name="name"
              placeholder={t("department.department-name")}
            />
            {inviteUserView ? null : (
              <TranslationFieldArr values={values} attribute="name" />
            )}
            {inviteUserView && isClient ? (
              <div className={styles["department-configuration-details"]}>
                {t("to-add-more-details-visit")}
                <span
                  className={`cursor-pointer ${styles["department-configuration-hyperlink"]}`}
                  onClick={() => redirectToDepartments()}
                >
                  {t("configurations")}
                </span>
                {t("menu")}
              </div>
            ) : null}
            <ModalFooter
              saveText={t("save")}
              loading={isLoading}
              onCancel={closeModal}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddDepartment;
