import moment from "moment";

interface GetChallengeStatusProps {
  startDate: Date | undefined;
  expiryDate: Date | undefined;
  isActive: boolean | undefined;
}
export const getChallengeStatuses = ({
  startDate,
  expiryDate,
  isActive,
}: GetChallengeStatusProps) => {
  if (startDate && expiryDate) {
    const challengeStartDate = moment(startDate);
    const challengeExpriyDate = moment(expiryDate);
    const todayDate = moment();

    const isExpired = challengeExpriyDate < todayDate;
    const isInProgress =
      challengeStartDate <= todayDate && challengeExpriyDate >= todayDate;
    const isPlanned = challengeStartDate > todayDate;
    return { isExpired, isInProgress, isPlanned, isActive };
  }
  return {};
};
