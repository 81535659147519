import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import EnglishTranslationFile from "translations/en/translation.json";

// Set Name Spaces
const resources = {
  en: {
    common: EnglishTranslationFile.common,
    error: EnglishTranslationFile.error,
  },
  ar: {
    // common: EnglishTranslationFile.common,
    // error: EnglishTranslationFile.error,
  },
};

i18n
  // load translation using http -> see /public/locales
  .use(Backend)
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  .init({
    resources,

    // set the default namespace
    defaultNS: "common",
    lng: "en",

    fallbackLng: "en",
    debug: true,
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json?lng={{lng}}&{{ns}}`,
      addPath: `${process.env.PUBLIC_URL}/locales/add/{{lng}}/{{ns}}`,
    },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
