import { customNotification } from "components/custom-notification/CustomNotification";
import { errorFormat } from "helpers";
import {
  useToggleContentActivationMutation,
  useGetContentListQuery,
} from "graphql/_generated/graphql";

import { useAddContent } from "../hooks/useAddContent";
import { useDeleteSubTitle } from "../hooks/useDeleteSubTitle";
import { useUpdateContent } from "../hooks/useUpdateContent";
import { useErrorHandler } from "hooks/useErrorHandler";

export const useContent = () => {
  const [activationDeactivationFun, { isLoading }] =
    useToggleContentActivationMutation();
  const { refetch } = useGetContentListQuery();
  const { handleError } = useErrorHandler();
  const { isLoading: addContentLoading, handleAddContent } = useAddContent();
  const {
    isLoading: updateContentLoading,
    handleFetchEditContentData,
    handleUpdateContent,
  } = useUpdateContent();

  //this function is responsible for activation and deactivation content
  const handleToggleActivationContent = async (id: string) => {
    try {
      const res = await activationDeactivationFun({
        toggleContentActivationId: id,
      }).unwrap();
      refetch();
      customNotification(
        "success",
        res.toggleContentActivation == true
          ? "Content Activate successfully"
          : "Content Deactivate successfully",
      );
    } catch (error: any) {
      handleError(error as string | object);
    }
  };

  const { handleDeleteSubTitle, isLoading: deleteSubTitleLoading } =
    useDeleteSubTitle();

  return {
    isLoading:
      isLoading ||
      addContentLoading ||
      updateContentLoading ||
      deleteSubTitleLoading,
    handleToggleActivationContent,
    handleAddContent,
    handleFetchEditContentData,
    handleUpdateContent,
    handleDeleteSubTitle,
  };
};
