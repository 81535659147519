import { StringMap } from "../components/interfaces";

export function keyMapper(data: StringMap[], mapper: StringMap) {
  return data.map((item: StringMap) => {
    const mapped: StringMap = {};

    for (const key in item) {
      if (mapper.hasOwnProperty(key)) {
        mapped[mapper[key]] = item[key];
      }
    }
    return mapped;
  });
}
