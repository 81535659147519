import CustomButton from "components/custom-button/CustomButton";
import CustomTable from "components/custom-table/CustomTable";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Space, Switch, Tag } from "antd";
import CustomPopconfirm from "components/form/custom-pop-confirm/CustomPopConfirm";
import CustomDropDown from "components/custom-dropdown/CustomDropDown";
import { MoreOutlined } from "@ant-design/icons";
import AddOnBoarding from "pages/onboarding/AddOnBoarding";
import {
  OnBoardingList as OnBoardingListType,
  useGetOnBoardingListQuery,
  useLazyGetOnBoardingListQuery,
} from "graphql/_generated/graphql";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import { useOnboarding } from "../../features/onboardings/hooks/useOnboarding";
import customNotification from "components/custom-notification/CustomNotification";
import { errorFormat } from "helpers";
import { useLanguages } from "hooks";
import styles from "features/onboardings/css/OnBoardingList.module.scss";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import { useErrorHandler } from "hooks/useErrorHandler";

interface OnBoardingListProps {}

const OnBoardingList: React.FunctionComponent<OnBoardingListProps> = (
  props,
) => {
  const { t } = useTranslation();
  const { isFetching, refetch } = useGetOnBoardingListQuery();
  const [getOnBoardings] = useLazyGetOnBoardingListQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const [columnsData, setColumnsData] = useState<OnBoardingListType>();
  const [filtersData, setFiltersData] = useState<any>(null);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState<boolean>(false);
  const [editId, setEditId] = useState<string | null>(null);
  const { handleToggleOnBoarding } = useOnboarding();
  const { handleError } = useErrorHandler();
  const { languages } = useLanguages();

  useEffect(() => {
    fetchDataFromApi(getOnBoardings, setColumnsData, filtersData).catch((e) =>
      handleError(e),
    );
  }, [isFetching]);

  const handleMenu = ({ domEvent, key, keyPath, id }: any) => {
    if (key === "edit-onboarding") {
      handleEdit(id);
    }
  };

  const handleEdit = (id: string) => {
    setVisible(true);
    setEditId(id);
  };

  const columns: ColumnsType<any> = [
    {
      title: t("id"),
      key: "id",
      dataIndex: "id",
      sorter: {
        multiple: 1,
      },
      render: (_text, _record, index) =>
        (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: t("onboarding-title"),
      key: "title",
      dataIndex: "title",
    },
    {
      title: t("languages"),
      dataIndex: "translations",
      key: "translations",
      responsive: ["xxl"],
      render: (_text, record, _index) => (
        <Space>
          {[
            "English",
            ...(record.translations
              ? [
                  Object.keys(record.translations).map((key, index) => {
                    return languages.filter((item) => item.value === key)[0]
                      ?.label;
                  }),
                ]
              : []),
          ]}
        </Space>
      ),
    },
    {
      title: t("created-at"),
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: {
        multiple: 1,
      },
      render: (createdAt: string) => moment(createdAt).format("MMMM Do YYYY"),
    },
    {
      title: t("updated-at"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: {
        multiple: 1,
      },
      render: (updatedAt: string) => moment(updatedAt).format("MMMM Do YYYY"),
    },
    {
      align: "center" as "center",
      title: t("status"),
      dataIndex: "isActive",
      key: "isActive",
      render: (isActive: boolean) => {
        return isActive ? (
          <Tag className="active-tag">{t("active")}</Tag>
        ) : (
          <Tag className="de-active-tag">{t("deactivated")}</Tag>
        );
      },
    },
    {
      title: t("activation"),
      dataIndex: "id",
      key: "activation",
      align: "center",
      render: (id, { isActive }) => (
        <CustomPopconfirm
          onConfirm={async () => {
            try {
              const isActivated = await handleToggleOnBoarding(id);
              customNotification(
                "success",
                isActivated
                  ? t("onboarding-activation-success")
                  : t("onboarding-deactivate-success"),
              );
              refetch();
            } catch (error) {
              handleError(error as string | object);
            }
          }}
          title={
            isActive
              ? t("confirm-deactivate-onboarding")
              : t("confirm-activate-onboarding")
          }
        >
          <Switch className="custom-switch" defaultChecked checked={isActive} />
        </CustomPopconfirm>
      ),
    },
    {
      title: t("action"),
      dataIndex: "id",
      align: "center",
      render: (id, record, _index) => (
        <CustomDropDown
          menu={{
            items: [{ key: "edit-onboarding", label: t("edit-onboarding") }],
            onClick: ({ domEvent, key, keyPath }) =>
              handleMenu({ domEvent, key, keyPath, record, id }),
          }}
        >
          <CustomButton type="link" className="link-btn">
            <MoreOutlined />
          </CustomButton>
        </CustomDropDown>
      ),
    },
  ];

  return (
    <>
      <DashboardLayout openKey={["MobileSettings"]}>
        <>
          <Breadcrumbs
            data={[
              {
                label: "Mobile Settings",
              },
              {
                label: t("onboardings-title"),
              },
            ]}
          />
          <CustomTable
            columns={columns}
            totalItems={columnsData?.metadata.total}
            loading={isLoading}
            onClick={() => setVisible(true)}
            headerTitle={t("onboardings-title")}
            btnText={t("onboarding.add-screen")}
            dataSource={columnsData?.data}
            rowKey="id"
            filters={{
              handleCallback: async (filtersData: any) => {
                await fetchDataFromApi(
                  getOnBoardings,
                  setColumnsData,
                  filtersData,
                );
                setPageSize(filtersData?.pagination?.offset?.pageSize);
                setCurrentPage(filtersData?.pagination?.offset?.page);
                setFiltersData(filtersData);
              },
              date: [
                {
                  applyAs: "and",
                  key: "createdAt",
                  comparison: "Range",
                  placeholder: t("created"),
                },
              ],
              status: {
                applyAs: "and",
                key: "isActive",
                comparison: "Equal",
              },
            }}
          />
        </>
      </DashboardLayout>
      <AddOnBoarding
        visible={visible}
        setVisible={setVisible}
        editId={editId}
        setEditId={setEditId}
      />
    </>
  );
};

export default OnBoardingList;
