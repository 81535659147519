import { useLazyGetOfferByIdQuery } from 'graphql/_generated/graphql';

export const useGetOffer = () => {
  const [getOfferFun] = useLazyGetOfferByIdQuery();

  //this function is responsible for fetching data against provided offer id
  const handleFetchOffer = async (id: string) => {
    try {
      const res = await getOfferFun({ getOfferByIdId: id }).unwrap();
      return res.getOfferById;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleFetchOffer,
  };
};
