import {
  DeleteOutlined,
  EyeOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";
import { message, Modal, Progress, Space, Tooltip, Upload } from "antd";
import { UploadFile } from "antd/es/upload";
import { RcFile } from "antd/lib/upload";
import audio from "assets/images/audio.jpg";
import { InfoCircleFilled } from "@ant-design/icons";
import fileupload from "assets/images/file-upload.svg";
import fileIcon from "assets/images/fileupload.png";
import axios from "axios";
import { ErrorMessage } from "formik";
import { useLazyGetSignedUrlQuery } from "graphql/_generated/graphql";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { useErrorTranslation } from "validations/schema/common/useErrorTranslation";
import { errorFormat, getBase64, validateFileSize } from "helpers";
import {
  AUDIO_FILE_TYPES,
  DOCUMENT_FILE_TYPES,
  IMG_FILE_TYPES,
} from "helpers/validateFileSize";
import styles from "./CustomUploadFile.module.scss";
import { CONFIG } from "helpers/constants";
const baseImgUrl =
  process.env.REACT_APP_BASE_IMAGE_URL || CONFIG.BASE_IMAGE_URL;

type Props = {
  name: string;
  label?: ReactElement | string;
  setFieldValue: any;
  imgUrl?: string | null | any;
  accept?: string;
  durationFieldName?: string;
  className?: string;
  customUploadText?: string | JSX.Element;
  customUploadImgSrc?: string;
  disabled?: boolean;
  infoText?: string;
  showFullPreview?: boolean;
  uploadText?: string;
};

export function CustomUploadFile(props: Props) {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>("");
  const [defaultFileList, setDefaultFileList] = useState([]);
  const { errorT } = useErrorTranslation();

  const [progress, setProgress] = useState(0);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const [imgUploadFun] = useLazyGetSignedUrlQuery();
  const { name, label, disabled, uploadText } = props;
  const imgUrlExt = props.imgUrl?.split(".").pop().toLowerCase();

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (source: UploadFile | string) => {
    setPreviewOpen(true);

    if (typeof source === "string") {
      setPreviewImage(source);
      return;
    }

    source.preview =
      source.preview ||
      source.url ||
      (await getBase64(source.originFileObj as RcFile));
    setPreviewImage(source.preview);
  };
  //this function is responsible uploading the image to S3
  const uploadProps = {
    multiple: false,
    accept: props.accept,
    listType: "picture-card" as "picture-card",
    onStart(file: any) {
      if (props.durationFieldName) {
        var reader: any = new FileReader();
        reader.onload = function () {
          var aud = new Audio(reader.result);
          aud.onloadedmetadata = function () {
            props.setFieldValue(
              props.durationFieldName,
              Math.floor(aud.duration),
            );
          };
        };
        reader.readAsDataURL(file);
      }
    },

    onChange({ fileList }: any) {
      setDefaultFileList(fileList);
    },
    onRemove() {
      props.setFieldValue(name, "");
      setError(null);
    },
    beforeUpload: (file: any) => {
      const extension = file.name.split(".").pop().toLowerCase();
      const imgFormat =
        IMG_FILE_TYPES.includes(extension) ||
        AUDIO_FILE_TYPES.includes(extension) ||
        DOCUMENT_FILE_TYPES.includes(extension);

      if (!imgFormat) {
        message.error(`Please upload png, jpg or jpeg image`);
      }

      const isValidSize = validateFileSize(file);
      if (!isValidSize) {
        message.error("must be smaller than 5MB!");
      }
      return (imgFormat && isValidSize) || Upload.LIST_IGNORE;
    },

    async customRequest({ onSuccess, onError, file, onProgress }: any) {
      const config = {
        onUploadProgress: (event: any) => {
          const percent = Math.floor((event.loaded / event.total) * 100);
          setProgress(percent);
          if (percent === 100) {
            setTimeout(() => setProgress(0), 1000);
          }
          onProgress({ percent: (event.loaded / event.total) * 100 });
        },
      };

      const fmData = new FormData();
      try {
        let extension = file.name.split(".").pop().toLowerCase();
        const res = await imgUploadFun({ extension }).unwrap();
        fmData.append("body", file);
        const response = await axios.put(res.getSignedUrl, file, {
          ...config,
          headers: {
            "Content-Type": file.type,
          },
        });
        props.setFieldValue(
          name,
          response.request.responseURL
            .split("?")[0]
            .split("/")
            .slice(3)
            .join("/"),
        );
        onSuccess("Ok");
      } catch (error: any) {
        setError(errorFormat(message));
        onError({ error });
      }
    },
  };

  return (
    <>
      <div className={styles["form-control"]}>
        <Space className="custom-upload--intro" align="center">
          {label &&
            (React.isValidElement(label) ? (
              label
            ) : (
              <label className="label" htmlFor={name}>
                {label}
              </label>
            ))}

          {props.infoText && (
            <Tooltip
              trigger="click"
              color="#494949"
              title={<p>{props.infoText}</p>}
            >
              <InfoCircleFilled />
            </Tooltip>
          )}
        </Space>

        <Upload
          {...uploadProps}
          className="custom-upload"
          maxCount={1}
          listType="picture-card"
          onPreview={handlePreview}
          name={name}
          disabled={disabled}
          showUploadList={{
            showRemoveIcon: !props?.showFullPreview,
            showPreviewIcon: !props?.showFullPreview,
          }}
        >
          {defaultFileList!.length >= 1 &&
          !props?.showFullPreview ? null : props.imgUrl ? (
            <div
              className={
                props?.showFullPreview ? "img-full-preview" : "img-url"
              }
            >
              <img
                draggable={false}
                src={
                  imgUrlExt === "mp3"
                    ? audio
                    : imgUrlExt === "png" ||
                      imgUrlExt === "jpeg" ||
                      imgUrlExt === "jpg"
                    ? `${baseImgUrl}${props?.imgUrl}`
                    : fileIcon
                }
                className={
                  props?.showFullPreview ? "img-fluid-preview" : "img-fluid"
                }
                alt=""
              />
              {props?.showFullPreview && (
                <div className="img-border img-container-left"></div>
              )}
              {props.showFullPreview && (
                <div className="img-border img-container-right">
                  {props?.disabled ? null : (
                    <div>
                      <CloseCircleFilled
                        className="img-close-circle"
                        onClick={(e) => {
                          e.stopPropagation();
                          setDefaultFileList([]);
                          props.setFieldValue(name, "");
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
              {props?.showFullPreview ? null : (
                <div className="upload-action">
                  <div className="action">
                    <EyeOutlined
                      color="white"
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePreview(props.imgUrl);
                      }}
                    />
                    <DeleteOutlined
                      onClick={(e) => {
                        e.stopPropagation();
                        setDefaultFileList([]);
                        props.setFieldValue(name, "");
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          ) : (
            <Space
              direction="vertical"
              size={1}
              className={`${props?.className ?? ""}`}
            >
              <img
                className={uploadText ? "upload-img" : "img-fluid"}
                src={props?.customUploadImgSrc ?? fileupload}
                alt=""
              />
              <div className="file-text">
                {props?.customUploadText ?? uploadText ?? "Upload"}
              </div>
            </Space>
          )}
        </Upload>

        {progress > 0 ? <Progress percent={progress} /> : null}

        {/* this error message will show when error in the api */}
        {error ? (
          <ErrorMessage
            name={name}
            render={(msg) => <div style={{ color: "red" }}>{error}</div>}
          />
        ) : (
          <ErrorMessage
            name={name}
            render={(msg) => <div style={{ color: "red" }}>{errorT(msg)}</div>}
          />
        )}

        {/* this error message will show if the validation not succeeded from the
      front end */}
      </div>
      {previewOpen && (
        <Modal
          open={previewOpen}
          title={"Preview"}
          footer={null}
          onCancel={handleCancel}
        >
          <img
            alt="example"
            style={{ width: "100%" }}
            src={
              previewImage.startsWith("data:")
                ? previewImage
                : `${baseImgUrl}${previewImage}`
            }
          />
        </Modal>
      )}
    </>
  );
}
