import {
  MutationAddNewPositionArgs,
  useAddNewPositionMutation,
} from "graphql/_generated/graphql";
import { convertTranslationArrToObj } from "helpers";

export const useAddPosition = () => {
  const [addPositionFun, { isLoading }] = useAddNewPositionMutation();

  //this function is responsible for adding the position data
  const handleAddPosition = async (values: MutationAddNewPositionArgs) => {
    try {
      let translations = await convertTranslationArrToObj(
        values.create.translations,
      );
      let params = { create: { ...values.create, translations } };
      const response = await addPositionFun(params).unwrap();
      return response.addNewPosition;
    } catch (error: any) {
      throw error;
    }
  };

  return {
    isLoading,
    handleAddPosition,
  };
};
