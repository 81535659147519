import { MoreOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import CustomButton from "components/custom-button/CustomButton";
import CustomDropDown from "components/custom-dropdown/CustomDropDown";
import CustomTable from "components/custom-table/CustomTable";
import moment from "moment";
import { useTranslation } from "react-i18next";
import AddUnit from "./AddUnit";
import { useMeasurementModel } from "./hooks/useMeasurementModel";
import { Modal, Space, Switch, Tag } from "antd";
import { getLangDir } from "helpers/getLangDir";
import CustomLanguageSelector from "components/form/custom-language-selector/CustomLanguageSelector";
import { CONFIRM_MODAL_CONFIG, PERMS } from "helpers/constants";
import CheckPermissions from "components/check-permissions/CheckPermissions";

interface UnitsListProps {}

const UnitsList: React.FunctionComponent<UnitsListProps> = (props) => {
  type MappedUnitList = NonNullable<typeof vm.unitsListData>[0];
  const vm = useMeasurementModel();
  const { t } = useTranslation();

  const handleMenu = ({
    key,
    id,
    record,
  }: {
    key: string;
    id: string;
    record: MappedUnitList;
  }) => {
    if (key === "edit-unit") {
      vm.onUpdateUnit(id);
    }
    if (key === "add-new-language") {
      vm.onPressAddLang(id, record);
    }
    if (key === "edit-language") {
      vm.onPressUpdateLanguage(id, record);
    }
    if (key === "delete-unit") {
      Modal.confirm({
        ...CONFIRM_MODAL_CONFIG,
        closable: true,
        centered: true,
        title: t("delete-unit"),
        content: (
          <>
            <div className="confirm-content">{t("confirm-delete-unit")}</div>
          </>
        ),
        width: 506,
        onOk: () => vm.onDeleteUnit(id),
      });
    }
  };

  const handleToggleUnit = (record: MappedUnitList) => {
    Modal.confirm({
      ...CONFIRM_MODAL_CONFIG,
      closable: true,
      centered: true,
      title: t("warning"),
      content: (
        <>
          <div className="confirm-content">
            {record.isActive
              ? t("sure-to-deactivate-unit")
              : t("sure-to-activate-unit")}
          </div>
        </>
      ),
      width: 506,
      onOk: () => vm.onToggleUnit(record.id),
    });
  };

  const columns: ColumnsType<MappedUnitList> = [
    {
      title: t("id"),
      key: "id",
      dataIndex: "id",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, index) =>
        record.hasChildren
          ? (vm.currentPage - 1) * vm.pageSize + index + 1
          : null,
    },
    {
      title: t("name"),
      key: "unit",
      dataIndex: "unit",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => {
        return (
          <div className={record?.hasChildren ? "parent-row" : "child-row"}>
            <Space>
              <span className={`cut-text-${getLangDir(record.unit ?? "")}`}>
                {record.unit}
              </span>
            </Space>
          </div>
        );
      },
    },
    {
      title: t("description"),
      key: "description",
      dataIndex: "description",
    },
    {
      title: t("created-at"),
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: {
        multiple: 1,
      },
      render: (createdAt: string) => moment(createdAt).format("MMMM Do YYYY"),
    },
    {
      title: t("updated-at"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: {
        multiple: 1,
      },
      render: (updatedAt: string) => moment(updatedAt).format("MMMM Do YYYY"),
    },
    {
      title: t("status-label"),
      dataIndex: "isActive",
      key: "isActive",
      align: "center" as "center",

      render: (isActive: boolean) =>
        isActive ? (
          <Tag className="active-tag"> &bull; {`${t("active")}`}</Tag>
        ) : (
          <Tag className="de-active-tag">{t("deactivated")}</Tag>
        ),
    },
    {
      align: "center" as "center",
      title: t("activation"),
      dataIndex: "id",
      key: "id",
      render: (_text, record, _index) => (
        <>
          <CheckPermissions permission={PERMS.UNIT_ACTIVATION}>
            <Switch
              className="custom-switch"
              defaultChecked
              checked={!!record.isActive}
              onClick={() => handleToggleUnit(record)}
            />
          </CheckPermissions>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "action",
      align: "center",
      render: (id: string, record) => {
        const langActions = [{ key: "edit-language", label: "Edit Language" }];
        const mainActions = [
          {
            label: t("edit-unit"),
            key: "edit-unit",
          },
          {
            key: "add-new-language",
            label: t("add-new-language"),
          },
          {
            key: "delete-unit",
            label: t("delete-unit"),
          },
        ];
        return (
          <>
            <CustomDropDown
              menu={{
                items: record?.hasChildren ? mainActions : langActions,
                onClick: ({ key }) => handleMenu({ key, id, record }),
              }}
            >
              <CustomButton type="link" className="link-btn">
                <MoreOutlined />
              </CustomButton>
            </CustomDropDown>
          </>
        );
      },
    },
  ];

  return (
    <>
      <CustomTable
        filters={{
          handleCallback: vm.filtersCallBack,
          status: {
            applyAs: "and",
            key: "isActive",
            comparison: "Equal",
          },
          date: [
            {
              applyAs: "and",
              key: "createdAt",
              comparison: "Range",
            },
          ],
        }}
        columns={columns}
        totalItems={vm.columnsData?.metadata.total}
        onClick={vm.onPressAddUnit}
        headerTitle={t("units.title")}
        btnText={t("units.add-new-unit")}
        dataSource={vm.unitsListData}
        loading={vm.loading.isLoading}
        expandIconColumnIndex={1}
        rowKey={(record) =>
          record.hasChildren ? record.id ?? "" : `${record?.id}_${record?.unit}`
        }
      />
      <AddUnit
        visible={vm.isAddUnit}
        editId={vm.editId}
        onCloseUnit={vm.onCloseUnit}
        onSubmitUnit={vm.onSubmitUnit}
        initialValues={vm.initialValues}
        loading={vm.loading.isLoadingAddUnit}
      />
      {vm.langVisibility && (
        <CustomLanguageSelector
          visible={vm.langVisibility}
          setVisible={vm.onCloseLangDialog}
          onSubmit={vm.onLanguageSubmit}
          data={vm.unitRecord}
        />
      )}
    </>
  );
};

export default UnitsList;
