import { MoreOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Switch, Tag } from "antd";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import CustomButton from "components/custom-button/CustomButton";
import CustomMenu from "components/custom-menu/CustomMenu";
import { customNotification } from "components/custom-notification/CustomNotification";
import CustomTable from "components/custom-table/CustomTable";
import CustomPopconfirm from "components/form/custom-pop-confirm/CustomPopConfirm";
import { errorFormat, getTranslatedValue, PERMS } from "helpers";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import {
  useGetConfigTagsQuery,
  useGetTagListQuery,
  useLazyGetTagListQuery,
} from "graphql/_generated/graphql";
import moment from "moment";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AddTag from "features/tags/AddTag";
import { useTag } from "features/tags/hooks/useTag";
import styles from "features/tags/css/TagList.module.scss";
import { useErrorHandler } from "hooks/useErrorHandler";

type Props = {};

export default function TagList({}: Props) {
  const { t } = useTranslation();
  const [columnsData, setColumnsData] = useState<any>();
  const [getAllTagsDataFun] = useLazyGetTagListQuery();
  const { isFetching, refetch } = useGetTagListQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const { refetch: configTagsFetch } = useGetConfigTagsQuery();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [editId, setEditId] = useState<string | null>(null);
  const [filtersData, setFiltersData] = useState<any>(null);
  const { handleError } = useErrorHandler();

  const handleMenu = ({ key, id }: any) => {
    if (key === "edit-tag") {
      handleEditTag(id);
    }
  };

  const handleEditTag = (id: string) => {
    setEditId(id);
    setVisible(true);
  };

  const [visible, setVisible] = useState(false);
  const { handleToggleActivateTag } = useTag();

  useEffect(() => {
    fetchDataFromApi(getAllTagsDataFun, setColumnsData, filtersData).catch(
      (e) => handleError(e),
    );
  }, [isFetching]);

  const columns = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      sorter: {
        multiple: 1,
      },
      render: (_text: any, _record: any, index: number) =>
        (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: t("tag.tag-name"),
      dataIndex: "title",
      key: "title",
      sorter: {
        multiple: 1,
      },
      render: (title: string, item: any) => (
        <div className="cut-text">{getTranslatedValue(item, "title")}</div>
      ),
    },
    {
      title: t("tag.usages"),
      dataIndex: "usages",
      key: "usages",
      align: "center" as "center",
    },
    {
      title: t("creation-date"),
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: {
        multiple: 1,
      },
      render: (createdAt: any) =>
        moment(createdAt).format("MMMM Do YYYY, h:mm:ss a"),
    },
    {
      align: "center" as "center",
      title: t("status"),
      dataIndex: "isActive",
      key: "isActive",
      render: (isActive: boolean) => {
        return isActive ? (
          <Tag className="active-tag">{t("active")}</Tag>
        ) : (
          <Tag className="de-active-tag">{t("deactivated")}</Tag>
        );
      },
    },
    {
      align: "right" as "right",
      title: t("activation"),
      dataIndex: "id",
      key: "id",
      render: (id: string, { isActive }: any) => (
        <CheckPermissions permission={PERMS.TOGGLE_TAG_ACTIVATION}>
          <CustomPopconfirm
            onConfirm={async () => {
              try {
                const isActiveTag = await handleToggleActivateTag(id);
                customNotification(
                  "success",
                  isActiveTag
                    ? t("tag-activation-success")
                    : t("tag-deactivate-success"),
                );
                refetch();
                configTagsFetch();
              } catch (error) {
                handleError(error as string | object);
              }
            }}
            title={
              isActive ? t("confirm-deactivate-tag") : t("confirm-activate-tag")
            }
          >
            <Switch
              className="custom-switch"
              defaultChecked
              checked={isActive}
            />
          </CustomPopconfirm>
        </CheckPermissions>
      ),
    },
    {
      align: "center" as "center",
      title: "",
      dataIndex: "id",
      key: "id",
      render: (id: string) => (
        <>
          <Dropdown
            overlay={
              <CheckPermissions permission={PERMS.UPDATE_TAG}>
                <CustomMenu
                  items={[{ label: t("edit-tag"), key: "edit-tag" }]}
                  onClick={({ domEvent, key, keyPath }) =>
                    handleMenu({ domEvent, key, keyPath, id })
                  }
                />
              </CheckPermissions>
            }
          >
            <CustomButton type="link" className="link-btn">
              <MoreOutlined />
            </CustomButton>
          </Dropdown>
        </>
      ),
    },
  ];

  return (
    <>
      <CustomTable
        filters={{
          handleCallback: async (filtersData: any) => {
            await fetchDataFromApi(
              getAllTagsDataFun,
              setColumnsData,
              filtersData,
            );
            setPageSize(filtersData?.pagination?.offset?.pageSize);
            setCurrentPage(filtersData?.pagination?.offset?.page);
            setFiltersData(filtersData);
            setFiltersData(filtersData);
          },
          date: [
            {
              applyAs: "and",
              key: "createdAt",
              comparison: "Range",
            },
          ],
          status: {
            applyAs: "and",
            key: "isActive",
            comparison: "Equal",
          },
        }}
        rowKey="id"
        totalItems={columnsData?.metadata?.total}
        loading={isLoading}
        className="custom-table"
        columns={columns}
        permission={PERMS.ADD_NEW_TAG}
        onClick={() => setVisible(true)}
        btnText={t("tag.add-tag")}
        dataSource={columnsData?.data}
      />
      <AddTag
        visible={visible}
        setVisible={setVisible}
        editId={editId}
        setEditId={setEditId}
      />
    </>
  );
}
