import {
  useToggleClientAdminActivation,
} from "./useToggleClientAdminActivation";

import {useInviteAdmin} from "./useInviteClientAdmin";

export const useClientAdmin = () => {
  const {inviteClientAdmin, isLoading} = useInviteAdmin();
  const {handleToggleClientActivation, isLoading: ToggleActivationLoading} =
    useToggleClientAdminActivation();

  return {
    isLoading: isLoading || ToggleActivationLoading,
    inviteClientAdmin,
    handleToggleClientActivation,
  };
};
