import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import * as React from "react";
import ChallengesRewardsList from "features/challenge-rewards/list";
import GlobalChallengePoints from "features/challenge-rewards/global-points";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";

interface ActivityProps {}

const ChallengeRewards: React.FunctionComponent<ActivityProps> = () => {
  return (
    <DashboardLayout openKey={["Activity"]}>
      <Breadcrumbs
        data={[
          {
            label: "Activities",
          },
          {
            label: "Challenge Rewards",
          },
        ]}
      />
      <GlobalChallengePoints />
      <ChallengesRewardsList />
    </DashboardLayout>
  );
};

export default ChallengeRewards;
