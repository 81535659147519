import { Button, Result } from "antd";
import { useLogout } from "features/dashboard/header/useLogout";
import { useDispatch } from "react-redux";
import { removePermissions } from "store/permissionsSlice";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "graphql/_generated/graphql";
import { removeToken } from "features/auth/authSlice";
import { resetStore } from "store/globalActions";
import { store } from "store/store";

type Props = {};

export default function LogoutUser({}: Props) {
  const { handleLogout: logoutFun } = useLogout();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { userId } = useParams();

  const handleLogout = async () => {
    try {
      await logoutFun({});
      dispatch(removeToken());
      dispatch(removePermissions());
      dispatch(resetStore());
      navigate(`/reset-password/${userId}`);
      store.dispatch(api.util.resetApiState());
    } catch (error) {}
  };

  return (
    <Result
      status="404"
      title=""
      subTitle="You are already logged in. Please logout to proceed."
      extra={
        <Button className="primary-btn" onClick={() => handleLogout()}>
          Logout
        </Button>
      }
    />
  );
}
