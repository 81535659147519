import customNotification from "components/custom-notification/CustomNotification";
import { FormikHelpers } from "formik";
import {
  PartnerList,
  useGetConfigPartnerTypesQuery,
  useGetPartnerListQuery,
  useLazyGetPartnerListQuery,
  useLazyGetRewardsCountByPartnerQuery,
} from "graphql/_generated/graphql";
import { useLanguages } from "hooks";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useTier } from "./useTier";

interface LanguageVisibilityProps {
  language: string;
}

export const usePartnersListViewModel = () => {
  const { t } = useTranslation();
  const { languages } = useLanguages();
  const navigation = useNavigate();

  const [langVisibility, setLangVisibility] = useState(false);
  const [langCode, setLangCode] = useState<string>();
  const [isAddLanguage, setIsAddLanguage] = useState(false);
  const [isAddPartner, setIsAddPartner] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [editId, setEditId] = useState<string | null>(null);
  const [partnerData, setPartnerData] = useState<MappedPartnerList>();
  const [rewardCount, setRewardCount] = useState<number>();
  const [isActivated, setIsActivated] = useState(false);

  const [columnsData, setColumnsData] = useState<PartnerList>();
  const [filtersData, setFiltersData] = useState<any>(null);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const { isFetching } = useGetPartnerListQuery();
  const [getPartnerListFun] = useLazyGetPartnerListQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const { data: partnerType } = useGetConfigPartnerTypesQuery();
  const [getRewardCountFun] = useLazyGetRewardsCountByPartnerQuery();
  const { handleError } = useErrorHandler();

  const {
    handleTogglePartnerActivation,
    handleDeletePartner,
    isLoading: isLoadingTier,
    regionVisibility,
  } = useTier();

  useEffect(() => {
    fetchDataFromApi(getPartnerListFun, setColumnsData, filtersData).catch(
      (e) => handleError(e),
    );
  }, [isFetching]);

  const mappedColumnsData = columnsData?.data.map((item) => {
    return (
      {
        ...item,
        hasChildren: true,
        children:
          item?.translations && Object.keys?.(item?.translations)
            ? Object.keys?.(item?.translations)?.map((record) => {
                return {
                  ...item,
                  name: item?.translations?.[record]?.name,
                  language: record,
                };
              })
            : null,
      } ?? []
    );
  });

  type MappedPartnerList = NonNullable<typeof mappedColumnsData>[0];

  const handleMenu = ({ key, id, record }: any) => {
    if (key === "partner-detail") {
      navigation("/partner-details", {
        state: {
          id: id,
          langCode: record.language,
        },
      });
    }
    if (key === "add-new-language") {
      setPartnerData(record);
      setLangVisibility(true);
      setEditId(id);
      setIsAddLanguage(true);
    }
    if (key === "edit-partner") {
      setIsAddLanguage(false);
      setEditId(id);
      setIsAddPartner(true);
      setLangCode(undefined);
    }
    if (key === "delete-partner") {
      const res = getRewardCount(id);
      res.then((data) => {
        setRewardCount(data);
      });
      setIsDelete(true);
      setEditId(id);
    }
    if (key === "edit-language") {
      setLangCode(record.language);
      setIsAddLanguage(true);
      setEditId(id);
      setIsAddPartner(true);
    }
  };

  const handleSubmit = (
    values: LanguageVisibilityProps,
    actions: FormikHelpers<LanguageVisibilityProps>,
  ) => {
    setLangCode(values?.language);
    setLangVisibility(false);
    setIsAddPartner(true);
    actions.resetForm();
  };

  const getRewardCount = async (id: string) => {
    try {
      const res = await getRewardCountFun({ partnerId: id }).unwrap();
      return res.getRewardsCountByPartner;
    } catch (error) {
      console.log(error);
    }
  };

  const submitDelete = async () => {
    try {
      const res = await handleDeletePartner(editId ?? "");
      customNotification("success", t("deleted-partner-successfully"));
      setIsDelete(false);
      setEditId(null);
      return res;
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const handleTogglePartner = (record: MappedPartnerList) => {
    setIsActivated(true);
    setPartnerData(record);
  };

  const handlePartnerActivation = async () => {
    try {
      const isPartnerActive = await handleTogglePartnerActivation(
        partnerData?.id ?? "",
      );
      customNotification(
        "success",
        isPartnerActive
          ? t("partner-activation-successfully")
          : t("partner-deactivate-successfully"),
      );
      setIsActivated(false);
      setPartnerData(undefined);
      return isPartnerActive;
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const filtersCallback = async (filtersData: any) => {
    await fetchDataFromApi(getPartnerListFun, setColumnsData, filtersData);
    setPageSize(filtersData?.pagination?.offset?.pageSize);
    setCurrentPage(filtersData?.pagination?.offset?.page);
    setFiltersData(filtersData);
    setFiltersData(filtersData);
  };

  return {
    mappedColumnsData,
    currentPage,
    pageSize,
    handleTogglePartner,
    handleMenu,
    filtersCallback,
    languages,
    regionVisibility,
    partnerType,
    columnsData,
    isLoading,
    langVisibility,
    setLangVisibility,
    handleSubmit,
    partnerData,
    isAddPartner,
    setIsAddPartner,
    editId,
    setEditId,
    isAddLanguage,
    langCode,
    isDelete,
    setIsDelete,
    rewardCount,
    submitDelete,
    isLoadingTier,
    isActivated,
    setIsActivated,
    handlePartnerActivation,
  };
};
