import { convertTranslationArrToObj } from "helpers";
import {
  api,
  MutationAddNewCategoryArgs,
  useAddNewCategoryMutation,
  useCategoriesTreeQuery,
} from "graphql/_generated/graphql";
import { store } from "store/store";

export type TypeHandleAddCategory = (
  values: MutationAddNewCategoryArgs
) => Promise<void>;

//define the type of useCategory hook
export type TypeUseAddCategory = () => {
  isLoading: boolean;
  handleAddCategory: TypeHandleAddCategory;
};

export const useAddCategory: TypeUseAddCategory = () => {
  const [addCategoryFun, { isLoading }] = useAddNewCategoryMutation();
  const { refetch } = useCategoriesTreeQuery();

  //this function is responsible for adding the category data
  const handleAddCategory = async (values: MutationAddNewCategoryArgs) => {
    try {
      let translations = await convertTranslationArrToObj(
        values.create.translations
      );

      let params;
      if (values.create.parent) {
        let parent = values.create.parent;
        params = { create: { ...values.create, translations, parent } };
      } else {
        params = { create: { ...values.create, translations, parent: null } };
      }

      await addCategoryFun(params).unwrap();
      refetch();
    } catch (error: any) {
      throw error;
    }
  };

  //this function is responsible for updating the category data

  return { isLoading, handleAddCategory };
};
