import { MoreOutlined } from "@ant-design/icons";
import { TableColumnsType, Form, Typography, Switch } from "antd";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import CustomButton from "components/custom-button/CustomButton";
import CustomDropDown from "components/custom-dropdown/CustomDropDown";
import CustomMenu from "components/custom-menu/CustomMenu";
import { customNotification } from "components/custom-notification/CustomNotification";
import CustomTable from "components/custom-table/CustomTable";
import { errorFormat, PERMS } from "helpers";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import {
  useGetClientsTreeQuery,
  useGetRewardPointsListQuery,
  useLazyGetRewardPointsListQuery,
} from "graphql/_generated/graphql";
import styles from "features/challenge-rewards/css/ChallengeRewards.module.scss";

import moment from "moment";
import { DefaultRecordType } from "rc-table/lib/interface";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useChallenge } from "../hooks/useChallenge";
import CustomTreeSelect from "components/form/custom-tree-select/CustomTreeSelect";
import { Formik } from "formik";
import { PlusCircleFilled } from "@ant-design/icons";

import CustomConfirmModal from "../../../components/custom-confirm-modal/CustomConfirmModal";
import CustomPopconfirm from "components/form/custom-pop-confirm/CustomPopConfirm";
import AddException from "../add-exception";
import { useErrorHandler } from "hooks/useErrorHandler";
interface ActivityListProps {}

const ChallengesRewardsList: React.FunctionComponent<ActivityListProps> = (
  props,
) => {
  const { t } = useTranslation();
  const [filtersData, setFiltersData] = useState<any>(null);
  const [columnsData, setColumnsData] = useState<any>();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState<boolean>(false);
  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
  const [challengeData, setChallengeData] = useState<any>(null);
  const { data: companiesData } = useGetClientsTreeQuery(
    { clientId: null },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const { isFetching, refetch } = useGetRewardPointsListQuery();
  const [getRewardsListDataFun] = useLazyGetRewardPointsListQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const { handleActivationException, handleDeleteException } = useChallenge();
  const { handleError } = useErrorHandler();
  const navigate = useNavigate();

  useEffect(() => {
    fetchDataFromApi(getRewardsListDataFun, setColumnsData, filtersData).catch(
      (e) => handleError(e),
    );
  }, [isFetching]);

  const handleMenu = ({ domEvent, key, keyPath, id, record }: any) => {
    if (key === "delete-exception") {
      setChallengeData(record);
      setDeleteVisible(true);
    } else if (key === "edit-exception") {
      setChallengeData(record);
      setVisible(true);
    }
  };

  const handleExceptionDelete = async () => {
    try {
      await handleDeleteException(challengeData?.id);
      setChallengeData(null);
      setDeleteVisible(false);
      refetch();
      customNotification("success", "Exception deleted successfully");
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const columns: TableColumnsType<DefaultRecordType> = [
    {
      title: `${t("id")}`,
      key: "id",
      dataIndex: "id",
      sorter: {
        multiple: 1,
      },
      render: (_text, _record, index) => (
        <Typography className={styles["list-id-content"]}>
          {(currentPage - 1) * pageSize + index + 1}
        </Typography>
      ),
    },
    {
      title: "Company",
      key: "companyName",
      dataIndex: "companyName",
    },
    {
      title: "Allocated Reward",
      key: "points",
      dataIndex: "points",
    },
    {
      title: `Added Date`,
      dataIndex: "startDate",
      key: "startDate",
      sorter: {
        multiple: 1,
      },
      render: (startDate: string) => moment(startDate).format("MMMM Do YYYY"),
    },
    {
      title: `Modified Date`,
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: {
        multiple: 1,
      },
      render: (updatedAt: string) => moment(updatedAt).format("MMMM Do YYYY"),
    },
    {
      title: t("activation"),
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (id: string, { isActive }) => (
        <CheckPermissions permission={PERMS.TOGGLE_CHALLENGE_ACTIVATION}>
          <CustomPopconfirm
            onConfirm={async () => {
              try {
                await handleActivationException({
                  toggleCompanyRewardPointsId: id,
                  status: !isActive,
                });
                customNotification(
                  "success",
                  isActive
                    ? t("challenge-reward-deactivated-successfully")
                    : t("challenge-reward-activated-successfully"),
                );
                refetch();
              } catch (error) {
                handleError(error as string | object);
              }
            }}
            title={
              isActive
                ? t("confirm-deactivate-challenge-reward")
                : t("confirm-activate-challenge-reward")
            }
          >
            <Switch
              className="custom-switch"
              defaultChecked
              checked={isActive}
            />
          </CustomPopconfirm>
        </CheckPermissions>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "Actions",
      align: "center",
      render: (id: string, record: any) => {
        const actions = [
          {
            label: "Edit Exception",
            key: "edit-exception",
          },
          {
            label: "Delete Exception",
            key: "delete-exception",
          },
        ];
        return (
          <>
            <CustomDropDown
              overlay={
                <CheckPermissions permission={PERMS.UPDATE_CHALLENGE}>
                  <CustomMenu
                    items={actions}
                    onClick={({ domEvent, key, keyPath }) =>
                      handleMenu({ domEvent, key, keyPath, id, record })
                    }
                  />
                </CheckPermissions>
              }
            >
              <CustomButton type="link" className="link-btn">
                <MoreOutlined />
              </CustomButton>
            </CustomDropDown>
          </>
        );
      },
    },
  ];
  return (
    <>
      <CustomTable
        columns={columns}
        filters={{
          handleCallback: async (filtersData: any) => {
            await fetchDataFromApi(
              getRewardsListDataFun,
              setColumnsData,
              filtersData,
            );
            setPageSize(filtersData?.pagination?.offset?.pageSize);
            setCurrentPage(filtersData?.pagination?.offset?.page);
            setFiltersData(filtersData);
          },
          manualFilters: [
            {
              key: "companyId",
              value: "d",
              comparison: "Equal",
              applyAs: "and",
              render: (onChangeCallback: any) => (
                <Formik initialValues={{ companyId: null }} onSubmit={() => {}}>
                  {({ setFieldValue, values }) => (
                    <Form>
                      <CustomTreeSelect
                        allowClear
                        showArrow
                        noMargin
                        treeNodeFilterProp="companyName"
                        treeLine={true && { showLeafIcon: false }}
                        className="filter-select"
                        name="companyId"
                        treeData={companiesData?.getClientsTree}
                        placeholder={"Company: All"}
                        fieldNames={{ label: "companyName", value: "id" }}
                        onChange={onChangeCallback}
                      />
                    </Form>
                  )}
                </Formik>
              ),
            },
          ],
        }}
        totalItems={columnsData?.metadata?.total}
        loading={isLoading}
        permission={PERMS.ADD_NEW_CHALLENGE}
        onClick={() => setVisible(true)}
        headerTitle="Exceptions"
        btnText="Add New Exception"
        dataSource={
          columnsData?.data.map((item: any) => {
            return {
              ...item,
            };
          }) ?? []
        }
      />
      {deleteVisible && (
        <CustomConfirmModal
          title={`Delete Exception`}
          visible={deleteVisible}
          setVisible={setDeleteVisible}
          onSubmit={handleExceptionDelete}
          cancelText={"Cancel"}
          saveText={"Yes, Delete"}
          desc={`Are you sure you want to delete this exception ?`}
        />
      )}
      {visible ? (
        <AddException
          visible={visible}
          setVisible={(value) => {
            setVisible(false);
            challengeData && setChallengeData(null);
          }}
          data={challengeData}
        />
      ) : null}
    </>
  );
};

export default ChallengesRewardsList;
