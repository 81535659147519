import { useInviteMultipleUsers } from "./useInviteMultipleUsers";
import { useInviteSingleEmployee } from "./useInviteSingleEmployee";
import { useResendInvite } from "./useResendInvite";
import { useToggleActivationUsers } from "./useToggleActivationUsers";

export const useUsers = () => {
  const { inviteSingleEmployee, isLoading } = useInviteSingleEmployee();
  const { isLoading: bulkLoading, inviteMultipleEmployees } =
    useInviteMultipleUsers();
  const { handleUserActivationToggle } = useToggleActivationUsers();

  const {
    handleResendInvite,
    isLoading: isLoadingResendInvite,
    handleResendBulkInvite,
    isInviting,
  } = useResendInvite();

  return {
    isLoading: isLoading || bulkLoading || isLoadingResendInvite || isInviting,
    inviteSingleEmployee,
    inviteMultipleEmployees,
    handleUserActivationToggle,
    handleResendInvite,
    handleResendBulkInvite,
  };
};
