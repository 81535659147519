import { removeChildNestedValues } from "helpers/removeChildNestedValuesOnUnCheck";
import RecursivePermissions from "../../features/roles/RecursivePermissions";

type Props = {
  permissionsDataArr: any;
  setPermissionsInitialValues: (x: any) => void;
  permissionsInitialValues: string[];
};

const Permissions = ({
  permissionsDataArr,
  setPermissionsInitialValues,
  permissionsInitialValues,
}: Props) => {
  
  //handle remove and add the checkbox values into the final array
  const handleChange = (e: any) => {
    let currValue = e.target.value;
    let isCurrentChecked = e.target.checked;
    let currArr = permissionsInitialValues;

    if (isCurrentChecked) {
      setPermissionsInitialValues([...currArr, currValue]);
    } else {
      let modules = removeChildNestedValues(
        permissionsDataArr,
        currValue,
        permissionsInitialValues
      );
      setPermissionsInitialValues(modules);
    }
  };

  return (
    <>
      <RecursivePermissions
        permissionsArr={permissionsDataArr}
        handleChange={handleChange}
        permissionsInitialValues={permissionsInitialValues}
        setPermissionsInitialValues={setPermissionsInitialValues}
      />
    </>
  );
};

export default Permissions;
