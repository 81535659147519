import { ErrorMessage, FastField } from "formik";
import { InputProps } from "formik-antd";
import { useErrorTranslation } from "validations/schema/common/useErrorTranslation";
import styles from "./CustomFastField.module.scss";

interface Props extends InputProps {
  label?: string;
  subLabel?: string;
  placeholder?: string;
  className?: string;
  parentClassName?: string;
}

export function CustomFastField(props: Props) {
  const { name, label, className, type, subLabel } = props;
  const { errorT } = useErrorTranslation();

  let FastFieldProps = { ...props };
  delete FastFieldProps?.parentClassName;
  delete FastFieldProps?.subLabel;

  return (
    <div
      className={`${styles["form-control"]} ${props?.parentClassName ?? ""}`}
    >
      {label && (
        <label className={styles["label"]} htmlFor={name}>
          {label}
        </label>
      )}
      {subLabel && (
        <label className={styles["subLabel"]} htmlFor={name}>
          {subLabel}
        </label>
      )}

      <FastField
        {...FastFieldProps}
        styles={{}}
        className={className ? className : "input-style"}
      />

      <div className={styles["error-msg"]}>
        <ErrorMessage
          name={name}
          render={(msg) => <div className="text-danger">{errorT(msg)}</div>}
        />
      </div>
    </div>
  );
}
