import { Pagination } from "antd";
import CustomLoader from "components/custom-loader/CustomLoader";
import CustomModal from "components/custom-modal/CustomModal";
import {
  ProgressStatus,
  useLazyGetLeaderboardQuery,
} from "graphql/_generated/graphql";
import { debounce } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ChallengeDataTable from "./ChallengeDataTable";
import DataTableFilters from "./DataTableFilters";

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  challengeId?: string;
};

const DEBOUNCE_TIME = 500;

const AllUsersLeft: React.FC<Props> = ({
  visible,
  setVisible,
  challengeId,
}: any) => {
  const handleCancel = () => {
    setVisible(false);
  };

  const [data, setData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>("");
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const [dateRange, setDateRange] = useState<any>(undefined);
  const [pagination, setPagination] = useState<any>({
    limit: 10,
    offset: 0,
  });

  const [getLeaderboardFun, { isLoading }] = useLazyGetLeaderboardQuery();

  useEffect(() => {
    fetchLeaderboardData();
  }, [currentPage, pagination, searchText, selectedCompany, dateRange]);

  const fetchLeaderboardData = async () => {
    const { getLeaderboard } = await getLeaderboardFun({
      entityId: challengeId,
      pagination,
      status: ProgressStatus.Left,
      search: searchText,
      companyId: selectedCompany,
      updatedAt: dateRange,
    }).unwrap();
    setData(getLeaderboard);
  };

  const handleSearchInput = (value: string) => {
    setSearchText(value);
    setPagination({
      limit: 10,
      offset: 0,
    });
  };

  const handleRangeFilter = (date: any, range: any) => {
    const value = { start: range?.[0], end: range?.pop() };
    if (value.start) setDateRange(value);
    else setDateRange(undefined);
  };

  return (
    <CustomModal
      width={720}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      title={`Users Left The Challenge (${data?.metadata?.total || ""})`}
    >
      <DataTableFilters
        handleSearchInput={handleSearchInput}
        setSelectedCompany={setSelectedCompany}
        isDateFilter={true}
        handleRangeFilter={handleRangeFilter}
      />

      {isLoading ? (
        <CustomLoader />
      ) : (
        <>
          <ChallengeDataTable
            data={
              data?.data?.length
                ? data.data.map((item: any) => ({
                    ...item,
                    status: `Left - ${moment(item.updatedAt).format(
                      "MMM DD, YYYY",
                    )}`,
                  }))
                : []
            }
            noRank={true}
            isModalVisible={true}
          />
          <div className={"pagination-margin"}>
            <Pagination
              onChange={(page, pageSize) => {
                setCurrentPage(page);
                setPagination({
                  offset: (page - 1) * pageSize,
                  limit: pageSize,
                });
              }}
              current={currentPage}
              pageSize={pagination?.limit}
              showTotal={(total, range) => (
                <span>
                  Showing&nbsp;
                  <b>
                    {range[0]}-{range[1]}
                  </b>
                  &nbsp;of <b>{total}</b>&nbsp; items
                </span>
              )}
              total={data?.metadata?.total || 0}
            />
          </div>
        </>
      )}
    </CustomModal>
  );
};

export default AllUsersLeft;
