export const TickSvg = () => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.57555 8.00003C4.39155 8.00003 4.21555 7.92404 4.08955 7.79003L0.847547 4.33737C0.594881 4.06937 0.608881 3.64737 0.876881 3.39537C1.14555 3.14337 1.56755 3.1567 1.81888 3.4247L4.56888 6.35203L10.1742 0.217368C10.4235 -0.0552989 10.8449 -0.0732989 11.1169 0.174701C11.3882 0.422701 11.4069 0.844701 11.1589 1.11603L5.06755 7.7827C4.94288 7.92003 4.76555 7.9987 4.58021 8.00003H4.57555Z"
      fill="currentColor"
    />
  </svg>
);
