import { useTranslation } from "react-i18next";
import { useStreak } from "./useStreak";
import { useErrorHandler } from "hooks/useErrorHandler";
import { StreakCreate } from "graphql/_generated/graphql";
import customNotification from "components/custom-notification/CustomNotification";

export const useStreaksViewModal = () => {
  const { handleAddStreak, streaksData, refetchStreaks, isLoading } =
    useStreak();

  const { t } = useTranslation();
  const { handleError } = useErrorHandler();

  const onSetStreakSubmit = async (values: StreakCreate) => {
    try {
      const res = await handleAddStreak({ streak: values });
      customNotification("success", t("streaks-added-successfully"));
      refetchStreaks();
      return res;
    } catch (error) {
      handleError(error as string | object);
    }
  };

  return {
    onSetStreakSubmit,
    refetchStreaks,
    streaksData,
    isLoading,
  };
};
