export const AssessmentTypeSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00004 6.75271L15.0934 3.77104C14.9717 3.64687 14.8342 3.53604 14.6675 3.45854L8.66754 0.646875C8.24671 0.451042 7.75337 0.451042 7.33254 0.646875L1.33254 3.45854C1.16587 3.53604 1.02837 3.64687 0.906707 3.77104L8.00004 6.75271ZM7.37504 7.85621L0.50004 4.96621V11.407C0.495874 11.9412 0.815874 12.4312 1.32504 12.672L7.32504 15.4829C7.33314 15.4867 7.34167 15.4892 7.35031 15.4918C7.35856 15.4942 7.3669 15.4967 7.37504 15.5004V7.85621ZM8.62504 15.4979V7.85621L15.5 4.96621V11.4145C15.5 11.947 15.1775 12.4345 14.6675 12.672L8.66754 15.4829C8.66 15.4866 8.65192 15.4891 8.64377 15.4915C8.6375 15.4934 8.63119 15.4953 8.62504 15.4979Z"
        fill="#303030"
      />
    </svg>
  );
};
