import { Popover } from "antd";
import React, { useEffect, useState } from "react";
import styles from "../CompanyDashboard.module.scss";
import { useTranslation } from "react-i18next";
import InfoCircleFilled from "@ant-design/icons/InfoCircleFilled";
import InfoCircleOutlined from "@ant-design/icons/InfoCircleOutlined";
import { CloseOutlined } from "@ant-design/icons";

interface InfoTextPopupProps {
  visible: boolean;
  items: string[];
  handleSelectedInfoText?: (reset?: boolean) => void;
}
export const InfoTextPopup: React.FC<InfoTextPopupProps> = ({
  visible,
  items,
  handleSelectedInfoText,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    visible && setOpen(false);
  }, [visible]);

  const renderPopupContent = () => (
    <div className={styles["infotext-popup-container"]}>
      <div className={styles["header"]}>
        <div className={styles["item-header"]}>{t("details")}</div>
        <CloseOutlined
          onClick={() => {
            setOpen(false);
            handleSelectedInfoText?.(true);
          }}
        />
      </div>
      <div className={styles["info-popup-scroll-container"]}>
        {items?.map((item: string) => (
          <div className={styles["info-content-container"]}>
            <InfoCircleOutlined className={styles["popup-info-icon"]} />
            <div
              className={`${styles["content-item-text"]} ${styles["info-text"]}`}
            >
              {item}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <>
      <div
        className="cursor-pointer"
        onClick={() => {
          setOpen(true);
          handleSelectedInfoText?.();
        }}
      >
        <InfoCircleFilled className={styles["info-icon"]} />
      </div>
      <Popover
        overlayInnerStyle={{
          borderRadius: 8,
        }}
        content={renderPopupContent()}
        trigger="click"
        open={open}
        arrowPointAtCenter={true}
        placement="bottomRight"
        showArrow={false}
      ></Popover>
    </>
  );
};
