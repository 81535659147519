import { convertTranslationArrToObj, convertTranslationObjToArr } from "helpers";
import {
  useUpdateAvatarMutation,
  MutationUpdateAvatarArgs,
  useLazyGetAvatarQuery,
} from "graphql/_generated/graphql";
export const useUpdateAvatar = () => {
  const [updateAvatarFun, { isLoading }] = useUpdateAvatarMutation();
  const [prevEditDataFun, { isLoading: prevDataLoading }] =
    useLazyGetAvatarQuery();
  //This function is responsible for updating the avatar data

  const handleUpdateAvatar = async (values: MutationUpdateAvatarArgs) => {
    try {
      let translations = await convertTranslationArrToObj(
        values.updateObj.translations
      );
      let params = { updateObj: { ...values.updateObj, translations } };
      await updateAvatarFun({
        updateAvatarId: values.id,
        updateObj: params.updateObj,
      }).unwrap();
    } catch (error) {
      throw error;
    }
  };

  //this function is responsible for fetching the specific avatar data which avatar id we will be provided
  const handleFetchEditAvatar = async (id: string) => {
    try {
      const res = await prevEditDataFun({ getAvatarId: id }).unwrap();
      const translations = await convertTranslationObjToArr(
        res?.getAvatar?.translations
      );
      return { ...res?.getAvatar, translations };
    } catch (error) {
      throw error;
    }
  };

  return {
    isLoading: isLoading || prevDataLoading,
    handleUpdateAvatar,
    handleFetchEditAvatar,
  };
};
