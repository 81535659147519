import {
  DownloadOutlined,
  MoreOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import { Select, Space, Switch } from "antd";
import { ColumnsType } from "antd/lib/table";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import CustomButton from "components/custom-button/CustomButton";
import CustomConfirmModal from "components/custom-confirm-modal/CustomConfirmModal";
import CustomDropDown from "components/custom-dropdown/CustomDropDown";
import CustomTable from "components/custom-table/CustomTable";
import CustomLanguageSelector from "components/form/custom-language-selector/CustomLanguageSelector";
import { PERMS, getLanguage } from "helpers";
import { getLangDir } from "helpers/getLangDir";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { usePartnerRewardsViewModel } from "../hooks/usePartnerRewardsViewModel";
import AddBulkRewards from "./AddBulkRewards";
import AddPartnerReward from "./AddPartnerReward";
interface PartnerRewardsProps {
  partnerId: string;
}

const PartnerRewards: React.FunctionComponent<PartnerRewardsProps> = ({
  partnerId,
}) => {
  const { t } = useTranslation();
  const vm = usePartnerRewardsViewModel({ partnerId });

  type MappedPartnerRewardList = NonNullable<typeof vm.mappedColumnsData>[0];

  const columns: ColumnsType<MappedPartnerRewardList> = [
    {
      title: `${t("id")}`,
      key: "id",
      dataIndex: "id",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, index) =>
        record.hasChildren
          ? (vm.currentPage - 1) * vm.pageSize + index + 1
          : null,
    },
    {
      title: `${t("title")}`,
      key: "name",
      dataIndex: "name",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => {
        return (
          <div className={record?.hasChildren ? "parent-row" : "child-row"}>
            <Space>
              <CustomAvatar shape="square" imgSrc={record?.image ?? ""} />
              <span className={`cut-text-${getLangDir(record.name)}`}>
                {record.name}
              </span>
            </Space>
          </div>
        );
      },
    },
    {
      title: `${t("language")}`,
      key: "language",
      dataIndex: "language",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => getLanguage(record.language),
    },
    {
      title: `${t("type")}`,
      key: "rewardType",
      dataIndex: "rewardType",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => record.rewardType?.name,
    },
    {
      title: `${t("tier")}`,
      key: "tier",
      dataIndex: "tier",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => record.tier?.name,
    },
    {
      title: `${t("price")}`,
      key: "price",
      dataIndex: "price",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => record?.price || 0,
    },
    {
      title: `${t("provider")}`,
      key: "rewardProvider",
      dataIndex: "rewardProvider",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => record.rewardProvider?.name,
    },
    {
      title: `${t("category")}`,
      key: "rewardCategory:",
      dataIndex: "rewardCategory:",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => record.rewardCategory?.name,
    },
    {
      title: `${t("creation-date")}`,
      key: "createdAt",
      dataIndex: "createdAt",
      sorter: {
        multiple: 1,
      },
      render: (createdAt: string) => moment(createdAt).format("MMMM Do YYYY"),
    },
    {
      title: `${t("updated-at")}`,
      key: "updatedAt",
      dataIndex: "updatedAt",
      sorter: {
        multiple: 1,
      },
      render: (updatedAt: string) => moment(updatedAt).format("MMMM Do YYYY"),
    },
    {
      align: "center" as "center",
      title: t("activation"),
      dataIndex: "id",
      key: "id",
      render: (_text, record, _index) => (
        <>
          <CheckPermissions permission={PERMS.TOGGLE_PARTNER_REWARD_ACTIVATION}>
            <Switch
              className="custom-switch"
              defaultChecked
              checked={record.isActive}
              onClick={() => vm.handleToggleReward(record)}
            />
          </CheckPermissions>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "Actions",
      align: "center",
      render: (id: string, record) => {
        const mainActions = [
          {
            key: "edit-reward",
            label: t("edit-reward"),
          },
          {
            key: "add-new-language",
            label: t("add-new-language"),
          },
          {
            key: "delete-reward",
            label: t("delete-reward"),
          },
        ];
        const langActions = [{ key: "edit-language", label: "Edit Language" }];
        return (
          <CustomDropDown
            menu={{
              items: record?.hasChildren ? mainActions : langActions,
              onClick: ({ domEvent, key, keyPath }) =>
                vm.handleMenu({ domEvent, key, keyPath, id, record }),
            }}
          >
            <CustomButton type="link" className="link-btn">
              <MoreOutlined />
            </CustomButton>
          </CustomDropDown>
        );
      },
    },
  ];

  const partnerActions = () => {
    return (
      <Space size={10}>
        <CheckPermissions permission={PERMS.BULK_IMPORT_REWARDS}>
          <CustomButton
            onClick={() => {
              vm.setIsBulkUpload(true);
            }}
            icon={<DownloadOutlined />}
            className="light-btn"
          >
            {t("import-rewards")}
          </CustomButton>
        </CheckPermissions>
        <CheckPermissions permission={PERMS.ADD_PARTNER_REWARD}>
          <CustomButton
            onClick={() => {
              vm.setLangVisibility(true);
              vm.setIsAddLanguage(false);
              vm.setRewardData(undefined);
            }}
            icon={<PlusCircleFilled />}
            className="primary-btn"
          >
            {t("add-reward")}
          </CustomButton>
        </CheckPermissions>
      </Space>
    );
  };

  return (
    <>
      <CustomTable
        expandIconColumnIndex={1}
        dataSource={vm.mappedColumnsData}
        columns={columns}
        filters={{
          handleCallback: vm.filtersCallback,
          search: true,
          date: [
            {
              applyAs: "and",
              key: "createdAt",
              comparison: "Range",
            },
          ],
          manualFilters: [
            {
              key: "rewardTypeId",
              value: "rewardTypeId",
              comparison: "Equal",
              applyAs: "and",
              render: (onChangeCallback) => (
                <Select
                  options={vm.rewardTypesData?.getConfigRewardTypes.options}
                  fieldNames={{ label: "label", value: "id" }}
                  className="filter-select"
                  onChange={onChangeCallback}
                  allowClear
                  placeholder={t("type-filter")}
                />
              ),
            },
            {
              key: "tier.id",
              value: "createdAt",
              comparison: "Equal",
              applyAs: "and",
              render: (onChangeCallback) => (
                <Select
                  options={vm.tiersData?.getConfigTiers.options}
                  fieldNames={{ label: "label", value: "id" }}
                  className="filter-select"
                  onChange={onChangeCallback}
                  allowClear
                  placeholder={t("tier-filter")}
                />
              ),
            },
          ],
        }}
        totalItems={vm.columnsData?.metadata?.total}
        loading={vm.isLoading}
        className="custom-table"
        rowKey={(record, index) =>
          record.hasChildren ? record.id : record.id + index
        }
        headerTitle={t("partner-rewards")}
        actions={partnerActions}
      />
      {vm.langVisibility && (
        <CustomLanguageSelector
          title={vm.isAddLanguage ? "" : `${t("reward")} ${t("main-language")}`}
          visible={vm.langVisibility}
          setVisible={vm.setLangVisibility}
          onSubmit={vm.handleSubmit}
          saveText={vm.isAddLanguage ? undefined : t("add-reward")}
          data={vm.rewardData}
          label={t("reward")}
        />
      )}
      {vm.isAddPartnerReward && (
        <AddPartnerReward
          visible={vm.isAddPartnerReward}
          setVisible={vm.setIsAddPartnerReward}
          editId={vm.editId}
          setEditId={vm.setEditId}
          langCode={vm.langCode}
          partnerId={partnerId}
          isAddLanguage={vm.isAddLanguage}
        />
      )}
      {vm.isActivated && (
        <CustomConfirmModal
          visible={vm.isActivated}
          setVisible={vm.setIsActivated}
          desc={
            vm.rewardData?.isActive
              ? t("sure-to-deactivate-reward")
              : t("sure-to-activate-reward")
          }
          loading={vm.isLoadingReward}
          onSubmit={vm.handleToggleRewardActivation}
        />
      )}
      {vm.isDelete && (
        <CustomConfirmModal
          visible={vm.isDelete}
          setVisible={vm.setIsDelete}
          desc={t("sure-to-delete-reward")}
          loading={vm.isLoadingReward}
          onSubmit={vm.submitDeleteReward}
        />
      )}
      {vm.isBulkUpload && (
        <AddBulkRewards
          partnerId={partnerId}
          visible={vm.isBulkUpload}
          setVisible={vm.setIsBulkUpload}
        />
      )}
    </>
  );
};

export default PartnerRewards;
