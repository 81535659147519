import CheckPermissions from "components/check-permissions/CheckPermissions";
import { PERMS } from "helpers";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import AddCategory from "features/categories/add-category/AddCategory";
import CategoriesList from "features/categories/categories-list/CategoriesList";
import styles from "features/categories/Categories.module.scss";

interface CategoriesProps {}

const Categories: React.FunctionComponent<CategoriesProps> = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [editId, setEditId] = useState<string | null>(null);
  return (
    <>
      <DashboardLayout>
        <div className={styles["categories"]}>
          <div className={styles["header"]}>
            <h1 className={styles["title"]}> {t("category.categories")}</h1>
          </div>
          <div className={styles["main-categories-table"]}>
            <CategoriesList />
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default Categories;
