import * as React from "react";
import { Collapse, CollapsePanelProps, Space } from "antd";

interface CustomCollapseHeaderProps extends CollapsePanelProps {}

const CustomCollapseHeader: React.FunctionComponent<
  CustomCollapseHeaderProps
> = (props) => {
  const { Panel } = Collapse;

  return (
    <Panel {...props} key={"1"}>
      {props.children}
    </Panel>
  );
};

export default CustomCollapseHeader;
