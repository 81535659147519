import {
  useUpdateAssessmentCarousalMutation,
  MutationUpdateAssessmentCarousalArgs,
} from "graphql/_generated/graphql";

export const useUpdateAssessmentCarousal = () => {
  const [updateAssessmentCarousalFun, { isLoading }] =
    useUpdateAssessmentCarousalMutation();

  const handleUpdateAssessmentCarousalFun = async (values: any) => {
    try {
      const { id, ...carousal } = values.updateCarousal;
      const res = await updateAssessmentCarousalFun({
        updateCarousal: carousal,
        carousalId: values.carousalId,
      }).unwrap();

      return res.updateAssessmentCarousal;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleUpdateAssessmentCarousalFun,
    isLoadingUpdateAssessment: isLoading,
  };
};
