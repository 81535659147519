import { GraphQLClient, ClientError } from "graphql-request";
import jwt_decode from "jwt-decode";
import {
  RefreshTokenDocument,
  RefreshTokenMutation,
} from "graphql/_generated/graphql";
import config from "config";

const graphqlUrl = config.api.graphqlUrl;
const client: any = new GraphQLClient(graphqlUrl);

export const decodeToken = (token: string) => {
  return jwt_decode<{
    email: string;
    name: string;
    isProfileComplete: boolean;
    iat: number;
    exp: number;
  }>(token);
};

export const isTokenExpired = (token: string, bufferInSeconds = 10) => {
  const { exp } = decodeToken(token);
  const currTime = new Date().getTime() / 1000;
  return exp - currTime <= bufferInSeconds;
};

export const getRefreshedToken = async (
  token: string,
  refreshToken: string,
  deviceToken?: string,
) => {
  try {
    const tokenResponse = await client.request(
      RefreshTokenDocument,
      {
        tokens: {
          refreshToken,
          deviceToken,
        },
      },
      { token },
    );
    return (tokenResponse as RefreshTokenMutation).refreshToken;
  } catch (error) {
    console.log("refreshError", error);
    throw error;
  }
};
