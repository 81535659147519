export const OnBoardingSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8333 12.8617C13.8333 13.3983 13.46 13.8333 13 13.8333H3L9.305 8.1525C9.51 7.94833 9.88167 7.94917 10.0825 8.15167L13.8333 11.8833V12.8617ZM4.66667 3.83333C5.35667 3.83333 5.91667 4.39333 5.91667 5.08333C5.91667 5.77333 5.35667 6.33333 4.66667 6.33333C3.97667 6.33333 3.41667 5.77333 3.41667 5.08333C3.41667 4.39333 3.97667 3.83333 4.66667 3.83333ZM13 0.5H3C1.62167 0.5 0.5 1.62167 0.5 3V13C0.5 14.3783 1.62167 15.5 3 15.5H13C14.3783 15.5 15.5 14.3783 15.5 13V3C15.5 1.62167 14.3783 0.5 13 0.5Z"
        fill="#303030"
      />
    </svg>
  );
};
