// import AuthLayout from "app/components/auth/auth-layout/AuthLayout";
// import { setToken } from "app/components/auth/authSlice";
// import CheckPermissions from "app/components/common/check-permissions/CheckPermissions";
// import CustomButton from "app/components/common/custom-button/CustomButton";
// import CustomModal from "app/components/common/custom-modal/CustomModal";
// import { customNotification } from "app/components/common/custom-notification/CustomNotification";
// import { CustomDatePicker } from "app/components/common/form/custom-date-picker/CustomDatePicker";
// import { CustomSelectField } from "app/components/common/form/custom-select-field/CustomSelectField";
// import { CustomTextArea } from "app/components/common/form/custom-text-area/CustomTextArea";
// import { CustomTextField } from "app/components/common/form/custom-text-field/CustomTextField";
// import { CustomUploadFile } from "app/components/common/form/custom-upload-file/CustomUploadFile";
// import gendersData from "app/components/common/genders.json";
// import { errorFormat, hasOnlySpaces, PERMS } from "app/helpers";
// import { useRefreshToken } from "app/hooks/useRefreshToken";
// import { languages } from "app/components/common/languages";
// import { Form, Formik } from "formik";
// import {
//   useGetConfigPillarsQuery,
//   useGetConfigTagsQuery,
// } from "graphql/_generated/graphql";
// import { useState } from "react";
// import { useTranslation } from "react-i18next";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { messaging, getToken } from "app/services/firebase/firebase.service";
// import { employeeProfileSchema } from "validations/schema";
// import { useExpert } from "../useExpert";
// import styles from "./ExpertProfile.module.scss";

// const ExpertProfile = () => {
//   // const messaging = getMessaging();
//   const { t } = useTranslation();
//   const navigate = useNavigate();
//   const { data: pillarData } = useGetConfigPillarsQuery();
//   const { data: tagsData } = useGetConfigTagsQuery();
//   const { isLoading, handleCompleteExpertProfile } = useExpert();
//   const [visible, setVisible] = useState(false);
//   const refreshToken = useSelector((state: any) => state.auth.refreshToken);
//   const { handleRefreshToken } = useRefreshToken();
//   const dispatch = useDispatch();

//   const initialValues = {
//     image: "",
//     specialty: "",
//     dob: "",
//     pillarId: "",
//     tagIds: [],
//     about: "",
//     specializations: "",
//     gender: "",
//     qualifications: "",
//     speakingLanguages: [],
//     servicesProvided: "",
//     phoneNumber: "",
//     personalEmail: "",
//   };

//   return (
//     <AuthLayout>
//       <Formik
//         initialValues={initialValues}
//         validationSchema={employeeProfileSchema}
//         onSubmit={async (values, { resetForm }) => {
//           try {
//             const successMsg = await handleCompleteExpertProfile({
//               completeProfileDTO: values,
//             });

//             resetForm();
//             const deviceToken = await getToken(messaging);
//             const newTokens = await handleRefreshToken({
//               tokens: { deviceToken, refreshToken },
//             });

//             customNotification("success", successMsg);
//             dispatch(setToken(newTokens.refreshToken));
//             navigate("/expert-dashboard");
//           } catch (error) {
//             customNotification("error", errorFormat(error));
//           }
//         }}
//       >
//         {({ setFieldValue, values }) => (
//           <div className={styles["company-card"]}>
//             <h1 className={styles["company-profile"]}>Complete Profile</h1>
//             <Form>
//               <CustomUploadFile
//                 name="image"
//                 setFieldValue={setFieldValue}
//                 imgUrl={values.image}
//                 accept="image/*"
//               />
//               <CustomTextField
//                 name="specialty"
//                 placeholder={t("specialty")}
//                 label={t("specialty")}
//               />
//               <CustomDatePicker
//                 placeholder="Select DOB"
//                 className="custom-date"
//                 name="dob"
//                 label={t("age")}
//               />
//               <CustomSelectField
//                 label={t("gender")}
//                 name="gender"
//                 placeholder={t("select-gender")}
//                 className="form-select"
//                 options={gendersData}
//               />
//               {values.gender === "Prefer to describe" && setVisible(true)}
//               {values.gender === "Prefer to describe" &&
//                 setFieldValue("gender", "")}

//               <CustomModal
//                 width={600}
//                 visible={visible}
//                 onCancel={() => {
//                   setVisible(false);
//                   setFieldValue("gender", "");
//                 }}
//                 footer={null}
//               >
//                 <CustomTextField
//                   name="gender"
//                   label={t("Prefer-to-describe")}
//                   placeholder={t("gender")}
//                   hideError={true}
//                   required
//                 />
//                 <CustomButton
//                   onClick={() => setVisible(false)}
//                   disabled={hasOnlySpaces(values.gender) ? true : false}
//                 >
//                   Save
//                 </CustomButton>
//               </CustomModal>

//               <CustomSelectField
//                 label={t("select-pillar")}
//                 name="pillarId"
//                 placeholder={t("select-pillar")}
//                 className="form-select"
//                 options={pillarData?.getConfigPillars}
//                 fieldNames={{ label: "label", value: "id" }}
//               />
//               <CustomSelectField
//                 label={t("tag.select-tag")}
//                 name="tagIds"
//                 mode="multiple"
//                 placeholder={t("tag.select-tag")}
//                 className="form-select"
//                 options={tagsData?.getConfigTags}
//                 fieldNames={{ label: "label", value: "id" }}
//               />
//               <CustomTextArea
//                 label={t("about")}
//                 name="about"
//                 rows={10}
//                 placeholder={t("about")}
//               />
//               <CustomTextArea
//                 label={t("specialization")}
//                 name="specializations"
//                 rows={10}
//                 placeholder={t("specialization")}
//               />
//               <CustomTextArea
//                 label={t("qualification")}
//                 name="qualifications"
//                 rows={10}
//                 placeholder={t("qualification")}
//               />
//               <CustomSelectField
//                 label={t("speaking-language")}
//                 name="speakingLanguages"
//                 mode="multiple"
//                 placeholder={t("speaking-language")}
//                 className="form-select"
//                 options={languages}
//               />
//               <CustomTextArea
//                 label={t("services-provided")}
//                 name="servicesProvided"
//                 rows={10}
//                 placeholder={t("services-provided")}
//               />
//               <CustomTextField
//                 name="phoneNumber"
//                 placeholder={t("phone-number")}
//                 label={t("phone-number")}
//               />
//               <CustomTextField
//                 name="personalEmail"
//                 placeholder="Personal Email"
//                 label="Personal Email"
//               />
//               <div className={styles["btn-div"]}>
//                 <CheckPermissions permission={PERMS.COMPLETE_EXPERT_PROFILE}>
//                   <CustomButton
//                     className="primary-btn"
//                     htmlType="submit"
//                     loading={isLoading}
//                   >
//                     Continue
//                   </CustomButton>
//                 </CheckPermissions>
//               </div>
//             </Form>
//           </div>
//         )}
//       </Formik>
//     </AuthLayout>
//   );
// };

export default () => {}; // ExpertProfile;
