import {
  useGetFaqListQuery,
  useCreateFaqMutation,
  MutationCreateFaqArgs,
} from "graphql/_generated/graphql";
import { convertTranslationArrToObj } from "helpers";

export const useAddFaq = () => {
  const [addFaqFun, { isLoading }] = useCreateFaqMutation();
  const { refetch } = useGetFaqListQuery();

  //this function is responsible for adding new faq
  const handleAddFaq = async (values: MutationCreateFaqArgs) => {
    try {
      const translations = await convertTranslationArrToObj(
        values.create?.translations!,
      );
      let params = { create: { ...values.create, translations } };
      await addFaqFun(params).unwrap();
      refetch();
    } catch (error: any) {
      throw error;
    }
  };

  return { isAddLoading: isLoading, handleAddFaq };
};
