import uploadImgPlaceholder from "assets/images/imgPlaceHolder.svg";
import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { CustomUploadFile } from "components/form/custom-upload-file/CustomUploadFile";
import { Form, Formik, FormikHelpers } from "formik";
import {
  CreateQuestMutation,
  GetQuestByIdQuery,
  QuestCreate,
  SetQuestTranslationMutation,
  UpdateQuestMutation,
} from "graphql/_generated/graphql";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { addQuestSchema } from "validations/schema";
import { SubmitQuestResponse } from "../hooks/useDailyQuestViewModal";

interface AddQuestProps {
  visible: boolean;
  editId?: string | null;
  langCode?: string;
  isAddLanguage?: boolean;
  loading: boolean;
  questData?: GetQuestByIdQuery["getQuestById"];
  questTypes: any;
  onCloseAddQuest: () => void;
  onAddQuestSubmit: (
    values: QuestCreate,
    formikHelpers: FormikHelpers<QuestCreate>,
  ) => Promise<SubmitQuestResponse | undefined>;
}

const AddQuest: React.FunctionComponent<AddQuestProps> = ({
  visible,
  editId,
  isAddLanguage,
  langCode,
  loading,
  questData,
  questTypes,
  onCloseAddQuest,
  onAddQuestSubmit,
}) => {
  const { t } = useTranslation();

  const initialValues = {
    language: questData?.language ?? langCode,
    image: questData?.image ?? "",
    title: questData?.title ?? "",
    typeId: questData?.questType.id ?? "",
  };

  return (
    <CustomModal
      visible={visible}
      onCancel={onCloseAddQuest}
      width={654}
      footer={false}
      title={
        editId && !isAddLanguage
          ? t("edit-quest")
          : `${t("add-quest")} ${isAddLanguage ? t("language") : ""}`
      }
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={addQuestSchema}
        onSubmit={onAddQuestSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className="challenge-detail-container reward-image-container">
              {!isAddLanguage && (
                <CustomUploadFile
                  infoText={t("quest-image")}
                  label={t("quest-image")}
                  setFieldValue={setFieldValue}
                  name="image"
                  accept="image/png, .jpg, .jpeg"
                  customUploadText={
                    <span>
                      {t("drop-image")} {t("or")} <br />
                      <b>{t("select-to-browse")}</b>
                    </span>
                  }
                  customUploadImgSrc={uploadImgPlaceholder}
                  showFullPreview={true}
                  imgUrl={values.image}
                />
              )}
            </div>
            <CustomTextField
              name="title"
              label={t("name")}
              placeholder={t("enter-quest-name")}
            />
            {!isAddLanguage && (
              <CustomSelectField
                name="typeId"
                label={t("type")}
                placeholder={t("select-quest-type")}
                fieldNames={{ label: "name", value: "id" }}
                options={questTypes}
              />
            )}
            <ModalFooter
              loading={loading}
              saveText={t("save-quest")}
              onCancel={onCloseAddQuest}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddQuest;
