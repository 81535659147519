import { FunctionComponent, useState } from "react";
import ChallengeDataTable from "./ChallengeDataTable";
import AllParticipatonCompanies from "./AllParticipatonCompanies";

interface ChallengeDescriptionProps {
  companies: any;
  challengeId: string;
}

const ParticipationCompanies: FunctionComponent<ChallengeDescriptionProps> = ({
  companies,
  challengeId,
}) => {
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <>
      <ChallengeDataTable
        titleLeft="Participating Companies"
        titleRight="See More"
        data={
          companies?.length
            ? companies.map((item: any) => ({
                userName: item.name,
                userImage: item.image,
              }))
            : []
        }
        noSteps={true}
        setVisible={setVisible}
      />
      {visible ? (
        <AllParticipatonCompanies
          visible={visible}
          setVisible={setVisible}
          challengeId={challengeId}
        />
      ) : null}
    </>
  );
};
export default ParticipationCompanies;
