import { MoreOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, Menu, Space, Switch, Tag } from "antd";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import CustomButton from "components/custom-button/CustomButton";
import CustomMenu from "components/custom-menu/CustomMenu";
import { customNotification } from "components/custom-notification/CustomNotification";
import CustomTable from "components/custom-table/CustomTable";
import CustomPopconfirm from "components/form/custom-pop-confirm/CustomPopConfirm";
import { cloneObject, errorFormat, PERMS } from "helpers";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import {
  ClientList,
  Comparison,
  useGetClientListQuery,
  useLazyGetClientListQuery,
} from "graphql/_generated/graphql";
import moment from "moment";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import InviteChildCompany from "features/company/invite-child-company/InviteChildCompany";
import { useCompany } from "../useCompany";
import { useLoggedInUser } from "hooks/useLoggedInUser";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

type Props = {
  setInviteVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const parentFilter = {
  key: "parent",
  value: null,
  comparison: Comparison.Equal,
};

const CompaniesTreeList = ({ setInviteVisible }: Props) => {
  const { t } = useTranslation();
  const [filtersData, setFiltersData] = useState<any>(null);
  const [columnsData, setColumnsData] = useState<ClientList>();
  const region = useSelector((state: RootState) => state.auth.region);
  const {
    isLoading: loading,
    handleToggleActivationCompany,
    handleResendInvite,
  } = useCompany();
  const { isFetching, refetch } = useGetClientListQuery();
  const [getAllClientsDataFun] = useLazyGetClientListQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const [parentId, setParentId] = useState("");
  const [visible, setVisible] = useState(false);
  const { handleError } = useErrorHandler();
  const navigate = useNavigate();

  const { isClient } = useLoggedInUser();

  const resendInviteCompany = async (id: string) => {
    try {
      await handleResendInvite({ clientId: id });
      customNotification("success", "Resent Invite successfully");
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const handleMenu = ({ key, id, record }: any) => {
    if (key === "Add-company") {
      handleEdit(id);
    } else if (key === "Edit-company") {
      navigate("/edit-company", {
        state: id,
      });
    } else if (key === "resend-invite") {
      resendInviteCompany(id);
    }
  };

  const handleEdit = (id: string) => {
    setParentId(id);
    setVisible(true);
  };

  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (!isClient) {
      setFiltersData({
        filters: {
          and: [parentFilter],
        },
      });
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [isFetching, filtersData, region]);

  const fetchData = () => {
    if (!filtersData?.filters) return;

    fetchDataFromApi(getAllClientsDataFun, setColumnsData, filtersData, {
      onlyFirstChild: true,
    }).catch((e) => handleError(e));
  };

  const columns = [
    {
      width: "10%",
      title: t("id"),
      key: "id",
      sorter: {
        multiple: 1,
      },
      render: (_text: any, _record: any, index: number) =>
        (currentPage - 1) * pageSize + index + 1,
    },
    {
      width: "20%",
      title: t("company-name"),
      key: "companyName",
      dataIndex: "companyName",
      sorter: {
        multiple: 1,
      },
      render: (companyName: string, { image }: any) => (
        <Space>
          <CustomAvatar imgSrc={image} />
          <div className="cut-text">{companyName}</div>
        </Space>
      ),
    },
    // {
    //   align: "center" as "center",
    //   width: "20%",
    //   title: t("employees-num"),
    //   key: "numberOfEmployee",
    //   dataIndex: "numberOfEmployee",
    // },
    {
      align: "center" as "center",
      width: "20%",
      title: t("no-of-subscriptions"),
      key: "subscriptions",
      dataIndex: "subscriptions",
    },
    {
      width: "20%",
      title: t("registration-date"),
      key: "createdAt",
      dataIndex: "createdAt",
      sorter: {
        multiple: 1,
      },
      render: (createdAt: string | undefined) =>
        moment(createdAt).format("MMMM Do YYYY, h:mm:ss a"),
    },
    {
      width: "20%",
      title: t("status"),
      dataIndex: "isActive",
      key: "isActive",
      align: "center" as "center",
      render: (isActive: boolean, { isProfileComplete }: any) =>
        !isProfileComplete ? (
          <Tag className="pending-tag">{t("pending")}</Tag>
        ) : isActive ? (
          <Tag className="active-tag">{t("active")}</Tag>
        ) : (
          <Tag className="de-active-tag">{t("deactivated")}</Tag>
        ),
    },
    {
      width: "20%",
      align: "center" as "center",
      title: t("activation"),
      dataIndex: "id",
      key: "id",
      render: (id: string, { isActive }: any) => (
        <CheckPermissions permission={PERMS.TOGGLE_CLIENT_ACTIVATION}>
          <CustomPopconfirm
            onConfirm={async () => {
              try {
                const isActive = await handleToggleActivationCompany(id);
                customNotification(
                  "success",
                  isActive
                    ? t("company-activation-successfully")
                    : t("company-deactivation-successfully"),
                );
                refetch();
              } catch (error) {
                handleError(error as string | object);
              }
            }}
            title={
              isActive
                ? t("confirm-deactivate-company")
                : t("confirm-activate-company")
            }
          >
            <Switch
              className="custom-switch"
              defaultChecked
              checked={isActive}
            />
          </CustomPopconfirm>
        </CheckPermissions>
      ),
    },
    {
      width: "5%",
      align: "center" as "center",
      dataIndex: "id",
      key: "id",
      render: (id: string, record: any) => (
        <Dropdown
          overlay={
            <CheckPermissions permission={PERMS.INVITE_CLIENT}>
              <CustomMenu
                items={[
                  { label: t("add-child-company"), key: "Add-company" },
                  { label: t("company-detail"), key: "Edit-company" },
                  ...(!record.isProfileComplete
                    ? [{ label: t("resend-invite"), key: "resend-invite" }]
                    : []),
                ]}
                onClick={({ domEvent, key, keyPath }) =>
                  handleMenu({ domEvent, key, keyPath, id, record })
                }
              />
            </CheckPermissions>
          }
        >
          <CustomButton type="link" className="link-btn">
            <MoreOutlined />
          </CustomButton>
        </Dropdown>
      ),
    },
  ];

  const ExpandedRowRender = ({ id }: { id: string }) => {
    const {
      data,
      isLoading: subLoading,
      isFetching,
      refetch,
    } = useGetClientListQuery({
      filters: {
        and: [{ key: "parent", value: id, comparison: Comparison.Equal }],
      },
    });

    useEffect(() => {
      refetch();
    }, []);

    return (
      <CustomTable
        isNestedTable={true}
        showSearch={false}
        loading={subLoading} // Use subLoading for loading
        columns={columns}
        dataSource={data?.getClientList.data.map((item) => {
          const { children, ...rest } = item;
          return {
            ...rest,
            hasChildren: children && children.length > 0,
          };
        })}
        pagination={false}
        showHeader={false}
        rowKey="id"
        expandable={{
          expandedRowRender: (record) => <ExpandedRowRender id={record.id} />,
          rowExpandable: (record) => record.hasChildren,
        }}
      />
    );
  };

  return (
    <>
      <CustomTable
        filters={{
          handleCallback: async (filtersData: any) => {
            const mergedFilters = { ...filtersData };
            mergedFilters.filters = {
              ...mergedFilters.filters,
              and: isClient
                ? mergedFilters.filters?.and || []
                : [parentFilter, ...(mergedFilters.filters?.and || [])],
            };
            setPageSize(mergedFilters?.pagination?.offset?.pageSize);
            setCurrentPage(mergedFilters?.pagination?.offset?.page);
            // fetchDataFromApi(getAllClientsDataFun, setColumnsData, filtersData);
            setFiltersData(mergedFilters);
          },
          date: [
            {
              applyAs: "and",
              key: "createdAt",
              comparison: "Range",
            },
          ],
          manualFilter: {
            applyAs: "and",
            comparison: "Equal",
            key: "parent",
            value: null,
          },
        }}
        totalItems={columnsData?.metadata?.total}
        loading={isLoading}
        columns={columns}
        permission={PERMS.INVITE_CLIENT}
        onClick={() => setInviteVisible(true)}
        btnText={t("company.invite-new-company")}
        dataSource={columnsData?.data.map((item) => {
          const { children, ...rest } = item;
          return {
            ...rest,
            hasChildren: children && children.length > 0,
          };
        })}
        expandable={{
          expandedRowRender: (record) => <ExpandedRowRender id={record.id} />,
          rowExpandable: (record) => record.hasChildren,
        }}
        rowKey="id"
      />
      <InviteChildCompany
        id={parentId}
        visible={visible}
        setVisible={setVisible}
      />
    </>
  );
};

export default CompaniesTreeList;
