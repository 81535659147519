import { unixTimestampMs } from "helpers/DatetimeUtils";

/**
 * Question initial for different types.
 */
let initialQuestionDifferentials: any = {
  options: {
    SN: 1,
    id: "",
    score: undefined,
    text: "",
    tags: [],
    createdAt: unixTimestampMs(),
  },

  ratings: {
    SN: 1,
    id: "",
    score: undefined,
    text: "",
    tags: [],
  },

  steps: {
    SN: 1,
    id: "",
    text: "",
    start: undefined,
    end: undefined,
    emoji: undefined,
    score: undefined,
    tags: [],
  },

  booleans: {
    SN: 1,
    id: "",
    emoji: undefined,
    text: "",
    score: undefined,
    tags: [],
  },
};

export default initialQuestionDifferentials;
