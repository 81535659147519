import EnumBoolean from "features/assessment/types/EnumBoolean";
import EnumQuestionHierarchy from "features/assessment/types/EnumQuestionHierarchy";
import IAssessmentPillarQuestion from "features/assessment/types/IAssessmentPillarQuestion";
import booleansInitialObject from "./booleansInitialObject";
import optionsInitialObject from "./optionsInitialObject";
import ratingsInitialObject from "./ratingsInitialObject";
import stepsInitialObject from "./stepsInitialObject";

const questionInitialObject: IAssessmentPillarQuestion = {
  SN: 0,
  id: "",
  optionType: "singleChoice",
  categoryId: undefined,
  area: undefined,
  isEmojiEnable: false,
  emoji: null,
  subQuestions: [],
  text: "",
  ratingNumber: 0,
  score: undefined,
  start: undefined,
  end: undefined,
  hierarchy: EnumQuestionHierarchy.PILLAR_QUESTION,
  savedOnServer: EnumBoolean.NO,
  numberOfTitles: undefined,
  numberOfOptions: undefined,
  metricTitles: [],
  metricOptions: [],
  minChar: undefined,
  maxChar: undefined,
  options: [optionsInitialObject],
  ratings: [ratingsInitialObject],
  steps: [stepsInitialObject],
  booleans: [booleansInitialObject],
  metrices: [],
};

export default questionInitialObject;
