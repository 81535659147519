import {
  convertTranslationArrToObj,
  convertTranslationObjToArr,
} from "helpers";
import {
  MutationUpdatePillarArgs,
  useGetConfigPillarsQuery,
  useLazyGetPillarQuery,
  useUpdatePillarMutation,
} from "graphql/_generated/graphql";

//define the function types
export type TypeHandleUpdatePillarFun = (
  values: MutationUpdatePillarArgs
) => Promise<void>;
export type TypeHandleFetchEditPillarDataFun = (id: string) => Promise<any>;

//define the type of usePillar hook
type TypeUseUpdatePillar = () => {
  isLoading: boolean;
  handleUpdatePillar: TypeHandleUpdatePillarFun;
  handleFetchEditPillarData: TypeHandleFetchEditPillarDataFun;
};

export const useUpdatePillar: TypeUseUpdatePillar = () => {
  const [updatePillarFun, { isLoading }] = useUpdatePillarMutation();
  const [prevEditDataFun, { isLoading: prevDataLoading }] =
    useLazyGetPillarQuery();
  const { refetch } = useGetConfigPillarsQuery();

  //this function is responsible for updating the Pillar data
  const handleUpdatePillar = async (values: MutationUpdatePillarArgs) => {
    try {
      let translations = await convertTranslationArrToObj(
        values.updateObj.translations
      );
      let params = { updateObj: { ...values.updateObj, translations } };

      await updatePillarFun({
        id: values.id,
        updateObj: params.updateObj,
      }).unwrap();

      refetch();
    } catch (error: any) {
      throw error;
    }
  };

  //this function is responsible for fetching the specific Pillar data which Pillar id we will be provided
  const handleFetchEditPillarData = async (id: string) => {
    try {
      const res = await prevEditDataFun({ id: id }).unwrap();
      const translations = await convertTranslationObjToArr(
        res.getPillar?.translations
      );

      return { ...res?.getPillar, translations };
    } catch (error) {
      throw error;
    }
  };

  return {
    isLoading: isLoading || prevDataLoading,
    handleUpdatePillar,
    handleFetchEditPillarData,
  };
};
