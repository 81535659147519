import Search from "antd/lib/input/Search";
import CustomButton from "components/custom-button/CustomButton";
import Icon from "@ant-design/icons";
import PlusCircleFilled from "@ant-design/icons/PlusCircleFilled";
import { SearchIcon } from "assets/icons/SearchIcon";

import "./CustomTable.module.scss";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import React, { CSSProperties } from "react";

interface CustomTableHeaderProps {
  onClick?: any;
  permission: string;
  handleSearchInput: any;
  title: string;
  btnText: string;
  showSearch: boolean;
  defaultValue: string;
  actions?: () => React.ReactNode;
  additionalButtons?: React.ReactNode[] | undefined;
  searchStyle?: CSSProperties;
  headerStyle?: CSSProperties;
}

const CustomTableHeader: React.FunctionComponent<CustomTableHeaderProps> = ({
  onClick,
  permission,
  handleSearchInput,
  title,
  btnText,
  showSearch,
  defaultValue,
  actions,
  additionalButtons,
  searchStyle,
  headerStyle,
}) => {
  const renderBtn = () => (
    <CustomButton
      className="primary-btn margin-left"
      icon={btnText.includes("Add") ? <PlusCircleFilled /> : undefined}
      onClick={onClick}
    >
      {btnText}
    </CustomButton>
  );

  const renderAdditionalButtons = (buttons?: React.ReactNode[]) => {
    if (!buttons) return;

    return buttons.map((button, index) => (
      <div key={index} className="margin-left">
        {button}
      </div>
    ));
  };

  return (
    <div className={"table-header"}>
      <h1 style={headerStyle} className={"title"}>
        {title}
      </h1>
      <div className="search-container">
        {showSearch ? (
          <Search
            style={searchStyle}
            placeholder={"Search"}
            className="search-input"
            bordered={false}
            onChange={handleSearchInput}
            defaultValue={defaultValue}
            enterButton={<Icon component={SearchIcon} />}
          />
        ) : null}
        {renderAdditionalButtons(additionalButtons)}
        {btnText ? (
          permission ? (
            <CheckPermissions permission={permission}>
              {renderBtn()}
            </CheckPermissions>
          ) : (
            renderBtn()
          )
        ) : null}
        {actions && <div className="margin-left">{actions()}</div>}
      </div>
    </div>
  );
};

export default CustomTableHeader;
