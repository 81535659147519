import { Col, Row } from "antd";
import {
  FilterValuesProps,
  WidgetExtraProps,
} from "features/company-dashboard/types";
import { timeFilterTypes } from "features/company-dashboard/utils";
import { ProgressStatus } from "graphql/_generated/graphql";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "../../CompanyDashboard.module.scss";
import { SectionHeader } from "../SectionHeader";
import { InfoTextProps } from "../organization-metrices/types";
import { Program } from "./Program";

export const Programmes: FunctionComponent<
  InfoTextProps & WidgetExtraProps
> = ({ selectedInfoText, setSelectedInfoText, companyRegisteredDate }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(true);
  const [userFilters, setUserFilters] = useState<any>({
    clientIds: null,
    departmentIds: null,
    gender: null,
    positionIds: null,
    age: null,
  });
  const [selectedValues, setSelectedValues] = useState<FilterValuesProps>({
    pastDays: timeFilterTypes.PAST_12_MONTHS,
    customDateRange: "",
  });

  const PROGRAMS: {
    [key: string]: {
      title: string;
      type: ProgressStatus;
      infoTexts: string[];
    };
  } = {
    "started-classes": {
      title: t("started-classes"),
      type: ProgressStatus.Left,
      infoTexts: [
        t("access-started-classes"),
        t("understand-employee-engagement"),
      ],
    },
    "inprogress-classes": {
      title: t("inprogress-classes"),
      type: ProgressStatus.InProgress,
      infoTexts: [
        t("track-ongoing-classes"),
        t("workforce-progress-engagement"),
      ],
    },
    "finished-classes": {
      title: t("finished-classes"),
      type: ProgressStatus.Done,
      infoTexts: [
        t("view-completed-classes"),
        t("successful-engagement-insights"),
      ],
    },
  };

  return (
    <>
      <div className={styles["checkins-chart"]}>
        <SectionHeader
          visible={visible}
          setVisible={setVisible}
          userFilters={userFilters}
          setUserFilters={setUserFilters}
          sectionTitle={t("classes")}
          initialValues={selectedValues}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
          companyRegisteredDate={companyRegisteredDate}
          open={open}
          setOpen={setOpen}
        />
        <div className={styles["checkins-list"]}>
          <Row gutter={[16, 16]} className={styles["row-flex"]}>
            {Object.keys(PROGRAMS).map((item) => {
              return (
                <Col xs={24} lg={12} xl={8}>
                  <Program
                    item={{
                      ...PROGRAMS[item],
                      selectedValues,
                      visible,
                    }}
                    userFilters={userFilters}
                    selectedInfoText={selectedInfoText}
                    setSelectedInfoText={setSelectedInfoText}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </>
  );
};
