import { List } from "antd";
import styles from "features/rewards/css/CompanyStats.module.scss";
import { useTranslation } from "react-i18next";
import { useCompanyStatsViewModel } from "../hooks/useCompanyStatsViewModel";
import CompanyItem from "./CompanyItem";
import CompanyStatsDetails from "./CompanyStatsDetails";
interface CompanyStatsProps {
  tierId: string;
}

const CompanyStats: React.FunctionComponent<CompanyStatsProps> = ({
  tierId,
}) => {
  const { t } = useTranslation();

  const vm = useCompanyStatsViewModel({ tierId });

  return (
    <div>
      <div className={styles["header"]}>
        <div className={styles["title"]}>{`${t("companies")} (${
          vm.companiesData?.getCompaniesListByTier.metadata.total ?? 0
        })`}</div>
        <div onClick={() => vm.setVisible(true)} className={styles["see-btn"]}>
          {t("see-more")}
        </div>
      </div>
      <div className={styles["desc-container"]}>
        <List
          dataSource={vm.companiesData?.getCompaniesListByTier.data}
          renderItem={(item, index) => (
            <CompanyItem
              id={index + 1}
              title={item.companyName}
              image={item.image ?? ""}
              users={item.usersCount}
            />
          )}
          loading={vm.isLoading}
        />
      </div>

      {vm.visible && (
        <CompanyStatsDetails
          visible={vm.visible}
          data={vm.data}
          pagination={vm.pagination}
          currentPage={vm.currentPage}
          isFetching={vm.isFetching}
          onPaginationChange={vm.onPaginationChange}
          handleSearchInput={vm.handleSearchInput}
          handleCancel={vm.handleCancel}
        />
      )}
    </div>
  );
};

export default CompanyStats;
