import {
  MutationForgotPasswordArgs,
  useForgotPasswordMutation,
  useRequestOtpMutation,
} from "graphql/_generated/graphql";

type UseForgotPasswordType = () => {
  isLoading: boolean;
  handleForgotPassword: (values: MutationForgotPasswordArgs) => Promise<void>;
};

export const useForgotPassword: UseForgotPasswordType = () => {
  const [forgotPasswordFun, { isLoading }] = useForgotPasswordMutation();
  const [requestOtpFun] = useRequestOtpMutation();

  //this function is responsible for forgot password
  const handleForgotPassword = async (values: MutationForgotPasswordArgs) => {
    const params = values;

    try {
      await forgotPasswordFun({
        body: { ...params.body, forAdmin: true },
      }).unwrap();
      await requestOtpFun({
        body: { ...params.body, forAdmin: true },
      }).unwrap();
    } catch (error) {
      throw error;
    }
  };

  return { isLoading, handleForgotPassword };
};
