import * as Yup from "yup";
import * as Validations from "./common/validations";

export const eapSchema = Yup.object().shape({
  isGlobal: Yup.mixed(),
  number: Validations.requiredPhoneNumber("phone-number", "countryCode"),
  countryCode: Yup.mixed().when("isGlobal", {
    is: (value: any) => value === false,
    then: Validations.requiredAlphaFieldWithoutSpaces("Country Code"),
  }),
});
