import { customNotification } from "components/custom-notification/CustomNotification";
import { ConfigDataProps } from "features/positions/hooks/useAddPositionViewModel";
import { FormikHelpers } from "formik";
import {
  ConfigOptions,
  DepartmentCreate,
  useLazyGetDepartmentsConfigQuery,
} from "graphql/_generated/graphql";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useLoggedInUser } from "hooks/useLoggedInUser";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useDepartment } from "../useDepartment";

export const useAddDepartmentViewModel = () => {
  const { isClient, isSuperAdmin, isViwellAdmin } = useLoggedInUser();

  const { handleError } = useErrorHandler();
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState<DepartmentCreate>({
    name: "",
    translations: [],
  });
  const [editId, setEditId] = useState<string | null>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [clientId, setClientId] = useState<string>("");
  const [inviteUserView, setInviteUserView] = useState<boolean>(false);

  const [configData, setConfigData] = useState<ConfigDataProps>({
    departmentId: "",
    positionId: "",
  });
  const [getDepartmentsConfigFun, { isFetching: isDeptsLoading }] =
    useLazyGetDepartmentsConfigQuery();

  const [departmentsData, setDepartmentsData] = useState<ConfigOptions[]>([
    {
      id: "",
      label: "",
    },
  ]);

  const closeModal = () => {
    setVisible(false);
    setEditId(null);
  };

  async function getDepartments() {
    const data = await getDepartmentsConfigFun({
      clientId,
    }).unwrap();
    setDepartmentsData(data.getDepartmentsConfig.options);
  }

  useEffect(() => {
    getDepartments();
  }, [clientId]);

  const handleClientId = (client: string) => {
    setClientId(client);
    setConfigData({
      departmentId: "",
      positionId: "",
    });
  };

  const handleEditId = (id: string) => {
    setEditId(id);
    setVisible(true);
  };

  useEffect(() => {
    fetchPositionData(editId);
  }, [editId]);

  const {
    isLoading,
    handleAddDepartment,
    handleFetchPreviousDepartmentData,
    handleUpdateDepartment,
  } = useDepartment();

  const navigate = useNavigate();

  const redirectToDepartments = () => {
    navigate("/departments");
  };

  const fetchPositionData = (editId?: string | null) => {
    if (editId) {
      handleFetchPreviousDepartmentData(editId).then((data) => {
        setInitialValues({
          name: data.name ?? "",
          translations: data.translations,
        });
      });
    } else {
      setInitialValues({
        name: "",
        translations: [],
      });
    }
  };

  const handleInviteUser = () => {
    setInviteUserView(true);
    setVisible(true);
  };

  const handleSubmit = async (
    values: DepartmentCreate,
    { resetForm }: FormikHelpers<DepartmentCreate>,
  ) => {
    const data = { ...values };
    if (isSuperAdmin || isViwellAdmin) {
      data.clientId = clientId;
    }
    if (!editId) {
      try {
        const deptResponse = await handleAddDepartment({ create: data });
        getDepartments();
        customNotification("success", t("department-added-successfully"));
        resetForm();
        inviteUserView &&
          setConfigData?.({
            departmentId: deptResponse?.id,
            positionId: "",
          });
        closeModal?.();
      } catch (error) {
        handleError(error as string | object);
      }
    } else {
      try {
        await handleUpdateDepartment({ id: editId, updateObj: data });
        customNotification("success", t("department-updated-successfully"));
        getDepartments();
        resetForm();
        closeModal?.();
      } catch (error) {
        handleError(error as string | object);
      }
    }
  };

  return {
    fetchPositionData,
    handleSubmit,
    redirectToDepartments,
    isClient,
    initialValues,
    isLoading,
    closeModal,
    editId,
    visible,
    setVisible,
    setEditId,
    handleClientId,
    setInviteUserView,
    handleEditId,
    inviteUserView,
    configData,
    handleInviteUser,
    departmentsData,
    isDeptsLoading,
  };
};
