import { InfoCircleOutlined } from "@ant-design/icons";
import { useField } from "formik";
import * as React from "react";
import PhoneInput, { PhoneInputProps } from "react-phone-input-2";
import { useErrorTranslation } from "validations/schema/common/useErrorTranslation";

interface PhoneFormInputProps extends PhoneInputProps {
  name: string;
  label?: string;
}

const PhoneFormInput: React.FunctionComponent<PhoneFormInputProps> = (
  props,
) => {
  const { label, name, ...restProps } = props;
  const [field, meta, helpers] = useField(name);
  const { errorT } = useErrorTranslation();
  const handleChange = (value: string) => {
    helpers.setTouched(true);
    helpers.setValue(`+${value}`);
  };
  return (
    <div>
      {label && (
        <label
          htmlFor={name}
          className="font-normal text-sm text-[#121212] font-sf-pro-text before:content-['*'] before:text-red-500 inline-block mb-1"
        >
          {label}
        </label>
      )}
      <PhoneInput
        {...restProps}
        value={field.value}
        onChange={handleChange}
        containerStyle={{
          height: "3.5rem",
          width: "100%",
        }}
        inputStyle={{
          border: "1px solid #D6D6D6",
          height: "3.5rem",
          width: "100%",
          borderRadius: "0.5rem",
          borderColor: meta.error && meta.touched ? "#E53935" : "#D6D6D6",
        }}
        buttonStyle={{
          backgroundColor: "transparent",
          border: "1px solid #D6D6D6",
          borderRadius: "0.5rem 0px 0px 0.5rem",
          borderRight: "0px",
          paddingLeft: "10px",
          borderColor: meta.error && meta.touched ? "#E53935" : "#D6D6D6",
        }}
      />
      {meta.touched && meta.error ? (
        <div className="flex items-center gap-x-1">
          <InfoCircleOutlined
            style={{
              color: "#E53935",
            }}
          />
          <div className="text-[#E53935] font-normal">{errorT(meta.error)}</div>
        </div>
      ) : null}
    </div>
  );
};

export default PhoneFormInput;
