// import CustomButton from "app/components/common/custom-button/CustomButton";
// import DashboardLayout from "app/components/dashboard/dashboard-layout/DashboardLayout";
// import React, { useState } from "react";
// import AddMetric from "../add-metric/AddMetric";
// import MetricList from "../metric-list/MetricList";
// import styles from "./Metric.module.scss";
// interface MetricProps {}

// const Metric: React.FunctionComponent<MetricProps> = (props) => {
//   const [visible, setVisible] = useState<boolean>(false);
//   const [editId, setEditId] = useState<string | null>(null);
//   return (
//     <>
//       <DashboardLayout openKey={["Reports"]}>
//         <div className={styles["metric"]}>
//           <CustomButton
//             className="primary-btn"
//             onClick={() => setVisible(true)}
//           >
//             Add New Metric
//           </CustomButton>
//         </div>
//         <MetricList />
//       </DashboardLayout>
//       <AddMetric
//         visible={visible}
//         setVisible={setVisible}
//         editId={editId}
//         setEditId={setEditId}
//       />
//     </>
//   );
// };

export default () => {}; // Metric;
