import { MoreOutlined } from "@ant-design/icons";
import { Dropdown, Modal, Select, Space, Switch, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import CustomButton from "components/custom-button/CustomButton";
import CustomMenu from "components/custom-menu/CustomMenu";
import CustomTable from "components/custom-table/CustomTable";
import CustomPopconfirm from "components/form/custom-pop-confirm/CustomPopConfirm";
import { Category, Comparison } from "graphql/_generated/graphql";
import { PERMS, getTranslatedValue } from "helpers";
import { CONFIRM_MODAL_CONFIG } from "helpers/constants";
import moment from "moment";
import { useTranslation } from "react-i18next";
import AddCategory from "../add-category/AddCategory";
import { useCategoriesListViewModel } from "../hooks/useCategoriesListViewModel";

const parentFilter = {
  key: "parent",
  value: null,
  comparison: Comparison.Equal,
};

const CategoriesList = () => {
  const { t } = useTranslation();

  const vm = useCategoriesListViewModel();

  const handleMenu = ({ key, id }: { key: string; id: string }) => {
    if (key === "edit-category") {
      vm.handleEdit(id);
    } else if (key === "delete-category") {
      Modal.confirm({
        ...CONFIRM_MODAL_CONFIG,
        title: t("warning"),
        content: (
          <>
            <div>{t("category.delete-warning")}</div>
          </>
        ),
        onOk: () => vm.handleDelete(id),
      });
    }
  };

  const columns: ColumnsType<Category> = [
    {
      width: 50,
      title: t("id"),
      dataIndex: "id",
      key: "id",
      sorter: {
        multiple: 1,
      },
      render: (_text: any, _record: any, index: number) =>
        (vm.currentPage - 1) * vm.pageSize + index + 1,
    },
    {
      width: "50%",
      title: t("name"),
      dataIndex: "name",
      key: "name",
      sorter: {
        multiple: 1,
      },
      render: (name: string, item: any) => (
        <Space>
          <CustomAvatar imgSrc={item.icon} />
          <div>{getTranslatedValue(item, "name")}</div>
        </Space>
      ),
    },
    {
      title: t("pillar-title"),
      dataIndex: "pillar",
      key: "pillar",
      sorter: {
        multiple: 1,
      },
      render: (_text: string, _record, index: number) => _record.pillar?.title,
    },
    {
      width: "20%",
      title: t("creation-date"),
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: {
        multiple: 1,
      },
      render: (createdAt: any) =>
        moment(createdAt).format("MMMM Do YYYY, h:mm:ss a"),
    },
    {
      width: "15%",
      title: t("status"),
      dataIndex: "isActive",
      key: "isActive",
      align: "center" as "center",
      render: (isActive: boolean) =>
        isActive ? (
          <Tag className="active-tag">{t("active")}</Tag>
        ) : (
          <Tag className="de-active-tag">{t("deactivated")}</Tag>
        ),
    },
    {
      width: "15%",
      align: "center" as "center",
      title: t("activation"),
      dataIndex: "id",
      key: "id",
      render: (id: string, { isActive }: any) => (
        <CheckPermissions permission={PERMS.TOGGLE_CATEGORY_ACTIVATION}>
          <CustomPopconfirm
            onConfirm={() => vm.handleDeActivation(id)}
            title={
              isActive
                ? t("confirm-deactivate-category")
                : t("confirm-activate-category")
            }
          >
            <Switch
              className="custom-switch"
              defaultChecked
              checked={isActive}
            />
          </CustomPopconfirm>
        </CheckPermissions>
      ),
    },
    {
      width: 50,
      align: "center" as "center",
      title: "",
      dataIndex: "id",
      key: "id",
      render: (id: string) => (
        <>
          <Dropdown
            overlay={
              <CheckPermissions permission={PERMS.UPDATE_CATEGORY}>
                <CustomMenu
                  items={[
                    { label: t("edit-category"), key: "edit-category" },
                    { label: t("delete-category"), key: "delete-category" },
                  ]}
                  onClick={({ key }) => handleMenu({ key, id })}
                />
              </CheckPermissions>
            }
          >
            <CustomButton type="link" className="link-btn">
              <MoreOutlined />
            </CustomButton>
          </Dropdown>
        </>
      ),
    },
  ];

  return (
    <div>
      <CustomTable
        filters={{
          handleCallback: vm.handleFilterCallback,
          date: [
            {
              applyAs: "and",
              key: "createdAt",
              comparison: "Range",
            },
          ],

          status: {
            applyAs: "and",
            key: "isActive",
            comparison: "Equal",
          },
          manualFilters: [
            {
              key: "pillar.id",
              comparison: "Equal",
              applyAs: "and",
              render: (onChangeCallback) => (
                <Select
                  options={vm.pillarsData}
                  fieldNames={{ label: "label", value: "value" }}
                  className="filter-select"
                  onChange={onChangeCallback}
                  allowClear
                  placeholder="Pillar Type: All"
                />
              ),
            },
          ],
        }}
        totalItems={vm.columnsData?.metadata?.total}
        loading={vm.isLoading}
        columns={columns}
        permission={PERMS.ADD_NEW_CATEGORY}
        onClick={vm.showModal}
        btnText={t("category.add-category")}
        dataSource={vm.columnsData?.data}
        rowKey="id"
      />
      <AddCategory
        visible={vm.visible}
        editId={vm.editId}
        handleCancel={vm.handleCancel}
        onSubmit={vm.onSubmit}
        isLoading={vm.isCategoryLoading}
        initialValues={vm.initialValues}
        pillarsData={vm.pillarsData}
      />
    </div>
  );
};

export default CategoriesList;
