export function parseJwt(token: string) {
  if (!token) {
    return "public";
  }

  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(""),
  );

  try {
    return JSON.parse(jsonPayload);
  } catch (error) {
    // Handle JSON parsing error, e.g., return an error object or log the issue.
    console.error("Error parsing JWT:", error);
    return null; // or throw an error as needed
  }
}
