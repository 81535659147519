import { useGetPositionsIdAndNameQuery } from "graphql/_generated/graphql";
import { useEffect, useState } from "react";

type usePositionsIdAndName = () => {
  positionData: { label: string; value: string; clientId: string }[];
};

export const usePositionsIdAndName: usePositionsIdAndName = () => {
  const { data } = useGetPositionsIdAndNameQuery();
  const [positionData, setPositionData] = useState([]);

  useEffect(() => {
    let dummyArr: any = [];
    data?.getPositionList.data.map(
      (item: { name: string; id: string; clientId: string }) => {
        dummyArr.push({
          label: item.name,
          value: item.id,
          clientId: item.clientId,
        });
      }
    );
    setPositionData(dummyArr);
  }, [data?.getPositionList.data]);

  return { positionData };
};
