import {
  ContentType,
  useGetConfigTagsQuery,
  useGetContentConfigQuery,
  useGetContentListQuery,
  useLazyGetCategoriesTreeQuery,
  useLazyGetVideoDetailsQuery,
} from "graphql/_generated/graphql";
import { useSpecialistConfigList } from "hooks/useSpecialistConfig";

export const useGetContentConfig = () => {
  const { data: contentData, refetch: contentRefetch } =
    useGetContentConfigQuery(
      { type: ContentType.Recipe },
      {
        refetchOnMountOrArgChange: true,
      },
    );

  const { refetch } = useGetContentListQuery();
  const { getSpecialistConfigData } = useSpecialistConfigList();
  const [getVideoDetailsFun] = useLazyGetVideoDetailsQuery();
  const { data: tagsData } = useGetConfigTagsQuery();
  const { refetch: contentConfigRefetch } = useGetContentConfigQuery();
  const [getCategoriesFun] = useLazyGetCategoriesTreeQuery();

  return {
    contentData,
    contentRefetch,
    getCategoriesFun,
    tagsData,
    contentConfigRefetch,
    getSpecialistConfigData,
    getVideoDetailsFun,
    refetch,
  };
};
