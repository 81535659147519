import { customNotification } from "components/custom-notification/CustomNotification";
import {
  MutationInviteViwellAdminArgs,
  useGetViwellAdminListQuery,
  useInviteViwellAdminMutation,
} from "graphql/_generated/graphql";
import { useTranslation } from "react-i18next";

export type TypeInviteAdmin = (
  values: MutationInviteViwellAdminArgs,
) => Promise<void>;

type TypeUseInviteAdmin = () => {
  isLoading: boolean;
  inviteAdmin: TypeInviteAdmin;
};

export const useInviteAdmin: TypeUseInviteAdmin = () => {
  const [inviteViwellAdmin, { isLoading }] = useInviteViwellAdminMutation();
  const { refetch, isLoading: getAdminsLoading } = useGetViwellAdminListQuery();
  const { t } = useTranslation();

  const inviteAdmin = async (values: MutationInviteViwellAdminArgs) => {
    try {
      await inviteViwellAdmin(values).unwrap();
      customNotification("success", t("admin-invited-success"));

      refetch();
    } catch (error: any) {
      throw error;
    }
  };

  return { isLoading: isLoading || getAdminsLoading, inviteAdmin };
};
