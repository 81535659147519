import IAssessmentPillar from "features/assessment/types/IAssessmentPillar";
import IAssessmentPillarHash from "features/assessment/types/IAssessmentPillarHash";
import generateNewQuestion from "./generateNewQuestion";
import Pillars from "./Pillars";

/**
 * Generates testing Pillars for Assessment Page.
 *
 * @returns Object
 */
const initialPillarsArray = (): IAssessmentPillarHash => {
  let localPillarsArr = Pillars;

  Object.values(Pillars).map((item: IAssessmentPillar): void => {
    const totalQuestions = Object.keys(item.questions ?? {})?.length;

    localPillarsArr = {
      ...localPillarsArr,
      [item.id]: {
        ...item,
        questions: {},
      },
    };
  });

  return localPillarsArr;
};

export default initialPillarsArray;
