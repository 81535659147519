import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setSearch } from "store/navigationSlice";
import { usePersistedState } from "./usePersistedState";
import { useState } from "react";

export const defaultSearch = { search: undefined };

const useSearch = (persistEnabled?: boolean) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const persistedSearch = usePersistedState("search", location, defaultSearch);
  const [localSearch, setLocalSearch] = useState<{
    search: {
      text: string;
    };
  }>();

  const handleSearch = (e: any) => {
    let params;
    setLocalSearch({
      search: {
        text: e.target.value,
      },
    });
    if (e.target.value) {
      params = { text: e.target.value };
    }
    if (persistEnabled) {
      dispatch(
        setSearch({
          location,
          ...{ search: params },
        }),
      );
    }

    persistEnabled && navigate("#persist");
  };

  return {
    search: persistEnabled ? persistedSearch : localSearch,
    handleSearch,
  };
};

export default useSearch;
