import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { customNotification } from "components/custom-notification/CustomNotification";
import { Form, Formik } from "formik";
import { Switch } from "formik-antd";
import {
  AnyAssessmentType,
  useGetAssessmentTypesQuery,
} from "graphql/_generated/graphql";
import { useEffect, useState } from "react";
import { useFetchDefaultSettings } from "../hooks/useFetchDefaultSettings";
import { useUpdateAssessmentType } from "../hooks/useUpdateAssessmentType";
import styles from "./TypeSettings.module.scss";
import { errorFormat } from "helpers";
import { Space } from "antd";
import { useFetchAssessmentType } from "../hooks/useFetchAssessmentType";
import { useTranslation } from "react-i18next";
import { useErrorHandler } from "hooks/useErrorHandler";

interface TypeSettingsProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  editId: string | null;
  setEditId: React.Dispatch<React.SetStateAction<string | null>>;
  assessmentType: any;
}

const TypeSettings: React.FunctionComponent<TypeSettingsProps> = ({
  visible,
  setEditId,
  setVisible,
  editId,
  assessmentType,
}) => {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const [initialValues, setInitialValues] = useState<any>({});
  const { isFetching, refetch } = useGetAssessmentTypesQuery();
  const objArray = Object.entries(initialValues).map(([key, value]) => ({
    key,
    value,
  }));
  const { handleFetchDefaultSettings, isLoading } = useFetchDefaultSettings();
  const { handleUpdateAssessmentType, isLoading: isLoadingUpdate } =
    useUpdateAssessmentType();
  const handleCancel = () => {
    setVisible(false);
    setEditId(null);
  };

  useEffect(() => {
    if (assessmentType) {
      console.log(initialValues);
      setInitialValues(assessmentType);
    } else {
      setInitialValues({});
    }
  }, [assessmentType]);

  return (
    <CustomModal
      title={`${assessmentType?.name || ""} Settings`}
      footer={false}
      onCancel={handleCancel}
      visible={visible}
      width={600}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={async (values, { resetForm }) => {
          try {
            const {
              isCategoriesVisible,
              isContentAssociationVisible,
              isExpiryDateVisible,
              isGroupingVisible,
              isMandatoryVisible,
              isNotificationsVisible,
              isPillarsVisible,
              isScoresVisible,
              isSkippableVisible,
              isTagsVisible,
              isSubQuestionsVisible,
              isWearableVisible,
              setColorVisible,
            } = values;

            await handleUpdateAssessmentType({
              assessmentTypeId: editId ?? "",
              update: {
                isCategoriesVisible,
                isContentAssociationVisible,
                isExpiryDateVisible,
                isGroupingVisible,
                isMandatoryVisible,
                isNotificationsVisible,
                isPillarsVisible,
                isScoresVisible,
                isSkippableVisible,
                isTagsVisible,
                isSubQuestionsVisible,
                isWearableVisible,
                setColorVisible,
              },
            });
            customNotification(
              "success",
              "Assessment type updated successfully",
            );
            resetForm();
            refetch();
            setVisible(false);
            setEditId(null);
          } catch (error) {
            handleError(error as string | object);
          }
        }}
      >
        {({ values }) => (
          <Form>
            <>
              {values.isCategoriesEditable && (
                <div className={styles["flex-grid"]}>
                  <div>{t("enabled-categories")}</div>
                  <Space>
                    {values["isCategoriesVisible"] ? t("on") : t("off")}
                    <Switch
                      className="custom-switch"
                      name="isCategoriesVisible"
                    />
                  </Space>
                </div>
              )}
              {values.isContentAssociationEditable && (
                <div className={styles["flex-grid"]}>
                  <div>{t("enable-content-association")}</div>
                  <Space>
                    {values["isContentAssociationVisible"] ? t("on") : t("off")}
                    <Switch
                      className="custom-switch"
                      name="isContentAssociationVisible"
                    />
                  </Space>
                </div>
              )}
              {values.isExpiryDateEditable && (
                <div className={styles["flex-grid"]}>
                  <div>{t("enable-expiry-date")}</div>
                  <Space>
                    {values["isExpiryDateVisible"] ? t("on") : t("off")}
                    <Switch
                      className="custom-switch"
                      name="isExpiryDateVisible"
                    />
                  </Space>
                </div>
              )}
              {values.isGroupingEditable && (
                <div className={styles["flex-grid"]}>
                  <div>{t("enable-grouping")}</div>
                  <Space>
                    {values["isGroupingVisible"] ? t("on") : t("off")}
                    <Switch
                      className="custom-switch"
                      name="isGroupingVisible"
                    />
                  </Space>
                </div>
              )}
              {values.isMandatoryEditable && (
                <div className={styles["flex-grid"]}>
                  <div>{t("is-mandatory")}</div>
                  <Space>
                    {values["isMandatoryVisible"] ? t("on") : t("off")}
                    <Switch
                      className="custom-switch"
                      name="isMandatoryVisible"
                    />
                  </Space>
                </div>
              )}
              {values.isNotificationsEditable && (
                <div className={styles["flex-grid"]}>
                  <div>{t("enable-notifications")}</div>
                  <Space>
                    {values["isNotificationsVisible"] ? t("on") : t("off")}
                    <Switch
                      className="custom-switch"
                      name="isNotificationsVisible"
                    />
                  </Space>
                </div>
              )}
              {values.isPillarsEditable && (
                <div className={styles["flex-grid"]}>
                  <div>{t("enable-pillars")}</div>
                  <Space>
                    {values["isPillarsVisible"] ? t("on") : t("off")}
                    <Switch className="custom-switch" name="isPillarsVisible" />
                  </Space>
                </div>
              )}
              {values.isScoresEditable && (
                <div className={styles["flex-grid"]}>
                  <div>{t("enable-scores")}</div>
                  <Space>
                    {values["isScoresVisible"] ? t("on") : t("off")}
                    <Switch className="custom-switch" name="isScoresVisible" />
                  </Space>
                </div>
              )}
              {values.isSkippableEditable && (
                <div className={styles["flex-grid"]}>
                  <div>{t("is-skippable")}</div>
                  <Space>
                    {values["isSkippableVisible"] ? t("on") : t("off")}
                    <Switch
                      className="custom-switch"
                      name="isSkippableVisible"
                    />
                  </Space>
                </div>
              )}
              {values.isSubQuestionsEditable && (
                <div className={styles["flex-grid"]}>
                  <div>{t("enable-subquestions")}</div>
                  <Space>
                    {values["isSubQuestionsVisible"] ? t("on") : t("off")}
                    <Switch
                      className="custom-switch"
                      name="isSubQuestionsVisible"
                    />
                  </Space>
                </div>
              )}
              {values.isTagsEditable && (
                <div className={styles["flex-grid"]}>
                  <div>{t("enable-tags")}</div>
                  <Space>
                    {values["isTagsVisible"] ? t("on") : t("off")}
                    <Switch className="custom-switch" name="isTagsVisible" />
                  </Space>
                </div>
              )}
              {values.isWearableEditable && (
                <div className={styles["flex-grid"]}>
                  <div>{t("enable-wearables")}</div>
                  <Space>
                    {values["isWearableVisible"] ? t("on") : t("off")}
                    <Switch
                      className="custom-switch"
                      name="isWearableVisible"
                    />
                  </Space>
                </div>
              )}
              {values.setColorEditable && (
                <div className={styles["flex-grid"]}>
                  <div>{t("enable-color")}</div>
                  <Space>
                    {values["setColorVisible"] ? t("on") : t("off")}
                    <Switch className="custom-switch" name="setColorVisible" />
                  </Space>
                </div>
              )}
            </>
            <ModalFooter
              saveText={t("save-text")}
              loading={isLoading || isLoadingUpdate}
              onCancel={handleCancel}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};
export default TypeSettings;
