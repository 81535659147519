import {
  useCreateHabitMutation,
  MutationCreateHabitArgs,
} from "graphql/_generated/graphql";

export const useAddHabit = () => {
  const [addHabitFun, { isLoading: isLoadingAddHabit }] =
    useCreateHabitMutation();

  const handleAddHabit = async (values: MutationCreateHabitArgs) => {
    try {
      const res = await addHabitFun({ habit: values.habit }).unwrap();
      return res.createHabit;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleAddHabit,
    isLoadingAddHabit,
  };
};
