import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Space } from "antd";
import { useTranslation } from "react-i18next";
import ContainerHeading from "features/assessment/questions/ContainerHeading";
import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
// import { useErrorTranslation } from "validations/schema/common/useErrorTranslation";
import CustomDivider from "components/custom-divider/CustomDivider";
import { CustomFastField } from "components/form/custom-fast-field/CustomFastField";
import { useGetAssessmentSettings } from "../hooks/useGetAssessmentSettings";
import { useUpdateAssessmentSettings } from "../hooks/useUpdateAssessmentSettings";
import { customNotification } from "components/custom-notification/CustomNotification";
import { errorFormat } from "helpers/errorFormat";
import { AssessmentSettingsCommon } from "./AssessmentSettingsCommon";
import { useUpdateAssessment } from "features/assessment/hooks/useUpdateAssessment";
import EmojiPicker from "components/form/custom-emoji-picker/EmojiPicker";
import ViwellColorPicker from "components/form/custom-color-picker/ViwellColorPicker";
import styles from "./AssessmentSettings.module.scss";
import { assessmentSettingsSchema } from "validations/schema/assessmentSchema";
import { useErrorHandler } from "hooks/useErrorHandler";

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  assessmentId: string | null;
  setRefetchSettings?: React.Dispatch<React.SetStateAction<boolean | null>>;
  initialValues?: any;
};

const AssessmentSettings = ({
  assessmentId,
  visible,
  setVisible,
  initialValues,
  setRefetchSettings,
}: Props) => {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const { handleGetAssessmentSettings, isLoadingAssessmentSettings } =
    useGetAssessmentSettings();

  const { handleUpdateSettings, isLoading } = useUpdateAssessmentSettings();
  const { handleUpdateAssessment } = useUpdateAssessment();

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <CustomModal
      width={600}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      title={
        <ContainerHeading
          text={`${initialValues?.name || ""} ${t("assessment.settings")}`}
        />
      }
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={assessmentSettingsSchema()}
        onSubmit={async (values) => {
          try {
            await handleUpdateSettings({
              id: assessmentId,
              update: values,
            });
            setVisible(false);
            setRefetchSettings?.(true);
            customNotification("success", "Settings are updated successfully");
          } catch (error) {
            handleError(error as string | object);
          }
        }}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form className="assessmentType__form">
              {/* LANGUAGE SELECTION */}
              <Space className="assessmentType__form__language">
                <CustomFastField
                  subLabel="Assessment Name"
                  name="name"
                  placeholder="Assessment Name"
                />
              </Space>
              {values?.assessmentSettingId?.setColorVisible && (
                <div className={styles["checkInIconRow"]}>
                  <EmojiPicker name="icon" label="Icon" />
                  <ViwellColorPicker
                    label="Color"
                    name="color"
                    defaultColor={"#284eb2"}
                  />
                </div>
              )}
              <CustomDivider />

              <Space className="assessmentType__form__language">
                <AssessmentSettingsCommon
                  values={values}
                  setFieldValue={setFieldValue}
                />
              </Space>

              <ModalFooter
                className="assessmentType__form__footer"
                saveText="Save Settings"
                loading={isLoadingAssessmentSettings || isLoading}
                onCancel={handleCancel}
              />
            </Form>
          );
        }}
      </Formik>
    </CustomModal>
  );
};

export default AssessmentSettings;
