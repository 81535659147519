import { Card, Col, Row, Space, Tag, Typography } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import CustomButton from "components/custom-button/CustomButton";
import CustomDropDown from "components/custom-dropdown/CustomDropDown";
import CustomMenu from "components/custom-menu/CustomMenu";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import {
  useLazyGetContentListQuery,
  useGetContentListQuery,
  ContentType,
  useGetCategoriesTreeQuery,
} from "graphql/_generated/graphql";
import moment from "moment";
import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import CustomCardList from "components/custom-card-list/CustomCardList";
import styles from "./ContentCardList.module.scss";
import { Formik } from "formik";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import CustomTreeSelect from "components/form/custom-tree-select/CustomTreeSelect";
import { Form } from "formik-antd";
import { contentTypes } from "helpers";
import { PERMS } from "helpers";
import { useTranslation } from "react-i18next";
import { CONFIG } from "helpers/constants";
import { useErrorHandler } from "hooks/useErrorHandler";

type Props = {};
const { Text } = Typography;
const baseImgUrl =
  process.env.REACT_APP_BASE_IMAGE_URL || CONFIG.BASE_IMAGE_URL;

export default function ContentCardList({}: Props) {
  const navigate = useNavigate();
  const [filtersData, setFiltersData] = useState<any>(null);
  const [columnsData, setColumnsData] = useState<any>();
  const [getAllContentDataFun] = useLazyGetContentListQuery();
  const { isFetching, refetch } = useGetContentListQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const { handleError } = useErrorHandler();
  const { t } = useTranslation();

  useEffect(() => {
    fetchDataFromApi(getAllContentDataFun, setColumnsData, filtersData).catch(
      (e) => handleError(e),
    );
  }, [isFetching]);

  const menuItems = [
    {
      label: "Edit Content",
      key: "edit",
    },
    {
      label: "View Detail",
      key: "view-detail",
    },
  ];

  const handleMenu = ({ domEvent, key, keyPath, item }: any) => {
    const editId: string = item.id;
    if (key === "edit") {
      navigate("/add-content", {
        state: editId,
      });
    } else if (key === "view-detail") {
      navigate("/content-detail", {
        state: item.id,
      });
    }
  };

  const contentDataTypes = useMemo(() => {
    return contentTypes();
  }, []);

  const { data: categoriesData } = useGetCategoriesTreeQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <CustomCardList
      filters={{
        handleCallback: async (filtersData: any) => {
          fetchDataFromApi(getAllContentDataFun, setColumnsData, filtersData);
        },
        date: [
          {
            applyAs: "and",
            key: "createdAt",
            comparison: "Range",
          },
        ],
        status: {
          applyAs: "and",
          key: "isActive",
          comparison: "Equal",
        },
        manualFilters: [
          {
            key: "contentType",
            comparison: "Equal",
            applyAs: "and",
            render: (onChangeCallback, value) => {
              return (
                <Formik initialValues={{ company: null }} onSubmit={() => {}}>
                  {({ setFieldValue, values }) => (
                    <Form>
                      <CustomSelectField
                        // label="Select Content Type"
                        // noMargin
                        marginBtm={false}
                        className="filter-select"
                        name="contentType"
                        placeholder="Select Content Type"
                        options={contentDataTypes}
                        onChange={onChangeCallback}
                        value={value}
                        allowClear
                      />
                    </Form>
                  )}
                </Formik>
              );
            },
          },
          {
            key: "category.id",
            comparison: "Equal",
            applyAs: "and",
            render: (onChangeCallback, value) => {
              return (
                <Formik initialValues={{ company: null }} onSubmit={() => {}}>
                  {({ setFieldValue, values }) => (
                    <Form>
                      <CustomTreeSelect
                        noMargin
                        allowClear
                        showArrow
                        treeNodeFilterProp="name"
                        treeLine={true && { showLeafIcon: false }}
                        // value={values.categoriesIds}
                        // label="Select Category"
                        fieldNames={{
                          label: "name",
                          value: "id",
                        }}
                        className="filter-select"
                        name="categoriesIds"
                        placeholder="Select Category "
                        treeData={categoriesData?.getCategoriesTree}
                        onChange={onChangeCallback}
                        value={value}
                      />
                    </Form>
                  )}
                </Formik>
              );
            },
          },
        ],
      }}
      renderCard={(item: any) => (
        <Card className={styles["card-rounded"]}>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Row justify="space-between">
              <Col>
                {item?.isActive ? (
                  <Tag className="active-tag">Active</Tag>
                ) : (
                  <Tag className="de-active-tag">Deactivated</Tag>
                )}
              </Col>
              <Col>
                <>
                  <CustomDropDown
                    overlay={
                      <CustomMenu
                        items={menuItems}
                        onClick={({ domEvent, key, keyPath }) =>
                          handleMenu({ domEvent, key, keyPath, item })
                        }
                      />
                    }
                  >
                    <CustomButton type="link" className="link-btn">
                      <MoreOutlined />
                    </CustomButton>
                  </CustomDropDown>
                </>
              </Col>
            </Row>
            <div
              className={styles["card-image"]}
              style={{
                backgroundImage: `url(${baseImgUrl}${item?.thumbnailImage})`,
              }}
            ></div>
            <Text strong>{item?.title}</Text>
            <div className={styles["card-description"]}>
              <Space
                className={styles["card-text"]}
                size="middle"
                split={<DividerDot />}
              >
                <Text>{item?.category?.name}</Text>
                {/* <Text>{moment(item?.createdAt).format("MMMM Do YYYY")}</Text> */}
              </Space>
              <Space
                className={styles["card-footer"]}
                size="middle"
                split={<DividerDot />}
              >
                <Text>{moment(item?.createdAt).format("MMMM Do YYYY")}</Text>
                <Text>Views: {item?.views}</Text>
                <Text>{item?.contentType}</Text>
              </Space>
            </div>
          </Space>
        </Card>
      )}
      totalItems={columnsData?.metadata?.total}
      loading={isLoading}
      permission={
        PERMS.ADD_NEW_VIDEO_CONTENT ||
        PERMS.ADD_NEW_AUDIO_CONTENT ||
        PERMS.ADD_NEW_TEXT_CONTENT ||
        PERMS.ADD_NEW_RECIPE_CONTENT ||
        PERMS.ADD_NEW_FILE_CONTENT
      }
      onClick={() => navigate("/add-content", { replace: true })}
      btnText={t("content.add-content")}
      data={columnsData?.data}
    />
  );
}

export const DividerDot: React.FC = () => (
  <span className={styles["divider-dot"]}></span>
);
