export const UnitSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.16667 4.66667H5.34833V2.16667H2.16667V4.66667ZM2.16667 8.83333H5.34833V6.33333H2.16667V8.83333ZM5.34833 12.2425C5.34833 13.12 4.635 13.8333 3.7575 13.8333C2.88 13.8333 2.16667 13.12 2.16667 12.2425V10.5H5.34833V12.2425ZM7.015 1.33333C7.015 0.8725 6.6425 0.5 6.18167 0.5H1.33333C0.8725 0.5 0.5 0.8725 0.5 1.33333V12.2425C0.5 14.0383 1.96167 15.5 3.7575 15.5C5.55333 15.5 7.015 14.0383 7.015 12.2425V1.33333ZM14.6667 8.98467H12.4208L10.8667 10.6513L7.90083 13.833L6.72167 15.098L6.3475 15.4997H14.6667C15.1275 15.4997 15.5 15.1272 15.5 14.6663V9.818C15.5 9.35717 15.1275 8.98467 14.6667 8.98467ZM9.71125 2.5365L13.1579 5.74983C13.4946 6.064 13.5138 6.5915 13.1996 6.92733L7.80042 12.7182C7.80528 12.6782 7.81079 12.6383 7.8163 12.5985C7.83251 12.4812 7.84875 12.3636 7.84875 12.2423V10.2232V5.7565V3.31233L8.53375 2.57733C8.68458 2.41567 8.89375 2.32067 9.11375 2.31317C9.34208 2.29483 9.55042 2.38567 9.71125 2.5365Z"
        fill="#303030"
      />
    </svg>
  );
};
