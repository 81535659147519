import { useGetSpecialistConfigQuery } from "graphql/_generated/graphql";

export const useSpecialistConfigList = () => {
  const { data, refetch } = useGetSpecialistConfigQuery();

  const getSpecialistConfigData = data?.getSpecialistConfig.options.map(
    (item) => {
      const { firstName, lastName, image, specialty } = item.extra;
      return {
        label: firstName + " " + lastName + " " + specialty,
        value: item.id,
        id: item.id,
        image,
        extra: {
          ...item.extra,
          id: item.id,
        },
      };
    }
  );
  return {
    getSpecialistConfigData,
    refetch,
  };
};
