import { DeleteFilled, PlusCircleOutlined } from "@ant-design/icons";
import { Avatar, Col, Row, Select, Space } from "antd";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { CustomUploadFile } from "components/form/custom-upload-file/CustomUploadFile";

import CustomButton from "components/custom-button/CustomButton";
import { FieldArray, FormikValues } from "formik";
import {
  ContentType,
  MutationDeleteSubtitleArgs,
  useGetContentConfigQuery,
} from "graphql/_generated/graphql";
import styles from "features/content/css/AddContent.module.scss";
import { useSpecialistConfigList } from "hooks/useSpecialistConfig";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const RecipeContent = (props: {
  editId: string;
  value: string | null;
  values: FormikValues;
  languages: { label: string; value: string }[];
  handleDeleteSubTitle: (values: MutationDeleteSubtitleArgs) => Promise<void>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
}) => {
  const { t } = useTranslation();
  const { data: contentData, refetch: contentRefetch } =
    useGetContentConfigQuery(
      { type: ContentType.Recipe },
      {
        refetchOnMountOrArgChange: true,
      },
    );
  const { values, setFieldValue, editId } = props;
  const { getSpecialistConfigData } = useSpecialistConfigList();

  return (
    <>
      {/* this block of code only shows when content type is recipes */}
      {values.contentType === "Recipe" && (
        <>
          <Row gutter={[24, 0]}>
            <Col sm={24} md={12}>
              <div className={styles["form-control"]}>
                <CustomSelectField
                  label={t("select-specialist")}
                  name="creatorId"
                  className="form-select"
                  placeholder={t("select-specialist")}
                >
                  {getSpecialistConfigData?.map((item: any, index: any) => (
                    <Option value={item.value} key={index}>
                      <Space>
                        <CustomAvatar imgSrc={item.image} />
                        {item.label}
                      </Space>
                    </Option>
                  ))}
                </CustomSelectField>
              </div>
            </Col>
          </Row>
          <div className={styles["form-control"]}>
            <div className="label">{t("add-recipes-images")}</div>
            <FieldArray
              name="gallery"
              render={(arrayHelpers) => (
                <div>
                  {values.gallery.length > 0 &&
                    values.gallery?.map((url: string, index: any) => (
                      <Space>
                        <Space direction="vertical" align="center">
                          <CustomUploadFile
                            name={`gallery[${index}].image`}
                            setFieldValue={setFieldValue}
                            imgUrl={values.gallery[index].image}
                            accept="image/*"
                          />
                          <div className={styles["remove-btn"]}>
                            <CustomButton
                              type="link"
                              className="remove-btn"
                              onClick={() => arrayHelpers.remove(index)} // remove an image from the list
                            >
                              <DeleteFilled />
                            </CustomButton>
                          </div>
                        </Space>
                      </Space>
                    ))}

                  <CustomButton
                    className="remove-btn"
                    onClick={() =>
                      arrayHelpers.push({
                        image: "",
                      })
                    }
                  >
                    <PlusCircleOutlined />
                  </CustomButton>
                </div>
              )}
            />
            <CustomSelectField
              label={t("related-recipes")}
              name="relatedRecipes"
              options={contentData?.getContentConfig.options.filter(
                (item: any) => item.id !== editId,
              )}
              fieldNames={{ label: "label", value: "id" }}
              mode="multiple"
              placeholder={t("related-recipes")}
            />
            <CustomTextField
              label={t("time")}
              name="timeRequired"
              type="number"
              placeholder={t("enter-time")}
            />
            <CustomTextField
              label={t("serving-persons")}
              name="serving"
              type="number"
              placeholder={t("how-many-persons")}
            />
          </div>
        </>
      )}
    </>
  );
};

export default RecipeContent;
