import { Dropdown, Menu, Space, Switch, Tag } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import { MoreOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/lib/table";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import CustomButton from "components/custom-button/CustomButton";
import { customNotification } from "components/custom-notification/CustomNotification";
import CustomTable from "components/custom-table/CustomTable";
import { errorFormat, getTranslatedValue, PERMS } from "helpers";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import {
  useGetAvatarListQuery,
  useLazyGetAvatarListQuery,
  AvatarList as AvatarListType,
} from "graphql/_generated/graphql";
import moment from "moment";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AddAvatar from "features/avatars/add-avatar/AddAvatar";
import { useAvatar } from "features/avatars/useAvatar";
import styles from "features/avatars/avatar-list/AvatarList.module.scss";
import CustomPopconfirm from "components/form/custom-pop-confirm/CustomPopConfirm";
import CustomMenu from "components/custom-menu/CustomMenu";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import { useErrorHandler } from "hooks/useErrorHandler";

type Props = {};

const AvatarList = (props: Props) => {
  //Initializing states
  const { t } = useTranslation();
  const [filtersData, setFiltersData] = useState<any>(null);
  const [columnsData, setColumnsData] = useState<AvatarListType>();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState<boolean>(false);
  const [editId, setEditId] = useState<string | null>(null);

  //Hooks
  const { isFetching, refetch } = useGetAvatarListQuery();
  const [getAllAvatarsDataFun] = useLazyGetAvatarListQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const { handleActivationDeactivationAvatar } = useAvatar();
  const { handleError } = useErrorHandler();

  const handleMenu = ({ key, id }: any) => {
    if (key === "edit-avatar") {
      handleEdit(id);
    }
  };

  const handleEdit = (id: string) => {
    setVisible(true);
    setEditId(id);
  };

  useEffect(() => {
    fetchDataFromApi(getAllAvatarsDataFun, setColumnsData, filtersData).catch(
      (e) => handleError(e),
    );
  }, [isFetching]);

  const columns: ColumnsType<AvatarListType["data"][0]> = [
    {
      title: t("id"),
      key: "id",
      sorter: {
        multiple: 1,
      },
      render: (_text, _record, index) =>
        (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, index) => (
        <Space>
          <CustomAvatar imgSrc={record.imgPath} />
          <div className="cut-text">{getTranslatedValue(record, "name")}</div>
        </Space>
      ),
    },
    {
      title: t("gender"),
      dataIndex: "gender",
      key: "gender",
      sorter: {
        multiple: 1,
      },
    },
    {
      title: t("created-at"),
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: {
        multiple: 1,
      },
      render: (createdAt: string) =>
        moment(createdAt).format("MMMM Do YYYY, h:mm:ss a"),
    },
    {
      title: t("updated-at"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: {
        multiple: 1,
      },
      render: (updatedAt: string) =>
        moment(updatedAt).format("MMMM Do YYYY, h:mm:ss a"),
    },
    {
      title: t("status"),
      dataIndex: "isActive",
      key: "isActive",
      align: "center" as "center",
      render: (isActive: boolean) =>
        isActive ? (
          <Tag className="active-tag">{t("active")}</Tag>
        ) : (
          <Tag className="de-active-tag">{t("deactivated")}</Tag>
        ),
    },
    {
      align: "right" as "right",
      title: t("actions"),
      dataIndex: "id",
      key: "id",
      render: (id: string, { isActive }) => (
        <CheckPermissions permission={PERMS.TOGGLE_AVATAR_ACTIVATION}>
          <CustomPopconfirm
            onConfirm={async () => {
              try {
                const isActivation = await handleActivationDeactivationAvatar(
                  id,
                );
                customNotification(
                  "success",
                  isActivation
                    ? t("avatar-activation-success")
                    : t("avatar-deactivation-success"),
                );
              } catch (error) {
                handleError(error as string | object);
              }
            }}
            title={
              isActive
                ? t("confirm-deactivate-avatar")
                : t("confirm-activate-avatar")
            }
          >
            <Switch
              className="custom-switch"
              defaultChecked
              checked={isActive}
            />
          </CustomPopconfirm>
        </CheckPermissions>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      align: "center" as "center",
      render: (id: string) => (
        <Dropdown
          overlay={
            <CustomMenu
              items={[{ label: t("edit-avatar"), key: "edit-avatar" }]}
              onClick={({ domEvent, key, keyPath }) =>
                handleMenu({ domEvent, key, keyPath, id })
              }
            />
          }
        >
          <CustomButton type="link" className="link-btn">
            <MoreOutlined />
          </CustomButton>
        </Dropdown>
      ),
    },
  ];

  return (
    <DashboardLayout openKey={["MobileSettings"]}>
      <Breadcrumbs
        data={[
          {
            label: "Mobile Settings",
          },
          {
            label: "Avatars",
          },
        ]}
      />
      <CustomTable
        filters={{
          handleCallback: async (filtersData: any) => {
            await fetchDataFromApi(
              getAllAvatarsDataFun,
              setColumnsData,
              filtersData,
            );
            setPageSize(filtersData?.pagination?.offset?.pageSize);
            setCurrentPage(filtersData?.pagination?.offset?.page);
            setFiltersData(filtersData);
          },
          date: [
            {
              applyAs: "and",
              key: "createdAt",
              comparison: "Range",
            },
          ],
          status: {
            applyAs: "and",
            key: "isActive",
            comparison: "Equal",
          },
        }}
        rowKey="id"
        totalItems={columnsData?.metadata.total}
        loading={isLoading}
        columns={columns}
        permission={PERMS.ADD_NEW_AVATAR}
        onClick={() => setVisible(true)}
        headerTitle={t("avatars")}
        btnText={t("avatar.add-avatar")}
        dataSource={columnsData?.data}
      />
      <AddAvatar
        visible={visible}
        setVisible={setVisible}
        editId={editId}
        setEditId={setEditId}
      />
    </DashboardLayout>
  );
};

export default AvatarList;
