import {
  UpdateTeamParticipantsScoreMutationVariables,
  useUpdateTeamParticipantsScoreMutation,
} from "graphql/_generated/graphql";

export const useUpdateTeamScore = () => {
  const [updateTeamScoreFun, { isLoading: isLoadingUpdateTeamScore }] =
    useUpdateTeamParticipantsScoreMutation();

  const handleUpdateTeamScore = async (
    values: UpdateTeamParticipantsScoreMutationVariables,
  ) => {
    try {
      const res = await updateTeamScoreFun(values).unwrap();
      return res.updateTeamParticipantsScore;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleUpdateTeamScore,
    isLoadingUpdateTeamScore,
  };
};
