import {
  useCreateAssessmentMutation,
  MutationCreateAssessmentArgs,
} from "graphql/_generated/graphql";

export const useCreateAssessment = () => {
  const [assessmentCreateFun, { isLoading }] = useCreateAssessmentMutation();
  const handleCreateAssessment = async (
    values: MutationCreateAssessmentArgs,
  ) => {
    try {
      const res = await assessmentCreateFun({ create: values.create }).unwrap();
      return res.createAssessment.id;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleCreateAssessment,
    isLoadingCreateAssessment: isLoading,
  };
};
