export const ExpertSvg = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.33333 7.16667C8.17167 7.16667 9.66667 5.67167 9.66667 3.83333C9.66667 1.995 8.17167 0.5 6.33333 0.5C4.495 0.5 3 1.995 3 3.83333C3 5.67167 4.495 7.16667 6.33333 7.16667ZM16.0469 1.538C15.6994 1.23633 15.1736 1.27217 14.8711 1.61967L13.3161 3.40717L12.7877 2.81633C12.4819 2.473 11.9552 2.44383 11.6111 2.75133C11.2686 3.058 11.2386 3.5855 11.5461 3.928L12.7036 5.22217C12.8619 5.39883 13.0877 5.49967 13.3244 5.49967H13.3302C13.5694 5.498 13.7969 5.39383 13.9536 5.21383L16.1294 2.71383C16.4311 2.36633 16.3944 1.83967 16.0469 1.538ZM12.1667 14.6667C12.1667 15.1267 11.7942 15.5 11.3333 15.5H1.33333C0.8725 15.5 0.5 15.1267 0.5 14.6667C0.5 11.45 3.1175 8.83333 6.33333 8.83333C9.54917 8.83333 12.1667 11.45 12.1667 14.6667Z"
        fill="#303030"
      />
    </svg>
  );
};
