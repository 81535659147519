import { convertTranslationArrToObj } from "helpers";

import {
  MutationAddNewAvatarArgs,
  useAddNewAvatarMutation,
  useGetAvatarListQuery,
} from "graphql/_generated/graphql";

export const useAddAvatar = () => {
  const [addAvatarFun, { isLoading }] = useAddNewAvatarMutation();
  const { refetch } = useGetAvatarListQuery();

  //this function is responsible for adding new avatar
  const handleAddAvatar = async (values: MutationAddNewAvatarArgs) => {
    try {
      let translations = await convertTranslationArrToObj(
        values.create.translations
      );
      let params = { create: { ...values.create, translations } };
      await addAvatarFun(params).unwrap();
      refetch();
    } catch (error: any) {
      throw error;
    }
  };

  return { isLoading, handleAddAvatar };
};
