import { FormikValues } from "formik";
import { MutationDeleteSubtitleArgs } from "graphql/_generated/graphql";
import AudioContent from "./AudioContent";
import DynamicContent from "./DynamicContent";
import FileContent from "./FileContent";
import RecipeContent from "./RecipeContent";
import TextContent from "./TextContent";
import VideoContent from "./VideoContent";

const ContentUpload = (props: {
  editId: string;
  value: string | null;
  values: FormikValues;
  languages: { label: string; value: string }[];
  handleDeleteSubTitle: (values: MutationDeleteSubtitleArgs) => Promise<void>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}) => {
  return (
    <>
      <VideoContent {...props} />
      <FileContent {...props} />
      <TextContent {...props} />
      <AudioContent {...props} />
      <DynamicContent {...props} />
      <RecipeContent {...props} />
    </>
  );
};

export default ContentUpload;
