import { Space } from "antd";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";

import { useLocation, useNavigate } from "react-router-dom";
import styles from "features/challenge/css/ActivityDetail.module.scss";

import { useChallenge } from "features/challenge/hooks/useChallenge";
import { useEffect, useState } from "react";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import CustomButton from "components/custom-button/CustomButton";
import Details from "features/challenge/challenge-detail";
import { Language } from "graphql/_generated/graphql";
import ParticipationDetails from "features/challenge/challenge-detail/ParticipationDetails";
import moment from "moment";
import customNotification from "components/custom-notification/CustomNotification";
import { errorFormat } from "helpers";
import { EditOutlined } from "@ant-design/icons";
import { useErrorHandler } from "hooks/useErrorHandler";

interface ActivityDetailProps {}

const ChallengeDetail: React.FunctionComponent<ActivityDetailProps> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { handleError } = useErrorHandler();
  const { id, language } = location.state;
  const [challengeData, setChallengeData] = useState<any>(null);

  const { handleFetchChallengeData } = useChallenge();
  const challengeStartDate = moment(challengeData?.startDate);

  const todayDate = moment();

  const isPlanned = challengeStartDate > todayDate;

  //this function is getting the data from api and show in the form
  const showChallengeDetails = async () => {
    if (id) {
      handleFetchChallengeData(id).then(async (data: any) => {
        try {
          setChallengeData(data);
        } catch (error) {
          handleError(error as string | object);
        }
      });
    }
  };

  useEffect(() => {
    //this function will fetch the prev data and show in the form if edit id exists
    showChallengeDetails();
  }, []);

  return (
    <DashboardLayout openKey={["Activity"]}>
      <Breadcrumbs
        data={[
          {
            label: "Activities",
          },
          {
            label: "Challenges",
          },
          {
            label: `Challenge Details - ${language}`,
          },
        ]}
      />
      <div className={styles["activity"]}>
        <h6 className="header__row--xtitle">{`Challenge Details - ${language}`}</h6>

        <Space>
          <CustomButton
            className="assessment-create-header-cancel link-text-btn"
            onClick={() => navigate("/challenges")}
          >
            Close
          </CustomButton>

          <CustomButton
            className="primary-btn"
            icon={isPlanned ? <EditOutlined /> : null}
            onClick={() => {
              navigate("/add-challenge", {
                state: {
                  editId: id,
                  language,
                  isViewOnly: !isPlanned,
                },
              });
            }}
          >
            <span>{`${isPlanned ? "Edit Challenge" : "View Details"}`}</span>
          </CustomButton>
        </Space>
      </div>
      <Details challengeData={challengeData} />
      {challengeData?.id ? (
        <ParticipationDetails challengeId={challengeData?.id} />
      ) : null}
    </DashboardLayout>
  );
};

export default ChallengeDetail;
