import CheckPermissions from "components/check-permissions/CheckPermissions";
import CustomButton from "components/custom-button/CustomButton";
import { PERMS } from "helpers";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AddDepartment from "features/department/add-department/AddDepartment";
import DepartmentList from "features/department/department-list/DepartmentList";
import styles from "features/department/departments/Departments.module.scss";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
type Props = {};

const Departments = (props: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <DashboardLayout openKey={["Configs"]}>
        <Breadcrumbs
          data={[
            {
              label: "Configurations",
            },
            {
              label: t("departments-title"),
            },
          ]}
        />
        <DepartmentList />
      </DashboardLayout>
    </>
  );
};

export default Departments;
