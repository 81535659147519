import { ReactEventHandler, useState } from "react";
import { Space } from "antd";
import { Switch, SwitchProps } from "formik-antd";
import styles from "./CustomSwitch.module.scss";

interface CustomSwitchProps extends SwitchProps {
  label?: string;
  subLabel?: string;
  className?: string;
  isActive?: boolean;
  name: string;
  handleChange?: any;
  showSwitchText?: boolean;
  switchPosition?: "start" | "end";
  isDisabled?: boolean;
}

export const CustomSwitch = (props: CustomSwitchProps) => {
  const {
    name,
    label,
    className,
    isActive = false,
    subLabel,
    handleChange,
    ...restProps
  } = props;

  return (
    <Space
      className={`
        ${styles["customSwitch"]} ${
        props.switchPosition === "start" ? styles["reverse-order"] : ""
      }`}
    >
      <label className={styles["customSwitch--label"]}>{label}</label>
      <div className={styles["customSwitch--switch"]}>
        <Space>
          {props?.showSwitchText && (
            <span className="custom-switch--label">
              {isActive ? "On" : "Off"}
            </span>
          )}
          <Switch
            name={name}
            className="custom-switch"
            checked={isActive}
            onChange={(checked: boolean, event) => {
              event.stopPropagation();
              if (props?.handleChange) props?.handleChange(!isActive);
            }}
            disabled={props?.isDisabled}
            {...restProps}
          />
        </Space>
      </div>
    </Space>
  );
};
