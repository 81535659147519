import { useGetIndividualLeaderboard } from "./useGetIndividualLeaderboard";
import { useUpdateIndividualScore } from "./useUpdateIdividualScore";

export const useIndividualLeaderboard = () => {
  const { handleGetIndividualLeaderboard, isLoadingIndividualLeaderboard } =
    useGetIndividualLeaderboard();

  const { handleUpdateIndividualScore, isLoadingUpdateIndividualScore } =
    useUpdateIndividualScore();

  return {
    handleGetIndividualLeaderboard,
    handleUpdateIndividualScore,
    isLoading: isLoadingIndividualLeaderboard || isLoadingUpdateIndividualScore,
  };
};
