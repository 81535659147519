/**
 * Judging criteria for a question's place in an assessment.
 * A 'hierarchy' field, with this Enum values, is placed under a question.
 */
enum EnumBoolean {
  YES = "YES",
  NO = "NO",
}

export default EnumBoolean;
