import { RewardPartnerStockTypes } from "graphql/_generated/graphql";
import * as Validations from "validations/schema/common/validations";
import * as Yup from "yup";

export const addPartnerRewardSchema = Yup.object().shape({
  name: Validations.requiredFieldWithoutSpaces("name"),
  startDate: Yup.mixed().when("isDateEnable", {
    is: true,
    then: Validations.required("start-date"),
  }),
  endDate: Yup.mixed().when("isDateEnable", {
    is: true,
    then: Validations.required("end-date"),
  }),
  price: Validations.requiredFieldWithoutSpaces("price"),
  redemptionLimits: Yup.mixed().when("isRedemptionLimitsEnable", {
    is: true,
    then: Validations.required("redemption limits"),
  }),
  stockType: Yup.mixed().when("isCreateStockEnable", {
    is: true,
    then: Validations.required("stock type"),
  }),
  stockLimit: Yup.mixed().when(["isCreateStockEnable", "stockType"], {
    is: (stockEnable: boolean, stockType: string) =>
      stockEnable && stockType === RewardPartnerStockTypes.Cumulative,
    then: Validations.required("stock limit"),
  }),
  availableRewardStocks: Yup.mixed().when(
    ["isCreateStockEnable", "stockType"],
    {
      is: (stockEnable: boolean, stockType: string) =>
        stockEnable && stockType === RewardPartnerStockTypes.Individual,
      then: Yup.array().min(1).required(),
    },
  ),
});

export const ImportBulkRewardsSchema = Yup.object().shape({
  rewards: Yup.array().of(
    Yup.object().shape({
      ...addPartnerRewardSchema.fields,
      isDateEnable: Validations.requiredBooleanValidation("Enable Date"),
      isRedemptionLimitsEnable: Validations.requiredBooleanValidation(
        "Enable Redemption Limit",
      ),
      isCreateStockEnable: Validations.requiredBooleanValidation(
        "Enable Create Stock",
      ),
    }),
  ),
});
