import { customNotification } from "components/custom-notification/CustomNotification";
import { FormikHelpers } from "formik";
import {
  Client,
  ClientDetails,
  ClientUpdate,
  ParentClientDetails,
  useGetClientsTreeQuery,
} from "graphql/_generated/graphql";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useEditCompany } from "./useEditCompany";

export type ExtendedClient = ClientUpdate & {
  isProfileComplete?: boolean;
  numberOfEmployee?: number;
  isEapEnabled?: boolean;
  subscribers?: number;
  departments?: [];
  children?: Client[];
  hasChildren?: boolean;
  parent?: ParentClientDetails;
  buildings?: [];
  positions?: [];
  adminEmail?: string;
  createdAt?: string;
  id?: string;
};

export const useEditCompanyViewModel = () => {
  const { handleError } = useErrorHandler();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { handleFetchEditClientData, handleUpdateClient, isLoading } =
    useEditCompany();
  const [id, setId] = useState();
  useEffect(() => {
    setId(location.state);
  }, []);

  useEffect(() => {
    if (!id) return;

    fetchClient();
  }, [id]);

  const fetchClient = async () => {
    const data = (await handleFetchEditClientData(id!))
      .getClientDetail as ClientDetails;
    const { createdAt, accessCode, isAccessCodeEnabled } = data;
    setInitialValues({
      ...data,
      createdAt: createdAt?.split("T")[0],
      accessCode: accessCode ?? "",
      isAccessCodeEnabled: isAccessCodeEnabled || false,
    });
  };

  const [initialValues, setInitialValues] = useState<ClientDetails>({
    id: "",
    image: "",
    subscribers: 0,
    subscriptions: 0,
    companyName: "",
    companySize: "",
    avgAgeOfEmployee: "",
    goal: "",
    // buildings: [],
    isProfileComplete: false,
    isActive: false,
    isEapEnabled: false,
    // numberOfEmployee: 0,
    // departments: [],
    // positions: [],
    children: [],
    parent: {} as ParentClientDetails,
    // hasChildren: false,
    adminEmail: "",
    totalActiveEmployees: 0,
    employees: 0,
    createdAt: "",
    adminId: "",
    updatedAt: "",
    isAccessCodeEnabled: false,
    accessCode: undefined,
  });

  const [visible, setInviteVisible] = useState(false);
  const { data: companiesData, refetch } = useGetClientsTreeQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const onSubmit = async (
    values: ClientDetails,
    { resetForm }: FormikHelpers<ClientDetails>,
  ) => {
    try {
      await handleUpdateClient({
        values,
        id,
      });
      customNotification("success", t("company-updated-successfully"));
      resetForm();
      refetch();
      navigate("/company");
    } catch (error) {
      handleError(error as string | object);
    }
  };

  return {
    initialValues,
    onSubmit,
    id,
    isLoading,
    setInviteVisible,
    visible,
  };
};
