import {
  UpdateIndividualParticipantsScoreMutationVariables,
  useUpdateIndividualParticipantsScoreMutation,
} from "graphql/_generated/graphql";

export const useUpdateIndividualScore = () => {
  const [
    updateIndividualScoreFun,
    { isLoading: isLoadingUpdateIndividualScore },
  ] = useUpdateIndividualParticipantsScoreMutation();

  const handleUpdateIndividualScore = async (
    values: UpdateIndividualParticipantsScoreMutationVariables,
  ) => {
    try {
      const res = await updateIndividualScoreFun({
        update: values.update,
      }).unwrap();
      return res.updateIndividualParticipantsScore;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleUpdateIndividualScore,
    isLoadingUpdateIndividualScore,
  };
};
