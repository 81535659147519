import { useMemo } from "react";
import { RewardVisibility } from "graphql/_generated/graphql";

export const useGetRewardVisibility = () => {
  const rewardVisibility = useMemo(() => {
    return Object.keys(RewardVisibility).map((item) => ({
      label: item,
      value: item,
    }));
  }, []);

  return {
    rewardVisibility,
  };
};
