export const ContentSvg = () => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.16699 0.5H14.8337C16.212 0.5 17.3337 1.62167 17.3337 3V7.16667H0.666992V3C0.666992 1.62167 1.78866 0.5 3.16699 0.5ZM3.16699 12.1667C1.78866 12.1667 0.666992 11.045 0.666992 9.66667V8.83333H17.3337V9.66667C17.3337 11.045 16.212 12.1667 14.8337 12.1667H9.83366V13.8333H13.167C13.6253 13.8333 14.0003 14.2083 14.0003 14.6667C14.0003 15.125 13.6253 15.5 13.167 15.5H4.83366C4.37533 15.5 4.00033 15.125 4.00033 14.6667C4.00033 14.2083 4.37533 13.8333 4.83366 13.8333H8.16699V12.1667H3.16699Z"
        fill="#303030"
      />
    </svg>
  );
};
