import { Space } from "antd";
import AddOption from "components/custom-button/AddOption";
import DeleteBtn from "components/custom-button/DeleteBtn";
import EmojiPicker from "components/form/custom-emoji-picker/EmojiPicker";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { mappedOptions } from "features/assessment/transformers/AssessmentTransformer";
import { FieldArray } from "formik";
import {
  OptionTypes,
  useLazyGetTagListQuery,
} from "graphql/_generated/graphql";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./CheckinOptions.module.scss";
interface CheckinOptionsProps {
  values: any;
  disabled: boolean;
  isAddLanguage?: boolean;
  oldRecord?: any;
  assessmentSettings?: any;
  setFieldValue: (key: string, value: string[]) => void;
}

const option = {
  score: "",
  text: undefined,
  subText: undefined,
  emoji: undefined,
  tags: [],
};

const CheckinOptions: React.FunctionComponent<CheckinOptionsProps> = ({
  values,
  disabled,
  isAddLanguage,
  oldRecord,
  assessmentSettings,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  // const { data: tagsData, isLoading: tagsLoading } = useGetTagListQuery();
  const [tagsData, setTagsData] = useState<any>();
  // const { data: tagsData, isLoading: tagsLoading }
  const [getTagList, { isLoading: tagsLoading }] = useLazyGetTagListQuery();
  let responseTagList = async () => {
    let incomingTagList = await getTagList({
      pagination: {
        limit: 1000,
      },
    }).unwrap();
    return setTagsData(incomingTagList);
  };

  useEffect(() => {
    responseTagList();
  }, []);

  return (
    <>
      <FieldArray
        name="options"
        render={({ push, remove }) => (
          <div>
            {values.options && values.options.length > 0
              ? values?.options
                  ?.sort(
                    (a: any, b: any) => a?.optionSortOrder - b?.optionSortOrder,
                  )
                  ?.map((option: any, index: number) => (
                    <div key={index}>
                      <div className={styles["option-header"]}>
                        <div className={styles["emoji-flex"]}>
                          <EmojiPicker
                            isDisabled={disabled || isAddLanguage}
                            name={`options[${index}].emoji`}
                          />

                          <CustomTextField
                            disabled={disabled}
                            name={`options[${index}].text`}
                            placeholder={t("option-text-placeholder")}
                            isAddLanguage={isAddLanguage}
                            oldTextValue={oldRecord?.options[index]?.text}
                          />
                        </div>
                        <div
                          className={
                            assessmentSettings?.isTagsEnable ||
                            assessmentSettings?.isScoresEnable
                              ? styles["score-flex"]
                              : styles["option-filter"]
                          }
                        >
                          {assessmentSettings?.isScoresEnable ? (
                            <CustomTextField
                              type="number"
                              name={`options[${index}].score`}
                              placeholder={t("option-score-placeholder")}
                              disabled={disabled || isAddLanguage}
                            />
                          ) : null}

                          {assessmentSettings?.isTagsEnable ? (
                            <CustomSelectField
                              maxTagCount="responsive"
                              name={`options[${index}].tags`}
                              mode="multiple"
                              options={mappedOptions(
                                tagsData?.getTagList.data.map((item: any) => ({
                                  value: item?.id,
                                  label: item?.title,
                                  questionId: values?.id,
                                })),
                                values?.options[index]?.tags,
                              )}
                              disabled={
                                disabled || tagsLoading || isAddLanguage
                              }
                              placeholder={t("option-tags-placeholder")}
                            />
                          ) : null}
                          <DeleteBtn
                            disabled={disabled || isAddLanguage}
                            onClick={() => {
                              values.options[index].id &&
                                setFieldValue("deleteOptionId", [
                                  ...(values?.deleteOptionId?.length
                                    ? values.deleteOptionId
                                    : []),
                                  values.options[index].id,
                                ]);
                              remove(index);
                            }}
                          />
                        </div>

                        <div className={styles["title-flex"]}>
                          <div>{t("sub-question-titles-label")}</div>
                          <CustomTextField
                            disabled={disabled}
                            label={t("sub-question-title-label")}
                            name={`options[${index}].subText`}
                            placeholder={t("sub-question-title-placeholder")}
                            isAddLanguage={isAddLanguage}
                            oldTextValue={oldRecord?.options[index]?.text}
                          />
                        </div>
                        {/* <div>
                    <CustomTextField
                      disabled={disabled}
                      label="Subtitle"
                      name={`options[${index}].subText`}
                      placeholder="Subtitle"
                      isAddLanguage={isAddLanguage}
                      oldTextValue={oldRecord?.options[index]?.subText}
                    />
                  </div> */}
                      </div>
                    </div>
                  ))
              : null}
            {((values.optionType === OptionTypes.SingleChoice &&
              values.options.length < 12) ||
              (values.optionType === OptionTypes.Slider &&
                values.options.length < 5)) &&
            !isAddLanguage ? (
              <Space>
                <AddOption
                  disabled={disabled}
                  title={t("add-new-answer-title")}
                  onClick={() => push(option)}
                />
                <div className={styles["sub-text"]}>
                  {`${values.options.length} of ${
                    values.optionType === "singleChoice" ? "12" : "5"
                  } ${t("allowed-answers-label")}`}
                </div>
              </Space>
            ) : null}
          </div>
        )}
      />
    </>
  );
};

export default CheckinOptions;
