import { getFileExtension } from "./getFileExtension";

export const IMG_FILE_TYPES = ["png", "jpeg", "jpg"];
export const AUDIO_FILE_TYPES = ["wav", "mp3"];
export const DOCUMENT_FILE_TYPES = [
  "pdf",
  "word",
  "ppt",
  "xls",
  "csv",
  "xlsx",
  "txt",
  "pptx",
  "docx",
];

const KB = 1024;

// use this function to validate size of file according to type
export const validateFileSize = (file: File) => {
  const extension = getFileExtension(file?.name);
  const sizeInMB = file?.size / KB / KB;

  if (IMG_FILE_TYPES.includes(extension?.toLowerCase()!)) {
    return sizeInMB <= 5;
  }

  return sizeInMB <= 5000;
};
