import {
  GetTopChallengesQueryVariables,
  useLazyGetTopChallengesQuery,
} from "graphql/_generated/graphql";

export const useGetTopChallenges = () => {
  const [topChallengesFun, { isFetching }] = useLazyGetTopChallengesQuery();

  const handleGetTopChallenges = async (
    values: GetTopChallengesQueryVariables,
  ) => {
    try {
      const res = await topChallengesFun(values).unwrap();

      return res.getTopChallenges;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleGetTopChallenges,
    isLoading: isFetching,
  };
};
