import {
  useAddUpdateQuestionTranslationMutation,
  MutationAddUpdateQuestionTranslationArgs,
} from "graphql/_generated/graphql";

export const useAddUpdateQuestionTranslation = () => {
  const [addUpdateQuestionTranslationFun, { isLoading }] =
    useAddUpdateQuestionTranslationMutation();
  const handleAddUpdateQuestionTranslation = async (
    values: MutationAddUpdateQuestionTranslationArgs,
  ) => {
    try {
      const res = await addUpdateQuestionTranslationFun({
        create: values.create,
      }).unwrap();
      return res;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleAddUpdateQuestionTranslation,
    isLoadingAddUpdateQuestionTranslation: isLoading,
  };
};
