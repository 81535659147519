export const languages = [
  { value: "ab", label: "Abkhaz", nativelabel: "аҧсуа" },
  { value: "aa", label: "Afar", nativelabel: "Afaraf" },
  { value: "af", label: "Afrikaans", nativelabel: "Afrikaans" },
  { value: "ak", label: "Akan", nativelabel: "Akan" },
  { value: "sq", label: "Albanian", nativelabel: "Shqip" },
  { value: "am", label: "Amharic", nativelabel: "አማርኛ" },
  { value: "ar", label: "Arabic", nativelabel: "العربية" },
  { value: "an", label: "Aragonese", nativelabel: "Aragonés" },
  { value: "hy", label: "Armenian", nativelabel: "Հայերեն" },
  { value: "as", label: "Assamese", nativelabel: "অসমীয়া" },
  { value: "av", label: "Avaric", nativelabel: "авар мацӀ, магӀарул мацӀ" },
  { value: "ae", label: "Avestan", nativelabel: "avesta" },
  { value: "ay", label: "Aymara", nativelabel: "aymar aru" },
  { value: "az", label: "Azerbaijani", nativelabel: "azərbaycan dili" },
  { value: "bm", label: "Bambara", nativelabel: "bamanankan" },
  { value: "ba", label: "Bashkir", nativelabel: "башҡорт теле" },
  { value: "eu", label: "Basque", nativelabel: "euskara, euskera" },
  { value: "be", label: "Belarusian", nativelabel: "Беларуская" },
  { value: "bn", label: "Bengali", nativelabel: "বাংলা" },
  { value: "bh", label: "Bihari", nativelabel: "भोजपुरी" },
  { value: "bi", label: "Bislama", nativelabel: "Bislama" },
  { value: "bs", label: "Bosnian", nativelabel: "bosanski jezik" },
  { value: "br", label: "Breton", nativelabel: "brezhoneg" },
  { value: "bg", label: "Bulgarian", nativelabel: "български език" },
  { value: "my", label: "Burmese", nativelabel: "ဗမာစာ" },
  { value: "ca", label: "Catalan; Valencian", nativelabel: "Català" },
  { value: "ch", label: "Chamorro", nativelabel: "Chamoru" },
  { value: "ce", label: "Chechen", nativelabel: "нохчийн мотт" },
  {
    value: "ny",
    label: "Chichewa; Chewa; Nyanja",
    nativelabel: "chiCheŵa, chinyanja",
  },
  {
    value: "zh",
    label: "Chinese",
    nativelabel: "中文 (Zhōngwén), 汉语, 漢語",
  },
  { value: "cv", label: "Chuvash", nativelabel: "чӑваш чӗлхи" },
  { value: "kw", label: "Cornish", nativelabel: "Kernewek" },
  { value: "co", label: "Corsican", nativelabel: "corsu, lingua corsa" },
  { value: "cr", label: "Cree", nativelabel: "ᓀᐦᐃᔭᐍᐏᐣ" },
  { value: "hr", label: "Croatian", nativelabel: "hrvatski" },
  { value: "cs", label: "Czech", nativelabel: "česky, čeština" },
  { value: "da", label: "Danish", nativelabel: "dansk" },
  {
    value: "dv",
    label: "Divehi; Dhivehi; Maldivian;",
    nativelabel: "ދިވެހި",
  },
  { value: "nl", label: "Dutch", nativelabel: "Nederlands, Vlaams" },
  { value: "en", label: "English", nativelabel: "English" },
  { value: "eo", label: "Esperanto", nativelabel: "Esperanto" },
  { value: "et", label: "Estonian", nativelabel: "eesti, eesti keel" },
  { value: "ee", label: "Ewe", nativelabel: "Eʋegbe" },
  { value: "fo", label: "Faroese", nativelabel: "føroyskt" },
  { value: "fj", label: "Fijian", nativelabel: "vosa Vakaviti" },
  { value: "fi", label: "Finnish", nativelabel: "suomi, suomen kieli" },
  {
    value: "fr",
    label: "French",
    nativelabel: "français, langue française",
  },
  {
    value: "ff",
    label: "Fula; Fulah; Pulaar; Pular",
    nativelabel: "Fulfulde, Pulaar, Pular",
  },
  { value: "gl", label: "Galician", nativelabel: "Galego" },
  { value: "ka", label: "Georgian", nativelabel: "ქართული" },
  { value: "de", label: "German", nativelabel: "Deutsch" },
  { value: "el", label: "Greek, Modern", nativelabel: "Ελληνικά" },
  { value: "gn", label: "Guaraní", nativelabel: "Avañeẽ" },
  { value: "gu", label: "Gujarati", nativelabel: "ગુજરાતી" },
  {
    value: "ht",
    label: "Haitian; Haitian Creole",
    nativelabel: "Kreyòl ayisyen",
  },
  { value: "ha", label: "Hausa", nativelabel: "Hausa, هَوُسَ" },
  { value: "he", label: "Hebrew (modern)", nativelabel: "עברית" },
  { value: "hz", label: "Herero", nativelabel: "Otjiherero" },
  { value: "hi", label: "Hindi", nativelabel: "हिन्दी, हिंदी" },
  { value: "ho", label: "Hiri Motu", nativelabel: "Hiri Motu" },
  { value: "hu", label: "Hungarian", nativelabel: "Magyar" },
  { value: "ia", label: "Interlingua", nativelabel: "Interlingua" },
  { value: "id", label: "Indonesian", nativelabel: "Bahasa Indonesia" },
  {
    value: "ie",
    label: "Interlingue",
    nativelabel: "Originally called Occidental; then Interlingue after WWII",
  },
  { value: "ga", label: "Irish", nativelabel: "Gaeilge" },
  { value: "ig", label: "Igbo", nativelabel: "Asụsụ Igbo" },
  { value: "ik", label: "Inupiaq", nativelabel: "Iñupiaq, Iñupiatun" },
  { value: "io", label: "Ido", nativelabel: "Ido" },
  { value: "is", label: "Icelandic", nativelabel: "Íslenska" },
  { value: "it", label: "Italian", nativelabel: "Italiano" },
  { value: "iu", label: "Inuktitut", nativelabel: "ᐃᓄᒃᑎᑐᑦ" },
  {
    value: "ja",
    label: "Japanese",
    nativelabel: "日本語 (にほんご／にっぽんご)",
  },
  { value: "jv", label: "Javanese", nativelabel: "basa Jawa" },
  {
    value: "kl",
    label: "Kalaallisut, Greenlandic",
    nativelabel: "kalaallisut, kalaallit oqaasii",
  },
  { value: "kn", label: "Kannada", nativelabel: "ಕನ್ನಡ" },
  { value: "kr", label: "Kanuri", nativelabel: "Kanuri" },
  { value: "ks", label: "Kashmiri", nativelabel: "कश्मीरी, كشميري‎" },
  { value: "kk", label: "Kazakh", nativelabel: "Қазақ тілі" },
  { value: "km", label: "Khmer", nativelabel: "ភាសាខ្មែរ" },
  { value: "ki", label: "Kikuyu, Gikuyu", nativelabel: "Gĩkũyũ" },
  { value: "rw", label: "Kinyarwanda", nativelabel: "Ikinyarwanda" },
  { value: "ky", label: "Kirghiz, Kyrgyz", nativelabel: "кыргыз тили" },
  { value: "kv", label: "Komi", nativelabel: "коми кыв" },
  { value: "kg", label: "Kongo", nativelabel: "KiKongo" },
  {
    value: "ko",
    label: "Korean",
    nativelabel: "한국어 (韓國語), 조선말 (朝鮮語)",
  },
  { value: "ku", label: "Kurdish", nativelabel: "Kurdî, كوردی‎" },
  { value: "kj", label: "Kwanyama, Kuanyama", nativelabel: "Kuanyama" },
  { value: "la", label: "Latin", nativelabel: "latine, lingua latina" },
  {
    value: "lb",
    label: "Luxembourgish, Letzeburgesch",
    nativelabel: "Lëtzebuergesch",
  },
  { value: "lg", label: "Luganda", nativelabel: "Luganda" },
  {
    value: "li",
    label: "Limburgish, Limburgan, Limburger",
    nativelabel: "Limburgs",
  },
  { value: "ln", label: "Lingala", nativelabel: "Lingála" },
  { value: "lo", label: "Lao", nativelabel: "ພາສາລາວ" },
  { value: "lt", label: "Lithuanian", nativelabel: "lietuvių kalba" },
  { value: "lu", label: "Luba-Katanga", nativelabel: "" },
  { value: "lv", label: "Latvian", nativelabel: "latviešu valoda" },
  { value: "gv", label: "Manx", nativelabel: "Gaelg, Gailck" },
  { value: "mk", label: "Macedonian", nativelabel: "македонски јазик" },
  { value: "mg", label: "Malagasy", nativelabel: "Malagasy fiteny" },
  { value: "ms", label: "Malay", nativelabel: "bahasa Melayu, بهاس ملايو‎" },
  { value: "ml", label: "Malayalam", nativelabel: "മലയാളം" },
  { value: "mt", label: "Maltese", nativelabel: "Malti" },
  { value: "mi", label: "Māori", nativelabel: "te reo Māori" },
  { value: "mr", label: "Marathi (Marāṭhī)", nativelabel: "मराठी" },
  { value: "mh", label: "Marshallese", nativelabel: "Kajin M̧ajeļ" },
  { value: "mn", label: "Mongolian", nativelabel: "монгол" },
  { value: "na", label: "Nauru", nativelabel: "Ekakairũ Naoero" },
  {
    value: "nv",
    label: "Navajo, Navaho",
    nativelabel: "Diné bizaad, Dinékʼehǰí",
  },
  { value: "nb", label: "Norwegian Bokmål", nativelabel: "Norsk bokmål" },
  { value: "nd", label: "North Ndebele", nativelabel: "isiNdebele" },
  { value: "ne", label: "Nepali", nativelabel: "नेपाली" },
  { value: "ng", label: "Ndonga", nativelabel: "Owambo" },
  { value: "nn", label: "Norwegian Nynorsk", nativelabel: "Norsk nynorsk" },
  { value: "no", label: "Norwegian", nativelabel: "Norsk" },
  { value: "ii", label: "Nuosu", nativelabel: "ꆈꌠ꒿ Nuosuhxop" },
  { value: "nr", label: "South Ndebele", nativelabel: "isiNdebele" },
  { value: "oc", label: "Occitan", nativelabel: "Occitan" },
  { value: "oj", label: "Ojibwe, Ojibwa", nativelabel: "ᐊᓂᔑᓈᐯᒧᐎᓐ" },
  {
    value: "cu",
    label:
      "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
    nativelabel: "ѩзыкъ словѣньскъ",
  },
  { value: "om", label: "Oromo", nativelabel: "Afaan Oromoo" },
  { value: "or", label: "Oriya", nativelabel: "ଓଡ଼ିଆ" },
  { value: "os", label: "Ossetian, Ossetic", nativelabel: "ирон æвзаг" },
  { value: "pa", label: "Panjabi, Punjabi", nativelabel: "ਪੰਜਾਬੀ, پنجابی‎" },
  { value: "pi", label: "Pāli", nativelabel: "पाऴि" },
  { value: "fa", label: "Persian", nativelabel: "فارسی" },
  { value: "pl", label: "Polish", nativelabel: "polski" },
  { value: "ps", label: "Pashto, Pushto", nativelabel: "پښتو" },
  { value: "pt", label: "Portuguese", nativelabel: "Português" },
  { value: "qu", label: "Quechua", nativelabel: "Runa Simi, Kichwa" },
  { value: "rm", label: "Romansh", nativelabel: "rumantsch grischun" },
  { value: "rn", label: "Kirundi", nativelabel: "kiRundi" },
  {
    value: "ro",
    label: "Romanian, Moldavian, Moldovan",
    nativelabel: "română",
  },
  { value: "ru", label: "Russian", nativelabel: "русский язык" },
  { value: "sa", label: "Sanskrit (Saṁskṛta)", nativelabel: "संस्कृतम्" },
  { value: "sc", label: "Sardinian", nativelabel: "sardu" },
  { value: "sd", label: "Sindhi", nativelabel: "सिन्धी, سنڌي، سندھی‎" },
  { value: "se", label: "Northern Sami", nativelabel: "Davvisámegiella" },
  { value: "sm", label: "Samoan", nativelabel: "gagana faa Samoa" },
  { value: "sg", label: "Sango", nativelabel: "yângâ tî sängö" },
  { value: "sr", label: "Serbian", nativelabel: "српски језик" },
  { value: "gd", label: "Scottish Gaelic; Gaelic", nativelabel: "Gàidhlig" },
  { value: "sn", label: "Shona", nativelabel: "chiShona" },
  { value: "si", label: "Sinhala, Sinhalese", nativelabel: "සිංහල" },
  { value: "sk", label: "Slovak", nativelabel: "slovenčina" },
  { value: "sl", label: "Slovene", nativelabel: "slovenščina" },
  { value: "so", label: "Somali", nativelabel: "Soomaaliga, af Soomaali" },
  { value: "st", label: "Southern Sotho", nativelabel: "Sesotho" },
  {
    value: "es",
    label: "Spanish; Castilian",
    nativelabel: "español, castellano",
  },
  { value: "su", label: "Sundanese", nativelabel: "Basa Sunda" },
  { value: "sw", label: "Swahili", nativelabel: "Kiswahili" },
  { value: "ss", label: "Swati", nativelabel: "SiSwati" },
  { value: "sv", label: "Swedish", nativelabel: "svenska" },
  { value: "ta", label: "Tamil", nativelabel: "தமிழ்" },
  { value: "te", label: "Telugu", nativelabel: "తెలుగు" },
  { value: "tg", label: "Tajik", nativelabel: "тоҷикӣ, toğikī, تاجیکی‎" },
  { value: "th", label: "Thai", nativelabel: "ไทย" },
  { value: "ti", label: "Tigrinya", nativelabel: "ትግርኛ" },
  {
    value: "bo",
    label: "Tibetan Standard, Tibetan, Central",
    nativelabel: "བོད་ཡིག",
  },
  { value: "tk", label: "Turkmen", nativelabel: "Türkmen, Түркмен" },
  {
    value: "tl",
    label: "Tagalog",
    nativelabel: "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔",
  },
  { value: "tn", label: "Tswana", nativelabel: "Setswana" },
  { value: "to", label: "Tonga (Tonga Islands)", nativelabel: "faka Tonga" },
  { value: "tr", label: "Turkish", nativelabel: "Türkçe" },
  { value: "ts", label: "Tsonga", nativelabel: "Xitsonga" },
  { value: "tt", label: "Tatar", nativelabel: "татарча, tatarça, تاتارچا‎" },
  { value: "tw", label: "Twi", nativelabel: "Twi" },
  { value: "ty", label: "Tahitian", nativelabel: "Reo Tahiti" },
  {
    value: "ug",
    label: "Uighur, Uyghur",
    nativelabel: "Uyƣurqə, ئۇيغۇرچە‎",
  },
  { value: "uk", label: "Ukrainian", nativelabel: "українська" },
  { value: "ur", label: "Urdu", nativelabel: "اردو" },
  { value: "uz", label: "Uzbek", nativelabel: "zbek, Ўзбек, أۇزبېك‎" },
  { value: "ve", label: "Venda", nativelabel: "Tshivenḓa" },
  { value: "vi", label: "Vietlabelse", nativelabel: "Tiếng Việt" },
  { value: "vo", label: "Volapük", nativelabel: "Volapük" },
  { value: "wa", label: "Walloon", nativelabel: "Walon" },
  { value: "cy", label: "Welsh", nativelabel: "Cymraeg" },
  { value: "wo", label: "Wolof", nativelabel: "Wollof" },
  { value: "fy", label: "Western Frisian", nativelabel: "Frysk" },
  { value: "xh", label: "Xhosa", nativelabel: "isiXhosa" },
  { value: "yi", label: "Yiddish", nativelabel: "ייִדיש" },
  { value: "yo", label: "Yoruba", nativelabel: "Yorùbá" },
  {
    value: "za",
    label: "Zhuang, Chuang",
    nativelabel: "Saɯ cueŋƅ, Saw cuengh",
  },
];
