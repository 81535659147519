import {
  MutationUpdateParticipantsScoreArgs,
  useUpdateParticipantsScoreMutation,
} from "graphql/_generated/graphql";

export const useUpdateParticipantsScore = () => {
  const [
    updateParticipantsScoreFn,
    { isLoading: isUpdatingParticipantsScore },
  ] = useUpdateParticipantsScoreMutation();

  const updateParticipantsScore = async (
    values: MutationUpdateParticipantsScoreArgs,
  ) => {
    try {
      const res = await updateParticipantsScoreFn({
        update: values.update,
      }).unwrap();
      return res.updateParticipantsScore;
    } catch (error) {
      throw error;
    }
  };

  return {
    updateParticipantsScore,
    isUpdatingParticipantsScore,
  };
};
