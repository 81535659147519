import { Col, Row } from "antd";
import {
  FilterValuesProps,
  WidgetExtraProps,
} from "features/company-dashboard/types";
import { timeFilterTypes } from "features/company-dashboard/utils";
import { UserFilters } from "graphql/_generated/graphql";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "../../CompanyDashboard.module.scss";
import { SectionHeader } from "../SectionHeader";
import { InfoTextProps } from "../organization-metrices/types";
import { TopChallenges } from "./TopChallenges";
import { TotalChallenges } from "./TotalChallenges";

export const Challenges: FunctionComponent<
  InfoTextProps & WidgetExtraProps
> = ({ selectedInfoText, setSelectedInfoText, companyRegisteredDate }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(true);
  const [userFilters, setUserFilters] = useState<UserFilters>({
    clientIds: null,
    departmentIds: null,
    gender: null,
    positionIds: null,
    age: null,
  });
  const [selectedValues, setSelectedValues] = useState<FilterValuesProps>({
    pastDays: timeFilterTypes.PAST_4_WEEKS,
    customDateRange: "",
  });

  return (
    <>
      <div className={styles["checkins-chart"]}>
        <SectionHeader
          visible={visible}
          setVisible={setVisible}
          userFilters={userFilters}
          setUserFilters={setUserFilters}
          sectionTitle={t("challenges")}
          initialValues={selectedValues}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
          companyRegisteredDate={companyRegisteredDate}
          open={open}
          setOpen={setOpen}
        />
        <Row gutter={[16, 16]} className={styles["row-flex"]}>
          <Col xs={24} xl={12}>
            <TotalChallenges
              userFilters={userFilters}
              selectedValues={selectedValues}
              visible={visible}
              selectedInfoText={selectedInfoText}
              setSelectedInfoText={setSelectedInfoText}
            />
          </Col>
          <Col xs={24} xl={12}>
            <TopChallenges
              item={{
                title: t("top-5-challenges"),
                type: t("top-5-challenges"),
                selectedValues: selectedValues,
                visible,
              }}
              userFilters={userFilters}
              selectedInfoText={selectedInfoText}
              setSelectedInfoText={setSelectedInfoText}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
