import EnumQuestionTypes from "features/assessment/types/EnumQuestionTypes";

/**
 * Question types that have sub-questions.
 */
export const subQuestionsBasedOptionTypes = [
  EnumQuestionTypes.SINGLE_CHOICE,
  // "ranking",
  // "multipleChoice",
  // "boolean",
  // "slider",
];
