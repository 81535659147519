import { Button, Result } from "antd";
import { useNavigationToDashboard } from "hooks/useNavigationToDashboard";
import { useEffect } from "react";
import { useSelector } from "react-redux";

type Props = { url: string };

export default function Redirect({ url }: Props) {
  const { navigateToSignInUserDashboard } = useNavigationToDashboard();
  const token = useSelector((state: any) => state.auth.token);
  useEffect(() => {
    // Redirect the user to viwell app download page
    window.location.href = url;
  }, []);

  return (
    <Result
      status="info"
      title="...Redirecting"
      subTitle="You are being redirected to the app download page."
      extra={
        <Button
          className="primary-btn"
          onClick={() => navigateToSignInUserDashboard(token)}
        >
          Back Home
        </Button>
      }
    />
  );
}
