import { setDayEndTime } from "helpers/DatetimeUtils";
import { useUpdateChallengeMutation } from "graphql/_generated/graphql";

export const useUpdateChallenge = () => {
  const [updateChallengeFun, { isLoading: isLoadingUpdateChallenge }] =
    useUpdateChallengeMutation();

  // this function is responsible for updating challenge
  const handleUpdateChallenge = async (id: string, values: any) => {
    try {
      const res = await updateChallengeFun({
        updateChallengeId: id,
        updateObj: {
          ...values,
        },
      }).unwrap();
    } catch (error) {
      throw error;
    }
  };
  return {
    isLoading: isLoadingUpdateChallenge,
    handleUpdateChallenge,
  };
};
