import {
  UpdateHabitMutationVariables,
  useUpdateHabitMutation,
} from "graphql/_generated/graphql";

export const useUpdateHabit = () => {
  const [updateHabitFun, { isLoading: isLoadingUpdateHabit }] =
    useUpdateHabitMutation();

  const handleUpdateHabit = async (values: UpdateHabitMutationVariables) => {
    try {
      const res = await updateHabitFun({
        updateHabitId: values.updateHabitId,
        updateObj: values.updateObj,
      }).unwrap();
      return res.updateHabit;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleUpdateHabit,
    isLoadingUpdateHabit,
  };
};
