import { useEffect } from "react";
import CheckinHeader from "./checkin-header/CheckinHeader";
import CheckinOptions from "./checkin-options/CheckinOptions";

interface CheckinQuestionProps {
  isAddLanguage?: boolean;
  disabled: boolean;
  values: any;
  oldRecord?: any;
  resetField?: any;
  assessmentSettings?: any;
}

const CheckinQuestionDetail: React.FunctionComponent<CheckinQuestionProps> = ({
  isAddLanguage,
  disabled,
  values,
  oldRecord,
  resetField,
  assessmentSettings,
}) => {
  useEffect(() => {
    if (oldRecord) {
      resetField("text", oldRecord?.translations?.text);
      if (values?.options) {
        values?.options.map((item: any, optIndex: number) => {
          resetField(
            `options[${optIndex}].text`,
            values?.options[optIndex]?.translations?.text,
          );

          resetField(
            `options[${optIndex}].subText`,
            values?.options[optIndex]?.translations?.subText,
          );
        });
      }
    }
  }, []);

  return (
    <>
      <CheckinHeader
        disabled={disabled}
        values={values}
        isAddLanguage={isAddLanguage}
        oldRecord={oldRecord}
        assessmentSettings={assessmentSettings}
      />
      <CheckinOptions
        disabled={disabled}
        values={values}
        isAddLanguage={isAddLanguage}
        oldRecord={oldRecord}
        assessmentSettings={assessmentSettings}
        setFieldValue={resetField}
      />
    </>
  );
};

export default CheckinQuestionDetail;
