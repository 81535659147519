export const errorFormat = (
  error: unknown | string,
  fallback = "Sorry, some problem occurred.",
) => {
  if (error == null) {
    return "";
  }

  if (typeof error === "string") {
    return error;
  }

  const errorObj = error as { message: string };
  if (!errorObj?.message) {
    return fallback;
  }

  if (errorObj?.message == "Network request failed") {
    return "No internet connection";
  }

  return errorObj?.message;
};
