import {
  useGetTagListQuery,
  useToggleTagActivationMutation,
} from "graphql/_generated/graphql";

export type handleToggleActivateTagFun = (id: string) => Promise<boolean>;
//define the type of useCategory hook

type TypeActivationTagToggle = () => {
  isLoading: boolean;
  handleToggleActivateTag: handleToggleActivateTagFun;
};

export const useToggleTagActivation: TypeActivationTagToggle = () => {
  const { refetch } = useGetTagListQuery();
  const [ActivationDeactivationFun, { isLoading }] =
    useToggleTagActivationMutation();

  //this function is responsible for Activation/Deactivation categories
  const handleToggleActivateTag = async (id: string) => {
    try {
      const res = await ActivationDeactivationFun({
        toggleTagActivationId: id,
      }).unwrap();

      refetch();
      return res.toggleTagActivation;
    } catch (error: any) {
      return error;
    }
  };

  return { isLoading, handleToggleActivateTag };
};
