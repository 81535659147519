import customNotification from "components/custom-notification/CustomNotification";
import { logout } from "features/auth/authSlice";
import { errorFormat } from "helpers";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

interface NotificationConfig {
  type?: string;
  message?: string;
  duration?: number | undefined;
}

interface ErrorOrMessage {
  response?: {
    errors?: [{ message?: string }];
    extensions?: { code?: string };
    status?: number;
  };
}

const STATUS = {
  UNAUTHORIZED: 401,
};

const predefinedErrorMessages: string[] = [
  "Invalid Token!",
  "Token not found",
  "Session not found",
];

export const useErrorHandler = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token: tokenExists } = useSelector((state: any) => state.auth);
  const loggingOut = useRef(false);

  useEffect(() => {
    if (!tokenExists) {
      loggingOut.current = false;
    }
  }, [tokenExists]);

  const showNotification = (
    type: string,
    message: string,
    duration?: number,
  ) => {
    if (!loggingOut.current) {
      customNotification(type, message, duration);
    }
  };

  const handleError = (
    errorOrMessage: ErrorOrMessage | string,
    config: {
      showNotification?: boolean;
      redirectRoute?: string;
      notificationConfig?: NotificationConfig;
    } = {},
  ) => {
    // Default configuration values
    const {
      showNotification: shouldShowNotification = true,
      redirectRoute = "/",
      notificationConfig = {},
    } = config;

    const { type = "error", duration, message } = notificationConfig;

    const isStringError: boolean = typeof errorOrMessage === "string";
    const isObjectError: boolean = typeof errorOrMessage === "object";

    if (isStringError) {
      const errorMessage: string = errorOrMessage as string;
      const isAuthError = predefinedErrorMessages.includes(errorMessage);

      if (isAuthError && tokenExists) {
        loggingOut.current = true;
        dispatch(logout());
        navigate(redirectRoute);
      }

      if (shouldShowNotification) {
        showNotification(type, message || errorMessage, duration);
      }

      return;
    }

    if (isObjectError) {
      const { response: { extensions: { code = "" } = {}, status } = {} } =
        errorOrMessage as ErrorOrMessage;

      if (
        (status === STATUS.UNAUTHORIZED || code === "Unauthorized") &&
        tokenExists
      ) {
        dispatch(logout());
        navigate(redirectRoute);
      }

      if (shouldShowNotification) {
        showNotification(
          type,
          message || errorFormat(errorOrMessage),
          duration,
        );
      }
    }
  };

  return { handleError };
};
