import { FormikValues } from "formik";
import { ContentType } from "graphql/_generated/graphql";

interface Lang {
  lang: string;
  title: string;
  language: string;
  description: string;
}

export const filterLanguagesWithout = (
  languages: { label: string; value: string }[],
  usedLanguages: any,
  excludeLanguage?: string,
  mainLanguage?: string,
) => {
  const filteredLanguages = languages?.filter(
    (item) => item.value !== excludeLanguage,
  );

  const mainLanArray = mainLanguage ? [{ lang: mainLanguage }] : [];
  const usedLangsArray = [...usedLanguages, ...mainLanArray];

  return filteredLanguages?.filter((item) => {
    return usedLangsArray.every((translation: Lang) => {
      const key = translation["lang"] ? "lang" : "language";
      return translation[key] !== item.value;
    });
  });
};

// export const filterLanguagesWithEn = (
//   languages: { label: string; value: string }[],
//   translations: { url?: string; text?: string; language: string }[]
// ) => {
//   const arr: any[] = [];
//   // languages.map((item) => {
//   //   translations.map((translation) => {
//   //     if (translation.language !== item.value) {
//   //       console.log("&&&&&&&&&&&&&&&&&&&&&", item);
//   //       arr.push(item);
//   //     }
//   //   });
//     for (var i = 0; i < languages.length; i++) {
//       for (var j = 0; j < translations.length; j++) {
//         if (translations[j].language !== languages[i].value) {
//           arr.push(languages[i]);
//         }
//       }
//     }
//     return arr;
//     // return translations.every((translation) => {
//     //   return translation.language !== item.value;
//     // });
//   // });
// };

export const filterLanguagesWithEn = (
  languages: { label: string; value: string }[] = [],
  translations: { url?: string; text?: string; language: string }[] = [],
) => {
  return languages?.filter((item) => {
    return translations?.every((translation) => {
      return translation.language !== item.value;
    });
  });
};

export const filterLangWithEn = (
  languages: { label: string; value: string }[],
  translations: { url?: string; text?: string; lang: string }[],
) => {
  return languages.filter((item) => {
    return translations.every((translation) => {
      return translation.lang !== item.value;
    });
  });
};
export const getLabelFromValue = (
  languages: { label: string; value: string }[],
  values: any,
  index?: number,
) => {
  if (!values) return;

  let label;
  let selectedValue: string;

  if (typeof values === "object") {
    selectedValue =
      index !== undefined ? values?.translations[index]?.lang : values.language;
    if (!selectedValue) return;
  }

  if (typeof values === "string") {
    selectedValue = values;
  }

  languages.forEach((item) => {
    if (item.value === selectedValue) {
      label = item.label;
    }
  });

  return label;
};

export const getLabelFromId = (
  obj: { label: string; id: string }[],
  value: string,
) => {
  let label;
  obj.forEach((item) => {
    if (item.id === value) {
      label = item.label;
    }
  });
  return label;
};

export const checkTypeAudio = ({ contentType }: FormikValues) => {
  return (
    contentType === ContentType.EducationalAudio ||
    contentType === ContentType.SoundscapeAudio ||
    contentType === ContentType.MusicAudio ||
    contentType === ContentType.SoundTherapyAudio ||
    contentType === ContentType.Audio
  );
};

export const getContentType = (contentType: ContentType) => {
  const isTypeAudio = checkTypeAudio({ contentType });
  return isTypeAudio
    ? "localizedAudios"
    : contentType === ContentType.Video
    ? "localizedVideos"
    : contentType === ContentType.File
    ? "localizedFiles"
    : "localizedTexts";
};
