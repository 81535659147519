import EmojiPicker from "components/form/custom-emoji-picker/EmojiPicker";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import * as React from "react";
import styles from "./MetricOptions.module.scss";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { useTranslation } from "react-i18next";

interface ISubMetricProps {
  option: string;
  SN: number;
  emoji: string;
  text: string;
  score: string;
  tags: any;
  namePrefix?: string;
  isEmojiEnable: boolean;
  isDisabled?: boolean;
  questionTags?: any;
}

/**
 * Matrix option box.
 *
 * @param props ISubMetricProps
 * @returns JSX
 */
const SubMetric: React.FunctionComponent<ISubMetricProps> = (props) => {
  let fieldNamePrefix = props?.namePrefix ? props?.namePrefix + "." : "";
  const { t } = useTranslation();

  return (
    <div className={styles["sub-card"]}>
      <div className={styles["inner-sub"]}>
        {props?.isEmojiEnable ? (
          <EmojiPicker
            name={`${fieldNamePrefix}emoji`}
            isDisabled={props?.isDisabled}
          />
        ) : null}
        <CustomTextField
          disabled={props?.isDisabled}
          marginBtm={false}
          name={`${fieldNamePrefix}text`}
          placeholder={t("title")}
        />
        <CustomTextField
          marginBtm={false}
          disabled={props?.isDisabled}
          style={{ width: "60px" }}
          name={`${fieldNamePrefix}score`}
          type="number"
          placeholder={t("score")}
        />
        <CustomSelectField
          marginBtm={false}
          disabled={props?.isDisabled}
          style={{ minWidth: "auto" }}
          maxTagCount="responsive"
          mode="tags"
          name={`${fieldNamePrefix}tags`}
          placeholder={t("tags")}
          options={props?.questionTags?.data}
          loading={props?.questionTags?.isLoading}
        />
      </div>
    </div>
  );
};

export default SubMetric;
