import { Language } from "graphql/_generated/graphql";

export const getLanguages = async () => {
  const res = await fetch("locales/languages.json");
  return res.json();
};

// export const getLanguage = (language: string) =>
//   languages.find(({ value }) => value === language)?.label;

const languageMapping: { [key: string]: any } = {
  en: Language.English,
  ar: Language.Arabic,
};

export const getLanguage = (language: string) =>
  languageMapping[language] || "";

export const getLanguageCode = (lang: Language) => {
  const languageCode = Object.entries(languageMapping).find(
    ([_, value]) => value === lang,
  );

  return languageCode ? languageCode[0] : null;
};
