import * as React from "react";
import ContainerHeading from "features/assessment/questions/ContainerHeading/ContainerHeading";
import CollapseAble from "features/assessment/questions/collapseables/CollapseAble";
import { useTranslation } from "react-i18next";
import CustomTable from "components/custom-table/CustomTable";
import { ColumnsType } from "antd/lib/table";
import { useTeamLeaderboardViewModel } from "features/events/hooks/useTeamLeaderboardViewModel";
import { GetTeamEventMembersQuery } from "graphql/_generated/graphql";
import { Space } from "antd";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import styles from "features/events/css/TeamLeaderBoard.module.scss";
import { useImperativeHandle } from "react";
export interface TeamParticipantsDetailRef {
  refetchTeamsData: () => void;
}
interface TeamParticipantDetailsProps {
  eventId: string;
}

const TeamParticipantDetails = React.forwardRef<
  TeamParticipantsDetailRef,
  TeamParticipantDetailsProps
>(({ eventId }, ref) => {
  const { t } = useTranslation();
  const vm = useTeamLeaderboardViewModel({ eventId });
  useImperativeHandle(
    ref,
    () => {
      return {
        refetchTeamsData: vm.fetchTeamEventParticipantsData,
      };
    },
    [],
  );
  const columns: ColumnsType<
    GetTeamEventMembersQuery["getTeamEventMembers"]["data"][0]
  > = [
    {
      title: t("id"),
      key: "userId",
      dataIndex: "userId",
      sorter: {
        multiple: 1,
      },
      render: (_text, _record, index) =>
        (vm.tableCurrentPage - 1) * vm.pageSize + index + 1,
    },
    {
      title: t("full-name"),
      dataIndex: "firstName",
      key: "firstName",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => (
        <Space>
          <CustomAvatar imgSrc={record.avatar?.imgPath ?? record.image ?? ""} />
          <div
            className={styles["team-member"]}
          >{`${record.firstName} ${record.lastName}`}</div>
        </Space>
      ),
    },
    {
      title: t("team"),
      dataIndex: "team",
      key: "team.title",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => (
        <Space>
          <div
            style={{ backgroundColor: record?.team?.color }}
            className={styles["team-color"]}
          />
          <div className={styles["team-member"]}>
            {record?.team?.title ?? t("un-assigned")}
          </div>
        </Space>
      ),
    },
    {
      title: t("company"),
      dataIndex: "company",
      key: "company.companyName",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => (
        <Space>
          <CustomAvatar imgSrc={record.company?.image ?? ""} />
          <div>{record.company?.companyName}</div>
        </Space>
      ),
    },
    {
      title: t("email"),
      dataIndex: "email",
      key: "email",
      sorter: {
        multiple: 1,
      },
    },
  ];
  return (
    <CollapseAble
      defaultActiveKey={["team-participants"]}
      headerKey="team-participants"
      hideExtra={false}
      expandIconPosition="end"
      header={<ContainerHeading text={t("participant-details")} />}
    >
      <CustomTable
        columns={columns}
        dataSource={vm.columnsData?.data}
        filters={{
          handleCallback: vm.filtersCallBack,
        }}
        loading={vm.isLoading.isLoadingTeamEventMembers}
        searchStyle={{
          borderStyle: "solid",
          borderRadius: 10,
          borderColor: "#adadad",
          width: 270,
        }}
        totalItems={vm.columnsData?.total}
      />
    </CollapseAble>
  );
});

export default TeamParticipantDetails;
