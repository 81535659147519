import {
  useToggleTranslationStatusMutation,
  MutationToggleTranslationStatusArgs,
} from "graphql/_generated/graphql";

export const useToggleTranslation = () => {
  const [tanslationToggleFun, { isLoading }] =
    useToggleTranslationStatusMutation();

  const handleToggleTranslation = async (
    values: MutationToggleTranslationStatusArgs,
  ) => {
    try {
      const res = await tanslationToggleFun(values).unwrap();
      return res.toggleTranslationStatus;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleToggleTranslation,
    isLoading,
  };
};
