import {
  convertTranslationArrToObj,
  convertTranslationObjToArr,
} from "helpers";
import {
  useUpdateAchievementMutation,
  MutationUpdateAchievementArgs,
  useLazyGetAchievementQuery,
} from "graphql/_generated/graphql";

export const useUpdateAchievement = () => {
  const [updateAchievementFun, { isLoading }] = useUpdateAchievementMutation();

  const [prevEditDataFun, { isLoading: prevDataLoading }] =
    useLazyGetAchievementQuery();

  //this function is responsible for fetching the specific Achievement Data
  const handleFetchAchievementData = async (id: string) => {
    try {
      const res = await prevEditDataFun({ getAchievementId: id }).unwrap();
      const translations = await convertTranslationObjToArr(
        res.getAchievement?.translations,
      );

      return { ...res?.getAchievement, translations };
    } catch (error) {
      throw error;
    }
  };

  //this function is responsible for updating the Achievement
  const handleUpdateAchievement = async (
    values: MutationUpdateAchievementArgs,
  ) => {
    try {
      let translations = await convertTranslationArrToObj(
        values.updateObj.translations,
      );
      const { tracker, ...rest } = values.updateObj;
      let params = {
        updateObj: {
          ...rest,
          tracker: {
            goal: tracker!.goal,
            cron: tracker!.cron,
            goalFilter: tracker?.goalFilter
              ? {
                  value: tracker?.goalFilter?.value!,
                  type: tracker?.goalFilter?.type!,
                }
              : null,
          },
          translations,
        },
      };

      await updateAchievementFun({
        updateAchievementId: values.id,
        updateObj: params.updateObj,
      }).unwrap();
    } catch (error: any) {
      throw error;
    }
  };

  return {
    isLoading: isLoading || prevDataLoading,
    handleUpdateAchievement,
    handleFetchAchievementData,
  };
};
