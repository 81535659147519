/**
 * Judging criteria for an assessment type.
 */
enum EnumAssessmentTypes {
  FULFILLMENT_CHECK_IN = "Fulfillment Check-In",
  STRESS_CHECK_IN = "Stress Check-In",
  MOOD_CHECK_IN = "Mood Check-In",
  SLEEP_CHECK_IN = "Sleep Check-In",
  ONBOARDING_ASSESSMENT = "OnBoarding Assessment",
  VIWELL_ASSESSMENT = "Viwell Assessment",
}

export default EnumAssessmentTypes;
