import { useActivationFaq } from "./useActivationFAQs";
import { useAddFaq } from "./useAddFAQ";
import { useUpdateFaq } from "./useUpdateFAQ";

export const useFAQs = () => {
  const { isAddLoading, handleAddFaq } = useAddFaq();
  const { activationLoading, handleActivationDeactivationFaq } =
    useActivationFaq();
  const { updateLoading, handleUpdateFaq, handleFetchEditFaq } = useUpdateFaq();
  return {
    isLoading: isAddLoading || activationLoading || updateLoading,
    handleAddFaq,
    handleActivationDeactivationFaq,
    handleUpdateFaq,
    handleFetchEditFaq,
  };
};
