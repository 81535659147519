export const MoodIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M11.8646 14.5189C12.1739 14.2104 12.3506 13.7824 12.3506 13.3462C12.3506 12.91 12.1739 12.482 11.8646 12.1735C11.5561 11.865 11.1281 11.6875 10.6919 11.6875C10.2547 11.6875 9.82772 11.865 9.51912 12.1735C9.20985 12.482 9.0332 12.91 9.0332 13.3462C9.0332 13.7824 9.20985 14.2104 9.51912 14.5189C9.82772 14.8282 10.2557 15.0049 10.6919 15.0049C11.1281 15.0049 11.5561 14.8282 11.8646 14.5189Z"
        fill="#303030"
      />
      <path
        d="M21.3076 11.6875C20.8714 11.6875 20.4434 11.865 20.1348 12.1735C19.8264 12.482 19.6489 12.9091 19.6489 13.3462C19.6489 13.7833 19.8264 14.2104 20.1348 14.5189C20.4434 14.8282 20.8714 15.0049 21.3076 15.0049C21.7439 15.0049 22.1718 14.8282 22.4803 14.5189C22.7888 14.2104 22.9663 13.7833 22.9663 13.3462C22.9663 12.91 22.7887 12.482 22.4803 12.1735C22.1718 11.865 21.7439 11.6875 21.3076 11.6875Z"
        fill="#303030"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 27.5C22.3513 27.5 27.5 22.3513 27.5 16C27.5 9.64873 22.3513 4.5 16 4.5C9.64873 4.5 4.5 9.64873 4.5 16C4.5 22.3513 9.64873 27.5 16 27.5ZM16 29C23.1797 29 29 23.1797 29 16C29 8.8203 23.1797 3 16 3C8.8203 3 3 8.8203 3 16C3 23.1797 8.8203 29 16 29Z"
        fill="#303030"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.9203 17.6321C10.3047 17.4778 10.7414 17.6643 10.8957 18.0487C11.3009 19.0582 11.9963 19.9249 12.8941 20.5392C13.7918 21.1535 14.8516 21.4878 15.9393 21.4997C17.027 21.5117 18.0939 21.2009 19.005 20.6065C19.916 20.0122 20.6304 19.161 21.0577 18.1607C21.2205 17.7798 21.6612 17.6029 22.0421 17.7656C22.423 17.9283 22.5999 18.369 22.4372 18.7499C21.8933 20.0231 20.9841 21.1064 19.8245 21.8629C18.665 22.6193 17.3072 23.0149 15.9228 22.9996C14.5384 22.9844 13.1896 22.559 12.047 21.7772C10.9044 20.9954 10.0193 19.8923 9.50363 18.6074C9.34935 18.223 9.53589 17.7863 9.9203 17.6321Z"
        fill="#303030"
      />
    </svg>
  );
};
