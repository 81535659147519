import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { useTranslation } from "react-i18next";
import styles from "./MetricOptions.module.scss";
import SubMetric from "./SubMetric";

interface MetricRowProps {
  namePrefix?: string;
  tags: any;
  values: any;
  item: any;
  isDisabled?: boolean;
  titleIndex?: number;
}

const MetricRow: React.FunctionComponent<MetricRowProps> = ({
  namePrefix,
  // rowNumber,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles["metric-row"]}>
      <CustomTextField
        disabled={props?.isDisabled}
        marginBtm={false}
        name={`metricTitles[${props.titleIndex}].value`}
        placeholder={`${t("title")} ${props.item.id}`}
      />
      <div className={styles["sub-flex"]}>
        {props?.values?.metricOptions?.map((parItem: any, optIndex: number) => {
          return (
            <div key={optIndex}>
              {props?.values?.metrices?.map((optItem: any, index: number) => {
                return (
                  (optItem.row === props.item.id &&
                    optItem.column === parItem.id && (
                      <SubMetric
                        questionTags={props.tags}
                        key={index}
                        {...optItem}
                        isEmojiEnable={props?.values?.isEmojiEnable}
                        namePrefix={`metrices[${index}]`}
                        isDisabled={props?.isDisabled}
                      />
                    )) || <div key={`${index}-hello`} />
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MetricRow;
