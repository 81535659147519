import customNotification from "components/custom-notification/CustomNotification";
import { FormikHelpers } from "formik";
import {
  Category,
  CategoryCreate,
  CategoryList,
  Comparison,
  useGetCategoryListQuery,
  useLazyGetCategoryListQuery,
} from "graphql/_generated/graphql";
import { cloneObject } from "helpers";
import { usePillarIdAndName } from "hooks";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCategory } from "./useCategory";
import { useDeleteCategory } from "./useDeleteCategory";

const parentFilter = {
  key: "parent",
  value: null,
  comparison: Comparison.Equal,
};

export const useCategoriesListViewModel = () => {
  const { t } = useTranslation();
  const [columnsData, setColumnsData] = useState<CategoryList>();
  const [getAllCategoriesDataFun] = useLazyGetCategoryListQuery();
  const { isFetching, refetch } = useGetCategoryListQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const [visible, setVisible] = useState<boolean>(false);
  const [editId, setEditId] = useState<string | null>(null);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [filtersData, setFiltersData] = useState<any>(null);
  const { handleDeleteCategory } = useDeleteCategory();
  const { handleError } = useErrorHandler();

  const {
    isLoading: isCategoryLoading,
    handleAddCategory,
    handleFetchEditCategoryData,
    handleUpdateCategory,
    handleDeActivationCategory,
  } = useCategory();

  const [initialValues, setInitialValues] = useState<CategoryCreate>({
    name: "",
    icon: "",
    pillarId: null,
    parent: null,
    translations: [],
  });

  const handleCancel = () => {
    setVisible(false);
    setEditId(null);
  };

  //setting the previous values to initial values object when click on the edit button
  useEffect(() => {
    if (editId) {
      handleFetchEditCategoryData(editId).then((data: Category) => {
        setInitialValues({
          name: data.name,
          icon: data.icon,
          parent: null,
          translations: data.translations,
          pillarId: data.pillar?.id,
        });
      });
    }
  }, [editId]);

  const handleEdit = (id: string) => {
    setEditId(id);
    setVisible(true);
  };

  const handleDelete = async (id: string) => {
    try {
      await handleDeleteCategory(id);
      refetch();
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const handleDeActivation = async (id: string) => {
    try {
      const isActiveCategory = await handleDeActivationCategory(id);
      customNotification(
        "success",
        isActiveCategory
          ? t("category-activated-successfully")
          : t("category-deactivated-successfully"),
      );
      refetch();
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const handleFilterCallback = async (filtersData: any) => {
    let mergedFilters = cloneObject(filtersData);
    const {
      filters: { or, and = [], search } = {
        or: undefined,
        and: [],
        search: undefined,
      },
    } = mergedFilters;

    mergedFilters.filters = {
      and: [...[parentFilter], ...and],
      or,
      search,
    };

    setPageSize(mergedFilters?.pagination?.offset?.pageSize);
    setCurrentPage(mergedFilters?.pagination?.offset?.page);
    setFiltersData(mergedFilters);
  };

  const showModal = () => {
    setVisible(true);
  };

  const onSubmit = async (
    values: CategoryCreate,
    { resetForm }: FormikHelpers<CategoryCreate>,
  ) => {
    if (!editId) {
      try {
        await handleAddCategory({ create: values });
        refetch();
        resetForm();
        setVisible(false);
        customNotification("success", t("category-added-successfully"));
        return editId;
      } catch (error) {
        handleError(error as string | object);
      }
    } else {
      try {
        await handleUpdateCategory({ id: editId, updateObj: values });
        customNotification("success", t("category-updated-successfully"));
        refetch();
        resetForm();
        setVisible(false);
        setEditId(null);
      } catch (error) {
        handleError(error as string | object);
      }
    }
  };

  const fetchData = () => {
    if (!filtersData?.filters) return;

    fetchDataFromApi(
      getAllCategoriesDataFun,
      setColumnsData,
      filtersData,
    ).catch((e) => handleError(e));
  };

  useEffect(() => {
    setFiltersData({
      filters: {
        and: [parentFilter],
      },
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, [isFetching, filtersData]);
  const { pillarsData } = usePillarIdAndName();

  useEffect(() => {
    if (!filtersData) {
      fetchDataFromApi(getAllCategoriesDataFun, setColumnsData, {
        filters: {
          and: [{ key: "parent", value: null, comparison: Comparison.Equal }],
        },
      }).catch((e) => handleError(e));
    } else {
      fetchDataFromApi(
        getAllCategoriesDataFun,
        setColumnsData,
        filtersData,
      ).catch((e) => handleError(e));
    }
  }, [isFetching]);

  return {
    handleEdit,
    handleDelete,
    handleDeActivation,
    currentPage,
    pageSize,
    handleFilterCallback,
    pillarsData,
    columnsData,
    isLoading,
    setVisible,
    showModal,
    visible,
    editId,
    handleCancel,
    initialValues,
    isCategoryLoading,
    onSubmit,
  };
};
