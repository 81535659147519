import styles from "features/assessment/css/AddLanguage.module.scss";

import { Form, Space, Spin, Typography } from "antd";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import CustomButton from "components/custom-button/CustomButton";
import { customNotification } from "components/custom-notification/CustomNotification";
import AssessmentPillerHeading from "features/assessment/pillar/AssessmentPillerHeading";
import Question from "features/assessment/questions/Question";
import CollapseAble from "features/assessment/questions/collapseables";
import { AssessmentTransformer } from "features/assessment/transformers";
import IAssessmentPillarHash from "features/assessment/types/IAssessmentPillarHash";
import IAssessmentSettings from "features/assessment/types/IAssessmentSettings";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import { Formik } from "formik";
import {
  useDeleteQuestionMutation,
  useGetCategoryListQuery,
  useGetTagListQuery,
  useLazyGetAdminAssessmentQuestionsQuery,
  useUpdateAssessmentMutation,
} from "graphql/_generated/graphql";
import { useErrorHandler } from "hooks/useErrorHandler";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import AssessmentRoutes from "routes/RouteCollections/AssessmentRoutes";
import initialPillarsArray from "../data/initialPillarsArray";
import settingInitialValues from "../data/settingInitialValues";
import { useGetAssessmentSettings } from "../hooks/useGetAssessmentSettings";
import { useToggleTranslation } from "../hooks/useToggleTranslation";

// const initialValuesObj = { description: "" };

/**
 * Create Assessment page component.
 *
 * @returns JSX
 */
const AssessmentLanguage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const params = location.state;

  // const assessmentId = "112454b4-e759-4a60-9086-19df7de306a7";

  const [visible, setVisible] = useState<boolean>(false);

  const [assessmentId, setAssessmentId] = useState<string>(
    params?.assessmentId || "",
  );

  const [tags, setTags] = useState<any>({ isLoading: true, data: [] });

  const [categories, setCategories] = useState<any>({
    isLoading: true,
    data: [],
  });

  const [updateAssessment, { isLoading: updateAssessmentLoading }] =
    useUpdateAssessmentMutation();

  const { handleToggleTranslation } = useToggleTranslation();

  const [deleteAssessmentQuestion] = useDeleteQuestionMutation();

  const { handleGetAssessmentSettings, isLoadingAssessmentSettings } =
    useGetAssessmentSettings();

  const [getAdminAssessmentQuestions, { isLoading }] =
    useLazyGetAdminAssessmentQuestionsQuery();

  const { data: categoriesData, isLoading: categoriesLoading } =
    useGetCategoryListQuery({
      pagination: {
        limit: 1000,
      },
    });

  const { data: tagsData, isLoading: tagsLoading } = useGetTagListQuery({
    pagination: {
      limit: 1000,
    },
  });

  const [settings, updateSettings] =
    useState<IAssessmentSettings>(settingInitialValues);

  const [assessmentPillars, setAssessmentPillars] =
    useState<IAssessmentPillarHash>(initialPillarsArray);

  const [initialValues, setInitialValues] = useState<any>({
    description: "",
    isDraft: false,
    assessmentTypeName: "",
    settings,
    pillars: assessmentPillars,
  });

  const [settingsInitialValues, setSettingsInitialValues] = useState<any>({
    notificationSetting: [],
    deleteNotifications: [],
  });

  const getAssessmentSettings = () => {
    handleGetAssessmentSettings(assessmentId).then((data) => {
      setSettingsInitialValues(data);
    });
  };

  useEffect(() => {
    loadAssessments();
    getAssessmentSettings();
  }, []);

  useEffect(() => {
    if (!categoriesLoading) {
      setCategories({
        data: categoriesData?.getCategoryList?.data,
        isLoading: categoriesLoading,
      });
    }
  }, [categoriesLoading]);

  useEffect(() => {
    if (!tagsLoading) {
      setTags({
        data: tagsData?.getTagList?.data,
        isLoading: tagsLoading,
      });
    }
  }, [tagsLoading]);

  const loadAssessments = async (isRefetch?: boolean) => {
    try {
      const response = await getAdminAssessmentQuestions({
        assessmentId: assessmentId || "",
        language: params?.language || "",
      }).unwrap();

      const transformedData = AssessmentTransformer(response);
      // console.log("settings:>", settings);
      // console.log("transformedData:>", transformedData);

      setAssessmentPillars(transformedData?.pillars);
      setInitialValues({
        ...initialValues,
        pillars: transformedData?.pillars,
        isDraft: transformedData?.settings?.isDraft,
        description: transformedData?.settings?.description,
        assessmentTypeName: transformedData?.settings?.assessmentTypeName || "",
      });
    } catch (error) {}
  };

  const toggleSettings = () => {
    setVisible(!visible);
    setAssessmentId(params.assessmentId);
  };

  // console.log("Assessment Loading:>", isLoading);
  console.log("AssessmentId:>", assessmentId);

  /**
   * To be removed.
   *
   * @param values
   * @param param1
   */
  console.log("updateAssessmentLoading:>", updateAssessmentLoading);

  const publishTranslation = async () => {
    const payload = {
      assessmentId,
      language: params?.language,
      isActive: true,
    };

    try {
      await handleToggleTranslation(payload);
      loadAssessments(true);
      navigate(AssessmentRoutes.AssessmentListRoute.path);

      customNotification(
        "success",
        "Assessment translation published successfully",
      );
    } catch (error: any) {
      handleError(error as string | object);
    }
  };

  return (
    <DashboardLayout className={styles["assessment-create"]}>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize={true}
        initialValues={initialValues}
        // validationSchema={assessmentSchema(checkEnabledThings)}
        onSubmit={(values) => {}}
      >
        {({
          values,
          setFieldValue,
          errors,
          validateOnChange,
          handleSubmit,
        }) => {
          return (
            <Fragment>
              <div className={styles["assessment-create-header"]}>
                <Breadcrumbs
                  data={[
                    {
                      label: t("assessments"),
                    },
                    {
                      label: t("assessment-list"),
                    },
                    {
                      label: `${params?.name} - ${params?.language}`,
                    },
                  ]}
                />

                <Space>
                  <CustomButton
                    className="light-btn"
                    onClick={() =>
                      navigate(
                        `${AssessmentRoutes.AssessmentListRoute.path}#persist`,
                      )
                    }
                  >
                    {t("cancel")}
                  </CustomButton>
                  {/* <CustomButton
                  disabled={updateAssessmentLoading}
                  loading={values.isDraft && updateAssessmentLoading}
                  className="light-btn"
                  onClick={() => saveAssessment(true)}
                >
                  {values.isDraft && updateAssessmentLoading
                    ? t("saving")
                    : t("save-as-draft")}
                </CustomButton> */}
                  <CustomButton
                    loading={!values.isDraft && updateAssessmentLoading}
                    disabled={updateAssessmentLoading}
                    className="primary-btn"
                    onClick={() => publishTranslation()}
                  >
                    {!values.isDraft && updateAssessmentLoading ? (
                      t("publishing")
                    ) : (
                      <span>{t("save-and-publish")}</span>
                    )}
                  </CustomButton>
                </Space>
              </div>

              <Form className={styles["assessment-create-form"]}>
                {/* INFO SECTION */}
                <div className={styles["pillars"]}>
                  {/* <CollapseAble
                  headerKey={"infoSection"}
                  header={<ContainerHeading text={t("description")} />}
                  hideExtra={true}
                  expandIconPosition="end"
                >
                  <CustomTextArea
                    rows={2}
                    name="description"
                    placeholder={t("type-description-here")}
                  />
                </CollapseAble> */}

                  {/* QUESTIONS SECTION */}
                  {Object.values(values?.pillars)?.map(
                    (pillar: any, index: number) => {
                      const totalQuestions = Object.values(
                        pillar.questions ?? {},
                      ).length;

                      return (
                        <CollapseAble
                          className="pillar"
                          key={index}
                          headerKey={index}
                          expandIconPosition="end"
                          hideExtra={true}
                          header={
                            <AssessmentPillerHeading
                              title={pillar.title}
                              icon={pillar.icon}
                              questionsCount={totalQuestions}
                              assessmentType={values?.assessmentTypeName}
                            />
                          }
                        >
                          {Object.values(pillar?.questions ?? {})
                            ?.sort((a: any, b: any) => a?.SN - b?.SN)
                            ?.map((pillarQuestion: any, pqIndex: number) => (
                              <Question
                                {...pillarQuestion}
                                assessmentTypeName={values?.assessmentTypeName}
                                isSubQuestionsEnable={true}
                                values={pillarQuestion}
                                pillarId={pillar.id}
                                assessmentId={assessmentId}
                                tags={tags}
                                categories={categories}
                                key={pqIndex}
                                reFetchAssessments={loadAssessments}
                                isAddLanguage={true}
                                oldRecord={
                                  initialValues?.pillars[pillar.id]?.questions[
                                    pillarQuestion.id
                                  ]
                                }
                                language={params?.language}
                                resetField={setFieldValue}
                                assessmentSettings={
                                  settingsInitialValues?.assessmentSettingId
                                }
                              />
                            ))}
                          {/* ADD QUESTION */}
                          {totalQuestions || isLoading ? (
                            <Spin spinning={isLoading} />
                          ) : (
                            <Typography>{t("no-question-found")}</Typography>
                          )}
                          {/* <AddElement id={pillar.id} handleAdd={null} /> */}
                          {/* {pillar.content} */}
                        </CollapseAble>
                      );
                    },
                  )}
                </div>
              </Form>
            </Fragment>
          );
        }}
      </Formik>
    </DashboardLayout>
  );
};

export default AssessmentLanguage;
