import CustomButton from "components/custom-button/CustomButton";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { messaging, getToken } from "services/firebase/firebase.service";
import CustomPassword from "components/form/custom-text-field/CustomPassword";
import AuthLayout from "features/auth/auth-layout/AuthLayout";
import { setToken } from "features/auth/authSlice";
import styles from "features/auth/css/ResetPassword.module.scss";
import { useResetPassword } from "features/auth/hooks/useResetPassword";
import { useErrorHandler } from "hooks/useErrorHandler";
import { resetPasswordSchema } from "validations/schema";

const ResetPassword = () => {
  // const messaging = getMessaging();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleError } = useErrorHandler();
  const { handleResetPassword, isLoading } = useResetPassword();
  const location = useLocation();
  const stateParams = location.state;

  let { userId } = useParams();

  return (
    <AuthLayout card={true} id="resetPasswordScreen">
      <h1 className={styles["title"]}>{t("new-password")}</h1>
      <p className={styles["description"]}>{t("new-password-description")}</p>
      <Formik
        initialValues={{
          newPassword: "",
          confirmPassword: "",
        }}
        validationSchema={resetPasswordSchema}
        onSubmit={async (values) => {
          try {
            // const deviceToken = await getToken(messaging);

            const { token, refreshToken } = await handleResetPassword({
              id: userId!,
              newPassword: values.newPassword,
              deviceToken: "",
            });

            dispatch(
              setToken({
                token,
                refreshToken,
              }),
            );

            navigate("/password-changed", {
              state: {
                changed: true,
                isResetPassword: stateParams?.isResetPassword,
              },
            });
          } catch (error: any) {
            handleError(error as string | object);
          }
        }}
      >
        <Form>
          <CustomPassword
            type="password"
            name="newPassword"
            label={t("new-password")}
            placeholder={t("new-password")}
            showRules
            hideError
          />
          <CustomTextField
            type="password"
            name="confirmPassword"
            label={t("retype-new-password")}
            placeholder={t("retype-new-password")}
          />
          <div className={styles["btn-div"]}>
            <CustomButton
              loading={isLoading}
              id="resetPassword"
              block
              htmlType="submit"
              className="primary-btn"
            >
              {t("submit-button")}
            </CustomButton>
          </div>
        </Form>
      </Formik>
    </AuthLayout>
  );
};

export default ResetPassword;
