import {
  MutationAddNewSpecialistArgs,
  useAddNewSpecialistMutation,
} from "graphql/_generated/graphql";
import { convertTranslationArrToObj } from "helpers";
export const useAddSpecialist = () => {
  const [addNewSpecialistFun, { isLoading }] = useAddNewSpecialistMutation();

  //this function is responsible for adding the Specialist data
  const handleAddNewSpecialist = async (
    values: MutationAddNewSpecialistArgs,
  ) => {
    try {
      let translations = await convertTranslationArrToObj(
        values.create.translations,
      );

      await addNewSpecialistFun({
        create: { ...values.create, translations },
      }).unwrap();
    } catch (error) {
      throw error;
    }
  };
  return {
    isLoading,
    handleAddNewSpecialist,
  };
};
