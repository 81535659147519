export const CrossBGSvg = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6" cy="6" r="6" fill="#EBEBEB" />
      <path
        d="M6.705 6.00009L8.855 3.85509C8.94915 3.76094 9.00205 3.63324 9.00205 3.50009C9.00205 3.36694 8.94915 3.23924 8.855 3.14509C8.76085 3.05094 8.63315 2.99805 8.5 2.99805C8.36685 2.99805 8.23915 3.05094 8.145 3.14509L6 5.29509L3.855 3.14509C3.76085 3.05094 3.63315 2.99805 3.5 2.99805C3.36685 2.99805 3.23915 3.05094 3.145 3.14509C3.05085 3.23924 2.99795 3.36694 2.99795 3.50009C2.99795 3.63324 3.05085 3.76094 3.145 3.85509L5.295 6.00009L3.145 8.14509C3.09814 8.19157 3.06094 8.24687 3.03555 8.3078C3.01017 8.36873 2.9971 8.43409 2.9971 8.50009C2.9971 8.5661 3.01017 8.63145 3.03555 8.69238C3.06094 8.75331 3.09814 8.80861 3.145 8.85509C3.19148 8.90196 3.24678 8.93915 3.30771 8.96454C3.36864 8.98992 3.43399 9.00299 3.5 9.00299C3.56601 9.00299 3.63136 8.98992 3.69229 8.96454C3.75322 8.93915 3.80852 8.90196 3.855 8.85509L6 6.70509L8.145 8.85509C8.19148 8.90196 8.24678 8.93915 8.30771 8.96454C8.36864 8.98992 8.43399 9.00299 8.5 9.00299C8.56601 9.00299 8.63136 8.98992 8.69229 8.96454C8.75322 8.93915 8.80852 8.90196 8.855 8.85509C8.90186 8.80861 8.93906 8.75331 8.96445 8.69238C8.98983 8.63145 9.0029 8.5661 9.0029 8.50009C9.0029 8.43409 8.98983 8.36873 8.96445 8.3078C8.93906 8.24687 8.90186 8.19157 8.855 8.14509L6.705 6.00009Z"
        fill="#231F20"
      />
    </svg>
  );
};
