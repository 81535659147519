import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import HabitDetail from "features/habits/habit-details/HabitDetail";
import { HabitState } from "features/habits/hooks/useAddHabitViewModel";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

interface HabitDetailsProps {}

const HabitDetails: React.FunctionComponent<HabitDetailsProps> = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const state = location.state as HabitState;
  return (
    <DashboardLayout openKey={["Activity"]}>
      <Breadcrumbs
        data={[
          {
            label: t("activities"),
          },
          {
            label: t("habits"),
          },
          {
            label: `${t("habit-details")} - ${t(state?.langCode)}`,
          },
        ]}
      />
      <HabitDetail state={state} />
    </DashboardLayout>
  );
};

export default HabitDetails;
