export const ContentsSvg = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.58366 11.1659L10.9245 8.99927L8.58366 6.83343V11.1659ZM12.317 9.98185L9.28199 12.7902C9.01616 13.0368 8.67033 13.166 8.31783 13.166C8.12366 13.166 7.92616 13.126 7.74033 13.0452C7.23199 12.8235 6.91699 12.3493 6.91699 11.8077V6.19102C6.91699 5.65018 7.23199 5.17518 7.74033 4.95435C8.26199 4.72518 8.86783 4.82518 9.28283 5.20935L12.317 8.01768C12.5928 8.27185 12.7503 8.63018 12.7503 8.99935C12.7503 9.36935 12.5928 9.72685 12.317 9.98185ZM9.00033 0.666016C4.40533 0.666016 0.666992 4.40518 0.666992 8.99935C0.666992 13.5943 4.40533 17.3327 9.00033 17.3327C13.5953 17.3327 17.3337 13.5943 17.3337 8.99935C17.3337 4.40518 13.5953 0.666016 9.00033 0.666016Z"
        fill="#303030"
      />
    </svg>
  );
};
