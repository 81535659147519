import * as React from "react";
import {
  CopyFilled,
  DeleteFilled,
  EditFilled,
  SaveFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import { CollapseProps, CollapsePanelProps, Space } from "antd";
import CustomCollapse from "components/custom-collapse/CustomCollapse";
import CustomCollapseHeader from "components/custom-collapse/CustomCollapseHeader";
import CustomPopconfirm from "components/form/custom-pop-confirm/CustomPopConfirm";
import { customNotification } from "components/custom-notification/CustomNotification";
import { errorFormat } from "helpers";
import CustomButton from "components/custom-button/CustomButton";
import { useTranslation } from "react-i18next";
import { useErrorHandler } from "hooks/useErrorHandler";

interface ExtraProps {
  handleDelete?: () => any;
  handleCopy?: () => any;
  handleEdit?: () => any;
  handleUpdate?: () => any;
  isEditMode?: boolean;
  isAddLanguage?: boolean;
  isCheckIn?: boolean;
  isUpdating?: boolean;
}

interface CollapseAbleProps extends CollapseProps {
  headerKey: string | number;
  header?: React.ReactNode;
  hideExtra?: boolean;
  extraProps?: ExtraProps;
}

const CollapseAble: React.FunctionComponent<CollapseAbleProps> = (props) => {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();

  const getExtra = (props: ExtraProps) => {
    let deleteElementProps = {
      onClick: () => {
        console.log("Deleted!");
      },
    };

    let copyElementProps = {
      onClick: () => {
        console.log("Copied!");
      },
    };

    let editElementProps = {
      onClick: () => {
        console.log("Edit Enabled!");
      },
    };

    let updateElementProps = {
      onClick: () => {
        console.log("Updated!");
      },
    };

    if (props?.handleDelete)
      deleteElementProps = {
        ...deleteElementProps,
        // onClick: props?.handleDelete,
      };

    if (props?.handleCopy)
      copyElementProps = {
        ...copyElementProps,
        // onClick: props?.handleCopy,
      };

    if (props?.handleEdit)
      editElementProps = {
        ...editElementProps,
        // onClick: props?.handleEdit,
      };

    if (props?.handleUpdate)
      updateElementProps = {
        ...updateElementProps,
        // onClick: props?.handleUpdate,
      };

    return (
      <Space>
        {!props?.isEditMode ? (
          props.handleEdit && (
            <CustomButton
              onClick={(e) => {
                e.stopPropagation();
                props?.handleEdit && props.handleEdit();
              }}
              icon={<EditFilled />}
              className={"primary-btn btn-sm btn-sm"}
            >
              {t("edit-label")}
            </CustomButton>
          )
        ) : (
          <Space>
            {props.handleEdit && (
              <CustomButton
                onClick={(e) => {
                  e.stopPropagation();
                  props?.handleEdit && props.handleEdit();
                }}
                icon={<CloseCircleFilled />}
                className={
                  props?.isUpdating
                    ? "cross-btn btn-sm"
                    : "link-text-btn btn-sm"
                }
                disabled={props?.isUpdating}
              >
                {t("cancel-label")}
              </CustomButton>
            )}
            {props.handleUpdate && (
              <CustomButton
                onClick={(e) => {
                  e.stopPropagation();
                  props?.handleUpdate && props.handleUpdate();
                }}
                icon={<SaveFilled />}
                className={
                  props?.isUpdating ? "cross-btn btn-sm" : "primary-btn btn-sm"
                }
                disabled={props?.isUpdating}
              >
                {props?.isUpdating ? t("saving-label") : t("save-label")}
              </CustomButton>
            )}
          </Space>
        )}

        {props?.isAddLanguage ? null : (
          <>
            {props?.isCheckIn
              ? null
              : props.handleCopy && (
                  <CustomButton
                    onClick={(e) => {
                      e.stopPropagation();
                      props?.handleCopy && props.handleCopy();
                    }}
                    icon={<CopyFilled />}
                    className={
                      props?.isUpdating
                        ? "cross-btn btn-sm"
                        : "light-btn btn-sm"
                    }
                    disabled={props?.isUpdating}
                  >
                    {t("clone-label")}
                  </CustomButton>
                )}

            {props.handleDelete && (
              <CustomPopconfirm
                onConfirm={async () => {
                  if (props?.handleDelete) {
                    try {
                      await props.handleDelete();
                    } catch (error) {
                      handleError(error as string | object);
                    }
                  }
                }}
                title={t("delete-question-confirmation-label")}
              >
                <CustomButton
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  icon={<DeleteFilled />}
                  className={
                    props?.isUpdating ? "cross-btn btn-sm" : "light-btn btn-sm"
                  }
                  disabled={props?.isUpdating}
                >
                  {t("delete-label")}
                </CustomButton>
              </CustomPopconfirm>
            )}
          </>
        )}
      </Space>
    );
  };

  return (
    <CustomCollapse {...props}>
      <CustomCollapseHeader
        key={props.headerKey}
        header={props.header}
        extra={!props?.hideExtra && getExtra({ ...props.extraProps })}
      >
        {props.children}
      </CustomCollapseHeader>
    </CustomCollapse>
  );
};

export default CollapseAble;
