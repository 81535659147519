export const focusOnErrorField = ({errors, isSubmitting, isValid}: any) => {
  if (
    errors &&
    Object.keys(errors)?.length &&
    isSubmitting &&
    !isValid
  ) {
    const firstError = Object.keys(errors)[0];
    const data = document.getElementsByName(firstError)[0];
    const labelTags = document.getElementsByTagName("label");
    if (labelTags?.length) {
      for (let label in labelTags) {
        if (labelTags[label].htmlFor === firstError) {
          labelTags[label].focus();
        }
      }
    }
    if (data) {
      data.focus();
    }
  }
}