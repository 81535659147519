import { Spin } from "antd";
import arrowDownSvg from "assets/images/arrowhead-down.svg";
import arrowUpSvg from "assets/images/arrowhead-up.svg";
import CustomCard from "components/custom-card/CustomCard";
import { PieChart } from "features/company-dashboard/charts/pie/PieChart";
import { FilterValuesProps } from "features/company-dashboard/types";
import { formatPastDaysInsight } from "features/company-dashboard/utils";
import { ChallengeType, UserFilters } from "graphql/_generated/graphql";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import styles from "../../CompanyDashboard.module.scss";
import { InfoTextPopup } from "../InfoTextPopup";
import { NoData } from "../NoData";
import { InfoTextProps } from "../organization-metrices/types";
import { ChallengesStatsDetails } from "./ChallengesStatsDetails";
import { useTotalChallengesViewModel } from "./hooks/useTotalChallengesViewModel";
interface TotalChallengesProps extends InfoTextProps {
  userFilters: UserFilters;
  selectedValues: FilterValuesProps;
  visible: boolean;
}

export const TotalChallenges: FunctionComponent<TotalChallengesProps> = ({
  userFilters,
  selectedValues,
  visible,
  selectedInfoText,
  setSelectedInfoText,
}) => {
  const {
    infoTexts,
    isLoading,
    challengeStatsData,
    mappedChallengesData,
    challengeBarColors,
    pastComparison,
    isDataExists,
    pastTimeLabel,
    getChallengeStats,
  } = useTotalChallengesViewModel({ selectedValues, userFilters });
  const { t } = useTranslation();

  return (
    <CustomCard bordered={false} className={`${styles["section-card"]}`}>
      <div className={styles["assessment-container"]}>
        <div className={styles["section-item-container"]}>
          <div className={styles["item-header"]}>{t("total-challenges")}</div>
          <div>
            <InfoTextPopup
              items={infoTexts}
              visible={visible || selectedInfoText !== ChallengeType.Virtual}
              handleSelectedInfoText={(reset?: boolean) =>
                setSelectedInfoText(reset ? "" : ChallengeType.Virtual)
              }
            />
          </div>
        </div>
        {!isLoading &&
        mappedChallengesData?.length &&
        !challengeStatsData?.isDataConcealed &&
        isDataExists ? (
          <>
            <div className={styles["total-challenges-container"]}>
              <div className={styles["challenges-pie-chart-container"]}>
                <PieChart
                  pieChartConfig={{
                    data: mappedChallengesData,
                    colors: challengeBarColors,
                    innerRadius: 40,
                    outerRadius: 105,
                  }}
                />
              </div>
              {mappedChallengesData?.length ? (
                <ChallengesStatsDetails
                  challengeBarColors={challengeBarColors}
                  challengesData={{
                    activeEmployees: challengeStatsData?.enrolledEmployees,
                    inActiveEmployees:
                      challengeStatsData?.totalEmployees -
                      challengeStatsData?.enrolledEmployees,
                    stats: mappedChallengesData,
                    totalEmployees: challengeStatsData?.totalEmployees,
                    totalEnrolledChallenges:
                      challengeStatsData?.totalEnrolledChallenges,
                  }}
                />
              ) : null}
            </div>
            <div className={styles["percentange-container"]}>
              <img
                className={styles["no-data-img"]}
                src={pastComparison >= 0 ? arrowUpSvg : arrowDownSvg}
                alt="No Data"
              />
              <div className={`${styles["past-value-comparison"]}`}>
                {`${challengeStatsData?.enrolledEmployees?.toLocaleString()} ${t(
                  "employee",
                )}${challengeStatsData?.enrolledEmployees > 1 ? "s" : ""} ${t(
                  "accepted-challenge",
                )}${challengeStatsData?.enrolledEmployees > 1 ? "s" : ""}${
                  challengeStatsData?.pastEnrolledEmployees
                    ? ` , ${t("its")}`
                    : "."
                }`}
              </div>
              {challengeStatsData?.pastEnrolledEmployees ? (
                <>
                  <div
                    className={`${styles["past-value-comparison"]} ${
                      styles["pillar-insights-margin"]
                    } ${
                      true ? styles["past-value-up"] : styles["past-value-down"]
                    }`}
                  >
                    {`${Math.abs(pastComparison)}%`}
                  </div>
                  <div className={styles["past-value-comparison"]}>
                    {`${pastComparison > 0 ? t("more") : t("less")} ${t(
                      "than-previous",
                    )} ${pastTimeLabel?.description}`}
                  </div>
                </>
              ) : null}
            </div>
            {selectedValues.pastDays ? (
              <div className={styles["percentange-container"]}>
                <div className={styles["checkin-past-days"]}>
                  {`${pastTimeLabel?.label}: ${formatPastDaysInsight(
                    selectedValues,
                  )}`}
                </div>
              </div>
            ) : null}
          </>
        ) : isLoading ? (
          <div className={styles["loading-container"]}>
            <Spin spinning={isLoading} />
          </div>
        ) : (
          <NoData
            handleClick={getChallengeStats}
            btnText={t("refresh")}
            title={
              challengeStatsData?.isDataConcealed && isDataExists
                ? t("data-concealed-for-privacy")
                : `${t("not-enough-data-for")} ${pastTimeLabel?.description}`
            }
          />
        )}
      </div>
    </CustomCard>
  );
};
