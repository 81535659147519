import { Tree } from "antd";
import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { CustomTextArea } from "components/form/custom-text-area/CustomTextArea";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import TranslationFieldArr from "components/translation-field-array/TranslationFieldArr";
import { Form, Formik, FormikHelpers } from "formik";
import { GetAllPermissionsQuery, RoleCreate } from "graphql/_generated/graphql";
import React, { Key } from "react";
import { useTranslation } from "react-i18next";
import { rolesSchema } from "validations/schema";
import styles from "./css/Permissions.module.scss";

interface AddRoleProps {
  visible: boolean;
  editId: string | null;
  initialValues: Partial<RoleCreate>;
  handleCancel: () => void;
  handleSubmit: (
    values: Partial<RoleCreate>,
    { resetForm }: FormikHelpers<Partial<RoleCreate>>,
  ) => void;
  permissionsInitialValues: Key[];
  permissionsDataArr: GetAllPermissionsQuery | undefined;
  ALL_PERMS: string;
  isLoading: boolean;
  showPermissionsError: boolean;
  onCheck: (
    checked:
      | {
          checked: Key[];
          halfChecked: Key[];
        }
      | Key[],
    info: any,
  ) => void;
}

type TreeNode = {
  key: string;
  label: string;
  children: TreeNode[] | undefined;
};

const AddRole: React.FunctionComponent<AddRoleProps> = ({
  visible,
  editId,
  initialValues,
  handleCancel,
  handleSubmit,
  permissionsInitialValues,
  permissionsDataArr,
  ALL_PERMS,
  isLoading,
  showPermissionsError,
  onCheck,
}) => {
  const { t } = useTranslation();

  return (
    <CustomModal
      width={600}
      title={editId ? t("edit-role") : t("add-new-role")}
      footer={null}
      visible={visible}
      onCancel={handleCancel}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={rolesSchema}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form>
            <CustomTextField
              name="name"
              label={t("name")}
              placeholder={t("enter-name")}
            />
            <CustomTextArea
              label={t("description")}
              name="description"
              rows={5}
              placeholder={t("enter-description")}
            />
            <TranslationFieldArr values={values} attribute="name" />
            <div className={styles["mb"]}>
              <Tree
                showLine
                checkable
                onCheck={onCheck}
                checkedKeys={permissionsInitialValues}
                defaultExpandedKeys={[ALL_PERMS]}
                treeData={
                  [
                    {
                      key: ALL_PERMS,
                      label: t("all-permissions"),
                      children: permissionsDataArr?.getAllPermissions,
                    },
                  ] as TreeNode[]
                }
                fieldNames={{ title: "label", key: "key" }}
              />
              {showPermissionsError && (
                <div className={styles["error"]}>
                  {t("at-least-one-permission-required")}
                </div>
              )}
            </div>
            <ModalFooter
              saveText={t("save-role")}
              onCancel={handleCancel}
              loading={isLoading}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddRole;
