import * as Yup from "yup";
import * as Validations from "validations/schema/common/validations";

const eventCommonSchema = Yup.object().shape({
  title: Validations.required("title"),
  description: Validations.required("description"),
  location: Validations.required("location"),
});

export const eventTranslationsSchema = Yup.object().shape({
  ...eventCommonSchema.fields,
});

export const eventSchema = Yup.object().shape({
  image: Validations.image("image"),
  companyIds: Yup.array()
    .min(1, "error:at-least-one-company-required")
    .required("error:company-required"),
  startDate: Yup.date()
    .min(new Date(), "error:start-date-not-in-past")
    .required("error:start-date-required")
    .nullable(),
  expiryDate: Yup.date()
    .min(Yup.ref("startDate"), "error:expiry-date-cannot-before-start-date")
    .required("error:expiry-date-required")
    .nullable(),
  leaderboardType: Validations.required("leaderboardType"),
  teams: Yup.array().when("leaderboardType", {
    is: "Team",
    then: Yup.array().of(
      Yup.object().shape({
        title: Validations.requiredFieldWithoutSpaces("title"),
        color: Validations.required("color"),
      }),
    ),
  }),
  ...eventCommonSchema.fields,
});

export const eventUpdateSchema = Yup.object().shape({
  image: Validations.image("image"),
  leaderboardType: Validations.required("leaderboardType"),
  companyIds: Yup.array()
    .min(1, "error:at-least-one-company-required")
    .required("error:company-required"),
  ...eventCommonSchema.fields,
});
