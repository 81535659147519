import { Space } from "antd";
import { PlusCircleFilled } from "@ant-design/icons";
import AddOption from "components/custom-button/AddOption";
import CustomButton from "components/custom-button/CustomButton";
import DeleteBtn from "components/custom-button/DeleteBtn";
import EmojiPicker from "components/form/custom-emoji-picker/EmojiPicker";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { FieldArray } from "formik";
import * as React from "react";
import { InitialValueTypesOfAssessment } from "features/playground/types";
import ICoreFields from "features/assessment/types/ICoreFields";

import styles from "./SliderOptions.module.scss";
import { useTranslation } from "react-i18next";

interface IAppProps extends ICoreFields {
  tags: any;
  values: any;
  namePrefix?: string;
  addStep: (key: string) => void;
  deleteStep: (key: string, optionId: string) => void;
  isDisabled?: boolean;
  isSubQuestion?: boolean;
  isAddLanguage?: boolean;
  oldRecord?: any;
  rangeError?: string;
  assessmentSettings?: any;
}

const App: React.FunctionComponent<IAppProps> = (props) => {
  const { values } = props;
  const { t } = useTranslation();
  const namePrefix = `${props?.namePrefix ? props?.namePrefix + "." : ""}`;
  // console.log("rangeError:>", props?.rangeError);
  return (
    <>
      <div className={styles["slider-header"]}>
        <div>{t("overall-range")}</div>
        <Space>
          <Space align="baseline">
            <div>{t("start")}</div>
            <CustomTextField
              name={`${namePrefix}start`}
              placeholder={t("start")}
              type="number"
              disabled={props?.isDisabled || props?.isAddLanguage}
            />
          </Space>
          <Space align="baseline">
            <div>{t("end")}</div>
            <CustomTextField
              name={`${namePrefix}end`}
              placeholder={t("end")}
              type="number"
              disabled={props?.isDisabled || props?.isAddLanguage}
            />
          </Space>
        </Space>
      </div>

      <FieldArray
        name="steps"
        render={() => (
          <>
            {values?.steps?.map((step: any, index: number) => {
              return (
                <div
                  key={step.id}
                  className={
                    props?.assessmentSettings?.isTagsEnable &&
                    props?.assessmentSettings?.isScoresEnable
                      ? values.isEmojiEnable
                        ? styles["rating-option"]
                        : styles["rating-header"]
                      : styles["option-filter"]
                  }
                >
                  <div>
                    {t("step")}&nbsp;{index + 1}
                  </div>
                  {props?.assessmentSettings?.isScoresEnable ? (
                    <CustomTextField
                      name={`${namePrefix}steps[${index}].score`}
                      placeholder={t("score")}
                      type="number"
                      disabled={props?.isDisabled || props?.isAddLanguage}
                    />
                  ) : null}
                  {values.isEmojiEnable && (
                    <div className={styles["emojiandtext"]}>
                      <EmojiPicker
                        name={`${namePrefix}steps[${index}].emoji`}
                        isDisabled={props?.isDisabled || props?.isAddLanguage}
                      />
                      <CustomTextField
                        name={`${namePrefix}steps[${index}].text`}
                        placeholder={t("enter-title")}
                        disabled={props?.isDisabled}
                        isAddLanguage={props?.isAddLanguage}
                        oldTextValue={props?.oldRecord?.steps[index]?.text}
                      />
                    </div>
                  )}
                  {props?.assessmentSettings?.isTagsEnable ? (
                    <CustomSelectField
                      maxTagCount="responsive"
                      name={`${namePrefix}steps[${index}].tags`}
                      mode="multiple"
                      options={props?.tags?.data}
                      disabled={
                        props?.tags?.isLoading ||
                        props?.isDisabled ||
                        props?.isAddLanguage
                      }
                      placeholder={t("select-tags")}
                    />
                  ) : null}
                  <div className={styles["inner-flex"]}>
                    <div>{t("step-range")}</div>
                    <Space>
                      <Space className={styles["timeLable"]} align="baseline">
                        <div>{t("start")}</div>
                        <CustomTextField
                          name={`${namePrefix}steps[${index}].start`}
                          placeholder={t("start")}
                          type="number"
                          disabled={props?.isDisabled || props?.isAddLanguage}
                        />
                      </Space>
                      <Space align="baseline" className={styles["timeLable"]}>
                        <div>{t("end")}</div>
                        <CustomTextField
                          name={`${namePrefix}steps[${index}].end`}
                          placeholder={t("end")}
                          type="number"
                          disabled={props?.isDisabled || props?.isAddLanguage}
                        />
                      </Space>
                    </Space>
                  </div>{" "}
                  <DeleteBtn
                    disabled={props?.isDisabled || props?.isAddLanguage}
                    onClick={() => props?.deleteStep("steps", step.id)}
                  />
                </div>
              );
            })}

            {!!props.rangeError && (
              <div className={styles["range-error"]}>{props?.rangeError}</div>
            )}
            {props?.isAddLanguage ? null : (
              <AddOption
                disabled={props?.isDisabled}
                title={t("add-range-step")}
                onClick={() => props?.addStep("steps")}
              />
            )}
          </>
        )}
      />
    </>
  );
};

export default App;
