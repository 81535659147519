import { useEffect, useState } from "react";
import { useIndividualLeaderboard } from "./useIndividualLeaderboard";
import { useErrorHandler } from "hooks/useErrorHandler";
import {
  EventParticipantsScoreInput,
  GetIndividualLeaderboardQuery,
  UpdateIndividualParticipantsScoreMutationVariables,
} from "graphql/_generated/graphql";
import { FormikHelpers } from "formik";
import customNotification from "components/custom-notification/CustomNotification";
import { useTranslation } from "react-i18next";
export type IndividualLeaderboardState = {
  eventId: string;
};
export const useIndividualLeaderboardViewModel = (
  state: IndividualLeaderboardState,
) => {
  const { eventId } = state ?? {};

  //States
  const [individualVisibility, setIndividualVisibility] = useState(false);
  const [leaderboardData, setLeaderBoardData] =
    useState<GetIndividualLeaderboardQuery["getIndividualLeaderboard"]>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pagination, setPagination] = useState({
    limit: 10,
    offset: 0,
  });

  //Hooks
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const {
    handleGetIndividualLeaderboard,
    handleUpdateIndividualScore,
    isLoading,
  } = useIndividualLeaderboard();

  const onPressEditIndividualScore = () => {
    setIndividualVisibility(!individualVisibility);
  };

  const onChangePagination = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPagination({
      offset: (page - 1) * pageSize,
      limit: pageSize,
    });
  };

  const fetchIndividualLeaderboardData = async () => {
    try {
      if (eventId) {
        const data = await handleGetIndividualLeaderboard({
          data: {
            eventId: eventId,
          },
          pagination: pagination,
        });
        setLeaderBoardData(data);
      }
    } catch (error) {
      handleError(error as string | object);
    }
  };

  useEffect(() => {
    fetchIndividualLeaderboardData();
  }, [eventId, pagination, currentPage]);

  const onUpdateScoreSubmit = async (
    values: EventParticipantsScoreInput,
    formikHelpers?: FormikHelpers<typeof values>,
  ) => {
    try {
      const res = await handleUpdateIndividualScore({ update: values });
      fetchIndividualLeaderboardData();
      formikHelpers?.resetForm();
      customNotification("success", t("score-update-successfully"));
      onPressEditIndividualScore();
      return res;
    } catch (error) {
      handleError(error as string | object);
    }
  };

  return {
    leaderboardData,
    currentPage,
    isLoading,
    individualVisibility,
    onPressEditIndividualScore,
    onChangePagination,
    onUpdateScoreSubmit,
  };
};
