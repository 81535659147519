import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import styles from "components/custom-confirm-modal/CustomConfirmModal.module.scss";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";

interface CustomConfirmModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit?: () => void;
  title?: string;
  desc?: string;
  cancelText?: string;
  saveText?: string;
  loading?: boolean;
}

const CustomConfirmModal: React.FunctionComponent<CustomConfirmModalProps> = ({
  setVisible,
  visible,
  onSubmit,
  title,
  desc,
  cancelText = "No",
  saveText = "Yes, Confirm",
  loading,
}) => {
  const handleCancel = () => {
    setVisible(false);
  };

  const { t } = useTranslation();

  return (
    <CustomModal
      centered
      width={506}
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      title={title ?? t("warning")}
    >
      <Typography>{desc}</Typography>

      <ModalFooter
        saveText={saveText}
        onSubmit={onSubmit}
        onCancel={handleCancel}
        className={styles["confirm-modal"]}
        cancelText={cancelText}
        loading={loading}
      />
    </CustomModal>
  );
};

export default CustomConfirmModal;
