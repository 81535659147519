import closeErrorSvg from "assets/images/minus-square.svg";
import openErrorSvg from "assets/images/plus-square.svg";
import CustomDivider from "components/custom-divider/CustomDivider";
import styles from "features/users/css/InviteMultipleUsers.module.scss";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useErrorTranslation } from "validations/schema/common/useErrorTranslation";

export const HandleErrors = ({ errors }: { errors: any }) => {
  const { errorT } = useErrorTranslation();
  const { t } = useTranslation();
  const [selectedError, setSelectedError] = useState<any>();
  if (!errors) return null;

  const detectedErrors = errors.reduce(
    (msgArray: any, error: any, index: number) => {
      if (error) {
        const errorFields = Object.keys(error);
        const errorMessages = errorFields.map(
          (field: any) => `${errorT(error[field])} ${t("at-row")} ${index + 1}`,
        );
        msgArray = [...msgArray, { row: index + 1, errors: errorMessages }];
      }
      return msgArray;
    },
    [],
  );

  return (
    <div style={{ color: "red" }}>
      {detectedErrors?.map((item: any) => (
        <>
          <div
            className={`cursor-pointer ${styles["collapse-container"]} ${styles["detected-errors-spacing"]}`}
            onClick={() =>
              setSelectedError(selectedError?.row === item.row ? null : item)
            }
          >
            <img
              src={
                selectedError?.row === item.row ? closeErrorSvg : openErrorSvg
              }
            />
            <div className={styles["error-content-spacing"]}>{`${t("row")}${
              item.row
            }`}</div>
          </div>
          {selectedError?.row === item.row ? (
            <div className={styles["error-desc-spacing"]}>
              {item.errors?.map((item: any) => (
                <div>{item}</div>
              ))}
            </div>
          ) : null}

          <CustomDivider className={styles["detected-errors-spacing"]} />
        </>
      ))}
    </div>
  );
};
