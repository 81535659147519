import { Login, useSignInMutation } from "graphql/_generated/graphql";

export const useSignIn = () => {
  const [signInFun, { isLoading }] = useSignInMutation();

  //this function is responsible for login all the users
  const handleSignIn = async (values: Login) => {
    const params = {
      credentials: { forAdmin: true, ...values },
    };

    const res = await signInFun(params).unwrap();

    return res;
  };

  return { isLoading, handleSignIn };
};
