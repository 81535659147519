import { Pagination, Space, Input, Select } from "antd";
import Search from "antd/lib/input/Search";
import CustomLoader from "components/custom-loader/CustomLoader";
import CustomModal from "components/custom-modal/CustomModal";
import { useLazyGetParticipatingCompaniesQuery } from "graphql/_generated/graphql";
import React, { useEffect, useState } from "react";
import ChallengeDataTable from "./ChallengeDataTable";
import styles from "features/challenge/css/ActivityDetail.module.scss";
import Icon from "@ant-design/icons";
import { SearchIcon } from "assets/icons/SearchIcon";
import { debounce } from "lodash";
import DataTableFilters from "./DataTableFilters";
const { Option } = Select;

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  challengeId?: string;
};

const AllParticipatonCompanies: React.FC<Props> = ({
  visible,
  setVisible,
  challengeId,
}: any) => {
  const handleCancel = () => {
    setVisible(false);
  };

  const [data, setData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>("");
  const [pagination, setPagination] = useState<any>({
    limit: 10,
    offset: 0,
  });

  const [getParticipatingCompaniesFun, { isLoading }] =
    useLazyGetParticipatingCompaniesQuery();

  useEffect(() => {
    fetchCompaniesData();
  }, [currentPage, pagination, searchText]);

  const fetchCompaniesData = async () => {
    const { getParticipatingCompanies } = await getParticipatingCompaniesFun({
      entityId: challengeId,
      pagination,
      search: searchText,
    }).unwrap();
    setData(getParticipatingCompanies);
  };

  const handleSearchInput = (value: string) => {
    setSearchText(value);
    setPagination({
      limit: 10,
      offset: 0,
    });
  };

  return (
    <CustomModal
      width={720}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      title={`Participating Companies (${data?.metadata?.total || ""})`}
    >
      <DataTableFilters
        isCompanyFilter={false}
        handleSearchInput={handleSearchInput}
      />

      {isLoading ? (
        <CustomLoader />
      ) : (
        <>
          <ChallengeDataTable
            data={
              data?.data?.length
                ? data.data.map((item: any) => ({
                    userName: item.name,
                    userImage: item.image,
                  }))
                : []
            }
            noSteps={true}
            isModalVisible={true}
          />
          <div className={"pagination-margin"}>
            <Pagination
              onChange={(page, pageSize) => {
                setCurrentPage(page);
                setPagination({
                  offset: (page - 1) * pageSize,
                  limit: pageSize,
                });
              }}
              current={currentPage}
              pageSize={pagination?.limit}
              showTotal={(total, range) => (
                <span>
                  Showing&nbsp;
                  <b>
                    {range[0]}-{range[1]}
                  </b>
                  &nbsp;of <b>{total}</b>&nbsp; items
                </span>
              )}
              total={data?.metadata?.total || 0}
            />
          </div>
        </>
      )}
    </CustomModal>
  );
};

export default AllParticipatonCompanies;
