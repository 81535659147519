import CheckPermissions from "components/check-permissions/CheckPermissions";
import CustomButton from "components/custom-button/CustomButton";
import { PERMS } from "helpers";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import AddBadge from "features/badges/add-badge/AddBadge";
import BadgesList from "features/badges/badges-list/BadgesList";
import styles from "features/badges/badges/Badges.module.scss";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
interface BadgesProps {}

const Badges: React.FunctionComponent<BadgesProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <DashboardLayout openKey={["Activity"]}>
        <Breadcrumbs
          data={[
            {
              label: "Activities",
            },
            {
              label: t("badges-title"),
            },
          ]}
        />
        <BadgesList />
      </DashboardLayout>
    </>
  );
};

export default Badges;
