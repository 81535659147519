import { MoreOutlined } from "@ant-design/icons";
import { Dropdown, Switch, Tag } from "antd";
import type { ColumnsType } from "antd/lib/table";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import CustomButton from "components/custom-button/CustomButton";
import CustomMenu from "components/custom-menu/CustomMenu";
import CustomTable from "components/custom-table/CustomTable";
import CustomPopconfirm from "components/form/custom-pop-confirm/CustomPopConfirm";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import { useFAQsListViewModel } from "features/faqs/hooks/useFAQsListViewModel";
import { FaqList as FaqListType } from "graphql/_generated/graphql";
import { PERMS } from "helpers";
import moment from "moment";
import { useTranslation } from "react-i18next";
import AddQuestion from "../../features/faqs/add-faq/AddFAQ";

const FAQsList = () => {
  const { t } = useTranslation();

  const vm = useFAQsListViewModel();
  //TODO: FAQs list column, column type change
  const columns: ColumnsType<FaqListType["data"][0]> = [
    {
      title: t("id"),
      key: "id",
      sorter: {
        multiple: 1,
      },
      render: (_text, _record, index) =>
        (vm.currentPage - 1) * vm.pageSize + index + 1,
    },
    {
      title: t("question-title"),
      dataIndex: "question",
      key: "question",
      sorter: {
        multiple: 1,
      },
      render: (text, record, index) => text,
    },
    {
      title: t("created-at"),
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: {
        multiple: 1,
      },
      render: (createdAt: string) =>
        moment(createdAt).format("MMMM Do YYYY, h:mm:ss a"),
    },
    {
      title: t("updated-at"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: {
        multiple: 1,
      },
      render: (updatedAt: string) =>
        moment(updatedAt).format("MMMM Do YYYY, h:mm:ss a"),
    },
    {
      title: t("status"),
      dataIndex: "isActive",
      key: "isActive",
      align: "center" as "center",
      render: (isActive: boolean) =>
        isActive ? (
          <Tag className="active-tag">{t("active")}</Tag>
        ) : (
          <Tag className="de-active-tag">{t("deactivated")}</Tag>
        ),
    },
    {
      align: "right" as "right",
      title: t("activation"),
      dataIndex: "id",
      key: "id",
      render: (id: string, { isActive }) => (
        <CheckPermissions permission={PERMS.TOGGLE_AVATAR_ACTIVATION}>
          <CustomPopconfirm
            onConfirm={() => vm.handleToggleFaq(id)}
            title={
              isActive ? t("faq.confirm-deactivate") : t("faq.confirm-activate")
            }
          >
            <Switch
              className="custom-switch"
              defaultChecked
              checked={isActive}
            />
          </CustomPopconfirm>
        </CheckPermissions>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      align: "center" as "center",
      render: (id: string) => (
        <Dropdown
          overlay={
            <CustomMenu
              items={[{ label: t("faq.edit-question"), key: "edit-question" }]}
              onClick={({ key }) => vm.handleMenu({ key, id })}
            />
          }
        >
          <CustomButton type="link" className="link-btn">
            <MoreOutlined />
          </CustomButton>
        </Dropdown>
      ),
    },
  ];

  return (
    <DashboardLayout openKey={["MobileSettings"]}>
      <Breadcrumbs
        data={[
          {
            label: "Mobile Settings",
          },
          {
            label: t("faqs"),
          },
        ]}
      />
      <CustomTable
        filters={{
          handleCallback: vm.handleFiltersCallback,
          date: [
            {
              applyAs: "and",
              key: "createdAt",
              comparison: "Range",
            },
          ],
          status: {
            applyAs: "and",
            key: "isActive",
            comparison: "Equal",
          },
        }}
        rowKey="id"
        totalItems={vm.columnsData?.metadata.total}
        loading={vm.isLoading}
        columns={columns}
        onClick={() => vm.setVisible(true)}
        headerTitle={t("faqs")}
        btnText={t("faqs.add-question")}
        dataSource={vm.columnsData?.data}
      />
      <AddQuestion
        visible={vm.visible}
        editId={vm.editId}
        handleCancel={vm.handleCancel}
        handleSubmit={vm.handleSubmit}
        isLoading={vm.isFaqLoading}
        initialValues={vm.initialValues}
      />
    </DashboardLayout>
  );
};

export default FAQsList;
