import { List } from "antd";
import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { Form, Formik, FormikHelpers } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styles from "features/events/css/TeamLeaderBoard.module.scss";
import EditTeamItemScore from "./EditTeamScoreItem";
import {
  EventParticipantsScoreInput,
  GetTeamLeaderboardQuery,
} from "graphql/_generated/graphql";
import CustomPagination from "components/custom-pagination/CustomPagination";
import { participantsScoreSchema } from "validations/schema";

interface EditTeamScoresProps {
  editScoreVisibility: boolean;
  onCloseEditTeamScore: () => void;
  TeamsData?: GetTeamLeaderboardQuery["getTeamLeaderboard"];
  loading: boolean;
  onChangePagination: (page: number, pageSize: number) => void;
  currentPage: number;
  onSubmitUpdateTeamScore: (
    values: EventParticipantsScoreInput,
    formikHelpers?: FormikHelpers<EventParticipantsScoreInput> | undefined,
  ) => Promise<boolean | undefined>;
}

const EditTeamScores: React.FunctionComponent<EditTeamScoresProps> = ({
  editScoreVisibility,
  TeamsData,
  loading,
  currentPage,
  onCloseEditTeamScore,
  onChangePagination,
  onSubmitUpdateTeamScore,
}) => {
  const { t } = useTranslation();
  return (
    <CustomModal
      centered
      width={720}
      visible={editScoreVisibility}
      onCancel={onCloseEditTeamScore}
      footer={null}
      title={t("team-management")}
    >
      <Formik
        enableReinitialize
        validationSchema={participantsScoreSchema}
        initialValues={{
          data: TeamsData?.data?.map((item) => ({
            participantId: item.id ?? "",
            score: item.eventScore ?? 0,
          })) ?? [
            {
              participantId: "",
              score: 0,
            },
          ],
        }}
        onSubmit={onSubmitUpdateTeamScore}
      >
        {({ values, errors }) => (
          <Form>
            <div
              className={`${styles["leaderboard-container"]} ${styles["mb-1"]} `}
            >
              <List
                loading={loading}
                size="small"
                dataSource={TeamsData?.data}
                renderItem={(item, index) => (
                  <EditTeamItemScore
                    itemKey={Number(item.rank)}
                    id={item.id}
                    teamTitle={item.title}
                    color={item.color}
                    members={Number(item.memberCount)}
                    error={!!(errors && errors.data && errors.data[index])}
                  />
                )}
              />
            </div>
            <div className={styles["mb-1"]}>
              <CustomPagination
                responsive
                showSizeChanger
                total={TeamsData?.total}
                onChange={onChangePagination}
                current={currentPage}
              />
            </div>
            <ModalFooter saveText={t("save")} onCancel={onCloseEditTeamScore} />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default EditTeamScores;
