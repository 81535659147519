import * as Yup from "yup";
import * as Validations from "validations/schema/common/validations";

export const metricSchema = Yup.object().shape({
  name: Validations.requiredFieldWithoutSpaces("name"),
  initialValue: Validations.requiredFieldPositiveNumber("initial-value")
    .min(0, "error:positive-integer")
    .max(100, "max-100-chars"),
  metricSign: Validations.required("metric-sign"),
  rangeId: Validations.required("range"),
  tags: Yup.array().of(
    Yup.object().shape({
      tagId: Validations.required("tag"),
      factor: Validations.requiredFieldPositiveNumber("factor")
        .min(0, "error:positive-integer")
        .max(100, "max-100-chars"),
      effectSign: Validations.required("effect-sign"),
    })
  ),
});
