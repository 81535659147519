import React from "react";

export const CalendarSvg = React.forwardRef((props, ref) => {
  return (
    <div
      style={{
        position: "absolute",
        left: "10px",
        top: "55%",
        zIndex: 1,
        transform: "translateY(-45%)",
      }}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Icon">
          <path
            id="Icon_2"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.6667 7.33337H3.33333V4.66671C3.33333 4.29937 3.63267 4.00004 4 4.00004H4.66667V4.66671C4.66667 5.03337 4.96667 5.33337 5.33333 5.33337C5.7 5.33337 6 5.03337 6 4.66671V4.00004H10V4.66671C10 5.03337 10.3 5.33337 10.6667 5.33337C11.0333 5.33337 11.3333 5.03337 11.3333 4.66671V4.00004H12C12.3673 4.00004 12.6667 4.29937 12.6667 4.66671V7.33337ZM10.6667 11.3334H8C7.63333 11.3334 7.33333 11.0334 7.33333 10.6667C7.33333 10.3 7.63333 10 8 10H10.6667C11.0333 10 11.3333 10.3 11.3333 10.6667C11.3333 11.0334 11.0333 11.3334 10.6667 11.3334ZM5.33333 11.3334C4.96667 11.3334 4.66667 11.0334 4.66667 10.6667C4.66667 10.3 4.96667 10 5.33333 10C5.7 10 6 10.3 6 10.6667C6 11.0334 5.7 11.3334 5.33333 11.3334ZM12 2.66671H11.3333V2.00004C11.3333 1.63337 11.0333 1.33337 10.6667 1.33337C10.3 1.33337 10 1.63337 10 2.00004V2.66671H6V2.00004C6 1.63337 5.7 1.33337 5.33333 1.33337C4.96667 1.33337 4.66667 1.63337 4.66667 2.00004V2.66671H4C2.89733 2.66671 2 3.56404 2 4.66671V12.6667C2 13.7694 2.89733 14.6667 4 14.6667H12C13.1027 14.6667 14 13.7694 14 12.6667V4.66671C14 3.56404 13.1027 2.66671 12 2.66671Z"
            fill="#121212"
          />
        </g>
      </svg>
    </div>
  );
});
