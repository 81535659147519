import CustomButton from "components/custom-button/CustomButton";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import { useState } from "react";
import AddUnit from "features/units/AddUnit";
import UnitsList from "features/units/UnitsList";
import styles from "features/units/css/Units.module.scss";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
type Props = {};

export default function Units({}: Props) {
  const { t } = useTranslation();

  return (
    <>
      <DashboardLayout openKey={["Configs"]}>
        <Breadcrumbs
          data={[
            {
              label: "Configurations",
            },
            {
              label: t("units.title"),
            },
          ]}
        />
        <UnitsList />
      </DashboardLayout>
    </>
  );
}
