import { TypeAddPillarFun, useAddPillar } from "./add-pillar/useAddPillar";

import {
  TypeHandleFetchEditPillarDataFun,
  TypeHandleUpdatePillarFun,
  useUpdatePillar,
} from "./add-pillar/useUpdatePillar";

//define the type of usePillar hook
type usePillar = () => {
  isLoading: boolean;
  handleAddPillar: TypeAddPillarFun;
  handleUpdatePillar: TypeHandleUpdatePillarFun;
  handleFetchEditPillarData: TypeHandleFetchEditPillarDataFun;
};

export const usePillar = () => {
  const { handleAddPillar, isLoading } = useAddPillar();
  const {
    handleFetchEditPillarData,
    handleUpdatePillar,
    isLoading: updatePillarLoading,
  } = useUpdatePillar();

  return {
    isLoading: isLoading || updatePillarLoading,
    handleAddPillar,
    handleUpdatePillar,
    handleFetchEditPillarData,
  };
};
