import Icon from "@ant-design/icons";
import { CalendarIcon } from "assets/icons/CalendarIcon";
import { ErrorMessage } from "formik";
import { DatePicker, DatePickerProps } from "formik-antd";
import { useErrorTranslation } from "validations/schema/common/useErrorTranslation";
import styles from "./CustomDatePicker.module.scss";
import { Space } from "antd";

type Props = DatePickerProps & {
  label?: string;
  subLabel?: string;
  required?: boolean;
};

export function CustomDatePicker(props: Props) {
  const { errorT } = useErrorTranslation();
  const { name, label, placeholder, className, subLabel, required, ...rest } =
    props;

  return (
    <div className={styles["form-control"]}>
      {(label || required) && (
        <Space size={2}>
          {required && (
            <span className={`${styles["label"]} ${styles["text-danger"]} `}>
              &#42;
            </span>
          )}
          {label && (
            <label className={styles["label"]} htmlFor={name}>
              {label}
            </label>
          )}
        </Space>
      )}

      {subLabel && (
        <label className={styles["subLabel"]} htmlFor={name}>
          {subLabel}
        </label>
      )}

      <DatePicker
        name={name}
        id={name}
        placeholder={placeholder || ""}
        className={className ? className : "custom-date"}
        suffixIcon={<Icon component={CalendarIcon} />}
        {...rest}
      />
      <ErrorMessage
        name={name}
        render={(msg) => <div style={{ color: "red" }}>{errorT(msg)}</div>}
      />
    </div>
  );
}
