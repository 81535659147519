import { useAssignToTeamsAutomatically } from "./useAssignToTeamAutomatically";
import { useAssignToTeamsManually } from "./useAssignToTeamsManually";
import { useGetTeamLeaderboard } from "./useGetTeamLeaderboard";
import { useRemoveTeamMember } from "./useRemoveTeamMember";
import { useUpdateTeamScore } from "./useUpdateTeamScore";

export const useTeamLeaderboard = () => {
  const { handleGetTeamLeaderboard, isLoadingTeamLeaderboard } =
    useGetTeamLeaderboard();

  const { handleUpdateTeamScore, isLoadingUpdateTeamScore } =
    useUpdateTeamScore();

  const { handleAssignToTeams, isLoadingAssignToTeams } =
    useAssignToTeamsManually();

  const { handleRemoveTeamMember, isLoadingRemoveTeamMember } =
    useRemoveTeamMember();

  const { handleTeamAutomatically, isLoadingTeamAutomatically } =
    useAssignToTeamsAutomatically();

  return {
    handleGetTeamLeaderboard,
    handleUpdateTeamScore,
    handleAssignToTeams,
    handleRemoveTeamMember,
    handleTeamAutomatically,
    isLoading: {
      isLoadingTeamLeaderboard,
      isLoadingUpdateTeamScore,
      isLoadingAssignToTeams,
      isLoadingRemoveTeamMember,
      isLoadingTeamAutomatically,
    },
  };
};
