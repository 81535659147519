import { convertTranslationArrToObj } from "helpers/translations";
import {
  MutationAddNewAchievementArgs,
  useAddNewAchievementMutation,
} from "graphql/_generated/graphql";

export const useAddAchievement = () => {
  const [addNewAchievementFun, { isLoading }] = useAddNewAchievementMutation();

  //this function is responsible for adding the Achievement
  const handleAddNewAchievement = async (
    values: MutationAddNewAchievementArgs
  ) => {
    try {
      let translations = await convertTranslationArrToObj(
        values.create.translations
      );
      await addNewAchievementFun({
        create: { ...values.create, translations },
      }).unwrap();
    } catch (error) {
      throw error;
    }
  };
  return {
    isLoading,
    handleAddNewAchievement,
  };
};
