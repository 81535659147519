export const CompanySvg = () => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0563 1.9C12.6713 1.62167 12.173 1.5425 11.7238 1.68917C11.2438 1.84583 10.6188 1.92833 9.91634 1.92833C8.97968 1.92833 8.16634 1.60917 7.30468 1.27167C6.33634 0.8925 5.33384 0.5 4.08301 0.5C1.67801 0.5 0.746341 1.29333 0.583008 1.45333C0.423841 1.60917 0.333008 1.82417 0.333008 2.04833V8V10.6192V14.6667C0.333008 15.1275 0.706341 15.5 1.16634 15.5C1.62717 15.5 1.99967 15.1275 1.99967 14.6667V11.085C2.32884 10.9392 2.97717 10.7383 4.08301 10.7383C5.01967 10.7383 5.83384 11.0575 6.69551 11.3958C7.66384 11.775 8.66551 12.1667 9.91634 12.1667C11.4238 12.1667 12.3522 11.8517 12.8672 11.5875C13.3605 11.3317 13.6663 10.8358 13.6663 10.2908V3.08833C13.6663 2.62167 13.4388 2.1775 13.0563 1.9Z"
        fill="#303030"
      />
    </svg>
  );
};
