import { CloudDownloadOutlined } from "@ant-design/icons";
import Dragger from "antd/lib/upload/Dragger";
import cloudUpload from "assets/images/cloud-upload.svg";
import CustomButton from "components/custom-button/CustomButton";
import ModalFooter from "components/custom-modal/ModalFooter";
import CustomTreeSelect from "components/form/custom-tree-select/CustomTreeSelect";
import styles from "features/users/css/InviteMultipleUsers.module.scss";
import { Form, Formik } from "formik";
import { useLoggedInUser } from "hooks/useLoggedInUser";
import { useTranslation } from "react-i18next";
import {
  InviteMultipleUsersSchemaForClient,
  InviteMultipleUsersSchemaForSuperAdmin,
} from "validations/schema";
import { useInviteMultipleUsersViewModel } from "../hooks/useInviteMultipleUsersViewModel";
import BulkUsersPreview from "./preview/BulkUsersPreview";

export type InviteMultipleProps = {
  setVisible: (x: boolean) => void;
  employerId?: string | undefined;
};

const InviteMultipleUsers = ({
  setVisible,
  employerId,
}: InviteMultipleProps) => {
  const { t } = useTranslation();
  const { isSuperAdmin, isClient } = useLoggedInUser();

  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const {
    initialValues,
    handleSubmit,
    handleRefetchEmployees,
    handleFileChange,
    handlePreviewAndSave,
    showPreview,
    setShowPreview,
    companiesData,
    bulkUsersContent,
  } = useInviteMultipleUsersViewModel({
    setVisible,
    employerId,
  });

  return (
    <div>
      <Formik
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={
          isSuperAdmin
            ? InviteMultipleUsersSchemaForSuperAdmin
            : InviteMultipleUsersSchemaForClient
        }
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, errors, touched, values }) => {
          return (
            <Form>
              {!isClient && (
                <CustomTreeSelect
                  allowClear
                  showArrow
                  disabled={!!employerId}
                  treeNodeFilterProp="companyName"
                  treeLine={true && { showLeafIcon: false }}
                  label={t("company")}
                  className="form-select"
                  name="parentId"
                  treeData={companiesData?.getClientsTree}
                  placeholder={t("select-company")}
                  fieldNames={{ label: "companyName", value: "id" }}
                  onChange={(values) => {
                    setFieldValue("parentId", values);
                  }}
                  required
                />
              )}
              <div className={styles["form-control"]}>
                <Dragger
                  maxCount={1}
                  multiple={false}
                  accept=".xlsx,.xls"
                  customRequest={dummyRequest}
                  onChange={async (e: any) =>
                    handleFileChange(e, setFieldValue)
                  }
                >
                  <div className={styles["center-div"]}>
                    <img src={cloudUpload} alt="Upload" />
                    <h2 className={styles["select-file"]}>
                      {t("select-file")}
                    </h2>
                    <div className={styles["file-size"]}>{t("file-size")}</div>
                    <CustomButton className="primary-btn">
                      {t("select-file")}
                    </CustomButton>
                  </div>
                </Dragger>
              </div>
              <a
                className={styles["downloadLink"]}
                href={
                  process.env.PUBLIC_URL + "/VIWELL Onboarding Template.xlsx"
                }
                download={"VIWELL Onboarding Template.xlsx"}
              >
                <CloudDownloadOutlined className={styles["downloadIcon"]} />
                {t("download-description")}
              </a>
              <ModalFooter
                saveText={t("preview-and-save")}
                onSubmit={() => handlePreviewAndSave(values)}
                onCancel={() => setVisible(false)}
              />
              {showPreview && bulkUsersContent?.employees?.length ? (
                <BulkUsersPreview
                  visible={showPreview}
                  setVisible={() => setShowPreview(false)}
                  users={bulkUsersContent}
                  errors={errors.users}
                  values={values}
                  refetch={handleRefetchEmployees}
                />
              ) : null}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default InviteMultipleUsers;
