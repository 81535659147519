import { useAddUpdateEvent } from "./useAddUpdateEvent";
import { useDeleteEvent } from "./useDeleteEvent";
import { useEventTranslation } from "./useEventTranslation";
import { useGetEvents } from "./useGetEvent";
import { useUpdateEventStatus } from "./useUpdateEventStatus";
import { useUpdateParticipantScore } from "./useUpdateParticipantScore";
import { useUpdateParticipantsScore } from "./useUpdateParticipantsScore";

export const useEvent = () => {
  const { getEvent, isLoading } = useGetEvents();
  const { deleteEvent, isDeletingEvent } = useDeleteEvent();
  const { addEvent, updateEvent, isAddingOrUpdatingEvent } =
    useAddUpdateEvent();
  const { updateParticipantsScore, isUpdatingParticipantsScore } =
    useUpdateParticipantsScore();
  const { updateEventStatus, isUpdatingEventStatus } = useUpdateEventStatus();

  const { handleEventTranslation, isLoadingTranslations } =
    useEventTranslation();

  const { handleUpdateParticipantScore, isLoadingUpdateParticipantScore } =
    useUpdateParticipantScore();

  return {
    getEvent,
    addEvent,
    updateEvent,
    deleteEvent,
    updateParticipantsScore,
    updateEventStatus,
    handleEventTranslation,
    handleUpdateParticipantScore,
    isLoadingEvent:
      isLoading ||
      isAddingOrUpdatingEvent ||
      isDeletingEvent ||
      isUpdatingParticipantsScore ||
      isUpdatingEventStatus ||
      isLoadingTranslations ||
      isLoadingUpdateParticipantScore,
  };
};
