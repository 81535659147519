import { customNotification } from "components/custom-notification/CustomNotification";
import { useFAQs } from "features/faqs/hooks/useFAQs";
import { FormikHelpers } from "formik";
import {
  FaqCreate,
  FaqList as FaqListType,
  useGetFaqListQuery,
  useLazyGetFaqListQuery,
} from "graphql/_generated/graphql";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const useFAQsListViewModel = () => {
  const { t } = useTranslation();

  //Initializing states
  const [filtersData, setFiltersData] = useState<any>(null);
  const [columnsData, setColumnsData] = useState<FaqListType>();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState<boolean>(false);
  const [editId, setEditId] = useState<string | null>(null);

  //FAQs Hooks
  const { isFetching } = useGetFaqListQuery();
  const [getAllFaqsDataFun] = useLazyGetFaqListQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const { handleError } = useErrorHandler();

  //FAQs actions handler
  const handleMenu = ({ key, id }: { key: string; id: string }) => {
    if (key === "edit-question") {
      handleEdit(id);
    }
  };

  //show faq existing content
  const handleEdit = (id: string) => {
    setVisible(true);
    setEditId(id);
  };

  //GET all faqs data
  useEffect(() => {
    fetchDataFromApi(getAllFaqsDataFun, setColumnsData, filtersData).catch(
      (e) => handleError(e),
    );
  }, [isFetching]);

  //FAQs hooks
  const { refetch } = useGetFaqListQuery();
  const {
    handleFetchEditFaq,
    handleUpdateFaq,
    handleAddFaq,
    handleActivationDeactivationFaq,
    isLoading: isFaqLoading,
  } = useFAQs();

  const handleSubmit = async (
    values: FaqCreate,
    { resetForm }: FormikHelpers<FaqCreate>,
  ) => {
    if (!editId) {
      try {
        await handleAddFaq({
          create: {
            ...values,
            isActive: true,
          },
        });
        customNotification("success", t("question-added-successfully"));
        resetForm();
        setVisible(false);
        return editId;
      } catch (error) {
        handleError(error as string | object);
      }
    } else {
      try {
        await handleUpdateFaq({
          id: editId,
          update: values,
        });
        setEditId(null);
        customNotification("success", t("question-updated-successfully"));
        resetForm();
        refetch();
        setVisible(false);
      } catch (error) {
        handleError(error as string | object);
      }
    }
  };

  //Initializing states
  const [initialValues, setInitialValues] = useState<FaqCreate>({
    question: "",
    answer: "",
    isActive: true,
    translations: [],
  });

  //this function is used to close modal with unsaved changes
  const handleCancel = () => {
    setVisible(false);
    setEditId(null);
  };

  //Re-Initializing states after fetching faq data for specific id
  useEffect(() => {
    if (editId) {
      handleFetchEditFaq(editId).then((data) => {
        setInitialValues(data);
      });
    } else {
      setInitialValues({
        question: "",
        answer: "",
        translations: [],
        isActive: true,
      });
    }
  }, [editId]);

  const handleToggleFaq = async (id: string) => {
    try {
      const isActivation = await handleActivationDeactivationFaq(id);
      customNotification(
        "success",
        isActivation
          ? t("faq.question-activated")
          : t("faq.question-deactivated"),
      );
      return isActivation;
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const handleFiltersCallback = async (filtersData: any) => {
    await fetchDataFromApi(getAllFaqsDataFun, setColumnsData, filtersData);
    setPageSize(filtersData?.pagination?.offset?.pageSize);
    setCurrentPage(filtersData?.pagination?.offset?.page);
    setFiltersData(filtersData);
  };

  return {
    currentPage,
    pageSize,
    handleToggleFaq,
    handleMenu,
    handleFiltersCallback,
    isLoading,
    columnsData,
    setVisible,
    visible,
    editId,
    setEditId,
    handleCancel,
    handleSubmit,
    isFaqLoading,
    initialValues,
  };
};
