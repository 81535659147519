import { CloseOutlined } from "@ant-design/icons";
import arrowDownSvg from "assets/images/arrowhead-down.svg";
import { BarChart } from "features/company-dashboard/charts/bar/BarChart";
import styles from "../../CompanyDashboard.module.scss";

export const CheckinDetails: React.FC<any> = ({
  open,
  toggleOpen,
  title,
  checkinData,
  message,
}) => {
  const optionsData = [...checkinData?.options];

  const sortedOptionsData = optionsData
    ?.sort((a: any, b: any) => {
      return a?.score - b?.score;
    })
    .map((item) => ({
      ...item,
      value: (
        (item?.totalSubmissions / checkinData?.totalSubmissions) *
        100
      ).toFixed(0),
    }));

  const lowScoreSubmissions =
    (checkinData?.lowScores / checkinData?.totalSubmissions) * 100;

  return (
    <>
      <div className={styles["checkin-details"]}>
        <div className={styles["header"]}>
          <div className={styles["item-header"]}>{title}</div>
          <CloseOutlined onClick={() => toggleOpen(false)} />
        </div>
        <div className={styles["barchart-container"]}>
          <BarChart
            barChartConfig={{
              barSize: 20,
              data: sortedOptionsData,
              xData: {
                key: "emoji",
                isEmojiBar: true,
                width: 30,
                customXTick: 13,
              },
              yData: {
                key: "value",
              },
              customBarBG: { x: 32, height: 160 },
            }}
          />
        </div>
        {checkinData?.lowScores ? (
          <div
            className={`${styles["past-value-container"]} ${styles["margin-top-16"]}`}
          >
            <div
              className={`${styles["past-value-comparison"]} ${styles["past-value-down"]}`}
            >
              <img
                className={styles["no-data-img"]}
                src={arrowDownSvg}
                alt="No Data"
              />
              {`${Math.abs(lowScoreSubmissions).toFixed(0)}%`}
            </div>
            <div
              className={`${styles["checkin-insights"]} ${styles["margin-left-5"]}`}
            >
              {message}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};
