export const GoldMedalSvg = () => {
  return (
    <svg
      width="29"
      height="32"
      viewBox="0 0 29 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.7775 32.0002L21.576 11.3818L15.3838 12.7564L19.0991 30.0365L26.7775 32.0002Z"
        fill="url(#paint0_linear_1490_2630)"
      />
      <path
        d="M2.00862 32.0002L7.2101 11.3818L13.4023 12.7564L9.687 30.0365L2.00862 32.0002Z"
        fill="url(#paint1_linear_1490_2630)"
      />
      <path
        d="M14.3931 28.8578L19.0992 30.036L15.3838 12.7559L14.3931 12.9522V28.8578Z"
        fill="url(#paint2_linear_1490_2630)"
      />
      <path
        d="M14.3931 28.8578L9.68696 30.036L13.4023 12.7559L14.3931 12.9522V28.8578Z"
        fill="url(#paint3_linear_1490_2630)"
      />
      <path
        d="M28.7855 12.3845L21.5892 24.769H7.19639L0 12.3845L7.19639 0L21.5892 6.25088e-07L28.7855 12.3845Z"
        fill="#F6E43D"
      />
      <path
        d="M26.7773 12.3847L20.585 23.0956H8.20054L2.0083 12.3847L8.20054 1.67383L20.585 1.67383L26.7773 12.3847Z"
        fill="#CBBF53"
      />
      <path
        d="M25.4836 12.3845L19.94 21.8412H8.8461L3.30248 12.3845L8.8461 2.92773L19.94 2.92773L25.4836 12.3845Z"
        fill="#CC6D3D"
        stroke="#A74D1A"
        stroke-width="0.5"
      />
      <path
        d="M20.5854 1.67358L21.5896 0L28.786 12.3845H26.7777L20.5854 1.67358Z"
        fill="#F5C12D"
      />
      <path
        d="M8.20068 1.67358L7.19654 0L0.000149727 12.3845H2.00844L8.20068 1.67358Z"
        fill="#FBD62F"
      />
      <path
        d="M20.5854 23.095L21.5896 24.7686L28.786 12.3841H26.7777L20.5854 23.095Z"
        fill="#F6C32B"
      />
      <path
        d="M8.20068 23.095L7.19654 24.7686L0.000149727 12.3841H2.00844L8.20068 23.095Z"
        fill="#E4972C"
      />
      <path
        d="M8.20044 23.0957H20.5849L21.5891 24.7693H7.19629L8.20044 23.0957Z"
        fill="#F2AD37"
      />
      <path
        d="M8.20044 1.67383H20.5849L21.5891 0.000249863H7.19629L8.20044 1.67383Z"
        fill="#FEE637"
      />
      <path
        d="M20.0826 2.67798L20.5847 1.67383H8.2002L8.70227 2.67798H20.0826Z"
        fill="#DF9C33"
      />
      <path
        d="M20.0831 22.0916L20.5852 23.0957H8.20068L8.70276 22.0916H20.0831Z"
        fill="#F7CE88"
      />
      <path
        d="M20.083 2.67798L20.5851 1.67383L26.7773 12.3847H25.7732L20.083 2.67798Z"
        fill="url(#paint4_linear_1490_2630)"
      />
      <path
        d="M8.70264 2.67798L8.20056 1.67383L2.00832 12.3847H3.01247L8.70264 2.67798Z"
        fill="url(#paint5_linear_1490_2630)"
      />
      <path
        d="M20.083 22.0916L20.5851 23.0957L26.7773 12.3848H25.7732L20.083 22.0916Z"
        fill="#FDCD43"
      />
      <path
        d="M8.70264 22.0916L8.20056 23.0957L2.00832 12.3848H3.01247L8.70264 22.0916Z"
        fill="#F7CE88"
      />
      <path
        d="M14.6164 5.57863L14.3928 5.13141L14.1692 5.57863L12.2187 9.47958L7.99519 10.1294L7.47403 10.2095L7.86596 10.5623L11.1036 13.4762L10.1326 17.684L10.0075 18.2262L10.496 17.9597L14.3928 15.8334L18.2896 17.9597L18.7781 18.2262L18.653 17.684L17.682 13.4762L20.9196 10.5623L21.3116 10.2095L20.7904 10.1294L16.5669 9.47958L14.6164 5.57863Z"
        fill="#A74D1A"
        stroke="#A74D1A"
        stroke-width="0.5"
      />
      <path
        d="M14.3931 12.05V5.69043L16.4014 9.70702L14.3931 12.05Z"
        fill="#F5C32E"
      />
      <path
        d="M14.3926 12.0501L12.3843 9.70708L14.3926 5.69043V12.0501Z"
        fill="#FFE55D"
      />
      <path
        d="M20.7527 10.3765L16.4014 9.70703L14.3931 12.05L20.7527 10.3765Z"
        fill="#FCE235"
      />
      <path
        d="M14.3926 12.0505L17.405 13.3894L20.7522 10.377L14.3926 12.0505Z"
        fill="#EDAE2A"
      />
      <path
        d="M14.3931 12.0498L17.4055 13.3887L18.4097 17.74L14.3931 12.0498Z"
        fill="#FBBF2C"
      />
      <path
        d="M14.3931 15.5643V12.0498L18.4097 17.74L14.3931 15.5643Z"
        fill="#EEAD2E"
      />
      <path
        d="M10.3765 17.74L14.3931 12.0498V15.5643L10.3765 17.74Z"
        fill="#E39B2C"
      />
      <path
        d="M11.3806 13.3887L14.3931 12.0498L10.3765 17.74L11.3806 13.3887Z"
        fill="#F6C32D"
      />
      <path
        d="M8.0332 10.377L14.3928 12.0505L11.3804 13.3894L8.0332 10.377Z"
        fill="#E49B2E"
      />
      <path
        d="M8.0332 10.3765L12.3845 9.70703L14.3928 12.05L8.0332 10.3765Z"
        fill="#FADA50"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1490_2630"
          x1="21.0806"
          y1="11.3818"
          x2="21.0806"
          y2="32.0002"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C53D45" />
          <stop offset="1" stop-color="#F34156" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1490_2630"
          x1="7.70548"
          y1="11.3818"
          x2="7.70548"
          y2="32.0002"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C53D45" />
          <stop offset="1" stop-color="#F34156" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1490_2630"
          x1="16.7461"
          y1="12.7559"
          x2="16.7461"
          y2="30.036"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB828B" />
          <stop offset="1" stop-color="#F4949B" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1490_2630"
          x1="12.04"
          y1="12.7559"
          x2="12.04"
          y2="30.036"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB828B" />
          <stop offset="1" stop-color="#F4949B" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1490_2630"
          x1="23.4302"
          y1="1.67383"
          x2="23.4302"
          y2="12.3847"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DF9C33" />
          <stop offset="1" stop-color="#F1BC3A" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1490_2630"
          x1="5.35548"
          y1="1.67383"
          x2="5.35548"
          y2="12.3847"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DF9C33" />
          <stop offset="1" stop-color="#F0BB39" />
        </linearGradient>
      </defs>
    </svg>
  );
};
