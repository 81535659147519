import {
  MutationToggleEventStatusArgs,
  useToggleEventStatusMutation,
} from "graphql/_generated/graphql";

export const useUpdateEventStatus = () => {
  const [updateEventStatusFn, { isLoading }] = useToggleEventStatusMutation();

  const updateEventStatus = async ({
    eventId,
  }: MutationToggleEventStatusArgs) => {
    try {
      const res = await updateEventStatusFn({
        eventId,
      }).unwrap();
      return res.toggleEventStatus;
    } catch (error) {
      throw error;
    }
  };

  return {
    updateEventStatus,
    isUpdatingEventStatus: isLoading,
  };
};
