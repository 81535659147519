export const SilverMedalSvg = () => {
  return (
    <svg
      width="29"
      height="32"
      viewBox="0 0 29 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.7776 31.9995L21.5761 11.3809L15.3838 12.7554L19.0992 30.0358L26.7776 31.9995Z"
        fill="url(#paint0_linear_1490_13140)"
      />
      <path
        d="M2.00848 31.9995L7.21003 11.3809L13.4023 12.7554L9.68695 30.0358L2.00848 31.9995Z"
        fill="url(#paint1_linear_1490_13140)"
      />
      <path
        d="M14.3926 28.857L19.0987 30.0352L15.3833 12.7549L14.3926 12.9513V28.857Z"
        fill="url(#paint2_linear_1490_13140)"
      />
      <path
        d="M14.3926 28.857L9.68642 30.0352L13.4018 12.7549L14.3926 12.9513V28.857Z"
        fill="url(#paint3_linear_1490_13140)"
      />
      <path
        d="M28.7859 12.3856L21.5894 24.7702H7.19647L0 12.3856L7.19648 0.000976562L21.5894 0.000977188L28.7859 12.3856Z"
        fill="#F6E43D"
      />
      <path
        d="M26.7776 12.3849L20.5852 23.0959H8.20062L2.0083 12.3849L8.20062 1.67383L20.5852 1.67383L26.7776 12.3849Z"
        fill="#CBBF53"
      />
      <path
        d="M25.4838 12.3846L19.9402 21.8415H8.84617L3.30248 12.3846L8.84617 2.92773L19.9402 2.92773L25.4838 12.3846Z"
        fill="#5F6F7E"
        stroke="#334752"
        stroke-width="0.5"
      />
      <path
        d="M20.5854 1.67458L21.5896 0.000976562L28.7861 12.3856H26.7778L20.5854 1.67458Z"
        fill="#D2DBEC"
      />
      <path
        d="M8.20068 1.67458L7.19652 0.000976562L4.95911e-05 12.3856H2.00837L8.20068 1.67458Z"
        fill="#E5F0F8"
      />
      <path
        d="M20.5859 23.0959L21.5901 24.7695L28.7866 12.3849H26.7783L20.5859 23.0959Z"
        fill="#B8C9D9"
      />
      <path
        d="M8.20068 23.0959L7.19652 24.7695L4.95911e-05 12.3849H2.00837L8.20068 23.0959Z"
        fill="#C6D6E3"
      />
      <path
        d="M8.20094 23.0967H20.5856L21.5897 24.7703H7.19678L8.20094 23.0967Z"
        fill="#A9B6C9"
      />
      <path
        d="M8.20094 1.67383H20.5856L21.5897 0.000229359H7.19678L8.20094 1.67383Z"
        fill="#ECF5F7"
      />
      <path
        d="M20.0832 2.67799L20.5853 1.67383H8.20068L8.70276 2.67799H20.0832Z"
        fill="#D0DEEB"
      />
      <path
        d="M20.0832 22.0925L20.5853 23.0967H8.20068L8.70276 22.0925H20.0832Z"
        fill="#E1F1FF"
      />
      <path
        d="M20.083 2.67799L20.5851 1.67383L26.7774 12.3849H25.7732L20.083 2.67799Z"
        fill="#B0C7D1"
      />
      <path
        d="M8.70312 2.67799L8.20105 1.67383L2.00873 12.3849H3.01289L8.70312 2.67799Z"
        fill="#C6D4E1"
      />
      <path
        d="M20.0835 22.0925L20.5856 23.0967L26.7779 12.3856H25.7737L20.0835 22.0925Z"
        fill="#C0D4DF"
      />
      <path
        d="M8.70264 22.0925L8.20056 23.0967L2.00824 12.3856H3.0124L8.70264 22.0925Z"
        fill="#E6F2FE"
      />
      <path
        d="M14.617 5.57863L14.3934 5.13141L14.1698 5.57863L12.2193 9.47963L7.99568 10.1294L7.47452 10.2096L7.86645 10.5623L11.1042 13.4763L10.1331 17.6841L10.008 18.2263L10.4965 17.9598L14.3934 15.8335L18.2903 17.9598L18.7787 18.2263L18.6536 17.6841L17.6826 13.4763L20.9203 10.5623L21.3122 10.2096L20.7911 10.1294L16.5675 9.47963L14.617 5.57863Z"
        fill="#A74D1A"
        stroke="#334752"
        stroke-width="0.5"
      />
      <path
        d="M14.3936 12.0501V5.69043L16.4019 9.70707L14.3936 12.0501Z"
        fill="#C5D5E2"
      />
      <path
        d="M14.3936 12.0502L12.3853 9.70712L14.3936 5.69043V12.0502Z"
        fill="#E7F3FF"
      />
      <path
        d="M20.7532 10.3774L16.4019 9.70801L14.3936 12.051L20.7532 10.3774Z"
        fill="#CEDEED"
      />
      <path
        d="M14.3936 12.0506L17.406 13.3894L20.7532 10.377L14.3936 12.0506Z"
        fill="#A7B5C2"
      />
      <path
        d="M14.3936 12.0498L17.406 13.3887L18.4102 17.74L14.3936 12.0498Z"
        fill="#CADAE7"
      />
      <path
        d="M14.3936 15.5644V12.0498L18.4102 17.74L14.3936 15.5644Z"
        fill="#A1B1BE"
      />
      <path
        d="M10.377 17.74L14.3936 12.0498V15.5644L10.377 17.74Z"
        fill="#A9BBC7"
      />
      <path
        d="M11.3811 13.3887L14.3936 12.0498L10.377 17.74L11.3811 13.3887Z"
        fill="#C7D9E5"
      />
      <path
        d="M8.03369 10.377L14.3934 12.0506L11.3809 13.3894L8.03369 10.377Z"
        fill="#A4B4C1"
      />
      <path
        d="M8.03369 10.3774L12.385 9.70801L14.3934 12.051L8.03369 10.3774Z"
        fill="#E0EEF9"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1490_13140"
          x1="21.0807"
          y1="11.3809"
          x2="21.0807"
          y2="31.9995"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C53D45" />
          <stop offset="1" stop-color="#F34156" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1490_13140"
          x1="7.70541"
          y1="11.3809"
          x2="7.70541"
          y2="31.9995"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C53D45" />
          <stop offset="1" stop-color="#F34156" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1490_13140"
          x1="16.7457"
          y1="12.7549"
          x2="16.7457"
          y2="30.0352"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB828B" />
          <stop offset="1" stop-color="#F4949B" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1490_13140"
          x1="12.0395"
          y1="12.7549"
          x2="12.0395"
          y2="30.0352"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB828B" />
          <stop offset="1" stop-color="#F4949B" />
        </linearGradient>
      </defs>
    </svg>
  );
};
