import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { parseJwt } from "helpers";

type TypeUseNavigateToUserProfile = () => {
  navigateToSignInUserProfile: (token: string) => void;
};

export const useNavigateToUserProfile: TypeUseNavigateToUserProfile = () => {
  const navigate = useNavigate();

  const navigateToSignInUserProfile = (token: string) => {
    const userRole = parseJwt(token).userType;
    if (userRole === "SuperAdmin") {
      navigate("/admin-profile");
    } else if (userRole === "ClientAdmin") {
      navigate("/company-profile");
    } else if (userRole === "Expert") {
      navigate("/expert-profile");
    } else if (userRole === "VIWELLAdmin") {
      navigate("/company");
    }
  };

  return { navigateToSignInUserProfile };
};
