import { BooleanIcon } from "assets/icons/BooleanIcon";
import { MultipleChoiceIcon } from "assets/icons/MultipleChoiceIcon";
import { RankingIcon } from "assets/icons/RankingSvg";
import { RatingIcon } from "assets/icons/RatingSvg";
import { singleChoiceIcon } from "assets/icons/SingleChoiceIcon";
import { TextIcon } from "assets/icons/TextSvg";
import { MetricIcon } from "assets/icons/MetricIcon";
import { SliderIcon } from "assets/icons/SliderIcon";

export const questionTypes = [
  { label: "Single Choice", value: "singleChoice", icon: singleChoiceIcon },
  {
    label: "Multiple Choice",
    value: "multipleChoice",
    icon: MultipleChoiceIcon,
  },
  { label: "Yes or No", value: "boolean", icon: BooleanIcon },
  { label: "Ranking", value: "ranking", icon: RankingIcon },
  { label: "Rating", value: "rating", icon: RatingIcon },
  { label: "Text", value: "text", icon: TextIcon },
  { label: "Slider", value: "slider", icon: SliderIcon },
  { label: "Matrix", value: "matrix", icon: MetricIcon },
];
