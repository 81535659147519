import { useAddAchievement } from "./add-badge/useAddAchievement";
import { useUpdateAchievement } from "./add-badge/useUpdateAchievement";
import { useToggleAchievementActivation } from "./badges-list/useToggleAchievementActivation";

export const useAchievement = () => {
  const { handleAddNewAchievement, isLoading } = useAddAchievement();

  const { handleActivationAchievement, isLoading: toggleAchievementLoading } =
    useToggleAchievementActivation();

  const {
    handleUpdateAchievement,
    handleFetchAchievementData,
    isLoading: updateAchievementLoading,
  } = useUpdateAchievement();

  return {
    isLoading:
      isLoading || updateAchievementLoading || toggleAchievementLoading,
    handleAddNewAchievement,
    handleUpdateAchievement,
    handleFetchAchievementData,
    handleActivationAchievement,
  };
};
