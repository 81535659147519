/**
 * Determines order of array.
 *
 * @param arr Array
 * @param order EnumOrder
 * @returns boolean
 */
const checkArrayOrder: IcheckArrayOrder = (
  arr = [],
  order = EnumOrder.ASCENDING,
) => {
  // let a = arr.slice(1);
  // if (!a.length) true;

  if (
    order === EnumOrder.ASCENDING &&
    arr.every((x, i) => i === 0 || x >= arr[i - 1])
  )
    return true;

  if (
    order === EnumOrder.DESCENDING &&
    arr.every((x, i) => i === 0 || x <= arr[i - 1])
  )
    return true;

  return false;
  // return EnumOrder.UNSORTED;
};

interface IcheckArrayOrder {
  (arr: Array<any>, order?: EnumOrder): boolean;
}

enum EnumOrder {
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
  UNSORTED = "UNSORTED",
}

export default checkArrayOrder;
