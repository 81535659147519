import customNotification from "components/custom-notification/CustomNotification";
import { FormikHelpers } from "formik";
import {
  Category,
  CollectionType,
  ContentType,
  GetCategoriesTreeQuery,
} from "graphql/_generated/graphql";
import { useLanguages, usePillarIdAndName } from "hooks";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useGetContentStatus } from "hooks/useGetContentStatus";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCollection } from "../collection/useCollection";
import { useGetCollectionConfig } from "./useGetCollectionConfig";

interface AddCollectionProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  editId?: string | null;
  setEditId: React.Dispatch<React.SetStateAction<string | null>>;
}

export interface CategoryWithChildren extends Category {
  children: Category[] | null;
}

export const useAddCollectionViewModel = ({
  visible,
  setVisible,
  editId,
  setEditId,
}: AddCollectionProps) => {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const { pillarsData } = usePillarIdAndName();
  const [pillarId, setPillarId] = useState<string | undefined>();
  const [categoriesData, setCategoriesData] = useState<
    GetCategoriesTreeQuery | undefined
  >();
  const [contentFilters, setContentFilters] = useState({});
  const [isContentLoading, setIsContentLoading] = useState(false);
  const { contentStatus } = useGetContentStatus();
  const { languages } = useLanguages();
  const [contentData, setContentData] = useState<any>([]);
  const [collectionTypeItems, setCollectionTypeItems] = useState<
    { label: string; value: string }[]
  >([]);
  const {
    handleAddNewCollection,
    handleFetchEditCollectionData,
    handleUpdateCollection,
    isLoading,
  } = useCollection();

  const { getContentFunc, tagsData, refetch, getCategoriesFun } =
    useGetCollectionConfig();

  const handleCancel = () => {
    setVisible(false);
    setEditId(null);
  };

  const defaultValues = {
    image: "",
    title: "",
    description: "",
    translations: [],
    pillarIds: "",
    categoryIds: [],
    collectionType: "",
    language: "",
    spokenLanguage: "",
    tagIds: [],
    contentIds: [],
    status: "",
  };

  const [initialValues, setInitialValues] = useState<any>(defaultValues);

  useEffect(() => {
    const collectionTypeItems = Object.keys(CollectionType).map(
      (item: string) => ({ label: item, value: item }),
    );
    setCollectionTypeItems(collectionTypeItems);
    fetchContent();
  }, []);

  useEffect(() => {
    const fetchEditCollectionData = async (id: string) => {
      const data = await handleFetchEditCollectionData(id);
      const dummyTags =
        data?.tags
          ?.filter(({ isActive }: { isActive: boolean }) => isActive)
          ?.map(({ id }: { id: string }) => id) || [];
      const contentArr =
        data?.contents?.map(({ id, title }: { id: string; title: string }) => ({
          id,
          label: title,
        })) || [];

      const v = {
        image: data?.image,
        title: data?.title,
        description: data?.description,
        translations: data?.translations,
        collectionType: data?.collectionType,
        language: data?.language,
        spokenLanguage: data?.spokenLanguage,
        pillarIds: data?.pillar?.id,
        categoryIds: data.categories
          .filter((category) => category.isActive)
          .map(({ id }) => id),
        tagIds: dummyTags,
        contentIds: contentArr,
        status: data?.status,
      };
      setInitialValues({
        ...defaultValues,
        ...v,
      });

      setContentFilters((prev) => ({
        ...prev,
        type: data?.collectionType,
      }));
    };

    editId ? fetchEditCollectionData(editId) : setInitialValues(defaultValues);
  }, [editId]);

  useEffect(() => {
    if (Object.keys(contentFilters).length) {
      fetchContent(contentFilters);
    }
  }, [contentFilters]);

  const handlePillarChange = (value: string) => {
    setPillarId(value);
  };

  useEffect(() => {
    (pillarId ?? initialValues?.pillarIds) && fetchPillarCategories();
  }, [pillarId, initialValues?.pillarIds]);

  const fetchPillarCategories = async () => {
    const res = await getCategoriesFun({
      pillarId: pillarId ?? initialValues?.pillarIds,
    });
    setCategoriesData(res?.data);
  };

  const fetchContent = async (args: { lang?: string; type?: string } = {}) => {
    const { lang, type } = args;
    setIsContentLoading(true);

    const params = {
      ...(lang && { language: lang }),
      ...(type === CollectionType.Topic && { type: ContentType.Video }),
    };

    try {
      const res = await getContentFunc(params);
      setContentData(res?.data?.getContentConfig?.options);
    } catch (error) {
      // Handle error
    } finally {
      setIsContentLoading(false);
    }
  };

  const onSubmit = async (values: any, { resetForm }: FormikHelpers<any>) => {
    try {
      if (!editId) {
        await handleAddNewCollection({ create: values });
        customNotification("success", t("program-added"));
      } else {
        await handleUpdateCollection({ id: editId, updateObj: values });
        customNotification("success", t("program-updated"));
        setEditId(null);
      }

      resetForm();
      refetch();
      setVisible(false);
    } catch (error) {
      handleError(error as string | object);
    }
  };

  return {
    handleCancel,
    initialValues,
    handleAddNewCollection,
    refetch,
    onSubmit,
    languages,
    collectionTypeItems,
    setContentFilters,
    pillarsData,
    categoriesData,
    contentStatus,
    tagsData,
    contentData,
    isContentLoading,
    isLoading,
    handlePillarChange,
  };
};
