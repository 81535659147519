import { notification } from "antd";
import "./CustomNotification.module.scss";
export const customNotification = (
  type: string,
  message: string | any,
  duration?: number | null,
) => {
  if (
    type === "success" ||
    type === "error" ||
    type === "info" ||
    type === "warning" ||
    type === "open"
  ) {
    notification[type]({
      message: `${type.charAt(0).toUpperCase()}${type.slice(1)}`,
      description: message,
      className: "custom-notification",
      duration,
    });
  }
};

export default customNotification;
