import customNotification from "components/custom-notification/CustomNotification";
import { FormikHelpers } from "formik";
import {
  RewardCategoryCreate,
  RewardCategoryList,
  useGetRewardCategoryListQuery,
  useLazyGetRewardCategoryListQuery,
  useLazyGetRewardsCountByCategoryQuery,
} from "graphql/_generated/graphql";
import { useLanguages } from "hooks";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTier } from "./useTier";

export const useCategoryListViewModel = () => {
  const { t } = useTranslation();
  const { languages } = useLanguages();
  const [visible, setVisible] = useState(false);
  const [langVisibility, setLangVisibility] = useState(false);
  const [langCode, setLangCode] = useState<string>();
  const [isAddLanguage, setIsAddLanguage] = useState(false);
  const [isAddCategory, setIsAddCategory] = useState(false);
  const [editId, setEditId] = useState<string | null>(null);
  const [isDelete, setIsDelete] = useState(false);
  const [categoriesData, setCategoriesData] = useState<MappedCategoryList>();
  const [rewardCount, setRewardCount] = useState<number>();
  const [isActivated, setIsActivated] = useState(false);

  const [columnsData, setColumnsData] = useState<RewardCategoryList>();
  const [filtersData, setFiltersData] = useState<any>(null);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const { isFetching } = useGetRewardCategoryListQuery();
  const [getRewardCategoryListFun] = useLazyGetRewardCategoryListQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const [getRewardCountFun] = useLazyGetRewardsCountByCategoryQuery();
  const { handleError } = useErrorHandler();

  const {
    handleToggleRewardCategory,
    handleDeleteRewardCategory,
    isLoading: isLoadingTier,
    handleAddRewardCategory,
    handleGetRewardCategory,
    handleUpdateRewardCategory,
  } = useTier();

  useEffect(() => {
    fetchDataFromApi(
      getRewardCategoryListFun,
      setColumnsData,
      filtersData,
    ).catch((e) => handleError(e));
  }, [isFetching]);

  const submitDelete = async () => {
    try {
      const res = await handleDeleteRewardCategory(editId ?? "");
      customNotification("success", t("deleted-reward-category-successfully"));
      setIsDelete(false);
      setEditId(null);
      return res;
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const getRewardCount = async (id: string) => {
    try {
      const res = await getRewardCountFun({ categoryId: id }).unwrap();
      return res.getRewardsCountByCategory;
    } catch (error) {
      console.log(error);
    }
  };

  const handleMenu = ({
    key,
    id,
    record,
  }: {
    key: string;
    id: string;
    record: MappedCategoryList;
  }) => {
    if (key === "add-new-language") {
      setCategoriesData(record);
      setLangVisibility(true);
      setEditId(id);
      setIsAddLanguage(true);
    }
    if (key === "edit-reward-category") {
      setIsAddLanguage(false);
      setEditId(id);
      setIsAddCategory(true);
      setLangCode(undefined);
    }
    if (key === "edit-language") {
      setLangCode(record.language);
      setIsAddLanguage(true);
      setEditId(id);
      setIsAddCategory(true);
    }
    if (key === "delete-reward-category") {
      const res = getRewardCount(id);
      res.then((data) => {
        setRewardCount(data);
      });
      setIsDelete(true);
      setEditId(id);
    }
  };

  const handleSubmit = (
    values: { language: string },
    actions: FormikHelpers<{ language: string }>,
  ) => {
    setLangCode(values?.language);
    setLangVisibility(false);
    setIsAddCategory(true);
    actions.resetForm();
  };

  const mappedColumnsData = columnsData?.data.map((item) => {
    return (
      {
        ...item,
        hasChildren: true,
        children:
          item?.translations && Object.keys?.(item?.translations)
            ? Object.keys?.(item?.translations)?.map((record) => {
                return {
                  ...item,
                  name: item?.translations?.[record]?.name,
                  language: record,
                };
              })
            : null,
      } ?? []
    );
  });

  type MappedCategoryList = NonNullable<typeof mappedColumnsData>[0];

  const handleToggleCategory = (record: MappedCategoryList) => {
    setIsActivated(true);
    setCategoriesData(record);
  };

  const handleToggleCategoryActivation = async () => {
    try {
      const isCategoryActive = await handleToggleRewardCategory(
        categoriesData?.id ?? "",
      );
      customNotification(
        "success",
        isCategoryActive
          ? t("reward-category-activation-successfully")
          : t("reward-category-deactivate-successfully"),
      );
      setIsActivated(false);
      setCategoriesData(undefined);
      return isCategoryActive;
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const handleFilterCallback = async (filtersData: any) => {
    await fetchDataFromApi(
      getRewardCategoryListFun,
      setColumnsData,
      filtersData,
    );
    setPageSize(filtersData?.pagination?.offset?.pageSize);
    setCurrentPage(filtersData?.pagination?.offset?.page);
    setFiltersData(filtersData);
    setFiltersData(filtersData);
  };

  const handleCancel = () => {
    setIsAddCategory(false);
    setEditId(null);
  };

  const [initialValues, setInitialValues] = useState<RewardCategoryCreate>({
    language: "",
    image: "",
    name: "",
  });

  useEffect(() => {
    if (editId) {
      handleGetRewardCategory(editId).then((data) => {
        const translationData = langCode && data?.translations?.[langCode];
        setInitialValues({
          name: isAddLanguage ? translationData?.name : data?.name,
          image: data.image,
          language: data.language,
        });
      });
    }
  }, [editId]);

  const handleAddSubmit = async (
    values: RewardCategoryCreate,
    { resetForm }: FormikHelpers<RewardCategoryCreate>,
  ) => {
    if (!editId && !isAddLanguage) {
      try {
        await handleAddRewardCategory({
          create: {
            ...values,
            language: langCode,
          },
        });
        customNotification("success", t("reward-category-added-successfully"));
        resetForm();
        setIsAddCategory(false);
      } catch (error) {
        handleError(error as string | object);
      }
    } else if (isAddLanguage && editId && langCode) {
      try {
        await handleUpdateRewardCategory({
          update: {
            translations: {
              [langCode]: {
                name: values.name,
              },
            },
          },
          id: editId,
        });
        setEditId(null);
        customNotification(
          "success",
          t("reward-category-language-added-successfully"),
        );
        resetForm();
        setIsAddCategory(false);
      } catch (error) {
        handleError(error as string | object);
      }
    } else {
      try {
        await handleUpdateRewardCategory({
          update: values,
          id: editId,
        });
        customNotification(
          "success",
          t("reward-category-updated-successfully"),
        );
        setEditId(null);
        setIsAddCategory(false);
        resetForm();
      } catch (error) {
        handleError(error as string | object);
      }
    }
  };

  return {
    handleFilterCallback,
    mappedColumnsData,
    currentPage,
    pageSize,
    handleSubmit,
    handleToggleCategory,
    handleMenu,
    languages,
    columnsData,
    isLoading,
    langVisibility,
    setLangVisibility,
    langCode,
    isAddLanguage,
    editId,
    setEditId,
    isAddCategory,
    setIsAddCategory,
    isDelete,
    setIsDelete,
    isActivated,
    setIsActivated,
    isLoadingTier,
    rewardCount,
    handleToggleCategoryActivation,
    categoriesData,
    submitDelete,
    handleAddSubmit,
    handleCancel,
    initialValues,
    visible,
    setVisible,
  };
};
