import {
  useSetQuestTranslationMutation,
  MutationSetQuestTranslationArgs,
} from "graphql/_generated/graphql";

export const useAddQuestTranslations = () => {
  const [QuestTranslationsFun, { isLoading }] =
    useSetQuestTranslationMutation();

  const handleAddQuestTranslations = async (
    values: MutationSetQuestTranslationArgs,
  ) => {
    try {
      const res = await QuestTranslationsFun({
        setQuestTranslationId: values.id,
        translationInput: values.translationInput,
      }).unwrap();
      return res.setQuestTranslation;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleAddQuestTranslations,
    isLoadingAddQuestTranslations: isLoading,
  };
};
