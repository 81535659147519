import {
  useSetStreakMutation,
  MutationSetStreakArgs,
} from "graphql/_generated/graphql";

export const useAddStreak = () => {
  const [addStreakFun, { isLoading }] = useSetStreakMutation();
  const handleAddStreak = async (values: MutationSetStreakArgs) => {
    try {
      const res = await addStreakFun({ streak: values.streak }).unwrap();
      return res.setStreak;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleAddStreak,
    isLoading,
  };
};
