import { useLazyGetHabitByIdQuery } from "graphql/_generated/graphql";

export const useGetHabitDetails = () => {
  const [getHabitDetailFun, { isFetching: isLoadingHabitDetails }] =
    useLazyGetHabitByIdQuery();

  const handleGetHabitDetail = async (id: string) => {
    try {
      const res = await getHabitDetailFun({ getHabitByIdId: id }).unwrap();
      return res.getHabitById;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleGetHabitDetail,
    isLoadingHabitDetails,
  };
};
