export const PartnerSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.50002 9.16667C9.33835 9.16667 10.8334 7.67167 10.8334 5.83333C10.8334 3.995 9.33835 2.5 7.50002 2.5C5.66169 2.5 4.16669 3.995 4.16669 5.83333C4.16669 7.67167 5.66169 9.16667 7.50002 9.16667ZM14.1667 10.8333C15.545 10.8333 16.6667 9.71167 16.6667 8.33333C16.6667 6.955 15.545 5.83333 14.1667 5.83333C12.7884 5.83333 11.6667 6.955 11.6667 8.33333C11.6667 9.71167 12.7884 10.8333 14.1667 10.8333ZM18.3334 15.8333C18.3334 16.2933 17.9609 16.6667 17.5 16.6667H13.3334C13.3334 17.1267 12.9609 17.5 12.5 17.5H2.50002C2.03919 17.5 1.66669 17.1267 1.66669 16.6667C1.66669 13.45 4.28419 10.8333 7.50002 10.8333C9.10585 10.8333 10.5609 11.4858 11.6167 12.5383C12.3409 11.9783 13.2325 11.6667 14.1667 11.6667C16.4642 11.6667 18.3334 13.5358 18.3334 15.8333Z"
        fill="#303030"
      />
    </svg>
  );
};
