import { convertTranslationArrToObj } from "helpers";
import {
  useAddNewCollectionMutation,
  MutationAddNewCollectionArgs,
  useGetSpecialistConfigQuery,
} from "graphql/_generated/graphql";

export const useAddNewCollection = () => {
  const [addNewCollectionFun, { isLoading }] = useAddNewCollectionMutation();
  const { refetch } = useGetSpecialistConfigQuery();

  //this function is responsible for adding the Specialist data
  const handleAddNewCollection = async (
    values: MutationAddNewCollectionArgs,
  ) => {
    try {
      let translations = await convertTranslationArrToObj(
        values.create.translations,
      );

      const collectionId = await addNewCollectionFun({
        create: {
          ...values.create,
          contentIds: values?.create?.contentIds?.map((item: any) => item.id),
          translations,
        },
      }).unwrap();

      refetch();
    } catch (error) {
      throw error;
    }
  };
  return {
    isLoading,
    handleAddNewCollection,
  };
};
