import { TreeProps } from "antd/es/tree";
import { customNotification } from "components/custom-notification/CustomNotification";
import { useRoles } from "features/roles/hooks/useRoles";
import { FormikHelpers } from "formik";
import {
  RoleCreate,
  RoleList,
  useGetAllPermissionsQuery,
  useGetConfigRolesQuery,
  useGetRoleListQuery,
  useLazyGetRoleListQuery,
} from "graphql/_generated/graphql";
import { removeDuplicatesInStringArr } from "helpers";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ALL_PERMS = "All Permissions";

export const useRolesListViewModel = () => {
  //Initializing States
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [editId, setEditId] = useState<string | null>(null);
  const [columnsData, setColumnsData] = useState<RoleList>();
  const [filtersData, setFiltersData] = useState<any>(null);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  //Hooks
  const [getRoleList] = useLazyGetRoleListQuery();
  const { isFetching, refetch } = useGetRoleListQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const { refetch: rolesFetch } = useGetConfigRolesQuery();
  const { handleError } = useErrorHandler();

  const [permissionsInitialValues, setPermissionsInitialValues] = useState<
    string[]
  >([]);

  const {
    handleAddNewRole,
    handleFetchPreviousRoleData,
    handleUpdateRole,
    isLoading: isRoleLoading,
    handleToggleActivationRole,
  } = useRoles();

  const { data: permissionsDataArr } = useGetAllPermissionsQuery();

  const [showPermissionsError, setShowPermissionsError] =
    useState<boolean>(false);

  const handleCancel = () => {
    setVisible(false);
    setEditId(null);
    setPermissionsInitialValues([]);
    setInitialValues({ name: "", description: undefined, translations: [] });
    setShowPermissionsError(false);
  };
  useEffect(() => {
    fetchDataFromApi(getRoleList, setColumnsData, filtersData).catch((e) =>
      handleError(e),
    );
  }, [isFetching]);

  const handleMenu = ({ key, id }: { key: string; id: string }) => {
    if (key === "edit-role") {
      handleEdit(id);
    }
  };

  const handleEdit = (id: string) => {
    setEditId(id);
    setVisible(true);
  };

  const handleFilterCallback = async (filtersData: any) => {
    await fetchDataFromApi(getRoleList, setColumnsData, filtersData);
    setPageSize(filtersData?.pagination?.offset?.pageSize);
    setCurrentPage(filtersData?.pagination?.offset?.page);
    setFiltersData(filtersData);
  };

  const handleRoleActivation = async (id: string) => {
    try {
      const isRoleActive = await handleToggleActivationRole(id);
      refetch();
      rolesFetch();
      customNotification(
        "success",
        isRoleActive
          ? t("role-activation-success")
          : t("role-deactivate-success"),
      );
      return isRoleActive;
    } catch (error) {
      handleError(error as string | object);
    }
  };

  useEffect(() => {
    if (permissionsInitialValues.length > 0) {
      setShowPermissionsError(false);
    }
  }, [permissionsInitialValues]);

  const [initialValues, setInitialValues] = useState<Partial<RoleCreate>>({
    name: "",
    description: undefined,
    translations: [],
  });

  useEffect(() => {
    if (editId) {
      handleFetchPreviousRoleData(editId).then((data) => {
        setInitialValues({
          name: data.name,
          description: data?.description,
          translations: data?.translations,
        });
        setPermissionsInitialValues(data.permissions ?? []);
      });
    } else {
      setInitialValues({
        name: "",
        description: "",
        translations: [],
      });
    }
  }, [editId]);

  const onCheck: TreeProps["onCheck"] = (checkedKeys) => {
    let updatedKeys;

    if (typeof checkedKeys === "object" && "checked" in checkedKeys) {
      updatedKeys = checkedKeys.checked.filter((key) => key !== ALL_PERMS);
    } else {
      updatedKeys = checkedKeys.filter((key) => key !== ALL_PERMS);
    }

    setPermissionsInitialValues(updatedKeys as string[]);
  };

  const handleSubmit = async (
    values: Partial<RoleCreate>,
    { resetForm }: FormikHelpers<Partial<RoleCreate>>,
  ) => {
    //this is manual validation for checkboxes this is because we are not handling the checkboxes with formik
    if (permissionsInitialValues.length === 0) {
      setShowPermissionsError(true);
      return;
    }

    try {
      if (!editId) {
        await handleAddNewRole({
          create: {
            ...values,
            name: values.name ?? "",
            permissions: removeDuplicatesInStringArr(permissionsInitialValues),
          },
        });
        customNotification("success", t("role-added-successfully"));
      } else {
        await handleUpdateRole({
          updateObj: {
            ...values,
            permissions: removeDuplicatesInStringArr(permissionsInitialValues),
          },
          updateRoleId: editId,
        });
        customNotification("success", t("role-updated-successfully"));
      }

      resetForm();
      rolesFetch();
      refetch();
      setVisible(false);
      setEditId(null);
      setPermissionsInitialValues([]);
      return editId;
    } catch (error) {
      handleError(error as string | object);
    }
  };

  return {
    handleFilterCallback,
    handleRoleActivation,
    handleMenu,
    editId,
    setEditId,
    setVisible,
    visible,
    columnsData,
    currentPage,
    pageSize,
    isLoading,
    handleSubmit,
    initialValues,
    permissionsInitialValues,
    onCheck,
    permissionsDataArr,
    isRoleLoading,
    ALL_PERMS,
    showPermissionsError,
    handleCancel,
  };
};
