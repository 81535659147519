import styles from "../../CompanyDashboard.module.scss";
import { useTranslation } from "react-i18next";
import { Popover, Typography } from "antd";
import CustomDivider from "components/custom-divider/CustomDivider";
import { useState } from "react";
import { DepartmentsDetails } from "./DepartmentsDetails";

export const AssessmentDepartmentsDetails: React.FC<any> = ({
  allDepartments,
  departmentBarColors,
  assessmentsData,
  otherDepartmentsData,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  const toggleOpen = (val: boolean) => {
    setOpen(val);
  };

  const renderDepartments = () =>
    allDepartments.map((item: any, index: number) => (
      <div
        className={`${styles["department-container"]} ${
          item.isClickable ? "cursor-pointer" : ""
        }`}
        style={{ background: item.isClickable && open ? "#F4F4F4" : undefined }}
        onClick={() => item.isClickable && setOpen(true)}
      >
        <div
          className={styles["department-bar"]}
          style={{
            background: departmentBarColors[index],
          }}
        ></div>
        <div className={styles["department-text-container"]}>
          <Typography className={styles["department-title"]}>
            {item.name}
          </Typography>
          <div className={styles["department-percentage"]}>
            <Typography className={styles["department-desc-text"]}>
              {`${item.submissions.toLocaleString()}/${item.totalEmployees.toLocaleString()}`}
            </Typography>
            <Typography className={styles["department-desc-text"]}>
              {`${((item.submissions / item.totalEmployees) * 100).toFixed(
                0,
              )}%`}
            </Typography>
          </div>
        </div>
      </div>
    ));

  return (
    <div className={styles["total-assessments-container"]}>
      <div className={styles["completed-assessments-container"]}>
        <div className={styles["completed-assessments-box"]}>
          <Typography className={styles["box-main-text"]}>
            {t("total-employees")}
          </Typography>
          <Typography className={styles["box-sub-text"]}>
            {assessmentsData?.totalEmployees.toLocaleString()}
          </Typography>
        </div>
        <div className={styles["completed-assessments-box"]}>
          <Typography className={styles["box-main-text"]}>
            {t("completed-assessments")}
          </Typography>
          <Typography className={styles["box-sub-text"]}>
            {`${assessmentsData.completedAssessments.toLocaleString()} - ${(
              (assessmentsData.completedAssessments /
                assessmentsData.totalEmployees) *
              100
            ).toFixed(0)}%`}
          </Typography>{" "}
        </div>
      </div>
      <CustomDivider className={styles["divider-color"]} />
      <div className={styles["departments-container"]}>
        {renderDepartments()}
      </div>

      <Popover
        overlayInnerStyle={{
          borderRadius: 8,
        }}
        content={
          <DepartmentsDetails
            {...{
              open,
              toggleOpen,
              data: otherDepartmentsData,
            }}
          />
        }
        trigger="click"
        open={open}
        arrowPointAtCenter={true}
        align={{ offset: [10, 50] }}
      ></Popover>
    </div>
  );
};
