import { message, Typography, Upload, UploadFile } from "antd";
import * as Yup from "yup";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { excelToJson, keyMapper, validateFileSize } from "helpers";
import type { UploadProps } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { customNotification } from "../../custom-notification/CustomNotification";
import { yupValidator } from "./yup-validator";
import { StringMap } from "../../interfaces";
import CustomButton from "../../custom-button/CustomButton";
import { useErrorHandler } from "hooks/useErrorHandler";

type Props = {
  name: string;
  accept?: string;
  keyMap?: StringMap;
  validationSchema?: Yup.AnySchema;
  durationFieldName?: string;
  handleUpload: (params: any) => void;
};

export const CustomBulkUpload: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { handleError } = useErrorHandler();

  const uploadProps: UploadProps = {
    showUploadList: false,
    multiple: false,
    accept: props.accept,
    fileList,
    customRequest: () => {},
    beforeUpload: async (file: any) => {
      setFileList([...fileList, file]);

      let excelError = false;
      const extension = file.name.split(".").pop().toLowerCase();
      const fileFormat =
        extension === "xls" || extension === "csv" || extension === "xlsx";

      if (!fileFormat) {
        message.error(`Please upload a valid excel file`);
      }

      const isValidSize = validateFileSize(file);

      if (!isValidSize) {
        message.error("must be smaller than 5MB!");
      }

      let json: any[] = await excelToJson(file);

      if (props.keyMap) {
        json = keyMapper(json, props.keyMap);
      }

      if (props.validationSchema) {
        const validator = Yup.array().of(props.validationSchema);

        try {
          await yupValidator(validator, json);
        } catch (error: any) {
          excelError = true;
          let errorList;

          if (error?.error_list?.length) {
            errorList = formatError(error.error_list).map((x: string) => (
              <Typography.Text>{x}</Typography.Text>
            ));
          }

          customNotification("error", errorList);
        }
      }

      return (!excelError && fileFormat && isValidSize) || Upload.LIST_IGNORE;
    },
    onChange: async (e: any) => {
      let jsonData = await excelToJson<any[]>(e.file.originFileObj);

      if (props.keyMap) {
        jsonData = keyMapper(jsonData, props.keyMap);
      }

      try {
        props.handleUpload(jsonData);
        setFileList([]);
      } catch (error) {
        handleError(error as string | object);
      }
    },
  };

  return (
    <Upload {...uploadProps}>
      <CustomButton icon={<UploadOutlined />} className="light-btn">
        {t("offers.import")}
      </CustomButton>
    </Upload>
  );
};

function formatError(errors: string[] = []): string[] {
  // we receive an error in this format [1].redemptionType

  return errors.map((x: string) => {
    const row = x.split(".")?.[0];
    const msg = x.split(".")?.[1];

    return `Error on row ${row}: ${msg}`;
  });
}
