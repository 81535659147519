import {
  useGetPillarIdAndNameQuery,
  useGetConfigPillarsQuery,
} from "graphql/_generated/graphql";
import { useEffect, useState } from "react";

export const usePillarIdAndName = () => {
  // const { data } = useGetPillarIdAndNameQuery();
  const { data: pillarData } = useGetConfigPillarsQuery();
  // const [pillarsData, setPillarsData] = useState([]);

  // useEffect(() => {
  //   let dummyArr: any = [];
  //   data?.getPillarList.data.map((item) => {
  //     dummyArr.push({ label: item.title, value: item.id, icon: item.icon });
  //   });
  //   setPillarsData(dummyArr);
  // }, [data?.getPillarList.data]);

  return {
    pillarsData: pillarData?.getConfigPillars?.options?.map((item: any) => ({
      label: item?.label,
      value: item.id,
      icon: item?.extra?.icon,
    })),
  };
};
