import {
  GetTopProgramsQueryVariables,
  useLazyGetTopProgramsQuery,
} from "graphql/_generated/graphql";

export const useGetTopPrograms = () => {
  const [topProgramsFun, { isFetching }] = useLazyGetTopProgramsQuery();

  const handleGetTopPrograms = async (values: GetTopProgramsQueryVariables) => {
    try {
      const res = await topProgramsFun(values).unwrap();

      return res.getTopPrograms;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleGetTopPrograms,
    isLoading: isFetching,
  };
};
