import {
  useUpdateProfileMutation,
  MutationUpdateProfileArgs,
  useLazyGetProfileQuery,
} from "graphql/_generated/graphql";

export const useUpdateProfile = () => {
  const [updateProfileMutation, { isLoading: isUpdateLoading }] =
    useUpdateProfileMutation();
  const [getProfileQuery, { isLoading: isProfileLoading }] =
    useLazyGetProfileQuery();

  const handleUpdateProfile = async (values: MutationUpdateProfileArgs) => {
    const { firstName, lastName, image, notificationsEnabled } = values.updates;
    try {
      const result = await updateProfileMutation({
        updates: { firstName, lastName, image, notificationsEnabled },
      }).unwrap();
      return result;
    } catch (error) {
      throw error;
    }
  };

  const handleFetchProfileData = async () => {
    try {
      const result = await getProfileQuery().unwrap();
      return result?.getProfile;
    } catch (error) {
      throw error;
    }
  };

  return {
    loading: isUpdateLoading || isProfileLoading,
    handleUpdateProfile,
    handleFetchProfileData,
  };
};
