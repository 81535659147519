import { useGetCheckinStats } from "features/company-dashboard/hooks/useGetCheckinStats";
import {
  getFetchingStatus,
  getFormattedDateRanges,
  timeFilterOptions,
} from "features/company-dashboard/utils";
import { CheckInStats, UserFilters } from "graphql/_generated/graphql";
import { useEffect, useState } from "react";

interface CheckinOptionsProps extends CheckInStats {
  totalSubmissions: number;
}

interface CheckinDataProps {
  totalSubmissions: number;
  pastSubmissions: number;
  lowScores: number;
  isDataConcealed: boolean;
  options: CheckinOptionsProps[] | [];
}

export interface CheckInProps {
  item: any;
  userFilters: UserFilters;
  visible: boolean;
}

export const useCheckinViewModel = ({
  item,
  userFilters,
  visible,
}: CheckInProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [checkinData, setCheckinData] = useState<CheckinDataProps>({
    totalSubmissions: 0,
    pastSubmissions: 0,
    isDataConcealed: false,
    options: [],
    lowScores: 0,
  });

  const { handleGetCheckinStats, isLoading } = useGetCheckinStats();

  useEffect(() => {
    const isFetching = getFetchingStatus(item.selectedValues);
    if (isFetching) getCheckinsData();
  }, [item.selectedValues, userFilters, item?.options]);

  const getCheckinsData = () => {
    if (item?.options) {
      const { startingDate, endingDate } = getFormattedDateRanges(
        item.selectedValues,
      );
      handleGetCheckinStats({
        startDate: startingDate,
        endDate: endingDate,
        type: item.type,
        userFilters,
      }).then((data) => {
        const checkinsData = { ...data };

        const allCheckinOptions = Object.keys(item.options);
        const submittedCheckinOptions = checkinsData?.options?.map(
          (item) => item.optionId,
        );

        const optionsWithNoSubmission = allCheckinOptions.filter(
          (val) => !submittedCheckinOptions.includes(val),
        );

        const mappedOptionsWithNoSubmission = optionsWithNoSubmission?.map(
          (option) => ({
            ...item.options[option],
            totalSubmissions: 0,
          }),
        );

        const mappedOptions = checkinsData?.options?.map((option) => ({
          totalSubmissions: option.totalSubmissions,
          ...item.options?.[option?.optionId],
        }));

        setCheckinData({
          ...checkinsData,
          options: [...mappedOptions, ...mappedOptionsWithNoSubmission],
        });
      });
    }
  };

  useEffect(() => {
    visible && toggleOpen(false);
  }, [visible]);

  const handleRefresh = () => {
    getCheckinsData();
  };

  const toggleOpen = (val: boolean) => {
    setOpen(val);
  };

  const pastSubmissionsComparison =
    ((checkinData?.totalSubmissions - checkinData?.pastSubmissions) /
      checkinData?.pastSubmissions) *
    100;

  const pastTotalSubmissions =
    (checkinData?.options?.[0]?.totalSubmissions /
      checkinData?.totalSubmissions) *
    100;

  const pastTimeLabel = timeFilterOptions.find(
    (time) => time.value === item.selectedValues?.pastDays,
  );
  return {
    handleRefresh,
    isLoading,
    checkinData,
    pastTimeLabel,
    pastTotalSubmissions,
    pastSubmissionsComparison,
    open,
    toggleOpen,
  };
};
