import { DeleteFilled } from "@ant-design/icons";
import { Avatar, Col, Divider, Radio, Row, Select, Space } from "antd";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { CustomVideoUpload } from "components/form/custom-video-upload/CustomVideoUpload";
import { FieldArray, FormikValues } from "formik";
import {
  ContentType,
  MutationDeleteSubtitleArgs,
} from "graphql/_generated/graphql";
import styles from "features/content/css/AddContent.module.scss";
import CustomShowDuration from "../CustomShowDuration";
import {
  checkTypeAudio,
  filterLanguagesWithout,
  getLabelFromValue,
} from "../utils";
import { CustomUploadFile } from "components/form/custom-upload-file/CustomUploadFile";
import CustomTextEditor from "components/form/custom-text-editor/CustomTextEditor";
import CustomButton from "components/custom-button/CustomButton";
import { useSpecialistConfigList } from "hooks/useSpecialistConfig";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import { useTranslation } from "react-i18next";
const { Option } = Select;
const EXCLUDED_LANG = undefined;

const DynamicContent = (props: {
  editId: string;
  value: string | null;
  values: FormikValues;
  languages: { label: string; value: string }[];
  handleDeleteSubTitle: (values: MutationDeleteSubtitleArgs) => Promise<void>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
}) => {
  const { t } = useTranslation();
  const { getSpecialistConfigData } = useSpecialistConfigList();
  const { values, setFieldValue, editId, languages } = props;
  const isTypeAudio = checkTypeAudio(values);

  return (
    <>
      {/* This field array handles the dynamic content upload */}
      {values.contentType !== "Recipe" && (
        <FieldArray
          name={
            isTypeAudio
              ? "localizedAudios"
              : values.contentType === ContentType.Video
              ? "localizedVideos"
              : values.contentType === ContentType.File
              ? "localizedFiles"
              : values.contentType === ContentType.Text
              ? "localizedTexts"
              : ""
          }
          render={(arrayHelpers) => {
            const { contentType } = values;
            const dynamicContentType = isTypeAudio
              ? "localizedAudios"
              : contentType === ContentType.Video
              ? "localizedVideos"
              : contentType === ContentType.File
              ? "localizedFiles"
              : "localizedTexts";

            const urlData = contentType === "Text" ? "text" : "url";
            return (
              <div>
                {values[dynamicContentType] &&
                  values[dynamicContentType].length > 0 &&
                  values[dynamicContentType].map(
                    (_value: any, index: number) => {
                      return (
                        <>
                          <div className={styles["divider"]}>
                            <div key={index}>
                              <Row gutter={[24, 0]}>
                                <Col sm={24} md={12}>
                                  <CustomSelectField
                                    label={t(
                                      isTypeAudio ||
                                        contentType === ContentType.Video
                                        ? "other-spoken-language"
                                        : "other-content-language",
                                    )}
                                    required={true}
                                    value={getLabelFromValue(
                                      languages,
                                      values[dynamicContentType][index]
                                        ?.language,
                                    )}
                                    name={`[${dynamicContentType}].${index}.language`}
                                    placeholder={t("select-language")}
                                    options={languages}
                                    defaultValue={getLabelFromValue(
                                      languages,
                                      values[dynamicContentType][index]
                                        ?.language,
                                    )}
                                    className="form-select"
                                  />
                                </Col>
                                <Col sm={24} md={12}>
                                  <CustomSelectField
                                    label={t("select-specialist")}
                                    name={`[${dynamicContentType}].${index}.specialist`}
                                    className="form-select"
                                    placeholder={t("select-specialist")}
                                  >
                                    {getSpecialistConfigData?.map(
                                      (item: any, index: number) => (
                                        <Option value={item.value} key={index}>
                                          <Space>
                                            <CustomAvatar imgSrc={item.image} />
                                            {item.label}
                                          </Space>
                                        </Option>
                                      ),
                                    )}
                                  </CustomSelectField>
                                </Col>
                              </Row>

                              {/* Upload content file for video type */}
                              {values.contentType === "Video" && (
                                <>
                                  <CustomVideoUpload
                                    label={t("upload-content-file")}
                                    name={`[${dynamicContentType}].${index}.${urlData}`}
                                    setFieldValue={setFieldValue}
                                    title={values.title}
                                    description={values.description}
                                    accept="video/*"
                                    durationFieldName={`[${dynamicContentType}].${index}.duration`}
                                    videoUrl={
                                      values.localizedVideos[index]?.url
                                    }
                                    disable={
                                      values.title?.length &&
                                      values.description?.length
                                        ? false
                                        : true
                                    }
                                  />

                                  {/* Duration Only shows if content type is video */}
                                  {values.localizedVideos[index].duration && (
                                    <CustomShowDuration
                                      values={
                                        values.localizedVideos[index].duration
                                      }
                                    />
                                  )}

                                  {/* if edit id exist then this video will show here */}
                                  {editId && (
                                    <iframe
                                      title="Vieme"
                                      frameBorder="0"
                                      allowFullScreen
                                      allow="autoplay; encrypted-media"
                                      src={values.localizedVideos[index]?.url}
                                    ></iframe>
                                  )}
                                </>
                              )}

                              {/* Upload content file for audio and file types */}
                              {(isTypeAudio ||
                                values.contentType === "File") && (
                                <CustomUploadFile
                                  durationFieldName={
                                    isTypeAudio
                                      ? `[${dynamicContentType}].${index}.duration`
                                      : undefined
                                  }
                                  accept={
                                    isTypeAudio
                                      ? ".mp3"
                                      : "application/pdf,.csv, .csv, .xlsx, .txt , .ppt, .xls, .pptx, .docx"
                                  }
                                  label={t("upload-content-file")}
                                  name={`[${dynamicContentType}].${index}.${urlData}`}
                                  setFieldValue={setFieldValue}
                                  imgUrl={
                                    values[dynamicContentType][index]?.url
                                  }
                                />
                              )}

                              {/* Show duration for audio type */}
                              {values.localizedAudios[index]?.duration && (
                                <CustomShowDuration
                                  values={
                                    values.localizedAudios[index]?.duration
                                  }
                                />
                              )}

                              {/* Add content in text area for text type */}
                              {values.contentType === "Text" && (
                                <CustomTextEditor
                                  name={`[${dynamicContentType}].${index}.${urlData}`}
                                  label={t("write-content")}
                                  placeholder={t("enter-content-title")}
                                />
                              )}
                            </div>
                            {values[dynamicContentType]?.length > 0 && (
                              <CustomButton
                                type="link"
                                className="remove-btn"
                                onClick={() => arrayHelpers.remove(index)} // remove a language from the list
                              >
                                <DeleteFilled />
                              </CustomButton>
                            )}
                          </div>
                          <Divider />
                        </>
                      );
                    },
                  )}

                <div className={styles["flex-end"]}>
                  {values[dynamicContentType].length + 1 < languages.length &&
                    values.contentType !== "Recipe" && (
                      <div className={styles["form-control"]}>
                        <span
                          className={styles["add-btn"]}
                          onClick={() =>
                            arrayHelpers.push({
                              [urlData]: undefined,
                              language: undefined,
                            })
                          }
                        >
                          + {t("add-translated")} {values.contentType}
                        </span>
                      </div>
                    )}
                </div>
              </div>
            );
          }}
        />
      )}
    </>
  );
};

export default DynamicContent;
