import { FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuest } from "./useQuest";
import {
  CreateQuestMutation,
  GetQuestByIdQuery,
  GetQuestTypesQuery,
  GetQuestsQuery,
  QuestCreate,
  SetQuestTranslationMutation,
  UpdateQuestMutation,
  useGetQuestTypesQuery,
  useGetQuestsQuery,
  useLazyGetQuestsQuery,
} from "graphql/_generated/graphql";
import customNotification from "components/custom-notification/CustomNotification";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";

export type SubmitQuestResponse =
  | Omit<CreateQuestMutation["createQuest"], "__typename">
  | Omit<UpdateQuestMutation["updateQuest"], "__typename">
  | Omit<SetQuestTranslationMutation["setQuestTranslation"], "__typename">;

export const useDailyQuestViewModal = () => {
  type QuestRecord = NonNullable<typeof mappedColumnsData>[0];
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();

  const [editId, setEditId] = useState<string | null>(null);
  const [langVisibility, setLangVisibility] = useState(false);
  const [langCode, setLangCode] = useState<string>();
  const [isAddLanguage, setIsAddLanguage] = useState(false);
  const [isAddQuest, setIsAddQuest] = useState(false);
  const [questData, setQuestData] =
    useState<GetQuestByIdQuery["getQuestById"]>();
  const [questRecord, setQuestRecord] = useState<QuestRecord>();

  const {
    handleAddQuest,
    handleDeleteQuest,
    handleToggleQuest,
    handleGetQuest,
    handleUpdateQuest,
    handleAddQuestTranslations,
    isLoading: isLoadingAddQuest,
  } = useQuest();

  const {
    data,
    refetch: refetchQuestTypes,
    isLoading: isLoadingQuestTypes,
  } = useGetQuestTypesQuery();

  const questTypesData = data?.getQuestTypes;

  const [columnsData, setColumnsData] = useState<GetQuestsQuery["getQuests"]>();
  const [filtersData, setFiltersData] = useState<any>(null);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const { isFetching, refetch: refetchQuestList } = useGetQuestsQuery();
  const [getQuestListFun] = useLazyGetQuestsQuery();
  const { fetchDataFromApi, isLoading: isLoadingQuestList } =
    useFetchDataFromApi();

  useEffect(() => {
    fetchDataFromApi(getQuestListFun, setColumnsData, filtersData).catch((e) =>
      handleError(e),
    );
  }, [isFetching, filtersData]);

  const filtersCallBack = async (filtersData: any) => {
    try {
      setFiltersData(filtersData);
      setPageSize(filtersData?.pagination?.offset?.pageSize);
      setCurrentPage(filtersData?.pagination?.offset?.page);
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const mappedColumnsData = columnsData?.map((item) => {
    return (
      {
        ...item,
        hasChildren: true,
        children:
          item?.translations && Object.keys(item?.translations).length
            ? Object.keys(item?.translations).map((record: string) => {
                return {
                  ...item,
                  title: item?.translations?.[record]?.title,
                  language: record,
                };
              })
            : null,
      } ?? []
    );
  });

  const combinedData = [
    ...(questTypesData ?? []),
    (questData &&
      questData.questType && {
        name: questData.questType.name,
        id: questData.questType.id,
      }) ??
      [],
  ];
  const uniqueOptions = Array.from(
    new Set(combinedData.map((item) => JSON.stringify(item))),
  )
    .map((itemString) => JSON.parse(itemString))
    .filter(
      (item) => Object.keys(item).length > 0,
    ) as GetQuestTypesQuery["getQuestTypes"];

  const onAddQuestSubmit = async (
    values: QuestCreate,
    formikHelpers?: FormikHelpers<typeof values>,
  ) => {
    try {
      let res: SubmitQuestResponse;
      if (!editId && !isAddLanguage) {
        res = await handleAddQuest({ quest: values });
        customNotification("success", t("quest-added-successfully"));
      } else if (isAddLanguage && editId && langCode) {
        res = await handleAddQuestTranslations({
          id: editId,
          translationInput: {
            translations: {
              [langCode]: {
                title: values.title,
              },
            },
          },
        });
        customNotification("success", t("quest-translations-added"));
      } else {
        res = await handleUpdateQuest({
          updateQuestId: editId,
          updateObj: values,
        });
        customNotification("success", t("quest-updated-successfully"));
      }

      formikHelpers?.resetForm();
      onCloseAddQuest();
      refetchQuestTypes();
      refetchQuestList();

      return res;
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const onLanguageSubmit = (
    values: { language: string },
    actions?: FormikHelpers<typeof values>,
  ) => {
    setLangCode(values?.language);
    setLangVisibility(false);
    actions?.resetForm();
    setIsAddQuest(true);
  };

  const onCloseLangDialog = () => {
    setLangVisibility(false);
    setIsAddLanguage(false);
  };

  const onCloseAddQuest = () => {
    setIsAddQuest(false);
    setEditId(null);
    setQuestData(undefined);
    setIsAddLanguage(false);
    setQuestRecord(undefined);
    setLangCode(undefined);
  };

  const onPressAddQuest = () => {
    setLangVisibility(true);
  };

  const onPressAddLang = (id: string, record: QuestRecord) => {
    setEditId(id);
    setLangVisibility(true);
    setIsAddLanguage(true);
    setQuestRecord(record);
  };

  const onPressUpdateLanguage = (id: string, record: QuestRecord) => {
    setLangCode(record.language);
    setIsAddLanguage(true);
    setEditId(id);
    setIsAddQuest(true);
  };

  const onDeleteQuest = async (id: string) => {
    try {
      const res = await handleDeleteQuest(id);
      customNotification("success", t("quest-deleted-successfully"));
      refetchQuestList();
      refetchQuestTypes();
      return res;
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const onQuestUpdate = (id: string) => {
    setEditId(id);
    setIsAddQuest(true);
  };

  const onToggleQuest = async (id: string) => {
    try {
      const isQuestActive = await handleToggleQuest(id);
      customNotification(
        "success",
        isQuestActive
          ? t("quest-activated-successfully")
          : t("quest-deactivated-successfully"),
      );
      refetchQuestList();
      refetchQuestTypes();
      return isQuestActive;
    } catch (error) {
      handleError(error as string | object);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (editId) {
          const data = await handleGetQuest(editId);
          const translationData = langCode && data?.translations?.[langCode];
          setQuestData({
            ...data,
            title: isAddLanguage ? translationData?.title : data?.title,
          });
        }
      } catch (error) {
        handleError(error as string | object);
      }
    };

    fetchData();
  }, [editId]);

  return {
    editId,
    langVisibility,
    langCode,
    isAddLanguage,
    isAddQuest,
    questTypesData: uniqueOptions,
    QuestListData: mappedColumnsData,
    questData,
    columnsData,
    currentPage,
    pageSize,
    questRecord,
    filtersCallBack,
    onLanguageSubmit,
    onCloseLangDialog,
    onCloseAddQuest,
    onPressAddQuest,
    onAddQuestSubmit,
    onPressAddLang,
    onQuestUpdate,
    onDeleteQuest,
    onToggleQuest,
    onPressUpdateLanguage,
    loading: { isLoadingAddQuest, isLoadingQuestList },
  };
};
