import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import HabitList from "features/habits/habit-list/HabitList";
import * as React from "react";
import { useTranslation } from "react-i18next";

interface HabitsProps {}

const Habits: React.FunctionComponent<HabitsProps> = (props) => {
  const { t } = useTranslation();
  return (
    <DashboardLayout openKey={["Activity"]}>
      <Breadcrumbs
        data={[
          {
            label: t("activities"),
          },
          {
            label: t("habits"),
          },
        ]}
      />
      <HabitList />
    </DashboardLayout>
  );
};

export default Habits;
