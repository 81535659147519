import { PERMS } from "helpers"
import CheckInCreate from "pages/assessment/CheckInCreate"
import AddAssessment from "pages/assessment/AddAssessment"
import AssessmentsList from "pages/assessment/AssessmentsList"
import AssessmentTypes from "pages/assessment/AssessmentTypes"
import AssessmentLanguage from "features/assessment/language/AssessmentLanguage"
import CheckInLanguage from "features/assessment/language/CheckInLanguage"

// import AssessmentCreate from "views/pages/assessment/create/AssessmentCreate"
// import Assessment from "app/components/assessment/assessment-engine/Assessment"
// import AssessmentType from "app/components/assessment/assessment-type/assessmentType"
// import AssessmentEngine from "app/components/assessment/assessment-engine/AssessmentEngine"

const AssessmentRoutes = {
  AssessmentListRoute: {
    path: "/assessment-list",
    element: <AssessmentsList />,
    // permission: PERMS.GET_ALL_ASSESSMENT,
  },

  AssessmentTypesRoute: {
    path: "/assessment-types",
    element: <AssessmentTypes />,
    // permission: PERMS.GET_ALL_ASSESSMENT_TYPES,
  },

  AssessmentCreateRoute: {
    path: "/assessment/create",
    element: <AddAssessment />,
    permission: PERMS.GET_ALL_ASSESSMENT,
  },

  AssessmentCreateCheckInRoute: {
    path: "/assessment/create/checkin",
    element: <CheckInCreate />,
    permission: PERMS.GET_ALL_ASSESSMENT,
  },

  AssessmentLanguageRoute: {
    path: "/assessment/add-language",
    element: <AssessmentLanguage />,
    permission: PERMS.GET_ALL_ASSESSMENT,
  },

  AssessmentCheckInLanguageRoute: {
    path: "/assessment/checkin/add-language",
    element: <CheckInLanguage />,
    permission: PERMS.GET_ALL_ASSESSMENT,
  },

  // AssessmentAddRoute: {
  //   path: "/add-assessment",
  //   element: <AssessmentEngine />,
  // },

  // AssessmentEditRoute: {
  //   path: "/edit-assessment/:id",
  //   element: <AssessmentEngine />,
  //   permission: PERMS.GET_ASSESSMENT,
  // },

}

export default AssessmentRoutes
