import { Select, Space } from "antd";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import { BranchesOutlined, BarsOutlined } from "@ant-design/icons";
import CustomButton from "components/custom-button/CustomButton";
import { PERMS } from "helpers";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CompaniesTreeList from "features/company/companies-list/CompaniesTreeList";
import InviteCompany from "features/company/invite-company/InviteCompany";
import styles from "features/company/css/CompanyList.module.scss";
import CompaniesFlatList from "features/company/companies-list/CompaniesFlatList";
import CustomSegment from "components/segment/CustomSegment";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";

const Companies: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const { Option } = Select;
  const [view, setView] = useState<string | number | null>("list");
  const handleChange = (value: string | number) => {
    setView(value);
  };

  const options = [
    {
      value: "list",
      label: t("list-view"),
      icon: <BarsOutlined />,
    },
    {
      value: "tree",
      label: t("tree-view"),
      icon: <BranchesOutlined />,
    },
  ];

  return (
    <>
      <DashboardLayout openKey={["Users"]}>
        <div className={styles["company"]}>
          <Breadcrumbs
            data={[
              {
                label: "Users",
              },
              {
                label: t("company.all-companies"),
              },
            ]}
          />
          <div className={styles["header"]}>
            <h1 className={styles["title"]}>{t("company.all-companies")}</h1>
          </div>
          <div className={styles["content-view"]}>
            <CustomSegment options={options} onChange={handleChange} />
          </div>
          <div className={styles["company-list"]}>
            {view === "list" ? (
              <CompaniesFlatList setInviteVisible={setVisible} />
            ) : (
              <CompaniesTreeList setInviteVisible={setVisible} />
            )}
          </div>
        </div>
      </DashboardLayout>
      <InviteCompany visible={visible} setVisible={setVisible} />
    </>
  );
};

export default Companies;
