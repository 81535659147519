import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { CustomTextArea } from "components/form/custom-text-area/CustomTextArea";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { CustomUploadFile } from "components/form/custom-upload-file/CustomUploadFile";
import { Form, Formik } from "formik";
import {
  PartnerCreate,
  RegionVisibility,
  useGetConfigCountriesQuery,
  useGetPartnerDetailsQuery,
  useGetConfigPartnerTypesQuery,
} from "graphql/_generated/graphql";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AddPartnerSchema } from "validations/schema/addPartnerSchema";
import { useTier } from "../hooks/useTier";
import customNotification from "components/custom-notification/CustomNotification";
import { errorFormat } from "helpers/errorFormat";
import { getLanguage } from "helpers";
import { CustomCheckBoxGroup } from "components/form/custom-checkbox-group/CustomCheckBoxGroup";
import { useErrorHandler } from "hooks/useErrorHandler";

interface AddPartnerProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  editId?: string | null;
  setEditId: React.Dispatch<React.SetStateAction<string | null>>;
  langCode?: string;
  isAddLanguage?: boolean;
}

const AddPartner: React.FunctionComponent<AddPartnerProps> = ({
  visible,
  setVisible,
  editId,
  setEditId,
  langCode,
  isAddLanguage,
}) => {
  const handleCancel = () => {
    setVisible(false);
    setEditId?.(null);
  };
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const { data: partnerType } = useGetConfigPartnerTypesQuery();
  const { data: countries } = useGetConfigCountriesQuery();
  const { refetch: refetchPartner } = useGetPartnerDetailsQuery({
    getPartnerDetailsId: editId,
  });

  const {
    regionVisibility,
    handleAddPartner,
    handleGetPartnerDetail,
    handleUpdatePartner,
    isLoading,
  } = useTier();

  const [initialValues, setInitialValues] = useState<PartnerCreate>({
    language: "",
    image: "",
    name: "",
    description: "",
    partnerTypeId: "",
    code: "",
    regionVisibility: RegionVisibility.Global,
    countriesListId: [],
  });

  useEffect(() => {
    if (editId) {
      handleGetPartnerDetail(editId).then((data) => {
        const translationData = langCode && data?.translations?.[langCode];
        const countries =
          data.countriesList && data.countriesList?.map((obj) => obj.id);
        setInitialValues({
          image: data.image,
          language: data.language,
          partnerTypeId: data.partnerType.id,
          code: data.code,
          regionVisibility: data.regionVisibility,
          countriesListId: countries,
          isCodeEnabled: data?.isCodeEnabled,
          name: isAddLanguage ? translationData?.name : data?.name,
          description: isAddLanguage
            ? translationData?.description
            : data?.description,
        });
      });
    }
  }, [editId]);
  const langLabel = getLanguage(langCode ?? "");
  return (
    <CustomModal
      visible={visible}
      onCancel={handleCancel}
      width={600}
      footer={false}
      title={
        editId && !isAddLanguage
          ? t("edit-partner")
          : `${t("add-partner")} ${isAddLanguage ? t("language") : ""}`
      }
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={AddPartnerSchema}
        onSubmit={async (values, { resetForm }) => {
          if (!editId && !isAddLanguage) {
            try {
              await handleAddPartner({
                create: {
                  ...values,
                  code: String(values.code),
                  language: langCode,
                },
              });
              customNotification("success", t("partner-added-successfully"));
              resetForm();
              setVisible(false);
              refetchPartner();
            } catch (error) {
              handleError(error as string | object);
            }
          } else if (isAddLanguage && editId && langCode) {
            try {
              await handleUpdatePartner({
                update: {
                  translations: {
                    [langCode]: {
                      name: values.name,
                      description: values.description,
                    },
                  },
                },
                id: editId,
              });
              setEditId(null);
              customNotification(
                "success",
                t("partner-language-added-successfully"),
              );
              resetForm();
              setVisible(false);
            } catch (error) {
              handleError(error as string | object);
            }
          } else {
            try {
              await handleUpdatePartner({
                id: editId,
                update: { ...values, code: String(values.code) },
              });
              customNotification("success", t("partner-updated-successfully"));
              refetchPartner();
              setEditId(null);
              setVisible(false);
              resetForm();
            } catch (error) {
              handleError(error as string | object);
            }
          }
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            {!isAddLanguage && (
              <CustomUploadFile
                name="image"
                setFieldValue={setFieldValue}
                accept="image/*"
                imgUrl={values.image}
                infoText={t("partner-image")}
                label={t("partner-image")}
              />
            )}
            <CustomTextField
              name="name"
              label={t("name")}
              placeholder={t("enter-name")}
            />
            <CustomTextArea
              name="description"
              label={t("description")}
              rows={6}
              placeholder={t("enter-partner-description")}
            />
            {!isAddLanguage && (
              <>
                <CustomSelectField
                  name="partnerTypeId"
                  label={t("partner-type")}
                  placeholder={t("select-partner-type")}
                  options={partnerType?.getConfigPartnerTypes.options}
                  fieldNames={{ label: "label", value: "id" }}
                />

                <div className="form-control">
                  <CustomCheckBoxGroup name="isCodeEnabled" options={[]}>
                    {t("enable-partner-code")}
                  </CustomCheckBoxGroup>
                </div>

                <CustomTextField
                  name="code"
                  label={t("partnerCode")}
                  placeholder={t("enter-partner-code")}
                  type="number"
                />

                <CustomSelectField
                  name="regionVisibility"
                  label={t("region-visibility")}
                  placeholder={t("select-region-visibility")}
                  options={regionVisibility}
                />

                {values.regionVisibility == RegionVisibility.Local && (
                  <CustomSelectField
                    mode="multiple"
                    name="countriesListId"
                    label={t("countries")}
                    placeholder={t("select-country")}
                    options={countries?.getConfigCountries.options}
                    fieldNames={{ label: "label", value: "id" }}
                  />
                )}
              </>
            )}
            <ModalFooter
              saveText={t("save-partner")}
              onCancel={handleCancel}
              loading={isLoading}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddPartner;
