import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Select, Space, Dropdown, Modal } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";

import {
  CreateWellBeingCalender,
  useGetWellBeingCalenderListQuery,
  useLazyGetWellBeingCalenderListQuery,
  WellBeingCalenderList,
} from "graphql/_generated/graphql";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import years from "../constants/years";
import months from "../constants/months";

import CustomTable from "components/custom-table/CustomTable";
import CustomMenu from "components/custom-menu/CustomMenu";
import CustomButton from "components/custom-button/CustomButton";
import AddWellbeingCalendar from "../add/AddWellbeingCalendar";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import { errorFormat, PERMS } from "helpers";
import { CONFIRM_MODAL_CONFIG } from "helpers/constants";
import customNotification from "components/custom-notification/CustomNotification";
import { useDeleteWellBeingCalender } from "../hooks/useDeleteWellBeingCalender";
import { CalendarSvg } from "assets/icons/CalendarSvg";
import styles from "../css/WellbeingCalendar.module.scss";
import { useErrorHandler } from "hooks/useErrorHandler";

interface WellbeingCalendarListProps {}

const { Option } = Select;

const WellbeingCalendarList: FC<WellbeingCalendarListProps> = () => {
  // Hooks and variables declarations
  const { t } = useTranslation();
  const [filtersData, setFiltersData] = useState<Record<string, any> | null>(
    null,
  );
  const [columnsData, setColumnsData] = useState<any>();
  const [editId, setEditId] = useState<string | null>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [wellBeingCalender, setWellBeingCalender] =
    useState<CreateWellBeingCalender | null>(null);
  const [getAllDataFun] = useLazyGetWellBeingCalenderListQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const { isFetching, refetch } = useGetWellBeingCalenderListQuery();
  const { handleDeleteWellBeingCalender } = useDeleteWellBeingCalender();
  const { handleError } = useErrorHandler();

  // Functions and side effects
  useEffect(() => {
    fetchDataFromApi(getAllDataFun, setColumnsData, filtersData).catch((e) =>
      handleError(e),
    );
  }, [isFetching]);

  const handleMenu = ({ key, id, record }: any) => {
    if (key === "edit") {
      setVisible(true);
      setEditId(id);
      setWellBeingCalender(record);
    } else if (key === "delete") {
      Modal.confirm({
        ...CONFIRM_MODAL_CONFIG,
        title: t("confirm-delete"),
        content: (
          <>
            <div>{record?.name}</div>
          </>
        ),
        onOk: async () => {
          try {
            await handleDeleteWellBeingCalender(id);
            customNotification("success", t("wellbeing.removed-successfully"));
            refetch();
          } catch (error) {
            handleError(error as string | object);
          }
        },
      });
    }
  };

  const columns: ColumnsType<WellBeingCalenderList> = [
    {
      title: `${t("id")}`,
      key: "id",
      dataIndex: "id",
      sorter: {
        multiple: 1,
      },
      render: (_text: any, _record: any, index: number) =>
        (currentPage - 1) * pageSize + index + 1,
    },

    {
      title: t("wellbeing.year"),
      key: "year",
      dataIndex: "year",
    },
    {
      title: t("wellbeing.month"),
      key: "month",
      dataIndex: "month",
    },
    {
      title: t("wellbeing.url"),
      key: "url",
      dataIndex: "url",
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      align: "center" as "center",
      render: (id: string, record: WellBeingCalenderList) => (
        <Dropdown
          overlay={
            <CheckPermissions permission={PERMS.UPDATE_WELLBEING_CALENDER}>
              <CustomMenu
                items={[
                  { label: t("wellbeing.edit"), key: "edit" },
                  { label: t("wellbeing.delete"), key: "delete" },
                ]}
                onClick={({ domEvent, key, keyPath }) =>
                  handleMenu({ domEvent, key, keyPath, id, record })
                }
              />
            </CheckPermissions>
          }
        >
          <CustomButton type="link" className="link-btn">
            <MoreOutlined />
          </CustomButton>
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      <CustomTable
        filters={{
          handleCallback: async (filtersData: Record<string, any>) => {
            await fetchDataFromApi(getAllDataFun, setColumnsData, filtersData);
            setPageSize(filtersData?.pagination?.offset?.pageSize);
            setCurrentPage(filtersData?.pagination?.offset?.page);
            setFiltersData(filtersData);
          },
          // search: true,
          manualFilters: [
            {
              key: "year",
              comparison: "Equal",
              applyAs: "and",
              render: (
                onChangeCallback: (value: string) => void,
                value: string,
              ) => (
                <div className={styles["select-icon"]}>
                  <CalendarSvg />
                  <Select
                    allowClear
                    placeholder={t("wellbeing.select-year")}
                    onChange={onChangeCallback}
                    className="filter-select no-highlight"
                    value={value}
                    style={{ paddingLeft: 22 }}
                  >
                    {years?.map((year: string, index: number) => (
                      <Option value={year} key={index}>
                        <Space>{year}</Space>
                      </Option>
                    ))}
                  </Select>
                </div>
              ),
            },
            {
              key: "month",
              comparison: "Equal",
              applyAs: "and",
              render: (
                onChangeCallback: (value: string) => void,
                value: string,
              ) => (
                <div className={styles["select-icon"]}>
                  <CalendarSvg />
                  <Select
                    allowClear
                    placeholder={t("wellbeing.select-month")}
                    onChange={onChangeCallback}
                    className="filter-select no-highlight"
                    value={value}
                    style={{ paddingLeft: 22 }}
                  >
                    {months?.map((month: string, index: number) => (
                      <Option value={month} key={index}>
                        <Space>{month}</Space>
                      </Option>
                    ))}
                  </Select>
                </div>
              ),
            },
          ],
        }}
        rowKey="id"
        loading={isLoading}
        columns={columns}
        onClick={() => setVisible(true)}
        headerTitle={t("wellbeing.title")}
        btnText={t("wellbeing.add")}
        totalItems={columnsData?.metadata?.total}
        dataSource={columnsData?.data}
      />
      <AddWellbeingCalendar
        visible={visible}
        setVisible={setVisible}
        editId={editId}
        setEditId={setEditId}
        data={wellBeingCalender}
      />
    </>
  );
};

export default WellbeingCalendarList;
