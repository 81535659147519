import styles from "features/assessment/css/AssessmentCreate.module.scss";

import Icon from "@ant-design/icons";
import { Form, Space, Typography } from "antd";
import { ConfigSvg } from "assets/icons";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import CustomButton from "components/custom-button/CustomButton";
import { customNotification } from "components/custom-notification/CustomNotification";
import { CustomTextArea } from "components/form/custom-text-area/CustomTextArea";
import Pillar from "features/assessment/pillar/Pillar";
import ContainerHeading from "features/assessment/questions/ContainerHeading";
import CollapseAble from "features/assessment/questions/collapseables";
import AssessmentSettings from "features/assessment/settings/AssessmentSettings";
import { AssessmentTransformer } from "features/assessment/transformers";
import { optionKey } from "features/assessment/transformers/AssessmentTransformer";
import EnumBoolean from "features/assessment/types/EnumBoolean";
import IAssessmentPillarHash from "features/assessment/types/IAssessmentPillarHash";
import IAssessmentSettings from "features/assessment/types/IAssessmentSettings";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import { Formik } from "formik";
import {
  Language,
  useDeleteQuestionMutation,
  useGetConfigTagsQuery,
  useLazyGetAdminAssessmentQuestionsQuery,
  useUpdateAssessmentMutation,
} from "graphql/_generated/graphql";
import { getLanguage } from "helpers";
import { unixTimestampMs } from "helpers/DatetimeUtils";
import { RetryHelper } from "helpers/RetryHelper";
import generateUniqueId from "helpers/generateUniqueId";
import { useErrorHandler } from "hooks/useErrorHandler";
import { debounce } from "lodash";
import { Fragment, memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import AssessmentRoutes from "routes/RouteCollections/AssessmentRoutes";
import { RootState } from "store/store";
import generateNewQuestion from "../../features/assessment/data/generateNewQuestion";
import initialPillarsArray from "../../features/assessment/data/initialPillarsArray";
import settingInitialValues from "../../features/assessment/data/settingInitialValues";
import { subQuestionsBasedOptionTypes } from "../../features/assessment/data/subQuestionBasedOptionTypes";
import { useGetAssessmentSettings } from "../../features/assessment/hooks/useGetAssessmentSettings";

const { Text } = Typography;

interface NavigationProps {
  message?: string;
  showMessage?: boolean;
}

/**
 * Create Assessment page component.
 *
 * @returns JSX
 */
const AddAssessment: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const params = location.state;

  const [loadingAssessment, setLoadingAssessment] = useState<boolean>(true);
  const [settingsVisible, setSettingsVisible] = useState<boolean>(false);
  const [savingDescription, setSavingDescription] = useState<string | null>(
    null,
  );
  const [savingAssessment, setSavingAssessment] = useState<boolean>(false);

  const [assessmentId, setAssessmentId] = useState<string>(
    params?.assessmentId || "",
  );

  const [settingsInitialValues, setSettingsInitialValues] = useState<any>({
    notificationSetting: [],
    deleteNotifications: [],
  });

  const [tags, setTags] = useState<any>({ isLoading: true, data: [] });

  const [updateAssessment, { isLoading: updateAssessmentLoading }] =
    useUpdateAssessmentMutation();

  const [deleteAssessmentQuestion] = useDeleteQuestionMutation();

  const [getAdminAssessmentQuestions, { isLoading }] =
    useLazyGetAdminAssessmentQuestionsQuery();

  const { data: tagsData, isLoading: tagsLoading } = useGetConfigTagsQuery();
  const { handleGetAssessmentSettings, isLoadingAssessmentSettings } =
    useGetAssessmentSettings();
  const region = useSelector((state: RootState) => state.auth.region);

  const [settings, updateSettings] =
    useState<IAssessmentSettings>(settingInitialValues);

  const [assessmentPillars, setAssessmentPillars] =
    useState<IAssessmentPillarHash>(initialPillarsArray);

  const [initialValues, setInitialValues] = useState<any>({
    description: "",
    isDraft: false,
    assessmentTypeName: "",
    settings,
    pillars: assessmentPillars,
  });
  const [refetchSettings, setRefetchSettings] = useState<boolean | null>(false);

  const navigateToList = ({ message, showMessage = true }: NavigationProps) => {
    showMessage &&
      customNotification(
        "info",
        message || "Create or Edit an Assessment first.",
      );

    navigate(AssessmentRoutes.AssessmentListRoute.path);
  };

  useEffect(() => {
    if (!assessmentId) {
      navigateToList({});
    }
    getAssessmentSettings();
    RetryHelper(loadAssessment, {
      logs: true,
    });
  }, []);

  useEffect(() => {
    loadAssessment(false);
  }, [region]);

  useEffect(() => {
    if (!tagsLoading) {
      setTags({
        data: tagsData?.getConfigTags?.options?.map((tagItem: any) => {
          return { ...tagItem, title: tagItem?.label };
        }),
        isLoading: tagsLoading,
      });
    }
  }, [tagsLoading]);

  useEffect(() => {
    refetchSettings && getAssessmentSettings();
  }, [refetchSettings]);

  const getAssessmentSettings = () => {
    handleGetAssessmentSettings(assessmentId).then((data) => {
      setSettingsInitialValues(data);
      setRefetchSettings(false);
    });
  };

  /**
   * Load or refetch assessment
   *
   * @param isRefetch boolean
   */
  const loadAssessment = async (showMessage?: boolean) => {
    try {
      const response = await getAdminAssessmentQuestions({
        assessmentId: assessmentId || "",
        language: Language.English,
      }).unwrap();

      const transformedData = AssessmentTransformer(response);

      setAssessmentPillars(transformedData?.pillars);

      setInitialValues({
        ...initialValues,
        pillars: transformedData?.pillars,
        isDraft: transformedData?.settings?.isDraft,
        description: transformedData?.settings?.description,
        assessmentTypeName: transformedData?.settings?.assessmentTypeName || "",
        assessmentLanguage: transformedData?.settings?.language || "",
      });

      setLoadingAssessment(false);
    } catch (error) {
      navigateToList({ message: "Error Loading Assessment.", showMessage });
    }
  };

  const toggleSettings = () => {
    setSettingsVisible(!settingsVisible);

    if (params?.assessmentId) setAssessmentId(params.assessmentId);
  };

  /**
   * Assessment submission.
   *
   * @param values
   * @param param1
   */
  const handleAssessmentSubmission = async (values: any) => {
    const payload = {
      updateAssessmentId: assessmentId,
      update: {
        ...values,
      },
    };

    try {
      const response = await updateAssessment(payload).unwrap();

      return response;
    } catch (error: any) {
      handleError(error as string | object);
    }
  };

  const saveDescription = debounce(async (e: any) => {
    const value = e?.target?.value;

    if (!!value) {
      setSavingDescription("Saving...");
      await handleAssessmentSubmission({
        description: value,
      });

      // customNotification("success", "Description saved.");
      setSavingDescription("Saved");
      setTimeout(() => {
        setSavingDescription(null);
      }, 500);
    }
  }, 2000);

  return (
    <DashboardLayout className={styles["assessment-create"]}>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize={true}
        initialValues={initialValues}
        // validationSchema={assessmentSchema}
        onSubmit={(values) => {
          handleAssessmentSubmission(values);
        }}
      >
        {({ values, setFieldValue }) => {
          /**
           * ADD QUESTION
           *
           * @param pillarId string
           * @param questionClone any
           */
          const addNewQuestion = (pillarId: string, questionClone?: any) => {
            let questions = values?.pillars?.[pillarId]?.questions || {};

            // GENERATING NEW QUESTION

            const totalQuestions = Object.keys(questions ?? {})?.length;

            let cloneOBJ: any = {};

            if (questionClone) {
              const uniqeId = generateUniqueId();
              const optionsKey = optionKey(questionClone?.optionType);
              const optionsKeySaved = optionKey(
                questionClone?.optionType,
                true,
              );

              let subQuestions = questionClone?.subQuestions || [];

              if (
                subQuestionsBasedOptionTypes.includes(
                  questionClone?.optionType,
                ) &&
                subQuestions?.length > 0
              ) {
                subQuestions = subQuestions.map((subQuestItem: any) => {
                  return {
                    ...subQuestItem,

                    [optionsKey]: subQuestItem?.[optionsKey].map(
                      (item: any) => {
                        return {
                          ...item,
                          id: generateUniqueId(),
                          savedOnServer: EnumBoolean.NO,
                        };
                      },
                    ),
                    [optionsKeySaved]: [],
                    parentOptionId: undefined,
                    id: generateUniqueId(),
                    savedOnServer: EnumBoolean.NO,
                    createdAt: unixTimestampMs(),
                  };
                });
              }

              cloneOBJ = {
                [uniqeId]: {
                  ...questionClone,
                  [optionsKey]: questionClone?.[optionsKey].map((item: any) => {
                    return {
                      ...item,
                      id: generateUniqueId(),
                      savedOnServer: EnumBoolean.NO,
                    };
                  }),
                  [optionsKeySaved]: [],
                  id: uniqeId,
                  SN: totalQuestions + 1,
                  savedOnServer: EnumBoolean.NO,
                  createdAt: unixTimestampMs(),
                  subQuestions,
                },
              };

              customNotification(
                "success",
                `Question copied at the bottom of the pillar.`,
              );
            }

            const newQuestion = questionClone
              ? cloneOBJ
              : generateNewQuestion(totalQuestions);

            setFieldValue(`pillars.${pillarId}.questions`, {
              ...questions,
              ...newQuestion,
            });
          };

          /**
           * DELETING ASSESSMENT MAIN QUESTION
           *
           * @param pillarId string
           * @param questionId string
           */
          const deleteQuestion = async (
            pillarId: string,
            questionId: string,
          ) => {
            try {
              let filteredQuestions = values?.pillars?.[pillarId]?.questions;

              // REMOVING FROM DB
              const isQuestionSavedOnServer =
                filteredQuestions?.[questionId]?.savedOnServer ===
                EnumBoolean.YES;

              if (isQuestionSavedOnServer) {
                await deleteAssessmentQuestion({ questionId }).unwrap();

                loadAssessment(true);
              }

              // REMOVING FROM LOCAL STATE
              delete filteredQuestions?.[questionId];

              setFieldValue(`pillars.${pillarId}.questions`, filteredQuestions);

              customNotification("success", "Question deleted successfully");
            } catch (error: any) {
              handleError(error as string | object);
            }
          };

          /**
           * SAVING ASSESSMENT QUESTION
           *
           * @param isDraft boolean
           */
          const saveAssessment = async (isDraft?: boolean) => {
            setFieldValue("isDraft", !!isDraft);

            setSavingAssessment(true);
            await handleAssessmentSubmission({
              isDraft,
            });
            navigate(`${AssessmentRoutes.AssessmentListRoute.path}#persist`);
            setSavingAssessment(false);
            customNotification("success", "Assessment updated successfully");
          };

          /**
           * jSX
           */

          return (
            <Fragment>
              <div className={styles["assessment-create-header"]}>
                <Breadcrumbs
                  data={[
                    {
                      label: t("assessments"),
                    },
                    {
                      label: t("assessment-list"),
                    },
                    {
                      // label: `${location?.state?.type?.name} - ${location?.state?.language}`,
                      label: !loadingAssessment
                        ? ` ${params?.assessmentTypeName || ""} - ${
                            getLanguage(initialValues?.assessmentLanguage) || ""
                          } `
                        : "",
                    },
                  ]}
                />

                <Space>
                  <CustomButton
                    className="assessment-create-header-cancel link-text-btn"
                    onClick={() =>
                      navigate(
                        `${AssessmentRoutes.AssessmentListRoute.path}#persist`,
                      )
                    }
                  >
                    {t("cancel")}
                  </CustomButton>

                  {/* <CustomButton className="light-btn">Preview</CustomButton> */}

                  <CustomButton
                    className="light-btn icon"
                    onClick={toggleSettings}
                  >
                    <Icon component={ConfigSvg} />
                    {t("settings")}
                  </CustomButton>

                  <CustomButton
                    disabled={savingAssessment}
                    loading={values.isDraft && savingAssessment}
                    className="light-btn"
                    onClick={() => saveAssessment(true)}
                  >
                    {values.isDraft && savingAssessment
                      ? t("saving")
                      : t("save-as-draft")}
                  </CustomButton>

                  <CustomButton
                    loading={!values.isDraft && savingAssessment}
                    disabled={savingAssessment}
                    className="primary-btn"
                    onClick={() => saveAssessment(false)}
                  >
                    {!values.isDraft && savingAssessment
                      ? t("publishing")
                      : t("save-publish")}
                  </CustomButton>
                </Space>
              </div>

              {!loadingAssessment ? (
                <Form className={styles["assessment-create-form"]}>
                  {/* INFO SECTION */}
                  <div className={styles["pillars"]}>
                    <CollapseAble
                      defaultActiveKey={["description"]}
                      headerKey={"description"}
                      header={
                        <div className="d-flex">
                          <ContainerHeading text={t("description")} />
                          {savingDescription && (
                            <Text type="secondary" className="ml-3">
                              {savingDescription}
                            </Text>
                          )}
                        </div>
                      }
                      hideExtra={true}
                      expandIconPosition="end"
                    >
                      <CustomTextArea
                        rows={2}
                        key={"description"}
                        name="description"
                        placeholder={t("type-description-here")}
                        customOnChange={saveDescription}
                        // disabled={savingDescription}
                      />
                      {/* {savingDescription && <span className="text-muted">Saving...</span>} */}
                    </CollapseAble>

                    {/* PILLARS SECTION */}
                    {Object.values(values?.pillars)?.map(
                      (pillar: any, index: number) => {
                        return (
                          <Pillar
                            key={index}
                            assessmentTypeName={values?.assessmentTypeName}
                            // assessmentTypeName="Viwell Assessment"
                            // isViwellAssessment={
                            //   values?.assessmentTypeName &&
                            //   values?.assessmentTypeName ==
                            //   EnumAssessmentTypes.VIWELL_ASSESSMENT
                            // }
                            isSubQuestionsEnable={
                              true
                              // !!values?.isSubQuestionsEnable
                            }
                            assessmentPillar={pillar}
                            assessmentId={assessmentId}
                            tags={tags}
                            deleteQuestion={deleteQuestion}
                            addNewQuestion={addNewQuestion}
                            reFetchAssessments={loadAssessment}
                            assessmentSettings={
                              settingsInitialValues?.assessmentSettingId
                            }
                          />
                        );
                      },
                    )}
                  </div>
                </Form>
              ) : (
                <h1 className="my-10 te-xt-center">
                  {t("loading-assessment")}
                </h1>
              )}
            </Fragment>
          );
        }}
      </Formik>
      {settingsVisible && (
        <AssessmentSettings
          visible={settingsVisible}
          setVisible={setSettingsVisible}
          assessmentId={assessmentId}
          initialValues={settingsInitialValues}
          setRefetchSettings={setRefetchSettings}
        />
      )}
    </DashboardLayout>
  );
};

export default memo(AddAssessment);
