import { List } from "antd";
import CustomModal from "components/custom-modal/CustomModal";
import { EventParticipantsScoreInput } from "graphql/_generated/graphql";
import React from "react";
import styles from "features/events/css/EventDetail.module.scss";
import ModalFooter from "components/custom-modal/ModalFooter";
import { useTranslation } from "react-i18next";
import ParticipatingEditItem from "./ParticipatingEditItem";
import { Form, Formik, FormikHelpers } from "formik";
import { GetEventDetails } from "../hooks/useEventViewModel";
import { participantsScoreSchema } from "validations/schema";

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  userVisibility: boolean;
  onCloseUser: () => void;
  companies?: GetEventDetails["participants"];
  loading?: boolean;
  onSubmitUpdateScore: (
    values: EventParticipantsScoreInput,
    formikHelpers?: FormikHelpers<EventParticipantsScoreInput> | undefined,
  ) => Promise<boolean | undefined>;
  onPressUser: (clientId: string) => void;
};

const ParticipatingCompaniesEditScore: React.FC<Props> = ({
  companies,
  visible,
  setVisible,
  loading,
  onSubmitUpdateScore,
  onPressUser,
}) => {
  const { t } = useTranslation();
  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <CustomModal
      centered
      width={720}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      title={t("event.participating-companies")}
    >
      <Formik
        initialValues={{
          data: companies?.map((item) => ({
            participantId: item.id ?? "",
            score: item.eventScore ?? 0,
          })) ?? [
            {
              participantId: "",
              score: 0,
            },
          ],
        }}
        validationSchema={participantsScoreSchema}
        enableReinitialize
        onSubmit={onSubmitUpdateScore}
      >
        {({ values, errors }) => (
          <Form>
            <div
              className={`${styles["participating-container"]} ${styles["mb-2"]} `}
            >
              <List
                loading={loading}
                size="small"
                dataSource={companies ?? []}
                renderItem={(item, index) => (
                  <ParticipatingEditItem
                    itemKey={index + 1}
                    id={item.id}
                    companyImg={item.info?.image ?? ""}
                    companyName={item.info?.companyName ?? ""}
                    participants={item.totalParticipatingEmployeeCount ?? 0}
                    error={!!(errors && errors.data && errors.data[index])}
                    clientId={item.clientId}
                    onItemClick={onPressUser}
                  />
                )}
              />
            </div>
            <ModalFooter saveText={t("save")} onCancel={handleCancel} />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default ParticipatingCompaniesEditScore;
