export const AdminSvg = () => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 17.2089C7.7175 17.2089 7.435 17.1389 7.18417 16.9973L6.93167 16.8548C2.96417 14.6231 0.5 10.4098 0.5 5.8581V5.73727C0.5 5.1381 0.825 4.58143 1.34833 4.2856L7.18333 1.00393C7.6875 0.720599 8.31333 0.719766 8.81583 1.0031L14.65 4.28477C15.175 4.58143 15.5 5.1381 15.5 5.73727V5.8581C15.5 10.4098 13.0358 14.6231 9.06833 16.8548L8.81667 16.9964C8.565 17.1381 8.2825 17.2089 8 17.2089Z"
        fill="#303030"
      />
    </svg>
  );
};
