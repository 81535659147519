import styles from "../../CompanyDashboard.module.scss";
import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { ViwellAssessment } from "./ViwellAssessment";
import { OrganizationScores } from "./OrganizationScores";
import { SectionHeader } from "../SectionHeader";
import { UserFilters } from "graphql/_generated/graphql";
import { InfoTextProps } from "../organization-metrices/types";
import { Col, Row } from "antd";

export const Assessments: FunctionComponent<InfoTextProps> = ({
  selectedInfoText,
  setSelectedInfoText,
}) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);

  const defaultValues = {
    clientIds: null,
    departmentIds: null,
    gender: null,
    positionIds: null,
    age: null,
    onlyActive: true,
  };

  const [userFilters, setUserFilters] = useState<UserFilters>(defaultValues);

  return (
    <>
      <div className={styles["checkins-chart"]}>
        <SectionHeader
          visible={visible}
          setVisible={setVisible}
          userFilters={userFilters}
          setUserFilters={(data: UserFilters, reset: boolean) =>
            setUserFilters(reset ? defaultValues : data)
          }
          sectionTitle={t("assessments")}
        />
      </div>
      <Row gutter={[16, 16]} className={styles["row-flex"]}>
        <Col xs={24} xl={12}>
          <ViwellAssessment
            userFilters={userFilters}
            visible={visible}
            selectedInfoText={selectedInfoText}
            setSelectedInfoText={setSelectedInfoText}
          />
        </Col>
        <Col xs={24} xl={12}>
          <OrganizationScores
            userFilters={userFilters}
            visible={visible}
            selectedInfoText={selectedInfoText}
            setSelectedInfoText={setSelectedInfoText}
          />
        </Col>
      </Row>
    </>
  );
};
