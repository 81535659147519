export function getNestedArrValues(
  arr: any[] | undefined,
  solution: any[] = []
) {
  arr?.map((item) => {
    solution.push(item.key);
    item.children && getNestedArrValues(item.children, solution);
  });
  return solution;
}
