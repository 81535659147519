import { PlusCircleFilled } from "@ant-design/icons";
import { Select } from "antd";
import CustomButton from "components/custom-button/CustomButton";
import CustomDivider from "components/custom-divider/CustomDivider";
import ModalFooter from "components/custom-modal/ModalFooter";
import { CustomDatePicker } from "components/form/custom-date-picker/CustomDatePicker";
import CustomPhoneInput from "components/form/custom-phone-input/CustomPhoneInput";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import CustomTreeSelect from "components/form/custom-tree-select/CustomTreeSelect";
import AddBuilding from "features/buildings/add-building/AddBuilding";
import { useAddBuildingViewModel } from "features/buildings/hooks/useAddBuildingViewModel";
import AddDepartment from "features/department/add-department/AddDepartment";
import { useAddDepartmentViewModel } from "features/department/hooks/useAddDepartmentViewModel";
import AddPosition from "features/positions/add-position/AddPosition";
import { useAddPositionViewModel } from "features/positions/hooks/useAddPositionViewModel";
import styles from "features/users/css/InviteSingleUsers.module.scss";
import { Form, Formik } from "formik";
import { useLoggedInUser } from "hooks/useLoggedInUser";
import moment from "moment";
import { ReactElement, cloneElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  inviteSingleUserSchemaForClient,
  inviteSingleUserSchemaForSuperAdmin,
} from "validations/schema";
import { editUserSchema } from "validations/schema/inviteSingleUserSchema";
import { useInviteSingleUserViewModel } from "../hooks/useInviteSingleUserViewModel";

const { Option } = Select;

export type InviteSingleUserProps = {
  setVisible: (x: boolean) => void;
  employerId?: string | undefined;
  employeeId?: string | undefined;
  selectedEmployee?: any;
  setEmployeeId?: React.Dispatch<React.SetStateAction<string | null>>;
};

export enum Gender {
  Male = "Male",
  Female = "Female",
  Other = "Other",
}

const InviteSingleUser = (props: InviteSingleUserProps) => {
  const { t } = useTranslation();
  const { isClient, isSuperAdmin, isViwellAdmin } = useLoggedInUser();
  const vm = useAddPositionViewModel();
  const vmDept = useAddDepartmentViewModel();
  const vmBuilding = useAddBuildingViewModel();

  const isEditMode = !!props.employeeId;

  const confirmContent = (
    <>
      <div className={styles["confirm-content"]}>
        {t("confirm-email-change-for-employee")}
      </div>
    </>
  );

  const {
    onSubmit,
    initialValues,
    isLoading,
    isLoadingEmployee,
    configData,
    setEmployerId,
  } = useInviteSingleUserViewModel(props, confirmContent);

  useEffect(() => {
    if (initialValues?.employerId) {
      vm.handleClientId(initialValues?.employerId);
      vmDept.handleClientId(initialValues?.employerId);
      vmBuilding.handleClientId(initialValues?.employerId);
    }
  }, [initialValues?.employerId]);
  return (
    <div>
      <Formik
        enableReinitialize
        validateOnBlur
        initialValues={{
          ...initialValues,
          employerId: initialValues.employerId ?? props.employerId,
        }}
        validationSchema={
          (isSuperAdmin || isViwellAdmin) && !isEditMode
            ? inviteSingleUserSchemaForSuperAdmin
            : isClient && !isEditMode
            ? inviteSingleUserSchemaForClient
            : isEditMode
            ? editUserSchema
            : null
        }
        onSubmit={(employeeValues, formikHelpers) =>
          onSubmit({ employeeValues, formikHelpers })
        }
      >
        {({ setFieldValue, values }) => (
          <Form>
            {(!isEditMode || props.selectedEmployee?.email) && (
              <CustomTextField
                disabled={props.selectedEmployee?.isProfileComplete}
                label={t("email")}
                name="employeeCreateDto.email"
                placeholder={t("please-enter-your-email")}
                required
              />
            )}

            {!isClient && (
              <CustomTreeSelect
                allowClear
                showArrow
                showSearch={false}
                treeNodeFilterProp="companyName"
                disabled={isEditMode || !!props.employerId}
                // value={employerId}
                treeLine={true && { showLeafIcon: false }}
                label={t("company")}
                className="form-select"
                name="employerId"
                treeData={configData.companies}
                placeholder={t("select-company")}
                fieldNames={{ label: "companyName", value: "id" }}
                onChange={(values) => {
                  setFieldValue("employerId", values);
                  setFieldValue("employeeCreateDto.departmentId", "");
                  setFieldValue("employeeCreateDto.positionId", "");
                  setFieldValue("employeeCreateDto.buildingId", "");
                  setEmployerId(values);
                  vm.handleClientId(values);
                  vmDept.handleClientId(values);
                  vmBuilding.handleClientId(values);
                }}
                required
              />
            )}
            <CustomTextField
              label={t("first-name")}
              name="employeeCreateDto.firstName"
              placeholder=""
              required
            />
            <CustomTextField
              label={t("last-name")}
              name="employeeCreateDto.lastName"
              placeholder=""
              required
            />
            <CustomSelectField
              label={t("gender")}
              placeholder={t("gender")}
              name="employeeCreateDto.gender"
            >
              {Object.values(Gender).map((item, index) => (
                <Option value={item}>{item}</Option>
              ))}
            </CustomSelectField>
            <CustomPhoneInput
              value={values.employeeCreateDto?.phoneNumber}
              setFieldValue={setFieldValue}
              name="employeeCreateDto.phoneNumber"
              placeholder={t("contact-number")}
              label={t("contact-number")}
            />
            <CustomDatePicker
              name="employeeCreateDto.dob"
              label={t("date-of-birth")}
              format="DD/MM/YYYY"
              placeholder={t("select-date-of-birth")}
              disabledDate={(current) => {
                // Disable all dates after today
                return current && current > moment().endOf("day");
              }}
            />
            <CustomSelectField
              label={t("department")}
              className="form-select"
              showSearch={false}
              name="employeeCreateDto.departmentId"
              options={vmDept.departmentsData}
              fieldNames={{ label: "label", value: "id" }}
              placeholder={t("select-department")}
              disabled={isClient ? false : !values.employerId}
              onChange={(values) => {
                vm.setDepartmentId(values);
                setFieldValue("employeeCreateDto.departmentId", values);
                setFieldValue("employeeCreateDto.positionId", "");
              }}
              required
              dropdownRender={(menu) => (
                <div>
                  {cloneElement(menu as ReactElement)}
                  <CustomDivider />
                  <CustomButton
                    className="link-text-btn margin-left"
                    icon={<PlusCircleFilled />}
                    onClick={() => vmDept.handleInviteUser()}
                  >
                    {`${t("add-new")} ${t("department")}`}
                  </CustomButton>
                </div>
              )}
            />
            <CustomSelectField
              label={t("position")}
              className="form-select"
              showSearch={false}
              name="employeeCreateDto.positionId"
              options={vm.positionsData}
              placeholder={t("select-positions")}
              fieldNames={{ label: "label", value: "id" }}
              disabled={!values.employeeCreateDto.departmentId}
              required
              dropdownRender={(menu) => (
                <div>
                  {cloneElement(menu as ReactElement)}
                  <CustomDivider />
                  <CustomButton
                    className="link-text-btn margin-left"
                    icon={<PlusCircleFilled />}
                    onClick={() => vm.handleInviteUser()}
                  >
                    {`${t("add-new")} ${t("position")}`}
                  </CustomButton>
                </div>
              )}
            />
            <CustomSelectField
              label={t("building")}
              className="form-select"
              showSearch={false}
              name="employeeCreateDto.buildingId"
              options={vmBuilding.buildingsData}
              fieldNames={{ label: "label", value: "id" }}
              placeholder={t("select-building")}
              required
              disabled={isClient ? false : !values.employerId}
              dropdownRender={(menu) => (
                <div>
                  {cloneElement(menu as ReactElement)}
                  <CustomDivider />
                  <CustomButton
                    className="link-text-btn margin-left"
                    icon={<PlusCircleFilled />}
                    onClick={() => vmBuilding.handleInviteUser()}
                  >
                    {`${t("add-new")} ${t("building")}`}
                  </CustomButton>
                </div>
              )}
            />
            <ModalFooter
              saveText={props.employeeId ? t("update-user") : t("invite-user")}
              loading={isLoading || isLoadingEmployee}
              onCancel={() => {
                props.setVisible(false);
                props.setEmployeeId && props.setEmployeeId(null);
              }}
            />
            <AddDepartment
              visible={vmDept.visible}
              closeModal={vmDept.closeModal}
              isLoading={vmDept.isLoading}
              initialValues={vmDept.initialValues}
              handleSubmit={vmDept.handleSubmit}
              isClient={vmDept.isClient}
              redirectToDepartments={vmDept.redirectToDepartments}
              setFieldValue={setFieldValue}
              configData={vmDept.configData}
              inviteUserView={vmDept.inviteUserView}
              isDeptsLoading={vmDept.isDeptsLoading}
              setDepartmentId={vm.setDepartmentId}
            />
            <AddPosition
              visible={vm.visible}
              closeModal={vm.closeModal}
              isLoading={vm.isLoading}
              initialValues={vm.initialValues}
              handleSubmit={vm.handleSubmit}
              isClient={vm.isClient}
              redirectToPositions={vm.redirectToPositions}
              departmentsData={vmDept.departmentsData}
              setFieldValue={setFieldValue}
              configData={vm.configData}
              inviteUserView={vm.inviteUserView}
              isPositionsLoading={vm.isPositionsLoading}
            />
            <AddBuilding
              visible={vmBuilding.visible}
              closeModal={vmBuilding.closeModal}
              isLoading={vmBuilding.isLoading}
              initialValues={vmBuilding.initialValues}
              handleSubmit={vmBuilding.handleSubmit}
              isClient={vmBuilding.isClient}
              redirectToBuildings={vmBuilding.redirectToBuildings}
              setFieldValue={setFieldValue}
              configData={vmBuilding.configData}
              inviteUserView={vmBuilding.inviteUserView}
              countries={vmBuilding.countries}
              isBuildingsLoading={vmBuilding.isBuildingsLoading}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default InviteSingleUser;
