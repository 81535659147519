import { useEffect, useState } from "react";
import { FieldArray } from "formik";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import styles from "./TranslationField.module.scss";
import { useTranslation } from "react-i18next";
type Props = {
  values: any;
};

let yes: any = {};
const TranslationFieldArr = ({ values }: Props) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  // this function gets the language from the api
  const getAppLanguages = async () => {
    const res = await fetch("locales/languages.json");
    const data = await res.json();
    const filteredData = data.filter((option: { value: string }) => {
      return option.value !== "en";
    });
    setOptions(filteredData);
  };

  //this use effect get the languages from the json file on component mount first time
  useEffect(() => {
    getAppLanguages();

    //on component destroy
    return () => {
      yes = {};
    };
  }, [values]);

  //this useEffect add the values into yes hashmap on the time of edit so the dropdown values disabled automatically
  useEffect(() => {
    values?.translations?.map((item: any, index: number) => {
      yes[index] = item.lang;
    });
  }, [values]);

  //this function handles the dropdown values if its already selected then it will be disabled into the next dropdown
  const getSpecificOptions = (notToBeDisabled: any) => {
    let hashMap: any = {};
    for (const key in yes) {
      hashMap[yes[key]] = true;
    }
    const items = options.map((option: any) => {
      return {
        ...option,
        disabled: !!hashMap[option.value] && option.value !== notToBeDisabled,
      };
    });

    return items;
  };

  return (
    <FieldArray
      name="translations"
      render={(arrayHelpers) => (
        <div>
          {values.translations && values.translations.length > 0 ? (
            values.translations.map((translation: any, index: number) => (
              <div key={index}>
                <CustomSelectField
                  label={t("select-language")}
                  required={true}
                  name={`translations.${index}.lang`}
                  placeholder={t("select-language")}
                  onChange={(val: any) => {
                    yes[index] = val;
                  }}
                  options={getSpecificOptions(yes[index])}
                  className="form-select"
                />

                <CustomTextField
                  label={t("question-in-selected-language")}
                  required={true}
                  name={`translations.${index}.question`}
                  placeholder={t("enter-question-selected-language")}
                />
                <CustomTextField
                  label={t("answer-in-selected-language")}
                  required={true}
                  name={`translations.${index}.answer`}
                  placeholder={t("enter-answer-selected-language")}
                />

                <div className={styles["flex-div"]}>
                  {values.translations.length < options.length ? (
                    <span
                      className={styles["add-btn"]}
                      onClick={() => arrayHelpers.push("")} // insert an empty string at a position
                    >
                      + {t("add-new-language")}
                    </span>
                  ) : (
                    ""
                  )}
                  <span
                    className={styles["remove-btn"]}
                    onClick={() => arrayHelpers.remove(index)} // remove a language from the list
                  >
                    - {t("remove-language")}
                  </span>
                </div>
              </div>
            ))
          ) : (
            <div className={styles["form-control"]}>
              <span
                className={styles["add-btn"]}
                onClick={() =>
                  arrayHelpers.push({
                    lang: "ar",
                  })
                }
              >
                + {t("add-new-language")}
              </span>
            </div>
          )}
        </div>
      )}
    />
  );
};

export default TranslationFieldArr;
