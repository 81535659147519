import CheckPermissions from "components/check-permissions/CheckPermissions";
import CustomButton from "components/custom-button/CustomButton";
import { PERMS } from "helpers";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import { useState } from "react";
import { List, Add } from "features/wellbeing-calendar";
import styles from "features/wellbeing-calendar/css/WellbeingCalendar.module.scss";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
interface WellbeingCalendarProps {}

const WellbeingCalendar: React.FunctionComponent<WellbeingCalendarProps> = (
  props,
) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [editId, setEditId] = useState<string | null>(null);
  const { t } = useTranslation();

  return (
    <DashboardLayout openKey={["Configs"]}>
      <Breadcrumbs
        data={[
          {
            label: t("configurations"),
          },
          {
            label: t("wellbeing.title"),
          },
        ]}
      />
      <List />
    </DashboardLayout>
  );
};

export default WellbeingCalendar;
