interface ColorsInterface {
  color: string;
  background: string;
}

type Themes = "dark" | "light";

type ThemeInterface = {
  [key in Themes]: ColorsInterface;
};

const themes: ThemeInterface = {
  light: {
    color: "black",
    background: "white",
  },
  dark: {
    color: "white",
    background: "black",
  },
};

export function selectTheme(currTheme: Themes) {
  const root = document.documentElement;
  const theme = themes[currTheme];

  for (const key in theme) {
    const value = theme[key as keyof ColorsInterface];
    root.style.setProperty(`--${key}`, value);
  }
}

selectTheme("dark");
