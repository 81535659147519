import {
  convertTranslationArrToObj,
  convertTranslationObjToArr,
} from "helpers";
import {
  MutationUpdateBuildingArgs,
  useGetBuildingListQuery,
  useLazyGetBuildingQuery,
  useUpdateBuildingMutation,
} from "graphql/_generated/graphql";

export const useUpdateBuilding = () => {
  const [updateBuildingFun, { isLoading }] = useUpdateBuildingMutation();
  const [prevEditDataFun, { isLoading: prevDataLoading }] =
    useLazyGetBuildingQuery();
  const { refetch } = useGetBuildingListQuery();

  //This function is responsible for updating the building data
  const handleUpdateBuilding = async (values: MutationUpdateBuildingArgs) => {
    try {
      let translations = await convertTranslationArrToObj(
        values.updateObj?.translations,
      );

      await updateBuildingFun({
        updateBuildingId: values.id,
        updateObj: { ...values.updateObj, translations },
      }).unwrap();
      refetch();
    } catch (error: any) {
      throw error;
    }
  };

  //this function is responsible for fetching the specific building data which building id we will be provided
  const handleFetchPreviousBuildingData = async (id: string) => {
    try {
      const res = await prevEditDataFun({ getBuildingId: id });
      const translations = await convertTranslationObjToArr(
        res.data?.getBuilding?.translations!,
      );

      return { ...res.data?.getBuilding, translations };
    } catch (error) {
      throw error;
    }
  };

  return {
    isLoading: isLoading || prevDataLoading,
    handleFetchPreviousBuildingData,
    handleUpdateBuilding,
  };
};
