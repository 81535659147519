import customNotification from "components/custom-notification/CustomNotification";
import { useGetSetDocumentation } from "features/documentation/hooks/useGetSetDocumentation";
import { DocumentationCreate } from "graphql/_generated/graphql";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const useDocumentationViewModel = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const [initialValues, setInitialValues] = useState<DocumentationCreate>({
    translations: {},
    privacyPolicy: "",
    termsAndConditions: "",
    aboutViwell: "",
  });

  const { getDocumentation, handleSetDocumentation, isLoading } =
    useGetSetDocumentation();

  useEffect(() => {
    try {
      getDocumentation().then((data) => {
        setInitialValues(data);
      });
    } catch (error) {
      handleError(error as string | object);
    }
  }, []);

  const handleSubmit = async (values: DocumentationCreate) => {
    try {
      const res = await handleSetDocumentation({
        create: { ...values },
      });
      customNotification("success", t("success-update"));
      return res;
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const resetNavigation = () => {
    navigate(-1);
  };

  return {
    handleSubmit,
    initialValues,
    isLoading,
    resetNavigation,
  };
};
