import { EmotionalPillerIcon } from "assets/icons/EmotionalPillerIcon";
import { FinancialPillerIcon } from "assets/icons/FinancialPillerIcon";
import { NutritionalPillerIcon } from "assets/icons/NutritionalPillerIcon";
import { PhysicalPillerIcon } from "assets/icons/PhysicalPillerIcon";
import { ProfessionalPillerIcon } from "assets/icons/ProfessionalPillerIcon";
import { SocialPillerIcon } from "assets/icons/SocialPillerIcon";

const Pillars = {
  "1": {
    id: "1",
    title: "Loading Pillars...",
    subtitle: "11",
    icon: ProfessionalPillerIcon,
  },
  // "2": {
  //   id: "2",
  //   title: "Nutritional",
  //   subtitle: "2 Questions",
  //   icon: NutritionalPillerIcon,
  // },
  // "3": {
  //   id: "3",
  //   title: "Financial ",
  //   subtitle: "2 Questions",
  //   icon: FinancialPillerIcon,
  // },
  // "4": {
  //   id: "4",
  //   title: "Social",
  //   subtitle: "2 Questions",
  //   icon: SocialPillerIcon,
  // },
  // "5": {
  //   id: "5",
  //   title: "Emotional",
  //   subtitle: "2 Questions",
  //   icon: EmotionalPillerIcon,
  // },
  // "6": {
  //   id: "6",
  //   title: "Physical",
  //   subtitle: "2 Questions",
  //   icon: PhysicalPillerIcon,
  // },
};

export default Pillars;
