import * as React from "react";
import { Button, Modal } from "antd";
import "./CustomModal.module.scss";
interface CustomModalProps {
  visible: boolean;
  width?: string | number;
  centered?: boolean;
  footer?: React.ReactNode;
  title?: string | React.ReactNode;
  className?: string;
  children: React.ReactNode;
  showHeaderBottomBorder?: boolean;
  onCancel: (e: React.MouseEvent<HTMLElement>) => void;
}

const CustomModal: React.FunctionComponent<CustomModalProps> = (props) => {
  const { children, showHeaderBottomBorder } = props;
  return (
    <Modal
      maskClosable={false}
      destroyOnClose={true}
      okButtonProps={{ htmlType: "submit" }}
      className={`custom-modal ${
        showHeaderBottomBorder ? "header-bottom-border" : ""
      }`}
      {...props}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
