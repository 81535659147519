import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import {
  useGetPillarIdAndNameQuery,
  useGetPillarListQuery,
} from "graphql/_generated/graphql";
import { pillarSchema } from "validations/schema";

import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { customNotification } from "components/custom-notification/CustomNotification";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { CustomUploadFile } from "components/form/custom-upload-file/CustomUploadFile";
import TranslationFieldArr from "components/translation-field-array/TranslationFieldArr";

import { errorFormat } from "helpers";
import { usePillar } from "../usePillar";
import { useErrorHandler } from "hooks/useErrorHandler";

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  editId?: string | null;
  setEditId: React.Dispatch<React.SetStateAction<string | null>>;
};

const AddPillar: React.FC<Props> = ({
  setVisible,
  visible,
  editId,
  setEditId,
}) => {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const { refetch } = useGetPillarListQuery();
  const { refetch: configPillarRefetch } = useGetPillarIdAndNameQuery();

  const {
    isLoading,
    handleAddPillar,
    handleUpdatePillar,
    handleFetchEditPillarData,
  } = usePillar();

  const [initialValues, setInitialValues] = useState({
    title: "",
    icon: "",
    color: "",
    translations: [],
  });

  const handleCancel = () => {
    setVisible(false);
    setEditId(null);
  };

  // Setting the previous values to the initial values object when clicking on the edit button
  useEffect(() => {
    if (editId) {
      handleFetchEditPillarData(editId).then((data: any) => {
        setInitialValues({
          title: data.title,
          icon: data.icon,
          translations: data.translations,
          color: data.color,
        });
      });
    } else {
      setInitialValues({
        title: "",
        icon: "",
        translations: [],
        color: "",
      });
    }
  }, [editId]);

  return (
    <CustomModal
      width={600}
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      title={t(editId ? "edit-pillar" : "pillar.add-pillar")}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={pillarSchema}
        onSubmit={async (values, { resetForm }) => {
          try {
            if (!editId) {
              await handleAddPillar({
                create: { ...values },
              });
              customNotification("success", t("pillar.added-successfully"));
            } else {
              await handleUpdatePillar({ id: editId, updateObj: values });
              setEditId(null);
              customNotification("success", t("pillar.updated-successfully"));
            }
            resetForm();
            setVisible(false);
            refetch();
            configPillarRefetch();
          } catch (error) {
            handleError(error as string | object);
          }
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <CustomUploadFile
              name="icon"
              setFieldValue={setFieldValue}
              imgUrl={values.icon}
              accept="image/*"
            />
            <CustomTextField
              label={t("pillar-name")}
              name="title"
              placeholder={t("pillar.enter-pillar-name")}
            />
            <TranslationFieldArr values={values} attribute="title" />
            <ModalFooter
              saveText={t("save-pillar")}
              onCancel={handleCancel}
              loading={isLoading}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddPillar;
