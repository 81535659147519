import React, { useCallback, useEffect, useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import { Select, Space } from "antd";
import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import CustomTreeSelect from "components/form/custom-tree-select/CustomTreeSelect";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import customNotification from "components/custom-notification/CustomNotification";
import {
  useGetCategoriesTreeQuery,
  useLazyGetCategoryPillarsMappingListQuery,
  GetCategoryPillarMapping,
} from "graphql/_generated/graphql";
import { usePillarIdAndName } from "hooks";
import { useAddCategoryMapping } from "../hooks/useAddMapper";
import { useUpdateCategoryMapping } from "../hooks/useUpdateMapper";
import { mapperSchema } from "validations/schema/mapperSchema";
import { errorFormat } from "helpers";
import { useErrorHandler } from "hooks/useErrorHandler";

const { Option } = Select;

interface InitialValuesType {
  pillarIds: string[];
  categoryId: string;
}

interface Props {
  visible: boolean;
  editId: string;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setCategoryMapper: React.Dispatch<React.SetStateAction<string | null>>;
  categoryMapper?: GetCategoryPillarMapping;
}

const MapperAdd = ({
  setVisible,
  visible,
  editId,
  setCategoryMapper,
  categoryMapper,
}: Props) => {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const { pillarsData } = usePillarIdAndName();
  const { handleAddMapper, isLoading } = useAddCategoryMapping();
  const { handleUpdateCategoryMapping, isLoading: isUpdating } =
    useUpdateCategoryMapping();
  const [getAll, { isLoading: isFetching }] =
    useLazyGetCategoryPillarsMappingListQuery();
  const { data: categoriesData } = useGetCategoriesTreeQuery(
    { pillarId: null },
    { refetchOnMountOrArgChange: true },
  );
  const [initialValues, setInitialValues] = useState<InitialValuesType>({
    pillarIds: [],
    categoryId: "",
  });

  useEffect(() => {
    if (categoryMapper) {
      const { pillars, category } = categoryMapper;
      setInitialValues({
        pillarIds: pillars.map((p: any) => p.id),
        categoryId: category.id,
      });
    }
  }, [categoryMapper]);

  const handleCancel = () => {
    setVisible(false);
    resetState();
  };

  const resetState = useCallback(() => {
    setCategoryMapper(null);
    setInitialValues({ pillarIds: [], categoryId: "" });
  }, [setCategoryMapper]);

  const onSubmit = async (
    values: InitialValuesType,
    { resetForm }: FormikHelpers<InitialValuesType>,
  ) => {
    try {
      if (!editId) {
        await handleAddMapper({ create: values });
      } else {
        await handleUpdateCategoryMapping({ update: values, id: editId });
      }

      getAll();
      resetState();
      setVisible(false);
    } catch (error: unknown) {
      handleError(error as string | object);
    }
  };

  return (
    <>
      <CustomModal
        width={600}
        visible={visible}
        onCancel={handleCancel}
        footer={false}
        title={t(editId ? "associations.edit" : "associations.add-new")}
      >
        <Formik
          enableReinitialize={true}
          validationSchema={mapperSchema}
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {({ values }) => (
            <Form>
              <CustomTreeSelect
                allowClear
                showArrow
                treeNodeFilterProp="name"
                treeLine={true && { showLeafIcon: false }}
                value={values.categoryId}
                label={t("associations.category")}
                fieldNames={{
                  label: "name",
                  value: "id",
                }}
                className="form-select"
                name="categoryId"
                placeholder={t("select-category")}
                treeData={categoriesData?.getCategoriesTree}
              />
              <CustomSelectField
                mode="multiple"
                allowClear
                label={t("associations.pillars")}
                className="form-select"
                name="pillarIds"
                placeholder={t("select-pillar")}
              >
                {pillarsData?.map((item: any, index: any) => (
                  <Option value={item.value} key={index}>
                    <Space>
                      <CustomAvatar imgSrc={item.icon} />
                      {item.label}
                    </Space>
                  </Option>
                ))}
              </CustomSelectField>
              <ModalFooter
                saveText={t("associations.save")}
                onCancel={handleCancel}
                loading={isLoading || isFetching || isUpdating}
              />
            </Form>
          )}
        </Formik>
      </CustomModal>
    </>
  );
};

export default MapperAdd;
