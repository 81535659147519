export const ProfileSvg = () => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 3.25C7.5 2.42275 6.82725 1.75 6 1.75C5.17275 1.75 4.5 2.42275 4.5 3.25C4.5 4.07725 5.17275 4.75 6 4.75C6.82725 4.75 7.5 4.07725 7.5 3.25ZM9 3.25C9 4.9045 7.6545 6.25 6 6.25C4.3455 6.25 3 4.9045 3 3.25C3 1.5955 4.3455 0.25 6 0.25C7.6545 0.25 9 1.5955 9 3.25ZM0.75 13C0.75 10.105 3.10575 7.75 6 7.75C8.89425 7.75 11.25 10.105 11.25 13C11.25 13.414 10.9148 13.75 10.5 13.75C10.0852 13.75 9.75 13.414 9.75 13C9.75 10.9323 8.06775 9.25 6 9.25C3.93225 9.25 2.25 10.9323 2.25 13C2.25 13.414 1.91475 13.75 1.5 13.75C1.08525 13.75 0.75 13.414 0.75 13Z"
        fill="#121212"
      />
    </svg>
  );
};
