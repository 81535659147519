import { useToggleQuestStatusMutation } from "graphql/_generated/graphql";

export const useToggleQuest = () => {
  const [toggleQuestFun, { isLoading }] = useToggleQuestStatusMutation();

  const handleToggleQuest = async (id: string) => {
    try {
      const res = await toggleQuestFun({ toggleQuestStatusId: id }).unwrap();
      return res.toggleQuestStatus;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleToggleQuest,
    isLoadingToggleQuest: isLoading,
  };
};
