import { useLazyGetChallengeQuery } from "graphql/_generated/graphql";

export const useEditChallenge = () => {
  const [prevEditDataFun, { isLoading: prevDataLoading }] =
    useLazyGetChallengeQuery();

  //this function is responsible for fetching data against provided challenge id
  const handleFetchChallengeData = async (id: string) => {
    try {
      const res = await prevEditDataFun({ getChallengeId: id }).unwrap();
      return res.getChallenge;
    } catch (error) {
      throw error;
    }
  };
  return {
    isLoading: prevDataLoading,
    handleFetchChallengeData,
  };
};
