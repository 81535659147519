import * as Yup from "yup";
import * as Validations from "validations/schema/common/validations";

export const employeeProfileSchema = Yup.object().shape({
  image: Validations.image("image"),
  specialty: Validations.required("specialty"),
  dob: Validations.dob("dob"),
  pillarId: Validations.required("pillar"),
  tagIds: Validations.requiredArrayOfStringValidation("tag"),
  about: Validations.requiredFieldWithoutSpaces("pls-describe-yourself"),
  specializations: Validations.requiredFieldWithoutSpaces("specialty"),
  gender: Validations.requiredFieldWithoutSpaces("gender"),
  qualifications: Validations.requiredFieldWithoutSpaces("qualification"),
  speakingLanguages: Validations.requiredArrayOfStringValidation("language"),
  servicesProvided: Validations.requiredFieldWithoutSpaces("services"),
  phoneNumber: Validations.phone("phone-number"),
  personalEmail: Validations.email,
});
