import {
  UpdateQuestMutationVariables,
  useUpdateQuestMutation,
} from "graphql/_generated/graphql";

export const useUpdateQuest = () => {
  const [updateQuestFun, { isLoading }] = useUpdateQuestMutation();

  const handleUpdateQuest = async (values: UpdateQuestMutationVariables) => {
    try {
      const res = await updateQuestFun({
        updateQuestId: values.updateQuestId,
        updateObj: values.updateObj,
      }).unwrap();
      return res.updateQuest;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleUpdateQuest,
    isLoadingUpdateQuest: isLoading,
  };
};
