import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useErrorHandler } from "hooks/useErrorHandler";
import {
  AddNewEventMutation,
  CreateEventInput,
  GetEventsByIdQuery,
  LeaderboardType,
  SetEventTranslationMutation,
  UpdateEventMutation,
  useGetClientsTreeQuery,
} from "graphql/_generated/graphql";
import { FormikHelpers } from "formik";
import { useEvent } from "./useEvent";
import customNotification from "components/custom-notification/CustomNotification";
import { useAppSelector } from "hooks/useStoreHooks";
import { getRandomColor } from "helpers";
import { useGetLeaderboardTypes } from "hooks/useGeLeaderboardTypes";
export type EventState = {
  langCode: string;
  id: string;
  addLanguage: boolean;
  editAble?: boolean;
};

export type SubmitEventResponse =
  | AddNewEventMutation["addNewEvent"]
  | SetEventTranslationMutation["setEventTranslation"]
  | UpdateEventMutation["updateEvent"];

export type GetEventDetails = GetEventsByIdQuery["getEventsById"] & {
  oldTitle?: string;
  oldDescription?: string;
  oldLocation?: string | null;
};

export const useEventViewModel = (state: EventState) => {
  const { id: editId, langCode, addLanguage } = state ?? {};
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const region = useAppSelector((state) => state.auth.region);

  const {
    getEvent,
    updateEvent,
    addEvent,
    handleEventTranslation,
    isLoadingEvent,
  } = useEvent();
  const { leaderboardTypes } = useGetLeaderboardTypes();

  const [eventData, setEventData] = useState<GetEventDetails>();

  const { data: companiesData } = useGetClientsTreeQuery(
    { clientId: null, region: region },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const initialValues: CreateEventInput = {
    language: eventData?.language ?? langCode,
    title: eventData?.title ?? "",
    description: eventData?.description ?? "",
    leaderboardType:
      eventData?.leaderboardType ?? (null as unknown as LeaderboardType),
    expiryDate: eventData?.expiryDate ?? "",
    startDate: eventData?.startDate ?? "",
    image: eventData?.image ?? "",
    location: eventData?.location ?? "",
    companyIds: eventData?.participants?.map((o) => o.clientId) ?? [],
    galleryImages: eventData?.gallery?.length
      ? eventData?.gallery?.map((item) => item.image)
      : [],
    teams: eventData?.teams?.map((item) => {
      const { id, ...restItem } = item;
      return {
        teamId: id,
        ...restItem,
      };
    }) ?? [{ title: "", color: getRandomColor() }],
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (editId) {
          const data = await getEvent(editId);
          const translationData = langCode && data?.translations?.[langCode];
          setEventData({
            ...data,
            title: addLanguage ? translationData?.title : data.title,
            description: addLanguage
              ? translationData?.description
              : data.description,
            location: addLanguage ? translationData?.location : data.location,
            oldTitle: data.title ?? "",
            oldDescription: data.description ?? "",
            oldLocation: data.location,
          });
        }
      } catch (error) {
        handleError(error as string | object);
      }
    };
    fetchData();
  }, [editId]);

  const onSubmit = async (
    values: CreateEventInput,
    { resetForm }: FormikHelpers<CreateEventInput>,
  ) => {
    let res;
    try {
      const { galleryImages, leaderboardType, teams, ...restValues } = values;
      const filteredGallery = galleryImages?.filter((item) => item !== "");
      if (!editId && !addLanguage) {
        res = await addEvent({
          create: {
            ...restValues,
            leaderboardType,
            galleryImages: filteredGallery,
            ...(leaderboardType === LeaderboardType.Team && { teams }),
          },
        });
        customNotification("success", t("event.added-successfully"));
      } else if (addLanguage && editId && langCode) {
        res = await handleEventTranslation({
          setEventTranslationId: editId,
          translationInput: {
            translations: {
              [langCode]: {
                title: values.title,
                description: values.description,
                location: values.location,
              },
            },
          },
        });
        customNotification("success", t("event.translation-successfully"));
      } else {
        res = await updateEvent({
          update: {
            ...restValues,
            leaderboardType,
            galleryImages: filteredGallery,
            eventId: editId,
            ...(leaderboardType === LeaderboardType.Team && { teams }),
          },
        });
        customNotification("success", t("event.updated-successfully"));
      }

      resetForm();
      navigate("/events");
      return res;
    } catch (error) {
      handleError(error as string | object);
    }
  };

  return {
    initialValues,
    eventData,
    onSubmit,
    isLoadingEvent,
    companiesData: companiesData?.getClientsTree,
    LeaderboardTypesData: leaderboardTypes,
  };
};
