import cronTime from "cron-time-generator";
import {
  useUpdateAssessmentMutation,
  useUpdateNotificationListMutation,
} from "graphql/_generated/graphql";
import moment from "moment";

export const useUpdateAssessmentSettings = () => {
  // const [updateAssessmentSettingsFun, { isLoading }] =
  //   useUpdateAssessmentSettingMutation();
  const [updateAssessmentFun, { isLoading }] = useUpdateAssessmentMutation();

  const [updateNotificationsFun, { isLoading: isLoadingNotification }] =
    useUpdateNotificationListMutation();

  const handleUpdateSettings = async (values: any) => {
    const {
      notificationSetting,
      deleteNotifications,
      assessmentSettingId,
      ...rest
    } = values.update;
    const {
      createdAt,
      updatedAt,
      id,
      isActive,
      language,
      ...updateAssessment
    } = rest;
    const formatDatewithTime = (item: any) => {
      const scheduleTime = item?.timeOfDay ? moment(item?.timeOfDay) : moment();
      const hour = scheduleTime.hour();
      const minute = scheduleTime.minutes();
      const second = scheduleTime.seconds();
      let startDate = moment().add(1, "days");
      let endDate = moment().add(6, "days");
      let cron;
      if (item?.isPeriodic) {
        startDate = moment(item?.startDate).set({ hour, minute, second });
        endDate = moment(item?.endDate).set({ hour, minute, second });
        cron = cronTime.every(parseInt(item?.cron)).days(hour, minute);
      } else {
        cron = "";
      }

      return {
        ...item,
        startDate,
        cron,
        endDate,
        timeOfDay: undefined,
      };
    };

    const updateNotificationList = notificationSetting
      .filter((item: any) => item.id)
      .map((item: any) => formatDatewithTime(item));
    const createNotificationList = notificationSetting
      .filter((obj: any) => !obj.id)
      .map((item: any) => formatDatewithTime(item));
    const updateObj = {
      ...updateAssessment,
      assessmentSettingId: {
        isCategoriesEnable: assessmentSettingId.isCategoriesEnable,
        isContentAssociationEnable:
          assessmentSettingId.isContentAssociationEnable,
        isExpiryDateEnable: assessmentSettingId.isExpiryDateEnable,
        isGroupingEnable: assessmentSettingId.isGroupingEnable,
        isMandatory: assessmentSettingId.isMandatory,
        isNotificationsEnable: assessmentSettingId.isNotificationsEnable,
        isPillarsEnable: assessmentSettingId.isPillarsEnable,
        isScoresEnable: assessmentSettingId.isScoresEnable,
        isSkippable: assessmentSettingId.isSkippable,
        isSubQuestionsEnable: assessmentSettingId.isSubQuestionsEnable,
        isTagsEnable: assessmentSettingId.isTagsEnable,
        isWearableEnable: assessmentSettingId.isWearableEnable,
        setColor: assessmentSettingId.setColor,
        wearableDataType: assessmentSettingId.wearableDataType,
      },
    };
    try {
      await updateAssessmentFun({
        updateAssessmentId: values.id,
        update: updateObj,
      }).unwrap();
      await updateNotificationsFun({
        assessmentId: values.id,
        createNotificationList: createNotificationList,
        updateNotificationList: updateNotificationList,
        deleteNotificationList: deleteNotifications ?? [],
      }).unwrap();
    } catch (error) {
      throw error;
    }
  };

  return {
    handleUpdateSettings,
    isLoading: isLoading || isLoadingNotification,
  };
};
