import {
  MutationSetDocumentationArgs,
  useLazyGetDocumentationQuery,
  useSetDocumentationMutation,
} from "graphql/_generated/graphql";
import {
  formatTranslationsForRequest,
  parseTranslationsFromResponse,
} from "../utils";

export const useGetSetDocumentation = () => {
  const [setDocumentation, { isLoading }] = useSetDocumentationMutation();
  const [getDocumentationFun, { isLoading: isFetching }] =
    useLazyGetDocumentationQuery();

  //this function is responsible for adding the onBoarding data
  const handleSetDocumentation = async (
    values: MutationSetDocumentationArgs,
  ) => {
    try {
      let translations = formatTranslationsForRequest(
        values.create.translations,
      );
      let params = { create: { ...values.create, translations } };

      const res = await setDocumentation(params).unwrap();
      return res.setDocumentation.id;
    } catch (error: any) {
      throw error;
    }
  };

  const getDocumentation = async () => {
    try {
      const res = await getDocumentationFun().unwrap();
      let translations = await parseTranslationsFromResponse(
        res.getDocumentation?.translations,
      );

      return { ...res?.getDocumentation, translations };
    } catch (error: any) {
      throw error;
    }
  };

  return {
    isLoading: isLoading || isFetching,
    handleSetDocumentation,
    getDocumentation,
  };
};
