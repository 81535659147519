import Icon from "@ant-design/icons";
import { PointsIcon } from "assets/icons/PointsSvg";
import uploadImgPlaceholder from "assets/images/imgPlaceHolder.svg";
import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { CustomUploadFile } from "components/form/custom-upload-file/CustomUploadFile";
import styles from "features/rewards/css/AddTier.module.scss";
import { Form, Formik, FormikHelpers } from "formik";
import { TierCreate } from "graphql/_generated/graphql";
import { useTranslation } from "react-i18next";
import { addTierSchema } from "validations/schema/addTierSchema";

export interface AddTierProps {
  visible: boolean;
  editId?: string | null;
  langCode?: string;
  isAddLanguage?: boolean;
  isLoading?: boolean;
  initialValues: TierCreate;
  handleCancel: (e: React.MouseEvent<HTMLElement>) => void;
  handleSubmit: (
    values: TierCreate,
    { resetForm }: FormikHelpers<TierCreate>,
  ) => void;
}

const AddTier: React.FunctionComponent<AddTierProps> = ({
  visible,
  editId,
  isAddLanguage,
  isLoading,
  initialValues,
  handleCancel,
  handleSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <CustomModal
      title={
        editId && !isAddLanguage
          ? t("edit-tier")
          : `${t("add-tier")} ${isAddLanguage ? t("language") : ""}`
      }
      footer={false}
      visible={visible}
      onCancel={handleCancel}
      width={700}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={addTierSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            {!isAddLanguage && (
              <div className={styles["tier-image"]}>
                <div className="challenge-detail-container">
                  <CustomUploadFile
                    infoText={t("tier-image")}
                    label={t("tier-image")}
                    setFieldValue={setFieldValue}
                    name="tierImage"
                    accept="image/png, .jpg, .jpeg"
                    customUploadText={<span>upload Image</span>}
                    customUploadImgSrc={uploadImgPlaceholder}
                    showFullPreview={true}
                    imgUrl={values.tierImage}
                  />
                </div>
                <div className="challenge-detail-container">
                  <CustomUploadFile
                    infoText={t("tier-viwell-card")}
                    label={t("tier-viwell-card")}
                    setFieldValue={setFieldValue}
                    name="tierCardImage"
                    accept="image/png, .jpg, .jpeg"
                    customUploadText={<span>upload Image</span>}
                    customUploadImgSrc={uploadImgPlaceholder}
                    showFullPreview={true}
                    imgUrl={values.tierCardImage}
                  />
                </div>
                <CustomUploadFile
                  infoText={t("tier-icon")}
                  label={t("tier-icon")}
                  setFieldValue={setFieldValue}
                  name="tierIcon"
                  accept="image/png, .jpg, .jpeg"
                  imgUrl={values.tierIcon}
                />
              </div>
            )}
            <CustomTextField
              name="name"
              label={t("tier-name")}
              placeholder={t("enter-tier-name")}
            />
            {!isAddLanguage && (
              <CustomTextField
                name="points"
                label={t("required-points")}
                placeholder={t("enter-tier-points")}
                suffix={<Icon component={PointsIcon} />}
                type="number"
              />
            )}
            <ModalFooter
              saveText={t("save-tier")}
              onCancel={handleCancel}
              loading={isLoading}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddTier;
