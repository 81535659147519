import { TabsProps } from "antd";
import CustomModal from "components/custom-modal/CustomModal";
import CustomTabs from "components/custom-tabs/CustomTabs";
import { useTranslation } from "react-i18next";
import { RewardCreateProps } from "../hooks/useAddPartnerRewardViewModel";
import AvailableCodes from "./AvailableCodes";
import RedeemedCodes from "./RedeemedCodes";

type Props = {
  visible: boolean;
  setVisible: () => void;
  values: RewardCreateProps;
};

export default function StockEditor({ visible, setVisible, values }: Props) {
  const { t } = useTranslation();

  const tabs: TabsProps["items"] = [
    {
      label: t("available"),
      children: (
        <AvailableCodes availableRewardStocks={values.availableRewardStocks} />
      ),
      key: "available",
    },
    {
      label: t("redeemed"),
      children: (
        <RedeemedCodes redeemedRewardStocks={values.redeemedRewardStocks} />
      ),
      key: "redeemed",
    },
  ];

  return (
    <CustomModal
      width={600}
      visible={visible}
      onCancel={() => setVisible()}
      footer={false}
      title={t("stock-editor")}
    >
      <CustomTabs items={tabs} />
    </CustomModal>
  );
}
