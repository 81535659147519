import { EditOutlined } from "@ant-design/icons";
import { Space } from "antd";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import CustomButton from "components/custom-button/CustomButton";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import AddPartner from "features/rewards/partners/AddPartner";
import PartnerDetail from "features/rewards/partners/PartnerDetail";
import PartnerRewards from "features/rewards/partners/PartnerRewards";
import { useGetPartnerDetailsQuery } from "graphql/_generated/graphql";
import { PERMS, getLanguage } from "helpers";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./PartnerDetails.module.scss";

interface PartnerDetailsProps {}

const PartnerDetails: React.FunctionComponent<PartnerDetailsProps> = (
  props,
) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [params, setPrams] = useState(location.state);

  const { t } = useTranslation();
  const langLabel = getLanguage(params?.langCode);

  const [visible, setVisible] = useState(false);
  const [editId, setEditId] = useState(params?.id);

  const { data } = useGetPartnerDetailsQuery({
    getPartnerDetailsId: params?.id,
  });

  return (
    <DashboardLayout openKey={["Partner"]}>
      <Breadcrumbs
        data={[
          {
            label: t("rewards"),
          },
          {
            label: t("partners"),
          },
          {
            label: t("partner-details", { langLabel }),
          },
        ]}
      />
      <div className={styles["partner-details"]}>
        <h1 className={styles["title"]}>
          {" "}
          {t("partner-details", { langLabel })}
        </h1>
        <div>
          <Space size={16}>
            <CustomButton
              onClick={() => navigate("/partners")}
              className="link-text-btn"
            >
              {t("close")}
            </CustomButton>
            <CheckPermissions permission={PERMS.UPDATE_PARTNER}>
              <CustomButton
                onClick={() => {
                  setVisible(true);
                  setEditId(params?.id);
                }}
                icon={<EditOutlined />}
                className="light-btn"
              >
                {t("edit-partner")}
              </CustomButton>
            </CheckPermissions>
          </Space>
        </div>
      </div>
      <PartnerDetail data={data?.getPartnerDetails} />
      <div className={styles["partner-rewards"]}>
        <PartnerRewards partnerId={params?.id} />
      </div>
      {visible && (
        <AddPartner
          visible={visible}
          setVisible={setVisible}
          editId={editId}
          setEditId={setEditId}
        />
      )}
    </DashboardLayout>
  );
};

export default PartnerDetails;
